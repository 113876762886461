export const ColorPalette = {
	colors: {
		"hoot-bgcolor-white": { background: "#ffffff", font: "black" },
		"hoot-bgcolor-grey": { background: "#cfd9df", font: "black" },
		"hoot-bgcolor-cyan": { background: "#56d5d8", font: "black" },
		"hoot-bgcolor-yellow": { background: "#ffda44", font: "black" },
		"hoot-bgcolor-brown": { background: "#e2c495", font: "black" },
		"hoot-bgcolor-pink": { background: "#fcb1dc", font: "black" },
		"hoot-bgcolor-violet": { background: "#6a5385", font: "white" },
		"hoot-bgcolor-darkblue": { background: "#34495e", font: "white" },
		"hoot-bgcolor-red": { background: "#fe5756", font: "white" },
		"hoot-bgcolor-green": { background: "#3bab52", font: "white" },
		"hoot-bgcolor-blue": { background: "#2584e5", font: "white" },
	},
	userColors: {
		"bgcolor-blue": { background: "#7fbef0", font: "white" },
		"bgcolor-red": { background: "#ff8076", font: "white" },
		"bgcolor-green": { background: "#a7d67d", font: "white" },
		"bgcolor-purple": { background: "#9380b7", font: "white" },
		"bgcolor-yellow": { background: "#fdc153", font: "white" },
		"bgcolor-pink": { background: "#eaa7cd", font: "white" },
		"bgcolor-nocolor": { background: "#a7a7a7", font: "white" },
	},
};

export default ColorPalette;

/**
 * Transform hoot colors from API to background and font colors.
 */
export const transformHootColors = (hoot) => {
	const { customHootThemeColors, background, font, hootBgColor } = hoot
		? hoot
		: {};

	//Variable for normal hoot background color, it has object with two property background and font
	const normalHootBgColor = ColorPalette.colors[hootBgColor];

	if (customHootThemeColors) {
		return {
			background: customHootThemeColors.background,
			font: customHootThemeColors.isDark ? "white" : "black",
		};
	}

	if (hootBgColor && normalHootBgColor) {
		return {
			background: normalHootBgColor.background || "white",
			font: normalHootBgColor.font || "black",
		};
	}

	return {
		background: background || "white",
		font: font || "black",
	};
};

/**
 * Check if provided color is a dark or light color.
 * Reference: https://awik.io/determine-color-bright-dark-using-javascript/
 */
export const isColorDark = (color) => {
	// Variables for red, green, blue values
	var r, g, b, hsp;

	// Check the format of the color, HEX or RGB?
	if (color.match(/^rgb/)) {
		// If RGB --> store the red, green, blue values in separate variables
		color = color.match(
			/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
		);

		r = color[1];
		g = color[2];
		b = color[3];
	} else {
		// If hex --> Convert it to RGB: http://gist.github.com/983661
		color = +(
			"0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
		);

		r = color >> 16;
		g = (color >> 8) & 255;
		b = color & 255;
	}

	// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
	hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

	// Using the HSP value, determine whether the color is light or dark
	if (hsp > 127.5) {
		return false;
	} else {
		return true;
	}
};

export const getColorIntensity = (hexColor) => {
	// Convert the hex color to RGB
	const r = parseInt(hexColor.slice(1, 3), 16);
	const g = parseInt(hexColor.slice(3, 5), 16);
	const b = parseInt(hexColor.slice(5, 7), 16);

	// Calculate the relative luminance of the color
	const min = Math.min(r, g, b);
	const max = Math.max(r, g, b);

	const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

	const lightness = Math.round(luminance * 100);
	const darkness = Math.round((1 - luminance) * 100);
	const saturation = max === 0 ? 0 : ((max - min) / max) * 100;

	// Return an object with the percentages
	return {
		lightness,
		darkness,
		saturation,
	};
};

// Helper function to pad a hex value with a leading zero if necessary
const padHexValue = (hexValue) => {
	let hexString = hexValue.toString(16);
	while (hexString.length < 6) {
		hexString = "0" + hexString;
	}
	return hexString;
};

// Helper function to convert a hex color to a darker variant
const darkenColor = (color, amount = 0.4) => {
	// Convert the given color from hex to RGB
	const r = parseInt(color.slice(1, 3), 16);
	const g = parseInt(color.slice(3, 5), 16);
	const b = parseInt(color.slice(5, 7), 16);

	// Calculate the new RGB values based on the given amount
	const newR = Math.max(0, Math.floor(r - r * amount));
	const newG = Math.max(0, Math.floor(g - g * amount));
	const newB = Math.max(0, Math.floor(b - b * amount));

	// Convert the new RGB values back to hex
	const newColor =
		"#" + ((newR << 16) | (newG << 8) | newB).toString(16).padStart(6, "0");

	// Return the new color in hex
	return newColor;
};

const lightenColor = (color, amount = 0.2) => {
	// Convert the given color from hex to RGB
	const r = parseInt(color.slice(1, 3), 16);
	const g = parseInt(color.slice(3, 5), 16);
	const b = parseInt(color.slice(5, 7), 16);

	// Calculate the new RGB values based on the given amount
	const newR = Math.min(255, Math.floor(r + (255 - r) * amount));
	const newG = Math.min(255, Math.floor(g + (255 - g) * amount));
	const newB = Math.min(255, Math.floor(b + (255 - b) * amount));

	// Convert the new RGB values back to hex
	const newColor =
		"#" + ((newR << 16) | (newG << 8) | newB).toString(16).padStart(6, "0");

	// Return the new color in hex
	return newColor;
};

const defaultColor = "#6dba30";

/**
 * Get board's primary color. Default to a green color.
 * @param {Object} boardThemeCustomColors Object from boards API response.
 */
export const getBoardColor = (boardThemeCustomColors) => {
	const { button } = boardThemeCustomColors || {};
	return button || defaultColor;
};

/**
 * Generate triadic colors for given primary color.
 * @param {Object} boardThemeCustomColors Object from boards API response.
 */
export const getTriadColors = (boardThemeCustomColors) => {
	let primaryColor = getBoardColor(boardThemeCustomColors);

	if (primaryColor.includes("rgb")) {
		// Extract the RGB values from the string
		const rgbValues = primaryColor.replace(/[^\d,]/g, "").split(",");
		const r = parseInt(rgbValues[0]);
		const g = parseInt(rgbValues[1]);
		const b = parseInt(rgbValues[2]);

		// Convert the RGB values to hex and concatenate them
		const hexR = r.toString(16).padStart(2, "0");
		const hexG = g.toString(16).padStart(2, "0");
		const hexB = b.toString(16).padStart(2, "0");

		primaryColor = "#" + hexR + hexG + hexB;
	}

	const { darkness, lightness, saturation } = getColorIntensity(primaryColor);

	if (saturation < 30) {
		primaryColor = defaultColor;
	} else if (lightness > 73) {
		primaryColor = darkenColor(primaryColor);
	} else if (darkness > 73) {
		primaryColor = lightenColor(primaryColor);
	}

	// Convert the given color from hex to RGB
	const r = parseInt(primaryColor.slice(1, 3), 16);
	const g = parseInt(primaryColor.slice(3, 5), 16);
	const b = parseInt(primaryColor.slice(5, 7), 16);

	// Calculate the three triadic colors
	const triadic1 = "#" + padHexValue((r << 16) | (g << 8) | b);
	const triadic2 = "#" + padHexValue((g << 16) | (b << 8) | r);
	const triadic3 = "#" + padHexValue((b << 16) | (r << 8) | g);

	// Return an array of the three triadic colors
	return [triadic1, triadic2, triadic3];
};
