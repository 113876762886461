import {
	PayloadAction,
	// combineReducers,
	createAction,
	createSlice,
} from "@reduxjs/toolkit";
import { ActionState, AsyncState } from "../../types";
import { Actions } from "./manageMembers.saga";
import { memberTypeFromCode } from "./memberType.util";

interface Member {
	boardAdmin: boolean;
	memberPhotoExists: boolean;
	memberColorCss: string;
	memberName: string;
	memberType: string;
	memberPhoto: string;
	memberInitials: string;
	memberId: number;
	memberEmail: string;
}
export interface PayloadActionsWithMeta {
	type: string;
	payload?: any;
	meta?: any;
}

//!validator.validate(action.payload.newEmail),
const defaultMessage = `Hey, Join our HootBoard please. It will only take 30 seconds, but it's worth it. It's a great way for us to post announcements, policies & events publicly OR privately. We can also use it as a forum or as a way to recognize our achievements. Once you open the board click "Accept Invitation".`;

/**
 * Action for updating a request.
 */
export const updateRequest = createAction(
	Actions.updateRequest + ActionState.REQUEST
);

/**
 * Action for fetching all manage members tab data.
 */
export const fetchManageMembersData = createAction(
	Actions.fetchManageMembersData + ActionState.REQUEST
);

/**
 * Action for fetching members data.
 */
export const fetchMembersData = createAction(
	Actions.fetchMembersData + ActionState.REQUEST
);

/**
 * Action for fetching invites data.
 */
export const fetchPendingInvitesData = createAction(
	Actions.fetchPendingInvitesData + ActionState.REQUEST
);

/**
 * Action for fetching requests data.
 */
export const fetchRequestsData = createAction(
	Actions.fetchRequestsData + ActionState.REQUEST
);

/**
 * Action for refresh pending invitations.
 */
export const refreshPendingInvites = createAction(
	Actions.refreshPendingInvites + ActionState.REQUEST
);

/**
 * Action for resending an invitation.
 */
export const resendInvitation = createAction(
	Actions.resendInvitation + ActionState.REQUEST
);

/**
 * Action for revoking an invitation.
 */
export const revokeInvitation = createAction(
	Actions.revokeInvitation + ActionState.REQUEST
);

/**
 * Action to change a member type.
 */
export const changeMemberType = createAction(
	Actions.changeMemberType + ActionState.REQUEST
);

/**
 * Action for removing a member.
 */
export const removeMember = createAction(
	Actions.removeMember + ActionState.REQUEST
);

/**
 * Action for fetching all subscribers.
 */

interface getAllSubscribersPayload {
	boardId: number;
	limit: number;
	offset: number;
}

export const getAllSubscribers = createAction<getAllSubscribersPayload>(
	Actions.getAllSubscribers + ActionState.REQUEST
);

/**
 * Action for sending invites.
 */
export const sendInvites = createAction(
	Actions.sendInvites + ActionState.REQUEST
);

/**
 * Action for sending bulk invites.
 */
export const sendBulkInvite = createAction(
	Actions.sendBulkInvite + ActionState.REQUEST
);

const initialState = {
	isFetchingInitial: true,
	members: [] as Member[],
	pendingInvites: [],
	requests: [],
	filterMemberTypes: {} as { [key: string]: boolean },

	pendingChanges: {} as { [key: string]: string },
	resendingInvitations: {} as { [key: string]: string },
	revokingInvitations: {} as { [key: string]: string },

	requestsAccepting: {} as { [key: string]: string },
	requestsDeclining: {} as { [key: string]: string },

	getSubscribersData: [],
	totalSubscribersCount: 0,
	getSubscribersState: AsyncState.IDLE as string,
	inviteMembers: {
		isShowing: false,
		step: 0,

		selectedMemberType: null,
		emails: "",
		//testing
		emailValidationError: "",
		message: defaultMessage,
		errorMessage: null,

		bulkInviteCSVFileName: "",
		originalFileName: "",
		uploadingCSV: false,

		recaptchaCompleted: false,
		status: null as null | string,
	},
};

const manageMembersSlice = createSlice({
	name: "manageMembers",
	initialState,
	reducers: {
		selectMemberTypeFilter: (state: any, action) => {
			state.filterMemberTypes[action.payload.memberType] = true;
		},
		removeMemberTypeFilter: (state: any, action) => {
			delete state.filterMemberTypes[action.payload.memberType];
		},
		clearMemberTypeFilters: (state) => {
			state.filterMemberTypes = {};
		},
		setIsUploadingCSV: (state: any, action) => {
			state.inviteMembers.uploadingCSV = action.payload;
		},
		bulkInviteFileUploaded: (state: any, action) => {
			const { genFileName, origFileName } = action.payload;
			state.inviteMembers.bulkInviteCSVFileName = genFileName;
			state.inviteMembers.originalFileName = origFileName;
			state.inviteMembers.uploadingCSV = false;
		},
		recaptchaCompletedChanged: (state: any, action) => {
			state.inviteMembers.recaptchaCompleted = action.payload;
		},
		showInviteMembersModal: (state) => {
			state.inviteMembers.isShowing = true;
		},
		close: (state) => {
			state.inviteMembers.step = 0;
			state.inviteMembers.isShowing = false;
			state.inviteMembers.message = defaultMessage;
			state.inviteMembers.selectedMemberType = null;
			state.inviteMembers.bulkInviteCSVFileName = "";
			state.inviteMembers.originalFileName = "";
			state.inviteMembers.status = null;
			state.inviteMembers.emailValidationError = "";
			state.inviteMembers.recaptchaCompleted = false;
			state.inviteMembers.emails = "";
		},
		nextStep: (state) => {
			state.inviteMembers.step += 1;
		},
		jumpToStep: (state: any, action) => {
			state.inviteMembers.step = action.payload;
			state.inviteMembers.recaptchaCompleted = false;
		},
		showErrors: () => {},
		emailsChanged: (state: any, action) => {
			state.inviteMembers.emails = (action.payload || "").replace(
				/;/g,
				","
			);
			state.inviteMembers.emailValidationError = "";
		},
		//testing
		updateEmailValidationMsg: (state: any, action) => {
			state.inviteMembers.emailValidationError = action.payload;
		},
		messageChanged: (state: any, action) => {
			state.inviteMembers.message = action.payload;
		},
		memberTypeSelected: (state: any, action) => {
			state.inviteMembers.selectedMemberType = action.payload;
			state.inviteMembers.step += 1;
		},
		reset: (state) => {
			state.inviteMembers.step = 0;
			state.inviteMembers.selectedMemberType = null;
			state.inviteMembers.message = defaultMessage;
			state.inviteMembers.recaptchaCompleted = false;
			state.inviteMembers.status = null;
			state.inviteMembers.emails = "";
			state.inviteMembers.bulkInviteCSVFileName = "";
			state.inviteMembers.originalFileName = "";
			state.inviteMembers.emailValidationError = "";
		},
	},
	extraReducers(builder) {
		/**
		 * cases for fetch Manage Members Data.
		 */
		builder.addCase(
			Actions.fetchManageMembersData + ActionState.PENDING,
			(state) => {
				state.isFetchingInitial = true;
			}
		);
		builder.addCase(
			Actions.fetchManageMembersData + ActionState.FULFILLED,
			(state) => {
				state.isFetchingInitial = false;
			}
		);
		builder.addCase(
			Actions.fetchManageMembersData + ActionState.REJECTED,
			(state) => {
				state.isFetchingInitial = false;
			}
		);

		/**
		 * cases for fetch Members Data.
		 */
		builder.addCase(
			Actions.fetchMembersData + ActionState.PENDING,
			(state) => {
				state.isFetchingInitial = true;
				state.filterMemberTypes = {};
				state.pendingChanges = {};
			}
		);
		builder.addCase(
			Actions.fetchMembersData + ActionState.FULFILLED,
			(state: any, action: PayloadAction<any>) => {
				state.members = action.payload;
				state.isFetchingInitial = false;
			}
		);
		builder.addCase(
			Actions.fetchMembersData + ActionState.REJECTED,
			(state) => {
				state.isFetchingInitial = false;
			}
		);

		/**
		 * cases for changing member type..inviteMembers
		 */
		builder.addCase(
			Actions.changeMemberType + ActionState.PENDING,
			(state: any, action: PayloadActionsWithMeta) => {
				const { memberId } = action.meta.arg;
				state.pendingChanges[memberId] = "pending";
			}
		);
		builder.addCase(
			Actions.changeMemberType + ActionState.FULFILLED,
			(state: any, action: PayloadAction<any>) => {
				const { response, memberId, memberAccessCode } = action.payload;

				state.pendingChanges[memberId] = "fulfilled";

				if (!response) return;

				const index = state.members.findIndex(
					(member: Member) => member.memberId === memberId
				);

				if (memberAccessCode === 3) {
					state.members.splice(index, 1);
					return;
				}

				state.members[index].memberType =
					memberTypeFromCode(memberAccessCode);
			}
		);
		builder.addCase(
			Actions.changeMemberType + ActionState.REJECTED,
			(state: any, action: PayloadActionsWithMeta) => {
				const { memberId } = action.meta.arg;
				state.pendingChanges[memberId] = "rejected";
			}
		);

		/**
		 * cases for removing member.
		 */
		builder.addCase(
			Actions.removeMember + ActionState.PENDING,
			(state: any, action: PayloadActionsWithMeta) => {
				const { memberId } = action.meta.arg;
				state.pendingChanges[memberId] = "pending";
			}
		);
		builder.addCase(
			Actions.removeMember + ActionState.FULFILLED,
			(state: any, action: PayloadAction<any>) => {
				const { memberId, response } = action.payload;

				if (!response) {
					state.pendingChanges[memberId] = "rejected";
					return;
				}

				state.members = state.members.filter(
					(member: Member) => member.memberId !== memberId
				);

				state.pendingChanges[memberId] = "fulfilled";
			}
		);
		builder.addCase(
			Actions.removeMember + ActionState.REJECTED,
			(state: any, action: PayloadActionsWithMeta) => {
				const { memberId } = action.meta.arg;
				state.pendingChanges[memberId] = "rejected";
			}
		);

		/**
		 * cases for fetching pending invites data.
		 */
		builder.addCase(
			Actions.fetchPendingInvitesData + ActionState.PENDING,
			(state) => {
				state.isFetchingInitial = true;
				state.pendingInvites = [];
				state.revokingInvitations = {};
				state.resendingInvitations = {};
			}
		);
		builder.addCase(
			Actions.fetchPendingInvitesData + ActionState.FULFILLED,
			(state: any, action: PayloadAction<any>) => {
				const pendingInvites = action.payload;
				state.isFetchingInitial = false;
				state.pendingInvites = pendingInvites;
			}
		);
		builder.addCase(
			Actions.fetchPendingInvitesData + ActionState.REJECTED,
			(state) => {
				state.isFetchingInitial = false;
			}
		);

		/**
		 * cases for refresh pending invites.
		 */
		builder.addCase(
			Actions.refreshPendingInvites + ActionState.PENDING,
			() => {}
		);
		builder.addCase(
			Actions.refreshPendingInvites + ActionState.FULFILLED,
			(state: any, action: PayloadAction<any>) => {
				const { requestsSent } = action.payload;

				state.pendingInvites = requestsSent ? requestsSent : [];
			}
		);
		builder.addCase(
			Actions.refreshPendingInvites + ActionState.REJECTED,
			() => {}
		);

		/**
		 * cases for resend pending invites.
		 */
		builder.addCase(
			Actions.resendInvitation + ActionState.PENDING,
			(state: any, action: PayloadActionsWithMeta) => {
				const invitationId = action.meta.arg;
				state.resendingInvitations[invitationId] = "pending";
			}
		);
		builder.addCase(
			Actions.resendInvitation + ActionState.FULFILLED,
			(state: any, action: PayloadActionsWithMeta) => {
				const { resendInviteStatus } = action.payload;
				const invitationId = action.meta.arg;

				state.resendingInvitations[invitationId] = resendInviteStatus
					? "fulfilled"
					: "rejected";
			}
		);
		builder.addCase(
			Actions.resendInvitation + ActionState.REJECTED,
			(state: any, action: PayloadActionsWithMeta) => {
				const invitationId = action.meta.arg;
				state.resendingInvitations[invitationId] = "rejected";
			}
		);

		/**
		 * cases for revoke pending invites.
		 */
		builder.addCase(
			Actions.revokeInvitation + ActionState.PENDING,
			(state: any, action: PayloadActionsWithMeta) => {
				const invitationId = action.meta.arg;
				state.revokingInvitations[invitationId] = "pending";
			}
		);
		builder.addCase(
			Actions.revokeInvitation + ActionState.FULFILLED,
			(state: any, action: PayloadActionsWithMeta) => {
				const { response, invitationId } = action.payload;

				state.revokingInvitations[invitationId] =
					response.removeInviteStatus ? "fulfilled" : "rejected";
			}
		);
		builder.addCase(
			Actions.revokeInvitation + ActionState.REJECTED,
			(state: any, action: PayloadActionsWithMeta) => {
				const invitationId = action.meta.arg;
				state.revokingInvitations[invitationId] = "rejected";
			}
		);

		/**
		 * cases for updating pending requests.
		 */
		builder.addCase(
			Actions.updateRequest + ActionState.PENDING,
			(state: any, action: PayloadActionsWithMeta) => {
				const { memberId, actionType } = action.meta.arg;

				if (actionType === "accept") {
					state.requestsAccepting[memberId] = "pending";
				} else {
					state.requestsDeclining[memberId] = "pending";
				}
			}
		);
		builder.addCase(
			Actions.updateRequest + ActionState.FULFILLED,
			(state: any, action: PayloadActionsWithMeta) => {
				const { response } = action.payload;
				const { success } = response;
				const { memberId, actionType } = action.meta.arg;

				if (success) {
					state.requests = state.requests.filter(
						({ id }: { id: number }) => id !== memberId
					);
				}

				if (actionType === "accept") {
					state.requestsAccepting[memberId] = success
						? "fulfilled"
						: "rejected";
				} else {
					state.requestsDeclining[memberId] = success
						? "fulfilled"
						: "rejected";
				}
			}
		);
		builder.addCase(
			Actions.updateRequest + ActionState.REJECTED,
			(state: any, action: PayloadActionsWithMeta) => {
				const { memberId, actionType } = action.meta.arg;
				if (actionType === "accept") {
					state.requestsAccepting[memberId] = "rejected";
				} else {
					state.requestsDeclining[memberId] = "rejected";
				}
			}
		);

		/**
		 * cases for fetching requests to post data.
		 */
		builder.addCase(
			Actions.fetchRequestsData + ActionState.PENDING,
			(state) => {
				state.isFetchingInitial = true;
				state.requestsAccepting = {};
				state.requestsDeclining = {};
			}
		);
		builder.addCase(
			Actions.fetchRequestsData + ActionState.FULFILLED,
			(state: any, action: PayloadAction<any>) => {
				const requests = action.payload;
				state.isFetchingInitial = false;
				state.requests = requests;
			}
		);
		builder.addCase(
			Actions.fetchRequestsData + ActionState.REJECTED,
			(state) => {
				state.isFetchingInitial = false;
			}
		);

		/**
		 * cases for fetching Subscribers.
		 */
		builder.addCase(
			Actions.getAllSubscribers + ActionState.PENDING,
			(state) => {
				state.getSubscribersState = AsyncState.PENDING;
			}
		);
		builder.addCase(
			Actions.getAllSubscribers + ActionState.FULFILLED,
			(state: any, action: PayloadAction<any>) => {
				const { subscribers, subscribersCount } = action.payload;
				state.getSubscribersData = subscribers;
				state.getSubscribersState = ActionState.FULFILLED;
				state.totalSubscribersCount = subscribersCount;
			}
		);
		builder.addCase(
			Actions.getAllSubscribers + ActionState.REJECTED,
			(state) => {
				state.getSubscribersState = ActionState.REJECTED;
			}
		);

		/**
		 * cases for sending Invites.
		 */
		builder.addCase(Actions.sendInvites + ActionState.PENDING, (state) => {
			state.inviteMembers.errorMessage = null;
			state.inviteMembers.status = "pending";
		});
		builder.addCase(
			Actions.sendInvites + ActionState.FULFILLED,
			(state: any, action: PayloadAction<any>) => {
				const { boardInviteStatus, msg } = action.payload;
				if (boardInviteStatus) {
					state.inviteMembers.step = 0;
					state.inviteMembers.selectedMemberType = null;
					state.inviteMembers.emails = "";
					state.inviteMembers.status = "fulfilled";
				} else {
					state.inviteMembers.errorMessage = msg;
					state.inviteMembers.status = "rejected";
				}
			}
		);
		builder.addCase(Actions.sendInvites + ActionState.REJECTED, (state) => {
			state.inviteMembers.status = "rejected";
		});

		/**
		 * cases for sending bulk Invites.
		 */
		builder.addCase(
			Actions.sendBulkInvite + ActionState.PENDING,
			(state) => {
				state.inviteMembers.status = "pending";
			}
		);
		builder.addCase(
			Actions.sendBulkInvite + ActionState.FULFILLED,
			(state: any, action: PayloadAction<any>) => {
				const response = action.payload;

				if (response.result === "1") {
					state.inviteMembers.step = 0;
					state.inviteMembers.selectedMemberType = null;
					state.inviteMembers.status = "fulfilled";
				} else {
					state.inviteMembers.status = "rejected";
				}
			}
		);
		builder.addCase(
			Actions.sendBulkInvite + ActionState.REJECTED,
			(state) => {
				state.inviteMembers.status = "rejected";
			}
		);
	},
});

export const {
	selectMemberTypeFilter,
	removeMemberTypeFilter,
	clearMemberTypeFilters,
	showInviteMembersModal,
	close,
	nextStep,
	jumpToStep,
	showErrors,
	emailsChanged,
	memberTypeSelected,
	messageChanged,
	recaptchaCompletedChanged,
	bulkInviteFileUploaded,
	reset,
	setIsUploadingCSV,
	updateEmailValidationMsg,
} = manageMembersSlice.actions;

export default manageMembersSlice.reducer;
