/* eslint-disable import/namespace */
/* eslint-disable react/prop-types */
import React from "react";

/**
 * Global error boundary for the app.
 * Usually, when an unhandled error happens, the react app will render a white screen (WCOD).
 * This component will catch all unhandled errors and prevent the app crashing.
 * And we can also send the error information to error reporting tool.
 */
export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
	}

	/**
	 * Update state so the next render will show the fallback UI.
	 */
	static getDerivedStateFromError() {
		return {};
	}

	componentDidCatch(error) {
		console.error(error)
	}

	render() {
		return this.props.children;
	}
}
