/*
 * @Author: snair@hootboard.com
 * @Date: 2020-06-22 19:46:21
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-12-24 15:50:28
 */

import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "../../api/calendar.service";

function* fetchCalendarEventsSaga() {
	yield takeLatest("calendar/events/get/request", function* (action) {
		try {
			yield put({
				type: "calendar/events/get/pending",
				payload: {},
			});
			const events = yield call(
				async () => await API.getHootsForCalendar(action.payload)
			);
			if (events && events.status !== "error") {
				yield put({
					type: "calendar/events/get/fulfilled",
					payload: events,
				});
			} else {
				throw new Error("API Error!");
			}
		} catch (e) {
			yield put({
				type: "calendar/events/get/rejected",
				payload: e,
			});
		}
	});
}

function* fetchCalendarEventsForDurationSaga() {
	yield takeLatest(
		"calendar/events/duration/get/request",
		function* (action) {
			try {
				yield put({
					type: "calendar/events/duration/get/pending",
					payload: {},
				});
				const events = yield call(
					async () =>
						await API.getHootsForCalendarDuration(action.payload)
				);
				if (events && events.status !== "error") {
					yield put({
						type: "calendar/events/duration/get/fulfilled",
						payload: events,
					});
				} else {
					throw new Error("API Error!");
				}
			} catch (e) {
				yield put({
					type: "calendar/events/duration/get/rejected",
					payload: e,
				});
			}
		}
	);
}

export const calendarSagas = [
	fetchCalendarEventsSaga(),
	fetchCalendarEventsForDurationSaga(),
];
