import moment from "moment-timezone";

/**
 * Return date as Nov 3, 2020 format.
 * @param {Object} date Date-time object.
 */
export const formatDateForPricing = (date) => {
	const dateFormat = "MMM D, YYYY";
	return moment(date).format(dateFormat);
};

/**
 * Calculate the item price details based on tier pricing or flat pricing.
 * Check the return value to get an idea.
 * @param {Object} params
 * @param {Number} params.price
 * @param {Number} params.quantity
 * @param {Array<Object>} params.tiers
 */
export const calculateItemPrice = (params) => {
	const tiers = [...(params.tiers || [])];

	// Sort the tiers by usage count.
	const sortedTiers = tiers
		.sort((a, b) => b.up_to - a.up_to)
		.filter((item) => item.up_to);

	/**
	 * If usage count doesn't match with any of the tiers, then use the highest tier.
	 * Why this logic? There will be definitely a minium value tier.
	 * If the usage count exceeds all the tiers, none of the tiers will be matched.
	 * So, we need to use the highest tier in that case.
	 * */
	const highestTier = tiers.find((item) => !item.up_to) || {};

	// This is the match tier.
	let selectedTier = {};

	for (const tier of sortedTiers) {
		if (params.quantity <= tier.up_to) {
			selectedTier = { ...tier };
		}
	}

	let unitPrice = null;
	// If no matching tier found,
	if (selectedTier.unit_amount_decimal) {
		unitPrice = parseFloat(`${selectedTier.unit_amount_decimal}`);
	} else {
		// Fallback to the highest tier.
		unitPrice = parseFloat(
			`${highestTier.unit_amount_decimal || params.price}`
		);
	}

	// We need to handle the flat fee as well. There could be some.
	const flatFee = +(selectedTier.flat_amount_decimal || 0);
	const totalPrice = unitPrice * params.quantity + flatFee;

	return {
		unitPrice: unitPrice,
		totalPrice: totalPrice,
	};
};

/**
 * Calculate the total price of the cart.
 * @param {Array} cartItems Use the `items` field from `cart` object from "pricing" reducer.
 */
export const calculateTotalPrice = (cartItems) => {
	return cartItems.reduce((result, item) => {
		return parseFloat(`${result}`) + parseFloat(`${item.totalPrice}`);
	}, 0);
};

/**
 * Convert the cart items into API friendly format.
 * @param {Array} cartItems Use the `items` field from `cart` object from "pricing" reducer.
 */
export const transformCartPayload = (cartItems) => {
	const payload = cartItems.map((item) => ({
		pricingId: item.pricingId,
		licenseType: item.licenseType,
		noOfLicenses: item.quantity,
	}));
	return JSON.stringify(payload);
};

/**
 * Get array of purchased items in such a format that mixpanel can track.
 * Refer to the cart item inside pricing slice.
 */
export const getPurchasedLicensesForMixpanel = (cart) => {
	const data = { ...cart, purchasedLicenses: cart.items };
	delete data.items;
	return data;
};

/**
 * Accept the whole board's active subscriptions list and make a summary out of it.
 * @param {Array<Object>} boardSubscriptions
 */
export const getCurrentCartSummary = (boardSubscriptions) => {
	const items = (boardSubscriptions || []) // Failsafe!
		// Add more statutes if needed.
		.filter((item) => ["active", "past_due"].includes(item.status));

	/**
	 * All items from all subscription items.
	 * Reduce to products items.
	 */
	let allProducts = items.reduce((result, item) => {
		return [...result, ...item.productItems];
	}, []);

	// Handling the user based pricing subscription items logic.

	const matchUBP = (item) => ["UBF", "UBM"].includes(item.licenseType);

	// First, we need to find the user based pricing subscription items.
	const allUBPItems = allProducts.filter(matchUBP);

	// Get the sum of all the user based pricing items.
	const totalUBPCount = allUBPItems.reduce((result, item) => {
		return result + item.quantity;
	}, 0);

	/**
	 * If user based pricing is present,
	 * we need to add a new item "UBP" to the list with sum of all the user based pricing items.
	 * And we need to remove the real user based pricing items from the list.
	 * So, this "UBP" is not a valid product item. This is just for the summary view to render properly.
	 * Otherwise we will have to show each user based pricing items (UBF & UBM) as separate items in summary.
	 */

	if (totalUBPCount > 0) {
		allProducts = [
			...allProducts.filter((item) => !matchUBP(item)),
			{
				currency: "$",
				itemName: "User Based Pricing",
				licenseType: "UBP",
				quantity: totalUBPCount,
			},
		];
	}

	/**
	 * Merged list of all pricing items.
	 * Products which have the same productId will be merged and shown as one item.
	 */
	const mergedPlans = allProducts.reduce((result, item) => {
		// If the item is already in the list, just update the quantity.
		// TODO: Change identifier to something more unique like productId.
		const matchExistingPlan = (plan) => plan.itemName === item.itemName;
		const existingPlan = result.find(matchExistingPlan);

		// If found,
		if (existingPlan) {
			return [
				{
					...existingPlan,
					// Update the quantity of the existing plan with matched item.
					quantity: existingPlan.quantity + item.quantity,
				},
				// Add the updated plan back to the list.
				// Remove the matched item from the list.
				...result.filter((plan) => !matchExistingPlan(plan)),
			];
		}

		// If not found, just add the item to the list.
		return [item, ...result];
	}, []);

	return mergedPlans;
};

/**
 * Access all pricing product items and group user based pricing items into one item.
 * This function is just to group the user based items. If we add more grouped items into our plan,
 * we need to consider adding another grouping method and follow a standard throughout the system.
 * @param {Array} products
 */
export const groupUBPProducts = (products) => {
	const matchCondition = (item) => ["UBF", "UBM"].includes(item.licenseType);

	// Find the user based pricing subscription items.
	const allUBPItems = products.filter(matchCondition);

	// If no user based pricing items, just return the products.
	if (allUBPItems.length === 0) return products;

	return [
		...products.filter((item) => !matchCondition(item)),
		{
			planName: "HootBoard for Web & Mobile",
			description:
				"Pay per user for your web and mobile. See below how much we charge for different types of users.",
			isGrouped: true,
			sequence: allUBPItems[0].sequence,
			isOneTime: true,
			licenseType: "UBP",
			items: allUBPItems.map((item) => ({
				...item,
				// For the grouped view, we can't just show the whole plan name.
				// There is not enough space. We need a short name for that.
				shortName: (() => {
					switch (item.licenseType) {
						case "UBF":
							return "Followers";

						case "UBM":
							return "Members";

						default:
							return "Others";
					}
				})(),
				// TODO: This and item description should be moved to the API.
				description: (() => {
					switch (item.licenseType) {
						case "UBF":
							return "Can only view public hoots and apps.";

						case "UBM":
							return "Can post, view private hoots and apps.";

						default:
							return "";
					}
				})(),
			})),
		},
	];
};

/**
 * Grouping method for the user based pricing items for the cart view.
 * If we add more grouped items into our plan, we need to consider adding
 * another grouping method and follow a standard throughout the system.
 * @param {Array} cartItems Use the `items` field from `cart` object from "pricing" reducer.
 */
export const groupUBPCartItems = (cartItems) => {
	const matchCondition = (item) => ["UBF", "UBM"].includes(item.licenseType);

	// Find the user based pricing subscription items.
	const allUBPItems = cartItems.filter(matchCondition);

	// If no user based pricing items, just return the products.
	if (allUBPItems.length === 0) return cartItems;

	const totalPrice = allUBPItems.reduce((result, item) => {
		return result + parseFloat(item.totalPrice);
	}, 0);

	return [
		...cartItems.filter((item) => !matchCondition(item)),
		{
			name: "HootBoard for Web & Mobile",
			isGrouped: true,
			currency: allUBPItems[0].currency,
			items: allUBPItems.map((item) => ({
				...item,
				// For the grouped view, we can't just show the whole plan name.
				// There is not enough space. We need a short name for that.
				shortName: (() => {
					switch (item.licenseType) {
						case "UBF":
							return "Followers";

						case "UBM":
							return "Members";

						default:
							return "Others";
					}
				})(),
			})),
			totalPrice,
		},
	];
};

/**
 * Check if the board subscriptions list contains user base pricing items.
 * If yes, board is already upgraded to UBP.
 * @param {Array<Object>} boardSubscriptions
 */
export const checkIfOnUBPFreePlan = (boardSubscriptions) => {
	// Get all product items from all subscriptions.
	const productItems = (boardSubscriptions || []).reduce((result, item) => {
		return [...result, ...item.productItems];
	}, []);
	const haveUBPItems = productItems.some((item) => {
		return ["UBF", "UBM"].includes(item.licenseType);
	});
	return !haveUBPItems;
};

/**
 * Loop through the subscriptions list and:
 * 1. Add missing discount amounts.
 * 2. Add price details of user based pricing items.
 *
 * This method is used inside the manage subscriptions page.
 */
export const optimizeSubscriptions = (subscriptions) => {
	return subscriptions.map((subscription) => {
		// First get the product items with proper price details.
		const productItems = subscription.productItems.map((productItem) => {
			const priceDetails = calculateItemPrice({
				price: productItem.unitAmount || 0,
				quantity: productItem.quantity || 0,
				tiers: productItem.tiers || [],
			});
			return { ...productItem, ...priceDetails };
		});

		// Then find the total amount.
		const totalAmount = productItems.reduce((result, item) => {
			return result + item.totalPrice;
		}, 0);

		// If there is coupon added to the subscription, find the discount amount and add that as well.
		let discount = 0;
		if ((subscription.coupon || {}).couponId) {
			discount = (totalAmount * subscription.coupon.percentOff) / 100;
		}

		return { ...subscription, productItems, totalAmount, discount };
	});
};
