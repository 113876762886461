import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as HootsService from "../api/hoots.service";
import * as HootsServiceAlt from "../api/hoots.service.alt";
import * as BoardsService from "../api/boards.service";

import * as BBActions from "../components/ResponsiveBulletinBoard/bulletinBoard.slice";
import * as MHActions from "./myHoots.slice";
import * as AHActions from "../components/HootDisplay/activeHoot.slice";

export const submit = createAsyncThunk(
	"/rehoot/submit",
	async (x, thunkApi) => {
		const {
			rehoot: { selectedBoards, selectedCollections, hoot },
		} = thunkApi.getState();

		const rehootBoards = selectedBoards
			.map(({ boardId }) => boardId)
			.join();
		const tagsText = selectedCollections
			.map(({ filterText }) => filterText)
			.join();

		const response = await HootsService.rehoot(
			hoot.id,
			rehootBoards,
			tagsText
		);

		thunkApi.dispatch(BBActions.rehooted(hoot));
		thunkApi.dispatch(MHActions.rehooted(hoot));
		thunkApi.dispatch(AHActions.rehooted(hoot));

		return { response };
	}
);

export const getCollections = createAsyncThunk(
	"/rehoot/getCollections",
	async (x, thunkApi) => {
		const {
			rehoot: { selectedBoards },
		} = thunkApi.getState();

		if (selectedBoards.length === 0) {
			return { collections: [] };
		}

		const res = await Promise.all(
			selectedBoards.map((b) => {
				return BoardsService.getTags(b.boardId).then((res) => ({
					res,
					boardId: b.boardId,
				}));
			})
		);

		const filtersMap = res.reduce((prev, curr) => {
			if (!curr.res.filters) return prev;

			prev[curr.boardId] = curr.res.filters;
			return prev;
		}, {});

		const distinctCollections = Object.keys(filtersMap).reduce(
			(prev, curr) => {
				filtersMap[curr].forEach((collection) => {
					prev[collection.filterText] = collection;
				});
				return prev;
			},
			{}
		);
		const collections = Object.keys(distinctCollections).map(
			(key) => distinctCollections[key]
		);

		return { collections };
	}
);

export const getBoards = createAsyncThunk(
	"/rehoot/getBoards",
	async ({ id: postId }) => {
		const response = await HootsServiceAlt.getBoardsForRehoot({ postId });
		const data = response.data ? response.data : [];
		return data;
	}
);

const rehootSlice = createSlice({
	name: "rehoot",
	initialState: {
		hoot: null,

		boards: [],
		selectedBoards: [],

		collections: [],
		selectedCollections: [],

		rehootStatus: "",
		boardsFetchStatus: "idle",
	},
	reducers: {
		addBoard: (state, action) => {
			state.selectedBoards.push(action.payload);
		},
		removeBoard: (state, action) => {
			state.selectedBoards = state.selectedBoards.filter(
				({ boardId }) => boardId !== action.payload.boardId
			);
		},
		addCollection: (state, action) => {
			state.selectedCollections.push(action.payload);
		},
		removeCollection: (state, action) => {
			state.selectedCollections = state.selectedCollections.filter(
				({ filterText }) => filterText !== action.payload.filterText
			);
		},
		setHoot: (state, action) => {
			state.hoot = action.payload;
		},
		close: (state) => {
			state.hoot = null;
			state.selectedBoards = [];
			state.collections = [];
			state.selectedCollections = [];
			state.rehootStatus = "";
		},
	},
	extraReducers: {
		[getBoards.fulfilled]: (state, action) => {
			state.boards = action.payload;
			state.boardsFetchStatus = "fulfilled";
		},
		[getBoards.pending]: (state) => {
			state.boardsFetchStatus = "pending";
		},
		[getBoards.rejected]: (state) => {
			state.boardsFetchStatus = "rejected";
		},

		[submit.fulfilled]: (state, action) => {
			const { response } = action.payload;
			if (response && response.status === "error") {
				state.rehootStatus = "rejected";
			} else {
				state.rehootStatus = "fulfilled";
			}
		},
		[submit.pending]: (state) => {
			state.rehootStatus = "pending";
		},
		[submit.rejected]: (state) => {
			state.rehootStatus = "rejected";
		},

		[getCollections.fulfilled]: (state, action) => {
			const { collections } = action.payload;
			state.collections = collections;
		},
		[getCollections.pending]: (state, action) => {
			const { addBoard, removeBoard } = action.meta.arg;

			if (addBoard) {
				state.selectedBoards.push(addBoard);
			}
			if (removeBoard) {
				state.selectedBoards = state.selectedBoards.filter(
					({ boardId }) => boardId !== removeBoard.boardId
				);
			}
		},
	},
});
export const {
	setHoot,
	close,
	removeBoard,
	removeCollection,
	addBoard,
	addCollection,
} = rehootSlice.actions;

export default rehootSlice.reducer;
