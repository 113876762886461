/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-24 17:10:21
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-10-21 12:10:12
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	accountsById: {},
	isMultiAuthEnabled: true,
};

const accountsSlice = createSlice({
	name: "accounts",
	initialState: initialState,
	reducers: {
		removeAll: () => {
			return initialState;
		},
		removeOneById: (state, action) => {
			let draft = { ...state.accountsById };
			delete draft[action.payload.partyId];
			return {
				...state,
				accountsById: draft,
			};
		},
		addNewAccount: (state, action) => {
			return {
				...state,
				accountsById: {
					...state.accountsById,
					[action.payload.account.partyId]: action.payload.account,
				},
			};
		},
	},
	extraReducers: {
		"auth/login/fulfilled": (state, action) => {
			return {
				...state,
				accountsById: {
					...state.accountsById,
					[action.payload.userDetails.partyId]:
						action.payload.userDetails,
				},
			};
		},
		"auth/signup/fulfilled": (state, action) => {
			return {
				...state,
				accountsById: {
					...state.accountsById,
					[action.payload.userDetails.partyId]:
						action.payload.userDetails,
				},
			};
		},
		"auth/signup/board/follow/fulfilled": (state, action) => {
			return {
				...state,
				accountsById: {
					...state.accountsById,
					[action.payload.userDetails.partyId]:
						action.payload.userDetails,
				},
			};
		},
		"user/account/update/fulfilled": (state, action) => {
			return {
				...state,
				accountsById: {
					...state.accountsById,
					[action.payload.accountDetails.partyId]:
						action.payload.accountDetails,
				},
			};
		},
	},
});
export const { removeAll, removeOneById, addNewAccount } =
	accountsSlice.actions;
export default accountsSlice.reducer;
