import { call, put, takeLatest } from "redux-saga/effects";
import { SERVER_ENV } from "../../helper/constants";

function* submitScheduleDemo(action) {
	try {
		yield put({ type: "schedule-demo/pending" });

		const _hsq = (window._hsq = window._hsq || []);

		const data = action.payload;

		_hsq.push([
			"identify",
			{
				email: data.email,
				firstname: data.firstName,
				lastname: data.lastName,
				company: data.organization,
				phone: data.phoneNum,
				url: data.url,
			},
		]);
		_hsq.push(["trackPageView"]);

		yield put({ type: "schedule-demo/fulfilled" });

		const options = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		};

		let url = "https://stagenotifications.hootboard.com/api/contactAdded";
		if (SERVER_ENV === "prod") {
			url = "https://notifications.hootboard.com/api/contactAdded";
		}

		const response = yield call(async () => await fetch(url, options));
		yield call(async () => await response.json());

		// const respJson = yield call(async () => await response.json());

		//The sending of the email is a validation for whether the email address is actually working or not
		// if (
		// 	respJson[0].status !== "sent" ||
		// 	respJson[0].reject_reason !== null
		// ) {
		// 	throw new Error(respJson[0] && respJson[0].reject_reason || "Unknown error!");
		// }
	} catch (e) {
		// yield put({ type: "schedule-demo/rejected", payload: e });
	} finally {
		yield put({ type: "schedule-demo/fulfilled" });
	}
}
export function* submitScheduleDemoSaga() {
	yield takeLatest("schedule-demo/request", submitScheduleDemo);
}
