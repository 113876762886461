/*
 * @Author: Unknown
 * @Date:   -
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-08-12 11:10:21
 */
import { httpPostV2, httpGetV2, httpPutV2 } from "./http-wrapper";

/**
 * API to create a new hoot
 * @param {number} boardId - BoardId where you want to create hoot
 * @param {Object} hootParams - Refer /helper/hoot-params.helper for the list of params
 */
const createHoot = async (boardId, hootParams) => {
	const apiConfig = {
		"x-board-id": boardId,
	};
	let response = await httpPostV2("private/hoot", hootParams, apiConfig);
	return response;
};

/**
 * API to update a hoot
 * @param {number} hootId - id of hoot which you want to update
 * @param {number} boardId - BoardId where you want to create hoot
 * @param {Object} hootParams - Refer /helper/hoot-params.helper for the list of params
 */
const updateHoot = async (boardId, hootId, hootParams) => {
	const apiConfig = {
		"x-board-id": boardId,
	};
	let response = await httpPutV2(
		`private/hoot/${hootId}`,
		hootParams,
		apiConfig
	);
	return response;
};

/**
 * API to get all board hoots
 * @param {number} start
 * @param {number} max
 * @param {boolean} upcomingEvents
 * @param {boolean} freeEvents
 * @param {Array} vicinitySet
 */
const getAllBoardHoots = async ({
	start = 0,
	max = 20,
	upcomingEvents = false,
	freeEvents = false,
}) => {
	const params = {
		start,
		max,
		upcomingEvents,
		freeEvents,
	};
	let apiEndpoint = "public/hoots/all";
	if (localStorage.getItem("authToken")) {
		apiEndpoint = "private/hoots/all";
	}
	let response = await httpGetV2(apiEndpoint, params, null);
	return response;
};

/**
 * API to get hoots created by user
 * @param {number} start
 * @param {number} max
 * @param {boolean} upcomingEvents
 * @param {boolean} freeEvents
 */
const getUserHoots = async ({
	start = 0,
	max = 20,
	upcomingEvents = false,
	freeEvents = false,
}) => {
	const params = {
		start,
		max,
		upcomingEvents,
		freeEvents,
	};

	let response = await httpGetV2("private/hoots", params, null);
	return response;
};

/**
 * API to get user favorite hoots
 * @param {number} start
 * @param {number} max
 * @param {boolean} upcomingEvents
 * @param {boolean} freeEvents
 */
const getUserFavoriteHoots = async ({ start = 0, max = 20 }) => {
	const params = { start, max };
	let response = await httpGetV2("private/user/fav/hoots", params, null);
	return response;
};

/**
 * API to get hoots from boards that are pinned by the user
 * @param {number} start
 * @param {number} max
 * @param {boolean} upcomingEvents
 * @param {boolean} freeEvents
 */
const getHootsFromPinnedBoards = async ({
	start = 0,
	max = 20,
	upcomingEvents = false,
	freeEvents = false,
}) => {
	const params = {
		start,
		max,
		upcomingEvents,
		freeEvents,
	};
	let response = await httpGetV2("private/hoots/boards/pinned", params, null);
	return response;
};

/**
 * API to get boards to rehoot
 * @param {number} hootId - hoot id which you want to get board to rehoot.
 */
const getBoardsForRehoot = async ({ postId }) => {
	const params = {
		postId,
	};
	let response = await httpGetV2("private/hoot/rehoot/boards", params, null);
	return response;
};

export {
	getHootsFromPinnedBoards,
	createHoot,
	updateHoot,
	getAllBoardHoots,
	getUserHoots,
	getUserFavoriteHoots,
	getBoardsForRehoot,
};
