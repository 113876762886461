/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-24 20:47:23
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-06-12 10:07:47
 */

import { call, put, takeLatest } from "redux-saga/effects";
import * as BoardApi from "../../api/boards.service";
import * as UserApi from "../../api/users.service";

function* userBoardsList() {
	try {
		yield put({ type: "user/boards/list/pending", payload: {} });
		const userBoardsRequest = yield call(
			async () => await UserApi.getUserBoards()
		);
		if (userBoardsRequest) {
			yield put({
				type: "user/boards/list/fulfilled",
				payload: { userBoards: userBoardsRequest },
			});
		} else {
			yield put({
				type: "user/boards/list/rejected",
				payload: { userBoards: [] },
			});
		}
	} catch (e) {
		yield put({ type: "user/boards/list/rejected", payload: {} });
	}
}

export function* userBoardsListSaga() {
	yield takeLatest("user/boards/list/request", userBoardsList);
}

function* pinBoard(action) {
	try {
		yield put({ type: "user/board/pin/pending", payload: {} });
		const userBoardsRequest = yield call(
			async () => await BoardApi.pinBoard(action.payload.boardId)
		);
		if (userBoardsRequest) {
			yield put({
				type: "user/boards/list/request",
				payload: {},
			});
			yield put({
				type: "user/board/pin/fulfilled",
				payload: { pinned: true },
			});
		} else {
			yield put({
				type: "user/board/pin/rejected",
				payload: { pinned: false },
			});
		}
	} catch (e) {
		yield put({ type: "user/board/pin/rejected", payload: {} });
	}
}

export function* pinBoardSaga() {
	yield takeLatest("user/board/pin/request", pinBoard);
}

function* unPinBoard(action) {
	try {
		yield put({ type: "user/board/unPin/pending", payload: {} });
		const userBoardsRequest = yield call(
			async () => await BoardApi.unPinBoard(action.payload.boardId)
		);
		if (userBoardsRequest) {
			// yield put({
			// 	type: "user/boards/list/request",
			// 	payload: {}
			// });
			yield put({
				type: "user/board/unPin/fulfilled",
				payload: { unPinned: true },
			});
		} else {
			yield put({
				type: "user/board/unPin/rejected",
				payload: { unPinned: false },
			});
		}
	} catch (e) {
		yield put({ type: "user/board/unPin/rejected", payload: {} });
	}
}

export function* unPinBoardSaga() {
	yield takeLatest("user/board/unPin/request", unPinBoard);
}
