import { call, put, takeLatest } from "redux-saga/effects";

import * as API from "../../api/users.service";

export const Actions = {
    getAllTourStatuses: "product-tour/get-all-tour-statuses/",
    markTourAsFinished: "product-tour/mark-tour-as-finished/",
    markTourAsSkipped: "product-tour/mark-tour-as-skipped/",
};

function* markTourAsSkippedSaga() {
    yield takeLatest(Actions.markTourAsSkipped + "request", function* (action) {
        try {
            yield put({
                type: Actions.markTourAsSkipped + "pending",
            });
            const onboardingTourName = action.payload;
            const apiResponse = yield call(async () => {
                return await API.markOnBoardingStatus({
                    onboardingTourName,
                    status: "skipped",
                });
            });
            if (apiResponse) {
                yield put({
                    type: Actions.markTourAsSkipped + "fulfilled",
                    payload: apiResponse,
                });
            } else {
                throw new Error(apiResponse);
            }
        } catch (e) {
            yield put({
                type: Actions.markTourAsSkipped + "rejected",
                payload: e,
            });
        }
    });
}

function* markTourAsFinishedSaga() {
    yield takeLatest(Actions.markTourAsFinished + "request", function* (action) {
        try {
            yield put({
                type: Actions.markTourAsFinished + "pending",
            });
            const onboardingTourName = action.payload;
            const apiResponse = yield call(async () => {
                return await API.markOnBoardingStatus({
                    onboardingTourName,
                    status: "completed",
                });
            });
            if (apiResponse) {
                yield put({
                    type: Actions.markTourAsFinished + "fulfilled",
                    payload: apiResponse,
                });
            } else {
                throw new Error(apiResponse);
            }
        } catch (e) {
            yield put({
                type: Actions.markTourAsFinished + "rejected",
                payload: e,
            });
        }
    });
}

function* getAllTourStatusesSaga() {
    yield takeLatest(Actions.getAllTourStatuses + "request", function* () {
        try {
            yield put({
                type: Actions.getAllTourStatuses + "pending",
            });
            const apiResponse = yield call(async () => {
                return await API.getOnBoardingStatuses();
            });
            if (apiResponse) {
                yield put({
                    type: Actions.getAllTourStatuses + "fulfilled",
                    payload: apiResponse
                });
            } else {
                throw new Error(apiResponse);
            }
        } catch (e) {
            yield put({
                type: Actions.getAllTourStatuses + "rejected",
                payload: e,
            });
        }
    });
}

export const productTourSagas = [
    getAllTourStatusesSaga(),
    markTourAsSkippedSaga(),
    markTourAsFinishedSaga(),
];
