import { all } from "redux-saga/effects";

import { authSagas } from "../../components/Auth/auth.saga";
import {
	changeBoardAccessSaga,
	followBoardSaga,
	requestBoardAccessSaga,
} from "../../components/BoardAccessModal/boardAccess.saga";
import {
	getBoardNotificationSettingsSaga,
	updateBoardNotificationSettingsSaga,
} from "../../components/BoardNotifications/boardNotifications.saga";
import { changeLogSaga } from "../../components/ChangeLog/changeLog.saga";
import { createBoardSagas } from "../../components/CreateBoard/createBoard.saga";
import {
	getAccountSaga,
	testPasswordSaga,
	updateAccountSaga,
	updateEmailSaga,
	updatePasswordSaga,
} from "../../components/MyAccount/myAccount.saga";
import {
	pinBoardSaga,
	unPinBoardSaga,
	userBoardsListSaga,
} from "../../components/MyBoardsNew/myboards.saga";
import {
	getPersonalNotificationSettingsSaga,
	updatePersonalNotificationSettingsSaga,
} from "../../components/NotificationSettings/notificationSettings.saga";
import { submitScheduleDemoSaga } from "../../components/ScheduleDemo/scheduleDemo.saga";
import { activateAccountSaga } from "../../pages/common/ActivateAccount.page/activateAccount.saga";
import { publicResetPasswordSaga } from "../../pages/common/ResetPassword.page/resetPassword.saga";
import {
	getBoardDetailsSaga,
	unSubscribeBoardSaga,
	unSubscribeInvitationSaga,
	unSubscribePersonalSaga,
} from "../../pages/common/UnSubscribe.page/unSubscribe.saga";
import { verifyEmailSaga } from "../../pages/common/VerifyEmail.page/verifyEmail.saga";

import { createScreenSaga } from "../../components/CreateScreenNew/createScreen.saga";
import {
	deleteBoardScreenMessagesSaga,
	getCustomMessagesSaga,
	saveBoardScreenMessagesSaga,
} from "../../components/ManageCustomMessage/manageCustomMessage.saga";
import {
	deleteScreenSaga,
	updateScreenSaga,
} from "../../components/UpdateScreen/updateScreen.saga";

import {
	getNotificationsSaga,
	readNotificationsSaga,
} from "../../components/ActivityFeed/activityFeed.saga";
import { activeBoardSagas } from "./activeBoard.saga";

import { manageScreensSaga } from "../../components/ManageScreensNew/manageScreens.saga";
import { userDetailsSaga } from "./activeAccount.saga";

import {
	hootsFromAllBoardsSaga,
	moreHootsFromAllBoardsSaga,
} from "../../components/HootsFromAllBoards/allBoardHoots.saga";
import {
	hootsFromPinnedBoardsSaga,
	moreHootsFromPinnedBoardsSaga,
} from "../../components/HootsFromPinnedBoards/pinnedBoardHoots.saga";
import { customUrlSaga } from "./customUrl.saga";

import { calendarSagas } from "../../components/Calendar/calendar.saga";

import { analyticsSagas } from "../../components/Analytics/analytics.saga";
import { sequencesSagas } from "../../components/ContentSequence/sequences.saga";
import { listSagas } from "../../components/Lists/lists.saga";
import { manageBoardSagas } from "../../components/ManageMyBusiness/manageMyBusiness.saga";
import { appStoreSagas } from "../../components/ManageScreenApps/appStore.saga";
import { manageScreenAppsSagas } from "../../components/ManageScreenApps/manageScreenApps.saga";
import {
	messagingConversationsSaga,
	messagingSendReplySaga,
	messagingSendSaga,
	messagingSetReadSaga,
	messagingUnreadCountSaga,
} from "../../components/Messaging/messaging.saga";
import { onBoardingSagas } from "../../components/OnBoarding/onBoarding.saga";
import { pricingSagas } from "../../components/Pricing/pricing.saga.js";
import { productTourSagas } from "../../components/ProductTour/productTour.saga";
import { bulletinBoardSagas } from "../../components/ResponsiveBulletinBoard/bulletinBoard.saga";
import { sharingSagas } from "./sharing.saga";
import { manageMembersSagas } from "../../components/ManageMembers/manageMembers.saga";

export default function* rootSaga() {
	yield all([
		changeLogSaga(),
		submitScheduleDemoSaga(),
		verifyEmailSaga(),
		activateAccountSaga(),
		publicResetPasswordSaga(),
		userBoardsListSaga(),
		getAccountSaga(),
		updateAccountSaga(),
		getPersonalNotificationSettingsSaga(),
		updatePersonalNotificationSettingsSaga(),
		getBoardNotificationSettingsSaga(),
		updateBoardNotificationSettingsSaga(),
		testPasswordSaga(),
		updatePasswordSaga(),
		updateEmailSaga(),
		changeBoardAccessSaga(),
		requestBoardAccessSaga(),
		pinBoardSaga(),
		unPinBoardSaga(),
		followBoardSaga(),
		createScreenSaga(),
		getCustomMessagesSaga(),
		saveBoardScreenMessagesSaga(),
		deleteBoardScreenMessagesSaga(),
		updateScreenSaga(),
		getNotificationsSaga(),
		readNotificationsSaga(),
		deleteScreenSaga(),
		userDetailsSaga(),
		customUrlSaga(),
		hootsFromPinnedBoardsSaga(),
		moreHootsFromPinnedBoardsSaga(),
		hootsFromAllBoardsSaga(),
		moreHootsFromAllBoardsSaga(),
		unSubscribeBoardSaga(),
		unSubscribePersonalSaga(),
		unSubscribeInvitationSaga(),
		messagingSendSaga(),
		messagingConversationsSaga(),
		messagingSendReplySaga(),
		messagingSetReadSaga(),
		messagingUnreadCountSaga(),
		getBoardDetailsSaga(),
		...manageScreensSaga,
		...analyticsSagas,
		...activeBoardSagas,
		...authSagas,
		...calendarSagas,
		...createBoardSagas,
		...pricingSagas,
		...appStoreSagas,
		...bulletinBoardSagas,
		...manageScreenAppsSagas,
		...manageBoardSagas,
		...productTourSagas,
		...onBoardingSagas,
		...sharingSagas,
		...listSagas,
		...sequencesSagas,
		...manageMembersSagas,
	]);
}
