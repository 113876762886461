import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "../../api/app-store.service";

export const Actions = {
	unPublishApp: "app-store/un-publish-app/",
	publishApp: "app-store/publish-app/",
	updatePublishedApp: "app-store/update-published-app/",
	getAppDirectory: "app-store/get-app-directory/",
	installApp: "app-store/install-app/",
	getBoardAppDirectory: "app-store/get-board-app-directory/",
	getRecommendedApps: "app-store/get-recommended-apps/",
};

function* unPublishAppSaga() {
	const base = Actions.unPublishApp;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId, appId } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(async () => {
				return await API.unPublishApp({ boardId, appId });
			});

			if (apiResponse && apiResponse.message === "success") {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse.data,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

function* publishAppSaga() {
	const base = Actions.publishApp;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId, payload } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(async () => {
				return await API.publishApp({ boardId, payload });
			});

			if (apiResponse && apiResponse.message === "success") {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse.data,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

function* updatePublishedAppSaga() {
	const base = Actions.updatePublishedApp;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId, appUUID, payload } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(async () => {
				return await API.updatePublishedApp({
					boardId,
					appUUID,
					payload,
				});
			});

			if (apiResponse && apiResponse.message === "success") {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse.data,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

function* getAppDirectorySaga() {
	const base = Actions.getAppDirectory;

	yield takeLatest(base + "request", function* (action) {
		try {
			yield put({ type: base + "pending" });

			const apiResponse = yield call(async () => {
				return await API.getAppDirectory(action.payload);
			});

			if (apiResponse && apiResponse.message === "success") {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse.data,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

function* installAppSaga() {
	const base = Actions.installApp;

	yield takeLatest(base + "request", function* (action) {
		try {
			yield put({ type: base + "pending" });

			const { boardId, payload } = action.payload;

			const apiResponse = yield call(async () => {
				return await API.installApp({ boardId, ...payload });
			});

			if (apiResponse && apiResponse.message === "success") {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse.data,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

function* getBoardAppDirectorySaga() {
	const base = Actions.getBoardAppDirectory;

	yield takeLatest(base + "request", function* (action) {
		try {
			yield put({ type: base + "pending" });

			const { boardId } = action.payload;

			const apiResponse = yield call(async () => {
				return await API.getBoardAppDirectory({ boardId });
			});

			if (apiResponse && apiResponse.message === "success") {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse.data,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

function* getRecommendedAppsSaga() {
	const base = Actions.getRecommendedApps;

	yield takeLatest(base + "request", function* (action) {
		try {
			yield put({ type: base + "pending" });

			const boardId = action.payload;

			const apiResponse = yield call(async () => {
				return await API.getRecommendedApps(boardId);
			});

			if (!apiResponse) throw new Error(apiResponse);
			if (!apiResponse.message) throw new Error(apiResponse);

			yield put({
				type: base + "fulfilled",
				payload: apiResponse.data || [],
			});
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

export const appStoreSagas = [
	publishAppSaga(),
	installAppSaga(),
	unPublishAppSaga(),
	getAppDirectorySaga(),
	updatePublishedAppSaga(),
	getRecommendedAppsSaga(),
	getBoardAppDirectorySaga(),
];
