/*
 * @Author: snair@hootboard.com
 * @Date: 2019-02-16 01:18:43
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-08-23 15:45:11
 */

import ColorChecker from "./color.utils";

//  Scheme of comment

// let comment = {
// 	commentText: null,
// 	postId: null,
// 	partyId: null,
// 	visitId: null,
// 	commentNeedApproval: false,
// 	mentionsIds: null,
// 	boardId: null,
// 	commenterName: "",
// 	commenterImg: "",
// 	commentId: null,
// 	commenterId: null,
// 	approved: false,
// 	commenterPicExists: false,
// 	commenterInitials: "",
// 	commenterColorCss: "",
// 	comment: null,
// 	commentPlainText: null,
// 	commentTime: "",
// 	pinnedComment: false,
// 	pinnedCommentTime: ""
// };

export const transformComments = (comment) => {
	return {
		id: comment.commentId,
		authorPartyId: comment.commenterId,
		isPinned: comment.pinnedComment ? comment.pinnedComment : false,
		postedTime: comment.commentTime,
		requiresApproval: !comment.approved ? !comment.approved : false,
		...ColorChecker.userColors[comment.commenterColorCss],
		...parseCommentText(comment),
		...parseAuthorInfo(comment),
	};
};

function parseCommentText(comment) {
	return {
		commentWithHtml: comment.comment,
		plainTextComment: comment.commentPlainText,
	};
}

function parseAuthorInfo(comment) {
	return {
		authorPictureUrl: comment.commenterImg,
		authorName: comment.commenterName,
		authorInitials: comment.commenterInitials,
	};
}
