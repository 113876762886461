export function memberTypeFromCode(memberAccessCode) {
	switch (memberAccessCode) {
		case 1:
			return "ME";
		case 2:
			return "AA";
		case 3:
			return "";
		case 4:
			return "FL";
		case 5:
			return "PV";

		default:
			return "";
	}
}

export function memberTypeFullString(memberType) {
	switch (memberType) {
		case "AA":
			return "Admin";
		case "FL":
			return "Follower";
		case "PV":
			return "Private Viewer";
		case "SA":
			return "Super Admin";
		case "ME":
			return "Poster";
		default:
			return memberType;
	}
}
