import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { MixpanelProvider } from "./contexts/mixpanel.context";
import { OnBoardingProvider } from "./contexts/onBoarding.context";
import { SocketProvider } from "./contexts/socket.context";

import { ErrorBoundary } from "./components/ErrorBoundary";
import { LazyLoader } from "./components/LazyLoader";
import { SmartAppBanners } from "./components/SmartAppBanners";

import "./index.css";
import store from "./redux";

const App = React.lazy(() => import("./App"));

ReactDOM.createRoot(document.getElementById("root")).render(
    <ErrorBoundary>
        <Provider store={store}>
            <MixpanelProvider>
                <SocketProvider>
                    <BrowserRouter>
                        <OnBoardingProvider>
                            <SmartAppBanners />
                            <LazyLoader>
                                <App />
                            </LazyLoader>
                        </OnBoardingProvider>
                    </BrowserRouter>
                </SocketProvider>
            </MixpanelProvider>
        </Provider>
    </ErrorBoundary>,
);
