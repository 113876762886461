/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-12 11:34:43
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-10-01 12:51:31
 */

import { createSlice } from "@reduxjs/toolkit";

const visibilitySlice = createSlice({
	name: "visibility",
	initialState: {
		isHomePageActive: false,
		publicDrawerHeight: 0,
		isMobileView: true,
		isLeaveBoardConfirmationModalVisible: false,
		isOnboardingModalVisibile: true,
		isBoardHeaderVisible: true,
		isMiniBoardHeaderVisible: false,
		isBoardAppsGridVisible: false,
		isBoardAppsDockVisible: true,
		isSideMenuVisible: false,
		isSideDockVisible: false,
		isPersonalNavigationOpen: false,
		isHeaderVisible: false,
		isBoardAppsVisible: false,
		masonryReRenderCount: 0,
		isUserMenuVisible: false,
		isLeftDrawerVisible: false,
		isSideBarActivityFeedVisible: false,
		isBoardAccessModalVisible: false,
		updateScreenModalVisibility: "hidden",
		updateCustomMessageModalVisibility: "hidden",
		confirmationModalVisibility: "hidden",
		postHootModalVisibility: "hidden",
		activityFeedDrawerVisibility: "hidden",
		slideOutMarketingDrawerVisibility: "hidden",
		authModalVisibility: "hidden",
		accountEmailValidationPromptVisibility: "hidden",
		calendarVisibility: "hidden",
		boardAccessModalVisibility: "hidden",
		isPrivacyShieldActive: false,
		//
		smartAppBannerPath: null,
		isSmartAppBannerVisible: false,
		isBoardSubscriberDownloadModalVisible: false,
	},
	reducers: {
		setSmartAppBannerPath: (state, action) => {
			state.smartAppBannerPath = action.payload;
		},
		toggleSmartAppBanner: (state, action) => {
			state.isSmartAppBannerVisible = !!action.payload;
		},
		setIsPrivacyShieldActive: (state, action) => {
			state.isPrivacyShieldActive = action.payload;
		},
		toggleLeaveBoardConfirmationModal: (state, action) => {
			state.isLeaveBoardConfirmationModalVisible = action.payload;
		},
		setHomePageActive: (state, action) => {
			state.isHomePageActive = action.payload;
		},
		setLeftDrawerVisibility: (state, action) => {
			state.isLeftDrawerVisible = action.payload;
		},
		toggleMobileView: (state, action) => {
			state.isMobileView = action.payload;
		},
		setPublicDrawerHeight: (state, action) => {
			state.publicDrawerHeight = action.payload;
		},
		setOnboardingModalVisibility: (state, action) => {
			state.isOnboardingModalVisibile = action.payload;
		},
		setBoardAccessModalVisibility: (state, action) => {
			return {
				...state,
				boardAccessModalVisibility: action.payload.visibility,
			};
		},
		setCalendarVisibility: (state, action) => {
			return {
				...state,
				calendarVisibility: action.payload.visibility,
			};
		},
		setAuthModalVisibility: (state, action) => {
			return {
				...state,
				authModalVisibility: action.payload.visibility,
			};
		},
		setAccountEmailValidationPromptVisibility: (state, action) => {
			return {
				...state,
				accountEmailValidationPromptVisibility:
					action.payload.visibility,
			};
		},
		setSlideOutMarketingDrawerVisibility: (state, action) => {
			return {
				...state,
				slideOutMarketingDrawerVisibility: action.payload.visibility,
			};
		},
		setActivityFeedDrawerVisibility: (state, action) => {
			return {
				...state,
				activityFeedDrawerVisibility: action.payload.visibility,
			};
		},
		setConfirmationModalVisibility: (state, action) => {
			return {
				...state,
				confirmationModalVisibility: action.payload.visibility,
			};
		},
		setUpdateScreenModalVisibility: (state, action) => {
			return {
				...state,
				updateScreenModalVisibility: action.payload.visibility,
			};
		},
		setUpdateCustomMessageModalVisibility: (state, action) => {
			return {
				...state,
				updateCustomMessageModalVisibility: action.payload.visibility,
			};
		},
		showBoardAccessModal: (state) => {
			return {
				...state,
				isBoardAccessModalVisible: true,
			};
		},
		hideBoardAccessModal: (state) => {
			return {
				...state,
				isBoardAccessModalVisible: false,
			};
		},
		showSideBarActivityFeed: (state) => {
			return {
				...state,
				isSideBarActivityFeedVisible: true,
			};
		},
		hideSideBarActivityFeed: (state) => {
			return {
				...state,
				isSideBarActivityFeedVisible: false,
			};
		},
		showUserMenu: (state) => {
			return {
				...state,
				isUserMenuVisible: true,
			};
		},
		hideUserMenu: (state) => {
			return {
				...state,
				isUserMenuVisible: false,
			};
		},
		showDefaultHeader: (state) => {
			return {
				...state,
				isDefaultHeaderVisible: true,
				isMiniBoardHeaderVisible: false,
			};
		},
		toggleBoardAppsView: (state) => {
			return {
				...state,
				isBoardAppsDockVisible: !state.isBoardAppsDockVisible,
				isBoardAppsGridVisible: !state.isBoardAppsGridVisible,
			};
		},
		masonryReRendered: (state) => {
			return {
				...state,
				masonryReRenderCount: state.masonryReRenderCount + 1,
			};
		},
		showBoardApps: (state) => {
			return {
				...state,
				isBoardAppsVisible: true,
			};
		},
		showHeader: (state) => {
			return {
				...state,
				isHeaderVisible: true,
			};
		},
		showPersonalNavigation: (state) => {
			return {
				...state,
				isPersonalNavigationOpen: true,
			};
		},
		hidePersonalNavigation: (state) => {
			return {
				...state,
				isPersonalNavigationOpen: false,
			};
		},
		showBoardHeader: (state) => {
			return {
				...state,
				isMiniHeaderMiniVisible: false,
				isBoardHeaderVisible: true,
			};
		},
		showMiniBoardHeader: (state) => {
			return {
				...state,
				isMiniBoardHeaderVisible: true,
				isBoardHeaderVisible: false,
			};
		},
		showBoardAppsGrid: (state) => {
			return {
				...state,
				isBoardAppsDockVisible: false,
				isBoardAppsGridVisible: true,
			};
		},
		showBoardAppsDock: (state) => {
			return {
				...state,
				isBoardAppsGridVisible: false,
				isBoardAppsDockVisible: true,
			};
		},
		setPostHootModalVisibility: (state, action) => {
			return {
				...state,
				postHootModalVisibility: action.payload.visibility,
			};
		},
		toggleBoardSubscriberDownloadModal: (state, action) => {
			return {
				...state,
				isBoardSubscriberDownloadModalVisible: action.payload,
			};
		},
	},
	extraReducers: {
		"app/selectBoardMenu": (state, action) => {
			if (action.payload === "posts") {
				return {
					...state,
					isBoardHeaderVisible: true,
					isMiniBoardHeaderVisible: false,
				};
			} else {
				return {
					...state,
					isBoardHeaderVisible: false,
					isMiniBoardHeaderVisible: true,
				};
			}
		},
		"app/selectBoard": (state) => {
			return {
				...state,
				isBoardHeaderVisible: true,
				isMiniBoardHeaderVisible: false,
			};
		},
	},
});
export const {
	setSmartAppBannerPath,
	toggleSmartAppBanner,
	setIsPrivacyShieldActive,
	setHomePageActive,
	toggleMobileView,
	setPublicDrawerHeight,
	toggleLeaveBoardConfirmationModal,
	setOnboardingModalVisibility,
	toggleBoardAppsView,
	showDefaultHeader,
	showBoardHeader,
	showMiniBoardHeader,
	showBoardAppsGrid,
	showBoardAppsDock,
	showPersonalNavigation,
	hidePersonalNavigation,
	showBoardApps,
	showHeader,
	masonryReRendered,
	showUserMenu,
	hideUserMenu,
	showSideBarActivityFeed,
	hideSideBarActivityFeed,
	showBoardAccessModal,
	hideBoardAccessModal,
	setUpdateScreenModalVisibility,
	setUpdateCustomMessageModalVisibility,
	setConfirmationModalVisibility,
	setPostHootModalVisibility,
	setLeftDrawerVisibility,
	setActivityFeedDrawerVisibility,
	setSlideOutMarketingDrawerVisibility,
	setAccountEmailValidationPromptVisibility,
	setAuthModalVisibility,
	setBoardAccessModalVisibility,
	toggleBoardSubscriberDownloadModal,
} = visibilitySlice.actions;

export default visibilitySlice.reducer;
