/*
 * @Author: Chandu J S
 * @Date:   2020-08-28 15:43:02
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-09-03 21:14:42
 */
import { createSlice } from "@reduxjs/toolkit";
import { InputValidators } from "../../utils/inputValidators.util";

const initialState = {
	isError: false,
	isLoading: false,
	isFinished: false,
	isScheduleDemoModelOpen: false,
	isOkToSubmit: false,
	errors: {
		firstName: "First name is required",
		lastName: "Last name is required",
		email: "Please enter an email address",
		phone: "Phone number is required",
		details: "Details required",
		organization: "Organization name required",
	},
	localState: {
		// Have a look at the createSlice
	},
};

const scheduleDemo = createSlice({
	name: "scheduleDemo",
	initialState: {
		...initialState,
		// Getting value of all fields as empty value.
		// Populated from errors because the members are same
		localState: Object.keys(initialState.errors).reduce(
			(localState, key) => {
				return { ...localState, [key]: "" };
			},
			{}
		),
	},
	reducers: {
		toggleScheduleDemoModel: (state) => {
			return {
				...initialState,
				isScheduleDemoModelOpen: !state.isScheduleDemoModelOpen,
			};
		},
		inputChange: (state, action) => {
			const data = action.payload;
			state.localState = {
				...state.localState,
				...data,
			};
			// Validate
			const validFields = Object.keys(state.localState).filter((key) => {
				return state.localState[key] !== "";
			});
			const nullErrors = {};
			for (const key of validFields) {
				nullErrors[key] = null;
			}
			state.errors = {
				...state.errors,
				...nullErrors,
			};
			if (data.phone && !state.errors.phone) {
				const phoneNumberValidation = InputValidators.phoneNumber(
					data.phone
				);
				state.errors.phone = phoneNumberValidation.hasError
					? phoneNumberValidation.errorMessage
					: null;
			}
			if (data.email && !state.errors.email) {
				const emailRegex =
					/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
				if (!emailRegex.test(data.email)) {
					state.errors.email = "Invalid email id";
				}
			}
			state.isOkToSubmit =
				Object.keys(state.errors).filter(
					(key) => state.errors[key] !== null
				).length === 0;
		},
	},
	extraReducers: {
		"schedule-demo/pending": (state) => {
			state.isError = false;
			state.isLoading = true;
			state.isFinished = false;
		},
		"schedule-demo/fulfilled": (state) => {
			state.isError = false;
			state.isLoading = false;
			state.isFinished = true;
		},
		"schedule-demo/rejected": (state) => {
			state.isError = true;
			state.isLoading = false;
			state.isFinished = true;
		},
	},
});

export const submitScheduleDemo = (payload) => {
	return {
		type: "schedule-demo/request",
		payload,
	};
};

export const { inputChange, toggleScheduleDemoModel } = scheduleDemo.actions;

export default scheduleDemo.reducer;
