/*
 * @Author: Chandu J S
 * @Date:   2020-08-12 13:33:43
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-09-04 10:59:27
 */
import { createSlice } from "@reduxjs/toolkit";
import { transformHoots } from "../../utils/transformHoots.util";

export const refreshData = () => {
	return {
		type: "boards/all/hoots/request",
		payload: {
			params: {
				start: 0,
				max: 40,
				upcomingEvents: false,
				freeEvents: false,
			},
		},
	};
};

export const fetchInitialHoots = (payload) => {
	return {
		type: "boards/all/hoots/request",
		payload,
	};
};

export const fetchMoreHoots = (payload) => {
	return {
		type: "boards/all/hoots/more/request",
		payload,
	};
};

// export const refreshData = createAsyncThunk(
// 	'/bulletinBoard/refreshData',
// 	async (x, thunkApi) => {
// 		const {
// 			bulletinBoard: { fetchHootParams, hoots, selectedCollections },
// 			activeBoard,
// 		} = thunkApi.getState();

// 		const params = {
// 			...fetchHootParams,
// 			start: 0,
// 			max: hoots.length,
// 		};

// 		if (params.searchString === '') {
// 			const response = await BoardsService.getHoots(
// 				activeBoard.boardId,
// 				params,
// 			);
// 			return {
// 				response,
// 				fetchHootParams: params,
// 				boardId: activeBoard.boardId,
// 				selectedCollections,
// 			};
// 		}

// 		return {
// 			response,
// 			fetchHootParams: params,
// 			boardId: activeBoard.boardId,
// 			selectedCollections,
// 		};
// 	},
// );

const initialState = {
	allBoardHoots: [],
	initialHootsFetchStatus: "idle",
	fetchMoreHootsStatus: "idle",
	shouldFetchMore: false,
	error: false,
	errorMessage: "",
	page: 0,
	max: 40,
	upcomingEvents: false,
	freeEvents: false,
};

const allBoardHootsSlice = createSlice({
	name: "allBoardHoots",
	initialState: initialState,
	reducers: {
		reset: () => {
			return initialState;
		},
		setPage: (state, action) => {
			return {
				...state,
				page: action.payload.page,
			};
		},
		updateHootPinned: (state, action) => {
			const { pin, selectedHootIndex } = action.payload;

			state.allBoardHoots[selectedHootIndex].isPinned = pin;
		},
		updateHootRehoot: (state, action) => {
			const { selectedHootIndex } = action.payload;

			state.allBoardHoots[selectedHootIndex].hootStats.rehoots += 1;
		},
		updateHootFavorite: (state, action) => {
			const { selectedHootIndex, favorite } = action.payload;

			state.allBoardHoots[selectedHootIndex].favPost = favorite;
			state.allBoardHoots[selectedHootIndex].hootStats.favorites +=
				favorite ? 1 : -1;
		},
		updateHootApproved: (state, action) => {
			const { selectedHootIndex, selectedHoot } = action.payload;

			if (selectedHootIndex) {
				state.allBoardHoots[selectedHootIndex].requiresApproval = false;
			} else {
				const index = state.allBoardHoots.findIndex(
					({ id }) => id === selectedHoot.id
				);
				state.allBoardHoots[index].requiresApproval = false;
			}
		},
		updateHootRejected: (state, action) => {
			const { selectedHootIndex, selectedHoot } = action.payload;

			if (selectedHootIndex) {
				state.allBoardHoots.splice(selectedHootIndex, 1);
			} else {
				const index = state.allBoardHoots.findIndex(
					({ id }) => id === selectedHoot.id
				);
				state.allBoardHoots.splice(index, 1);
			}
		},
		updateHootCommentCount: (state, action) => {
			const { selectedHootIndex, selectedHoot } = action.payload;

			if (selectedHootIndex) {
				state.allBoardHoots[selectedHootIndex].hootStats.comments += 1;
			} else {
				const index = state.allBoardHoots.findIndex(
					({ id }) => id === selectedHoot.id
				);
				state.allBoardHoots[index].hootStats.comments += 1;
			}
		},
		rehooted: (state, action) => {
			const index = state.allBoardHoots.findIndex(
				({ id }) => id === action.payload.id
			);

			if (index > -1) {
				state.allBoardHoots[index].hootStats.rehoots += 1;
			}
		},
	},
	extraReducers: {
		"saved/hoots/saveLocal/request": (state, action) => {
			const hootIds = action.payload.saveForLaterData.hoots;
			return {
				...state,
				allBoardHoots: state.allBoardHoots.map((hoot) => {
					if (hootIds.includes(hoot.id)) {
						return { ...hoot, isSaved: true };
					}
					return hoot;
				}),
			};
		},
		"saved/hoots/unsave/request": (state, action) => {
			const { hootId } = action.payload;
			return {
				...state,
				allBoardHoots: state.allBoardHoots.map((hoot) => {
					if (hoot.id === hootId) {
						return { ...hoot, isSaved: false };
					}
					return hoot;
				}),
			};
		},
		"boards/all/hoots/pending": (state) => {
			return {
				...state,
				initialHootsFetchStatus: "pending",
				allBoardHoots: [],
			};
		},
		"boards/all/hoots/fulfilled": (state, action) => {
			if (action.payload.hoots && action.payload.hoots.length) {
				return {
					...state,
					page: state.page + 1,
					initialHootsFetchStatus: "fulfilled",
					allBoardHoots: action.payload.hoots
						.map(transformHoots)
						// TODO: Need to fix approval issue from API
						.map((item) => {
							return { ...item, requiresApproval: false };
						}),
					shouldFetchMore:
						action.payload.hoots.length > state.max - 2,
				};
			} else {
				return {
					...state,
					initialHootsFetchStatus: "fulfilled",
					allBoardHoots: [],
					shouldFetchMore: false,
				};
			}
		},
		"boards/all/hoots/rejected": (state) => {
			return {
				...state,
				initialHootsFetchStatus: "rejected",
				allBoardHoots: [],
				error: true,
				errorMessage: "Failed to fetch hoots",
			};
		},
		"boards/all/hoots/more/pending": (state) => {
			return {
				...state,
				fetchMoreHootsStatus: "pending",
			};
		},
		"boards/all/hoots/more/fulfilled": (state, action) => {
			if (action.payload.hoots && action.payload.hoots.length) {
				return {
					...state,
					fetchMoreHootsStatus: "fulfilled",
					allBoardHoots: [
						...state.allBoardHoots,
						...action.payload.hoots
							.map(transformHoots)
							// TODO: Need to fix approval issue from API
							.map((item) => {
								return { ...item, requiresApproval: false };
							}),
					],
					page: state.page + 1,
					shouldFetchMore:
						action.payload.hoots.length > state.max - 2,
				};
			} else {
				return {
					...state,
					page: state.page + 1,
					fetchMoreHootsStatus: "fulfilled",
					shouldFetchMore: false,
				};
			}
		},
		"boards/all/hoots/more/rejected": (state) => {
			return {
				...state,
				fetchMoreHootsStatus: "rejected",
				allBoardHoots: [],
				error: true,
				errorMessage: "Failed to fetch hoots",
			};
		},
	},
});
export const {
	rehooted,
	updateHootApproved,
	updateHootCommentCount,
	updateHootFavorite,
	updateHootPinned,
	updateHootRehoot,
	updateHootRejected,
} = allBoardHootsSlice.actions;
export default allBoardHootsSlice.reducer;
