import { useDispatch, useSelector } from "react-redux";
//
import { HealthyImage } from "../hb-ui/HealthyImage";
//
import { setSmartAppBannerPath } from "../redux/visibility.slice";
//

export const useSmartAppBanners = () => {
	const dispatch = useDispatch();

	const deepLinkPath: string = useSelector(
		(state: any) => state.visibility.smartAppBannerPath || ""
	);

	/**
	 * To identify the platform which the app is opened.
	 */
	const platformName: "ios-safari" | "android" | "ios-other" | undefined =
		(() => {
			const userAgent = navigator.userAgent;
			if (/Android/i.test(userAgent)) {
				return "android";
			} else if (
				/iPhone|iPad|iPod/i.test(userAgent) &&
				/Safari/i.test(userAgent)
			) {
				return "ios-safari";
			} else if (/iPhone|iPad|iPod/i.test(userAgent)) {
				return "ios-other";
			}
		})();

	const openInApp = (e?: any) => {
		e?.preventDefault();
		window.location.replace(`hootboard://${deepLinkPath}`);
		setTimeout(() => {
			if (platformName == "android") {
				if (document.hasFocus()) {
					window.location.replace(
						"https://play.google.com/store/apps/details?id=com.hootboard"
					);
				}
			} else {
				window.location.replace(
					"https://apps.apple.com/us/app/hootboard/id1141706263"
				);
			}
		}, 2000);
	};

	return {
		platformName,
		deepLinkPath,
		openInApp,
		setAppBannerPath: (value?: string) => {
			dispatch(setSmartAppBannerPath(value));
		},
	};
};

/**
 * Smart app banner for mobile screens.
 * The custom design is an exact replica of native ios-safari banner.
 * Improve the style if its not matching.
 */
export const SmartAppBanners = () => {
	const { openInApp, platformName } = useSmartAppBanners();

	// const hootId = useSelector((state: any) => state.activeHoot.selectedHootId);
	// const authModalVisibility = useSelector(
	// 	(state: any) => state.visibility.authModalVisibility
	// );

	// const postHootModalVisibility = useSelector(
	// 	(state: any) => state.visibility.postHootModalVisibility
	// );

	const isDrawerOpen = useSelector(
		(state: any) => state.visibility.isLeftDrawerVisible
	);

	/**
	 * There are some issues with the native ios smart banner.
	 * As per apple, there is a meta tag we can add to show the banner.
	 * But, it is only showing a closable banner. Once closed, it is gone forever.
	 *
	 * But some websites like, notion & reddit is using a non closable banner.
	 * And I couldn't find a meta tag related to that on those websites.
	 * There is no explanation I could find from apple regarding this.
	 *
	 * So, until we figure out the correct solution, we will be using a custom made banner.
	 */

	// if (platformName == "ios-safari") {
	// 	return (
	// 		<Helmet>
	// 			<meta
	// 				name="apple-itunes-app"
	// 				content={`app-id=1141706263, app-argument=hootboard://${deepLinkPath}`}
	// 			/>
	// 		</Helmet>
	// 	);
	// }

	if (!platformName || isDrawerOpen) return null;

	return (
		<div
			id="smartAppBanner"
			className="flex relative bg-white items-center p-2 justify-between border-b h-14 z-10 sm:hidden"
		>
			<div className="flex items-center">
				<HealthyImage
					src="/assets/app-store.png"
					className="rounded-lg w-10 h-10"
				/>
				<div className="ml-2">
					<div className="font-bold">HootBoard</div>
					<div className="text-xs opacity-50 -mt-1">
						Open in the HootBoard app
					</div>
				</div>
			</div>
			<div
				className="cursor-pointer text-white bg-brand-primary font-bold text-sm px-4 py-2 rounded-full"
				onClick={openInApp}
			>
				OPEN
			</div>
		</div>
	);
};
