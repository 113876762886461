/* eslint-disable no-undef */
/*
 * @Author: -
 * @Date:   -
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-12-11 14:22:07
 */
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import persistState from "redux-localstorage";
import createSagaMiddleware from "redux-saga";

import authSlice from "../components/Auth/auth.slice";
import drawerSlice from "../components/Drawer/drawer.slice";
import embedSlice from "../components/EmbedBoard/embed.slice";
import rehootSlice from "../redux/rehoot.slice";
import appSlice from "./app.slice";
import myHootsSlice from "./myHoots.slice";
import rootSaga from "./sagas";
// TODO: Remove this later
// import snackbarSlice from "../components/AppToast/snackbar.slice";
import calendarSlice from "../components/Calendar/calendar.slice";
import changeLogSlice from "../components/ChangeLog/changeLog.slice";
import activeHootSlice from "../components/HootDisplay/activeHoot.slice";
import messagingSlice from "../components/Messaging/messaging.slice";
import myAccountSlice from "../components/MyAccount/myAccount.slice";
import myBoardsSlice from "../components/MyBoardsNew/myBoards.slice";
import postHootSlice from "../components/PostHootNew/postHoot.slice";
import pricingSlice from "../components/Pricing/pricing.slice";
import accountsSlice from "./accounts.slice";
import customUrlSlice from "./customUrl.slice";
import visibilitySlice from "./visibility.slice";
// import EditScreenSlice from "../components/ManageScreensNew/editScreen.slice";
import activityFeedSlice from "../components/ActivityFeed/activityFeed.slice";
import analyticsSlice from "../components/Analytics/analytics.slice";
import boardAccessSlice from "../components/BoardAccessModal/boardAccess.slice";
import boardNotificationSettingsSlice from "../components/BoardNotifications/boardNotifications.slice";
import sequencesSlice from "../components/ContentSequence/sequences.slice";
import createBoardSlice from "../components/CreateBoard/createBoard.slice";
import createScreenSlice from "../components/CreateScreenNew/createScreen.slice";
import allBoardHootsSlice from "../components/HootsFromAllBoards/allBoardHoots.slice";
import pinnedBoardHootsSlice from "../components/HootsFromPinnedBoards/pinnedBoardHoots.slice";
import listsSlice from "../components/Lists/lists.slice";
import manageBoardSlice from "../components/ManageBoard/manageBoardSlice";
import manageCustomMessageSlice from "../components/ManageCustomMessage/manageCustomMessage.slice";
import manageMembersSlice from "../components/ManageMembers/manageMembers.slice";
import manageMyBusinessSlice from "../components/ManageMyBusiness/manageMyBusiness.slice";
import appStoreSlice from "../components/ManageScreenApps/appStore.slice";
import manageScreenAppsSlice from "../components/ManageScreenApps/manageScreenApps.slice";
import manageScreensSlice from "../components/ManageScreensNew/manageScreens.slice";
import personalNotificationSettingsSlice from "../components/NotificationSettings/notificationSettings.slice";
import onBoardingSlice from "../components/OnBoarding/onBoarding.slice";
import productTourSlice from "../components/ProductTour/productTour.slice";
import bulletinBoardSlice from "../components/ResponsiveBulletinBoard/bulletinBoard.slice";
import scheduleDemoSlice from "../components/ScheduleDemo/scheduleDemo.slice";
// import slideOutMarketingDrawerSlice from "../components/SlideoutDrawer/slideoutDrawer.slice";
import updateScreenSlice from "../components/UpdateScreen/updateScreen.slice";
import activateAccountSlice from "../pages/common/ActivateAccount.page/activateAccount.slice";
import resetPasswordSlice from "../pages/common/ResetPassword.page/resetPassword.slice";
import unSubscribeSlice from "../pages/common/UnSubscribe.page/unSubscribe.slice";
import verifyEmailSlice from "../pages/common/VerifyEmail.page/verifyEmail.slice";
import sharingSlice from "../redux/sharing.slice";
import activeAccountSlice from "./activeAccount.slice";
import activeBoardSlice from "./activeBoard.slice";

const sagaMiddleware = createSagaMiddleware();

const middleware =
	process.env.NODE_ENV !== "production"
		? [
				...getDefaultMiddleware({
					immutableCheck: false,
					serializableCheck: false,
				}),
				// logger,
				sagaMiddleware,
		  ]
		: [
				...getDefaultMiddleware({
					immutableCheck: false,
					serializableCheck: false,
				}),
				sagaMiddleware,
		  ];

export default configureStore({
	reducer: {
		accounts: accountsSlice,
		activateAccount: activateAccountSlice,
		activeAccount: activeAccountSlice,
		activeBoard: activeBoardSlice,
		activeHoot: activeHootSlice,
		activityFeed: activityFeedSlice,
		allBoardHoots: allBoardHootsSlice,
		analytics: analyticsSlice,
		app: appSlice,
		appStore: appStoreSlice,
		auth: authSlice,
		boardAccess: boardAccessSlice,
		boardNotificationSettings: boardNotificationSettingsSlice,
		bulletinBoard: bulletinBoardSlice,
		calendar: calendarSlice,
		changeLog: changeLogSlice,
		createBoard: createBoardSlice,
		createScreen: createScreenSlice,
		customUrl: customUrlSlice,
		drawer: drawerSlice,
		// editScreen: EditScreenSlice,
		embed: embedSlice,
		lists: listsSlice,
		manageBoard: manageBoardSlice,
		manageCustomMessage: manageCustomMessageSlice,
		manageMembers: manageMembersSlice,
		manageMyBusiness: manageMyBusinessSlice,
		manageScreenApps: manageScreenAppsSlice,
		manageScreens: manageScreensSlice,
		messaging: messagingSlice,
		myAccount: myAccountSlice,
		myBoards: myBoardsSlice,
		myHoots: myHootsSlice,
		onBoarding: onBoardingSlice,
		personalNotificationSettings: personalNotificationSettingsSlice,
		pinnedBoardHoots: pinnedBoardHootsSlice,
		postHoot: postHootSlice,
		pricing: pricingSlice,
		productTour: productTourSlice,
		rehoot: rehootSlice,
		resetPassword: resetPasswordSlice,
		scheduleDemo: scheduleDemoSlice,
		sequences: sequencesSlice,
		sharing: sharingSlice,
		// slideOutMarketingDrawer: slideOutMarketingDrawerSlice,
		unSubscribe: unSubscribeSlice,
		updateScreen: updateScreenSlice,
		verifyEmail: verifyEmailSlice,
		visibility: visibilitySlice,
	},
	middleware,
	devTools: process.env.NODE_ENV !== "production",
	enhancers: [persistState(["myBoards"])],
});

sagaMiddleware.run(rootSaga);
