import {
	combineReducers,
	createAsyncThunk,
	createSlice
} from "@reduxjs/toolkit";
import {
	createApp,
	deleteApp,
	getAppCategories,
	getBoardApps,
	updateApp,
	updateSequence
} from "../../api/app.service";
import * as ABActions from "../../redux/activeBoard.slice";
import {
	convertFromEndpoint,
	convertToEndpoint
} from "./components/CollectionSelector/redux";
import appSliceReducer from "./components/InstalledApps/AddApp/addApp.slice"; // close as closeEditModal, // showErrors as showAddAppErrors,
import editAppSliceReducer from "./components/InstalledApps/EditApp/editApp.slice";
import { Actions } from "./manageScreenApps.saga";

const setBoardApps = async (thunkApi, boardId) => {
	const appsResponse = await getBoardApps(boardId);
	thunkApi.dispatch(ABActions.setApps(appsResponse.data));
};

export const fetchScreenAppData = createAsyncThunk(
	"/manageScreenApps/fetchScreenAppData",
	async ({ boardId }, thunkApi) => {
		const {
			activeBoard: {
				boardDetails: { boardName },
			},
		} = thunkApi.getState();

		const results = await Promise.all([
			getAppCategories(boardId),
			getBoardApps(boardId),
		]);

		thunkApi.dispatch(ABActions.setApps(results[1].data));

		function mapBoardAppsRes(response) {
			if (!response.success) {
				return [];
			}
			return response.data.map((item) => {
				const { appTypeCode, boardAppsEndpoints } = item;
				const endpoints =
					appTypeCode === "CL"
						? boardAppsEndpoints.map((boardAppEndPoint) => {
								const { boards, endpoint } = boardAppEndPoint;
								return {
									...boardAppEndPoint,
									endpoint: convertFromEndpoint({
										boards,
										endpoint,
										currentBoard: {
											boardId,
											boardName,
										},
									}),
								};
						  })
						: boardAppsEndpoints;
				return { ...item, boardAppsEndpoints: endpoints };
				// return { ...item, boardAppsEndpoints };
			});
		}

		return {
			appCategories: results[0],
			screenApps: mapBoardAppsRes(results[1]),
		};
	}
);

export const saveWebApp = createAsyncThunk(
	"/manageScreenApps/saveWebApp",
	async ({ boardId, appProps, webAppProps }) => {
		const response = await createApp(boardId, {
			boardAppsEndpoints: [
				{
					endpoint: webAppProps.url,
					collectionBehavior: "U",
					mobileEndpoint: webAppProps.mobileFriendlyUrl,
					domainsAllowed: webAppProps.allowedDomains
						.split(",")
						.filter((item) => item)
						.join(","),
					// boardScreenId: '',
					boardScreenId: appProps.selectedScreens
						.map(({ screenId }) => screenId)
						.join(),
				},
			],
			appName: appProps.name,
			boardId,
			appTypeCode: appProps.type.appTypeCode,
			platformAppId: "1",
			showAllScreens: appProps.showOnAllScreens,
			appIconUrl: appProps.iconUrl,
			private: appProps.private,
			showOnMobile: appProps.showOnMobile,
			showOnWeb: appProps.showOnWeb,
		});

		if (response.message !== "success") return { success: false };

		return { success: true, app: response.data };
	}
);

export const saveRSSFeedReaderApp = createAsyncThunk(
	"/manageScreenApps/saveRSSFeedReaderApp",
	async ({ boardId, appProps, rssFeedReaderAppProps }) => {
		const response = await createApp(boardId, {
			boardAppsEndpoints: [
				{
					endpoint: rssFeedReaderAppProps.url,
					collectionBehavior: "U",
					boardScreenId: appProps.selectedScreens
						.map(({ screenId }) => screenId)
						.join(),
				},
			],
			appName: appProps.name,
			boardId,
			appTypeCode: appProps.type.appTypeCode,
			platformAppId: "8",
			showAllScreens: appProps.showOnAllScreens,
			appIconUrl: appProps.iconUrl,
			private: appProps.private,
			showOnMobile: appProps.showOnMobile,
			showOnWeb: appProps.showOnWeb,
		});

		if (response.message !== "success") return { success: false };

		return { success: true, app: response.data };
	}
);

export const saveFileApp = (payload) => {
	return {
		type: Actions.saveFileApp + "request",
		payload,
	};
};

export const getSignedUploadRequest = (payload) => {
	return {
		type: Actions.getSignedUploadRequest + "request",
		payload,
	};
};

export const saveCollectionApp = createAsyncThunk(
	"/manageScreenApps/saveCollectionApp",
	async ({ boardId, appProps, collectionAppProps }) => {
		const response = await createApp(boardId, {
			boardAppsEndpoints: [
				{
					endpoint: convertToEndpoint({
						collections: collectionAppProps.selectedCollections,
					}),
					collectionBehavior: collectionAppProps.collectionBehavior,
					boardScreenId:
						appProps.selectedScreens.length === 0
							? ""
							: appProps.selectedScreens
									.map(({ screenId }) => screenId)
									.join(),
				},
			],
			appName: appProps.name,
			boardId,
			appTypeCode: appProps.type.appTypeCode,
			platformAppId: "2",
			showAllScreens: appProps.showOnAllScreens,
			appIconUrl: appProps.iconUrl,
			private: appProps.private,
			showOnMobile: appProps.showOnMobile,
			showOnWeb: appProps.showOnWeb,
		});

		if (response.message !== "success") return { success: false };

		return { success: true, app: response.data };
	}
);

export const saveCalendarApp = createAsyncThunk(
	"/manageScreenApps/saveCalendarApp",
	async ({ boardId, appProps }) => {
		const response = await createApp(boardId, {
			boardAppsEndpoints: [
				{
					endpoint: "/calendar",
					collectionBehavior: "U",
					boardScreenId:
						appProps.selectedScreens.length === 0
							? ""
							: appProps.selectedScreens
									.map(({ screenId }) => screenId)
									.join(),
				},
			],
			appName: appProps.name,
			boardId,
			appTypeCode: appProps.type.appTypeCode,
			platformAppId: "6",
			showAllScreens: appProps.showOnAllScreens,
			appIconUrl: appProps.iconUrl,
			private: appProps.private,
			showOnMobile: appProps.showOnMobile,
			showOnWeb: appProps.showOnWeb,
		});

		if (response.message !== "success") return { success: false };

		return { success: true, app: response.data };
	}
);

export const deleteScreenApp = createAsyncThunk(
	"/manageScreenApps/deleteScreenApp",
	async ({ boardId, appId }, thunkApi) => {
		const response = await deleteApp(boardId, appId);

		thunkApi.dispatch(fetchScreenAppData({ boardId }));

		return { response };
	}
);

export const updateWebApp = createAsyncThunk(
	"/manageScreenApps/updateWebApp",
	async ({ boardId, appProps, webAppProps, app }, thunkApi) => {
		const response = await updateApp(boardId, app.boardAppsId, {
			...app,
			appName: appProps.name,
			showAllScreens: appProps.showOnAllScreens,
			boardId,
			appIconUrl: appProps.iconUrl,
			private: appProps.appAccess !== "public",
			showOnMobile: appProps.showOnMobile,
			showOnWeb: appProps.showOnWeb,
			boardAppsEndpoints: [
				{
					...app.boardAppsEndpoints[0],
					endpoint: webAppProps.url,
					mobileEndpoint: webAppProps.mobileFriendlyUrl,
					domainsAllowed: webAppProps.allowedDomains
						.split(",")
						.filter((item) => item)
						.join(","),
					boardScreenId: appProps.selectedScreens
						.map(({ screenId }) => screenId)
						.join(),
				},
			],
		});

		if (response.message !== "success") return { success: false };

		thunkApi.dispatch(fetchScreenAppData({ boardId }));

		return { success: true, updatedApp: response.data };
	}
);

export const updateFileApp = (payload) => {
	return {
		type: Actions.updateFileApp + "request",
		payload,
	};
};

export const updateRSSFeedReaderApp = createAsyncThunk(
	"/manageScreenApps/updateRSSFeedReaderApp",
	async ({ boardId, appProps, rssFeedReaderAppProps, app }, thunkApi) => {
		const response = await updateApp(boardId, app.boardAppsId, {
			...app,
			appName: appProps.name,
			showAllScreens: appProps.showOnAllScreens,
			boardId,
			appIconUrl: appProps.iconUrl,
			private: appProps.appAccess !== "public",
			showOnMobile: appProps.showOnMobile,
			showOnWeb: appProps.showOnWeb,
			boardAppsEndpoints: [
				{
					...app.boardAppsEndpoints[0],
					endpoint: rssFeedReaderAppProps.url,
					boardScreenId: appProps.selectedScreens
						.map(({ screenId }) => screenId)
						.join(),
				},
			],
		});

		if (response.message !== "success") return { success: false };

		thunkApi.dispatch(fetchScreenAppData({ boardId }));

		return { success: true, updatedApp: response.data };
	}
);

export const updateCollectionApp = createAsyncThunk(
	"/manageScreenApps/updateCollectionApp",
	async ({ boardId, appProps, collectionAppProps, app }, thunkApi) => {
		const response = await updateApp(boardId, app.boardAppsId, {
			...app,
			appName: appProps.name,
			showAllScreens: appProps.showOnAllScreens,
			boardId,
			appIconUrl: appProps.iconUrl,
			private: appProps.appAccess !== "public",
			showOnMobile: appProps.showOnMobile,
			showOnWeb: appProps.showOnWeb,
			boardAppsEndpoints: [
				{
					...app.boardAppsEndpoints[0],
					collectionBehavior: collectionAppProps.collectionBehavior,
					// endpoint: collectionAppProps.selectedCollections
					// 	.map(({ collectionName }) => collectionName)
					// 	.join(),
					endpoint: convertToEndpoint({
						collections: collectionAppProps.selectedCollections,
					}),
					boardScreenId:
						appProps.selectedScreens.length === 0
							? ""
							: appProps.selectedScreens
									.map(({ screenId }) => screenId)
									.join(),
				},
			],
		});

		if (response.message !== "success") return { success: false };

		thunkApi.dispatch(fetchScreenAppData({ boardId }));

		return { success: true, updatedApp: response.data };
	}
);

export const updateCalendarApp = createAsyncThunk(
	"/manageScreenApps/updateCalendarApp",
	async ({ boardId, appProps, app }, thunkApi) => {
		const response = await updateApp(boardId, app.boardAppsId, {
			...app,
			appName: appProps.name,
			showAllScreens: appProps.showOnAllScreens,
			boardId,
			appIconUrl: appProps.iconUrl,
			private: appProps.appAccess !== "public",
			showOnMobile: appProps.showOnMobile,
			showOnWeb: appProps.showOnWeb,
			boardAppsEndpoints: [
				{
					...app.boardAppsEndpoints[0],
					boardScreenId:
						appProps.selectedScreens.length === 0
							? ""
							: appProps.selectedScreens
									.map(({ screenId }) => screenId)
									.join(),
				},
			],
		});

		if (response.message !== "success") return { success: false };

		thunkApi.dispatch(fetchScreenAppData({ boardId }));

		return { success: true, updatedApp: response.data };
	}
);

const formatNewOrderApps = ({ source, destination, screenApps }) => {
	const duplicateScreenApps = screenApps.map((arr) =>
		arr
			.map((app) => ({ ...app }))
			.filter((anyValue) => typeof anyValue !== "undefined")
	);
	const [app] = duplicateScreenApps[source.page].splice(source.index, 1);

	if (duplicateScreenApps[destination.page]) {
		duplicateScreenApps[destination.page].splice(destination.index, 0, app);
	} else {
		duplicateScreenApps.push([app]);
	}

	let sequences = [];
	const newSequence = duplicateScreenApps
		.filter((item) => item.length)
		.map((arr, pageNum) =>
			arr.map((app, seqNumber) => {
				sequences.push({
					appId: app.boardAppsId,
					seqNumber: seqNumber + 1,
					pageNum: pageNum + 1,
				});
				return {
					...app,
					pageNum: pageNum + 1,
					seqNumber: seqNumber + 1,
				};
			})
		);

	sequences = sequences
		.sort((a, b) => a.pageNum - b.pageNum)
		.reduce((items, item) => {
			let itemData = { ...item };
			const lastItem = items[items.length - 1];

			if (lastItem) {
				const nextPageNumber = lastItem.pageNum + 1;

				if (itemData.pageNum > nextPageNumber) {
					itemData = { ...itemData, pageNum: nextPageNumber };
				}
			}
			return [...items, { ...itemData, seq: itemData.seqNumber }];
		}, []);

	return { sequences, newSequence };
};

export const persistNewAppOrder = createAsyncThunk(
	"/manageScreenApps/persistNewAppOrder",
	async (state, thunkApi) => {
		const { sequences, newSequence } = formatNewOrderApps(state);

		const response = await updateSequence(state.boardId, {
			boardId: state.boardId,
			sequences,
		});
		setBoardApps(thunkApi, state.boardId);

		if (response.message !== "success") return { success: false };
		return { success: true, screenApps: newSequence };
	}
);

const manageScreenApps = createSlice({
	name: "manageScreenApps",
	initialState: {
		isVideoHelperActive: false,
		isAppIconChooserActive: false,
		isFetchingInitial: false,
		screenApps: [],
		appCategories: [],

		addAppStatus: null,
		trackAppData: {},
		getSignedUploadRequestStatus: "idle",
		getSignedUploadRequestData: null,
		startUploadCounter: 0,

		editAppStatus: "idle",
		deleteAppStatus: null,
	},
	reducers: {
		setAppIconChooser: (state, action) => {
			state.isAppIconChooserActive = !!action.payload;
		},
		setVideoHelperActive: (state, action) => {
			state.isVideoHelperActive = !!action.payload;
		},
		incrementStartUploadCounter: (state, action) => {
			if (typeof action.payload === "number") {
				state.startUploadCounter = action.payload;
			} else {
				state.startUploadCounter += 1;
			}
		},

		resetStatus: (state) => {
			state.addAppStatus = null;
			state.editAppStatus = "idle";
			state.deleteAppStatus = null;
			state.trackAppData = {};
		},
		addNewPage: (state) => {
			state.screenApps = [...state.screenApps, []];
		},
	},
	extraReducers: {
		[fetchScreenAppData.fulfilled]: (state, action) => {
			const { appCategories, screenApps } = action.payload;
			//
			const largestPageNumber = screenApps.reduce((largest, item) => {
				if (item.pageNum > largest) return item.pageNum;
				return largest;
			}, 0);

			const screenAppPages = [...Array(largestPageNumber).keys()].map(
				(index) => {
					return screenApps.filter(
						(item) => item.pageNum === index + 1
					);
				}
			);

			state.appCategories = appCategories;
			state.isFetchingInitial = false;
			state.screenApps = screenAppPages;
		},
		[fetchScreenAppData.pending]: (state) => {
			state.isFetchingInitial = true;
			state.screenApps = [];
			state.appCategories = [];
		},
		[fetchScreenAppData.rejected]: (state) => {
			state.isFetchingInitial = false;
		},

		[deleteScreenApp.fulfilled]: (state) => {
			state.deleteAppStatus = "fulfilled";
		},
		[deleteScreenApp.pending]: (state) => {
			state.deleteAppStatus = "pending";
		},
		[deleteScreenApp.rejected]: (state) => {
			state.deleteAppStatus = "rejected";
		},

		[persistNewAppOrder.fulfilled]: (state, action) => {
			const { success } = action.payload;

			if (!success) {
				// TODO: failure toast
				return;
			}
			// TODO: success toast
		},

		[persistNewAppOrder.pending]: (state, action) => {
			// TODO: Need to find other ways. Not sure this is the correct method.
			const { newSequence } = formatNewOrderApps(action.meta.arg);
			state.screenApps = newSequence;
		},
		[persistNewAppOrder.rejected]: () => {},

		[saveWebApp.fulfilled]: (state, action) => {
			const { success, app } = action.payload;
			const {
				appIconUrl,
				appName: appTitle,
				boardAppsId: boardAppId,
				appTypeCode: appType,
			} = app || {};

			state.trackAppData = {
				appCategory: "Web",
				appId: boardAppId,
				boardAppId,
				appTitle,
				appIconUrl,
				appType,
			};
			state.addAppStatus = success ? "fulfilled" : "rejected";
		},
		[saveWebApp.pending]: (state) => {
			state.addAppStatus = "pending";
		},
		[saveWebApp.rejected]: (state) => {
			state.addAppStatus = "rejected";
		},

		[saveRSSFeedReaderApp.fulfilled]: (state, action) => {
			const { success, app } = action.payload;
			const {
				appIconUrl,
				appName: appTitle,
				boardAppsId: boardAppId,
				appTypeCode: appType,
			} = app || {};

			state.trackAppData = {
				appCategory: "Web",
				appId: boardAppId,
				boardAppId,
				appTitle,
				appIconUrl,
				appType,
			};
			state.addAppStatus = success ? "fulfilled" : "rejected";
		},
		[saveRSSFeedReaderApp.pending]: (state) => {
			state.addAppStatus = "pending";
		},
		[saveRSSFeedReaderApp.rejected]: (state) => {
			state.addAppStatus = "rejected";
		},

		[Actions.saveFileApp + "fulfilled"]: (state, action) => {
			const { success, app } = action.payload;
			const {
				appIconUrl,
				appName: appTitle,
				boardAppsId: boardAppId,
				appTypeCode: appType,
			} = app || {};

			state.trackAppData = {
				appCategory: "Web",
				appId: boardAppId,
				boardAppId,
				appTitle,
				appIconUrl,
				appType,
			};
			state.addAppStatus = success ? "fulfilled" : "rejected";
			state.getSignedUploadRequestStatus = "idle";
			state.getSignedUploadRequestData = null;
			state.startUploadCounter = 0;
		},
		[Actions.saveFileApp + "pending"]: (state) => {
			state.addAppStatus = "pending";
		},
		[Actions.saveFileApp + "rejected"]: (state) => {
			state.addAppStatus = "rejected";
		},
		"addApp/close": (state) => {
			state.getSignedUploadRequestStatus = "idle";
			state.getSignedUploadRequestData = null;
			state.startUploadCounter = 0;
		},
		"editApp/close": (state) => {
			state.getSignedUploadRequestStatus = "idle";
			state.getSignedUploadRequestData = null;
			state.startUploadCounter = 0;
		},

		[Actions.getSignedUploadRequest + "fulfilled"]: (state, action) => {
			state.getSignedUploadRequestStatus = "fulfilled";
			state.getSignedUploadRequestData = action.payload;
		},
		[Actions.getSignedUploadRequest + "pending"]: (state) => {
			state.getSignedUploadRequestStatus = "pending";
		},
		[Actions.getSignedUploadRequest + "rejected"]: (state) => {
			state.getSignedUploadRequestStatus = "rejected";
		},

		[saveCollectionApp.fulfilled]: (state, action) => {
			const {
				success,
				app: {
					appIconUrl,
					appName: appTitle,
					boardAppsId: boardAppId,
					appTypeCode: appType,
				},
			} = action.payload;

			state.trackAppData = {
				appId: boardAppId,
				appCategory: "HootBoard",
				boardAppId,
				appTitle,
				appIconUrl,
				appType,
			};
			state.addAppStatus = success ? "fulfilled" : "rejected";
		},
		[saveCollectionApp.pending]: (state) => {
			state.addAppStatus = "pending";
		},
		[saveCollectionApp.rejected]: (state) => {
			state.addAppStatus = "rejected";
		},

		[saveCalendarApp.fulfilled]: (state, action) => {
			const {
				success,
				app: {
					appIconUrl,
					appName: appTitle,
					boardAppsId: boardAppId,
					appTypeCode: appType,
				},
			} = action.payload;

			state.trackAppData = {
				appId: boardAppId,
				appCategory: "HootBoard",
				boardAppId,
				appTitle,
				appIconUrl,
				appType,
			};
			state.addAppStatus = success ? "fulfilled" : "rejected";
		},
		[saveCalendarApp.pending]: (state) => {
			state.addAppStatus = "pending";
		},
		[saveCalendarApp.rejected]: (state) => {
			state.addAppStatus = "rejected";
		},

		[updateWebApp.fulfilled]: (state, action) => {
			state.editAppStatus = action.payload.success
				? "fulfilled"
				: "rejected";
		},
		[updateWebApp.pending]: (state) => {
			state.editAppStatus = "pending";
		},
		[updateWebApp.rejected]: (state) => {
			state.editAppStatus = "rejected";
		},

		[Actions.updateFileApp + "fulfilled"]: (state, action) => {
			state.editAppStatus = action.payload.success
				? "fulfilled"
				: "rejected";
		},
		[Actions.updateFileApp + "pending"]: (state) => {
			state.editAppStatus = "pending";
		},
		[Actions.updateFileApp + "rejected"]: (state) => {
			state.editAppStatus = "rejected";
		},

		[updateRSSFeedReaderApp.fulfilled]: (state, action) => {
			state.editAppStatus = action.payload.success
				? "fulfilled"
				: "rejected";
		},
		[updateRSSFeedReaderApp.pending]: (state) => {
			state.editAppStatus = "pending";
		},
		[updateRSSFeedReaderApp.rejected]: (state) => {
			state.editAppStatus = "rejected";
		},

		[updateCollectionApp.fulfilled]: (state, action) => {
			state.editAppStatus = action.payload.success
				? "fulfilled"
				: "rejected";
		},
		[updateCollectionApp.pending]: (state) => {
			state.editAppStatus = "pending";
		},
		[updateCollectionApp.rejected]: (state) => {
			state.editAppStatus = "rejected";
		},

		[updateCalendarApp.fulfilled]: (state, action) => {
			state.editAppStatus = action.payload.success
				? "fulfilled"
				: "rejected";
		},
		[updateCalendarApp.pending]: (state) => {
			state.editAppStatus = "pending";
		},
		[updateCalendarApp.rejected]: (state) => {
			state.editAppStatus = "rejected";
		},
	},
});
export const {
	resetStatus,
	addNewPage,
	setVideoHelperActive,
	incrementStartUploadCounter,
	setAppIconChooser,
} = manageScreenApps.actions;

export default combineReducers({
	manage: manageScreenApps.reducer,
	addApp: appSliceReducer,
	editApp: editAppSliceReducer,
});
