/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-24 17:10:44
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-10-21 12:20:18
 */

import { createSlice } from "@reduxjs/toolkit";
import { Actions as AuthActions } from "../components/Auth/auth.saga";

const initialState = {
	activeAccountId: null,
	activeAccountDetails: null,
	activeAccountUserBoardsById: {},
	activeAccountUserBoardsFetchStatus: "idle",
	activeAccountUserDetailsFetchStatus: "idle",
	extendedRoles: [],
};

function generateMapFromCollection(userBoards) {
	let obj = {};
	[...userBoards]
		.sort((a, b) => a.boardName.localeCompare(b.boardName))
		.map((item) => {
			obj[item.boardId] = item;
		});
	return obj;
}

export const fetchUserBoardsAsync = (payload) => {
	return {
		type: "user/boards/list/request",
		payload: payload,
	};
};

export const fetchUserDetailsAsync = (payload) => {
	return {
		type: "user/details/request",
		payload,
	};
};

const activeAccountSlice = createSlice({
	name: "activeAccount",
	initialState: initialState,
	reducers: {
		setActiveAccount: (state, action) => {
			return {
				...state,
				activeAccountId: action.payload.activeAccountId,
				activeAccountDetails: action.payload.activeAccountDetails,
				activeAccountUserBoardsById: {},
			};
		},
		resetActiveAccount: () => {
			return initialState;
		},
		setEmailValidated: (state) => {
			if (state.activeAccountDetails) {
				state.activeAccountDetails.emailValidated = true;
			}
		},
		setPasswordSet: (state) => {
			if (state.activeAccountDetails) {
				state.activeAccountDetails.isPasswordResetRequired = false;
			}
		},
	},
	extraReducers: {
		"user/boards/list/pending": (state) => {
			return {
				...state,
				activeAccountUserBoardsFetchStatus: "pending",
			};
		},
		"user/boards/list/fulfilled": (state, action) => {
			return {
				...state,
				activeAccountUserBoardsFetchStatus: "fulfilled",
				activeAccountUserBoardsById: generateMapFromCollection(
					action.payload.userBoards
				),
			};
		},
		"board/access/change/fulfilled": (state, action) => {
			return {
				...state,
				activeAccountUserBoardsFetchStatus: "fulfilled",
				activeAccountUserBoardsById: generateMapFromCollection(
					action.payload.userDetails.userBoards
				),
			};
		},
		"auth/signup/board/follow/fulfilled": (state, action) => {
			return {
				...state,
				activeAccountUserBoardsFetchStatus: "fulfilled",
				activeAccountId: action.payload.userDetails.partyId,
				activeAccountDetails: action.payload.userDetails,
				activeAccountUserBoardsById: generateMapFromCollection(
					action.payload.userDetails.userBoards
				),
			};
		},
		"user/boards/list/rejected": (state) => {
			return {
				...state,
				activeAccountUserBoardsFetchStatus: "rejected",
			};
		},
		"auth/login/fulfilled": (state, action) => {
			return {
				...state,
				activeAccountId: action.payload.userDetails.partyId,
				activeAccountDetails: action.payload.userDetails,
				activeAccountUserBoardsById: generateMapFromCollection(
					action.payload.userDetails.userBoards
				),
			};
		},
		"auth/signup/fulfilled": (state, action) => {
			return {
				...state,
				activeAccountId: action.payload.userDetails.partyId,
				activeAccountDetails: action.payload.userDetails,
				activeAccountUserBoardsById: generateMapFromCollection(
					action.payload.userDetails.userBoards
				),
			};
		},
		"board/create/fulfilled": (state, action) => {
			return {
				...state,
				activeAccountUserBoardsById: generateMapFromCollection(
					action.payload.userDetails.userBoards
				),
			};
		},
		"board/follow/fulfilled": (state, action) => {
			return {
				...state,
				activeAccountUserBoardsById: generateMapFromCollection(
					action.payload.userDetails.userBoards
				),
			};
		},
		"user/account/update/fulfilled": (state, action) => {
			return {
				...state,
				activeAccountDetails: action.payload.accountDetails,
			};
		},
		"user/details/pending": (state) => {
			return {
				...state,
				activeAccountUserDetailsFetchStatus: "pending",
			};
		},
		"user/details/rejected": (state) => {
			return {
				...state,
				activeAccountUserDetailsFetchStatus: "rejected",
			};
		},
		"user/details/fulfilled": (state, action) => {
			const partyId = action.payload.userDetails.partyId;
			localStorage.setItem("partyId", partyId);
			return {
				...state,
				extendedRoles: action.payload.extendedRoles,
				activeAccountUserDetailsFetchStatus: "fulfilled",
				activeAccountId: partyId,
				activeAccountDetails: action.payload.userDetails,
				activeAccountUserBoardsById: generateMapFromCollection(
					action.payload.userDetails.userBoards
				),
			};
		},
		"auth/facebookLogin/fulfilled": (state, action) => {
			const userDetails = action.payload.userDetails;
			state.activeAccountId = userDetails.partyId;
			state.activeAccountDetails = userDetails;
			state.activeAccountUserBoardsById = generateMapFromCollection(
				userDetails.userBoards
			);
		},
		[AuthActions.socialLogin + "fulfilled"]: (state, action) => {
			const userDetails = action.payload.userDetails;
			state.activeAccountId = userDetails.partyId;
			state.activeAccountDetails = userDetails;
			state.activeAccountUserBoardsById = generateMapFromCollection(
				userDetails.userBoards
			);
		},
	},
});
export const {
	setActiveAccount,
	setEmailValidated,
	resetActiveAccount,
	setPasswordSet,
} = activeAccountSlice.actions;
export default activeAccountSlice.reducer;
