/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-29 17:02:21
 * @Last Modified by: Sridhar Katta
 * @Last Modified time: 2022-08-22 12:25:03
 */

import { call, put, takeLatest } from "redux-saga/effects";
import * as BoardsService from "../../api/boards.service";
import * as UserApi from "../../api/users.service";
import { FilestackClient } from "../../components/FileStack.component";

export const Actions = {
	getBoardTypes: "board/get-types/",
	createBoard: "board/create/",
	registerForAdSpace: "board/register-ad-space/",
	searchBusinesses: "board/search-businesses/",
	getBusinessDetails: "board/get-business-details/",
	claimBusiness: "board/claim-business/",
	importBusiness: "board/import/",
	importBusinessPhotos: "board/fetch-business-photos/",
	updateThemeColors: "board/update-theme-colors/",
	checkBusinessExists: "board/check-business-exists/",
};

function* getBoardTypes() {
	yield takeLatest(Actions.getBoardTypes + "request", function* () {
		try {
			yield put({
				type: Actions.getBoardTypes + "pending",
				payload: {},
			});
			const serverResponse = yield call(
				async () => await BoardsService.getBoardTypes()
			);
			if (serverResponse && serverResponse.message === "success") {
				yield put({
					type: Actions.getBoardTypes + "fulfilled",
					payload: serverResponse.data || [],
				});
			} else {
				throw new Error(serverResponse);
			}
		} catch (e) {
			yield put({
				type: Actions.getBoardTypes + "rejected",
				payload: {},
			});
		}
	});
}

function* createBoardSaga() {
	yield takeLatest(Actions.createBoard + "request", function* (action) {
		try {
			yield put({ type: Actions.createBoard + "pending", payload: {} });

			const data = action.payload;

			// check if the boardLogo is a valid filestack URL.
			// if not, we need to upload the image into filestack and get the URL.
			const boardLogo = data.boardLogo;
			if (!boardLogo.includes("https://cdn.filestackcontent.com/")) {
				const filestackResponse = yield call(
					async () => await FilestackClient.storeURL(boardLogo)
				);
				data.boardLogo = filestackResponse.url;
			}

			const createBoardRequest = yield call(
				async () => await BoardsService.createBoard(data)
			);

			if (
				createBoardRequest &&
				createBoardRequest.data &&
				createBoardRequest.data.boardUrl
			) {
				const userDetails = yield call(
					async () => await UserApi.getUserDetails()
				);
				const boardDetails = yield call(async () => {
					return await BoardsService.getBoardDetails(
						createBoardRequest.data.boardId,
						{}
					);
				});
				if (userDetails) {
					yield put({
						type: Actions.createBoard + "fulfilled",
						payload: {
							loginSuccess: true,
							userDetails: userDetails,
							boardInfo: boardDetails,
						},
					});
				} else {
					yield put({
						type: Actions.createBoard + "fulfilled",
						payload: { loginSuccess: true, userDetails: null },
					});
				}
				// The board created has to be pinned to the sidebar,so we are calling the action of type
				// user/board/pin/request to the pin the board that just got created.
				yield put({
					type: "user/board/pin/request",
					payload: { boardId: createBoardRequest.data.boardId },
				});
			} else {
				yield put({
					type: Actions.createBoard + "rejected",
					payload: { loginSuccess: false },
				});
			}
		} catch (e) {
			yield put({ type: Actions.createBoard + "rejected", payload: {} });
		}
	});
}

function* registerForAdSpace() {
	yield takeLatest(
		Actions.registerForAdSpace + "request",
		function* (action) {
			try {
				yield put({
					type: Actions.registerForAdSpace + "pending",
					payload: {},
				});

				// TODO: Get the user details and board details from state instead of making another API call.

				const userDetails = yield call(
					async () => await UserApi.getUserDetails()
				);
				const boardDetails = yield call(async () => {
					return await BoardsService.getBoardDetails(
						action.payload.boardId,
						{}
					);
				});
				if (userDetails && boardDetails) {
					if (boardDetails.boardType === "HLB") {
						// Refer to mergeWithAdvertisingEnabled() function for more information.

						const locations = boardDetails.locations || [{}];
						const regionCode = locations[0].regionCode;
						const countryCode = locations[0].countryCode;

						if (
							countryCode === "US" &&
							["NY", "NJ", "CT", "PA"].includes(regionCode)
						) {
							BoardsService.registerForAdvertising({
								email: userDetails.email,
								fullName: userDetails.displayName,
								userDetails,
								boardDetails,
							});
						}
					}

					yield put({
						type: Actions.registerForAdSpace + "fulfilled",
						payload: {
							loginSuccess: true,
							userDetails: userDetails,
							boardInfo: boardDetails,
						},
					});
				} else {
					throw new Error();
				}
			} catch (e) {
				yield put({
					type: Actions.registerForAdSpace + "rejected",
					payload: {},
				});
			}
		}
	);
}

function* searchBusinessesSaga() {
	yield takeLatest(Actions.searchBusinesses + "request", function* (action) {
		try {
			yield put({
				type: Actions.searchBusinesses + "pending",
				payload: {},
			});
			const { query, location } = action.payload;
			const serverResponse = yield call(
				async () =>
					await BoardsService.searchBusinesses({ ...location, query })
			);
			if (
				serverResponse &&
				serverResponse.message === "success" &&
				serverResponse.data
			) {
				yield put({
					type: Actions.searchBusinesses + "fulfilled",
					payload: serverResponse.data,
				});
			} else {
				throw new Error(serverResponse);
			}
		} catch (e) {
			yield put({
				type: Actions.searchBusinesses + "rejected",
				payload: {},
			});
		}
	});
}

/**
 * This saga is used to get existing board details.
 * Don't get confused with the import flow.
 * That's `importBusinessSaga()` we are using for importing business details from other sources.
 */
function* getBusinessesDetailsSaga() {
	yield takeLatest(
		Actions.getBusinessDetails + "request",
		function* (action) {
			try {
				yield put({
					type: Actions.getBusinessDetails + "pending",
					payload: {},
				});
				const boardId = action.payload;
				const serverResponse = yield call(
					async () => await BoardsService.getBoardDetails(boardId)
				);
				if (serverResponse && serverResponse.boardId) {
					yield put({
						type: Actions.getBusinessDetails + "fulfilled",
						payload: serverResponse,
					});
				} else {
					throw new Error(serverResponse);
				}
			} catch (e) {
				yield put({
					type: Actions.getBusinessDetails + "rejected",
					payload: {},
				});
			}
		}
	);
}

function* claimBusinessSaga() {
	yield takeLatest(Actions.claimBusiness + "request", function* (action) {
		try {
			yield put({
				type: Actions.claimBusiness + "pending",
				payload: {},
			});
			const data = action.payload;
			const serverResponse = yield call(
				async () => await BoardsService.claimBusiness(data)
			);
			if (serverResponse && serverResponse.message === "success") {
				yield put({
					type: Actions.claimBusiness + "fulfilled",
					payload: serverResponse.data,
				});
			} else {
				throw new Error(serverResponse);
			}
		} catch (e) {
			yield put({
				type: Actions.claimBusiness + "rejected",
				payload: {},
			});
		}
	});
}

function* importBusinessSaga() {
	yield takeLatest(Actions.importBusiness + "request", function* (action) {
		try {
			yield put({
				type: Actions.importBusiness + "pending",
				payload: {},
			});
			const data = action.payload;
			const serverResponse = yield call(
				async () => await BoardsService.getBusinessDetails(data)
			);
			if (
				serverResponse &&
				serverResponse.message === "success" &&
				serverResponse.data
			) {
				const businessDetails = serverResponse.data;

				// initiating business photo fetching.
				// this API call shouldn't affect the import flow.
				// this should happen in the background while the user completes the other steps.
				yield put({
					type: Actions.importBusinessPhotos + "request",
					payload: businessDetails.rawPhotos || [],
				});

				yield put({
					type: Actions.importBusiness + "fulfilled",
					payload: businessDetails,
				});
			} else {
				throw new Error(serverResponse);
			}
		} catch (e) {
			yield put({
				type: Actions.importBusiness + "rejected",
				payload: {},
			});
		}
	});
}

function* importBusinessPhotosSaga() {
	yield takeLatest(
		Actions.importBusinessPhotos + "request",
		function* (action) {
			try {
				yield put({
					type: Actions.importBusinessPhotos + "pending",
					payload: {},
				});
				const rawPhotos = action.payload;
				const serverResponses = yield call(async () => {
					// getting static URL of individual photoReference
					return await Promise.all(
						rawPhotos.map((item) => {
							return BoardsService.fetchBusinessPhoto(
								item.photoReference
							);
						})
					);
				});

				if (serverResponses.every((item) => item.success)) {
					yield put({
						type: Actions.importBusinessPhotos + "fulfilled",
						payload: serverResponses.map((item, index) => {
							/**
							 * The static image URL we are getting from google have a specific format.
							 * At the end of the URL, there is a pattern, `s1600-w100-h-100` something like this.
							 * If we pass the width and height like that, we will get the proper size.
							 *
							 * While showing the thumbnails, we need only small sizes.
							 * But when uploading to filestack, we need original size.
							 * Also, the original size should not exceed 1800x1000.
							 */

							const width = rawPhotos[index].width;
							const height = rawPhotos[index].height;

							const maxWidth = 1800;
							const maxHeight = 1000;

							return {
								url: item.data.url,
								width: width > maxWidth ? maxWidth : width,
								height: height > maxHeight ? maxHeight : height,
							};
						}),
					});
				} else {
					throw new Error(serverResponses);
				}
			} catch (e) {
				yield put({
					type: Actions.importBusinessPhotos + "rejected",
					payload: {},
				});
			}
		}
	);
}

function* updateThemeColors() {
	yield takeLatest(Actions.updateThemeColors + "request", function* (action) {
		try {
			yield put({
				type: Actions.updateThemeColors + "pending",
				payload: {},
			});
			const { boardId, boardParams } = action.payload;
			const response = yield call(
				async () =>
					await BoardsService.manageBoard(boardId, boardParams)
			);
			if (response) {
				yield put({
					type: Actions.updateThemeColors + "fulfilled",
					payload: {},
				});
			} else {
				yield put({
					type: Actions.updateThemeColors + "rejected",
					payload: {},
				});
			}
		} catch (e) {
			yield put({
				type: Actions.updateThemeColors + "rejected",
				payload: {},
			});
		}
	});
}

function* checkBusinessExistsSaga() {
	yield takeLatest(
		Actions.checkBusinessExists + "request",
		function* (action) {
			try {
				yield put({
					type: Actions.checkBusinessExists + "pending",
					payload: {},
				});
				const { placeId, businessName } = action.payload;
				const response = yield call(
					async () =>
						await BoardsService.checkBusinessExists(
							placeId,
							businessName
						)
				);
				if (response) {
					yield put({
						type: Actions.checkBusinessExists + "fulfilled",
						payload: response.data,
					});
				} else {
					yield put({
						type: Actions.checkBusinessExists + "rejected",
						payload: {},
					});
				}
			} catch (e) {
				yield put({
					type: Actions.checkBusinessExists + "rejected",
					payload: {},
				});
			}
		}
	);
}

export const createBoardSagas = [
	getBoardTypes(),
	createBoardSaga(),
	searchBusinessesSaga(),
	getBusinessesDetailsSaga(),
	claimBusinessSaga(),
	registerForAdSpace(),
	importBusinessSaga(),
	importBusinessPhotosSaga(),
	updateThemeColors(),
	checkBusinessExistsSaga(),
];
