/*
 * @Author: Chandu J S
 * @Date:   2020-10-16 22:32:30
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-10-19 15:16:33
 */
import { httpGetV2, httpPostV2, httpPutV2 } from "./http-wrapper";

/**
 * Service to send message to a user.
 */
export const sendMessage = async ({
	sendersId,
	receiverId,
	messageText,
	hootId,
}) => {
	return httpPostV2("private/messages/send", {
		sendersId,
		receiverId,
		messageText,
		hootId,
	});
};

/**
 * Get all conversations of session user
 */
export const getConversations = async () => {
	return httpGetV2("private/messages/conversations");
};

/**
 * Get all unread message count of session user
 */
export const getUnreadCount = async () => {
	return httpGetV2("private/messages/count/unread");
};

/**
 * Service to send reply to a user message.
 */
export const sendReply = async ({ conversationId, messageText }) => {
	return httpPostV2("private/messages/reply", {
		conversationId,
		messageText,
	});
};

/**
 * Set a conversation as read
 */
export const setRead = async (conversationId) => {
	return httpPutV2("private/messages/read/" + conversationId);
};
