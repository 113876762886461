/*
 * @Author: snair@hootboard.com
 * @Date: 2020-06-02 17:52:43
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-06-10 17:23:08
 */

import { call, put, takeLatest } from "redux-saga/effects";
import * as BoardApi from "../../api/boards.service";

function* customUrl(action) {
	try {
		yield put({ type: "custom-url/validate/pending", payload: {} });
		let { customUrl } = action.payload;
		const customUrlApiResponse = yield call(
			async () => await BoardApi.checkUrlAvailability(0, customUrl)
		);
		if (customUrlApiResponse && customUrlApiResponse.status !== "error") {
			yield put({
				type: "custom-url/validate/rejected",
				payload: { isValidCustomUrl: false },
			});
		} else {
			yield put({
				type: "custom-url/validate/rejected",
				payload: { isValidCustomUrl: true },
			});
		}
	} catch (e) {
		yield put({
			type: "custom-url/validate/rejected",
			payload: {
				isValidCustomUrl: false,
			},
		});
	}
}

export function* customUrlSaga() {
	yield takeLatest("custom-url/validate/request", customUrl);
}
