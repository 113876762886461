import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "../../api/lists.service";
import { ActionState, ErrorCodes } from "../../types";
import {
	AddItemToListActionPayload,
	CreateNewListActionPayload,
	RemoveItemFromListActionPayload,
} from "../../types/lists";

export const Actions = {
	createNewList: "list/create-new-list/",
	removeItemFromList: "list/remove-item-from-list/",
	addItemToList: "list/add-item-to-list/",
	getAllLists: "list/get-all-lists/",
	getList: "list/get-list/",
	getListById: "list/get-list-id/",
	saveList: "list/save-list/",
	updateUserAssignedData: "list/update-user-assigned-data/",
	updateList: "list/update-list/",
	deleteList: "list/delete-list/",
	getShareToken: "list/get-share-token/",
	getListByToken: "list/get-list-by-token/",
} as const;

function* addToListSaga() {
	yield takeLatest(
		Actions.addItemToList + ActionState.REQUEST,
		function* (action: PayloadAction<AddItemToListActionPayload>): any {
			try {
				yield put({
					type: Actions.addItemToList + ActionState.PENDING,
				});
				const data = yield call(() => API.addToList(action.payload));
				if (!data) throw new Error();
				if (!data.success) throw new Error(data.errorMessage);

				yield put({
					type: Actions.addItemToList + ActionState.FULFILLED,
					payload: {
						item: action.payload,
						itemIds: data.data.insertIds,
					},
				});
			} catch (e: any) {
				yield put({
					type: Actions.addItemToList + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* removeFromListSaga() {
	yield takeLatest(
		Actions.removeItemFromList + ActionState.REQUEST,
		function* (
			action: PayloadAction<RemoveItemFromListActionPayload>
		): any {
			try {
				yield put({
					type: Actions.removeItemFromList + ActionState.PENDING,
				});
				const data = yield call(() =>
					API.removeFromList(action.payload)
				);
				if (!data) throw new Error();
				if (!data.success) throw new Error(data.errorMessage);

				yield put({
					type: Actions.removeItemFromList + ActionState.FULFILLED,
					payload: action.payload,
				});
			} catch (e: any) {
				yield put({
					type: Actions.removeItemFromList + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* createNewListSaga() {
	yield takeLatest(
		Actions.createNewList + ActionState.REQUEST,
		function* (action: PayloadAction<CreateNewListActionPayload>): any {
			try {
				yield put({
					type: Actions.createNewList + ActionState.PENDING,
					payload: action.payload,
				});
				const data = yield call(() =>
					API.createNewList(action.payload.listData)
				);
				if (!data) throw new Error();
				if (!data.success) throw new Error(data.errorMessage);
				const listId = data.data.listId;

				yield put({
					type: Actions.createNewList + ActionState.FULFILLED,
					payload: { listData: action.payload, data: listId },
				});
				yield put({
					type: Actions.addItemToList + ActionState.REQUEST,
					payload: {
						listId,
						data: action.payload.itemData,
					},
				});
			} catch (e: any) {
				yield put({
					type: Actions.createNewList + ActionState.REJECTED,
					payload: {
						data: action.payload,
						message: e?.message || ErrorCodes.GENERIC,
					},
				});
			}
		}
	);
}

function* getAllListsSaga() {
	yield takeLatest(
		Actions.getAllLists + ActionState.REQUEST,
		function* (): any {
			try {
				yield put({
					type: Actions.getAllLists + ActionState.PENDING,
				});
				const data = yield call(() => API.getAllLists());
				if (!data) throw new Error();
				if (!data.success) throw new Error(data.errorMessage);

				yield put({
					type: Actions.getAllLists + ActionState.FULFILLED,
					payload: data.data,
				});
			} catch (e: any) {
				yield put({
					type: Actions.getAllLists + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* getListSaga() {
	yield takeLatest(
		Actions.getList + ActionState.REQUEST,
		function* (action: any): any {
			try {
				yield put({
					type: Actions.getList + ActionState.PENDING,
				});
				const data = yield call(() => API.getList(action.payload));
				if (!data) throw new Error();
				if (!data.success) throw new Error(data.errorMessage);

				yield put({
					type: Actions.getList + ActionState.FULFILLED,
					payload: data.data,
				});
			} catch (e: any) {
				yield put({
					type: Actions.getList + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* getListByIdSaga() {
	yield takeLatest(
		Actions.getListById + ActionState.REQUEST,
		function* (action: any): any {
			try {
				yield put({
					type: Actions.getListById + ActionState.PENDING,
				});
				const data = yield call(() => API.getListById(action.payload));
				if (!data) throw new Error();
				if (!data.success) throw new Error(data.errorMessage);
				yield put({
					type: Actions.getListById + ActionState.FULFILLED,
					payload: data.data,
				});
			} catch (e: any) {
				yield put({
					type: Actions.getListById + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
				throw e
			}
		}
	);
}

function* saveListSaga() {
	yield takeLatest(
		Actions.saveList + ActionState.REQUEST,
		function* (action: any): any {
			try {
				yield put({
					type: Actions.saveList + ActionState.PENDING,
				});
				const response = yield call(() => API.saveList(action.payload));
				if (!response) throw new Error();
				if (!response.success)
					throw new Error(response?.response?.message);
				yield put({
					type: Actions.saveList + ActionState.FULFILLED,
				});
			} catch (e: any) {
				yield put({
					type: Actions.saveList + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* updateUserAssignedDataSaga() {
	yield takeLatest(
		Actions.updateUserAssignedData + ActionState.REQUEST,
		function* (action: any): any {
			try {
				yield put({
					type: Actions.updateUserAssignedData + ActionState.PENDING,
				});
				const response = yield call(() =>
					API.updateListItem(action.payload)
				);
				if (!response) throw new Error();
				if (!response.success) throw new Error(response.message);
				yield put({
					type:
						Actions.updateUserAssignedData + ActionState.FULFILLED,
					payload: action.payload,
				});
			} catch (e: any) {
				yield put({
					type: Actions.updateUserAssignedData + ActionState.REJECTED,
					payload: e.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* updateListSaga() {
	yield takeLatest(
		Actions.updateList + ActionState.REQUEST,
		function* (action: any): any {
			try {
				yield put({
					type: Actions.updateList + ActionState.PENDING,
				});
				const response = yield call(() =>
					API.updateList(action.payload)
				);
				if (!response) throw new Error();
				if (!response.success) throw new Error(response.message);
				yield put({
					type: Actions.updateList + ActionState.FULFILLED,
					payload: action.payload,
				});
			} catch (e: any) {
				yield put({
					type: Actions.updateList + ActionState.REJECTED,
					payload: e.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* deleteListSaga() {
	yield takeLatest(
		Actions.deleteList + ActionState.REQUEST,
		function* (action: PayloadAction<number>): any {
			try {
				yield put({
					type: Actions.deleteList + ActionState.PENDING,
				});
				const response = yield call(() =>
					API.deleteList(action.payload)
				);
				if (!response) throw new Error();
				if (!response.success) throw new Error(response.message);
				yield put({
					type: Actions.deleteList + ActionState.FULFILLED,
					payload: action.payload,
				});
			} catch (error: any) {
				yield put({
					type: Actions.deleteList + ActionState.REJECTED,
					payload: error.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* getShareTokenSaga() {
	yield takeLatest(
		Actions.getShareToken + ActionState.REQUEST, function* (action: PayloadAction<number>): any {
			try {
				yield put ({
					type: Actions.getShareToken + ActionState.PENDING,
				})
				const response = yield call(() => API.getShareToken(action.payload))
				if (!response) throw new Error(response.message);
				if (!response.success) throw new Error(response.message);
				if (!response.data) throw new Error(response.message);

				yield put ({
					type: Actions.getShareToken + ActionState.FULFILLED,
					payload: response.data.shareableUrlToken,
				})

			} catch (error: any) {
				yield put({
					type: Actions.getShareToken + ActionState.REJECTED,
					payload: error.message || ErrorCodes.GENERIC
				})
			}
		}
	)
}

function* getListByTokenSaga() {
	yield takeLatest(
		Actions.getListByToken + ActionState.REQUEST,
		function* (action: any): any {
			try {
				yield put({
					type: Actions.getListById + ActionState.PENDING,
				});
				const data = yield call(() => API.getListByToken(action.payload));
				if (!data) throw new Error();
				if (!data.success) throw new Error(data.errorMessage);
				yield put({
					type: Actions.getListById + ActionState.FULFILLED,
					payload: data.data,
				});
			} catch (e: any) {
				yield put({
					type: Actions.getListById + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

export const listSagas = [
	addToListSaga(),
	removeFromListSaga(),
	createNewListSaga(),
	getAllListsSaga(),
	getListSaga(),
	getListByIdSaga(),
	saveListSaga(),
	updateUserAssignedDataSaga(),
	updateListSaga(),
	deleteListSaga(),
	getShareTokenSaga(),
	getListByTokenSaga()
];
