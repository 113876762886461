/*
 * @Author: tnityanand@hootboard.com
 * @Date: 2020-04-03 16:35:12
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-09-06 15:00:57
 */

import { apiRequest, httpGetV2, httpPutV2 } from "./http-wrapper";

export const getExtendedRoles = async (email) => {
	return apiRequest("membership/extended-roles", {
		payload: {
			email,
		},
	});
};

/**
 * API for fetching user's created or follwed boards
 */
const getAccountInfo = async () => {
	let response = httpGetV2("private/accmgmt/info", null, null);
	return response;
};

/**
 * API for fetching user's created or follwed boards
 */
const getPersonalNotificationSettings = async () => {
	let response = httpGetV2(
		"private/accmgmt/settings/notifications",
		null,
		null
	);
	return response;
};

/**
 * API for fetching user's created or follwed boards
 */
const updatePersonalNotificationSettings = async (params) => {
	const response = await httpPutV2(
		"private/accmgmt/settings/notifications",
		params,
		null
	);
	return response;
};

/**
 * API for fetching user's created or follwed boards
 */
const updateAccountInfo = async (params) => {
	const response = await httpPutV2("private/accmgmt/info", params, null);
	return response;
};

const updateAccountPassword = async (payload) => {
	return apiRequest("account/password/reset", {
		payload,
		method: "PATCH",
	});
};

const changeAccountEmail = async (newEmail) => {
	const response = await httpPutV2(
		"private/accmgmt/email",
		{ newEmail },
		null
	);
	return response;
};

const updateAccountEmail = async (params) => {
	const response = await httpPutV2(
		"private/accmgmt/email/verification",
		{ newEmail: params.newEmail },
		null
	);
	return response;
};

export {
	updateAccountInfo,
	updateAccountPassword,
	updateAccountEmail,
	changeAccountEmail,
	getAccountInfo,
	getPersonalNotificationSettings,
	updatePersonalNotificationSettings,
};
