/*
 * @Author: tnityanand@hootboard.com
 * @Date: 2020-07-17 15:01:33
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-09-27 01:45:23
 */

let embedConfigParams = {
	id: 0,
	collections: "",
	boardView: "",
	showPosterName: true,
	showComments: true,
	allowedDomains: "",
	embedWindowSize: "",
};
export const mapEmbedConfigParams = ({
	embedConfigId,
	selectedCollections,
	selectedEmbedSize,
	selectedEmbedView,
	showPosterName,
	showComments,
	allowedDomains,
	collectionBehavior,
}) => {
	if (embedConfigId > 0) {
		embedConfigParams.id = embedConfigId;
	}
	if (selectedCollections && selectedCollections.length > 0) {
		let collectionStr = "";
		selectedCollections.map((collection, i) => {
			if (i === 0) {
				collectionStr = `${collection.collectionName}`;
			} else {
				collectionStr = `${collectionStr},${collection.collectionName}`;
			}
		});
		embedConfigParams.collections = collectionStr;
	}
	embedConfigParams.boardView = selectedEmbedView;
	embedConfigParams.showPosterName = showPosterName;
	embedConfigParams.showComments = showComments;
	embedConfigParams.allowedDomains = allowedDomains;
	embedConfigParams.embedWindowSize =
		createWindowSizeString(selectedEmbedSize);
	// need Backend support
	embedConfigParams.collectionBehavior = collectionBehavior;
	return embedConfigParams;
};

function createWindowSizeString(selectedEmbedSize) {
	if (selectedEmbedSize.width > 0 && selectedEmbedSize.height > 0) {
		return `${selectedEmbedSize.width}x${selectedEmbedSize.height}`;
	} else {
		return "";
	}
}

export const embedWindowDims = (embedWindowSize, isIframeSize) => {
	if (embedWindowSize) {
		const width = embedWindowSize.substring(
				0,
				embedWindowSize.indexOf("x")
			),
			height = embedWindowSize.substring(
				embedWindowSize.indexOf("x") + 1
			);
		return {
			width: isIframeSize ? `${width}px` : parseInt(width),
			height: isIframeSize ? `${height}px` : parseInt(height),
		};
	} else {
		return {
			width: 0,
			height: 0,
		};
	}
};

export const mapSelectedCollections = (collectionStr) => {
	let selectedCollections = [];
	if (collectionStr !== "") {
		const fetchedCollections = collectionStr.split(",");
		fetchedCollections.map((coll) => {
			selectedCollections.push({
				collectionName: coll,
			});
		});
	}
	return selectedCollections;
};
