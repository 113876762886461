import produce from "immer";
import { useMemo, useReducer } from "react";

/**
 * This hook is to simplify the use of react's `useReducer`, where it is required to have reducer function that updates the state based on the key-type provided, requiring to use switch-cases.
 * It is also hard to execute some another action within a action.
 *
 * But this hook uses a different approach (but does the same thing internally) and accepts a action-function-map as an object (instead of a reducer function).
 * Where the actionType is the name of the function as a key, and the value provided to this function is the payload that the action needs to update the state.
 *
 *
 * #Example
 * ```js
 *	 const actionMap = {
 *		 increment(draft, by = 1, allowNegative = false) {
 *			 if (allowNegative || by > 0) {
 *				 draft.count += by;
 *			 }
 *		 },
 *		 decrement(draft, by = 1) {
 *			 draft.count -= by;
 *		 },
 *	 };
 *	 const initialState = {
 *		 count: 0,
 *	 };
 *
 *	 function ExampleComponent() {
 *		 const [state, dispatch] = useFunctionalReducer(actionMap, initialState);
 *
 *		 return (
 *			 <div>
 *				 <button onClick={() => dispatch.decrement()}>Decrement</button>
 *				 <span>Value - {state.count}</span>
 *				 <button onClick={() => dispatch.increment(-5, true)}>
 *					 Increment
 *				 </button>
 *			 </div>
 *		 );
 *	 }
 * ```
 */
export const useFunctionalReducer = (
	actionMap,
	initialState,
	initialAction
) => {
	const cachedReducer = useMemo(
		() =>
			produce((draft, action) => {
				actionMap[action.type] &&
					actionMap[action.type](draft, ...action.payload);
			}),
		[]
	);
	const [state, reactDispatch] = useReducer(
		cachedReducer,
		initialState,
		initialAction
	);
	const dispatch = useMemo(() => {
		const dispatch = {};
		for (const type in actionMap) {
			dispatch[type] = (...payload) => reactDispatch({ type, payload });
		}

		return dispatch;
	}, [actionMap]);

	return [state, dispatch];
};
