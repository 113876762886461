import { call, put, takeLatest } from "redux-saga/effects";

import * as ScreenApi from "../../api/screens.service";

// import { Actions } from "../ManageScreensNew/manageScreens.saga";

function* updateScreen(action) {
    try {
        yield put({
            type: "screen/update/pending",
            payload: {},
        });
        const updateScreenApiResponse = yield call(() =>
            ScreenApi.updateScreen(action.payload.boardId, action.payload.params),
        );
        if (updateScreenApiResponse && !updateScreenApiResponse.error) {
            yield put({
                type: "screen/update/fulfilled",
                payload: {
                    screenUpdated: true,
                },
            });
        } else {
            yield put({
                type: "screen/update/rejected",
                payload: { screenUpdated: false },
            });
        }
    } catch (e) {
        yield put({
            type: "screen/update/rejected",
            payload: {},
        });
    }
}

export function* updateScreenSaga() {
    yield takeLatest("screen/update/request", updateScreen);
}

function* deleteScreen(action) {
    try {
        yield put({
            type: "screen/delete/pending",
            payload: {},
        });
        const deleteScreenApiResponse = yield call(
            async () => await ScreenApi.deleteBoardScreen(action.payload.screenId),
        );
        if (deleteScreenApiResponse && deleteScreenApiResponse.screenDeleted) {
            yield put({
                type: "board/screens/list/request",
                payload: {
                    boardId: action.payload.boardId,
                },
            });
            yield put({
                type: "screen/delete/fulfilled",
                payload: {
                    screenDeleted: true,
                    screenId: action.payload.screenId,
                    screenName: action.payload.screenName,
                },
            });
            yield put({
                type: "board/screens/messages/get/request",
                payload: {
                    boardId: action.payload.boardId,
                },
            });
        } else {
            yield put({
                type: "screen/delete/rejected",
                payload: { screenDeleted: false },
            });
        }
    } catch (e) {
        yield put({
            type: "screen/delete/rejected",
            payload: {},
        });
    }
}

export function* deleteScreenSaga() {
    yield takeLatest("screen/delete/request", deleteScreen);
}
