/*
 * @Author: snair@hootboard.com
 * @Date: 2020-06-01 15:03:46
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-10-15 22:59:10
 */
import { createSlice } from "@reduxjs/toolkit";
import validator from "valid-url";

import { emojiFilter } from "../../helper/filters.helper";

export const createScreenAsync = (payload) => {
    const idleStates = {};
    for (const idleState of payload.params.idleStates) {
        idleStates[idleState.keyName] = idleState.isActive;
        idleStates[idleState.configKeyName] = JSON.stringify(idleState.config);
    }
    return {
        type: "screen/create/request",
        payload: { ...payload, params: { ...payload.params, idleStates } },
    };
};

const initialState = {
    steps: [
        {
            label: "screen Info",
        },
        {
            label: "Content Settings",
        },
        {
            label: "Locations",
        },
        {
            label: "Creating Screen",
        },
    ], // TODO: Add {label: 'Attractor Loops',} once enabled again
    currentStep: 0,
    localState: {
        screenName: "",
        screenType: "INT",
        screenLayout: "POR",
        showPrivatePosts: false,
        showBoardCommunityCode: false,
        collections: "",
        showPosterName: false,
        showInfoPage: false,
        mobileJoin: false,
        showComments: false,
        screensDefaultView: "AV",
        screenLocation: {
            formattedAddress: "",
        },
        locationText: "",
        locationAppUrl: "",
        screenJoiningAccess: "FL",
        idleStates: [
            {
                name: "Concierge Home Page",
                keyName: "conciergeHomePage",
                configKeyName: "conciergeConfig",
                config: {
                    title: "",
                },
            },
            {
                name: "Digital Signage",
                keyName: "digitalSignage",
                configKeyName: "digitalSignageConfig",
            },
            {
                name: "App Switcher",
                keyName: "appSwitcher",
                configKeyName: "appSwitcherConfig",
            },
            {
                name: "Hoot Switcher",
                keyName: "hootSwitcher",
                configKeyName: "hootSwitcherConfig",
            },
            {
                name: "Collection Switcher",
                keyName: "collectionSwitcher",
                configKeyName: "collectionSwitcherConfig",
                config: {
                    collections: "",
                    behaviour: "union",
                },
            },
            {
                name: "Tap Anywhere Animation",
                keyName: "tapAnywhereAnimation",
                configKeyName: "tapAnywhereAnimationConfig",
            },
            {
                name: "Upcoming Events Switcher",
                keyName: "upcomingEventSwitcher",
                configKeyName: "upcomingEventSwitcherConfig",
            },
            {
                name: "Fullscreen Image Switcher",
                keyName: "fullScreenImageSwitcher",
                configKeyName: "fullScreenImageSwitcherConfig",
                config: {
                    collections: "",
                    behaviour: "union",
                },
            },
        ].map((item) => {
            // Setting default fields in a map to avoid duplication
            return {
                ...item,
                isActive: false,
                isMoreConfigOpen: false,
                isConfigurable: !!item.config,
                config: {
                    ...item.config,
                    duration: 20,
                },
                // quickView: {
                // 	imageUrl: '',
                // 	description: '',
                // },
            };
        }),
    },
    serverState: null,
    isEditing: false,
    screenId: null,
    createScreenStatus: "idle",
    showAllFormErrors: false,
    screenNameError: true,
    screenLayoutError: true,
    screenNameErrorMessage: "Enter a name or nick-name for your screen",
    screenLayoutErrorMessage: "Please select a screen orientation",
    screenLocationError: true,
    screenLocationErrorMessage: "Please enter the location of the screen",
    locationTextError: true,
    locationTextErrorMessage: "Please enter valid text",
    locationAppUrlError: true,
    locationAppUrlErrorMessage: "Please enter a valid URL",
};

const createScreenSlice = createSlice({
    name: "createScreen",
    initialState: initialState,
    reducers: {
        updateIdleState: (state, action) => {
            const { index, data } = action.payload;
            state.localState.idleStates[index] = data;
        },
        setScreenType: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    screenType: action.payload.screenType,
                },
            };
        },
        collectionChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    collections: action.payload.collections,
                },
            };
        },
        setInitialState: (state, action) => {
            return {
                ...state,
                serverState: action.payload.initialState,
                localState: action.payload.initialState,
            };
        },
        screenNameChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    screenName: emojiFilter(action.payload.screenName),
                },
                screenNameError:
                    action.payload.screenName &&
                    action.payload.screenName.trim() &&
                    action.payload.screenName.trim().length
                        ? false
                        : true,
            };
        },
        screenLayoutChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    screenLayout: action.payload.screenLayout,
                },
            };
        },
        toggleShowPrivatePosts: (state) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    showPrivatePosts: !state.localState.showPrivatePosts,
                },
            };
        },
        toggleShowBoardCommunityCode: (state) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    showBoardCommunityCode: !state.localState.showBoardCommunityCode,
                },
            };
        },
        toggleShowComments: (state) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    showComments: !state.localState.showComments,
                },
            };
        },
        toggleShowPosterName: (state) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    showPosterName: !state.localState.showPosterName,
                },
            };
        },
        toggleShowInfoPage: (state) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    showInfoPage: !state.localState.showInfoPage,
                },
            };
        },
        toggleMobileJoin: (state) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    mobileJoin: !state.localState.mobileJoin,
                },
            };
        },
        screenLocationChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    screenLocation: action.payload.screenLocation,
                },
                screenLocationError:
                    action.payload.screenLocation && action.payload.screenLocation.googlePlaceId
                        ? false
                        : true,
            };
        },
        locationAppUrlChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    locationAppUrl: action.payload.locationAppUrl,
                },
                locationAppUrlError:
                    action.payload.locationAppUrl &&
                    action.payload.locationAppUrl.trim() &&
                    action.payload.locationAppUrl.trim().length
                        ? !validator.isWebUri(action.payload.locationAppUrl)
                        : true,
            };
        },
        locationTextChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    locationText: action.payload.locationText,
                },
                locationTextError:
                    action.payload.locationText &&
                    action.payload.locationText.trim() &&
                    action.payload.locationText.trim().length
                        ? false
                        : true,
            };
        },
        screenJoiningAccessChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    screenJoiningAccess: action.payload.screenJoiningAccess,
                },
            };
        },
        screensDefaultViewChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    screensDefaultView: action.payload.screensDefaultView,
                },
            };
        },
        nextStep: (state) => {
            // TODO: Add this back once Attractor Loops are enabled
            // const stepFinisher = state.localState.screenType === "INT" ? 1 : 2;
            const stepFinisher = 1;

            if (state.currentStep >= state.steps.length - stepFinisher) return state;

            return {
                ...state,
                showAllFormErrors: false,
                currentStep: state.currentStep + 1,
            };
        },
        prevStep: (state) => {
            if (state.currentStep <= 0) return;
            return {
                ...state,
                showAllFormErrors: false,
                currentStep: state.currentStep - 1,
            };
        },
        jumpToStep: (state, action) => {
            if (action.payload.targetStep >= state.currentStep) return;
            return {
                ...state,
                showAllFormErrors: false,
                currentStep: action.payload.targetStep,
            };
        },
        resetCreateScreen: (state) => {
            return {
                ...initialState,
                localState: {
                    ...initialState.localState,
                    screenType: state.localState.screenType,
                },
            };
        },
        showFormErrors: (state) => {
            return {
                ...state,
                showAllFormErrors: true,
            };
        },
    },
    extraReducers: {
        "screen/create/pending": (state) => {
            return {
                ...state,
                createScreenStatus: "pending",
            };
        },
        "screen/create/fulfilled": (state, action) => {
            return {
                ...state,
                createScreenStatus: "fulfilled",
                screenId: action.payload.screenId,
            };
        },
        "screen/create/rejected": (state) => {
            return {
                ...state,
                createScreenStatus: "rejected",
            };
        },
    },
});
export const {
    updateIdleState,
    screenNameChanged,
    screenJoiningAccessChanged,
    screenLayoutChanged,
    toggleShowPrivatePosts,
    toggleShowBoardCommunityCode,
    toggleShowComments,
    toggleShowPosterName,
    toggleShowInfoPage,
    toggleMobileJoin,
    locationAppUrlChanged,
    locationTextChanged,
    screenLocationChanged,
    screensDefaultViewChanged,
    setInitialState,
    nextStep,
    prevStep,
    jumpToStep,
    resetCreateScreen,
    collectionChanged,
    setScreenType,
    showFormErrors,
} = createScreenSlice.actions;
export default createScreenSlice.reducer;
