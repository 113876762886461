import { createAction, createSlice } from "@reduxjs/toolkit";

import { ActionState, AsyncState } from "../../types";

import { Actions } from "./manageScreens.saga";

/**
 * @param {Object} payload
 * @param {string} payload.boardId
 */
export const getScreens = (payload: any) => {
    return {
        type: Actions.getScreens + ActionState.REQUEST,
        payload,
    };
};

/**
 * @param {Object} payload
 * @param {string} payload.boardId
 * @param {string} payload.screenId
 * @param {boolean} payload.value
 */
export const premiumScreenActivationToggle = (payload: any) => {
    return {
        type: Actions.premiumActivationToggle + ActionState.REQUEST,
        payload,
    };
};

export const getKiosksData = createAction(Actions.getKiosksData + ActionState.REQUEST);

const initialState = {
    screens: [],
    pendingUpdateScreensIds: [],
    screenLicenses: null,
    customMessages: [],
    currentlySelectedTabIndex: 0,
    screenSetupTab: null,
    kioskHardwareTab: 0,
    showSpinner: false,
    screensLoadingStatus: AsyncState.IDLE,
    kiosksData: [],
    kiosksDataState: AsyncState.IDLE,
    kiosksDataError: "",
};
const manageScreensSlice = createSlice({
    name: "manageScreens",
    initialState,
    reducers: {
        tabSelected: (state, action) => {
            return {
                ...state,
                currentlySelectedTabIndex: action.payload.index,
            };
        },
        screenSetupTabSelected: (state, action) => {
            return {
                ...state,
                screenSetupTab: action.payload,
            };
        },
        kioskHardwareTabSelected: (state, action) => {
            return {
                ...state,
                kioskHardwareTab: action.payload,
            };
        },
    },
    extraReducers: {
        // for fetching the data from shopify and display in our hootboard web app
        [Actions.getKiosksData + ActionState.PENDING]: (state) => {
            state.kiosksDataState = AsyncState.PENDING;
            state.kiosksDataError = "";
        },
        [Actions.getKiosksData + ActionState.FULFILLED]: (state, action) => {
            state.kiosksData = action.payload;
            state.kiosksDataState = AsyncState.FULFILLED;
            state.kiosksDataError = "";
        },
        [Actions.getKiosksData + ActionState.REJECTED]: (state, action) => {
            state.kiosksDataState = AsyncState.REJECTED;
            state.kiosksDataError = action.payload;
        },
        // for resetting the state when active board is changed/navigated away.
        "activeBoard/resetActiveBoard": () => {
            return initialState;
        },
        [Actions.getScreens + ActionState.PENDING]: (state) => {
            return {
                ...state,
                showSpinner: true,
                screens: [],
            };
        },
        [Actions.getScreens + ActionState.FULFILLED]: (state, action) => {
            if (action.payload.response && action.payload.response.data) {
                return {
                    ...state,
                    showSpinner: false,
                    screens: action.payload.screens,
                };
            } else {
                return {
                    ...state,
                    showSpinner: false,
                    screens: [],
                };
            }
        },
        [Actions.getScreens + ActionState.REJECTED]: (state) => {
            return {
                ...state,
                showSpinner: false,
                error: true,
                screens: [],
            };
        },
        "screen/delete/pending": (state) => {
            return {
                ...state,
                showSpinner: true,
            };
        },
        "screen/update/pending": (state) => {
            return {
                ...state,
            };
        },
        [Actions.getScreens + ActionState.FULFILLED]: (state, action) => {
            state.showSpinner = false;
            state.screens = action.payload.screens;
            state.screensLoadingStatus = AsyncState.FULFILLED;
        },
        [Actions.getScreens + ActionState.REJECTED]: (state) => {
            state.showSpinner = false;
            state.screens = [];
            state.screensLoadingStatus = AsyncState.REJECTED;
        },
        [Actions.getScreens + ActionState.PENDING]: (state) => {
            state.showSpinner = true;
            state.screensLoadingStatus = AsyncState.PENDING;
        },
        [Actions.premiumActivationToggle + ActionState.REQUEST]: (state: any, action) => {
            const { screenId, value } = action.payload;
            state.screens = state.screens.map((screen: any) => {
                if (screen.screenId === screenId) {
                    return {
                        ...screen,
                        screenLicenseActive: value,
                    };
                }
                return screen;
            });
            state.pendingUpdateScreensIds = [...state.pendingUpdateScreensIds, screenId];
        },
        [Actions.premiumActivationToggle + ActionState.FULFILLED]: (state, action) => {
            const { screenId } = action.payload;
            state.pendingUpdateScreensIds = state.pendingUpdateScreensIds.filter(
                (id: any) => id !== screenId,
            );
        },
        [Actions.premiumActivationToggle + ActionState.REJECTED]: (state: any, action) => {
            const { screenId } = action.payload;
            state.screens = state.screens.map((screen: any) => {
                if (screen.screenId === screenId) {
                    return {
                        ...screen,
                        // In case of failure, we revert the value to its previous value
                        screenLicenseActive: !screen.screenLicenseActive,
                    };
                }
                return screen;
            });
            state.pendingUpdateScreensIds = state.pendingUpdateScreensIds.filter(
                (id: any) => id !== screenId,
            );
        },
    },
});

export const { tabSelected, screenSetupTabSelected, kioskHardwareTabSelected } =
    manageScreensSlice.actions;

export default manageScreensSlice.reducer;
