import { call, put, takeLatest } from "redux-saga/effects";
import * as AppsApi from "../../api/app.service";
import * as BoardApi from "../../api/boards.service";

function* boardDetailsSaga() {
	yield takeLatest("board/details/request", function* (action) {
		try {
			yield put({ type: "board/details/pending", payload: {} });
			let { boardId, boardDetails } = action.payload;
			const boardDetailsApiResponse = yield call(
				async () =>
					await BoardApi.getBoardDetails(boardId, boardDetails)
			);
			if (
				boardDetailsApiResponse &&
				boardDetailsApiResponse.status !== "error"
			) {
				try {
					const boardAppsApiResponse = yield call(
						async () => await AppsApi.getScreenApps(boardId)
					);
					if (boardAppsApiResponse && boardAppsApiResponse.success) {
						yield put({
							type: "board/details/fulfilled",
							payload: {
								boardDetails: boardDetailsApiResponse,
								boardApps: boardAppsApiResponse.data,
							},
						});
					} else {
						yield put({
							type: "board/details/fulfilled",
							payload: {
								boardDetails: boardDetailsApiResponse,
								boardApps: [],
							},
						});
					}
				} catch (err) {
					yield put({
						type: "board/details/fulfilled",
						payload: {
							boardDetails: boardDetailsApiResponse,
							boardApps: [],
						},
					});
				}
			} else {
				yield put({
					type: "board/details/rejected",
					payload: { boardDetails: null },
				});
			}
		} catch (e) {
			yield put({ type: "board/details/rejected", payload: {} });
		}
	});
}

function* leaveBoardSaga() {
	yield takeLatest("board/leave/request", function* (action) {
		try {
			yield put({ type: "board/leave/pending", payload: {} });
			let { boardId, memberId } = action.payload;
			const boardLeaveResponse = yield call(
				async () => await BoardApi.leaveBoard(boardId, memberId)
			);
			if (boardLeaveResponse && boardLeaveResponse.status !== "error") {
				yield put({
					type: "board/details/request",
					payload: {
						boardId: boardId,
						boardDetails: {
							start: 0,
							max: 20,
							addBoardPosts: false,
							upcomingEvents: false,
							freeEvents: false,
							showFeaturedCollection: false,
							showScheduledHoots: true,
							customURL: "",
						},
					},
				});
				yield put({
					type: "user/details/request",
					payload: {},
				});
				yield put({
					type: "board/leave/fulfilled",
					payload: {
						success: true,
					},
				});
			} else {
				yield put({
					type: "board/leave/rejected",
					payload: { success: false },
				});
			}
		} catch (e) {
			yield put({ type: "board/leave/rejected", payload: {} });
		}
	});
}

export const activeBoardSagas = [boardDetailsSaga(), leaveBoardSaga()];
