/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment-timezone";

import { HootEventInfo, HootLike } from "../types/lists";

import { CITYGUIDE_DOMAIN, MAP_BOX } from "../helper/constants";

import { analyticsClient } from "../contexts/mixpanel.context";

import { isColorDark } from "./color.utils";

/**
 * This function is used to transform getList API response itemData into hoot
 * data to use it in HootTile component.
 */
export const transformListHootLike = (data: any): HootLike => {
    const distinctId = analyticsClient.getDistinctID();
    const images =
        data.object === "board"
            ? [
                  {
                      url: MAP_BOX({
                          longitude: data.location.longitude,
                          latitude: data.location.latitude,
                      }),
                  },
              ]
            : data?.images || [{ url: data?.logoUrl || data?.backgroundImageUrl }];
    //
    const tags = data?.tags?.split(",") || data?.collections;
    let locationInfo = null;

    if (data?.locationInfo || data?.location) {
        locationInfo = {
            formattedAddress:
                data?.locationInfo?.formattedAddress || data?.location?.formattedAddress,
            geometry: {
                latitude: data?.locationInfo?.geometry.latitude || data?.location?.latitude,
                longitude: data?.locationInfo?.geometry.longitude || data?.location?.longitude,
            },
            googlePlaceId: data?.locationInfo?.googlePlaceId || data?.location?.googlePlaceId,
            boardLocation: `https://stagecityguide.hootboard.com/category/1?activePlaceId=${
                data?.locationInfo?.googlePlaceId || data?.location?.googlePlaceId
            }&boardId=${data?.id}&distinctId=${distinctId}`,
        };
    }
    const cityGuideLink =
        CITYGUIDE_DOMAIN +
        `/category/1?activePlaceId=${
            data?.locationInfo?.googlePlaceId || data?.location?.googlePlaceId
        }&boardId=${data?.id}&distinctId=${distinctId}`;

    return {
        id: data?.id,
        url: data.object === "board" ? cityGuideLink : data?.url,
        boardTagsList: tags,
        authorId: data?.authorInfo?.id,
        owningBoardId: data?.owningBoardId,
        type: data?.type,
        objectType: data?.object,
        isPinned: data?.isPinned,
        isSaved: data?.isSaved,
        isDemo: data?.isDemo,
        locationInfo: locationInfo,
        rehootAllowed: true,
        sourceType: data?.sourceTypeCode,
        hootStats: {
            favorites: data?.stats?.favCount,
            comments: data?.stats?.commentsCount,
            rehoots: data?.stats?.rehootCount,
        },
        eventTextLocation: data?.eventInfo?.eventTextLoc,
        pinnedComment: {
            commentText: null,
            commentPlainText: null,
            commenterName: null,
        },
        background: data?.colorInfo?.background || data?.customColors?.board?.button || "white",
        font:
            data?.colorInfo?.font ||
            (isColorDark(data.customColors?.board?.button) ? "#ffffff" : "#000000") ||
            "black",
        title: data?.title || data?.name,
        showTitle: true,
        description: data?.description,
        plainTextDescription: data?.descriptionPlainText,
        eventLocationPlaceId: data?.eventInfo?.eventLocPlaceId || data?.location?.googlePlaceId,
        eventLocationText: data?.eventInfo?.eventTextLoc || data?.location?.formattedAddress,
        twitterHandle: data?.twitterInfo?.twitterHandle,
        twitterLink: data?.twitterInfo?.tweetUrl,
        collections: tags,
        authorPictureUrl: data?.authorInfo?.imageUrl,
        authorName: data?.authorInfo?.firstName + " " + data?.authorInfo?.lastName,
        authorInitials: data?.authorInfo?.initials,
        largeImages: [
            {
                id: null,
                width: images[0]?.width,
                url: images[0]?.url,
                height: images[0]?.height,
            },
        ],
        images: [
            {
                id: null,
                width: images[0]?.width,
                url: images[0]?.url,
                height: images[0]?.height,
            },
        ],
        backgroundImageUrl: data?.backgroundImageUrl,
        buttonLink: data?.object == "board" ? cityGuideLink : data?.button?.link,
        buttonLabel: data?.object == "board" ? "Show Details" : (data.type === "HLLIST" ? "View List" : data?.button?.label),
        mediaUrl: data?.embedMediaInfo?.url,
        mediaSnippet: data?.embedMediaInfo?.html,
        pdfUrl: data?.fileInfo && data?.fileInfo?.type === "UFPDF" ? data?.fileInfo?.url : null,
        manage: {
            expiryDate: null,
            favPost: false,
            privateHoot: false,
            setExpiryDate: true,
        },
        postingCategory: data?.majorCategoryCode,
        hootBgColorClass: data?.hootBgColor,
        customHootThemeColors: data?.customHootThemeColors,
        favPost: false,
        requiresApproval: false,
        isScheduled: false,
        postBoards: null,
        scheduledOnDate: null,
        recurringRule: null,
        postedTime: moment(data?.createdAt).fromNow(),
        ...((eventInfo: HootEventInfo) => {
            let eventDateTime = {
                eventStartDateTime: "",
                eventEndDateTime: "",
                eventTimezone: "",
                freeEvent: false,
            };

            if (eventInfo && eventInfo.eventStartDateTime && eventInfo.eventTimezone) {
                eventDateTime = {
                    ...eventDateTime,
                    eventTimezone: eventInfo.eventTimezone,
                };
            }

            if (eventInfo && eventInfo.eventStartDateTime && eventInfo.eventTimezone) {
                eventDateTime = {
                    ...eventDateTime,
                    eventStartDateTime: `${moment(eventInfo.eventStartDateTime)
                        .tz(eventInfo.eventTimezone)
                        .format("ddd, YYYY MMM Do h:mm A")}`,
                };
            }

            if (eventInfo && eventInfo.eventStartDateTime && eventInfo.eventTimezone) {
                eventDateTime = {
                    ...eventDateTime,
                    eventStartDateTime: `${moment(eventInfo.eventStartDateTime)
                        .tz(eventInfo.eventTimezone)
                        .format("ddd, YYYY MMM Do h:mm A")}`,
                };
            }

            if (eventInfo && eventInfo.eventStartDateTime && !eventInfo.eventTimezone) {
                eventDateTime = {
                    ...eventDateTime,
                    eventStartDateTime: `${moment(eventInfo.eventStartDateTime)
                        .tz("america/new_york")
                        .format("ddd, YYYY MMM Do h:mm A")}`,
                };
            }

            if (eventInfo && eventInfo.eventEndDateTime && eventInfo.eventTimezone) {
                eventDateTime = {
                    ...eventDateTime,
                    eventEndDateTime: `${moment(eventInfo.eventEndDateTime)
                        .tz(eventInfo.eventTimezone)
                        .format("ddd, YYYY MMM Do h:mm A")}`,
                };
            }

            if (eventInfo && eventInfo.eventEndDateTime && eventInfo.eventTimezone) {
                eventDateTime = {
                    ...eventDateTime,
                    eventEndDateTime: `${moment(eventInfo.eventEndDateTime)
                        .tz(eventInfo.eventTimezone)
                        .format("ddd, YYYY MMM Do h:mm A")}`,
                };
            }

            if (eventInfo && eventInfo.eventEndDateTime && !eventInfo.eventTimezone) {
                eventDateTime = {
                    ...eventDateTime,
                    eventEndDateTime: `${moment(eventInfo.eventEndDateTime)
                        .tz("america/new_york")
                        .format("ddd, YYYY MMM Do h:mm A")}`,
                };
            }

            return {
                ...eventDateTime,
                freeEvent: (eventInfo && eventInfo.freeEvent) || false,
            };
        })(data?.eventInfo),
    };
};
