import { SHOPIFY_API_KEY } from "../helper/constants";
import {
	Constants,
	apiRequest,
	httpDeleteV2,
	httpGetV2,
	httpPostV2,
} from "./http-wrapper";

/**
 * API to create a new screen
 * @param {Object} payload Refer /helper/screen-params.helper for the list of params
 */
const createScreen = async (boardId, payload) => {
	return await apiRequest("screens", {
		method: "POST",
		payload,
		extraHeaders: {
			[Constants.BOARD_ID_HEADER]: boardId,
		},
	});
};

/**
 * API to update a new screen
 * @param {number} boardId
 * @param {Object} payload - Refer /helper/screen-params.helper for the list of params
 */
const updateScreen = async (boardId, payload) => {
	return await apiRequest(`screens/${payload.screenId}`, {
		method: "PUT",
		payload,
		extraHeaders: {
			[Constants.BOARD_ID_HEADER]: boardId,
		},
	});
};

/**
 * API to toggle license status of a screen
 * @param {Object} params
 * @param {number} params.boardId
 * @param {number} params.screenId
 * @param {boolean} params.screenLicenseActive
 */
export const toggleLicense = async (params) => {
	return await apiRequest(`screens/${params.screenId}/license`, {
		method: "PATCH",
		payload: { screenLicenseActive: params.screenLicenseActive },
		extraHeaders: {
			[Constants.BOARD_ID_HEADER]: params.boardId,
		},
	});
};

/**
 * API to get screens for a board
 * @param {number} boardId
 */
export const getScreens = async (boardId) => {
	return await apiRequest("screens", {
		extraHeaders: {
			[Constants.BOARD_ID_HEADER]: boardId,
		},
	});
};

/**
 * API to get screen information for editing.
 * @param {Object} params
 * @param {number} params.boardId
 * @param {number} params.screenId
 */
export const getScreen = async (params) => {
	return await apiRequest(`screens/${params.screenId}`, {
		extraHeaders: {
			[Constants.BOARD_ID_HEADER]: params.boardId,
		},
	});
};

/**
 * API to delete board screens
 * @param {String} screenId
 */
const deleteBoardScreen = async (screenId) => {
	let response = await httpDeleteV2(`private/screen/${screenId}`, null, null);
	return response;
};

/**
 * API to get board screens messages
 * @param {number} boardId
 * @param {String} screenIds
 * @param {String} screenMessage
 * @param {number} screenMessageId
 */
const saveBoardScreenMessages = async (
	boardId,
	screenIds,
	screenMessage,
	screenMessageId
) => {
	const apiConfig = {
		[Constants.BOARD_ID_HEADER]: boardId,
	};
	const messageParams = {
		screenIds,
		screenMessage,
		screenMessageId,
	};
	let response = await httpPostV2(
		"private/screen/message",
		messageParams,
		apiConfig
	);
	return response;
};

/**
 * API to create board screens messages
 * @param {number} boardId
 */
const getBoardScreenMessages = async (boardId) => {
	const apiConfig = {
		[Constants.BOARD_ID_HEADER]: boardId,
	};
	let response = await httpGetV2("private/screen/message", null, apiConfig);
	return response;
};

/**
 * API to Delete a board screen messages
 * @param {number} messageId - id of message which you want to delete
 */
const deleteBoardScreenMessage = async (messageId) => {
	let response = await httpDeleteV2(
		`private/screen/message/${messageId}`,
		null,
		null
	);
	return response;
};

/**
 * API to get the kiosks data from hootboard store
 */
const getKiosksData = async () => {
	return apiRequest("shopify/query", {
		payload: {
			key: SHOPIFY_API_KEY,
			resource: "products",
			api_version: "2023-07",
		},
	});
};
export {
	createScreen,
	deleteBoardScreen,
	deleteBoardScreenMessage,
	getBoardScreenMessages,
	getKiosksData,
	saveBoardScreenMessages,
	updateScreen
};

