import moment from "moment-timezone";
import ColorChecker from "./color.utils";

//  Scheme of hoot

// let hoot = {
// 	title: "",
// 	description: "",
// 	userQuote: "",
// 	buttonLabel: "",
// 	buttonLink: "",
// 	background: "",
// 	font: "",
// 	type: "",
// 	authorName: "",
// 	authorPictureUrl: "",
// 	authorInitials: "",
// 	twitterLink: "",
// 	twitterHandle: "",
// 	images: "",
// 	showTitle: "",
// 	eventDateTime: "",
// 	eventTimezone: "",
// 	eventLocation: "",
// 	postedTime: "",
// 	pinned: "",
// 	collections: "",
// 	mediaUrl: "",
// 	mediaSnippet: "",
// 	sourceType: "",
// 	rehootAllowed: false,
// 	digFullScreenHoot: false,
// 	digHootTimeoutInSec: 15,
// 	hootStats: {},
// 	fav: false
// };

//  based on the type of hoot, you have to look in several places
//  to gather data in the required schema.
//  My argument with this transform operation is that let's do a transform
//  and get the data in order first so that we don't have to do this condition
//  checking at component level.

// this one here is only used inside the hoot display page.
// data coming from the API should be transformed into above structure.
export const transformDisplayHoot = (hoot) => {
	const result = {
		id: hoot.postId,
        listId: hoot.listId,
        items: hoot.items || [],
		postBoards: hoot.postBoards,
		boardTagsList: hoot.boardTagsList,
		authorId: hoot.ownerId ? hoot.ownerId : null,
		owningBoardId: hoot.owningBoardId ? hoot.owningBoardId : null,
		requiresApproval: !hoot.postApproved,
		isScheduled: hoot.scheduledHoot ? hoot.scheduledHoot : false,
		isSaved: hoot.saved,
		scheduledOnDate: hoot.hootScheduledDate
			? moment(hoot.hootScheduledDateTime).format("Do MMM, YYYY")
			: null,
		type: hoot.hootType,
		isPinned: hoot.pinnedHoot,
		postedTime: hoot.hootPostedTime,
		rehootAllowed: hoot.rehootAllowed,
		sourceType: hoot.sourceType,
		hootTimezone: hoot.hootTimezone,
		hootStats: hoot.hootCounts
			? hoot.hootCounts
			: { favorites: 0, comments: 0, rehoots: 0 },
		eventTextLocation: hoot.eventTextLoc ? hoot.eventTextLoc : null,
		pinnedComment: hoot.pinnedComments
			? hoot.pinnedComments
			: {
					commentText: null,
					commentPlainText: null,
					commenterName: null,
			  },
		...ColorChecker.colors[hoot.hootBgColor],
		...parseTitle(hoot),
		...parseDescription(hoot),
		...parseUserQuote(hoot),
		...parseDates(hoot),
		...parseLocation(hoot),
		...parseTwitterInfo(hoot),
		...parseCollections(hoot),
		...parseAuthorInfo(hoot),
		...parseImages(hoot),
		...parseButtons(hoot),
		...parseEmbedableMedia(hoot),
		...parsePdfInfo(hoot),
		...parseManageHoot(hoot),
		digFullScreenHoot: hoot.digFullScreenHoot,
		digHootTimeoutInSec: hoot.digHootTimeoutInSec,
        createdAt: hoot.timeStamp,
		//TN: Following are added here because these are needed for Edit Hoot
		postingCategory: hoot.postingCategory,
		hootBgColorClass: hoot.hootBgColor,
		customHootThemeColors: hoot.customHootThemeColors,
		calendarOnlyHoot: hoot.calendarOnlyHoot,
		recurringRule: hoot.recurringRule,
		hootExpiryDate: hoot.hootLevelExpDate,
		defaultHoot: hoot.defaultHoot,
		privateHoot: hoot.privateHoot,
	};
	return result;
};

function parseManageHoot(hoot) {
    const defaultValues = {
        digHootTimeoutInSec: 15,
        digFullScreenHoot: false,
        disableComments: false,
        isScheduled: false,
        expiryDate: null,
        scheduledOnDate: "",
        excludeDigests: false,
        favPost: false,
        privateHoot: true,
    };
    const {
        digHootTimeoutInSec,
        digFullScreenHoot,
        disableComments,
        expiryDate,
        scheduledHoot,
        hootScheduledDateTime,
        excludeDigests,
        favPost,
        privateHoot,
    } = hoot;

    return {
        manage: {
            ...defaultValues,
            digFullScreenHoot,
            digHootTimeoutInSec,
            disableComments,
            isScheduled: scheduledHoot,
            scheduledOnDate: moment(hootScheduledDateTime).format("MM/DD/YYYY hh:mm a"),
            setExpiryDate: expiryDate !== "",
            expiryDate: expiryDate !== "" ? expiryDate : null,
            excludeDigests,
            privateHoot,
        },
        favPost,
    };
}

function parseTitle(hoot) {
    const { itemName, postName, showTitle } = hoot;

    return {
        title: itemName ? itemName : postName,
        showTitle: showTitle ? showTitle : true,
    };
}

function parseCollections(hoot) {
    if (hoot.collections && hoot.collections.length) {
        return {
            collections: hoot.collections,
        };
    } else {
        return null;
    }
}

function parseDescription(hoot) {
    switch (hoot.hootType) {
        case "HLVID":
        case "HLBLOG":
        case "HLLIST":
            return {
                description: hoot.aboutLink ? hoot.aboutLink : null,
                plainTextDescription: hoot.aboutLink ? hoot.aboutLink : null,
            };

        default: {
            const { desc, postDesc, descPlainText, postDescPlainText } = hoot;
            return {
                description: desc ? desc : postDesc ? postDesc : null,
                plainTextDescription: descPlainText
                    ? descPlainText
                    : postDescPlainText
                    ? postDescPlainText
                    : null,
            };
        }
    }
}

function parseDates(hoot) {
    let eventDateTime = {
        eventStartDateTime: null,
        eventEndDateTime: null,
        eventTimezone: null,
        freeEvent: true,
    };

    if (hoot.eventTimezone) {
        eventDateTime = {
            ...eventDateTime,
            eventTimezone: hoot.eventTimezone,
        };
    }

    if (hoot.eventStart && hoot.eventTimezone) {
        eventDateTime = {
            ...eventDateTime,
            eventStartDateTime: `${moment(hoot.eventStart)
                .tz(hoot.eventTimezone)
                .format("ddd, YYYY MMM Do h:mm A")}`,
        };
    }

    if (hoot.eventStart && hoot.eventTimezone) {
        eventDateTime = {
            ...eventDateTime,
            eventStartDateTime: `${moment(hoot.eventStart)
                .tz(hoot.eventTimezone)
                .format("ddd, YYYY MMM Do h:mm A")}`,
        };
    }

    if (hoot.eventStart && !hoot.eventTimezone) {
        eventDateTime = {
            ...eventDateTime,
            eventStartDateTime: `${moment(hoot.eventStart)
                .tz("america/new_york")
                .format("ddd, YYYY MMM Do h:mm A")}`,
        };
    }

    if (hoot.eventEnd && hoot.eventTimezone) {
        eventDateTime = {
            ...eventDateTime,
            eventEndDateTime: `${moment(hoot.eventEnd)
                .tz(hoot.eventTimezone)
                .format("ddd, YYYY MMM Do h:mm A")}`,
        };
    }

    if (hoot.eventEnd && hoot.eventTimezone) {
        eventDateTime = {
            ...eventDateTime,
            eventEndDateTime: `${moment(hoot.eventEnd)
                .tz(hoot.eventTimezone)
                .format("ddd, YYYY MMM Do h:mm A")}`,
        };
    }

    if (hoot.eventEnd && !hoot.eventTimezone) {
        eventDateTime = {
            ...eventDateTime,
            eventEndDateTime: `${moment(hoot.eventEnd)
                .tz("america/new_york")
                .format("ddd, YYYY MMM Do h:mm A")}`,
        };
    }

    return {
        ...eventDateTime,
        freeEvent: hoot.freeEvent || false,
    };
}

function parseAuthorInfo(hoot) {
    return {
        authorPictureUrl: hoot.authorPic
            ? hoot.authorPic
            : hoot.ownerPhoto
            ? hoot.ownerPhoto
            : null,
        authorName: hoot.authorName || hoot.ownerName,
        authorInitials: hoot.authorInitials,
    };
}

function parsePdfInfo(hoot) {
    if (hoot.postPdfUrl && hoot.hootType === "UFPDF") {
        return {
            pdfUrl: hoot.postPdfUrl,
        };
    } else {
        return {
            pdfUrl: null,
        };
    }
}

function parseImages(hoot) {
    switch (hoot.hootType) {
        case "HLVID":
        case "HLBLOG":
        case "HLLIST":
            return {
                images: parseInternalImages(hoot.internalImagesNew, "S"),
                largeImages: parseInternalImages(hoot.internalImagesNew, "L"),
            };

        case "DISC":
            return {
                images: parseHootCoverImages(
                    hoot.hootCoverImagesNew.small ? hoot.hootCoverImagesNew : hoot.hootCoverImages,
                    "S",
                ),
                largeImages: parseHootCoverImages(
                    hoot.hootCoverImagesNew.large ? hoot.hootCoverImagesNew : hoot.hootCoverImages,
                    "L",
                ),
            };

        case "UFIMG":
            return hoot.photoPath
                ? {
                      images: [
                          {
                              width: hoot.imgWidth,
                              height: hoot.imgHeight,
                              url: hoot.photoPath,
                          },
                      ],
                      largeImages: [
                          {
                              width: hoot.imgWidth,
                              height: hoot.imgHeight,
                              url: hoot.photoPath,
                          },
                      ],
                  }
                : hoot.postImgUrl
                ? {
                      images: [
                          {
                              width: hoot.imgWidth,
                              height: hoot.imgHeight,
                              url: hoot.postImgUrl,
                          },
                      ],
                      largeImages: [
                          {
                              width: hoot.imgWidth,
                              height: hoot.imgHeight,
                              url: hoot.postImgUrl,
                          },
                      ],
                  }
                : { images: null, largeImages: null };

        case "UFPDF":
            return hoot.photoPath
                ? {
                      images: [
                          {
                              width: hoot.imgWidth,
                              height: hoot.imgHeight,
                              url: hoot.photoPath,
                          },
                      ],
                      largeImages: [
                          {
                              width: hoot.imgWidth,
                              height: hoot.imgHeight,
                              url: hoot.photoPath,
                          },
                      ],
                  }
                : hoot.postImgUrl
                ? {
                      images: [
                          {
                              width: hoot.imgWidth,
                              height: hoot.imgHeight,
                              url: hoot.postImgUrl,
                          },
                      ],
                      largeImages: [
                          {
                              width: hoot.imgWidth,
                              height: hoot.imgHeight,
                              url: hoot.postImgUrl,
                          },
                      ],
                  }
                : { images: null, largeImages: null };

        default:
            return { images: null };
    }
}

function parseInternalImages(internalImages, size) {
    if (internalImages.small && internalImages.small.length && size === "S") {
        return [internalImages.small[0]];
    }

    if (internalImages.large && internalImages.large.length && size === "L") {
        return [internalImages.large[0]];
    }
    return null;
}

function parseHootCoverImages(hootCoverImages, size) {
    if (hootCoverImages && hootCoverImages.small && hootCoverImages.small.length && size === "S") {
        return hootCoverImages.small;
    }

    if (hootCoverImages && hootCoverImages.large && hootCoverImages.large.length && size === "L") {
        return hootCoverImages.large;
    }

    return null;
}

function parseUserQuote(hoot) {
    switch (hoot.hootType) {
        case "HLVID":
        case "HLBLOG":
        case "HLLIST":
            return {
                userQuote: hoot.desc ? hoot.desc : hoot.postDesc ? hoot.postDesc : null,
            };

        default:
            return null;
    }
}

function parseEmbedableMedia(hoot) {
    if (hoot.embedableMedia && hoot.embedableMedia.length) {
        if (hoot.embededMediaUrl && hoot.embededMediaUrl.length) {
            return {
                mediaUrl: hoot.embededMediaUrl,
                mediaSnippet: hoot.embedableMedia,
            };
        } else {
            return {
                mediaUrl: null,
                mediaSnippet: hoot.embedableMedia,
            };
        }
    } else {
        if (hoot.embededMediaUrl && hoot.embededMediaUrl.length) {
            return {
                mediaUrl: hoot.embededMediaUrl,
                mediaSnippet: null,
            };
        } else {
            return {
                mediaUrl: null,
                mediaSnippet: null,
            };
        }
    }
}

function parseLocation(hoot) {
    if (hoot.eventLoc) {
        if (hoot.eventLocFormatdAdd) {
            return {
                eventLocationPlaceId: hoot.eventLoc,
                eventLocationText: hoot.eventLocFormatdAdd,
            };
        } else {
            return {
                eventLocationPlaceId: hoot.eventLoc,
                eventLocationText: null,
            };
        }
    } else {
        return {
            eventLocationPlaceId: null,
            eventLocationText: null,
        };
    }
}

function parseTwitterInfo(hoot) {
    if (hoot.twitterHandle && hoot.twitterHandle.length) {
        if (hoot.tweetUrl && hoot.tweetUrl.length) {
            return {
                twitterLink: hoot.tweetUrl,
                twitterHandle: hoot.twitterHandle,
            };
        }
    }

    return {
        twitterHandle: "",
        twitterLink: "",
    };
}

function parseButtons(hoot) {
    if (hoot.callActionLink && hoot.callActionLink.length) {
        if (hoot.callActionName && hoot.callActionName.length) {
            return {
                buttonLink: hoot.callActionLink,
                buttonLabel: hoot.callActionName,
            };
        }
    }

    return {
        buttonLink: null,
        buttonLabel: null,
    };
}
