import { setToken } from "../utils/auth.utils";
import { httpGetV2, httpPostV2 } from "./http-wrapper";

export const authenticateUser = async (loginEmail, password) => {
	try {
		const response = await httpPostV2(
			"auth",
			{ loginEmail, password },
			null,
			{ rawResponse: true }
		);

		if (response.status === 200) {
			const authToken = response.headers.get("Authorization");
			setToken(authToken.substring(authToken.indexOf(" ")));
			return { loginError: false, activeError: false };
		} else {
			const responseJson = await response.json();
			return responseJson;
		}
	} catch (error) {
		console.error(error);
	}
};

export const registerUser = async (params) => {
	const { fullName, loginEmail, password, existingUserId } = params;
	try {
		const response = await httpPostV2(
			"signup",
			{
				fullName,
				loginEmail,
				password,
				existPartyId: existingUserId,
			},
			null,
			{ rawResponse: true }
		);

		const responseJson = await response.json();

		if (responseJson && !responseJson.signupError) {
			const authToken = response.headers.get("Authorization");
			setToken(authToken.substring(authToken.indexOf(" ")));
		}

		return responseJson;
	} catch (error) {
		console.error(error);
	}
};

/**
 * API to authenticate or create the user based on the FB login crediantials.
 * @param {string} fbAccessToken - Access token provided by FB received in success response for FB login service
 * @param {string} fbUserId - ID of the user from Facebook
 * @param {Object} fbResp - Response received from the FB login service
 */
export const fbLogin = async (fbResp) => {
	try {
		const response = await httpPostV2(
			"auth/fb",
			{
				accesstoken: fbResp.accessToken,
				email: fbResp.email,
				fbType: "login",
				fbid: fbResp.id,
				firstName: fbResp.first_name,
				gender: fbResp.gender,
				lastName: fbResp.last_name,
				name: fbResp.name,
				picUrl: fbResp.picture.data.url,
			},
			null,
			{ rawResponse: true }
		);

		if (response.status === 200) {
			const authToken = response.headers.get("Authorization");
			setToken(authToken.substring(authToken.indexOf(" ")));
		}
		//Removed the ResponseJSON as there is no response in the API
		//Once fixed will be enabled
		// const responseJson = await response.json();
		// return responseJson;
		return true;
	} catch (error) {
		// TODO: Add error logger ("Error occured in fbLogin :: " + error);
	}
};

/**
 * we have to pass the parameters we are getting from the google login response
 * @param socialResp
 */
export const socialLogin = async (socialResp) => {
	try {
		const response = await httpPostV2(
			"auth/social",
			{
				accesstoken: socialResp.accessToken,
				email: socialResp.email,
				firstName: socialResp.firstName,
				gender: "",
				lastName: socialResp.lastName,
				name: socialResp.name,
				picUrl: socialResp.profilePicURL,
				clientId: socialResp.clientId,
				loginSource: socialResp.loginSource == "FB" ? "FA" : socialResp.loginSource,
			},
			null,
			{ rawResponse: true }
		);
		if (response.status === 200) {
			const authToken = response.headers.get("Authorization");
			setToken(authToken.substring(authToken.indexOf(" ")));
		}
		return true;
	} catch (error) {
		// TODO: Add error logger ("Error occured in fbLogin :: " + error);
	}
};

/**
 * API to check if email address already exists or not
 * @param {string} loginEmail Email to check the validity
 */
export const validateEmail = async (loginEmail) => {
	const response = httpGetV2("validate/email", { loginEmail });
	return response;
};

/**
 * API to request for password reset link.
 * @param {string} loginEmail User's login email used to create the account
 */
export const forgotPassword = async (loginEmail) => {
	const response = httpPostV2("password/forgot", { loginEmail });
	return response;
};

/**
 * API to send an activation link
 * @param {string} loginEmail User's login email used to create the account
 */
export const resendActivation = async (loginEmail) => {
	const response = httpPostV2("resend/email", { loginEmail });
	return response;
};

/**
 * @param {string} encPartyId Base64 encoded user ID
 */
export const activateAccount = async (encPartyId) => {
	const payload = {
		encPartyId,
		visitId: 1, // TODO: Might wanna remove this.
	};
	const response = httpPostV2("account/activate", payload);
	return response;
};
