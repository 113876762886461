import { parse, stringify } from "query-string";
import { useNavigate } from "react-router-dom";
import { checkQueryRedirect } from "../utils/general.util";

const performQueryRedirect = (param, navigate) => {
	const hasRedirection = checkQueryRedirect(param.queryName);
	// ignore navigation if not
	if (!hasRedirection) return;

	const boardUrl = location.pathname.split("/").slice(1, 4).join("/");
	const queryParamsObject = parse(location.search);
	if (queryParamsObject[param.queryName]) {
		let otherQueries = "";
		// If other queries are required, remove the `queryName` from the query params.
		// And add rest to the new URL.
		if (param.keepOtherQueries) {
			delete queryParamsObject[param.queryName];
			otherQueries = stringify(queryParamsObject);
		}
		navigate(`/${boardUrl}/${param.navigateTo}${otherQueries}`);
	}
};

const boardNav = (path = "", params = {}, navigate) => {
	let boardUrl = "";
	if (params.boardId && params.boardName) {
		boardUrl = `b/${params.boardId}/${params.boardName.replace(/ /g, "_")}`;
	} else {
		// checking for board URL pattern /b/:boardId/boardName
		const expression = new RegExp(
			/https?:\/\/([\S]+)\/b\/([0-9]+)?\/(\w+)/gm
		);
		// ignore if not
		if (!expression.test(location.href)) return location.href;

		boardUrl = location.pathname.split("/").slice(1, 4).join("/");
	}

	const urlQueryParams = parse(location.search);
	const pathQueryParams = parse(path.split("?")[1] || "");

	const newQueryParams = stringify({
		...urlQueryParams,
		...pathQueryParams,
	});
	const newQueryString = newQueryParams ? `?${newQueryParams}` : "";
	const pathWithoutQueryParams = path.split("?")[0];

	const finalUrl = `/${boardUrl}/${pathWithoutQueryParams}${newQueryString}`;

	if (!params.navigation) return finalUrl;

	navigate(finalUrl);
};

const anchorNavigation = (href, navigate) => {
	return {
		href,
		onClick: (e) => {
			e.preventDefault();
			navigate(href);
		},
	};
};

export const useCustomNavigation = () => {
	const navigate = useNavigate();

	return {
		/**
		 * Return the board link and performs navigation if needed.
		 * @param {string} path
		 * @param {Object} params
		 * @param {boolean} params.navigation
		 * @param {string} params.boardId
		 * @param {string} params.boardName
		 */
		boardNav: (path, params) => boardNav(path, params, navigate),
		/**
		 * Use this to create a `navigate` based experience for <a> tags.
		 * Spread the response as props into a <a> tag.
		 * @param {string} href
		 */
		anchorNavigation: (href) => anchorNavigation(href, navigate),
		/**
		 * Based on the provided query param, redirect the user to given page.
		 * @param {Object} param
		 * @param {string} param.queryName The query param name.
		 * @param {string} param.keepOtherQueries Should we keep other query params.
		 * @param {string} param.navigateTo Page to navigate to.
		 */
		performQueryRedirect: (param) => performQueryRedirect(param, navigate),
	};
};
