/*
 * @Author: Chandu J S
 * @Date:   2020-10-16 22:12:49
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-10-19 16:44:40
 */
import { call, put, takeLatest } from "redux-saga/effects";
import * as Service from "../../api/messaging.service";

function* messagingSend(action) {
	try {
		yield put({
			type: "messaging/send/pending",
		});
		const apiResponse = yield call(
			async () => await Service.sendMessage(action.payload)
		);

		if (!apiResponse.conversationId || !apiResponse.messageId) {
			throw new Error(apiResponse);
		}

		yield put({
			type: "messaging/send/fulfilled",
			payload: apiResponse,
		});
	} catch (e) {
		yield put({
			type: "messaging/send/rejected",
			payload: e,
		});
	}
}

export function* messagingSendSaga() {
	yield takeLatest("messaging/send/request", messagingSend);
}

function* messagingConversations(action) {
	const { silent } = action.payload;

	try {
		if (!silent) {
			yield put({
				type: "messaging/get-conversations/pending",
			});
		}

		const apiResponse = yield call(
			async () => await Service.getConversations()
		);

		if (
			!apiResponse.data ||
			(apiResponse && apiResponse.message !== "success")
		) {
			throw new Error(apiResponse);
		}

		yield put({
			type: "messaging/get-conversations/fulfilled",
			payload: apiResponse.data,
		});
	} catch (e) {
		if (!silent) {
			yield put({
				type: "messaging/get-conversations/rejected",
				payload: e,
			});
		}
	}
}

export function* messagingConversationsSaga() {
	yield takeLatest(
		"messaging/get-conversations/request",
		messagingConversations
	);
}

function* messagingSendReply(action) {
	try {
		yield put({
			type: "messaging/send-reply/pending",
		});
		const { conversationId, messageText, conversationIndex } =
			action.payload;

		const apiResponse = yield call(
			async () => await Service.sendReply({ conversationId, messageText })
		);

		if (
			!apiResponse.messageText ||
			!apiResponse.messageCount ||
			!apiResponse.messageDate
		) {
			throw new Error(apiResponse);
		}

		yield put({
			type: "messaging/send-reply/fulfilled",
			payload: {
				conversationId,
				conversationIndex,
				messageDate: apiResponse.messageDate,
				messgeDivClass: "blue",
				messageText: apiResponse.messageText,
			},
		});
	} catch (e) {
		yield put({
			type: "messaging/send-reply/rejected",
			payload: e,
		});
	}
}

export function* messagingSendReplySaga() {
	yield takeLatest("messaging/send-reply/request", messagingSendReply);
}

function* messagingUnreadCount() {
	try {
		yield put({
			type: "messaging/get-unread/pending",
		});
		const apiResponse = yield call(
			async () => await Service.getUnreadCount()
		);

		if (!apiResponse.unreadMsgCount) {
			throw new Error(apiResponse);
		}

		yield put({
			type: "messaging/get-unread/fulfilled",
			payload: apiResponse.unreadMsgCount,
		});
	} catch (e) {
		yield put({
			type: "messaging/get-unread/rejected",
			payload: e,
		});
	}
}

export function* messagingUnreadCountSaga() {
	yield takeLatest("messaging/get-unread/request", messagingUnreadCount);
}

function* messagingSetRead(action) {
	try {
		yield put({
			type: "messaging/read/pending",
		});
		const { conversationId, conversationIndex } = action.payload;

		const apiResponse = yield call(
			async () => await Service.setRead(conversationId)
		);

		if (!apiResponse.readMsgStatus) {
			throw new Error(apiResponse);
		}

		yield put({
			type: "messaging/read/fulfilled",
			payload: { conversationId, conversationIndex },
		});
	} catch (e) {
		yield put({
			type: "messaging/read/rejected",
			payload: e,
		});
	}
}

export function* messagingSetReadSaga() {
	yield takeLatest("messaging/read/request", messagingSetRead);
}
