/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-24 16:25:23
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-05-26 17:30:37
 */

import { call, put, takeLatest } from "redux-saga/effects";
import * as AccountApi from "../../api/account.service";
import * as Auth from "../../api/authentication.service";

function* updateAccount(action) {
	try {
		yield put({ type: "user/account/update/pending", payload: {} });
		const updateAccountRequest = yield call(
			async () =>
				await AccountApi.updateAccountInfo(action.payload.params)
		);
		if (updateAccountRequest.status !== "error") {
			const accountDetails = yield call(
				async () => await AccountApi.getAccountInfo()
			);
			if (accountDetails) {
				yield put({
					type: "user/account/update/fulfilled",
					payload: { accountDetails: accountDetails },
				});
			} else {
				yield put({
					type: "user/account/update/rejected",
					payload: { accountDetails: null },
				});
			}
		} else {
			yield put({
				type: "user/account/update/rejected",
				payload: {},
			});
		}
	} catch (e) {
		yield put({ type: "user/account/update/rejected", payload: {} });
	}
}

export function* updateAccountSaga() {
	yield takeLatest("user/account/update/request", updateAccount);
}

function* getAccount() {
	try {
		yield put({ type: "user/account/get/pending", payload: {} });
		const accountDetails = yield call(
			async () => await AccountApi.getAccountInfo()
		);
		if (accountDetails) {
			yield put({
				type: "user/account/get/fulfilled",
				payload: { accountDetails: accountDetails },
			});
		} else {
			yield put({
				type: "user/account/get/rejected",
				payload: { accountDetails: null },
			});
		}
	} catch (e) {
		yield put({
			type: "user/account/get/rejected",
			payload: { accountDetails: null },
		});
	}
}

export function* getAccountSaga() {
	yield takeLatest("user/account/get/request", getAccount);
}

function* testPassword(action) {
	try {
		yield put({ type: "user/account/password/test/pending", payload: {} });
		const loginRequest = yield call(
			async () =>
				await Auth.authenticateUser(
					action.payload.loginEmail,
					action.payload.password
				)
		);
		if (!loginRequest) throw new Error(loginRequest);
		if (loginRequest.loginError) throw new Error(loginRequest);

		yield put({
			type: "user/account/password/test/fulfilled",
			payload: { loginSuccess: true },
		});
	} catch (e) {
		yield put({ type: "user/account/password/test/rejected", payload: e });
	}
}

export function* testPasswordSaga() {
	yield takeLatest("user/account/password/test/request", testPassword);
}

function* updatePassword(action) {
	try {
		yield put({
			type: "user/account/password/update/pending",
			payload: {},
		});
		const loginRequest = yield call(
			async () => await AccountApi.updateAccountPassword(action.payload)
		);
		if (loginRequest) {
			yield put({
				type: "user/account/password/update/fulfilled",
				payload: { updatePasswordSuccess: true },
			});
		} else {
			yield put({
				type: "user/account/password/update/rejected",
				payload: { updatePasswordSuccess: false },
			});
		}
	} catch (e) {
		yield put({
			type: "user/account/password/update/rejected",
			payload: {},
		});
	}
}

export function* updatePasswordSaga() {
	yield takeLatest("user/account/password/update/request", updatePassword);
}

function* updateEmail(action) {
	try {
		yield put({
			type: "user/account/email/update/pending",
			payload: {},
		});
		const validationRequest = yield call(
			async () => await Auth.validateEmail(action.payload.params.newEmail)
		);
		if (validationRequest.isMailIDUnique) {
			const loginRequest = yield call(
				async () =>
					await AccountApi.updateAccountEmail(action.payload.params)
			);
			if (loginRequest) {
				const accountDetails = yield call(
					async () => await AccountApi.getAccountInfo()
				);
				if (accountDetails) {
					yield put({
						type: "user/account/email/update/fulfilled",
						payload: { accountDetails: accountDetails },
					});
				} else {
					yield put({
						type: "user/account/email/update/rejected",
						payload: { accountDetails: null },
					});
				}
			} else {
				yield put({
					type: "user/account/email/update/rejected",
					payload: { updateEmailSuccess: false },
				});
			}
		} else {
			yield put({
				type: "user/account/email/update/rejected",
				payload: { updateEmailSuccess: false, isUniqueEmail: false },
			});
		}
	} catch (e) {
		yield put({
			type: "user/account/email/update/rejected",
			payload: {},
		});
	}
}

export function* updateEmailSaga() {
	yield takeLatest("user/account/email/update/request", updateEmail);
}
