/*
 * @Author: tnityanand@hootboard.com
 * @Date: 2020-05-12 00:09:23
 * @Last Modified by: tnityanand@hootboard.com
 * @Last Modified time: 2020-06-07 00:47:44
 */

export const getRandomHootColor = () => {
	const randomColorIndex = Math.floor(Math.random() * colors.length + 0);
	return colors[randomColorIndex].colorValue;
};

const colors = [
	{
		name: "white",
		color: "#fff",
		colorValue: "hoot-bgcolor-white",
		textColor: "text-gray-700",
	},
	{
		name: "grey",
		color: "#cfd9df",
		colorValue: "hoot-bgcolor-grey",
		textColor: "text-gray-700",
	},
	{
		name: "cyan",
		color: "#56d5d8",
		colorValue: "hoot-bgcolor-cyan",
		textColor: "text-gray-700",
	},
	{
		name: "yellow",
		color: "#ffda44",
		colorValue: "hoot-bgcolor-yellow",
		textColor: "text-gray-700",
	},
	{
		name: "brown",
		color: "#e2c495",
		colorValue: "hoot-bgcolor-brown",
		textColor: "text-gray-700",
	},
	{
		name: "pink",
		color: "#fcb1dc",
		colorValue: "hoot-bgcolor-pink",
		textColor: "text-gray-700",
	},
	{
		name: "violet",
		color: "#6a5385",
		colorValue: "hoot-bgcolor-violet",
		textColor: "text-white",
	},
	{
		name: "blue",
		color: "#2584e5",
		colorValue: "hoot-bgcolor-blue",
		textColor: "text-white",
	},
	{
		name: "green",
		color: "#3bab52",
		colorValue: "hoot-bgcolor-green",
		textColor: "text-white",
	},
	{
		name: "red",
		color: "#fe5756",
		colorValue: "hoot-bgcolor-red",
		textColor: "text-white",
	},
	{
		name: "darkblue",
		color: "#34495e",
		colorValue: "hoot-bgcolor-darkblue",
		textColor: "text-white",
	},
];

export default colors;
