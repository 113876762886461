/*
 * @Author: Chandu J S
 * @Date:   2020-08-25 15:47:14
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-08-26 15:56:53
 */
import { httpPostV2 } from "./http-wrapper";

export const unSubscribeBoard = async ({ boardId, encParams }) => {
	const apiConfig = { "x-board-id": boardId };
	const response = await httpPostV2(
		"public/boards/notification/email/unsubscribe",
		{ encParams },
		apiConfig
	);
	return response;
};

export const unSubscribePersonal = async ({ encParams }) => {
	const response = await httpPostV2(
		"public/accmgmt/notification/email/unsubscribe",
		{ encParams },
		null
	);
	return response;
};

export const unSubscribeInvitation = async ({ encParams }) => {
	const response = await httpPostV2(
		"public/invitation/unsubscribe/emails",
		{ encParams },
		null
	);
	return response;
};
