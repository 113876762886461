/*
 * @Author: snair@hootboard.com
 * @Date: 2020-06-10 17:20:04
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-06-10 17:23:39
 */

import { createSlice } from "@reduxjs/toolkit";

export const validateCustomUrlAsync = (payload) => {
	return {
		type: "custom-url/validate/request",
		payload,
	};
};

const initialState = {
	customUrlValidationStatus: "idle",
	isValidCustomUrl: false,
};

const customUrlSlice = createSlice({
	name: "customUrl",
	initialState,
	reducers: {},
	extraReducers: {
		"custom-url/validate/request": (state) => {
			return {
				...state,
				customUrlValidationStatus: "pending",
			};
		},
		"custom-url/validate/fulfilled": (state, action) => {
			return {
				...state,
				customUrlValidationStatus: "fulfilled",
				isValidCustomUrl: action.payload.isValidCustomUrl,
			};
		},
		"custom-url/validate/rejected": (state, action) => {
			return {
				...state,
				customUrlValidationStatus: "rejected",
				isValidCustomUrl: action.payload.isValidCustomUrl,
			};
		},
	},
});
// export const {} = customUrlSlice.actions;
export default customUrlSlice.reducer;
