/**
 * Redux action states.
 */
export enum ActionState {
	REQUEST = "request",
	PENDING = "pending",
	FULFILLED = "fulfilled",
	REJECTED = "rejected",
}

/**
 * When any async operations happens,
 * this can be used to mention the state of the operation.
 */
export enum AsyncState {
	IDLE = "idle",
	PENDING = "pending",
	FULFILLED = "fulfilled",
	REJECTED = "rejected",
}

export enum APIMethods {
	GET = "GET",
	PUT = "PUT",
	POST = "POST",
	PATCH = "PATCH",
	DELETE = "DELETE",
}

/**
 * Add all possible error codes when the development progresses.
 * Error codes will give us better understanding of what went wrong.
 */
export enum ErrorCodes {
	EMAIL_ALREADY_EXISTS = "Email already exists!",
	EMAIL_NOT_FOUND = "Email not found!",
	EMAIL_NOT_VALIDATED = "Your account isn't activated yet.",
	GENERIC = "Something wrong happened! Please try again.",
	INVALID_CREDENTIALS = "Invalid credentials!",
	INVALID_REQUEST = "Invalid request!",
	INVALID_URL_OR_SHORTCODE = "Invalid urlOrShortCode",
	METHOD_NOT_ALLOWED = "Invalid request method!",
	NO_MORE_HOOT = "That's it. No more hoots are available!",
	NO_NOTIFICATIONS = "Current page has no notifications!",
	NOT_FOUND = "Entry not found!",
	UNEXPECTED_ERROR = "Unexpected server error!",
}
