/* eslint-disable no-useless-escape */
import {
	HOOTBOARD_ANALYTICS_ENDPOINT,
	API_ENDPOINT,
	API_KEY,
	MIXPANEL_BASIC_AUTHORIZATION,
	ANALYTICS_EU_USAGE_GROUP,
	ENGAGEMENT_CHANNELS,
	ANALYTICS_SDK_KEY,
	ANALYTICS_API_URL,
} from "../helper/constants";
import moment from "moment-timezone";
import { getNextResetDate } from "../utils/analytics.utils";

import { httpGetV2 } from "./http-wrapper";
import { analyticsClient } from "../contexts/mixpanel.context";

/**
 * If the selectedTimeInterval is in the format of CUSTOM-startDate-endDate
 * then we will split and check the first element and compute the start date and endDate
 *
 * If the selectedTimeInterval is in the format of WEEK, TODAY, YESTERDAY,
 * LAST_7_DAYS,LAST_30_DAYS, LAST_3_MONTHS,LAST_6_MONTHS AND LAST_12_MONTHS
 * we will calculate start date and end date from the present day
 * @param {string} selectedTimeInterval
 */
const computeStartAndEndDates = (selectedTimeInterval) => {
	const customInterval = selectedTimeInterval.split("-");

	if (customInterval[0] === "CUSTOM") {
		const intervals = selectedTimeInterval.split("-");
		const endInterval = intervals[1];
		const startInterval = intervals[2];
		const endDate = moment(startInterval).format("YYYY-MM-DD");
		const startDate = moment(endInterval).format("YYYY-MM-DD");
		return {
			endDate: endDate,
			startDate: startDate,
		};
	} else if (selectedTimeInterval === "WEEK") {
		const endDate = moment().format("YYYY-MM-DD");
		const startDate = moment(moment().subtract(1, "week")).format(
			"YYYY-MM-DD"
		);

		return {
			endDate: endDate,
			startDate: startDate,
		};
	} else if (selectedTimeInterval === "TODAY") {
		const endDate = moment().format("YYYY-MM-DD");
		const startDate = moment().format("YYYY-MM-DD");
		return {
			endDate: endDate,
			startDate: startDate,
		};
	} else if (selectedTimeInterval === "YESTERDAY") {
		const endDate = moment().format("YYYY-MM-DD");
		const startDate = moment().format("YYYY-MM-DD");
		return {
			endDate: endDate,
			startDate: startDate,
		};
	} else if (selectedTimeInterval === "LAST_7_DAYS") {
		const endDate = moment().format("YYYY-MM-DD");
		const startDate = moment(moment().subtract(7, "days")).format(
			"YYYY-MM-DD"
		);
		return {
			endDate: endDate,
			startDate: startDate,
		};
	} else if (selectedTimeInterval === "LAST_30_DAYS") {
		const endDate = moment().format("YYYY-MM-DD");
		const startDate = moment(moment().subtract(30, "days")).format(
			"YYYY-MM-DD"
		);
		return {
			endDate: endDate,
			startDate: startDate,
		};
	} else if (selectedTimeInterval === "LAST_3_MONTHS") {
		const endDate = moment().format("YYYY-MM-DD");
		const startDate = moment(moment().subtract(3, "months")).format(
			"YYYY-MM-DD"
		);
		return {
			endDate: endDate,
			startDate: startDate,
		};
	} else if (selectedTimeInterval === "LAST_6_MONTHS") {
		const endDate = moment().format("YYYY-MM-DD");
		const startDate = moment(moment().subtract(6, "months")).format(
			"YYYY-MM-DD"
		);
		return {
			endDate: endDate,
			startDate: startDate,
		};
	} else if (selectedTimeInterval === "LAST_12_MONTHS") {
		const endDate = moment().format("YYYY-MM-DD");
		const startDate = moment(moment().subtract(12, "months")).format(
			"YYYY-MM-DD"
		);
		return {
			endDate: endDate,
			startDate: startDate,
		};
	} else {
		console.error("Not supported format");
	}
};

/**
 * @param {Object} params
 * @param {any} params.boardId
 * @param {any} params.selectedTimeInterval
 * */
const fetchAnalyticsForSavedContent = async (params) => {
	try {
		const { startDate, endDate } = computeStartAndEndDates(
			params.selectedTimeInterval
		);

		const response = await httpGetV2(
			"private/board/saveforlater/analytics",
			{ startDate, endDate },
			{ "x-board-id": params.boardId }
		);

		if (!response) throw Error();

		return {
			success: true,
			error: false,
			errorMessage: "",
			data: response.data || [],
		};
	} catch (error) {
		return {
			success: false,
			error: true,
			errorMessage: "Fetching analytics for saved content failed",
			data: [],
		};
	}
};
/**
 * Used to get activity channels based on the groupId
 */
const fetchActivityChannels = async () => {
	let request = await fetch(
		`${ANALYTICS_API_URL}/v1/events-group?key=${ANALYTICS_SDK_KEY}
		&groupId=${ANALYTICS_EU_USAGE_GROUP}`
	);
	if (request.ok) {
		let response = await request.json();
		if (response && response.success) {
			return {
				success: true,
				error: false,
				errorMessage: "",
				data: response.data,
			};
		} else {
			return {
				success: true,
				error: false,
				errorMessage: "",
				data: [],
			};
		}
	}
};

const fetchAnalyticsForBoardId = async ({ boardId, selectedTimeInterval }) => {
	let { startDate, endDate } = computeStartAndEndDates(selectedTimeInterval);

	let request = await fetch(
		`${HOOTBOARD_ANALYTICS_ENDPOINT}/analytics?boardId=${boardId}&startDate=${startDate}&endDate=${endDate}`,
		{
			method: "GET",
			headers: {
				Accept: "application/json; charset=utf-8",
				"x-access-token": `${localStorage.getItem("authToken")}`,
			},
		}
	);

	if (request.ok) {
		let response = await request.json();

		if (response && response.success) {
			return {
				success: true,
				error: false,
				errorMessage: "",
				data: response,
			};
		} else {
			return {
				success: true,
				error: false,
				errorMessage: "",
				data: [],
			};
		}
	} else {
		return {
			success: false,
			error: true,
			errorMessage: "Fetching analytics for boardId failed",
			data: null,
		};
	}
};

/**
 * @param {Array} hootIds
 * @param {number} boardId
 */
const fetchHoots = async ({ hootIds, boardId }) => {
	const apiEndpoint = `private/screens/hoots`;
	const params = { hootIds: hootIds.join(",") };
	const apiConfig = {
		Accept: "application/json; charset=utf-8",
		"x-board-id": boardId,
	};
	const response = await httpGetV2(apiEndpoint, params, apiConfig);
	if (response) {
		if (response && response.data && response.data.length) {
			return {
				success: true,
				error: false,
				errorMessage: "",
				result: response.data,
			};
		} else {
			return {
				success: true,
				error: false,
				errorMessage: "",
				result: [],
			};
		}
	} else {
		return {
			success: false,
			error: true,
			errorMessage: "Fetching hoot details for hoot ids failed",
			results: null,
		};
	}
};

const fetchScreenAppsForBoardId = async (boardId) => {
	let request = await fetch(
		API_ENDPOINT + `/screens/apps?boardId=${boardId}`,
		{
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"x-api-key": API_KEY,
			},
		}
	);

	if (request.ok) {
		let response = await request.json();

		if (response.data && response.data.length) {
			return {
				success: true,
				error: false,
				errorMessage: "",
				infoMessage: "",
				apps: response.data,
			};
		} else {
			return {
				success: true,
				error: false,
				errorMessage: "",
				infoMessage: "No board details could be fetched.",
				apps: [],
			};
		}
	} else {
		return {
			success: false,
			error: true,
			errorMessage: "Fetching hoot details for hoot ids failed",
			results: null,
			hoots: 0,
			collections: 0,
			followerCount: 0,
		};
	}
};

const fetchBoardVitalsFromBoardId = async (boardId, partyId) => {
	let request = await fetch(API_ENDPOINT + "/boards", {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			appMethod: "boardDetails",
			params: {
				apikey: API_KEY,
				boardDetailsView: {
					partyId: partyId,
					boardId: boardId,
					addBoardPosts: false,
				},
			},
		}),
	});

	if (request.ok) {
		let response = await request.json();

		if (response.boardExists) {
			return {
				success: true,
				error: false,
				errorMessage: "",
				infoMessage: "",
				numberOfHoots: response.hootsCount,
				numberOfCollections: response.filters.length,
				numberOfFoollowers: response.followerCount,
				boardName: response.commName,
				boardLicenses: response.boardLicenses,
			};
		} else {
			return {
				success: true,
				error: false,
				errorMessage: "",
				infoMessage: "No board details could be fetched.",
				hoots: 0,
				collections: 0,
				followers: 0,
			};
		}
	} else {
		return {
			success: false,
			error: true,
			errorMessage: "Fetching hoot details for hoot ids failed",
			results: null,
			hoots: 0,
			collections: 0,
			followerCount: 0,
		};
	}
};

/**
 * Get engagement unit usage of a board for current month.
 * @param {Object} params
 * @param {string} params.boardId
 * @param {string} params.cycleResetDay
 */
export const getEngagementUnitUsage = async (params) => {
	try {
		const nextResetDate = getNextResetDate(params.cycleResetDay);

		const startDateTime = nextResetDate
			.subtract("month", 1)
			.toDate()
			.getTime();
		const endDateTime = moment().toDate().getTime();

		const segmentationData = await analyticsClient.segmentation({
			startDateTime,
			endDateTime,
			groupBy: ["channel"],
			timeZone: moment.tz.guess(),
			event: ANALYTICS_EU_USAGE_GROUP,
			filter: `properties.boardId IN ${params.boardId},"${params.boardId}"`,
		});

		// Since segmentation API can give all channels information, we only need a few

		const [webMobileUsage, kioskUsage] = segmentationData.reduce(
			([webMobile, kiosk], item) => {
				if (ENGAGEMENT_CHANNELS.webMobile.includes(item.label)) {
					return [webMobile + item.count, kiosk];
				} else if (item.label == ENGAGEMENT_CHANNELS.touchKiosk) {
					return [webMobile, kiosk + item.count];
				}
				return [webMobile, kiosk];
			},
			[0, 0]
		);

		return {
			success: true,
			data: {
				webMobileUsage,
				kioskUsage,
			},
		};
	} catch (error) {
		return {
			success: false,
			message: "Fetching analytics for boardId failed",
		};
	}
};

/**
 * @param {object} payload
 * @param {object} payload.params
 * @param {string} payload.params.startDate
 * @param {string} payload.params.endDate
 * @param {number} payload.params.boardId
 */
const fetchBoardAppEngagement = async (payload) => {
	const { params } = payload;
	try {
		const encodedParams = new URLSearchParams();

		encodedParams.set(
			"script",
			`function main() {
				return Events({
				  from_date: '${params.startDate}',
				  to_date:   '${params.endDate}',
				  event_selectors:[{event:"Viewed Board App"}]
				})
				.filter(item => item.properties.boardId === "${params.boardId}" )
				.map(event =>{
				  return {
				  appIconUrl:event.properties.appIconUrl,
				  boardId:event.properties.boardId,
				  appName:event.properties.appName
				  }
				}).groupBy(["appName"],[mixpanel.reducer.count(),mixpanel.reducer.any()]).sortDesc("value.0");
			  }`
		);
		encodedParams.set(
			"params",
			`{
  			 "scriptParam": "paramValue"
			 }`
		);

		const url = "https://mixpanel.com/api/2.0/jql";
		const options = {
			method: "POST",
			headers: {
				Authorization: MIXPANEL_BASIC_AUTHORIZATION,
				accept: "application/json",
				"content-type": "application/x-www-form-urlencoded",
			},
			body: encodedParams,
		};

		const request = await fetch(url, options);
		if (!request) throw new Error();
		if (!request.ok) throw new Error();

		const response = await request.json();
		if (!response) throw new Error();

		return {
			success: true,
			error: false,
			errorMessage: false,
			infoMessage: "",
			result: response,
		};
	} catch (error) {
		return {
			result: [],
			success: false,
			message: "Fetching analytics for boardId failed",
		};
	}
};

/**
 * @param {object} payload
 * @param {object} payload.params
 * @param {string} payload.params.startDate
 * @param {string} payload.params.endDate
 * @param {number} payload.params.boardId
 */
const fetchHootEngagement = async (payload) => {
	const { params } = payload;
	try {
		const encodedParams = new URLSearchParams();

		encodedParams.set(
			"script",
			`function main() {
				return Events({
				  from_date: '${params.startDate}',
				  to_date:   '${params.endDate}',
				  event_selectors:[{event:"Viewed Hoot Display Page"}],
				})
				.filter(item => item.properties.boardId === ${params.boardId} && item.properties.title !== undefined )
				.map(event =>{
				  return {
				  hootId:event.properties.hootId,
				  boardId:event.properties.boardId,
				  title:event.properties.title
				  }
				}).groupBy(["title"],[mixpanel.reducer.count(),mixpanel.reducer.any()]).sortDesc('value.0');
			  }`
		);
		encodedParams.set(
			"params",
			`{
  			 "scriptParam": "paramValue"
			 }`
		);

		const url = "https://mixpanel.com/api/2.0/jql";
		const options = {
			method: "POST",
			headers: {
				Authorization: MIXPANEL_BASIC_AUTHORIZATION,
				accept: "application/json",
				"content-type": "application/x-www-form-urlencoded",
			},
			body: encodedParams,
		};

		const request = await fetch(url, options);
		if (!request) throw new Error();
		if (!request.ok) throw new Error();

		const response = await request.json();
		if (!response) throw new Error();

		return {
			success: true,
			error: false,
			errorMessage: false,
			infoMessage: "",
			result: response,
		};
	} catch (error) {
		return {
			result: [],
			success: false,
			message: "Fetching analytics for boardId failed",
		};
	}
};
export {
	fetchAnalyticsForBoardId,
	fetchHoots,
	fetchBoardVitalsFromBoardId,
	fetchScreenAppsForBoardId,
	fetchAnalyticsForSavedContent,
	fetchBoardAppEngagement,
	fetchHootEngagement,
	fetchActivityChannels,
};
