import { call, put, takeLatest } from "redux-saga/effects";
import * as PricingAPI from "../../api/pricing.service";

export const Actions = {
	saveCard: "pricing/save-card/",
	notifyAdmin: "pricing/notify-admin/",
	fetchInvoices: "pricing/fetch-invoices/",
	processCheckout: "pricing/process-checkout/",
	fetchCustomerCard: "pricing/fetch-customer-card/",
	updateBillingMethod: "pricing/update-billing-method/",
	fetchProductPricing: "pricing/fetch-product-pricing/",
	updateCustomerDetails: "pricing/update-customer-details/",
	fetchBoardSubscriptions: "pricing/fetch-board-subscriptions/",
	subscriptionCancellation: "pricing/subscription-cancellation/",
};

function* notifyAdminSaga() {
	yield takeLatest(Actions.notifyAdmin + "request", function* (action) {
		try {
			yield put({
				type: Actions.notifyAdmin + "pending",
			});

			const apiResult = yield call(async () => {
				return await PricingAPI.notifyAdmin(action.payload); // payload is boardId
			});

			if (!apiResult) throw new Error(apiResult);
			if (!apiResult.result) throw new Error(apiResult);

			yield put({
				type: Actions.notifyAdmin + "fulfilled",
			});
		} catch (error) {
			yield put({
				type: Actions.notifyAdmin + "rejected",
				payload: error,
			});
		}
	});
}

function* startCheckoutSaga() {
	yield takeLatest(Actions.processCheckout + "request", function* (action) {
		try {
			yield put({ type: Actions.processCheckout + "pending" });

			const apiResult = yield call(async () => {
				return await PricingAPI.createSubscription(action.payload);
			});

			if (!apiResult) throw new Error(apiResult);
			if (!apiResult.message) throw new Error(apiResult);
			if (apiResult.message !== "success") throw new Error(apiResult);
			if (!apiResult.data) throw new Error(apiResult);
			if (!apiResult.data.subscriptionId) throw new Error(apiResult);

			yield put({
				type: Actions.processCheckout + "fulfilled",
				payload: apiResult,
			});
		} catch (error) {
			yield put({
				type: Actions.processCheckout + "rejected",
				payload: error,
			});
		}
	});
}

function* fetchProductPricingSaga() {
	const base = Actions.fetchProductPricing;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(
				async () => await PricingAPI.getProductPricing(boardId)
			);

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (e) {
			yield put({
				type: base + "rejected",
				payload: e,
			});
		}
	});
}

function* fetchCustomerCardSaga() {
	const base = Actions.fetchCustomerCard;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(
				async () => await PricingAPI.getCustomerCard(boardId)
			);

			if (apiResponse) {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (e) {
			yield put({
				type: base + "rejected",
				payload: e,
			});
		}
	});
}

function* saveCardSaga() {
	const base = Actions.saveCard;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId, stripeToken } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(
				async () =>
					await PricingAPI.saveCustomerCard(boardId, stripeToken)
			);

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (e) {
			yield put({
				type: base + "rejected",
				payload: e,
			});
		}
	});
}

function* fetchBoardSubscriptionsSaga() {
	const base = Actions.fetchBoardSubscriptions;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(
				async () => await PricingAPI.getSubscriptions(boardId)
			);

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (e) {
			yield put({
				type: base + "rejected",
				payload: e,
			});
		}
	});
}

function* subscriptionCancellationSaga() {
	const base = Actions.subscriptionCancellation;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId, subscriptionId } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(
				async () =>
					await PricingAPI.cancelSubscription(boardId, subscriptionId)
			);

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success" &&
				apiResponse.data &&
				apiResponse.data.status
			) {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (e) {
			yield put({
				type: base + "rejected",
				payload: e,
			});
		}
	});
}

function* updateCustomerDetailsSaga() {
	const base = Actions.updateCustomerDetails;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId, adminEmail } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(
				async () => await PricingAPI.updateCustomer(boardId, adminEmail)
			);

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (e) {
			yield put({
				type: base + "rejected",
				payload: e,
			});
		}
	});
}

function* updateBillingMethodSaga() {
	const base = Actions.updateBillingMethod;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId, subscriptionId, billingMethod } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(
				async () =>
					await PricingAPI.updateSubscription(
						boardId,
						subscriptionId,
						billingMethod
					)
			);

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (e) {
			yield put({
				type: base + "rejected",
				payload: e,
			});
		}
	});
}

function* fetchInvoicesSaga() {
	const base = Actions.fetchInvoices;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId, subscriptionId } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(
				async () =>
					await PricingAPI.getInvoices(boardId, subscriptionId)
			);

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (e) {
			yield put({
				type: base + "rejected",
				payload: e,
			});
		}
	});
}

export const pricingSagas = [
	startCheckoutSaga(),
	fetchProductPricingSaga(),
	fetchCustomerCardSaga(),
	saveCardSaga(),
	fetchBoardSubscriptionsSaga(),
	subscriptionCancellationSaga(),
	updateCustomerDetailsSaga(),
	updateBillingMethodSaga(),
	fetchInvoicesSaga(),
	notifyAdminSaga(),
];
