/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* FIXME: Fix lint issues. */
import {
	ADV_API,
	API_ACCESS_KEY,
	API_URL_LATEST,
	SCRAPPER_URL
} from "../helper/constants";
import { APIMethods } from "../types";
import {
	apiRequest,
	httpDeleteV2,
	httpGetV2,
	httpPostV2,
	httpPutV2
} from "./http-wrapper";

/**
 * API to fetch collection names of a board. Mostly used in CollectionSelector component.
 * @param {number} boardId
 */
const getBoardCollections = async ({ boardId }) => {
	let response = await httpGetV2("private/board/tags", { boardId }, null);
	return response;
};

/**
 * API to search the board name for the given input text
 * @param {string} searchString - Text for searching the board with
 */
const searchBoards = async ({ searchString }) => {
	let response = await httpGetV2(
		"private/boards/advanced_search",
		{ searchString },
		null
	);
	return response;
};

// /**
//  * TODO : The boardId is a mandatory parameter for almost all of the APIs
//  * We can decide to keep the boardId in some store so that it doesn't have to be passed to each function individually
//  */

// /**
//  * API to create a new board
//  * @param {Object} boardParams - Refer /helper/board-params.helper for the list of params
//  */
// const createBoard = async boardParams => {
// 	let response = await httpPostV2("private/boards", boardParams, null);
// 	return response;
// };

const leaveBoard = async (boardId, memberId) => {
	const apiConfig = {
		"x-board-id": boardId,
	};
	let response = await httpDeleteV2(
		`private/membership/remove/${memberId}`,
		null,
		apiConfig
	);
	return response;
};

/**
 * API to update the board
 * @param {number} boardId - ID of the board which is updating
 * @param {Object} boardParams  - Refer /helper/board-params.helper for the list of params
 */
const manageBoard = async (boardId, boardParams) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let response = await httpPutV2("private/boards", boardParams, apiConfig);
	return response;
};

/**
 * API for fetching the board details
 * @param {number} boardId - ID of the board
 * @param {Object} params - Object of parameters
 * @param {number} params.start - Starting index for the hoots
 * @param {number} params.max - Maximum number of hoots for the page
 * @param {boolean} params.addBoardPosts - Whether the posts are included in the response
 * @param {boolean} params.upcomingEvents - Show upcoming events only
 * @param {boolean} params.freeEvents - Show free events only
 * @param {boolean} params.showFeaturedCollection - Show hoots having the featured collection
 * @param {boolean} params.showScheduledHoots - Show scheduled hoots or not
 * @param {String} params.customURL - Find board using the custom URL
 */
const getBoardDetails = async (
	boardId,
	params = {
		start: 0,
		max: 20,
		addBoardPosts: false,
		upcomingEvents: false,
		freeEvents: false,
		showFeaturedCollection: false,
		showScheduledHoots: true,
		customURL: "",
	}
) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let apiEndpoint = "public/boards";
	if (localStorage.getItem("authToken")) {
		apiEndpoint = "private/boards";
	}

	let response = httpGetV2(apiEndpoint, params, apiConfig);
	return response;
};

/**
 * API to get the list of members, followeres, private viewers on the board
 * @param {number} boardId
 */
const getBoardMembers = async (boardId) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let response = await httpGetV2("private/boards/members", null, apiConfig);
	return response;
};

/**
 * API to get the list of pending membership requests
 * @param {number} boardId
 */
const getPendingRequests = async (boardId) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let response = await httpGetV2(
		"private/boards/pendingRequests",
		null,
		apiConfig
	);
	return response;
};

/**
 * API to save the day of the week on which the weekly newsletter will be sent
 * @param {number} boardId
 * @param {string} weeklyDigestDay - 2 char abbrevation for the day of the week
 */
const saveWeeklyDigestDay = async (boardId, weeklyDigestDay) => {
	const apiConfig = { "x-board-id": boardId };

	const params = { weeklyDigestDay };

	let response = await httpPostV2(
		"private/boards/weeklyDigest",
		params,
		apiConfig
	);
	return response;
};

/**
 * API to save custome short URL for the board
 * @param {number} boardId
 * @param {string} customURL - Short custome URL added by the admin
 */
const saveCustomUrl = async (boardId, customURL) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let response = await httpPostV2(
		`private/boards/customUrl?customUrl=${customURL}`,
		null,
		apiConfig
	);
	return response;
};

/**
 * API call to fetch board notifications setting.
 * @return all notifications settings or the respective board.
 */
export const getBoardNotifications = async (boardId) => {
	return apiRequest(`notifications/boards/${boardId}`);
};

/**
 * API to update the notification settings for the board.
 * @return update all notifications settings or the respective board.
 */
export const saveBoardNotifications = async (boardId, params) => {
	return apiRequest(`notifications/boards/${boardId}`, {
		method:"POST",
		payload: params,
	});
};

/**
 * API to get teh list of pending invite requests made by the admin
 * @param {number} boardId
 */
const getPendingAdminInvites = async (boardId) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let response = await httpGetV2(
		"private/boards/admin/invites",
		null,
		apiConfig
	);
	return response;
};

/**
 * API to delete the board
 * @param {number} boardId
 * @param {string} delBoardMsg - Message added by the admin during deletion
 */
const deleteBoard = async (boardId, delBoardMsg) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let endPoint = "private/boards";
	if (delBoardMsg != null && delBoardMsg !== "") {
		endPoint = `${endPoint}/?adminNote=${delBoardMsg}`;
	}

	let response = await httpDeleteV2(endPoint, null, apiConfig);
	return response;
};

/**
 * API to get the hoot to display
 * @param {number} boardId
 * @param {number} postId
 */
const getHoot = async (boardId, postId) => {
	const apiConfig = { "x-board-id": boardId };
	const params = { postId };
	let apiEndpoint = "public/hoot";
	if (localStorage.getItem("authToken")) {
		apiEndpoint = "private/hoot";
	}
	let response = await httpGetV2(apiEndpoint, params, apiConfig);
	return response;
};

/**
 * API to get the next set of hoots for a board
 * @param {number} boardId
 * @param {Object} params
 */
const getHoots = async (boardId, params) => {
	const apiConfig = { "x-board-id": boardId };
	let apiEndpoint = "public/hoots/board";
	if (localStorage.getItem("authToken")) {
		apiEndpoint = "private/hoots/board";
	}
	let response = await httpGetV2(apiEndpoint, params, apiConfig);
	return response;
};

/**
 * API to get saved hoots for a board
 * @param {number} boardId
 * @param {Object} params
 */
const getSavedHoots = async (boardId, params) => {
	const apiConfig = { "x-board-id": boardId };
	let apiEndpoint = "user/saveForLater/hoots";
	if (localStorage.getItem("authToken")) {
		apiEndpoint = "private/user/saveForLater/hoots";
	}
	let response = await httpGetV2(apiEndpoint, params, apiConfig);
	return response;
};

/**
 * API to save localy saved hoots
 * @param {number} boardId
 * @param {Object} data
 */
const saveLocalHoots = async (boardId, data) => {
	const apiConfig = { "x-board-id": boardId };
	const apiEndpoint = "private/user/saveForLater";
	let response = await httpPostV2(apiEndpoint, data, apiConfig);
	return response;
};

/**
 * API to unsave hoots
 * @param {number} boardId
 * @param {Object} hootId
 */
const unSaveHoot = async (boardId, hootId) => {
	const apiConfig = { "x-board-id": boardId };
	const apiEndpoint = "private/user/saveForLater/remove/" + hootId;
	let response = await httpPutV2(apiEndpoint, hootId, apiConfig);
	return response;
};

/**
 * API to get tags for board
 * @param {number} boardId
 */
const getTags = async (boardId) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpGetV2("private/tags", null, apiConfig);
	return response;
};

/**
 * API to resend invite
 * @param {number} invitationId
 */
const resendInvitationReq = async (invitationId) => {
	const response = await httpPutV2(
		`private/invitation/admin/resend/${invitationId}`,
		null,
		null
	);
	return response;
};

/**
 * API to revoke invite
 * @param {number} invitationId
 */
const revokeInvitationReq = async (invitationId) => {
	const response = await httpDeleteV2(
		`private/invitation/admin/remove/${invitationId}`,
		null,
		null
	);
	return response;
};

/**
 * API to change member type
 * @param {number} boardId
 * @param {number} memberId
 * @param {string} accessLevel
 */
const changeMemberTypeReq = async (boardId, memberId, accessLevel) => {
	return apiRequest(`boards/${boardId}/members/${memberId}/access`, {
		method: "PATCH",
		payload: {
			accessLevel,
		},
	});
};

/**
 * API for fetching the board details
 * @param {number} boardId - ID of the board
 * @param {Object} params - Object of parameters
 * @param {number} params.start - Starting index for the hoots
 * @param {number} params.max - Maximum number of hoots for the page
 * @param {boolean} params.addBoardPosts - Whether the posts are included in the response
 * @param {boolean} params.upcomingEvents - Show upcoming events only
 * @param {boolean} params.freeEvents - Show free events only
 * @param {boolean} params.showFeaturedCollection - Show hoots having the featured collection
 * @param {boolean} params.showScheduledHoots - Show scheduled hoots or not
 * @param {String} params.customURL - Find board using the custom URL
 */
const getManageBoardDetails = async (boardId, params) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let apiEndpoint = "public/boards";
	if (localStorage.getItem("authToken")) {
		apiEndpoint = "private/boards";
	}

	let response = httpGetV2(apiEndpoint, params, apiConfig);
	return response;
};

/**
 * API to update board collection
 * @param {number} boardId
 * @param {number} collectionId
 * @param {Object} params
 * @param {boolean} params.featured
 * @param {number} parentTagId
 * @param {number} price
 * @param {string} tagText
 */
const updateBoardCollection = async (boardId, collectionId, params) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpPutV2(
		`private/tags/${collectionId}`,
		params,
		apiConfig
	);
	return response;
};

/**
 * API to delete board collection
 * @param {number} boardId
 * @param {number} collectionId
 */
const deleteBoardCollection = async (boardId, collectionId) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpDeleteV2(
		`private/tags/${collectionId}`,
		null,
		apiConfig
	);
	return response;
};

/**
 * API to add board collection
 * @param {number} boardId
 * @param {Object} params
 */
const addBoardCollection = async (boardId, params) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpPostV2("private/tags", params, apiConfig);
	return response;
};

/**
 * API to pin/star a collection
 * @param {number} boardId
 * @param {number} filterId
 * @param {boolean} featured
 */
const updateCollectionFeatured = async (boardId, filterId, featured) => {
	const apiConfig = { "x-board-id": boardId };
	const params = { featured, filterId };

	const response = await httpPutV2(
		"private/tags/featured",
		params,
		apiConfig
	);
	return response;
};

/**
 * API to pin the board
 * @param {number} boardId - ID of the board which you want to pin
 */
const pinBoard = async (boardId) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let response = await httpPutV2("private/boards/pin", null, apiConfig);
	return response;
};

/**
 * API to unpin the board
 * @param {number} boardId - ID of the board which you want to pin
 */
const unPinBoard = async (boardId) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let response = await httpPutV2("private/boards/unpin", null, apiConfig);
	return response;
};

/**
 * API to send invites
 * @param {number} boardId
 * @param {Object} params
 * @param {boolean} params.adminInvite
 * @param {boolean} params.partnerBoard
 * @param {string} params.boardName
 * @param {string} params.inviteEmails
 * @param {string} params.inviteMsg
 * @param {string} params.inviteeName
 * @param {string} params.membType
 */
const sendInvites = async (boardId, params) => {
	const apiConfig = { "x-board-id": boardId };

	let newParams = {};
	if (params.membType === "EP") {
		newParams = {
			...params,
			membType: "mem",
		};

		const response = await fetch(
			`${API_URL_LATEST}/membership/extended-roles`,
			{
				method: "POST",
				body: JSON.stringify({
					emails: newParams.inviteEmails,
					membershipAccessTypeCode: "ME",
					extendedRoles: ["EP"],
				}),
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"x-board-id": boardId,
					// Authorization: `Bearer ${authToken}`,
				},
			}
		);
	} else {
		newParams = {
			...params,
		};
	}

	const response = await httpPostV2(
		"private/boards/invite",
		newParams,
		apiConfig
	);
	return response;
};

const joinBoard = async (params) => {
	let { boardId, visitId, screenAccessToken, refId, inviteType } = params;
	let AdminInviteTypes = ["204", "206", "207"];
	const apiConfig = { "x-board-id": boardId };

	let isAdminInvite = AdminInviteTypes.includes(inviteType);

	let apiEndpoint = isAdminInvite
		? "private/invitation/admin"
		: "private/membership/follow";

	let response = await httpPostV2(
		apiEndpoint,
		isAdminInvite
			? { visitId, screenAccessToken, refId }
			: { visitId, screenAccessToken, encRefId: refId },
		apiConfig
	);
	return response;
};

const changeBoardAccess = async (params) => {
	let { boardId, boardCommunityCode, screenAccessToken, encRefId } = params;
	const apiConfig = { "x-board-id": boardId };

	let apiEndpoint = "private/membership/access";

	let response = await httpPutV2(
		apiEndpoint,
		{ boardCommunityCode, screenAccessToken, encRefId },
		apiConfig
	);
	return response;
};

const requestBoardAccess = async (params) => {
	let { boardId, screenAccessToken, encRefId, boardCommunityCode } = params;
	const apiConfig = { "x-board-id": boardId };

	let apiEndpoint = "private/membership/request";

	let response = await httpPostV2(
		apiEndpoint,
		{ boardCommunityCode, screenAccessToken, encRefId },
		apiConfig
	);
	return response;
};

/**
 * API to check availability of custom url
 */
const checkUrlAvailability = async (boardId, shortUrl) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpPutV2(
		`private/boards/url?shortUrl=${shortUrl}`,
		null,
		apiConfig
	);
	return response;
};

/**
 * API to upload bulk invite CSV
 */
const sendBulkInvites = async (boardId, fileName, orginalFileName) => {
	const params = {
		boardId,
		fileName,
		orginalFileName,
		invId: 0,
	};

	const apiConfig = { "x-board-id": boardId };
	const response = await httpPostV2(
		"private/invitation/bulk",
		params,
		apiConfig
	);
	return response;
};

/** API to process hoot approval request
 * @param {number} boardId
 * @param {Object} params
 * @param {number} params.memberId
 * @param {string} params.membershipType
 */
const acceptPendingRequest = async (boardId, params) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpPutV2(
		"private/membership/process/accept",
		params,
		apiConfig
	);
	return response;
};

/** API to process hoot approval request
 * @param {number} boardId
 * @param {Object} params
 * @param {number} params.memberId
 * @param {string} params.membershipType
 */
const declinePendingRequest = async (boardId, params) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpPutV2(
		"private/membership/process/decline",
		params,
		apiConfig
	);
	return response;
};

/** API to process hoot approval
 * @param {number} boardId
 * @param {number} hootId
 * @param {string} postApproval
 * @param {string} removeMsg
 */
const processHootApproval = async (
	boardId,
	hootId,
	postApproval,
	removeMsg
) => {
	const apiConfig = { "x-board-id": boardId };
	const params = { postApproval, removeMsg, approvalReqFrom: "approval" };

	const response = await httpPutV2(
		`private/boards/hootApproval/${hootId}`,
		params,
		apiConfig
	);
	return response;
};

/** API to process join board request
 * @param {number} boardId
 * @param {Object} params
 * @param {number} params.memberId
 * @param {number} params.membershipReqAction
 * @param {string} params.membershipType
 */
const processMembshipReq = async (boardId, params) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpPutV2(
		"private/membership/process/pending",
		params,
		apiConfig
	);
	return response;
};

/** API to add a parent board
 * @param {number} boardId
 * @param {number} parentBoardId
 */
const addParentBoard = async (boardId, parentBoardId) => {
	const apiConfig = { "x-board-id": boardId };
	const params = { parentBoardId };

	const response = await httpPostV2(
		"private/subboards/parent",
		params,
		apiConfig
	);
	return response;
};

/** API to remove a parent board
 * @param {number} boardId
 * @param {number} parentBoardId
 */
const removeParentBoard = async (boardId, parentBoardId) => {
	const apiConfig = { "x-board-id": boardId };
	const params = { parentBoardId };

	const response = await httpPostV2(
		"private/subboards/removeParent",
		params,
		apiConfig
	);
	return response;
};

/** API to remove a subboard
 * @param {number} boardId
 * @param {number} subboardId
 */
const removeSubboard = async (boardId, subboardId) => {
	const apiConfig = { "x-board-id": boardId };
	const params = { boardId: subboardId };

	const response = await httpPostV2(
		"private/subboards/remove",
		params,
		apiConfig
	);
	return response;
};

const getOnboardStatuses = async (boardId) => {
	const apiConfig = { "x-board-id": boardId };
	const response = await httpGetV2(
		"private/onboardStatuses",
		null,
		apiConfig
	);
	return response;
};

const saveOnboardStatuses = async (boardId, onboardStatuses) => {
	const apiConfig = { "x-board-id": boardId };
	const response = await httpPostV2(
		"private/onboardStatuses",
		onboardStatuses,
		apiConfig
	);
	return response;
};

/**
 * API to save the embed config
 * @param {*} boardId
 * @param {*} embedConfigParams
 */
const saveEmbedConfig = async (boardId, embedConfigParams) => {
	const apiConfig = { "x-board-id": boardId };
	const response = await httpPostV2(
		"private/board/embed",
		embedConfigParams,
		apiConfig
	);
	return response;
};

/**
 * API to update the existing embed config
 * @param {*} boardId
 * @param {*} embedConfigParams
 */
const updateEmbedConfig = async (boardId, embedConfigParams) => {
	const apiConfig = { "x-board-id": boardId };
	const response = await httpPutV2(
		"private/board/embed",
		embedConfigParams,
		apiConfig
	);
	return response;
};

/**
 * API to fetch the embed config
 * @param {*} boardId
 */
const getEmbedConfig = async (boardId) => {
	const apiConfig = { "x-board-id": boardId };
	const response = await httpGetV2("private/board/embed", null, apiConfig);
	return response;
};

/**
 * API to fetch the embed config by accessToken
 * @param {*} accessToken
 */
const loadEmbedConfig = async (accessToken) => {
	const params = { accessToken: accessToken };
	const response = await httpGetV2("public/board/embed", params, null);
	return response;
};

export {
	declinePendingRequest,
	removeSubboard,
	removeParentBoard,
	addParentBoard,
	processMembshipReq,
	processHootApproval,
	acceptPendingRequest,
	sendBulkInvites,
	checkUrlAvailability,
	sendInvites,
	updateBoardCollection,
	deleteBoardCollection,
	addBoardCollection,
	manageBoard,
	getBoardDetails,
	getBoardMembers,
	getPendingRequests,
	searchBoards,
	saveWeeklyDigestDay,
	saveCustomUrl,
	getPendingAdminInvites,
	unSaveHoot,
	getSavedHoots,
	saveLocalHoots,
	deleteBoard,
	getHoot,
	getHoots,
	getTags,
	resendInvitationReq,
	revokeInvitationReq,
	changeMemberTypeReq,
	pinBoard,
	unPinBoard,
	getManageBoardDetails,
	updateCollectionFeatured,
	joinBoard,
	changeBoardAccess,
	requestBoardAccess,
	getOnboardStatuses,
	saveOnboardStatuses,
	leaveBoard,
	saveEmbedConfig,
	updateEmbedConfig,
	getEmbedConfig,
	loadEmbedConfig,
	getBoardCollections,
};

/**
 * API to get all completed quick start guide steps
 * @param {Object} params
 * @param {number} params.boardId
 */
export const getAllCompletedSteps = async (params) => {
	const headers = {
		"x-board-id": params.boardId,
	};
	let response = await httpGetV2("private/board/startguide", null, headers);

	return response;
};

/**
 * API to mark as done quick start guide step
 * @param {Object} params
 * @param {number} params.boardId
 * @param {Object} params.data
 * @param {string} params.data.startGuideTourName
 * @param {string} params.data.startGuideTourStatus
 */
export const markStepAsDone = async (params) => {
	const headers = {
		"x-board-id": params.boardId,
	};
	let response = await httpPostV2(
		"private/board/startguide",
		params.data,
		headers
	);
	return response;
};

/**
 * API for fetching different board types.
 */
export const getBoardTypes = async () => {
	let response = await httpGetV2("public/boards/type");

	return response;
};

/**
 * API to get all board categories.
 */
export const getCategories = async () => {
	let response = await httpGetV2("public/business/categories", null, null);
	return response;
};

/**
 * API to create a new board
 * @param {Object} boardParams - Refer /helper/board-params.helper for the list of params
 */
export const createBoard = async (boardParams) => {
	let response = await httpPostV2("private/boards", boardParams, null);
	return response;
};

/**
 * API to create location information of a board
 * @param {Object} params
 * @param {number} params.boardId
 * @param {Array} params.data
 */
export const createLocations = async (params) => {
	const headers = {
		"x-board-id": params.boardId,
	};
	let response = await httpPostV2(
		"private/boards/location",
		params.data,
		headers
	);
	return response;
};

/**
 * API to save location information of a board
 * @param {Object} params
 * @param {number} params.boardId
 * @param {Array} params.data
 */
export const updateLocations = async (params) => {
	const headers = {
		"x-board-id": params.boardId,
	};
	let response = await httpPutV2(
		"private/boards/location",
		params.data,
		headers
	);
	return response;
};

/**
 * API to create business details of a board
 * @param {Object} params
 * @param {number} params.boardId
 * @param {Object} params.data
 */
export const createBusinessDetails = async (params) => {
	const headers = {
		"x-board-id": params.boardId,
	};
	let response = await httpPostV2(
		"private/business/details",
		params.data,
		headers
	);
	return response;
};

/**
 * API to update business details of a board
 * @param {Object} params
 * @param {number} params.boardId
 * @param {Object} params.data
 */
export const updateBusinessDetails = async (params) => {
	const headers = {
		"x-board-id": params.boardId,
	};
	let response = await httpPutV2(
		"private/business/details",
		params.data,
		headers
	);
	return response;
};

/**
 * API to create business hours of a board
 * @param {Object} params
 * @param {number} params.boardId
 * @param {Object} params.data
 */
export const createBusinessHours = async (params) => {
	const headers = {
		"x-board-id": params.boardId,
	};
	let response = await httpPostV2(
		"private/business/hours",
		params.data,
		headers
	);
	return response;
};

/**
 * API to update business hours of a board
 * @param {Object} params
 * @param {number} params.boardId
 * @param {Object} params.data
 */
export const updateBusinessHours = async (params) => {
	const headers = {
		"x-board-id": params.boardId,
	};
	let response = await httpPutV2(
		"private/business/hours",
		params.data,
		headers
	);
	return response;
};

/**
 * API to save business photos of a board
 * @param {Object} params
 * @param {number} params.boardId
 * @param {Array} params.data - Business photos data.
 */
export const savePhotos = async (params) => {
	const headers = {
		"x-board-id": params.boardId,
	};
	let response = await httpPostV2(
		"private/business/photos",
		params.data,
		headers
	);
	return response;
};

/**
 * API to delete business photo
 * @param {Object} params
 * @param {number} params.boardId
 * @param {string} params.data
 */
export const deletePhoto = async (params) => {
	const headers = {
		"x-board-id": params.boardId,
	};
	let response = await httpDeleteV2(
		"private/business/photos/" + params.data,
		null,
		headers
	);
	return response;
};

/**
 * API to search for businesses
 * @param {Object} params
 * @param {string} params.query Search query.
 * @param {string} params.latitude Latitude of the user.
 * @param {string} params.longitude Longitude of the user.
 */
export const searchBusinesses = async (params) => {
	let response = await httpGetV2("public/business/search", params, null);
	return response;
};

/**
 * API to get business details of a UUID
 * @param {Object} params
 * @param {string} params.uuid
 * @param {string} params.provider
 */
export const getBusinessDetails = async (params) => {
	let response = await httpGetV2("public/business/details", params, null);
	return response;
};

/**
 * API to get request claim business
 * @param {Object} params
 * @param {number} params.boardId
 * @param {string} params.phoneNumber
 * @param {string} params.notes
 */
export const claimBusiness = async (params) => {
	const headers = {
		"x-board-id": params.boardId,
	};
	let response = await httpPostV2("private/business/claim", params, headers);
	return response;
};

/**
 * API to get static image URL of a business photo.
 * We are using our scrapper service to do the processing.
 * @param {string} photoReference
 */
export const fetchBusinessPhoto = async (photoReference) => {
	/**
	 * for faster image loads, we can ask for image with height & width as 1px
	 * (the returned static URL can return any size based on the last pattern in the URL)
	 * @ref see `importBusinessPhotosSaga()` method for more details.
	 */
	const apiResponse = await fetch(
		`${SCRAPPER_URL}/gpxy/places/details/photos/static?photoReference=${photoReference}&width=1&height=1`
	);
	const data = await apiResponse.json();
	return data;
};

/**
 * API to register the user with advertising platform.
 * @param {object} params
 * @param {string} params.fullName
 * @param {string} params.email
 * @param {object} params.userDetails
 * @param {object} params.boardDetails
 */
export const registerForAdvertising = async (params) => {
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
	};
	const authToken = localStorage.getItem("authToken");
	headers["Authorization"] = `Bearer ${authToken}`;

	// We don't need certain fields from the OG response.

	const { userBoards: _, ...userDetails } = params.userDetails || {};
	const {
		hoots: __,
		boardCollections: ___,
		...boardDetails
	} = params.boardDetails || {};

	await fetch(ADV_API + "/advertisers", {
		headers,
		method: "POST",
		body: JSON.stringify({
			...userDetails,
			boardDetails,
			fullName: params.fullName,
			email: params.email,
			context: "hootboardCreateBoardFlow",
		}),
	});
};

/**
 * API to subscribe a board with email
 */
export const subscribeEmail = async (boardId, payload) => {
	return apiRequest(`boards/${boardId}/subscribe`, {
		method: APIMethods.POST,
		extraHeaders: {
			"x-access-key": API_ACCESS_KEY,
		},
		payload,
	});
};

/**
 * API to find if the business exists on google and hootboard database based on placeId and business name.
 */
export const checkBusinessExists = async (placeId, businessName) => {
	return apiRequest(`businesses/search`, {
		payload: { id: placeId, name: businessName },
	});
};
