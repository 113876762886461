import QueryString from "query-string";
import { API_URL, API_URL_LATEST } from "../helper/constants";

export const Constants = {
	BOARD_ID_HEADER: "x-board-id",
};

/**
 * API request wrapper.
 * This function will always use the latest API version.
 * Use the older implementations (httpGetV2, httpPutV2, etc.) if you need to use an older version.
 *
 * @param {string} resource Path to the API route.
 * @param {Object} additionalParams
 * @param {string=} additionalParams.method "GET" by default.
 * @param {Object=} additionalParams.payload Body of the request
 * @param {Object=} additionalParams.extraHeaders Additional headers like `x-board-id`.
 * @param {number=} additionalParams.version API version. Latest version by default.
 * @param {boolean=} additionalParams.rawResponse Should the response be returned as raw or parsed after 200 status check.
 */
export const apiRequest = async (resource, additionalParams = {}) => {
	// If version is not provided, consider as latest version (v3).
	const isLatestAPI = !additionalParams.version;

	const requestOptions = {
		method: additionalParams.method || "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			...(additionalParams.extraHeaders || {}),
		},
	};

	let apiResourcePath = `${resource}`;
	let isPrivate = false;
	const authToken = localStorage.getItem("authToken");

	if ((apiResourcePath.includes("private") || isLatestAPI) && authToken) {
		isPrivate = true;
		requestOptions.headers["Authorization"] = `Bearer ${authToken}`;
	}

	if (additionalParams.payload) {
		if (requestOptions.method === "GET") {
			const queryParams = QueryString.stringify(additionalParams.payload);
			apiResourcePath = `${apiResourcePath}?${queryParams}`;
		} else {
			requestOptions.body = JSON.stringify(
				additionalParams.payload || {}
			);
		}
	}

	const apiURL = isLatestAPI
		? API_URL_LATEST
		: `${API_URL}/v${additionalParams.version}`;

	const response = await fetch(
		`${apiURL}/${apiResourcePath}`,
		requestOptions
	);

	if (additionalParams.rawResponse) return response;

	// In case of a token expired situation, we need to let the user know and redirect to login page.
	if (isPrivate && response.status === 401) {
		window.clearSession();
		localStorage.setItem("sessionError", true); // This is for an error message in login page.
		// There could be multiple API requests happening one after another. If we already cleared the localStorage, there won't be any authToken available. In that case, we dont want to redirect again.
		if (authToken) {
			window.location.href = "/login";
		}
		return "Unauthorized";
	}

	let jsonResponse = null;
	try {
		jsonResponse = await response.json();
	} catch {
		jsonResponse = { result: "1" };
	}

	if (!response.ok) {
		return {
			status: "error",
			message: "Server responded with unexpected error",
			response: jsonResponse,
		};
	}

	return jsonResponse;
};

// Below functions should be removed after we finished the migration to latest API version.

/**
 * Refer to `apiRequest()`
 */
export const httpPostV2 = (...args) => {
	return apiRequest(args[0], {
		method: "POST",
		payload: args[1],
		extraHeaders: args[2] || {},
		version: 2,
		...(args[3] || {}),
	});
};

/**
 * Refer to `apiRequest()`
 */
export const httpGetV2 = (...args) => {
	return apiRequest(args[0], {
		method: "GET",
		payload: args[1],
		extraHeaders: args[2] || {},
		version: 2,
		...(args[3] || {}),
	});
};

/**
 * Refer to `apiRequest()`
 */
export const httpPutV2 = async (...args) => {
	return apiRequest(args[0], {
		method: "PUT",
		payload: args[1],
		extraHeaders: args[2] || {},
		version: 2,
		...(args[3] || {}),
	});
};

/**
 * Refer to `apiRequest()`
 */
export const httpDeleteV2 = async (...args) => {
	return apiRequest(args[0], {
		method: "DELETE",
		payload: args[1],
		extraHeaders: args[2] || {},
		version: 2,
		...(args[3] || {}),
	});
};
