/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-15 13:28:56
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-12-09 12:07:25
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	getEmbedConfig,
	saveEmbedConfig,
	updateEmbedConfig,
} from "../../api/boards.service";
import {
	embedWindowDims,
	mapEmbedConfigParams,
	mapSelectedCollections,
} from "./embed.utils";

export const saveConfig = createAsyncThunk(
	"/board/embed/saveConfig",
	async ({ configParams }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();
		const response = await saveEmbedConfig(
			boardId,
			mapEmbedConfigParams(configParams)
		);

		return { response };
	}
);

export const updateConfig = createAsyncThunk(
	"/board/embed/updateConfig",
	async ({ configParams }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();
		const response = await updateEmbedConfig(
			boardId,
			mapEmbedConfigParams(configParams)
		);

		return { response };
	}
);

export const fetchConfig = createAsyncThunk(
	"/board/embed/fetchConfig",
	async (x, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await getEmbedConfig(boardId);

		return { response };
	}
);

const initialState = {
	isEmbed: window.location.hostname.includes("embed"),
	// isEmbed: true, //for test

	embedCode: "",
	embedConfigId: 0,
	selectedCollections: [],
	selectedSizingOption: "responsive",
	selectedEmbedSize: {
		height: 0,
		width: 0,
	},
	embedIframeSize: {
		width: "100%",
		height: "630px",
	},
	selectedEmbedView: "AI",
	showPosterName: true,
	showComments: true,
	allowedDomains: "",
	allowPrivateAccess: false,
	embedAccessToken: "",

	saveEmbedConfigStatus: "idle",
	updateEmbedConfigStatus: "idle",
	fetchEmbedConfigStatus: "idle",
	loadEmbedConfigStatus: "idle",

	collectionBehavior: "U",

	// create state in slice for collection
};

const embedSlice = createSlice({
	name: "embed",
	initialState,
	reducers: {
		clearEmbedData: () => {
			return initialState;
		},
		embedCodeGenerated: (state, action) => {
			let embedCode = action.payload;
			return {
				...state,
				embedCode: embedCode,
			};
		},
		collectionAdded: (state, action) => {
			let selectedCollections = action.payload;
			return {
				...state,
				selectedCollections: selectedCollections,
			};
		},
		collectionRemoved: (state, action) => {
			return {
				...state,
				selectedCollections: state.selectedCollections.filter(
					(item) => item.collectionId !== action.payload.collectionId
				),
			};
		},
		sizingOptionsSelected: (state, action) => {
			return {
				...state,
				selectedSizingOption: action.payload,
				embedIframeSize:
					action.payload === "responsive"
						? initialState.embedIframeSize
						: state.embedIframeSize,
			};
		},
		embedIframeSizeSelected: (state, action) => {
			let embedIframeSize = action.payload;
			return {
				...state,
				embedIframeSize: embedIframeSize,
			};
		},
		embedSizeSelected: (state, action) => {
			let embedSize = {
				height: action.payload.height,
				width: action.payload.width,
			};
			return {
				...state,
				selectedEmbedSize: embedSize,
			};
		},
		embedViewSelected: (state, action) => {
			return {
				...state,
				selectedEmbedView: action.payload,
			};
		},
		showPosterNameToggled: (state, action) => {
			return {
				...state,
				showPosterName: action.payload,
			};
		},
		showCommentsToggled: (state, action) => {
			return {
				...state,
				showComments: action.payload,
			};
		},
		allowedDomainsAdded: (state, action) => {
			return {
				...state,
				allowedDomains: action.payload,
			};
		},
		allowPrivateAccessToggled: (state, action) => {
			return {
				...state,
				allowPrivateAccess: action.payload,
			};
		},
		removeEmbedAccessToken: (state) => {
			return {
				...state,
				embedAccessToken: "",
			};
		},
		setCollectionBehavior: (state, action) => {
			return {
				...state,
				collectionBehavior: action.payload,
			};
		},
		resetFixedSize: (state) => {
			return {
				...state,

				selectedEmbedSize: {
					height: 0,
					width: 0,
				},
				embedIframeSize: {
					width: "100%",
					height: "630px",
				},
			};
		},
	},
	extraReducers: {
		[saveConfig.fulfilled]: (state, action) => {
			if (action.payload.response) {
				let response = action.payload.response;
				if (response.data) {
					return {
						...state,
						embedConfigId: response.data.id,
						embedAccessToken: response.data.embedAccessToken,
						saveEmbedConfigStatus: "success",
					};
				}
			}
		},
		[saveConfig.pending]: (state) => {
			return {
				...state,
				saveEmbedConfigStatus: "pending",
			};
		},
		[saveConfig.rejected]: (state, action) => {
			if (action.payload.response) {
				return {
					...state,
					saveEmbedConfigStatus: "rejected",
				};
			}
		},
		[updateConfig.fulfilled]: (state, action) => {
			if (action.payload.response) {
				let response = action.payload.response;
				if (response.data) {
					return {
						...state,
						embedAccessToken: response.data.embedAccessToken,
						updateEmbedConfigStatus: "success",
					};
				}
			}
		},
		[updateConfig.pending]: (state) => {
			return {
				...state,
				updateEmbedConfigStatus: "pending",
			};
		},
		[updateConfig.rejected]: (state, action) => {
			if (action.payload.response) {
				return {
					...state,
					updateEmbedConfigStatus: "rejected",
				};
			}
		},
		[fetchConfig.fulfilled]: (state, action) => {
			const data = ((action.payload || {}).response || {}).data || {};

			return {
				...state,
				embedConfigId: data.id || initialState.embedConfigId,
				selectedEmbedView:
					data.boardView || initialState.selectedEmbedView,
				selectedSizingOption: data.embedWindowSize
					? "fixedSize"
					: "responsive",

				selectedEmbedSize: data.embedWindowSize
					? embedWindowDims(data.embedWindowSize, false)
					: initialState.selectedEmbedSize,

				embedIframeSize: data.embedWindowSize
					? embedWindowDims(data.embedWindowSize, true)
					: initialState.embedIframeSize,

				showPosterName:
					data.showPosterName === undefined
						? initialState.showPosterName
						: data.showPosterName,

				showComments:
					data.showComments === undefined
						? initialState.showComments
						: data.showComments,

				allowedDomains:
					data.allowedDomains || initialState.allowedDomains,
				selectedCollections: data.collections
					? mapSelectedCollections(data.collections)
					: initialState.selectedCollections,
				embedAccessToken:
					data.embedAccessToken || initialState.embedAccessToken,
				// TODO: Uncomment this when the private access is enabled
				// allowPrivateAccess:
				// 	embedConfigData.embedAccessToken !== "" ? true : false,
				fetchEmbedConfigStatus: "success",
				collectionBehavior:
					data.collectionBehavior || initialState.collectionBehavior,
			};
		},
		[fetchConfig.pending]: (state, action) => {
			if (action.payload) {
				return {
					...state,
					fetchEmbedConfigStatus: "pending",
				};
			}
		},
		[fetchConfig.rejected]: (state, action) => {
			if (action.payload) {
				return {
					...state,
					fetchEmbedConfigStatus: "rejected",
				};
			}
		},
	},
});

export const {
	clearEmbedData,
	embedCodeGenerated,
	collectionAdded,
	collectionRemoved,
	sizingOptionsSelected,
	embedIframeSizeSelected,
	embedSizeSelected,
	embedViewSelected,
	showPosterNameToggled,
	showCommentsToggled,
	allowedDomainsAdded,
	allowPrivateAccessToggled,
	removeEmbedAccessToken,
	setCollectionBehavior,
	resetFixedSize,
} = embedSlice.actions;

export default embedSlice.reducer;
