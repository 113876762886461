import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkIfAdmin } from "../utils/accessLevel.utils";
import { getRandomHootColor } from "../components/PostHootNew/utils/hootBgColor.util";

import { boardLinkGenerator } from "../helper/boardLinkGenerator.helper";
// slices
import {
	getAllCompletedSteps,
	markStepAsDone,
} from "../components/OnBoarding/onBoarding.slice";

import { boardInfoTabView } from "../components/ManageBoard/manageBoardSlice";

import {
	showInviteMembersModal,
	memberTypeSelected,
} from "../components/ManageMembers/manageMembers.slice";
import { setPostHootModalVisibility } from "../redux/visibility.slice";
import {
	postingCategorySelected,
	hootTypeSelected,
	uploadFileButtonToggled,
	hootBgColorSelected,
	hootEventControlToggled,
	hootCoverImageControlToggled,
} from "../../src/components/PostHootNew/postHoot.slice";
import { OutlineButton } from "../hb-ui/Button";
// icons
import OrganizeYourHootBoardIcon from "../components/OnBoarding/QuickStartGuide/assets/organize-your-HootBoard.svg";
import SetUpYourKioskIcon from "../components/OnBoarding/QuickStartGuide/assets/set-up-your-kiosk.svg";
import SetUpTheBasicIcon from "../components/OnBoarding/QuickStartGuide/assets/set-up-the-basics.svg";
import PostContentIcon from "../components/OnBoarding/QuickStartGuide/assets/post-content.svg";
import AdvertiseOnHootBoardIcon from "../components/OnBoarding/QuickStartGuide/assets/hootBoard-info.svg";
import AddHootBoardOnYourPlatformIcon from "../components/OnBoarding/QuickStartGuide/assets/add-HootBoard-on-your-platform.svg";
import SeeYourHootBoardEngagementIcon from "../components/OnBoarding/QuickStartGuide/assets/see-your-HootBoard-Engagement.svg";
import CompleteYourBusinessProfileIcon from "../components/OnBoarding/QuickStartGuide/assets/complete-your-business-profile.svg";
import bringLifeToHootBoardIcon from "../components/OnBoarding/QuickStartGuide/assets/bring-life-to-HootBoard.svg";

//
import { MixpanelContext } from "./mixpanel.context";
import { navigateToAdPlatform } from "../utils/general.util";
import { screenSetupTabSelected } from "../../src/components/ManageScreensNew/manageScreens.slice";
import ActionContentItem from "../components/OnBoarding/QuickStartGuide/components/StartGuideActions/ActionContentItem.component";
import { useCustomNavigation } from "../hooks/useCustomNavigation";

export const OnBoardingContext = createContext();

/**
 * Board level on-boarding functionality.
 * @param {OnBoardingProvider.propTypes} props
 */
export const OnBoardingProvider = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { boardNav } = useCustomNavigation();

	const {
		isOnBoardContext,
		boardId,
		membershipType,
		boardType,
		boardName,
		completedSteps,
		createdBoard,
	} = useSelector(
		({
			activeBoard,
			onBoarding: { completedSteps },
			createBoard: { createdBoard },
		}) => {
			const boardDetails = activeBoard.boardDetails || {};

			return {
				boardDetails,
				// this boardId will be only available when the user is in board context.
				// don't get confused with the boardId from the boardDetails object.
				isOnBoardContext: !!activeBoard.boardId,
				boardId: boardDetails.boardId,
				boardType: boardDetails.boardType,
				membershipType: boardDetails.membershipType,
				boardName: boardDetails.boardName,
				purchasedLicenses: boardDetails.purchasedLicenses || {},
				completedSteps,
				createdBoard,
			};
		}
	);

	const boardIdInCreatedBoard = createdBoard ? createdBoard.boardId : "";

	/**
	 * This UI for `Improve Guest Experience` section.
	 */
	const StaticKioskComponentUI = () => {
		return (
			<>
				<div className="mt-1 ml-10">
					Explore more about HootBoard ready kiosks from one of our
					partners.
				</div>

				<OutlineButton
					onClick={() => {
						navigate(
							`${boardLinkGenerator(
								boardId,
								boardName
							)}/manage/screens/learn-more`
						);
					}}
					className="border-b mt-3 mb-3 ml-10 border-hb-brand-blue text-hb-brand-blue border rounded"
				>
					<span className="text-xs sm:text-sm md:text-base">
						Learn about our Kiosk Software
					</span>
				</OutlineButton>
				<div className="grid gap-2 mt-3">
					<ActionContentItem
						title="Set up your FREE Interactive Kiosk"
						handleOnClick={() => {
							dispatch(screenSetupTabSelected(0));
							boardNav("manage/screens/screens-setup", {
								navigation: true,
							});
						}}
					/>
					<ActionContentItem
						title="Set up your FREE Digital Signage"
						handleOnClick={() => {
							dispatch(screenSetupTabSelected(1));
							boardNav("manage/screens/screens-setup", {
								navigation: true,
							});
						}}
					/>
				</div>
			</>
		);
	};

	const generateActionLink = (boardId, boardName, linkName) => {
		return `${boardLinkGenerator(boardId, boardName)}/${linkName}`;
	};
	/**
	 * Contains all quick start guide steps for all board types.
	 * The reason why we didn't put this in a JSON file is because we want to add actions to each step.
	 * The callbacks could contain things like dispatching actions, or updating state.
	 *
	 * @see providerValues.getQuickStartGuideSteps() for additional `isCompleted` logic.
	 * @see https://docs.google.com/spreadsheets/d/12CmIVfASDyW8bfKT-t0nlexBfyZVI36MwTpTbXw11TM/edit#gid=1256301017
	 */
	const quickStartGuideSteps = {
		HSC: [
			{
				icon: SetUpTheBasicIcon,
				title: "Let's set up the basics!",
				description:
					"Set up the basics of your HootBoard and get up and running in less than 5 minutes!",

				steps: [
					{
						slug: "customizeBoard",
						title: "Customize your HootBoard",
						description:
							"Add logo, update theme and hoot colors, add background image which matches your brand, etc.",

						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/info"
								)
							);
						},
					},
					{
						slug: "inviteMembers",
						title: "Invite members",
						description:
							"Invite more members into your HootBoard in a single step.",
						onClick: () => {
							dispatch(showInviteMembersModal());
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/members"
								)
							);
						},
					},
					{
						slug: "updateSecurity",
						title: "Update security settings",
						description:
							"Decide who can join, post content to your HootBoard. Update privacy settings.				",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/security"
								)
							);
						},
					},
				],
			},
			{
				icon: SetUpYourKioskIcon,
				title: "Setup FREE Interactive Screens & Digital Signage",
				description:
					"Take your HootBoard to next level. Setup kiosks for your HootBoard to streamline your communication with something much more engaging.",
				staticComponent: <StaticKioskComponentUI />,
			},
			{
				icon: OrganizeYourHootBoardIcon,
				title: "Organize your HootBoard",
				description:
					"Organize your HootBoard's content with apps and collections.",
				steps: [
					{
						slug: "updateCollections",
						title: "Customize collections",
						description:
							"You can manage board-level collections from settings and hoot-level collections while posting. ",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/collections"
								)
							);
						},
					},
					{
						slug: "createApp",
						title: "Create an app",
						description:
							"Apps are a great way to add more interactive content to your HootBoard.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/apps"
								)
							);
						},
					},
				],
			},
			{
				icon: PostContentIcon,
				title: "Post Content",
				description: "",
				steps: [
					{
						slug: "postHoot",
						title: "Post a hoot",
						description: "",
						onClick: () => {
							dispatch(
								setPostHootModalVisibility({
									visibility: "visible",
								})
							);
						},
					},
				],
			},
			{
				icon: AddHootBoardOnYourPlatformIcon,
				title: "Add HootBoard on your platform",
				description:
					"Your HootBoard can be embedded into other websites as well.",
				steps: [
					{
						slug: "visitEmbed",
						title: "Embed your board",
						description:
							"Try embedding your HootBoard. Make sure you disabled the Secured Board option in security settings.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/embed"
								)
							);
						},
					},
				],
			},
			{
				icon: SeeYourHootBoardEngagementIcon,
				title: "See your HootBoard's Engagement",
				description:
					"See how your HootBoard is performing among users.",
				steps: [
					{
						slug: "visitAnalytics",
						title: "See how people interact with your HootBoard",
						description: "",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"analytics"
								)
							);
						},
					},
				],
			},
			{
				icon: AdvertiseOnHootBoardIcon,
				title: "Advertise on HootBoard",
				description:
					"HootBoard invites you to showcase your business to our visitors.",
				steps: [
					{
						slug: "visitAdvertise",
						title: "Signup now to get $100 in advertising credit!",
						description:
							"Advertise and reach people who’re ready to spend.",
						onClick: () => {
							navigateToAdPlatform();
							dispatch(
								markStepAsDone({
									boardId,
									startGuideTourName: "visitAdvertise",
								})
							);
						},
					},
				],
			},
		],
		HDT: [
			{
				icon: SetUpTheBasicIcon,
				title: "Customize HootBoard",
				description:
					"Set up the basics of your HootBoard make it live in less than 5 minutes!",
				steps: [
					{
						slug: "customizeBoard",
						title: "Style your HootBoard appearance!",
						description:
							"Add logo, update theme and hoot colors, add background image which matches your brand, etc.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/info"
								)
							);
						},
					},

					{
						slug: "updateSecurity",
						title: "Update security settings",
						description:
							"Your board security is already optimally set. However, take a look at the variety of security settings to adjust as needed.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/security"
								)
							);
						},
					},
					{
						slug: "inviteAdminMembers",
						title: "Invite co-admins",
						description:
							"Bring on a couple of teammates who might help you with your communications.",
						onClick: () => {
							dispatch(showInviteMembersModal());
							dispatch(memberTypeSelected("adm"));
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/members"
								)
							);
						},
					},
				],
			},
			{
				icon: PostContentIcon,
				title: "Post Hoots",
				description:
					"Hoots are the lifeblood for your HootBoard. Keep your board up-to-date with the best of what your organization has to offer.",
				steps: [
					{
						slug: "postHootEvent",
						title: "Post an event",
						description:
							"Try posting your first Hoot to get started!.",
						onClick: () => {
							dispatch(
								setPostHootModalVisibility({
									visibility: "visible",
								})
							);

							dispatch(postingCategorySelected("EVENT"));
							dispatch(hootTypeSelected(3));
							dispatch(hootBgColorSelected(getRandomHootColor()));
							dispatch(uploadFileButtonToggled(true));
							dispatch(hootEventControlToggled(true));
							dispatch(hootCoverImageControlToggled(true));
						},
					},
				],
			},
			{
				icon: OrganizeYourHootBoardIcon,
				title: "Organize HootBoard",
				description:
					"Arrange your HootBoard's content with apps and collections. It makes finding Hoots easy.",
				steps: [
					{
						slug: "updateCollections",
						title: "Create your #tags, we call them collections!",
						description:
							"A better way to group similar category of Hoots!",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/collections"
								)
							);
						},
					},
					{
						slug: "createApp",
						title: "Create app-based navigation",
						description:
							"Apps are a great way to add more interactive content to your HootBoard.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/apps"
								)
							);
						},
					},
				],
			},
			{
				icon: bringLifeToHootBoardIcon,
				title: "Invite Members",
				description:
					"Adding members to your HootBoard increases the engagements on your board.",
				steps: [
					{
						slug: "inviteMembers",
						title: "Send Invites",
						description:
							"Invite all the co-admins and other members on your board.",
						onClick: () => {
							dispatch(showInviteMembersModal());
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/members"
								)
							);
						},
					},
				],
			},
			{
				icon: SetUpYourKioskIcon,
				title: "Setup FREE Interactive Screens & Digital Signage",
				description:
					"Take your guest experience to the next level. Add HootBoard kiosks and signage to communicate with your audience whether they are your staff or visitors.",
				staticComponent: <StaticKioskComponentUI />,
			},

			{
				icon: AddHootBoardOnYourPlatformIcon,
				title: "Boost Engagement",
				description:
					"Become a HootBoard expert by mastering these advanced features. These advanced features can improve your board engagement. Integrations made easy!",
				steps: [
					{
						slug: "visitEmbed",
						title: "Embed your HootBoard",
						description:
							"Often you want your website visitors to view content from your HootBoard. You can now embed your HootBoard in seconds.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/embed"
								)
							);
						},
					},
					{
						slug: "visitConnectors",
						title: "Integrations made easy!",
						description:
							"HootBoard Connectors are a great way to connect your social media accounts to the footboard. Add content on these integrations to HootBoard.",
						onClick: () => {
							handleVisitConnectors();
						},
					},
					{
						slug: "addShortUrl",
						title: "Set your short URL",
						description:
							"By default, HootBoard creates a URL for your board. But setting a short URL for your board makes it easier for others to navigate to your board.",
						onClick: () => {
							dispatch(boardInfoTabView(2));
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/info"
								)
							);
						},
					},
					{
						slug: "visitNotifications",
						title: "Control Notifications",
						description:
							"Easily control what emails and push notifications needs to reach you and your HootBoard members.",
						onClick: () => {
							providerValues.markAsDone({
								slug: "visitNotifications",
							});
							navigate("/account/settings/notifications");
						},
					},
				],
			},
		],

		HGI: [
			{
				icon: SetUpTheBasicIcon,
				title: "Let's set up the basics!",
				description:
					"Set up the basics of your HootBoard and get up and running in less than 5 minutes!",
				steps: [
					{
						slug: "customizeBoard",
						title: "Customize your HootBoard",
						description:
							"Add logo, update theme and hoot colors, add background image which matches your brand, etc.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/info"
								)
							);
						},
					},
					{
						slug: "inviteMembers",
						title: "Invite managers & other team members",
						description:
							"Invite more members into your HootBoard in a single step.",
						onClick: () => {
							dispatch(showInviteMembersModal());
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/members"
								)
							);
						},
					},
					{
						slug: "updateSecurity",
						title: "Update security settings",
						description:
							"Decide who can join, post content to your HootBoard. Update privacy settings.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/security"
								)
							);
						},
					},
				],
			},
			{
				icon: OrganizeYourHootBoardIcon,
				title: "Organize your HootBoard",
				description:
					"Organize your HootBoard's content with apps and collections.",
				steps: [
					{
						slug: "updateCollections",
						title: "Customize collections",
						description:
							"You can manage board-level collections from settings and hoot-level collections while posting. ",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/collections"
								)
							);
						},
					},
					{
						slug: "createApp",
						title: "Create an app",
						description:
							"Apps are a great way to add more interactive content to your HootBoard.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/apps"
								)
							);
						},
					},
				],
			},
			{
				icon: PostContentIcon,
				title: "Post Content",
				description:
					"Post content regularly to add more engagement towards your HootBoard.",
				steps: [
					{
						slug: "postHootEvent",
						title: "Post an event",
						description:
							"Try posting your first Hoot to get started!",
						onClick: () => {
							dispatch(
								setPostHootModalVisibility({
									visibility: "visible",
								})
							);
							dispatch(postingCategorySelected("EVENT"));
							dispatch(hootTypeSelected(3));
							dispatch(hootBgColorSelected(getRandomHootColor()));
							dispatch(uploadFileButtonToggled(true));
							dispatch(hootEventControlToggled(true));
							dispatch(hootCoverImageControlToggled(true));
						},
					},
				],
			},
			{
				icon: SetUpYourKioskIcon,
				title: "Setup FREE Interactive Screens & Digital Signage",
				description:
					"Take your HootBoard to next level. Setup kiosks for your HootBoard to streamline your communication with something much more engaging.",
				staticComponent: <StaticKioskComponentUI />,
			},
			{
				icon: AddHootBoardOnYourPlatformIcon,
				title: "Add HootBoard on your platform",
				description:
					"Your HootBoard can be embedded into other websites as well.",
				steps: [
					{
						slug: "visitEmbed",
						title: "Embed your board",
						description:
							"Try embedding your HootBoard. Make sure you disabled the Secured Board option in security settings.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/embed"
								)
							);
						},
					},
				],
			},
			{
				icon: SeeYourHootBoardEngagementIcon,
				title: "See your HootBoard's Engagement",
				description:
					"See how your HootBoard is performing among users.",
				steps: [
					{
						slug: "visitAnalytics",
						title: "See how your visitors interact with your HootBoard",
						description: "",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"analytics"
								)
							);
						},
					},
				],
			},
			{
				icon: AdvertiseOnHootBoardIcon,
				title: "Advertise on HootBoard",
				description:
					"HootBoard invites you to showcase your business to our visitors.",
				steps: [
					{
						slug: "visitAdvertise",
						title: "Signup now to get $100 in advertising credit!",
						description:
							"Advertise and reach people who’re ready to spend.",
						onClick: () => {
							navigateToAdPlatform();
							dispatch(
								markStepAsDone({
									boardId,
									startGuideTourName: "visitAdvertise",
								})
							);
						},
					},
				],
			},
		],
		HSI: [
			{
				icon: SetUpTheBasicIcon,
				title: "Customize HootBoard",
				description:
					"Set up the basics of your HootBoard make it live in less than 5 minutes!",
				steps: [
					{
						slug: "customizeBoard",
						title: "Style your HootBoard appearance!",
						description:
							"Add logo & background to your HootBoard and a theme for your hoots to make your HootBoard stand!",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/info"
								)
							);
						},
					},
					{
						slug: "updateSecurity",
						title: "Update security settings",
						description:
							"Your board security is already optimally set. However, take a look at the variety of security settings to adjust as needed.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/security"
								)
							);
						},
					},
					{
						slug: "inviteAdminMembers",
						title: "Invite co-admins",
						description:
							"Invite more members into your HootBoard in a single step.",
						onClick: () => {
							dispatch(showInviteMembersModal());
							dispatch(memberTypeSelected("adm"));
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/members"
								)
							);
						},
					},
				],
			},
			{
				icon: PostContentIcon,
				title: "Post Hoots",
				description:
					"Hoots are the lifeblood for your HootBoard. Keep your board up-to-date with the best of what your organization has to offer.",
				steps: [
					{
						slug: "postHootAnnouncement",
						title: "Post an announcement",
						description:
							"Try posting your first Hoot to get started!",
						onClick: () => {
							dispatch(
								setPostHootModalVisibility({
									visibility: "visible",
								})
							);
							dispatch(postingCategorySelected("ANNT"));
							dispatch(hootTypeSelected(0));
							dispatch(hootBgColorSelected(getRandomHootColor()));
							dispatch(uploadFileButtonToggled(true));
							dispatch(hootEventControlToggled(true));
							dispatch(hootCoverImageControlToggled(true));
						},
					},
					{
						slug: "postHootEvent",
						title: "Post an event",
						description:
							"Event hoots will show up on the calendar view as well.",
						onClick: () => {
							dispatch(
								setPostHootModalVisibility({
									visibility: "visible",
								})
							);
							dispatch(postingCategorySelected("EVENT"));
							dispatch(hootTypeSelected(3));
							dispatch(hootBgColorSelected(getRandomHootColor()));
							dispatch(uploadFileButtonToggled(true));
							dispatch(hootEventControlToggled(true));
							dispatch(hootCoverImageControlToggled(true));
						},
					},
				],
			},

			{
				icon: OrganizeYourHootBoardIcon,
				title: "Organize HootBoard",
				description:
					"Arrange your HootBoard's content with apps and collections. It makes finding Hoots easy.",
				steps: [
					{
						slug: "updateCollections",
						title: "Create your #tags, we call them collections!",
						description:
							"A better way to group similar category of Hoots!",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/collections"
								)
							);
						},
					},
					{
						slug: "createApp",
						title: "Create app-based navigation",
						description:
							"Apps are a great way to add more interactive content to your HootBoard.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/apps"
								)
							);
						},
					},
				],
			},
			{
				icon: bringLifeToHootBoardIcon,
				title: "Invite Members",
				description:
					"Adding members to your HootBoard increases the engagements on your board.",
				steps: [
					{
						slug: "inviteMembers",
						title: "Send Invites",
						description:
							"Invite all the co-admins and other members on your board.",
						onClick: () => {
							dispatch(showInviteMembersModal());
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/members"
								)
							);
						},
					},
				],
			},
			{
				icon: SetUpYourKioskIcon,
				title: "Setup FREE Interactive Screens & Digital Signage",
				description:
					"Take your guest experience to the next level. Add HootBoard kiosks and signage to communicate with your audience whether they are your staff or visitors.",
				staticComponent: <StaticKioskComponentUI />,
			},

			{
				icon: AddHootBoardOnYourPlatformIcon,
				title: "Boost Engagement",
				description:
					"Become a HootBoard expert by mastering these advanced features. These advanced features can improve your board engagement. Integrations made easy!",
				steps: [
					{
						slug: "visitEmbed",
						title: "Embed your HootBoard",
						description:
							"Often you want your website visitors to view content from your HootBoard. You can now embed your HootBoard in seconds.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/embed"
								)
							);
						},
					},
					{
						slug: "visitConnectors",
						title: "Integrations made easy!",
						description:
							"HootBoard Connectors are a great way to connect your social media accounts to the footboard. Add content on these integrations to HootBoard.",
						onClick: () => {
							handleVisitConnectors();
						},
					},
					{
						slug: "addShortUrl",
						title: "Set your short URL",
						description:
							"By default, HootBoard creates a URL for your board. But setting a short URL for your board makes it easier for others to navigate to your board.",
						onClick: () => {
							dispatch(boardInfoTabView(2));
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/info"
								)
							);
						},
					},
					{
						slug: "visitNotifications",
						title: "Control Notifications",
						description:
							"Easily control what emails and push notifications needs to reach you and your HootBoard members.",
						onClick: () => {
							providerValues.markAsDone({
								slug: "visitNotifications",
							});

							navigate("/account/settings/notifications");
						},
					},
				],
			},
		],
		HEE: [
			{
				icon: SetUpTheBasicIcon,
				title: "Customize HootBoard",
				description:
					"Set up the basics of your HootBoard make it live in less than 5 minutes!",
				steps: [
					{
						slug: "customizeBoard",
						title: "Style your HootBoard appearance!",
						description:
							"Add logo, update theme and hoot colors, add background image which matches your brand, etc.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/info"
								)
							);
						},
					},

					{
						slug: "updateSecurity",
						title: "Update security settings",
						description:
							"Your board security is already optimally set. However, take a look at the variety of security settings to adjust as needed.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/security"
								)
							);
						},
					},
					{
						slug: "inviteAdminMembers",
						title: "Invite co-admins",
						description:
							"Bring on a couple of teammates who might help you with your communications.",
						onClick: () => {
							dispatch(showInviteMembersModal());
							dispatch(memberTypeSelected("adm"));
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/members"
								)
							);
						},
					},
				],
			},
			{
				icon: PostContentIcon,
				title: "Post Hoots",
				description:
					"Hoots are the lifeblood for your HootBoard. Keep your board up-to-date with the best of what your organization has to offer.",
				steps: [
					{
						slug: "postHootAnnouncement",
						title: "Post an announcement",
						description:
							"Try posting your first Hoot to get started!",
						onClick: () => {
							dispatch(
								setPostHootModalVisibility({
									visibility: "visible",
								})
							);
							dispatch(postingCategorySelected("ANNT"));
							dispatch(hootTypeSelected(0));
							dispatch(hootBgColorSelected(getRandomHootColor()));
							dispatch(uploadFileButtonToggled(true));
							dispatch(hootEventControlToggled(true));
							dispatch(hootCoverImageControlToggled(true));
						},
					},
					{
						slug: "postHootEvent",
						title: "Post an event",
						description:
							"Event hoots will show up on the calendar view as well.",
						onClick: () => {
							dispatch(
								setPostHootModalVisibility({
									visibility: "visible",
								})
							);
							dispatch(postingCategorySelected("EVENT"));
							dispatch(hootTypeSelected(3));
							dispatch(hootBgColorSelected(getRandomHootColor())); //
							dispatch(uploadFileButtonToggled(true));
							dispatch(hootEventControlToggled(true));
							dispatch(hootCoverImageControlToggled(true));
						},
					},
				],
			},
			{
				icon: OrganizeYourHootBoardIcon,
				title: "Organize HootBoard",
				description:
					"Arrange your HootBoard's content with apps and collections. It makes finding Hoots easy.",
				steps: [
					{
						slug: "updateCollections",
						title: "Create your #tags, we call them collections!",
						description:
							"A better way to group similar category of Hoots!",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/collections"
								)
							);
						},
					},
					{
						slug: "createApp",
						title: "Create app-based navigation",
						description:
							"Apps are a great way to add more interactive content to your HootBoard.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/apps"
								)
							);
						},
					},
				],
			},
			{
				icon: bringLifeToHootBoardIcon,
				title: "Invite Members",
				description:
					"Adding members to your HootBoard increases the engagements on your board.",
				steps: [
					{
						slug: "inviteMembers",
						title: "Send Invites",
						description:
							"Invite all the co-admins and other members on your board.",
						onClick: () => {
							dispatch(showInviteMembersModal());
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/members"
								)
							);
						},
					},
				],
			},

			{
				icon: SetUpYourKioskIcon,
				title: "Setup FREE Interactive Screens & Digital Signage",
				description:
					"Take your guest experience to the next level. Add HootBoard kiosks and signage to communicate with your audience whether they are your staff or visitors.",
				staticComponent: <StaticKioskComponentUI />,
			},
			{
				icon: AddHootBoardOnYourPlatformIcon,
				title: "Boost Engagement",
				description:
					"Become a HootBoard expert by mastering these advanced features. These advanced features can improve your board engagement. Integrations made easy!",
				steps: [
					{
						slug: "visitEmbed",
						title: "Embed your HootBoard",
						description:
							"Often you want your website visitors to view content from your HootBoard. You can now embed your HootBoard in seconds.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/embed"
								)
							);
						},
					},
					{
						slug: "visitConnectors",
						title: "Integrations made easy!",
						description:
							"HootBoard Connectors are a great way to connect your social media accounts to the footboard. Add content on these integrations to HootBoard.",
						onClick: () => {
							handleVisitConnectors();
						},
					},
					{
						slug: "addShortUrl",
						title: "Set your short URL",
						description:
							"By default, HootBoard creates a URL for your board. But setting a short URL for your board makes it easier for others to navigate to your board.",
						onClick: () => {
							dispatch(boardInfoTabView(2));
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/info"
								)
							);
						},
					},
					{
						slug: "visitNotifications",
						title: "Control Notifications",
						description:
							"Easily control what emails and push notifications needs to reach you and your HootBoard members.",
						onClick: () => {
							providerValues.markAsDone({
								slug: "visitNotifications",
							});

							navigate("/account/settings/notifications");
						},
					},
				],
			},
		],
		HCE: [
			{
				icon: SetUpTheBasicIcon,
				title: "Let's set up the basics!",
				description:
					"Set up the basics of your HootBoard and get up and running in less than 5 minutes!",
				steps: [
					{
						slug: "customizeBoard",
						title: "Customize your HootBoard",
						description:
							"Add logo, update theme and hoot colors, add background image which matches your brand, etc.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/info"
								)
							);
						},
					},
					{
						slug: "inviteMembers",
						title: "Invite managers & other team members",
						description:
							"Invite more members into your HootBoard in a single step.",
						onClick: () => {
							dispatch(showInviteMembersModal());
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/members"
								)
							);
						},
					},
					{
						slug: "updateSecurity",
						title: "Update security settings",
						description:
							"Decide who can join, post content to your HootBoard. Update privacy settings.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/security"
								)
							);
						},
					},
				],
			},
			{
				icon: PostContentIcon,
				title: "Post Content",
				description:
					"Post content regularly to add more engagement towards your HootBoard.",
				steps: [
					{
						slug: "postHootEvent",
						title: "Post your first event",
						description:
							"Try posting your first Hoot to get started!",
						onClick: () => {
							dispatch(
								setPostHootModalVisibility({
									visibility: "visible",
								})
							);
							dispatch(postingCategorySelected("EVENT"));
							dispatch(hootTypeSelected(3));
							dispatch(hootBgColorSelected(getRandomHootColor()));
							dispatch(uploadFileButtonToggled(true));
							dispatch(hootEventControlToggled(true));
							dispatch(hootCoverImageControlToggled(true));
						},
					},
					{
						slug: "postHootAnnouncement",
						title: "Post an announcement",
						description: "",
						onClick: () => {
							dispatch(
								setPostHootModalVisibility({
									visibility: "visible",
								})
							);
							dispatch(postingCategorySelected("ANNT"));
							dispatch(hootTypeSelected(0));
							dispatch(hootBgColorSelected(getRandomHootColor()));
							dispatch(uploadFileButtonToggled(true));
							dispatch(hootEventControlToggled(true));
							dispatch(hootCoverImageControlToggled(true));
						},
					},
				],
			},
			{
				icon: OrganizeYourHootBoardIcon,
				title: "Organize your HootBoard",
				description:
					"Organize your HootBoard's content with apps and collections.",
				steps: [
					{
						slug: "updateCollections",
						title: "Customize collections",
						description:
							"You can manage board-level collections from settings and hoot-level collections while posting. ",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/collections"
								)
							);
						},
					},
					{
						slug: "createApp",
						title: "Create an app",
						description:
							"Apps are a great way to add more interactive content to your HootBoard.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/apps"
								)
							);
						},
					},
				],
			},
			{
				icon: SetUpYourKioskIcon,
				title: "Setup FREE Interactive Screens & Digital Signage",
				description:
					"Take your HootBoard to next level. Setup kiosks for your HootBoard to streamline your communication with something much more engaging.",
				staticComponent: <StaticKioskComponentUI />,
			},
			{
				icon: AdvertiseOnHootBoardIcon,
				title: "Advertise on HootBoard",
				description:
					"HootBoard invites you to showcase your business to our visitors.",
				steps: [
					{
						slug: "visitAdvertise",
						title: "Signup now to get $100 in advertising credit!",
						description:
							"Advertise and reach people who’re ready to spend.",
						onClick: () => {
							navigateToAdPlatform();
							dispatch(
								markStepAsDone({
									boardId,
									startGuideTourName: "visitAdvertise",
								})
							);
						},
					},
				],
			},
			{
				icon: AddHootBoardOnYourPlatformIcon,
				title: "Add HootBoard on your platform",
				description:
					"Your HootBoard can be embedded into other websites as well.",
				steps: [
					{
						slug: "visitEmbed",
						title: "Embed your board",
						description:
							"Try embedding your HootBoard. Make sure you disabled the Secured Board option in security settings.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/embed"
								)
							);
						},
					},
				],
			},
			{
				icon: SeeYourHootBoardEngagementIcon,
				title: "See your HootBoard's Engagement",
				description:
					"See how your HootBoard is performing among users.",
				steps: [
					{
						slug: "visitAnalytics",
						title: "See how people interact with your HootBoard",
						description: "",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"analytics"
								)
							);
						},
					},
				],
			},
		],
		HLB: [
			{
				icon: CompleteYourBusinessProfileIcon,
				title: "List your business for FREE!",
				description:
					"A complete profile increases your chances of getting noticed by our visitors. It only takes a few minutes, but makes a world of difference.",
				steps: [
					{
						slug: "setBusinessPhone",
						title: "Add a phone number",
						description:
							"Adding a phone number increases the chances of a customer getting in touch with you after they encounter your business on HootBoard.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/my-business/info"
								)
							);
						},
					},
					{
						slug: "setBusinessWeb",
						title: "Add website address",
						description:
							"Let your customer see what you want them to see, showcase your beautifully crafted website to your prospective customers.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/my-business/info"
								)
							);
						},
					},
					{
						slug: "setBusinessHours",
						title: "Add working hours",
						description: "",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/my-business/business-hours"
								)
							);
						},
					},
				],
			},
			{
				icon: SetUpTheBasicIcon,
				title: "Customize HootBoard",
				description:
					"Set up the basics of your HootBoard make it live in less than 5 minutes!",
				steps: [
					{
						slug: "customizeBoard",
						title: "Style your HootBoard appearance!",
						description:
							"Add logo & background to your HootBoard and a theme for your hoots to make your HootBoard stand!",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/my-business/info/branding"
								)
							);
						},
					},
					{
						slug: "updateSecurity",
						title: "Update security settings",
						description:
							"Your board security is already optimally set. However, take a look at the variety of security settings to adjust as needed.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/security"
								)
							);
						},
					},
					{
						slug: "inviteAdminMembers",
						title: "Invite co-admins",
						description:
							"Invite more members into your HootBoard in a single step.",
						onClick: () => {
							dispatch(showInviteMembersModal());
							dispatch(memberTypeSelected("adm"));
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/members"
								)
							);
						},
					},
				],
			},
			{
				icon: PostContentIcon,
				title: "Post Hoots",
				description:
					"Hoots are the lifeblood for your HootBoard. Keep your board up-to-date with the best of what your organization has to offer. Let's post a hoot about your next event.",
				steps: [
					{
						slug: "postHootEvent",
						title: "Post a hoot",
						description:
							"Try posting your first Hoot to get started!",
						onClick: () => {
							dispatch(
								setPostHootModalVisibility({
									visibility: "visible",
								})
							);
							dispatch(postingCategorySelected("EVENT"));
							dispatch(hootTypeSelected(3));
							dispatch(hootBgColorSelected(getRandomHootColor()));
							dispatch(uploadFileButtonToggled(true));
							dispatch(hootEventControlToggled(true));
							dispatch(hootCoverImageControlToggled(true));
						},
					},
				],
			},
			{
				icon: OrganizeYourHootBoardIcon,
				title: "Organize HootBoard",
				description:
					"Arrange your HootBoard's content with apps and collections. It makes finding Hoots easy.",
				steps: [
					{
						slug: "updateCollections",
						title: "Create your #tags, we call them collections!",
						description:
							"A better way to group similar category of Hoots!",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/collections"
								)
							);
						},
					},
					{
						slug: "createApp",
						title: "Create app-based navigation",
						description:
							"Apps are a great way to add more interactive content to your HootBoard.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/apps"
								)
							);
						},
					},
				],
			},

			{
				icon: bringLifeToHootBoardIcon,
				title: "Invite Members",
				description:
					"Adding members to your HootBoard increases the engagements on your board.",
				steps: [
					{
						slug: "inviteMembers",
						title: "Send Invites",
						description:
							"Invite all the co-admins and other members on your board.",
						onClick: () => {
							dispatch(showInviteMembersModal());
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/members"
								)
							);
						},
					},
				],
			},

			{
				icon: SetUpYourKioskIcon,
				title: "Setup FREE Interactive Screens & Digital Signage",
				description:
					"Take your guest experience to the next level. Add HootBoard kiosks and signage to communicate with your audience whether they are your staff, or visitors.",
				staticComponent: <StaticKioskComponentUI />,
			},

			{
				icon: AddHootBoardOnYourPlatformIcon,
				title: "Boost Engagement",
				description:
					"Become a HootBoard expert by mastering these advanced features. These advanced features can improve your board engagement. Integrations made easy!",
				steps: [
					{
						slug: "visitEmbed",
						title: "Embed your HootBoard",
						description:
							"Often you want your website visitors to view content from your HootBoard. You can now embed your HootBoard in seconds.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/embed"
								)
							);
						},
					},
					{
						slug: "visitConnectors",
						title: "Integrations made easy!",
						description:
							"HootBoard Connectors are a great way to connect your social media accounts to the footboard. Add content on these integrations to HootBoard.",
						onClick: () => {
							handleVisitConnectors();
						},
					},
					{
						slug: "addShortUrl",
						title: "Set your short URL",
						description:
							"By default, HootBoard creates a URL for your board. But setting a short URL for your board makes it easier for others to navigate to your board.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/my-business/info/url"
								)
							);
						},
					},
					{
						slug: "visitNotifications",
						title: "Control Notifications",
						description:
							"Easily control what emails and push notifications needs to reach you and your HootBoard members.",
						onClick: () => {
							providerValues.markAsDone({
								slug: "visitNotifications",
							});

							navigate("/account/settings/notifications");
						},
					},
				],
			},

			{
				icon: AdvertiseOnHootBoardIcon,
				title: "Advertise on HootBoard",
				description:
					"With the HootBoard promotions app, advertising is dead simple. You can now advertise any content you want in just a few steps.",
				steps: [
					{
						slug: "visitAdvertise",
						title: "Sign up now and get a $100 free credit. Use it to advertise right away!",
						description:
							"Advertise and reach people who’re ready to spend.",
						onClick: () => {
							navigateToAdPlatform();
							dispatch(
								markStepAsDone({
									boardId,
									startGuideTourName: "visitAdvertise",
								})
							);
						},
					},
				],
			},
		],
		HET: [
			{
				icon: SetUpTheBasicIcon,
				title: "Let's set up the basics!",
				description:
					"Set up the basics of your HootBoard and get up and running in less than 5 minutes!",
				steps: [
					{
						slug: "customizeBoard",
						title: "Customize your HootBoard",
						description:
							"Add logo, update theme and hoot colors, add background image which matches your brand, etc.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/info"
								)
							);
						},
					},
					{
						slug: "inviteMembers",
						title: "Invite managers & other team members",
						description:
							"Invite more members into your HootBoard in a single step.",
						onClick: () => {
							dispatch(showInviteMembersModal());
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/members"
								)
							);
						},
					},
					{
						slug: "updateSecurity",
						title: "Update security settings",
						description:
							"Decide who can join, post content to your HootBoard. Update privacy settings.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/security"
								)
							);
						},
					},
				],
			},
			{
				icon: SetUpYourKioskIcon,
				title: "Setup FREE Interactive Screens & Digital Signage",
				description:
					"Take your HootBoard to next level. Setup kiosks for your HootBoard to streamline your communication with something much more engaging.",
				staticComponent: <StaticKioskComponentUI />,
			},
			{
				icon: OrganizeYourHootBoardIcon,
				title: "Organize your HootBoard",
				description:
					"Organize your HootBoard's content with apps and collections.",
				steps: [
					{
						slug: "updateCollections",
						title: "Customize collections",
						description:
							"You can manage board-level collections from settings and hoot-level collections while posting. ",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/settings/collections"
								)
							);
						},
					},
					{
						slug: "createApp",
						title: "Create an app",
						description:
							"Apps are a great way to add more interactive content to your HootBoard.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/apps"
								)
							);
						},
					},
				],
			},
			{
				icon: PostContentIcon,
				title: "Post Content",
				description:
					"Post content regularly to add more engagement towards your HootBoard.",
				steps: [
					{
						slug: "postHoot",
						title: "Post a hoot",
						description: "",
						onClick: () => {
							dispatch(
								setPostHootModalVisibility({
									visibility: "visible",
								})
							);
						},
					},
				],
			},
			{
				icon: AdvertiseOnHootBoardIcon,
				title: "Advertise on HootBoard",
				description:
					"HootBoard invites you to showcase your business to our visitors.",
				steps: [
					{
						slug: "visitAdvertise",
						title: "Signup now to get $100 in advertising credit!",
						description:
							"Advertise and reach people who’re ready to spend.",
						onClick: () => {
							navigateToAdPlatform();
							dispatch(
								markStepAsDone({
									boardId,
									startGuideTourName: "visitAdvertise",
								})
							);
						},
					},
				],
			},
			{
				icon: AddHootBoardOnYourPlatformIcon,
				title: "Add HootBoard on your platform",
				description:
					"Your HootBoard can be embedded into other websites as well.",
				steps: [
					{
						slug: "visitEmbed",
						title: "Embed your board",
						description:
							"Try embedding your HootBoard. Make sure you disabled the Secured Board option in security settings.",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"manage/embed"
								)
							);
						},
					},
				],
			},
			{
				icon: SeeYourHootBoardEngagementIcon,
				title: "See your HootBoard's Engagement",
				description:
					"See how your HootBoard is performing among users.",
				steps: [
					{
						slug: "visitAnalytics",
						title: "See how your visitors interact with your HootBoard",
						description: "",
						onClick: () => {
							navigate(
								generateActionLink(
									boardId,
									boardName,
									"analytics"
								)
							);
						},
					},
				],
			},
		],
	};

	// Fallback for old board types.
	quickStartGuideSteps["HW"] = quickStartGuideSteps.HEE;
	quickStartGuideSteps["HV"] = quickStartGuideSteps.HGI;
	quickStartGuideSteps["HO"] = quickStartGuideSteps.HSI;

	// Fallback for anything else other than known board types.
	if (!quickStartGuideSteps[boardType]) {
		quickStartGuideSteps[boardType] = quickStartGuideSteps.HSI;
	}

	// only admins needs to see the onboarding processes.
	const isUserAdmin = checkIfAdmin(membershipType);

	useEffect(() => {
		if (isOnBoardContext && boardId && isUserAdmin) {
			// Call the API to get the list of completed on-boarding steps.
			dispatch(getAllCompletedSteps(boardId));
		}
	}, [boardId, isUserAdmin]);

	const mixpanelContext = useContext(MixpanelContext);

	const providerValues = {
		/**
		 * Return's active board's quick start guide steps.
		 * Returned object can be used to render the quick start guide UI.
		 * Inside the steps object, each step will have a computed property `isCompleted`.
		 */
		getQuickStartGuideSteps: () => {
			return quickStartGuideSteps[boardType].map((segment) => {
				return {
					...segment,
					steps:
						segment.steps &&
						segment.steps.map((step) => {
							return {
								...step,
								// simple logic to check if the step is completed.
								isCompleted: completedSteps.includes(step.slug),
							};
						}),
				};
			});
		},

		/**
		 * Mark a step as completed.
		 * To avoid too much condition checks and imports in the other components,
		 * we can just call the method, when the expected action happens.
		 * We can just check the `completeSteps` and dispatch the action only if the step is not completed.
		 * We will also send mixpanel events to track the step completion.
		 * If we don't want to wait for API success. Adding another check `saveOnRequest`
		 * if it is true, we can save slug in completedSteps in pending status (without API get success)
		 * @param {Object} params
		 * @param {string} params.slug
		 * @param {string} params.saveOnRequest
		 * @param {boolean} params.saveOnlyLocal
		 */
		markAsDone: (params) => {
			if (!completedSteps.includes(params.slug) && isUserAdmin) {
				// Call the API to mark the step as completed.
				dispatch(
					markStepAsDone({
						boardId: boardId || boardIdInCreatedBoard,
						startGuideTourName: params.slug,
						saveOnRequest: params.saveOnRequest,
						saveOnlyLocal: params.saveOnlyLocal,
					})
				);

				// Send mixpanel event to track.
				mixpanelContext.trackQuickStartGuideItemCompleted({
					completedStep: params.slug,
				});
			}
		},

		/**
		 * Hide the quick start guide manually.
		 * We will just dispatch the action with "manuallyHidden" slug.
		 */
		hideGuideManually: () => {
			if (!completedSteps.includes("manuallyHidden")) {
				// Call the API to mark the step as completed with "manuallyHidden" slug.
				dispatch(
					markStepAsDone({
						boardId,
						startGuideTourName: "manuallyHidden",
					})
				);

				// Send mixpanel event to track.
				mixpanelContext.trackQuickStartGuideSkipped();
			}
		},
	};

	const handleVisitConnectors = () => {
		//markAsDone needs boardId, on connector page we do not have boardId,
		//so that's why we are calling markAsDone here.
		providerValues.markAsDone({ slug: "visitConnectors" });
		navigate("/connectors");
	};

	return (
		<OnBoardingContext.Provider value={providerValues}>
			{props.children}
		</OnBoardingContext.Provider>
	);
};

OnBoardingProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
