/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-26 08:22:56
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-10-12 11:39:22
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	localState: {
		adminHootEmailOptIn: true,
		adminHootNotifOptIn: true,
		comntBoardEmailOptIn: true,
		comntBoardNotifOptIn: true,
		comntMentnEmailOptIn: true,
		comntMentnNotifOptIn: true,
		dailyDigest: true,
		evntRemindEmailOptIn: true,
		evntRemindNotifOptIn: true,
		membHootEmailOptIn: true,
		membHootNotifOptIn: false,
		mineComntEmailOptIn: true,
		mineComntNotifOptIn: true,
		otherComntEmailOptIn: true,
		otherComntNotifOptIn: true,
		subBoardAddEmailOptIn: true,
		subBoardAddNotifOptIn: true,
		newMemberJoinEmailOptIn: true,
		newMemberJoinNotifOptIn: true,
		weeklyNewsletterOptIn: true,
		savedDataAnalyticsEmailOptIn: false,
		screenStatusUpdateEmail:true,


	},
	serverState: null,
	isEditing: false,
	fetchBoardNotificationSettingsStatus: "idle",
};

export function getBoardNotificationSettingsAsync(payload) {
	return {
		type: "board/notifications/get/request",
		payload: payload,
	};
}

export function updateBoardNotificationSettingsAsync(payload) {
	return {
		type: "board/notifications/update/request",
		payload: payload,
	};
}

const boardNotificationSettingsSlice = createSlice({
	name: "boardNotificationSettings",
	initialState: initialState,
	reducers: {
		toggleAdminHootEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					adminHootEmailOptIn: action.payload.checked,
				},
			};
		},
		toggleAdminHootNotif: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					adminHootNotifOptIn: action.payload.checked,
				},
			};
		},
		toggleCommentBoardEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					comntBoardEmailOptIn: action.payload.checked,
				},
			};
		},
		toggleCommentBoardNotif: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					comntBoardNotifOptIn: action.payload.checked,
				},
			};
		},
		toggleCommentMentionEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					comntMentnEmailOptIn: action.payload.checked,
				},
			};
		},
		toggleCommentMentionNotif: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					comntMentnNotifOptIn: action.payload.checked,
				},
			};
		},
		toggleDailyDigestEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					dailyDigest: action.payload.checked,
				},
			};
		},
		toggleEventRemindersEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					evntRemindEmailOptIn: action.payload.checked,
				},
			};
		},
		toggleScreenStatusUpdateEmail:(state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					screenStatusUpdateEmail: action.payload.checked,
				},
			};
		},
		toggleWeeklySavesDigestEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					savedDataAnalyticsEmailOptIn: action.payload.checked,
				},
			};
		},
		toggleMemberHootEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					membHootEmailOptIn: action.payload.checked,
				},
			};
		},
		toggleMemberHootNotif: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					membHootNotifOptIn: action.payload.checked,
				},
			};
		},
		toggleMineCommentEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					mineComntEmailOptIn: action.payload.checked,
				},
			};
		},
		toggleMineCommentNotif: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					mineComntNotifOptIn: action.payload.checked,
				},
			};
		},
		toggleNewMemberJoinEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					newMemberJoinEmailOptIn: action.payload.checked,
				},
			};
		},
		toggleNewMemberJoinNotif: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					newMemberJoinNotifOptIn: action.payload.checked,
				},
			};
		},
		toggleOtherCommentEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					otherComntEmailOptIn: action.payload.checked,
				},
			};
		},
		toggleSubBoardAddedEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					subBoardAddEmailOptIn: action.payload.checked,
				},
			};
		},
		toggleSubBoardAddedNotif: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					subBoardAddNotifOptIn: action.payload.checked,
				},
			};
		},
		toggleWeeklyNewsletterEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					weeklyNewsletterOptIn: action.payload.checked,
				},
			};
		},
	},
	extraReducers: {
		"board/notifications/get/fulfilled": (state, action) => {
			return {
				...state,
				serverState: action.payload.notificationSettings,
				localState: action.payload.notificationSettings,
				isEditing: false,
				fetchBoardNotificationSettingsStatus: "fulfilled",
			};
		},
		"board/notifications/get/pending": (state) => {
			return {
				...state,
				fetchBoardNotificationSettingsStatus: "pending",
			};
		},
		"board/notifications/get/rejected": (state) => {
			return {
				...state,
				fetchBoardNotificationSettingsStatus: "rejected",
			};
		},
		"board/notifications/update/fulfilled": (state, action) => {
			return {
				...state,
				serverState: action.payload.notificationSettings,
				localState: action.payload.notificationSettings,
				isEditing: false,
				updateBoardNotificationSettingsStatus: "fulfilled",
			};
		},
		"board/notifications/update/pending": (state) => {
			return {
				...state,
				updateBoardNotificationSettingsStatus: "pending",
			};
		},
		"board/notifications/update/rejected": (state) => {
			return {
				...state,
				updateBoardNotificationSettingsStatus: "rejected",
			};
		},
	},
});
export const {
	toggleAdminHootEmail,
	toggleAdminHootNotif,
	toggleCommentBoardEmail,
	toggleCommentBoardNotif,
	toggleCommentMentionEmail,
	toggleCommentMentionNotif,
	toggleDailyDigestEmail,
	toggleEventRemindersEmail,
	toggleMemberHootEmail,
	toggleMemberHootNotif,
	toggleMineCommentEmail,
	toggleMineCommentNotif,
	toggleNewMemberJoinEmail,
	toggleNewMemberJoinNotif,
	toggleOtherCommentEmail,
	toggleWeeklySavesDigestEmail,
	toggleSubBoardAddedEmail,
	toggleSubBoardAddedNotif,
	toggleWeeklyNewsletterEmail,
	toggleScreenStatusUpdateEmail
} = boardNotificationSettingsSlice.actions;
export default boardNotificationSettingsSlice.reducer;
