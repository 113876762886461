/* eslint-disable @typescript-eslint/no-explicit-any */
import * as queryString from "query-string";

/**
 * Generate ready to open url for the SSO login.
 */
export const generateSSORedirectURL = (params: {
    boardId: string;
    ssoConfig?: any;
    followBoard?: boolean;
}) => {
    const state: typeof params = {
        boardId: params.boardId,
    };

    // No need to increase the payload size with a false for the below one.
    // Add only needed.
    if (params.followBoard) state.followBoard = true;

    const query = queryString.stringify({
        client_id: params.ssoConfig.client_id,
        redirect_uri: params.ssoConfig.redirect_uri,
        response_mode: params.ssoConfig.response_mode,
        response_type: params.ssoConfig.response_type,
        scope: params.ssoConfig.scope,
        state: JSON.stringify(state),
    });
    return `${params.ssoConfig.sso_authorize_endpoint}?${query}`;
};

/**
 * Check if SSO auth credentials are present in the url and take action.
 * @returns State object which can be used to do actions like `follow board` and stuff.
 */
export const handleSSOAuthAndReturnState = () => {
    const query = queryString.parse(window.location.search) as any;

    if (query?.sessionToken) {
        localStorage.setItem("authToken", query?.sessionToken);

        const state = JSON.parse(query.state || "{}") || {};

        // Then replace the new query.
        // We still need to add the rest of the query back to the url.
        // There could be other useful query params in the url.
        sanitizeUrl();

        return state;
    }
    return {};
};

/**
 * Remove security sensitive query params from the url.
 * Params like, authToken, sessionToken, state, etc.
 */
export const sanitizeUrl = () => {
    const query = queryString.parse(window.location.search);

    // Remove items from the url. Add more if needed.
    delete query.sessionToken;
    delete query.authToken;
    delete query.boardId;
    delete query.state;

    history.replaceState({}, "", `${window.location.pathname}?${queryString.stringify(query)}`);
};

/**
 * Check for error in redirect URI from SSO auth and return the error text.
 */
export const getSSOAuthError = () => {
    const query = queryString.parse(window.location.search);
    if (query.ssoAuthError) {
        return "Yikes! Something went wrong while logging in. Please contact support if this is happening regularly.";
    }

    return false;
};

/**
 * Check if there is `authToke` available in the storage.
 */
export const hasSessionToken = () => {
    return !!window.localStorage.getItem("authToken");
};

/**
 * Store `authToken` in both local storage & cookies.
 * Cookies gives us a way to communicate across sub-domains easily by way
 * of storing some piece of data in the cookie.
 * For example, if we were to share the `authToken` from hootboard.com with all of the sub-domains,
 * we can easily do that in the following manner.
 */
export const setToken = (token: string) => {
    window.localStorage.setItem("authToken", token);
    document.cookie = `authToken=${token}; domain=hootboard.com; path=/`;
};
