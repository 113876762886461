import { apiRequest } from "./http-wrapper";

/**
 * Api call to fetch requests to post list.
 * @return all requests to post of respective board
 */
export const getPendingRequests = async (boardId: number) => {
	return apiRequest(`boards/${boardId}/members/pending`);
};

/**
 * Api call to update members by accepting/declining request to post.
 * @return all requests to post of respective board
 */
interface UpdatePendingRequestsParams {
	memberId: number;
	action: string;
	accessLevel: string | null;
}
export const updatePendingRequests = async (
	boardId: number,
	params: UpdatePendingRequestsParams
) => {
	return apiRequest(`boards/${boardId}/members/${params.memberId}/update`, {
		payload: { action: params.action, accessLevel: params.accessLevel },
		method: "PATCH",
	});
};

/**
 * Api call to fetch subscribers list.
 * @return all subscribers of respective board
 */
export const getAllSubscribers = async (
	boardId: number,
	limit = 20,
	offset = 0
) => {
	return apiRequest(
		`boards/${boardId}/subscribers?limit=${limit}&offset=${offset}`
	);
};
