import { call, put, takeLatest } from "redux-saga/effects";
import * as AccountApi from "../../api/account.service";
import * as Auth from "../../api/authentication.service";
import * as BoardApi from "../../api/boards.service";
import * as UserApi from "../../api/users.service";

export const Actions = {
	socialLogin: "auth/socialLogin/",
};

function* loginSaga() {
	yield takeLatest("auth/login/request", function* (action) {
		try {
			yield put({ type: "auth/login/pending", payload: {} });
			const loginApiResponse = yield call(
				async () =>
					await Auth.authenticateUser(
						action.payload.loginEmail,
						action.payload.password
					)
			);
			if (
				loginApiResponse &&
				!loginApiResponse.loginError &&
				!loginApiResponse.activeError
			) {
				const userDetails = yield call(
					async () => await UserApi.getUserDetails()
				);
				if (userDetails) {
					yield put({
						type: "auth/login/fulfilled",
						payload: {
							loginSuccess: true,
							networkError: false,
							userDetails: userDetails,
							...loginApiResponse,
						},
					});
				} else {
					yield put({
						type: "auth/login/fulfilled",
						payload: {
							loginSuccess: true,
							networkError: false,
							userDetails: null,
							...loginApiResponse,
						},
					});
				}
			} else {
				yield put({
					type: "auth/login/rejected",
					payload: {
						loginSuccess: false,
						networkError: false,
						...loginApiResponse,
						...loginApiResponse,
					},
				});
			}
		} catch (e) {
			yield put({
				type: "auth/login/rejected",
				payload: {
					networkError: true,
					activeError: false,
					loginError: false,
				},
			});
		}
	});
}

function* resetPasswordSaga() {
	yield takeLatest("auth/resetPassword/request", function* (action) {
		try {
			yield put({ type: "auth/resetPassword/pending", payload: {} });
			const validationRequest = yield call(
				async () =>
					await Auth.forgotPassword(action.payload.resetPasswordEmail)
			);
			if (validationRequest.userExists) {
				yield put({
					type: "auth/resetPassword/fulfilled",
					payload: { userExists: true },
				});
			} else {
				yield put({
					type: "auth/resetPassword/rejected",
					payload: { userExists: false },
				});
			}
		} catch (e) {
			yield put({ type: "auth/resetPassword/rejected", payload: {} });
		}
	});
}

function* resendActivationEmailSaga() {
	yield takeLatest(
		"auth/activation/email/resend/request",
		function* (action) {
			try {
				yield put({
					type: "auth/activation/email/resend/pending",
					payload: {},
				});
				const validationRequest = yield call(
					async () =>
						await Auth.resendActivation(
							action.payload.activationEmail
						)
				);
				if (validationRequest.userExists) {
					yield put({
						type: "auth/activation/email/resend/fulfilled",
						payload: { userExists: true },
					});
				} else {
					yield put({
						type: "auth/activation/email/resend/rejected",
						payload: { userExists: false },
					});
				}
			} catch (e) {
				yield put({
					type: "auth/activation/email/resend/rejected",
					payload: {},
				});
			}
		}
	);
}

function* validateEmailSaga() {
	yield takeLatest("auth/email/validate/request", function* (action) {
		try {
			yield put({
				type: "auth/email/validate/pending",
				payload: {},
			});
			const validateEmailApiResponse = yield call(
				async () => await Auth.validateEmail(action.payload.loginEmail)
			);
			if (validateEmailApiResponse) {
				yield put({
					type: "auth/email/validate/fulfilled",
					payload: { response: validateEmailApiResponse },
				});
			} else {
				yield put({
					type: "auth/email/validate/rejected",
					payload: { response: validateEmailApiResponse },
				});
			}
		} catch (e) {
			yield put({
				type: "auth/email/validate/rejected",
				payload: {},
			});
		}
	});
}

function* signupSaga() {
	yield takeLatest("auth/signup/request", function* (action) {
		try {
			yield put({
				type: "auth/signup/pending",
				payload: {},
			});
			const signupRequest = yield call(
				async () => await Auth.registerUser(action.payload.params)
			);
			if (signupRequest && !signupRequest.signupError) {
				const userDetails = yield call(
					async () => await UserApi.getUserDetails()
				);
				if (userDetails) {
					yield put({
						type: "auth/signup/fulfilled",
						payload: {
							loginSuccess: true,
							userDetails: userDetails,
						},
					});
				} else {
					yield put({
						type: "auth/signup/fulfilled",
						payload: { loginSuccess: true, userDetails: null },
					});
				}
			} else {
				yield put({
					type: "auth/signup/rejected",
					payload: { userExists: false },
				});
			}
		} catch (e) {
			yield put({
				type: "auth/signup/rejected",
				payload: {},
			});
		}
	});
}

function* signupAndFollowSaga() {
	yield takeLatest("auth/signup/board/follow/request", function* (action) {
		try {
			yield put({
				type: "auth/signup/board/follow/pending",
				payload: {},
			});
			const signupRequest = yield call(
				async () => await Auth.registerUser(action.payload.signupParams)
			);
			if (signupRequest) {
				const followBoard = yield call(
					async () =>
						await BoardApi.joinBoard(
							action.payload.followBoardParams
						)
				);
				if (followBoard) {
					const userDetails = yield call(
						async () => await UserApi.getUserDetails()
					);
					if (userDetails) {
						yield put({
							type: "auth/signup/board/follow/fulfilled",
							payload: {
								loginSuccess: true,
								userDetails: userDetails,
							},
						});
					} else {
						yield put({
							type: "auth/signup/board/follow/rejected",
							payload: {
								loginSuccess: true,
								userDetails: null,
							},
						});
					}
				} else {
					yield put({
						type: "auth/signup/board/follow/rejected",
						payload: {
							loginSuccess: true,
							userDetails: null,
						},
					});
				}
			} else {
				yield put({
					type: "auth/signup/board/follow/rejected",
					payload: { userExists: false },
				});
			}
		} catch (e) {
			yield put({
				type: "auth/signup/board/follow/rejected",
				payload: {},
			});
		}
	});
}

function* setNewPasswordSaga() {
	yield takeLatest("auth/password/new/request", function* (action) {
		try {
			yield put({
				type: "auth/password/new/pending",
				payload: {},
			});
			const loginRequest = yield call(
				async () =>
					await AccountApi.updateAccountPassword(action.payload)
			);
			if (loginRequest) {
				yield put({
					type: "auth/password/new/fulfilled",
					payload: { updatePasswordSuccess: true },
				});
			} else {
				yield put({
					type: "auth/password/new/rejected",
					payload: { updatePasswordSuccess: false },
				});
			}
		} catch (e) {
			yield put({
				type: "auth/password/new/rejected",
				payload: {},
			});
		}
	});
}

function* validateUserSaga() {
	yield takeLatest("auth/user/validate/request", function* () {
		try {
			yield put({
				type: "auth/user/validate/pending",
				payload: {},
			});
			const userDetailsRequest = yield call(
				async () => await UserApi.getUserDetails()
			);
			if (userDetailsRequest) {
				yield put({
					type: "auth/user/validate/fulfilled",
					payload: { updatePasswordSuccess: true },
				});
			} else {
				yield put({
					type: "auth/user/validate/rejected",
					payload: { updatePasswordSuccess: false },
				});
			}
		} catch (e) {
			yield put({
				type: "auth/user/validate/rejected",
				payload: {},
			});
		}
	});
}

function* facebookAuthSaga() {
	yield takeLatest("auth/facebookLogin/request", function* (action) {
		try {
			yield put({
				type: "auth/facebookLogin/pending",
				payload: {},
			});
			const facebookAuthRequest = yield call(
				async () => await Auth.fbLogin(action.payload)
			);
			if (facebookAuthRequest) {
				const userDetails = yield call(
					async () => await UserApi.getUserDetails()
				);
				if (userDetails) {
					yield put({
						type: "auth/facebookLogin/fulfilled",
						payload: {
							loginSuccess: true,
							userDetails: userDetails,
						},
					});
				} else {
					yield put({
						type: "auth/facebookLogin/fulfilled",
						payload: { loginSuccess: true, userDetails: null },
					});
				}
			} else {
				yield put({
					type: "auth/facebookLogin/rejected",
					payload: { userExists: false },
				});
			}
		} catch (e) {
			yield put({
				type: "auth/facebookLogin/rejected",
				payload: {},
			});
		}
	});
}

function* socialAuthSaga() {
	yield takeLatest(Actions.socialLogin + "request", function* (action) {
		try {
			yield put({
				type: Actions.socialLogin + "pending",
				payload: {},
			});

			const socialAuthRequest = yield call(
				async () => await Auth.socialLogin(action.payload)
			);

			if (!socialAuthRequest) throw new Error("Auth request failed!");

			const userDetails = yield call(
				async () => await UserApi.getUserDetails()
			);

			if (!userDetails) throw new Error("User details failed!");

			yield put({
				type: Actions.socialLogin + "fulfilled",
				payload: {
					loginSuccess: true,
					userDetails: userDetails,
				},
			});
		} catch (e) {
			yield put({
				type: Actions.socialLogin + "rejected",
				payload: {},
			});
		}
	});
}

export const authSagas = [
	socialAuthSaga(),
	loginSaga(),
	resetPasswordSaga(),
	resendActivationEmailSaga(),
	validateEmailSaga(),
	signupSaga(),
	signupAndFollowSaga(),
	setNewPasswordSaga(),
	validateUserSaga(),
	facebookAuthSaga(),
	socialAuthSaga(),
];
