import { Actions } from "../../manageScreenApps.saga";

export const initialState = {
	// selectedCollections is one level up
	isLoading: false,
	searchString: "",
	boardsList: [],
	searchType: "board",
	selectedBoard: null,
	collectionsList: [],
};

export const convertToEndpoint = ({ collections, noHash = false }) => {
	let result = [...collections].reduce((calculatedValue, item) => {
		const isEmpty = calculatedValue === "";
		const boardId = item.boardId + ":";
		if (calculatedValue.includes(boardId)) {
			return calculatedValue.replace(
				boardId,
				boardId + item.collection + ","
			);
		}
		return (
			calculatedValue + (isEmpty ? "" : "|") + boardId + item.collection
		);
	}, "");

	if (noHash) {
		result = result.replace(/#/g, "");
	}

	return result;
};

export const convertFromEndpoint = ({
	endpoint,
	currentBoard,
	boards = [],
	noBoardName = false,
}) => {
	if (!endpoint || endpoint === "") return [];
	return endpoint.split("|").reduce((calculatedValues, item) => {
		const [boardId, collections] = (
			item.includes(":") ? item : `${currentBoard.boardId}:${item}`
		).split(":");
		const board = boards.find((board) => board.boardId === +boardId);
		const boardSets = collections.split(",").map((collection) => {
			const result = {
				boardId,
				collection: "#" + collection.replace("#", ""),
			};

			if (!noBoardName) {
				result["boardName"] = (board ? board : currentBoard).boardName;
			}

			return result;
		});
		return [...boardSets, ...calculatedValues];
	}, []);
};

export const searchForCollections = ({ boardId }) => {
	return {
		type: Actions.getCollections + "request",
		payload: { boardId },
	};
};

export const searchForBoards = ({ searchString }) => {
	return {
		type: Actions.getBoards + "request",
		payload: { searchString: searchString.replace("@", "") },
	};
};

export const filterExistingCollections = ({ collections, selectedValues }) => {
	const transformer = ({ boardId, collection }) => {
		return `${boardId}-${collection}`;
	};
	const transformedSelectedCollections = selectedValues.map((item) =>
		transformer(item)
	);
	return collections.filter((item) => {
		const collection = transformer(item);
		return !transformedSelectedCollections.includes(collection);
	});
};

export const tagsToCollectionSets = (tags = []) => {
	return convertFromEndpoint({
		endpoint: tags
			.reduce((result, item) => {
				const stringSet = `${item.boardId}:${item.collections.join()}`;
				return [stringSet, ...result];
			}, [])
			.join("|"),
		noBoardName: true,
	});
};

export const reducers = {
	resetPanel: (state) => {
		state.collectionAppProps.collectionSelector = { ...initialState };
	},
	syncData: (state, action) => {
		state.collectionAppProps.selectedCollections = action.payload;
	},
	filterLocalCollections: (state, action) => {
		const {
			searchString,
			boardDetails: { boardId, boardName, boardCollections },
		} = action.payload;
		const { selectedCollections } = state.collectionAppProps;

		const collections = boardCollections
			.map((item) => item.collectionName)
			.filter((item) => item.includes(searchString))
			.map((collection) => ({
				boardId: boardId,
				boardName: boardName.replace(/ /g, "_"),
				collection,
			}));

		state.collectionAppProps.collectionSelector.selectedBoard = {
			boardId,
			boardName,
		};
		state.collectionAppProps.collectionSelector.searchType = "collection";
		state.collectionAppProps.collectionSelector.collectionsList =
			filterExistingCollections({
				collections,
				selectedValues: selectedCollections,
			});
	},
	setSearchType: (state, action) => {
		state.collectionAppProps.collectionSelector.searchType = action.payload;
	},
	setSearchText: (state, action) => {
		state.collectionAppProps.collectionSelector.searchString =
			action.payload;
		if (!action.payload.includes("@")) {
			state.collectionAppProps.collectionSelector.selectedBoard = null;
		}
	},
	removeCollection: (state, action) => {
		const { index } = action.payload;
		state.collectionAppProps.selectedCollections =
			state.collectionAppProps.selectedCollections.filter(
				(_, itemIndex) => itemIndex !== index
			);
	},
	selectBoard: (state, action) => {
		const { boardId, boardName } = action.payload;
		state.collectionAppProps.collectionSelector.searchString =
			"@" + boardName;
		state.collectionAppProps.collectionSelector.searchType = "collection";
		state.collectionAppProps.collectionSelector.selectedBoard = {
			boardId,
			boardName,
		};
	},
	selectCollection: (state, action) => {
		const { collection } = action.payload;
		const { boardId, boardName } =
			state.collectionAppProps.collectionSelector.selectedBoard;
		state.collectionAppProps.collectionSelector.searchString = "";
		state.collectionAppProps.selectedCollections = [
			{ boardId, boardName: boardName.replace(/ /g, "_"), collection },
			...state.collectionAppProps.selectedCollections,
		];
	},
};

export const extraReducers = {
	[Actions.getBoards + "pending"]: (state) => {
		state.collectionAppProps.collectionSelector.isLoading = true;
	},
	[Actions.getBoards + "rejected"]: (state) => {
		state.collectionAppProps.collectionSelector.isLoading = false;
	},
	[Actions.getBoards + "fulfilled"]: (state, action) => {
		state.collectionAppProps.collectionSelector.isLoading = false;
		state.collectionAppProps.collectionSelector.boardsList = action.payload;
	},
	//
	[Actions.getCollections + "pending"]: (state) => {
		state.collectionAppProps.collectionSelector.isLoading = true;
	},
	[Actions.getCollections + "rejected"]: (state) => {
		state.collectionAppProps.collectionSelector.isLoading = false;
	},
	[Actions.getCollections + "fulfilled"]: (state, action) => {
		if (!state.collectionAppProps.collectionSelector.selectedBoard)
			return state;
		const { boardId, boardName } =
			state.collectionAppProps.collectionSelector.selectedBoard;
		state.collectionAppProps.collectionSelector.isLoading = false;
		state.collectionAppProps.collectionSelector.collectionsList =
			filterExistingCollections({
				collections: action.payload.map((collection) => ({
					boardId,
					boardName,
					collection,
				})),
				selectedValues: state.collectionAppProps.selectedCollections,
			});
	},
};
