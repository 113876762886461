import { httpGetV2 } from "./http-wrapper";
import { BEAMER_API_KEY } from "../helper/constants";

/**
 * Fetch filters for getting changelogs
 *
 * Response will be something like:
 * ```
 * {
 * 	"role": "admin;poster",
 * 	"city": "",
 * 	"country": ""
 * }
 * ```
 */
export const getFilters = async () => {
	return httpGetV2("private/accmgmt/beamerFilters", null, null);
};

/**
 * Get changelog badge count
 * @param filters User filters
 */
export const getData = async (filters) => {
	const response = await fetch(
		`https://api.getbeamer.com/v0/unread/count?filter=${filters}`,
		{
			method: "GET",
			headers: {
				"Beamer-Api-Key": BEAMER_API_KEY,
			},
		}
	);
	if (response.status === 200) {
		return response.json();
	} else {
		throw Error("Beamer API failed!");
	}
};
