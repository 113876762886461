/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-31 10:51:59
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-06-07 01:16:44
 */

export const SIGNUP = "SIGNUP";
export const LOGIN = "LOGIN";
export const BOARD_LOGIN = "BOARD_LOGIN";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESEND_ACTIVATION = "RESEND_ACTIVATION";
export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
