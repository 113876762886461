/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as queryString from "query-string";

import { apiRequest } from "../api/http-wrapper";

import { ACTION_STATES, ADV_DSP_URL, SHARE_ITEM_URL } from "../helper/constants";

import boardTypesLabels from "../components/CreateBoard/steps/StepSelectBoardType/boardTypesLabels.json";

export const convertPostedTime = (postedTime: string) => {
    if (!postedTime) return;

    return postedTime
        .replace("a few", "")
        .replace("second", "now")
        .replace("minute", "m")
        .replace("hour", "h")
        .replace("day", "d")
        .replace("week", "w")
        .replace("month", "mo")
        .replace("year", "y")
        .replace("ago", "")
        .replace("s", "")
        .replace(" ", "");
};

export const getFormLabels = (boardType?: keyof typeof boardTypesLabels) => {
    const commonLabels = boardTypesLabels["common"];
    return {
        ...commonLabels,
        ...(boardType ? boardTypesLabels[boardType] : null),
    };
};

/**
 * Navigate user to the advertising platform.
 * @param {Object} params
 * @param {string} params.subPages If we need the user to goto any sub pages of ad-space.
 * @param {string} params.additionalParams
 * @param {boolean} params.noToken
 */
export const navigateToAdPlatform = (params: any = {}) => {
    const token = localStorage.getItem("authToken")?.trim() || "";

    const data: any = params.additionalParams || {};
    if (!params.noToken) {
        data.token = token;
    }

    window.open(
        [ADV_DSP_URL, params.subPages || "", "?", queryString.stringify(data)].join(""),
        "_blank",
    );
};

/**
 * Returns the `boardDetails` with boolean field `isAdvertisingEnabled` which
 * tell us if the board as advertising enabled.
 * @param {Object} boardDetails
 */
export const mergeWithAdvertisingEnabled = (boardDetails: Record<string, any>) => {
    // Certain boards only have the advertising option. For now.

    const locations = boardDetails.locations || [{}];
    const regionCode = locations[0].regionCode;
    const countryCode = locations[0].countryCode;

    // board location should be in certain cities in USA.
    const isAreaEligible = countryCode === "US" && ["NY", "NJ", "CT", "PA"].includes(regionCode);

    const { isCustomAdvertisingPromptEnabled } = boardDetails.customSettings || {};

    return {
        ...boardDetails,
        // for board level functions
        isAdvertisingEnabled: (() => {
            if (isCustomAdvertisingPromptEnabled) return true;
            if (boardDetails.boardType === "HLB") return isAreaEligible;
            return false;
        })(),
    };
};

/**
 * Based on the provided query param, check if redirect is needed to given page.
 * @param {string} queryName The query param name.
 */
export const checkQueryRedirect = (queryName: string) => {
    // checking for board URL pattern /b/:boardId/boardName
    const expression = new RegExp(/https?:\/\/([\S]+)\/b\/([0-9]+)?\/(\w+)/gm);
    // ignore if not
    if (!expression.test(location.href)) return false;

    const queryParamsObject = queryString.parse(location.search);
    return queryParamsObject[queryName];
};

export const generateDefaultActions = (actionsObject: Record<string, any>) => {
    return Object.keys(actionsObject || {}).reduce((result, actionKey) => {
        return {
            ...result,
            ...Object.values(ACTION_STATES).reduce((actionsResult, actionState) => {
                return {
                    ...actionsResult,
                    [actionsObject[actionKey] + actionState]: (state: any) => {
                        state[actionKey + "APIStatus"] = actionState;
                    },
                };
            }, {}),
        };
    }, {});
};

/**
 * This will format the provided number (in bytes).
 * @returns the formatted file size
 */
export const formatFileSize = (bytes: number, decimalPoint = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint)) + " " + sizes[i];
};

/**
 * This can upload files, and accepts an upload progress callback function.
 */
export const uploadFiles = (params: {
    url: string;
    files: File | File[];
    onProgress: (arg: number) => void;
}) =>
    new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener("progress", (e) =>
            params.onProgress(+(e.loaded / e.total).toFixed(2)),
        );
        xhr.addEventListener("load", () => resolve({ status: xhr.status, body: xhr.responseText }));
        xhr.addEventListener("error", () => reject(new Error("File upload failed")));
        xhr.addEventListener("abort", () => reject(new Error("File upload aborted")));
        xhr.open("PUT", params.url, true);

        // @ts-ignore
        if (typeof params.files[Symbol.iterator] === "function") {
            const formData = new FormData();
            Array.from(params.files as File[]).forEach((file, index) =>
                formData.append(index.toString(), file),
            );
            xhr.send(formData);
        } else {
            // @ts-ignore
            xhr.send(params.files);
        }
    });

/**
 * This will return a file icon based on the file name (with ext) provided
 */
export const getIconUrl = (fileName: string) => {
    if (/.(jpg|jpeg|png|gif|svg)$/.test(fileName)) {
        return "https://d9yce0v7jgjpv.cloudfront.net/appicons/Image.svg";
    }
    if (/.(pdf)$/.test(fileName)) {
        return "https://d9yce0v7jgjpv.cloudfront.net/appicons/PDF.svg";
    }
    if (/.(doc|docx)$/.test(fileName)) {
        return "https://d9yce0v7jgjpv.cloudfront.net/appicons/Word%20Doc.svg";
    }
    if (/.(ppt|pptx)$/.test(fileName)) {
        return "https://d9yce0v7jgjpv.cloudfront.net/appicons/Power%20point.svg";
    }
    if (/.(xls|xlsx)$/.test(fileName)) {
        return "https://d9yce0v7jgjpv.cloudfront.net/appicons/Excel%20file.svg";
    }

    return "https://d9yce0v7jgjpv.cloudfront.net/appicons/FIle%20Upload.svg";
};

/**
 * This will remove all falsy values from an object and return an new object
 */
export const removeFalsyValuesFromObject = <T extends Record<string, any>>(obj: T) => {
    return Object.fromEntries(Object.entries(obj).filter(([, value]) => !!value));
};

/**
 * This will generate a shareable url with the passed params
 */
export const generateShareableUrl = (data: {
    /** URL of the website to be redirected to. eg: https://hootboard.com. */
    url?: string | null;
    /** Website Name. eg: HootBoard. */
    websiteName?: string | null;
    /** Facebook App's ID. eg: fb23445345. */
    facebookAppId?: string | null;
    /** Alt value for the image params. */
    imageAlt?: string | null;
    /** Twitter username. eg @hootboard. */
    twitterUsername?: string | null;
    /** Description that needs to be document. */
    description?: string | null;
    /** Full image URL for the thumbnail. */
    image?: string | null;
    /** Title. */
    title?: string | null;
}) => {
    return `${SHARE_ITEM_URL}?${new URLSearchParams({
        websiteName: "HootBoard",
        twitterUsername: "@HootBoard",
        ...removeFalsyValuesFromObject(data),
    })}`;
};

/**
 * Get custom settings from board object.
 */
export const getCustomSettings = (boardDetails: any) => {
    if (!boardDetails) return {};
    if (!boardDetails.customSettings) return {};
    return boardDetails.customSettings;
};

/**
 * API to get a signed upload request.
 */
export const getSignedUploadRequest = (
    boardId: number,
    params: { fileName: string; fileType: string },
) => {
    return apiRequest("upload/sr", {
        payload: {
            ...params,
            context: "files",
        },
        extraHeaders: { "x-board-id": boardId },
    });
};

/**
 * Join classes into a single string.
 * Useful when if we want to use conditions inside classNames prop.
 */
export const cl = (...classNames: (string | undefined | boolean)[]) => {
    return classNames.filter((item) => !!item).join(" ");
};

/**
 * Shuffles the items in a given array.
 */
export const shuffleArrayItems = (array: string[]) => {
    const shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
};

/**
 * Converts an array of objects to a CSV string.
 */
export function jsonToCsv(items: any[]) {
    const header = Object.keys(items[0]);
    const headerString = header.join(",");

    // handle null or undefined values here
    const replacer = (_: any, value: any) => value ?? "";

    const rowItems = items.map((row) =>
        header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(","),
    );
    const csv = [headerString, ...rowItems].join("\r\n");

    return csv;
}
