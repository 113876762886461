import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "../../api/app.service";
import * as BoardsAPI from "../../api/boards.service";

export const Actions = {
	getBoards: "manage-screen-apps/get-boards/",
	getCollections: "manage-screen-apps/get-collections/",
	getSignedUploadRequest: "manage-screen-apps/get-signed-upload-request/",
	saveFileApp: "manage-screen-apps/save-file-app/",
	updateFileApp: "manage-screen-apps/update-file-app/",
};

function* getBoardsSaga() {
	const base = Actions.getBoards;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { searchString } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(async () => {
				return await BoardsAPI.searchBoards({ searchString });
			});

			if (apiResponse.message !== "success" || !apiResponse.data) {
				throw new Error(apiResponse);
			}

			yield put({
				type: base + "fulfilled",
				payload: apiResponse.data,
			});
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

function* getCollectionsSaga() {
	const base = Actions.getCollections;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(async () => {
				return await BoardsAPI.getBoardCollections({ boardId });
			});

			if (apiResponse.message !== "success" || !apiResponse.data) {
				throw new Error(apiResponse);
			}

			yield put({
				type: base + "fulfilled",
				payload: apiResponse.data,
			});
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

function* getSignedUploadRequestSaga() {
	const base = Actions.getSignedUploadRequest;

	yield takeLatest(base + "request", function* (action) {
		try {
			yield put({ type: base + "pending" });

			const apiResponse = yield call(() => {
				const { boardId, fileName, fileType } = action.payload;
				return API.fetchSignedUploadRequest(boardId, {
					fileName,
					fileType,
				});
			});

			if (!apiResponse.success) {
				throw new Error(apiResponse);
			}

			yield put({
				type: base + "fulfilled",
				payload: apiResponse.data,
			});
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

function* saveFileAppSaga() {
	const base = Actions.saveFileApp;

	yield takeLatest(base + "request", function* (action) {
		try {
			yield put({ type: base + "pending" });

			const apiResponse = yield call(() => {
				const { boardId, appProps, fileAppProps } = action.payload;
				return API.createApp(boardId, {
					boardAppsEndpoints: [
						{
							endpoint: fileAppProps.url,
							collectionBehavior: "U",
							boardScreenId: appProps.selectedScreens
								.map(({ screenId }) => screenId)
								.join(),
						},
					],
					appName: appProps.name,
					boardId,
					appTypeCode: appProps.type.appTypeCode,
					platformAppId: "9",
					showAllScreens: appProps.showOnAllScreens,
					appIconUrl: appProps.iconUrl,
					private: appProps.private,
					showOnMobile: appProps.showOnMobile,
					showOnWeb: appProps.showOnWeb,
				});
			});

			if (apiResponse.message !== "success") return { success: false };

			yield put({
				type: base + "fulfilled",
				payload: { success: true, app: apiResponse.data },
			});
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

function* updateFileAppSaga() {
	const base = Actions.updateFileApp;

	yield takeLatest(base + "request", function* (action) {
		try {
			yield put({ type: base + "pending" });

			const apiResponse = yield call(() => {
				const { boardId, appProps, fileAppProps, app } = action.payload;
				return API.updateApp(boardId, app.boardAppsId, {
					...app,
					appName: appProps.name,
					showAllScreens: appProps.showOnAllScreens,
					boardId,
					appIconUrl: appProps.iconUrl,
					private: appProps.appAccess !== "public",
					showOnMobile: appProps.showOnMobile,
					showOnWeb: appProps.showOnWeb,
					boardAppsEndpoints: [
						{
							...app.boardAppsEndpoints[0],
							endpoint: fileAppProps.url,
							boardScreenId: appProps.selectedScreens
								.map(({ screenId }) => screenId)
								.join(),
						},
					],
				});
			});

			if (apiResponse.message !== "success") return { success: false };

			yield put({
				type: base + "fulfilled",
				payload: { success: true, app: apiResponse.data },
			});

			const appsResponse = yield call(() =>
				API.getBoardApps(action.payload.boardId)
			);
			yield put({
				type: "board/details/setApps",
				payload: appsResponse.data,
			});
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

export const manageScreenAppsSagas = [
	getBoardsSaga(),
	getCollectionsSaga(),
	getSignedUploadRequestSaga(),
	saveFileAppSaga(),
	updateFileAppSaga(),
];
