import {
	SEND_MESSAGE_ENDPOINT,
	SHARE_VIA_EMAIL_ENDPOINT,
	URL_SHORTENER_API_KEY,
	URL_SHORTENER_URL,
} from "../helper/constants";
//
import { APIMethods } from "../types";
import { ShareViaEmailPayload } from "../types/sharing";

/**
 * It accepts a url and returns back a short url for that.
 */
export const getUrlShortCode = async (url: string) => {
	const response = await fetch(
		`${URL_SHORTENER_URL}/api/shorten?key=${URL_SHORTENER_API_KEY}&url=${encodeURIComponent(
			url
		)}`,
		{
			method: APIMethods.GET,
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		}
	);

	if (!response.ok) throw new Error("");

	return response.json();
};

export const sendMessage = async (data: string) => {
	const response = await fetch(SEND_MESSAGE_ENDPOINT, {
		method: APIMethods.POST,
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});

	if (!response.ok) throw new Error("");

	return response.json();
};

export const shareViaEmails = async (
	data: ShareViaEmailPayload & {
		distinctId: string;
		userId: number;
		userEmail: string;
	}
) => {
	/* - Temporary disabling subscribing to the emails
	if ( "boardId" in data) {
		for (const email of data.recipients) {
			if (email == data.userEmail) continue;
			BoardsService.subscribeEmail(data.boardId, {
				distinctId: data.distinctId,
				subscriberEmail: email,
			});
		}
	} */
	const response = await fetch(SHARE_VIA_EMAIL_ENDPOINT, {
		method: APIMethods.POST,
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});

	if (!response.ok) throw new Error("");

	return response.json();
};
