/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-27 11:48:27
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-08-21 19:45:21
 */

import { createSlice } from "@reduxjs/toolkit";

export function followBoardAsync(payload) {
	return {
		type: "board/follow/request",
		payload,
	};
}

export function requestBoardAccessAsync(payload) {
	return {
		type: "board/access/request/request",
		payload: payload,
	};
}

export function changeBoardAccessAsync(payload) {
	return {
		type: "board/access/change/request",
		payload: payload,
	};
}

const initialState = {
	boardCommunityCode: "",
	boardCommunityCodeError: true,
	boardCommunityCodeErrorMessage: "A valid community code is required",
	changeBoardAccessStatus: "idle",
	requestBoardAccessStatus: "idle",
	followBoardStatus: "idle",
	showAllFormErrors: false,
};

const boardAccessSlice = createSlice({
	name: "boardAccess",
	initialState: initialState,
	reducers: {
		reset: () => {
			return initialState;
		},
		boardCommunityCodeEntered: (state, action) => {
			return {
				...state,
				boardCommunityCode: action.payload.boardCommunityCode,
				boardCommunityCodeError:
					action.payload.boardCommunityCode.trim().length
						? false
						: true,
				boardCommunityCodeErrorMessage: "Need a valid community code",
			};
		},
		showFormValidationErrors: (state) => {
			return {
				...state,
				showAllFormErrors: true,
			};
		},
	},
	extraReducers: {
		"board/access/change/fulfilled": (state) => {
			return {
				...state,
				changeBoardAccessStatus: "fulfilled",
			};
		},
		"board/access/change/pending": (state) => {
			return {
				...state,
				changeBoardAccessStatus: "pending",
			};
		},
		"board/access/change/rejected": (state) => {
			return {
				...state,
				changeBoardAccessStatus: "rejected",
				showAllFormErrors: false,
			};
		},
		"board/access/request/fulfilled": (state) => {
			return {
				...state,
				requestBoardAccessStatus: "fulfilled",
			};
		},
		"board/access/request/pending": (state) => {
			return {
				...state,
				requestBoardAccessStatus: "pending",
			};
		},
		"board/access/request/rejected": (state) => {
			return {
				...state,
				requestBoardAccessStatus: "rejected",
				showAllFormErrors: false,
			};
		},
		"board/follow/fulfilled": (state) => {
			return {
				...state,
				followBoardStatus: "fulfilled",
			};
		},
		"board/follow/pending": (state) => {
			return {
				...state,
				followBoardStatus: "pending",
			};
		},
		"board/follow/rejected": (state, action) => {
			return {
				...state,
				followBoardStatus: "rejected",
				showAllFormErrors: false,
				linkExpired: action.payload.linkExpired,
			};
		},
	},
});
export const { showFormValidationErrors, boardCommunityCodeEntered, reset } =
	boardAccessSlice.actions;
export default boardAccessSlice.reducer;
