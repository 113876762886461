import { apiRequest, httpGetV2, httpPostV2, httpPutV2 } from "./http-wrapper";

/**
 * API to publish a shared app into app store
 * @param {number} boardId
 * @param {Object} payload
 */
export const publishApp = async ({ boardId, payload }) => {
	const headers = { "x-board-id": boardId };
	const apiEndpoint = "private/appstore/app";
	const response = await httpPostV2(apiEndpoint, payload, headers);
	return response;
};

/**
 * API to update a published app
 * @param {number} boardId
 * @param {string} appUUID
 * @param {Object} payload
 */
export const updatePublishedApp = async ({ boardId, appUUID, payload }) => {
	const headers = { "x-board-id": boardId };
	const apiEndpoint = "private/appstore/app/" + appUUID;
	const response = await httpPutV2(apiEndpoint, payload, headers);
	return response;
};

/**
 * API to get un-publish an app
 * @param {number} boardId
 * @param {number} appId
 *
 */
export const unPublishApp = async ({ appId, boardId }) => {
	const headers = { "x-board-id": boardId };
	const apiEndpoint = `private/appstore/app/unpublish/${appId}`;
	const response = await httpPutV2(apiEndpoint, null, headers);
	return response;
};

/**
 * API to get all apps from app directory
 * @param {boolean} global If we want to pull all the apps which are published globally (Required)
 * @param {number} start start index for the record (Optional)
 * @param {number} max end index of record (Optional)
 * @param {string} zipcode For zip code for which you have to fetch the apps (Optional)
 * @param {number} lattitude For which lat long you want to fetch the apps (Optional)
 * @param {number} longitude For which lat long you want to fetch the apps (Optional)
 */
export const getAppDirectory = async ({ boardId, latitude, longitude }) => {
	const headers = { "x-board-id": boardId };
	const apiEndpoint = "private/appstore/published/app";
	const response = await httpGetV2(
		apiEndpoint,
		{ latitude, longitude },
		headers
	);
	return response;
};

/**
 * API to install from app directory
 * @param {number} boardId
 * @param {string} appId
 * @param {string} appIcon
 * @param {boolean} showAllScreens
 * @param {boolean} showOnMobile
 * @param {boolean} showOnWeb
 * @param {number} boardScreenIds Coma separated values of screenIds
 */
export const installApp = async ({
	boardId,
	appId,
	appIcon,
	showOnWeb,
	isPrivate,
	showOnMobile,
	showAllScreens,
	boardScreenIds,
}) => {
	const headers = { "x-board-id": boardId };
	const apiEndpoint = "private/appstore/app/install";
	const response = await httpPostV2(
		apiEndpoint,
		{
			appId,
			showOnWeb,
			appIconUrl: appIcon,
			private: isPrivate,
			showOnMobile,
			showAllScreens,
			boardScreenIds,
		},
		headers
	);
	return response;
};

/**
 * API to get all the apps which user has created. It will return all app except Deleted
 * @param {number} boardId
 */
export const getBoardAppDirectory = async ({ boardId }) => {
	const headers = { "x-board-id": boardId };
	const apiEndpoint = "private/appstore/app";
	const response = await httpGetV2(apiEndpoint, null, headers);
	return response;
};

/**
 * API to get recommended apps against a board type.
 * @param {number} boardId
 */
export const getRecommendedApps = async (boardId) => {
	return apiRequest(`boards/${boardId}/apps/recommended-apps`);
};
