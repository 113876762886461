/*
 * @Author: tnityanand@hootboard.com
 * @Date: 2020-06-09 18:33:47
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2021-03-04 10:17:40
 */

import moment from "moment-timezone";

export default function mapHootDataForEdit(defaultState, hootData) {
	const populatedData = {
		...defaultState,
		hootId: hootData.id,
		hootTitle: hootData.title,
		hootDesc:
			hootData.type === "HLVID" || hootData.type === "HLBLOG" || hootData.type === "HLLIST"
				? hootData.userQuote
				: hootData.description,
		postingCategory: hootData.postingCategory,
		selectedHootTypeIdx: 0, //Based on the category the selectedHootTypeIdx will be selected
		postHootStage: 1, //Open the create hoot section directly
		selectedHootBgColor: hootData.hootBgColorClass, //Need to manipulate
		customHootThemeColors: hootData.customHootThemeColors,
		hootCoverImages: parseHootCoverImages(
			hootData.type,
			hootData.largeImages
		), //Need to manipulate
		hootLocation: parseHootLocation(
			hootData.eventLocationPlaceId,
			hootData.eventLocationText
		), //Need to convert to Google Place
		actionButtonLabel: hootData.buttonLabel,
		actionButtonLink: hootData.buttonLink,
		hootExpiryDate: hootData.hootExpiryDate ? hootData.hootExpiryDate : "",
		eventDetails: parseEventDetails(
			createEventDateObject(hootData.eventStartDateTime),
			createEventDateObject(hootData.eventEndDateTime),
			hootData
		),
		recurrenceRule: hootData.recurringRule, //Need to manipulate
		selectedCollections: parseCollections(hootData.boardTagsList), //Need to convert comma separated to array
		showActionButtonControl: hootData.buttonLabel ? true : false,
		showHootLocationControl:
			hootData.eventLocationPlaceId &&
			hootData.eventLocationPlaceId !== ""
				? true
				: false,
		showHootExpiryControl: hootData.hootExpiryDate ? true : false,
		showHootEventControl:
			hootData.eventStartDateTime !== null ? true : false,
		hootTitleError: false,
		hootDescError: false,
		boardSelectionError: false,
		showHootCoverImageControl:
			hootData.type === "HLBLOG" ||
			hootData.type === "HLVID" ||
			hootData.type === "HLLIST" ||
			hootData.type.indexOf("UF") !== -1
				? false
				: true,
		embedLinkHoot:
			hootData.type === "HLBLOG" || hootData.type === "HLVID" || hootData.type === "HLLIST"
				? true
				: false,
		fileHoot: hootData.type.indexOf("UF") !== -1 ? true : false,
		fileHootShowTitle: hootData.showTitle,
		selectedBoardId: hootData.owningBoardId,
		scheduledHootDate: hootData.scheduledOnDate
			? moment(hootData.scheduledOnDate, "Do MMM, YYYY").format(
					"MMM DD, YYYY"
			  )
			: "",
		isEditHoot: true,
	};
	return populatedData;
}

function parseHootCoverImages(hootType, hootDataImages) {
	let hootCoverImages = [];
	//As the images object contains both the hootcover iamges and images from the link, need to check only for discussion hoot type
	if (hootType === "DISC" && hootDataImages) {
		hootDataImages.map((image) => {
			hootCoverImages.push({
				url: image.url,
				id: image.id,
			});
		});
	}
	return hootCoverImages;
}

function parseHootLocation(placeId, formattedAddress) {
	let hootLocation = {
		cityName: "",
		zipCode: "",
		regionName: "",
		countryCode: "",
		formattedAddress: "",
		latitude: 0,
		longitude: 0,
		googlePlaceId: "",
	};
	if (placeId !== "" && formattedAddress !== "") {
		return {
			...hootLocation,
			googlePlaceId: placeId,
			formattedAddress: formattedAddress,
		};
	}
}

const parseEventDetails = (eventStart, eventEnd, hootData) => {
	let eventDetails = {};
	if (
		eventStart !== null &&
		eventStart !== "" // &&
		// eventEnd !== null &&
		// eventEnd !== ""
	) {
		if (eventStart !== null && eventStart !== "") {
			eventDetails = {
				startDate: eventStart,
				startTime: eventStart,
			};
		}
		// if (eventEnd !== null && eventEnd !== "") {
		eventDetails = {
			...eventDetails,
			endDate: eventEnd || eventStart,
			endTime: eventEnd || eventStart,
		};
		// }
		return {
			...eventDetails,
			calendarOnlyHoot: hootData.calendarOnlyHoot,
			removeAfterEvent: !!hootData.hootExpiryDate,
			freeEvent: hootData.freeEvent,
		};
	} else {
		return null;
	}
};

function createEventDateObject(inputDate) {
	if (inputDate != null) {
		return moment(inputDate, "ddd, YYYY MMM Do h:mm A").toDate();
	} else {
		return null;
	}
}

function parseCollections(collections) {
	let selectedCollections = [];
	if (collections && collections.length > 0) {
		collections.map((collection) => {
			selectedCollections.push({
				filterText: collection,
			});
		});
	}
	return selectedCollections;
}
