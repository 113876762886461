import { createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";

import { Actions } from "./sequences.saga";

import { generateDefaultActions } from "../../utils/general.util";
import {
    transformSequence,
    transformSequenceSlots,
    transformSequences,
} from "../../utils/sequence.utils";

import { ACTION_STATES } from "../../helper/constants";

const initialState = {
    selectedTab: 0,
    //
    getDefaultSequencesAPIStatus: ACTION_STATES.IDLE,
    //
    allSequences: [],
    getAllSequencesAPIStatus: ACTION_STATES.IDLE,
    //
    sequenceBuilder: {
        formMode: null, // edit/create,
        formStep: 0,
        //
        selectedSlotPosition: null,
        //
        selectedSlotType: null,
        //
        activeSequenceDetails: {
            // title
            // description
            // orientation
            // sequenceItems => {seqItemTypeCode, seqItemValueType, seqItemValue, seqItemPositionNumber}
        },
        previewContent: null,
    },
    fetchLayoutsAPIStatus: ACTION_STATES.IDLE,
    loadPreviewContentAPIStatus: ACTION_STATES.IDLE,
    //
    getSequenceAPIStatus: ACTION_STATES.IDLE,
    //
    createSequenceAPIStatus: ACTION_STATES.IDLE,
    //
    deleteSequenceAPIStatus: ACTION_STATES.IDLE,
    //
    saveSequenceAPIStatus: ACTION_STATES.IDLE,
    //
    assignSequenceAPIStatus: ACTION_STATES.IDLE,
    //
    updateScheduleAPIStatus: ACTION_STATES.IDLE,
    //
    unAssignSequenceAPIStatus: ACTION_STATES.IDLE,
    //
    markAsDefaultAPIStatus: ACTION_STATES.IDLE,
    //
    sequenceAvailability: [
        // {
        // 	"screenDetails": {
        // 		"screenName": "First Screen",
        // 		"screenId": "qqm9db"
        // 	},
        // 	"assignedSequences": [
        // 		{
        // 			"object": "boardContentSequenceSchedule",
        // 			"id": 1,
        // 			"sequenceId": 14,
        // 			"name": "Regular Week Sequence",
        // 			"description": "This sequence shall run on every weekday.",
        // 			"startDateTime": "2022-10-11T11:26:50.000Z",
        // 			"endDateTime": "2022-10-14T11:26:50.000Z",
        // 			"isDefault": true
        // 		}
        // 	]
        // }
    ],
    getAvailabilityAPIStatus: ACTION_STATES.IDLE,
    //
    sequenceScheduling: {
        dayWidth: 35,
        startDateTime: moment().startOf("day").toDate(),
        endDateTime: moment().add(1, "month").endOf("day").toDate(),
        scheduleInfoDetails: null,
        screensWithSchedule: {},
    },
    //
    slotLayout: {
        currentStep: 0,
        selectedLayout: null,
        selectedZoneId: null,
        availableLayouts: [],
        showSelectContentModal: false,
        zoneType: null,
        isContentAssigned: false,
    },
};

const setDefaultSequences = (state, payload) => {
    /**
     * Payload structure::
     * payload:{
     * sequences:[], List of assigned sequences.
     * defaultSequenceId?: number | null  Its optional in case of unAssignSequence(reducer).
     * }
     *
     * NOTE: defaultSequenceId is only needed when you initially set up the
     * screen sequences calendar UI and when you update it by moving the sliders.
     * Its not needed when you delete an assigned sequence item from the calendar.
     */

    let defaultSequence = state.allSequences.find((item) => item.isDefault);
    const restOfSequences = payload.sequences.filter((item) => !item.isDefault || item.sequenceId);
    /**
     * NOTE: If there is any sequence assigned to the current screen from the manage screens page,
     * then use that sequence as the default sequence otherwise fall back to the actual
     * default marked sequence in allSequences page.
     **/
    if (payload.assignedSequenceId) {
        const foundSequence = state.allSequences.find(
            (item) => item.id == payload.assignedSequenceId,
        );
        if (foundSequence) {
            defaultSequence = { ...foundSequence, isDefault: true };
        }
    }

    const defaultSequences = [];
    if (restOfSequences.length > 0) {
        for (let index = 0; index < restOfSequences.length; index++) {
            const item = restOfSequences[index];
            const newStartDate = moment(item.endDateTime).startOf("day");
            const newEndDate = moment(
                (restOfSequences[index + 1] || {}).startDateTime ||
                    state.sequenceScheduling.endDateTime,
            ).endOf("day");

            if (
                index === 0 &&
                moment(item.startDateTime).isAfter(moment(state.sequenceScheduling.startDateTime))
            ) {
                defaultSequences.push({
                    ...defaultSequence,
                    startDateTime: state.sequenceScheduling.startDateTime,
                    endDateTime: moment(item.startDateTime).subtract(1, "day").toDate(),
                });
            }

            if (!newStartDate.isSame(newEndDate.clone().subtract(1, "day"), "date")) {
                const newStartDateTime = newStartDate.add(1, "day");
                const newEndDateTime = newEndDate.subtract(1, "day");

                if (newStartDateTime.isBefore(moment(state.sequenceScheduling.endDateTime))) {
                    defaultSequences.push({
                        ...defaultSequence,
                        startDateTime: newStartDateTime.toDate(),
                        endDateTime: newEndDateTime.toDate(),
                    });
                }
            }
        }
    } else {
        if (defaultSequence) {
            defaultSequences.push({
                ...defaultSequence,
                startDateTime: state.sequenceScheduling.startDateTime,
                endDateTime: moment(state.sequenceScheduling.endDateTime)
                    .subtract(1, "day")
                    .toDate(),
            });
        }
    }

    return [...defaultSequences, ...restOfSequences];
};

const slice = createSlice({
    name: "sequences",
    initialState,
    reducers: {
        resetState: () => initialState,
        setFormMode: (state, action) => {
            state.sequenceBuilder.formMode = action.payload;
        },
        setTab: (state, action) => {
            state.selectedTab = action.payload;
        },
        setFormStep: (state, action) => {
            state.sequenceBuilder.formStep = action.payload;
        },
        setSlotSelector: (state, action) => {
            state.sequenceBuilder.selectedSlotPosition = action.payload;
        },
        setSlotType: (state, action) => {
            state.sequenceBuilder.selectedSlotType = action.payload;
        },
        setFormData: (state, action) => {
            state.sequenceBuilder.activeSequenceDetails = action.payload;
        },
        resetFormMode: (state) => {
            state.sequenceBuilder = initialState.sequenceBuilder;
        },
        setSlotValue: (state, action) => {
            const { position, value } = action.payload;
            state.sequenceBuilder.activeSequenceDetails.sequenceItems =
                state.sequenceBuilder.activeSequenceDetails.sequenceItems.map((item) => {
                    if (item.seqItemPositionNumber === position) {
                        return {
                            ...item,
                            seqItemValue: value,
                            seqItemValueType: value ? state.sequenceBuilder.selectedSlotType : null,
                        };
                    }
                    return item;
                });
            state.sequenceBuilder.selectedSlotType = null;
            state.sequenceBuilder.selectedSlotPosition = null;
            state.slotLayout.currentStep = 0;
            state.slotLayout.selectedLayout = null;
            state.slotLayout.selectedZoneId = null;
        },
        setScreenSequences: (state, action) => {
            const { screenId, payload } = action.payload;
            state.sequenceScheduling.screensWithSchedule[screenId] = setDefaultSequences(
                state,
                payload,
            );
        },
        setTimelineDates: (state, action) => {
            if (action.payload) {
                const { startDateTime, endDateTime } = action.payload;
                state.sequenceScheduling.startDateTime = startDateTime;
                state.sequenceScheduling.endDateTime = endDateTime;
            } else {
                state.sequenceScheduling.startDateTime =
                    initialState.sequenceScheduling.startDateTime;
                state.sequenceScheduling.endDateTime = initialState.sequenceScheduling.endDateTime;
            }
        },
        setScheduleInfoBox: (state, action) => {
            state.sequenceScheduling.scheduleInfoDetails = action.payload;
        },
        removeSlotValue: (state) => {
            const selectedSlotPosition = state.sequenceBuilder.selectedSlotPosition;
            state.sequenceBuilder.activeSequenceDetails.sequenceItems =
                state.sequenceBuilder.activeSequenceDetails.sequenceItems.map((item) => {
                    if (item.seqItemPositionNumber === selectedSlotPosition) {
                        return {
                            ...item,
                            seqItemValue: null,
                            seqItemValueType: null,
                        };
                    }
                    return item;
                });
            state.sequenceBuilder.selectedSlotType = null;
            state.sequenceBuilder.selectedSlotPosition = null;
        },
        setCurrentStep: (state, action) => {
            state.slotLayout.currentStep = action.payload;
        },

        setSelectedLayout: (state, action) => {
            const layoutId = action.payload;
            if (layoutId !== null) {
                const layout = state.slotLayout.availableLayouts.find(
                    (layout) => layout.id == layoutId,
                );
                const newZones = layout.zones.map((zone) => {
                    return { ...zone, assignedContent: {} };
                });
                layout.zones = newZones;
                if (layout) {
                    state.slotLayout.selectedLayout = layout;
                    state.slotLayout.isContentAssigned = false;
                }
            } else {
                state.slotLayout.selectedLayout = null;
            }
        },
        setAssignedContent: (state, action) => {
            const { zoneId, value, type } = action.payload;
            if (value == null && type == null) {
                state.slotLayout.selectedLayout.zones.find(
                    (item) => item.id == zoneId,
                ).assignedContent = {};
                state.slotLayout.currentStep = 0;
                state.slotLayout.isContentAssigned = false;
            } else {
                state.slotLayout.selectedLayout.zones.find(
                    (item) => item.id == zoneId,
                ).assignedContent = { value, type };
            }
        },
        setShowSelectContentModal: (state, action) => {
            state.slotLayout.showSelectContentModal = action.payload;
        },
        setSelectedZoneId: (state, action) => {
            state.slotLayout.selectedZoneId = action.payload;
        },
        setZoneType: (state, action) => {
            state.slotLayout.zoneType = action.payload;
        },
        setIsContentAssigned: (state, action) => {
            state.slotLayout.isContentAssigned = action.payload;
        },
        copyContentToAllSlots: (state, action) => {
            const { value } = action.payload;
            state.sequenceBuilder.activeSequenceDetails.sequenceItems =
                state.sequenceBuilder.activeSequenceDetails.sequenceItems.map((item) => {
                    if (item.seqItemValue == null && item.seqItemTypeCode !== "HAL") {
                        return {
                            ...item,
                            seqItemValue: value,
                            seqItemValueType: "layoutConfig",
                        };
                    }
                    return item;
                });
            state.sequenceBuilder.selectedSlotType = null;
            state.sequenceBuilder.selectedSlotPosition = null;
            state.slotLayout.currentStep = 0;
            state.slotLayout.selectedLayout = null;
            state.slotLayout.selectedZoneId = null;
        },
    },
    extraReducers: {
        ...generateDefaultActions(Actions),
        //
        [Actions.getDefaultSequences + ACTION_STATES.FULFILLED]: (state, action) => {
            state.getDefaultSequencesAPIStatus = ACTION_STATES.FULFILLED;
            const { sequenceItems } = action.payload;
            const sequences = (sequenceItems || []).map(transformSequenceSlots);
            if (!state.sequenceBuilder.activeSequenceDetails.sequenceItems) {
                state.sequenceBuilder.activeSequenceDetails.sequenceItems = sequences;
            }
        },
        //
        [Actions.getAllSequences + ACTION_STATES.FULFILLED]: (state, action) => {
            state.getAllSequencesAPIStatus = ACTION_STATES.FULFILLED;
            state.allSequences = (action.payload || [])
                .sort((a, b) => b.isDefault - a.isDefault)
                .map(transformSequences);
        },
        //
        [Actions.saveSequence + ACTION_STATES.FULFILLED]: (state) => {
            state.saveSequenceAPIStatus = ACTION_STATES.FULFILLED;
            state.sequenceBuilder = initialState.sequenceBuilder;
        },
        //
        [Actions.createSequence + ACTION_STATES.FULFILLED]: (state) => {
            state.createSequenceAPIStatus = ACTION_STATES.FULFILLED;
            state.sequenceBuilder = initialState.sequenceBuilder;
        },
        //
        [Actions.getSequence + ACTION_STATES.FULFILLED]: (state, action) => {
            state.getSequenceAPIStatus = ACTION_STATES.FULFILLED;
            if (!action.payload.orientation || action.payload.orientation == null) {
                state.sequenceBuilder.activeSequenceDetails = transformSequence({
                    ...action.payload,
                    orientation: "LAN",
                });
            } else {
                state.sequenceBuilder.activeSequenceDetails = transformSequence(action.payload);
            }
        },
        //
        [Actions.deleteSequence + ACTION_STATES.REQUEST]: (state, action) => {
            state.allSequences = state.allSequences.filter((item) => item.id !== action.payload.id);
        },
        //
        [Actions.markAsDefault + ACTION_STATES.REQUEST]: (state, action) => {
            state.allSequences = state.allSequences.map((item) => {
                return { ...item, isDefault: item.id === action.payload.id };
            });
        },
        //
        [Actions.unAssignSequence + ACTION_STATES.REQUEST]: (state, action) => {
            const { id } = action.payload;
            let screenId;
            state.sequenceAvailability = state.sequenceAvailability.map((item) => {
                return {
                    ...item,
                    assignedSequences: item.assignedSequences.filter((item2) => {
                        if (item2.id === id) {
                            screenId = item.screenDetails.screenId;
                        }
                        return item2.id !== id;
                    }),
                };
            });
            state.sequenceScheduling.scheduleInfoDetails = null;
            state.sequenceScheduling.screensWithSchedule[screenId] = setDefaultSequences(state, {
                sequences: state.sequenceScheduling.screensWithSchedule[screenId].filter(
                    (item) => item.id !== id,
                ),
            });
        },
        //
        [Actions.getAvailability + ACTION_STATES.REQUEST]: (state, action) => {
            const { silentRefresh } = action.payload;
            if (!silentRefresh) {
                state.sequenceAvailability = [];
                state.getAvailabilityAPIStatus = ACTION_STATES.REQUEST;
            }
        },
        [Actions.getAvailability + ACTION_STATES.PENDING]: (state, action) => {
            const { silentRefresh } = action.payload;
            if (!silentRefresh) {
                state.getAvailabilityAPIStatus = ACTION_STATES.PENDING;
            }
        },
        [Actions.getAvailability + ACTION_STATES.FULFILLED]: (state, action) => {
            state.getAvailabilityAPIStatus = ACTION_STATES.FULFILLED;
            state.sequenceAvailability = action.payload.map((screen) => {
                return {
                    ...screen,
                    assignedSequences: screen.assignedSequences.map((item) => {
                        const matchedSequence =
                            state.allSequences.find(
                                (sequence) => sequence.id === item.sequenceId,
                            ) || {};
                        return {
                            ...item,
                            startDateTime: moment(item.startDateTime).startOf("day"),
                            endDateTime: moment(item.endDateTime).endOf("day"),
                            theme: matchedSequence.theme || {
                                background: "#dbdbdb",
                            },
                        };
                    }),
                };
            });
        },
        //
        [Actions.updateSchedule + ACTION_STATES.REQUEST]: (state, action) => {
            const { data, index, screenId } = action.payload;
            const newSequences = state.sequenceScheduling.screensWithSchedule[screenId];
            newSequences[index] = {
                ...newSequences[index],
                ...data,
            };
            state.sequenceScheduling.screensWithSchedule[screenId] = setDefaultSequences(state, {
                sequences: newSequences,
                assignedSequenceId: data.assignedSequenceId,
            });
        },
        //
        [Actions.loadPreviewContent + ACTION_STATES.FULFILLED]: (state, action) => {
            state.loadPreviewContentAPIStatus = ACTION_STATES.FULFILLED;
            const { response, type, zoneId } = action.payload || {};
            state.slotLayout.selectedLayout.zones.find(
                (item) => item.id == zoneId,
            ).assignedContent = {
                value: response.data || response,
                type: type,
            };
        },
        //
        [Actions.fetchLayouts + ACTION_STATES.FULFILLED]: (state, action) => {
            state.fetchLayoutsAPIStatus = ACTION_STATES.FULFILLED;
            const layouts = action.payload;
            const orientation = state.sequenceBuilder.activeSequenceDetails.orientation;
            state.slotLayout.availableLayouts = layouts.filter(
                (layout) => layout.orientation == orientation,
            );
        },
    },
});

export const {
    setTab,
    resetState,
    setScheduleInfoBox,
    setFormMode,
    setFormStep,
    setSlotSelector,
    setSlotValue,
    setFormData,
    setSlotType,
    resetFormMode,
    setScreenSequences,
    removeSlotValue,
    setTimelineDates,
    setCurrentStep,
    setSelectedLayout,
    setShowSelectContentModal,
    setAssignedContent,
    setSelectedZoneId,
    setZoneType,
    setIsContentAssigned,
    copyContentToAllSlots,
} = slice.actions;

export const getDefaultSequences = (boardId) => {
    return {
        type: Actions.getDefaultSequences + ACTION_STATES.REQUEST,
        payload: boardId,
    };
};
export const getAllSequences = (boardId) => {
    return {
        type: Actions.getAllSequences + ACTION_STATES.REQUEST,
        payload: boardId,
    };
};
export const getSequence = (payload) => {
    return {
        type: Actions.getSequence + ACTION_STATES.REQUEST,
        payload,
    };
};
export const createSequence = (payload) => {
    return {
        type: Actions.createSequence + ACTION_STATES.REQUEST,
        payload,
    };
};
export const deleteSequence = (payload) => {
    return {
        type: Actions.deleteSequence + ACTION_STATES.REQUEST,
        payload,
    };
};
export const saveSequence = (payload) => {
    return {
        type: Actions.saveSequence + ACTION_STATES.REQUEST,
        payload,
    };
};
export const assignSequence = (payload) => {
    return {
        type: Actions.assignSequence + ACTION_STATES.REQUEST,
        payload,
    };
};
export const updateSchedule = (payload) => {
    return {
        type: Actions.updateSchedule + ACTION_STATES.REQUEST,
        payload,
    };
};
export const unAssignSequence = (payload) => {
    return {
        type: Actions.unAssignSequence + ACTION_STATES.REQUEST,
        payload,
    };
};
export const getAvailability = (payload) => {
    return {
        type: Actions.getAvailability + ACTION_STATES.REQUEST,
        payload,
    };
};
export const markAsDefault = (payload) => {
    return {
        type: Actions.markAsDefault + ACTION_STATES.REQUEST,
        payload,
    };
};
export const loadPreviewContent = ({ id, type, zoneId, isSaved }) => {
    return {
        type: Actions.loadPreviewContent + ACTION_STATES.REQUEST,
        payload: { id, type, zoneId, isSaved },
    };
};
export const fetchLayouts = (payload) => {
    return {
        type: Actions.fetchLayouts + ACTION_STATES.REQUEST,
        payload,
    };
};

export default slice.reducer;
