/*
 * @Author: tnityanand@hootboard.com
 * @Date: 2020-05-07 20:22:42
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2021-01-07 14:57:49
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTags } from "../../api/boards.service";
import { createHoot, updateHoot } from "../../api/hoots.service.alt";
import { refreshData } from "../Calendar/calendar.slice";
import { getRandomHootColor } from "./utils/hootBgColor.util";
import mapHootDataForEdit from "./utils/mapHootDataForEdit.util";
import { mapPostParams } from "./utils/postHootParams.util";

export const fetchBoardCollections = createAsyncThunk(
	"/postHoot/fetchBoardCollections",
	async ({ boardId }) => {
		let response = await getTags(boardId);
		return {
			response: response,
		};
	}
);

export const postHootRequest = createAsyncThunk(
	"/postHoot/postHootRequest",
	async ({ boardId, postHootParams }, thunkApi) => {
		let response = await createHoot(boardId, mapPostParams(postHootParams));
		thunkApi.dispatch(refreshData());
		return {
			response: response,
		};
	}
);

export const updateHootRequest = createAsyncThunk(
	"/postHoot/postHootRequest",
	async ({ boardId, hootId, postHootParams }, thunkApi) => {
		let response = await updateHoot(
			boardId,
			hootId,
			mapPostParams(postHootParams)
		);
		thunkApi.dispatch(refreshData());
		return {
			response: response,
		};
	}
);

const defaultState = {
	hootId: 0,
	hootTitleError: true,
	hootDescError: true,
	boardSelectionError: true,
	eventError: false,
	showErrors: false,
	selectedHootTypeIdx: 0,
	postHootStage: 0,
	postingCategory: "ANNT",
	showHootCoverImageControl: false,
	showHootEventControl: false,
	showHootLocationControl: false,
	showActionButtonControl: false,
	showHootExpiryControl: false,
	showUploadFileControl: false,
	showAddFileButton: true,
	selectedHootBgColor: getRandomHootColor(),
	customHootThemeColors: null,
	hootTitle: "",
	hootDesc: "",
	acquiredLinkData: null, //Made NULL by default so that its not included in the postHootParams
	linkPreviewLoading: false,
	embedLinkHoot: false,
	hootCoverImages: [],
	removedHootCoverImageIds: [],
	hootLocation: {},
	actionButtonLabel: "",
	actionButtonLink: "",
	uploadedFileData: null,
	fileHoot: false,
	fileHootShowTitle: true,
	hootExpiryDate: "",
	eventDetails: null, //Made NULL by default so that its not included in the postHootParams
	eventStartDateAddedCount: 0, //Hack for setting the durations for the recurrence dates
	recurrenceRule: null, //Made NULL by default so that its not included in the postHootParams

	selectedBoards: [],
	selectedBoardId: 0,
	selectedBoardName: "",
	selectedBoardPostApproval: true,
	isBoardSelected: false,
	selectedCollections: [],
	scheduledHootDate: "",
	hootVisibility: "pri",
	privateHoot: true,
	boardCollections: [],
	postHootParams: {},
	isEditHoot: false,
	hootCreationStatus: "",
	createdHootUrl: "",
	isRichTextUsed: false,
	createdHootUrlWithBoard: "",
	isPublishListClicked: false,
	publishListId: null,
};

const postHootSlice = createSlice({
	name: "postHoot",
	initialState: defaultState,
	reducers: {
		clearPostHootData: () => {
			return { ...defaultState };
		},
		setRichTextUsed: (state) => {
			state.isRichTextUsed = true;
		},
		hootTypeSelected: (state, action) => {
			let selectedHootTypeIdx = action.payload;
			return {
				...state,
				selectedHootTypeIdx: selectedHootTypeIdx,
				postHootStage: 1,
			};
		},
		postHootStageSelected: (state, action) => {
			const postHootStage = action.payload;

			if (postHootStage === 2) {
				// handle links in html
				// there are some issues with the link wrapper solution for slate.js
				// here we are simply extracting links and wrapping around an <a> tag.

				let hootDesc = state.hootDesc || "";

				const expression =
					/(https?:\/\/(?:www\.|(?!www))[^\s.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
				const linksFound = hootDesc.match(expression);

				if (linksFound && linksFound.length > 0) {
					for (const item of linksFound) {
						// at some point some special chars are also getting detected as part of link
						// like </p> is mistaken as the / part of the link. we are removing it.
						const indexOfSomeTag = item.indexOf("<");
						const link = item.slice(
							0,
							indexOfSomeTag === -1 ? undefined : indexOfSomeTag
						);
						let properLink = link.trim();

						if (!properLink.includes("://")) {
							properLink = `http://${properLink}`;
						}

						hootDesc = hootDesc.replace(
							link,
							`<a href="${properLink}" target="_blank">${link}</a>`
						);
					}

					state.hootDesc = hootDesc;
				}
			}

			state.postHootStage = postHootStage;
		},
		postingCategorySelected: (state, action) => {
			let postingCategory = action.payload;
			return {
				...state,
				postingCategory: postingCategory,
			};
		},
		hootCoverImageControlToggled: (state, action) => {
			let showHootCoverImageControl = action.payload;
			return {
				...state,
				showHootCoverImageControl: showHootCoverImageControl,
			};
		},
		hootEventControlToggled: (state, action) => {
			const showHootEventControl = action.payload;
			state.showHootEventControl = showHootEventControl;
			if (!showHootEventControl) {
				state.eventDetails = null;
			}
		},
		hootLocationControlToggled: (state, action) => {
			let showHootLocationControl = action.payload;
			let returnData = {
				...state,
				showHootLocationControl: showHootLocationControl,
			};
			if (!showHootLocationControl) {
				returnData = {
					...returnData,
					hootLocation: {},
				};
			}
			return returnData;
		},
		actionButtonControlToggled: (state, action) => {
			let showActionButtonControl = action.payload;
			let returnData = {
				...state,
				showActionButtonControl: showActionButtonControl,
			};
			if (!showActionButtonControl) {
				returnData = {
					...returnData,
					actionButtonLabel: "",
					actionButtonLink: "",
				};
			}
			return returnData;
		},
		hootExpiryControlToggled: (state, action) => {
			let showHootExpiryControl = action.payload;
			let returnData = {
				...state,
				showHootExpiryControl: showHootExpiryControl,
			};
			if (!showHootExpiryControl) {
				returnData = {
					...returnData,
					hootExpiryDate: "",
				};
			}
			return returnData;
		},
		uploadFileControlToggled: (state, action) => {
			let showUploadFileControl = action.payload;
			let returnData = {
				...state,
				showUploadFileControl: showUploadFileControl,
			};
			if (!showUploadFileControl) {
				returnData = {
					...returnData,
					uploadedFileData: null,
					fileHoot: false,
				};
			}
			return returnData;
		},
		uploadFileButtonToggled: (state, action) => {
			let showAddFileButton = action.payload;
			return {
				...state,
				showAddFileButton: showAddFileButton,
			};
		},
		hootBgColorSelected: (state, action) => {
			const color = action.payload;

			let selectedHootBgColor = null;
			let customHootThemeColors = null;

			// if selected color is a custom color set
			if (typeof color === "object") {
				// set this value as white for backward compatibility.
				// our mobile don't have the option to render custom colors.
				selectedHootBgColor = "hoot-bgcolor-white";
				customHootThemeColors = { ...color };
			} else {
				selectedHootBgColor = color;
			}

			return {
				...state,
				selectedHootBgColor,
				customHootThemeColors,
			};
		},
		toggleErrorsDisplay: (state, action) => {
			return {
				...state,
				showErrors: action.payload,
			};
		},
		hootTitleEntered: (state, action) => {
			let hootTitle = action.payload;
			return {
				...state,
				hootTitle: hootTitle,
				hootTitleError: hootTitle ? false : true,
			};
		},
		hootDescEntered: (state, action) => {
			const hootDesc = action.payload;

			const hootDescTrimmed = hootDesc
				.replace(/(<([^>]+)>)/gi, "") // Stripping html tags from the description
				.trim(); // Trimming all white spaces.

			// Only if the description is non-empty, proceed.
			if (hootDescTrimmed) {
				state.hootDesc = hootDesc;
				state.hootDescError =
					state.acquiredLinkData || hootDesc ? false : true;
			} else {
				// Or set the description error to if not a link hoot.
				state.hootDescError = !state.embedLinkHoot;
			}
		},
		linkDataAcquired: (state, action) => {
			const acquiredLinkData = action.payload;

			state.acquiredLinkData = acquiredLinkData;
			state.hootTitleError = acquiredLinkData ? false : true;
			state.hootDescError = acquiredLinkData ? false : true;
			state.embedLinkHoot = true;

			if (acquiredLinkData.location) {
				const {
					googlePlaceId,
					formattedAddress,
					latAndLong: { lat, lon },
				} = acquiredLinkData.location;
				state.showHootLocationControl = true;
				state.hootLocation.latitude = lat;
				state.hootLocation.longitude = lon;
				state.hootLocation.googlePlaceId = googlePlaceId;
				state.hootLocation.formattedAddress = formattedAddress;
			}

			if (acquiredLinkData.timings) {
				const { ends, starts } = acquiredLinkData.timings;
				state.showHootEventControl = true;
				state.eventDetails = {
					startDate: starts,
					endDate: ends,
					startTime: starts,
					endTime: ends,
				};
			} else {
				state.showHootEventControl = !!state.eventDetails;
			}
		},
		linkPreviewLoaded: (state, action) => {
			let linkPreviewLoading = action.payload;
			return {
				...state,
				linkPreviewLoading: linkPreviewLoading,
			};
		},
		hootCoverImagesAdded: (state, action) => {
			let hootCoverImages = action.payload;
			return {
				...state,
				hootCoverImages: [...state.hootCoverImages, ...hootCoverImages],
			};
		},
		hootCoverImagesRemoved: (state, action) => {
			const targetImage = state.hootCoverImages[action.payload];
			let removedIds = [...state.removedHootCoverImageIds];
			if (targetImage.id > 0) {
				removedIds.push(targetImage.id);
			}
			return {
				...state,
				hootCoverImages: state.hootCoverImages.filter(
					(item) => item !== targetImage
				),
				removedHootCoverImageIds: removedIds,
			};
		},
		hootCoverImagesMoved: (state, action) => {
			const targetImage = state.hootCoverImages[action.payload];
			const otherCoverImages = state.hootCoverImages.filter(
				(item) => item !== targetImage
			);
			otherCoverImages.unshift(targetImage);
			return {
				...state,
				hootCoverImages: otherCoverImages,
			};
		},
		hootLocationEntered: (state, action) => {
			let hootLocation = action.payload;
			return {
				...state,
				hootLocation: hootLocation,
			};
		},
		actionButtonLabelEntered: (state, action) => {
			let actionButtonLabel = action.payload;
			return {
				...state,
				actionButtonLabel: actionButtonLabel,
			};
		},
		actionButtonLinkEntered: (state, action) => {
			let actionButtonLink = action.payload;
			return {
				...state,
				actionButtonLink: actionButtonLink,
			};
		},
		fileUploaded: (state, action) => {
			let uploadedFileData = action.payload;
			return {
				...state,
				uploadedFileData: uploadedFileData,
				fileHoot: true,
			};
		},
		fileHootShowTitleToggled: (state, action) => {
			return {
				...state,
				fileHootShowTitle: action.payload,
			};
		},
		hootExpiryDateAdded: (state, action) => {
			let hootExpiryDate = action.payload;
			return {
				...state,
				hootExpiryDate: hootExpiryDate,
			};
		},
		eventDetailsAdded: (state, action) => {
			let eventDetails = action.payload;
			return {
				...state,
				eventDetails: eventDetails,
			};
		},
		eventStartDateCountIncremented: (state, action) => {
			let eventStartDateAddedCount = action.payload;
			return {
				...state,
				eventStartDateAddedCount: eventStartDateAddedCount,
			};
		},
		recurrenceRuleAdded: (state, action) => {
			state.recurrenceRule = action.payload;
		},

		boardsToPostAdded: (state, action) => {
			let selectedBoards = action.payload;
			return {
				...state,
				selectedBoards: selectedBoards,
				isBoardSelected: true,
				hootVisibility: "pri", //setting hoot visibility to private by default when new board is selected
			};
		},
		singleBoardToPostAdded: (state, action) => {
			let selectedBoard = action.payload;
			return {
				...state,
				selectedBoardId: selectedBoard.boardId,
				selectedBoardName: selectedBoard.boardName,
				selectedBoardPostApproval:
					selectedBoard.membershipType === "AA" ||
					selectedBoard.membershipType === "VA" ||
					selectedBoard.membershipType === "SA"
						? true
						: !selectedBoard.postApprovalNeeded, //setting the postApproved to true is the postApprovalNeeded is false
				hootVisibility: "pri", //setting hoot visibility to private by default when new board is selected
				boardSelectionError: selectedBoard.boardId > 0 ? false : true,
			};
		},
		collectionToPostAdded: (state, action) => {
			let selectedCollections = action.payload;
			return {
				...state,
				selectedCollections: selectedCollections,
			};
		},
		collectionToPostRemoved: (state, action) => {
			return {
				...state,
				selectedCollections: state.selectedCollections.filter(
					(item) => {
						if (item.filterId) {
							return item.filterId !== action.payload.filterId;
						} else {
							return (
								item.filterText !== action.payload.filterText
							);
						}
					}
				),
			};
		},
		scheduledHootDateAdded: (state, action) => {
			let scheduledHootDate = action.payload;
			return {
				...state,
				scheduledHootDate: scheduledHootDate,
			};
		},
		scheduledHootDateRemoved: (state) => {
			return {
				...state,
				scheduledHootDate: "",
			};
		},
		hootVisibilityChanged: (state, action) => {
			const hootVisibility = action.payload;
			const privateHoot = hootVisibility === "pri";
			return {
				...state,
				privateHoot,
				hootVisibility: hootVisibility,
			};
		},
		postHootParamObjectCreated: (state, action) => {
			let postHootParams = action.payload;
			return {
				...state,
				postHootParams: postHootParams,
			};
		},
		setInitialState: (_state, action) => {
			let hootData = action.payload;
			return mapHootDataForEdit(defaultState, hootData);
		},
		updatePublishList: (state, action) => {
			state.isPublishListClicked = action.payload ? true : false;
			state.publishListId = action.payload;
		},
	},
	extraReducers: {
		[fetchBoardCollections.fulfilled]: (state, action) => {
			if (action.payload.response) {
				if (action.payload.response.filters) {
					return {
						...state,
						showSpinner: false,
						boardCollections: action.payload.response.filters,
						boardCollectionsFetchedStatus: "success",
					};
				} else {
					return {
						...state,
						showSpinner: false,
						boardCollections: [],
						boardCollectionsFetchedStatus: "rejected",
					};
				}
			} else {
				return {
					...state,
					showSpinner: false,
					boardCollections: [],
					boardCollectionsFetchedStatus: "rejected",
				};
			}
		},
		[fetchBoardCollections.pending]: (state) => {
			return {
				...state,
				showSpinner: true,
				boardCollectionsFetchedStatus: "pending",
			};
		},
		[fetchBoardCollections.rejected]: (state) => {
			return {
				...state,
				showSpinner: false,
				boardCollections: null,
				boardCollectionsFetchedStatus: "rejected",
			};
		},
		[postHootRequest.fulfilled]: (state, action) => {
			if (action.payload.response) {
				let data = action.payload.response.data;
				if (data) {
					return {
						...state,
						createdHootUrl: data.hootUrlWithBoard,
						showSpinner: false,
						hootCreationStatus: "success",
					};
				} else {
					return {
						...state,
						showSpinner: false,
						hootCreationStatus: "failed",
					};
				}
			} else {
				return {
					...state,
					showSpinner: false,
					hootCreationStatus: "failed",
				};
			}
		},
		[postHootRequest.rejected]: (state) => {
			return {
				...state,
				showSpinner: false,
				hootCreationStatus: "failed",
			};
		},
		[postHootRequest.pending]: (state) => {
			return {
				...state,
				showSpinner: true,
				hootCreationStatus: "pending",
			};
		},
		[updateHootRequest.fulfilled]: (state, action) => {
			if (action.payload.response) {
				const data = action.payload.response.data;
				if (data) {
					return {
						...state,
						createdHootUrlWithBoard: data.hootUrlWithBoard,
						createdHootUrl: data.hootUrl,
						showSpinner: false,
						hootCreationStatus: "success",
					};
				} else {
					return {
						...state,
						showSpinner: false,
						hootCreationStatus: "failed",
					};
				}
			} else {
				return {
					...state,
					showSpinner: false,
					hootCreationStatus: "failed",
				};
			}
		},
		[updateHootRequest.rejected]: (state) => {
			return {
				...state,
				showSpinner: false,
				hootCreationStatus: "failed",
			};
		},
		[updateHootRequest.pending]: (state) => {
			return {
				...state,
				showSpinner: true,
				hootCreationStatus: "pending",
			};
		},
	},
});

export const {
	setRichTextUsed,
	clearPostHootData,
	hootTypeSelected,
	postHootStageSelected,
	postingCategorySelected,
	hootCoverImageControlToggled,
	hootEventControlToggled,
	hootLocationControlToggled,
	actionButtonControlToggled,
	hootExpiryControlToggled,
	uploadFileControlToggled,
	uploadFileButtonToggled,
	hootBgColorSelected,
	toggleErrorsDisplay,
	hootTitleEntered,
	hootDescEntered,
	linkDataAcquired,
	linkPreviewLoaded,
	hootCoverImagesAdded,
	hootCoverImagesRemoved,
	hootCoverImagesMoved,
	hootLocationEntered,
	actionButtonLabelEntered,
	actionButtonLinkEntered,
	fileUploaded,
	fileHootShowTitleToggled,
	hootExpiryDateAdded,
	eventDetailsAdded,
	eventStartDateCountIncremented,
	recurrenceRuleAdded,
	boardsToPostAdded,
	singleBoardToPostAdded,
	collectionToPostAdded,
	collectionToPostRemoved,
	scheduledHootDateAdded,
	scheduledHootDateRemoved,
	hootVisibilityChanged,
	postHootParamObjectCreated,
	setInitialState,
	updatePublishList
} = postHootSlice.actions;

export default postHootSlice.reducer;
