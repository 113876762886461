/*
 * @Author: snair@hootboard.com
 * @Date: 2020-06-22 19:59:45
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2021-03-19 08:47:25
 */

import { createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";

const initialState = {
	events: [],
	refreshId: 0,
	eventsStore: [],
	yearlyEvents: [],
	datesWithEvents: [],
	eventsFetchStatus: "pending",
	viewType: "week",
	selectedDate: moment().startOf("day").toDate(),
};

const calendarSlice = createSlice({
	name: "calendar",
	initialState: initialState,
	reducers: {
		refreshData: (state) => {
			state.refreshId = state.refreshId + 1;
		},
		resetCalendar: (state) => {
			state.viewType = initialState.viewType;
			state.selectedDate = initialState.selectedDate;
		},
		filterEventsByDate: (state) => {
			const selectedDate = state.selectedDate;
			const events = state.eventsStore.filter((event) => {
				const checkDate = moment(selectedDate).add(1, "second");
				const eventStart = event.eventDetails.eventStartDateTime;
				let eventEnd =
					event.eventDetails[
						event.recurringEvent
							? "eventStartDateTime"
							: "eventEndDateTime"
					] || eventStart;

				const startDateTimePart = eventStart.split("T")[1];
				const endDateTimePart = eventEnd.split("T")[1];

				/**
				 * Checking if time of start and end is the same.
				 * If yes, we need to subtract 1 second from end date.
				 * */
				if (startDateTimePart === endDateTimePart) {
					eventEnd = moment(eventEnd).subtract(1, "second").toDate();
				}

				return checkDate.isBetween(
					moment(eventStart).startOf("day"),
					moment(eventEnd).endOf("day")
				);
			});

			state.events = events;
		},
		switchViewType: (state, action) => {
			state.viewType = action.payload;
		},
		selectYear: (state, action) => {
			const year = action.payload;
			const newDate = new Date(state.selectedDate);
			newDate.setFullYear(year);

			state.selectedDate = newDate;
			state.viewType = "calendar";
		},
		selectDay: (state, action) => {
			if (state.viewType !== "week") {
				state.viewType = "week";
			}
			state.selectedDate = moment(action.payload).startOf("day").toDate();
		},
		navigateDate: (state, action) => {
			const direction = action.payload;
			const isBack = direction === "back";

			const newDate = new Date(state.selectedDate);

			if (state.viewType === "week") {
				newDate.setDate(newDate.getDate() + (isBack ? -7 : 7));
			}

			if (state.viewType === "calendar") {
				newDate.setMonth(newDate.getMonth() + (isBack ? -1 : 1));
			}

			state.selectedDate = newDate;
		},
	},
	extraReducers: {
		"calendar/events/get/request": (state) => {
			state.eventsFetchStatus = "pending";
			state.datesWithEvents = [];
		},
		"calendar/events/get/fulfilled": (state, action) => {
			const events = action.payload.sort((a, b) => {
				return (
					moment(a.eventDetails.eventStartDateTime) -
					moment(b.eventDetails.eventStartDateTime)
				);
			});
			state.eventsFetchStatus = "fulfilled";
			state.eventsStore = events;
			const datesWithEvents = [];

			const monthStart = moment(state.selectedDate).startOf("month");
			const monthEnd = moment(state.selectedDate).endOf("month");

			for (const event of events) {
				const eventStart = event.eventDetails.eventStartDateTime;
				let eventEnd =
					event.eventDetails[
						event.recurringEvent
							? "eventStartDateTime"
							: "eventEndDateTime"
					] || eventStart;

				const startDateTimePart = eventStart.split("T")[1];
				const endDateTimePart = eventEnd.split("T")[1];

				/**
				 * Checking if time of start and end is the same.
				 * If yes, we need to subtract 1 second from end date.
				 * */
				if (startDateTimePart === endDateTimePart) {
					eventEnd = moment(eventEnd).subtract(1, "second").toDate();
				}

				for (
					const day = moment(monthStart);
					day.isBefore(monthEnd);
					day.add(1, "days")
				) {
					const checkDate = moment(day).add(1, "second");

					const isOk = checkDate.isBetween(
						moment(eventStart).startOf("day"),
						moment(eventEnd).endOf("day")
					);

					if (isOk) {
						const dayDate = day.toDate().getDate();
						if (!datesWithEvents.includes(dayDate)) {
							datesWithEvents.push(dayDate);
						}
					}
				}
			}
			state.datesWithEvents = datesWithEvents;
		},
		"calendar/events/get/rejected": (state) => {
			state.eventsFetchStatus = "rejected";
		},
		"calendar/events/duration/get/request": (state) => {
			state.eventsFetchStatus = "pending";
		},
		"calendar/events/duration/get/fulfilled": (state, action) => {
			const events = action.payload.sort((a, b) => {
				return (
					moment(a.eventDetails.eventStartDateTime) -
					moment(b.eventDetails.eventStartDateTime)
				);
			});
			state.eventsFetchStatus = "fulfilled";
			state.yearlyEvents = events.sort((first, second) => {
				const startDateFirst = new Date(
					first.eventDetails.eventStartDateTime
				);
				const startDateSecond = new Date(
					second.eventDetails.eventStartDateTime
				);
				return startDateFirst - startDateSecond;
			});
		},
		"calendar/events/duration/get/rejected": (state) => {
			state.eventsFetchStatus = "rejected";
		},
	},
});

export const getMonthlyEvents = (payload) => {
	return {
		type: "calendar/events/get/request",
		payload,
	};
};

export const getYearlyEvents = (payload) => {
	return {
		type: "calendar/events/duration/get/request",
		payload,
	};
};

export const {
	resetCalendar,
	switchViewType,
	filterEventsByDate,
	selectDay,
	selectYear,
	navigateDate,
	refreshData,
} = calendarSlice.actions;

export default calendarSlice.reducer;
