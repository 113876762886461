import { httpGetV2, httpPutV2, httpPostV2, httpDeleteV2 } from "./http-wrapper";

/**
 * API to get hoots for selected collections
 * @param {number} boardId
 * @param {Object} params
 */
const getHootsForCollections = async (boardId, params) => {
	const apiConfig = { "x-board-id": boardId };
	let apiEndpoint = "public/hoots/collection";
	if (localStorage.getItem("authToken")) {
		apiEndpoint = "private/hoots/collection";
	}
	const response = await httpGetV2(apiEndpoint, params, apiConfig);
	return response;
};

/**
 * API to get related tags for selected collections
 * @param {number} boardId
 * @param {Object} params
 */
const getRelatedTags = async ({ boardId, params }) => {
	const apiConfig = { "x-board-id": boardId };
	let apiEndpoint = "public/hoots/tags";
	if (localStorage.getItem("authToken")) {
		apiEndpoint = "private/hoots/tags";
	}
	const response = await httpGetV2(apiEndpoint, params, apiConfig);
	return response;
};

/**
 * API to get hoots for selected collections
 * @param {number} postId
 */
const getComments = async (postId) => {
	const params = { postId };
	let apiEndpoint = "public/hoot/comment";
	if (localStorage.getItem("authToken")) {
		apiEndpoint = "private/hoot/comment";
	}
	const response = await httpGetV2(apiEndpoint, params, null);
	return response;
};

/**
 * API to favorite a hoot
 * @param {number} boardId
 * @param {number} hootId
 */
const favoriteHoot = async (boardId, hootId) => {
	const apiConfig = { "x-board-id": boardId };
	const params = { postId: hootId };

	const response = await httpPostV2(
		"private/hoot/favorite",
		params,
		apiConfig
	);
	return response;
};

/**
 * API to unfavorite a hoot
 * @param {number} boardId
 * @param {number} hootId
 */
const unfavoriteHoot = async (boardId, hootId) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpPutV2(
		`private/hoot/favorite/${hootId}`,
		null,
		apiConfig
	);
	return response;
};

/**
 * API to rehoot a hoot
 * @param {number} hootId
 * @param {string} rehootBoards
 * @param {string} tagsText
 */
const rehoot = async (hootId, rehootBoards, tagsText) => {
	const params = {
		hootId,
		rehootBoards,
		tagsText,
	};

	const response = await httpPostV2("private/hoot/rehoot", params, null);
	return response;
};

/**
 * API to get mentions for hoot
 * @param {number} boardId
 * @param {number} hootId
 */
const getMentions = async (boardId, hootId) => {
	const apiConfig = { "x-board-id": boardId };
	const params = { hootId };

	const response = await httpGetV2("private/hoot/mention", params, apiConfig);
	return response;
};

/**
 * API to pin/unpin a hoot for a board
 * @param {number} boardId
 * @param {number} hootId
 * @param {boolean} pin
 */
const pinHoot = async (boardId, hootId, pin) => {
	const apiConfig = { "x-board-id": boardId };
	const response = await httpPutV2(
		`private/boards/hoot/${pin ? "pin" : "unpin"}/${hootId}`,
		null,
		apiConfig
	);
	return response;
};

/**
 * API to update hoot visibility
 * @param {number} boardId
 * @param {number} hootId
 * @param {string} visibility
 */
const updateVisibility = async (boardId, hootId, visibility) => {
	const apiConfig = { "x-board-id": boardId };
	const params = {
		approvalReqFrom: "permission",
		postApproval: visibility,
		removeMsg: "",
	};

	const response = await httpPutV2(
		`private/boards/hootApproval/${hootId}`,
		params,
		apiConfig
	);
	return response;
};

/**
 * API to update expiry date
 * @param {number} boardId
 * @param {number} hootId
 * @param {string} expiryDate
 */
const putExpiryDate = async (boardId, hootId, expiryDate = "") => {
	const apiConfig = { "x-board-id": boardId };
	const params = { expiryDate };

	const response = await httpPutV2(
		`private/hoot/expiry/${hootId}`,
		params,
		apiConfig
	);
	return response;
};

/**
 * API to update scheduled hoot date.
 * @param {number} boardId
 * @param {number} hootId
 * @param {string} hootScheduledDate
 */
export const reScheduleHoot = async (
	boardId,
	hootId,
	hootScheduledDate = "",
	hootTimezone = ""
) => {
	const apiConfig = { "x-board-id": boardId };
	const params = { hootScheduledDate, hootTimezone };

	const response = await httpPutV2(
		`private/hoot/schedule/${hootId}`,
		params,
		apiConfig
	);
	return response;
};

/**
 * API to update hoot settings
 * @param {number} boardId
 * @param {Object} params
 */
const updateHootSettings = async (boardId, params = {}) => {
	const apiConfig = { "x-board-id": boardId };
	const response = await httpPostV2(
		"private/hoot/settings",
		params,
		apiConfig
	);
	return response;
};

/**
 * API to add collection to hoot
 * @param {number} boardId
 * @param {number} hootId
 * @param {string} tagText
 */
const addCollectionToHoot = async (boardId, hootId, tagText) => {
	const apiConfig = { "x-board-id": boardId };
	const params = { postId: hootId, tagText };

	const response = await httpPostV2("private/tags/post", params, apiConfig);
	return response;
};

/**
 * API to remove collection from hoot
 * @param {number} boardId
 * @param {number} hootId
 * @param {string} tagText
 */
const removeCollectionFromHoot = async (boardId, hootId, tagText) => {
	const apiConfig = { "x-board-id": boardId };
	const params = { tagText };

	const response = await httpPutV2(
		`private/tags/post/${hootId}`,
		params,
		apiConfig
	);
	return response;
};

/**
 * API to post a comment to a hoot
 * @param {number} boardId
 * @param {number} hootId
 * @param {Array} mentionIds
 * @param {string} commentText
 */
const postComment = async (boardId, hootId, mentionIds, commentText) => {
	const apiConfig = { "x-board-id": boardId };
	const params = { postId: hootId, mentionIds, commentText };

	const response = await httpPostV2(
		"private/hoot/comment",
		params,
		apiConfig
	);
	return response;
};

/**
 * API to approve comment
 * @param {number} boardId
 * @param {number} commentId
 * @param {Object} params
 * @param {string} params.commentApproval
 * @param {string} params.commentRejectMsg
 */
const approveComment = async (boardId, commentId, params) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpPutV2(
		`private/hoot/comment/approve/${commentId}`,
		params,
		apiConfig
	);

	return response;
};

/**
 * API to delete hoot
 * @param {number} postId
 */
const deleteHoot = async (postId) => {
	const response = await httpDeleteV2(`private/hoot/${postId}`, null, null);

	return response;
};

/**
 * API to delete comment from hoot
 * @param {number} commentId
 * @param {number} hootId
 */
const deleteComment = async (commentId, hootId) => {
	const response = await httpDeleteV2(
		`private/hoot/comment/${hootId}/${commentId}`,
		null,
		null
	);
	return response;
};

/** API to remove rehooted hoot from board
 * @param {number} boardId
 * @param {number} postId
 */
const removeRehootedHoot = async (boardId, postId) => {
	const apiConfig = { "x-board-id": boardId };

	const response = httpPutV2(
		`private/hoot/remove/${postId}`,
		{ adminNote: "" },
		apiConfig
	);

	return response;
};

export {
	removeRehootedHoot,
	deleteComment,
	deleteHoot,
	approveComment,
	getHootsForCollections,
	getRelatedTags,
	getComments,
	favoriteHoot,
	unfavoriteHoot,
	rehoot,
	getMentions,
	pinHoot,
	updateVisibility,
	putExpiryDate,
	updateHootSettings,
	addCollectionToHoot,
	removeCollectionFromHoot,
	postComment,
};
