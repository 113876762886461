import { createSlice } from "@reduxjs/toolkit";
import { emojiFilter } from "../../../../../helper/filters.helper";
import * as CollectionSelectorRedux from "../../CollectionSelector/redux";

const addAppSlice = createSlice({
	name: "addApp",
	initialState: {
		isAddingApp: false,
		step: 0,

		appProps: {
			type: null,
			name: "",
			iconUrl: "",
			showOnAllScreens: true,
			selectedScreens: [],

			private: false,
			showOnWeb: true,
			showOnMobile: true,

			// TODO: Remove this. Not used anywhere
			showTypeErrorRequired: false,
			showNameErrorRequired: false,
			showIconUrlErrorRequired: false,
			showOnSelectedScreenErrorRequired: false,
		},
		webAppProps: {
			url: "",
			mobileFriendlyUrl: "",
			allowedDomains: "",

			showUrlErrorRequired: false,
		},
		rssFeedReaderAppProps: {
			url: "",
			showUrlErrorRequired: false,
		},
		fileAppProps: {
			url: "",
			showUrlErrorRequired: false,
		},
		collectionAppProps: {
			selectedCollections: [],
			collectionBehavior: "U",
			// Redux related codes for collectionSelector is moved into a separate file to avoid duplication
			collectionSelector: CollectionSelectorRedux.initialState,
		},
	},
	reducers: {
		// Redux related codes for collectionSelector is moved into a separate file to avoid duplication
		...CollectionSelectorRedux.reducers,
		setCollectionBehavior: (state, action) => {
			state.collectionAppProps.collectionBehavior = action.payload;
		},
		addAppPressed: (state, action) => {
			state.isAddingApp = true;
			state.appProps.type = action.payload;
		},
		close: (state) => {
			state.step = 0;
			state.isAddingApp = false;

			state.appProps.type = null;
			state.appProps.name = "";
			state.appProps.iconUrl = "";
			state.appProps.showOnAllScreens = true;
			state.appProps.selectedScreens = [];
			state.showTypeErrorRequired = false;
			state.showNameErrorRequired = false;
			state.showIconUrlErrorRequired = false;
			state.showOnSelectedScreenErrorRequired = false;

			state.webAppProps.url = "";
			state.webAppProps.mobileFriendlyUrl = "";
			state.webAppProps.allowedDomains = "";
			state.webAppProps.showUrlErrorRequired = false;

			state.rssFeedReaderAppProps.url = "";
			state.rssFeedReaderAppProps.showUrlErrorRequired = false;

			state.fileAppProps.url = "";
			state.fileAppProps.showUrlErrorRequired = false;

			state.collectionAppProps.selectedCollections = [];
			state.collectionAppProps.collectionBehavior = "U";
		},
		nextStep: (state) => {
			const typeIsEmpty =
				state.appProps.type === null ||
				state.appProps.type === undefined;
			const nameIsEmpty = state.appProps.name.trim() === "";
			const iconUrlIsEmpty = state.appProps.iconUrl.trim() === "";
			const showOnSpecificScreensAndEmpty =
				!state.appProps.showOnAllScreens &&
				state.appProps.selectedScreens.length === 0;

			const canGoToNextStep = [
				typeIsEmpty,
				nameIsEmpty,
				iconUrlIsEmpty,
				showOnSpecificScreensAndEmpty,
			].every((error) => error === false);

			if (!canGoToNextStep) {
				if (typeIsEmpty) state.appProps.showTypeErrorRequired = true;
				else state.appProps.showTypeErrorRequired = false;

				if (nameIsEmpty) state.appProps.showNameErrorRequired = true;
				else state.appProps.showNameErrorRequired = false;

				if (iconUrlIsEmpty)
					state.appProps.showIconUrlErrorRequired = true;
				else state.appProps.showIconUrlErrorRequired = false;

				if (showOnSpecificScreensAndEmpty)
					state.appProps.showOnSelectedScreenErrorRequired = true;
				else state.appProps.showOnSelectedScreenErrorRequired = false;

				return;
			}

			state.step += 1;
		},
		jumpToStep: (state, action) => {
			state.step = action.payload;
		},
		appShowOnWebChanged: (state, action) => {
			state.appProps.showOnWeb = action.payload;
		},
		appShowOnMobileChanged: (state, action) => {
			state.appProps.showOnMobile = action.payload;
		},
		appPrivateChanged: (state, action) => {
			state.appProps.private = action.payload;
		},
		appTypeSelected: (state, action) => {
			state.appProps.type = action.payload;
			state.appProps.showTypeErrorRequired = action.payload === null;
		},
		appNameEntered: (state, action) => {
			state.appProps.name = emojiFilter(action.payload);
			state.appProps.showNameErrorRequired = action.payload === "";
		},
		appIconUploaded: (state, action) => {
			state.appProps.iconUrl = action.payload;
			state.appProps.showIconUrlErrorRequired = action.payload === "";
		},
		appShownOnAllScreensChanged: (state, action) => {
			state.appProps.showOnAllScreens = action.payload;
			state.appProps.showOnSelectedScreenErrorRequired = false;
		},
		appScreenToShowOnAdded: (state, action) => {
			state.appProps.selectedScreens.push(action.payload);
			state.appProps.showOnSelectedScreenErrorRequired = false;
		},
		appScreenToShowOnRemoved: (state, action) => {
			state.appProps.selectedScreens.splice(action.payload, 1);
			state.appProps.showOnSelectedScreenErrorRequired =
				state.appProps.selectedScreens.length === 0;
		},
		webAppUrlEntered: (state, action) => {
			state.webAppProps.url = action.payload;
			state.webAppProps.showUrlErrorRequired = action.payload === "";
		},
		webAppMobileFriendlyUrlEntered: (state, action) => {
			state.webAppProps.mobileFriendlyUrl = action.payload;
		},
		webAppAllowedDomainAdded: (state, action) => {
			state.webAppProps.allowedDomains = action.payload.trim();
		},
		rssFeedReaderAppUrlEntered: (state, action) => {
			state.rssFeedReaderAppProps.url = action.payload;
			state.rssFeedReaderAppProps.showUrlErrorRequired =
				action.payload === "";
		},
		fileAppUrlEntered: (state, action) => {
			state.fileAppProps.url = action.payload;
			state.fileAppProps.showUrlErrorRequired = action.payload === "";
		},
		setCollectionAppCollections: (state, action) => {
			state.collectionAppProps.selectedCollections = action.payload.map(
				(filterText) => ({
					filterText,
				})
			);
		},
		showErrors: (state, action) => {
			switch (action.payload) {
				case "WL":
					state.webAppProps.showUrlErrorRequired =
						state.webAppProps.url.trim() === "";
					break;

				case "RR":
					state.rssFeedReaderAppProps.showUrlErrorRequired =
						state.rssFeedReaderAppProps.url.trim() === "";
					break;

				case "FA":
					state.fileAppProps.showUrlErrorRequired =
						state.fileAppProps.url.trim() === "";
					break;

				case "IA":
					state.appProps.showTypeErrorRequired =
						state.appProps.type === null ||
						state.appProps.type === undefined;
					state.appProps.showNameErrorRequired =
						state.appProps.name.trim() === "";
					state.appProps.showIconUrlErrorRequired =
						state.appProps.iconUrl.trim() === "";
					state.appProps.showOnSelectedScreenErrorRequired =
						!state.appProps.showOnAllScreens &&
						state.appProps.selectedScreens.length === 0;
					break;

				default:
					break;
			}
		},
	},
	extraReducers: {
		...CollectionSelectorRedux.extraReducers,
	},
});

export const {
	addAppPressed,
	close,
	nextStep,
	jumpToStep,
	appTypeSelected,
	appNameEntered,
	appIconUploaded,
	appShownOnAllScreensChanged,
	appScreenToShowOnAdded,
	appScreenToShowOnRemoved,
	webAppUrlEntered,
	webAppMobileFriendlyUrlEntered,
	webAppAllowedDomainAdded,
	rssFeedReaderAppUrlEntered,
	fileAppUrlEntered,
	setCollectionAppCollections,
	showErrors,
	setCollectionBehavior,
	appPrivateChanged,
	appShowOnMobileChanged,
	appShowOnWebChanged,
	// Redux related codes for collectionSelector is moved into a separate file to avoid duplication
	// Below actions are coming from redux.js inside CollectionSelector
	syncData,
	resetPanel,
	selectBoard,
	setSearchType,
	setSearchText,
	removeCollection,
	selectCollection,
	filterLocalCollections,
} = addAppSlice.actions;

export default addAppSlice.reducer;
