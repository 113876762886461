/* eslint-disable react/prop-types */

/* FIXME: Fix lint issues. */

/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-23 23:47:01
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-06-12 03:46:46
 */
import React from "react";

import { cl } from "../../utils/general.util";

import "./style.css";

export const Spinner = ({ className = `spinner`, color = "white", width = "100px" }) => {
    return (
        <div style={{ minWidth: width }}>
            <div
                className={cl(
                    `relative h-6 w-full`,
                    className,
                    `before:border-${color} before:border-t-${color}`,
                )}></div>
        </div>
    );
};

export default Spinner;
