import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "../../api/analytics.service";
import * as BoardsService from "../../api/boards.service";
import { analyticsClient } from "../../contexts/mixpanel.context";
import { Actions } from "./actions";

// Currently used
function* getMixpanelEngagementSaga() {
	yield takeLatest("mixpanel/engagement/request", function* (action) {
		try {
			yield put({
				type: "mixpanel/engagement/pending",
				payload: {},
			});
			const response = yield call(
				async () =>
					await analyticsClient.segmentation(action.payload.params)
			);
			if (response) {
				yield put({
					type: "mixpanel/engagement/fulfilled",
					payload: { data: response },
				});
			} else {
				yield put({
					type: "mixpanel/engagement/rejected",
					payload: { data: null },
				});
			}
		} catch (e) {
			yield put({
				type: "mixpanel/engagement/rejected",
				payload: { data: null },
			});
		}
	});
}

// Currently used
function* getEngagementUnitUsageSaga() {
	yield takeLatest(
		Actions.getEngagementUnitsUsage + "request",
		function* (action) {
			try {
				yield put({
					type: Actions.getEngagementUnitsUsage + "pending",
					payload: {},
				});

				const { boardId, cycleResetDay } = action.payload;

				const response = yield call(async () => {
					return await API.getEngagementUnitUsage({
						boardId,
						cycleResetDay,
					});
				});
				if (!response) throw new Error();
				if (!response.success) throw new Error();
				if (!response.data) throw new Error();

				yield put({
					type: Actions.getEngagementUnitsUsage + "fulfilled",
					payload: response.data,
				});
			} catch (e) {
				yield put({
					type: Actions.getEngagementUnitsUsage + "rejected",
				});
			}
		}
	);
}

// Currently used
function* getHootEngagementUnitUsageSaga() {
	yield takeLatest(
		Actions.getHootEngagementUnitsUsage + "request",
		function* (action) {
			try {
				yield put({
					type: Actions.getHootEngagementUnitsUsage + "pending",
					payload: {},
				});

				const response = yield call(
					async () =>
						await analyticsClient.segmentation(
							action.payload.params
						)
				);
				if (response) {
					yield put({
						type: Actions.getHootEngagementUnitsUsage + "fulfilled",
						payload: { data: response },
					});
				} else {
					yield put({
						type: Actions.getHootEngagementUnitsUsage + "rejected",
						payload: { data: null },
					});
				}
			} catch (error) {
				yield put({
					type: Actions.getHootEngagementUnitsUsage + "rejected",
				});
			}
		}
	);
}

function* getAppEngagementUnitUsageSaga() {
	yield takeLatest(
		Actions.getAppEngagementUnitsUsage + "request",
		function* (action) {
			try {
				yield put({
					type: Actions.getAppEngagementUnitsUsage + "pending",
					payload: {},
				});

				const response = yield call(
					async () =>
						await analyticsClient.segmentation(
							action.payload.params
						)
				);
				if (response) {
					yield put({
						type: Actions.getAppEngagementUnitsUsage + "fulfilled",
						payload: { data: response },
					});
				} else {
					yield put({
						type: Actions.getAppEngagementUnitsUsage + "rejected",
						payload: { data: null },
					});
				}
			} catch (error) {
				yield put({
					type: Actions.getAppEngagementUnitsUsage + "rejected",
				});
			}
		}
	);
}

function* getActivityChannelsSaga() {
	yield takeLatest(Actions.getActivityChannels + "request", function* () {
		try {
			yield put({
				type: Actions.getActivityChannels + "pending",
				payload: {},
			});

			const response = yield call(async () => {
				return await API.fetchActivityChannels();
			});

			if (response && response.success) {
				yield put({
					type: Actions.getActivityChannels + "fulfilled",
					payload: { data: response.data },
				});
			} else {
				yield put({
					type: Actions.getActivityChannels + "rejected",
					payload: { data: null },
				});
			}
		} catch (error) {
			yield put({
				type: Actions.getActivityChannels + "rejected",
			});
		}
	});
}

function* getAnalyticsForBoardSaga() {
	yield takeLatest("board/analytics/request", function* (action) {
		try {
			yield put({
				type: "board/analytics/pending",
				payload: {},
			});
			const response = yield call(
				async () =>
					await API.fetchAnalyticsForBoardId(action.payload.params)
			);
			if (response && response.success) {
				yield put({
					type: "board/analytics/fulfilled",
					payload: { data: response.data },
				});
			} else {
				yield put({
					type: "board/analytics/rejected",
					payload: { data: null },
				});
			}
		} catch (e) {
			yield put({
				type: "board/analytics/rejected",
				payload: { data: null },
			});
		}
	});
}

function* getAnalyticsForSavedContentSaga() {
	yield takeLatest("board/analytics/saved/request", function* (action) {
		try {
			yield put({
				type: "board/analytics/saved/pending",
				payload: {},
			});
			const response = yield call(
				async () =>
					await API.fetchAnalyticsForSavedContent(
						action.payload.params
					)
			);
			if (response && response.success) {
				yield put({
					type: "board/analytics/saved/fulfilled",
					payload: { data: response.data },
				});
			} else {
				yield put({
					type: "board/analytics/saved/rejected",
					payload: { data: null },
				});
			}
		} catch (e) {
			yield put({
				type: "board/analytics/saved/rejected",
				payload: { data: null },
			});
		}
	});
}

function* getBoardMembersSaga() {
	yield takeLatest("board/members/request", function* (action) {
		try {
			yield put({
				type: "board/members/pending",
				payload: {},
			});
			const response = yield call(
				async () =>
					await BoardsService.getBoardMembers(
						action.payload.params.boardId
					)
			);
			if (response) {
				yield put({
					type: "board/members/fulfilled",
					payload: { data: response.members || [] },
				});
			} else {
				yield put({
					type: "board/members/rejected",
					payload: { data: [] },
				});
			}
		} catch (e) {
			yield put({
				type: "board/analytics/saved/rejected",
				payload: { data: [] },
			});
		}
	});
}

export const analyticsSagas = [
	getAnalyticsForBoardSaga(),
	getMixpanelEngagementSaga(),
	getEngagementUnitUsageSaga(),
	getAnalyticsForSavedContentSaga(),
	getBoardMembersSaga(),
	getHootEngagementUnitUsageSaga(),
	getAppEngagementUnitUsageSaga(),
	getActivityChannelsSaga(),
];
