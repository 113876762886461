/*
 * @Author: snair@hootboard.com
 * @Date: 2018-05-07 08:54:09
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-08-13 11:27:23
 */

import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
//
import { NOTIFICATIONS_WEBSOCKET_ENDPOINT } from "../helper/constants";
import { useFunctionalReducer } from "../hooks/useFunctionalReducer";

const SocketContext = createContext(null);

const socketInitialState = {
	newNotificationsCounter: 0,
	socket: null,
};
const socketActionMap = {
	setSocket: (draft, payload) => {
		draft.socket = payload;
	},
	increaseNotificationsCounter: (draft) => {
		draft.newNotificationsCounter++;
	},
	resetNotificationsCounter: (draft) => {
		draft.newNotificationsCounter = 0;
	},
};

/**
 * This would be the realtime data provider for the whole app.
 *
 * @param {SocketProvider.propTypes} props
 */
function SocketProvider(props) {
	const [state, dispatch] = useFunctionalReducer(
		socketActionMap,
		socketInitialState
	);

	const activeAccountToken = useSelector(
		(state) =>
			state.activeAccount.activeAccountDetails &&
			state.activeAccount.activeAccountDetails.token
	);

	useEffect(() => {
		if (activeAccountToken) {
			initializeSocket(activeAccountToken);
		}
	}, [activeAccountToken]);

	const initializeSocket = async (token) => {
		try {
			let socket = await io(NOTIFICATIONS_WEBSOCKET_ENDPOINT, {
				query: "token=" + token,
			});

			return initializeSocketIOListeners(socket);
		} catch (err) {
			// TODO: Add logger: "FAILED_TO_CONNECT_TO_REALTIME_SERVER";
		}
	};

	const initializeSocketIOListeners = (socket) => {
		if (!socket) return;
		dispatch.setSocket(socket);

		socket.on("hootboard:notification", () => {
			dispatch.increaseNotificationsCounter();
		});

		return () => {
			socket.off("hootboard:notification");
		};
	};

	return (
		<SocketContext.Provider value={{ state, dispatch }}>
			{props.children}
		</SocketContext.Provider>
	);
}

function useSocket() {
	const data = useContext(SocketContext);

	if (!data) {
		throw new Error("Please, Wrap the parent with socket provider");
	}

	return data;
}

export { SocketProvider, SocketContext, useSocket };

SocketProvider.propTypes = {
	children: PropTypes.node,
};
