/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-28 09:58:15
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-08-21 16:24:58
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	serverState: null,
	localState: {
		boardsById: {},
		pinnedBoardIds: [],

		pinnedBoards: [],
		unPinnedBoards: [],
	},
	getBoardsStatus: "idle",
	pinBoardStatus: "idle",
	pinBoardError: false,
	pinBoardErrorMessage: "Failed to pin the board",
	unPinBoardError: false,
	unPinBoardErrorMessage: "Failed to pin the board",
	getBoardsError: false,
	getBoardsErrorMessage: "Failed to get boards",
};

export function fetchUserBoardsAsync(payload) {
	return {
		type: "user/boards/list/request",
		payload,
	};
}

export function pinBoardAsync(payload) {
	return {
		type: "user/board/pin/request",
		payload,
	};
}

export function unPinBoardAsync(payload) {
	return {
		type: "user/board/unPin/request",
		payload,
	};
}

function getPinnedBoardIds(boardsById) {
	let boardIds = Object.keys(boardsById);

	return boardIds.filter((boardId) => boardsById[boardId].pinned);
}

function generateMapFromCollection(userBoards) {
	let obj = {};
	[...userBoards]
		.sort((a, b) => a.boardName.localeCompare(b.boardName))
		.map((item) => {
			obj[item.boardId] = item;
		});
	return obj;
}

const sortBoards = (list, pinned) => {
	return list
		.map((item) => ({ ...item, pinned }))
		.sort((a, b) => a.boardName.localeCompare(b.boardName));
};

const myBoardsSlice = createSlice({
	name: "myBoards",
	initialState: initialState,
	reducers: {
		setInitialState: (state, action) => {
			state.boardsById = action.payload.activeAccountUserBoardsById;
			state.pinnedBoardIds = getPinnedBoardIds(
				action.payload.activeAccountUserBoardsById
			);
		},
		pinBoard: (state, action) => {
			state.pinnedBoardIds = [
				...state.pinnedBoardIds,
				action.payload.boardId,
			];
		},
		unPinBoard: (state, action) => {
			const boardId = action.payload.boardId;
			const board = state.localState.pinnedBoards.find(
				(item) => item.boardId === boardId
			);
			// TODO: Remove?
			state.pinnedBoardIds = state.pinnedBoardIds.filter(
				(item) => item !== board
			);

			state.localState.pinnedBoards = sortBoards(
				state.localState.pinnedBoards.filter((item) => item !== board),
				true
			);

			state.localState.unPinnedBoards = sortBoards(
				[...state.localState.unPinnedBoards, board],
				false
			);
		},
	},
	extraReducers: {
		"user/board/pin/request": (state, action) => {
			const boardId = action.payload.boardId;
			const board = state.localState.unPinnedBoards.find(
				(item) => item.boardId === boardId
			);
			// since the board is not available in unpinnedboards just after the creation of board
			// we are adding this condition to by pass sorting of boards
			if (board) {
				state.localState.unPinnedBoards = sortBoards(
					state.localState.unPinnedBoards.filter(
						(item) => item !== board
					),
					false
				);

				state.localState.pinnedBoards = sortBoards(
					[...state.localState.pinnedBoards, board],
					true
				);
			}
		},
		"user/board/unPin/request": (state, action) => {
			const boardId = action.payload.boardId;
			const board = state.localState.pinnedBoards.find(
				(item) => item.boardId === boardId
			);

			state.localState.pinnedBoards = sortBoards(
				state.localState.pinnedBoards.filter((item) => item !== board),
				true
			);

			state.localState.unPinnedBoards = sortBoards(
				[...state.localState.unPinnedBoards, board],
				false
			);
		},
		"user/board/pin/pending": (state) => {
			return {
				...state,
				pinBoardStatus: "pending",
			};
		},
		"user/board/pin/fulfilled": (state) => {
			return {
				...state,
				pinBoardStatus: "fulfilled",
			};
		},
		"user/board/pin/rejected": (state) => {
			return {
				...state,
				pinBoardStatus: "rejected",
			};
		},
		"user/board/unPin/pending": (state) => {
			return {
				...state,
				pinBoardStatus: "pending",
			};
		},
		"user/board/unPin/fulfilled": (state) => {
			return {
				...state,
				pinBoardStatus: "fulfilled",
			};
		},
		"user/board/unPin/rejected": (state) => {
			return {
				...state,
				pinBoardStatus: "rejected",
			};
		},
		"user/boards/list/fulfilled": (state, action) => {
			state.boardsById = generateMapFromCollection(
				action.payload.userBoards
			);
			// TODO: Remove?
			state.pinnedBoardIds = getPinnedBoardIds(
				generateMapFromCollection(action.payload.userBoards)
			);
			state.localState.unPinnedBoards = action.payload.userBoards.filter(
				(item) => !item.pinned
			);
			state.localState.pinnedBoards = action.payload.userBoards.filter(
				(item) => item.pinned
			);
		},
		"user/details/fulfilled": (state, action) => {
			state.boardsById = generateMapFromCollection(
				action.payload.userDetails.userBoards
			);
			// TODO: Remove?
			state.pinnedBoardIds = getPinnedBoardIds(
				generateMapFromCollection(action.payload.userDetails.userBoards)
			);
			state.localState.unPinnedBoards =
				action.payload.userDetails.userBoards.filter(
					(item) => !item.pinned
				);
			state.localState.pinnedBoards =
				action.payload.userDetails.userBoards.filter(
					(item) => item.pinned
				);
		},
		"board/follow/fulfilled": (state, action) => {
			state.localState.unPinnedBoards =
				action.payload.userDetails.userBoards.filter(
					(item) => !item.pinned
				);
			state.localState.pinnedBoards =
				action.payload.userDetails.userBoards.filter(
					(item) => item.pinned
				);
		},
		"auth/logUserOut": () => {
			return initialState;
		},
	},
});
export const { setInitialState, pinBoard, unPinBoard } = myBoardsSlice.actions;
export default myBoardsSlice.reducer;
