import { call, delay, put, takeLatest } from "redux-saga/effects";
import { ActionState, ErrorCodes } from "../../types";
import * as API from "../../api/members.service";
import * as BoardsService from "../../api/boards.service";
import { memberTypeFromCode } from "./memberType.util";
import { PayloadActionsWithMeta } from "./manageMembers.slice";

/**
 * Actions for Manage Members Component.
 */
export const Actions = {
	fetchManageMembersData: "manageMembers/fetchManageMembersData/",
	fetchMembersData: "manageMembers/fetchMembersData/",
	fetchPendingInvitesData: "manageMembers/fetchPendingInvitesData/",
	fetchRequestsData: "manageMembers/fetchRequestsData/",
	refreshPendingInvites: "manageMembers/refreshPendingInvites/",
	resendInvitation: "manageMembers/resendInvitation/",
	revokeInvitation: "manageMembers/revokeInvitation/",
	changeMemberType: "manageMembers/changeMemberType/",
	removeMember: "manageMembers/removeMember/",
	updateRequest: "manageMembers/updateRequest/",
	getAllSubscribers: "manageMembers/getAllSubscribers/",
	sendInvites: "manageMembers/sendInvites/",
	sendBulkInvite: "manageMembers/sendBulkInvite/",
} as const;

/**
 * This function dispatches all required actions to fetch data for all manage members tabs.
 */
function* fetchManageMembersDataSaga() {
	yield takeLatest(
		Actions.fetchManageMembersData + ActionState.REQUEST,
		function* (action: PayloadActionsWithMeta) {
			yield put({
				type: Actions.fetchMembersData + ActionState.REQUEST,
				payload: action.payload,
			});
			yield put({
				type: Actions.fetchPendingInvitesData + ActionState.REQUEST,
				payload: action.payload,
			});
			yield put({
				type: Actions.fetchRequestsData + ActionState.REQUEST,
				payload: action.payload,
			});
			yield put({
				type: Actions.getAllSubscribers + ActionState.REQUEST,
				payload: action.payload,
			});
		}
	);
}

function* fetchMembersDataSaga() {
	yield takeLatest(
		Actions.fetchMembersData + ActionState.REQUEST,
		function* (action: any): any {
			try {
				const { boardId } = action.payload;
				yield put({
					type: Actions.fetchMembersData + ActionState.PENDING,
				});

				const response = yield call(() =>
					BoardsService.getBoardMembers(boardId)
				);

				if (!response) throw new Error();
				if (!response.members) throw new Error();

				yield put({
					type: Actions.fetchMembersData + ActionState.FULFILLED,
					payload: response.members,
				});
			} catch (e: any) {
				yield put({
					type: Actions.fetchMembersData + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* changeMemberTypeSaga() {
	yield takeLatest(
		Actions.changeMemberType + ActionState.REQUEST,
		function* (action: any): any {
			try {
				const { boardId, memberId, memberAccessCode } = action.payload;
				yield put({
					type: Actions.changeMemberType + ActionState.PENDING,
					meta: { arg: { memberId } },
				});

				const response = yield call(() =>
					BoardsService.changeMemberTypeReq(
						boardId,
						memberId,
						memberTypeFromCode(memberAccessCode)
					)
				);

				if (!response) throw new Error();
				if (!response.success) throw new Error(response.message);

				yield put({
					type: Actions.changeMemberType + ActionState.FULFILLED,
					payload: { response, memberId, memberAccessCode },
				});
			} catch (e: any) {
				yield put({
					type: Actions.changeMemberType + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* removeMemberSaga() {
	yield takeLatest(
		Actions.removeMember + ActionState.REQUEST,
		function* (action: any): any {
			try {
				const { boardId, memberId } = action.payload;

				yield put({
					type: Actions.removeMember + ActionState.PENDING,
					meta: { arg: { memberId } },
				});

				const response = yield call(() =>
					BoardsService.leaveBoard(boardId, memberId)
				);

				if (!response) throw new Error();

				yield put({
					type: Actions.removeMember + ActionState.FULFILLED,
					payload: { response, memberId },
				});
			} catch (e: any) {
				yield put({
					type: Actions.removeMember + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* fetchPendingInvitesDataSaga() {
	yield takeLatest(
		Actions.fetchPendingInvitesData + ActionState.REQUEST,
		function* (action: any): any {
			try {
				const { boardId } = action.payload;

				yield put({
					type: Actions.fetchPendingInvitesData + ActionState.PENDING,
				});

				const response = yield call(() =>
					BoardsService.getPendingAdminInvites(boardId)
				);

				if (!response) throw new Error();
				if (!response.requestsSent) throw new Error();

				yield put({
					type:
						Actions.fetchPendingInvitesData + ActionState.FULFILLED,
					payload: response.requestsSent,
				});
			} catch (e: any) {
				yield put({
					type:
						Actions.fetchPendingInvitesData + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* resendInvitationSaga() {
	yield takeLatest(
		Actions.resendInvitation + ActionState.REQUEST,
		function* (action: any): any {
			try {
				yield put({
					type: Actions.resendInvitation + ActionState.PENDING,
					meta: { arg: action.payload },
				});

				const response = yield call(() =>
					BoardsService.resendInvitationReq(action.payload)
				);

				if (!response) throw new Error();

				yield put({
					type: Actions.resendInvitation + ActionState.FULFILLED,
					payload: response,
					meta: { arg: action.payload },
				});
			} catch (e: any) {
				yield put({
					type: Actions.resendInvitation + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
					meta: { arg: action.payload },
				});
			}
		}
	);
}

function* revokeInvitationSaga() {
	yield takeLatest(
		Actions.revokeInvitation + ActionState.REQUEST,
		function* (action: any): any {
			try {
				yield put({
					type: Actions.revokeInvitation + ActionState.PENDING,
					meta: { arg: action.payload },
				});

				const response = yield call(() =>
					BoardsService.revokeInvitationReq(action.payload)
				);

				if (!response) throw new Error();

				yield put({
					type: Actions.revokeInvitation + ActionState.FULFILLED,
					payload: { response, invitationId: action.payload },
				});
			} catch (e: any) {
				yield put({
					type: Actions.revokeInvitation + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* refreshPendingInvitesSaga() {
	yield takeLatest(
		Actions.refreshPendingInvites + ActionState.REQUEST,
		function* (action: any): any {
			try {
				yield put({
					type: Actions.refreshPendingInvites + ActionState.PENDING,
				});

				const response = yield call(() =>
					BoardsService.getPendingAdminInvites(action.payload)
				);

				if (!response) throw new Error();

				yield put({
					type: Actions.refreshPendingInvites + ActionState.FULFILLED,
					payload: response,
				});
			} catch (e: any) {
				yield put({
					type: Actions.refreshPendingInvites + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* updateRequestsSaga() {
	yield takeLatest(
		Actions.updateRequest + ActionState.REQUEST,
		function* (action: PayloadActionsWithMeta): any {
			try {
				const { boardId, memberId, actionType, accessLevel } =
					action.payload;
				yield put({
					type: Actions.updateRequest + ActionState.PENDING,
					meta: { arg: { memberId, actionType } },
				});

				const response = yield call(() =>
					API.updatePendingRequests(boardId, {
						memberId,
						accessLevel,
						action: actionType,
					})
				);

				if (!response) throw new Error();
				if (response.error) throw new Error();

				if (actionType === "accept" && accessLevel) {
					// Refresh Board Members Data
					yield put({
						type: Actions.fetchMembersData + ActionState.REQUEST,
						payload: { boardId },
					});
				}

				yield put({
					type: Actions.updateRequest + ActionState.FULFILLED,
					payload: {
						response,
					},
					meta: { arg: { memberId, actionType } },
				});
			} catch (e: any) {
				const { memberId, actionType } = action.payload;
				yield put({
					type: Actions.updateRequest + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
					meta: {
						arg: { memberId, actionType },
					},
				});
			}
		}
	);
}

function* fetchRequestsDataSaga() {
	yield takeLatest(
		Actions.fetchRequestsData + ActionState.REQUEST,
		function* (action: any): any {
			try {
				const { boardId } = action.payload;

				yield put({
					type: Actions.fetchRequestsData + ActionState.PENDING,
				});

				const response = yield call(() =>
					API.getPendingRequests(boardId)
				);

				if (!response) throw new Error();
				if (!response.data) throw new Error();
				if (!response.data.pendingRequests) throw new Error();

				yield put({
					type: Actions.fetchRequestsData + ActionState.FULFILLED,
					payload: response.data.pendingRequests,
				});
			} catch (e: any) {
				yield put({
					type: Actions.fetchRequestsData + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* getAllSubscribersSaga() {
	yield takeLatest(
		Actions.getAllSubscribers + ActionState.REQUEST,
		function* (action: any): any {
			try {
				const { boardId, limit, offset } = action.payload;

				yield put({
					type: Actions.getAllSubscribers + ActionState.PENDING,
				});

				const response = yield call(() =>
					API.getAllSubscribers(boardId, limit, offset)
				);

				if (!response) throw new Error();
				if (!response.success) throw new Error(response.errorMessage);
				if (!response.data.subscribers) throw new Error();

				yield put({
					type: Actions.getAllSubscribers + ActionState.FULFILLED,
					payload: {
						subscribers: response.data.subscribers,
						subscribersCount:
							response.data.totalSubscriberCount || 0,
					},
				});
			} catch (e: any) {
				yield put({
					type: Actions.getAllSubscribers + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* sendInvitesSaga() {
	yield takeLatest(
		Actions.sendInvites + ActionState.REQUEST,
		function* (action: PayloadActionsWithMeta): any {
			try {
				const {
					boardId,
					boardAdmin,
					boardName,
					emails,
					message,
					displayName,
					selectedMemberType,
				} = action.payload;

				yield put({
					type: Actions.sendInvites + ActionState.PENDING,
				});

				const response = yield call(() =>
					BoardsService.sendInvites(boardId, {
						adminInvite: boardAdmin,
						boardName: boardName,
						inviteEmails: emails,
						inviteMsg: message,
						inviteeName: displayName,
						membType: selectedMemberType,
						partnerBoard: false,
					})
				);

				// delay for 1 second
				yield delay(1000);
				// then refresh the pending invites
				yield put({
					type: Actions.refreshPendingInvites + ActionState.REQUEST,
					payload: boardId,
				});

				if (!response) throw new Error();

				yield put({
					type: Actions.sendInvites + ActionState.FULFILLED,
					payload: response,
				});
			} catch (e: any) {
				yield put({
					type: Actions.sendInvites + ActionState.REJECTED,
				});
			}
		}
	);
}

function* sendBulkInvitesSaga() {
	yield takeLatest(
		Actions.sendBulkInvite + ActionState.REQUEST,
		function* (action: PayloadActionsWithMeta): any {
			try {
				const { boardId, bulkInviteCSVFileName, originalFileName } =
					action.payload;

				yield put({
					type: Actions.sendBulkInvite + ActionState.PENDING,
				});

				const response = yield call(() =>
					BoardsService.sendBulkInvites(
						boardId,
						bulkInviteCSVFileName,
						originalFileName
					)
				);

				if (!response) throw new Error();

				// delay for 1 second
				yield delay(1000);
				// then refresh the pending invites
				yield put({
					type: Actions.refreshPendingInvites + ActionState.REQUEST,
					payload: boardId,
				});

				yield put({
					type: Actions.sendBulkInvite + ActionState.FULFILLED,
					payload: response,
				});
			} catch (e: any) {
				yield put({
					type: Actions.sendBulkInvite + ActionState.REJECTED,
				});
			}
		}
	);
}

export const manageMembersSagas = [
	fetchManageMembersDataSaga(),
	fetchMembersDataSaga(),
	changeMemberTypeSaga(),
	removeMemberSaga(),
	fetchPendingInvitesDataSaga(),
	resendInvitationSaga(),
	revokeInvitationSaga(),
	refreshPendingInvitesSaga(),
	updateRequestsSaga(),
	fetchRequestsDataSaga(),
	getAllSubscribersSaga(),
	sendInvitesSaga(),
	sendBulkInvitesSaga(),
];
