import { createAction, createSlice } from "@reduxjs/toolkit";
import { ActionState, AsyncState } from "../../types";
import { getScreens } from "../ManageScreensNew/manageScreens.slice";
import { Actions } from "./appStore.saga";
import * as CollectionSelectorRedux from "./components/CollectionSelector/redux";

export const unPublishApp = ({ appId, boardId }) => {
	return {
		type: Actions.unPublishApp + "request",
		payload: { appId, boardId },
	};
};

export const installApp = ({ boardId, payload }) => {
	return {
		type: Actions.installApp + "request",
		payload: {
			boardId,
			payload: {
				appId: payload.appId,
				appIcon: payload.appIcon,
				isPrivate: payload.appAccess !== "public",
				showOnWeb: payload.showOnWeb,
				showOnMobile: payload.showOnMobile,
				boardScreenIds: payload.boardScreenIds,
				showAllScreens: payload.showAllScreens,
			},
		},
	};
};

export const publishApp = ({ boardId, payload }) => {
	return {
		type: Actions.publishApp + "request",
		payload: {
			boardId,
			payload: {
				appTypeCode: payload.appTypeCode,
				developerApp: payload.developerApp,
				appVersion: {
					appName: payload.appName,
					endpoint: payload.endpoint,
					mobileEndpoint: payload.mobileEndpoint,
					collectionBehaviour: payload.collectionBehaviour,
					sortBy: payload.sortBy,
					appIcon: payload.appIcon,
					hbConfig: payload.hbConfig,
					settingsEndpoint: payload.settingsEndpoint,
					domainsAllowed: payload.domainsAllowed,
					appDescription: payload.appDescription,
					global: false,
					radius: 1000,
					appLocation: payload.appLocation,
				},
			},
		},
	};
};

export const updatePublishedApp = ({ boardId, appUUID, payload }) => {
	return {
		type: Actions.updatePublishedApp + "request",
		payload: {
			boardId,
			appUUID,
			payload: {
				appTypeCode: payload.appTypeCode,
				developerApp: payload.developerApp,
				appVersion: {
					appName: payload.appName,
					endpoint: payload.endpoint,
					mobileEndpoint: payload.mobileEndpoint,
					collectionBehaviour: payload.collectionBehaviour,
					sortBy: payload.sortBy,
					appIcon: payload.appIcon,
					hbConfig: payload.hbConfig,
					settingsEndpoint: payload.settingsEndpoint,
					domainsAllowed: payload.domainsAllowed,
					appDescription: payload.appDescription,
					global: payload.global,
					radius: payload.radius,
					appLocation: payload.appLocation,
				},
			},
		},
	};
};

export const getAppDirectory = ({ boardId, boardLocation }) => {
	let payload = {
		start: 0,
		max: 20,
		global: true,
		boardId,
	};

	if (boardLocation) {
		payload = {
			...payload,
			latitude: boardLocation.latitude,
			longitude: boardLocation.longitude,
		};
	}

	return {
		type: Actions.getAppDirectory + "request",
		payload,
	};
};

export const getBoardAppDirectory = ({ boardId }) => {
	return {
		type: Actions.getBoardAppDirectory + "request",
		payload: { boardId },
	};
};

/**
 * @param {number} boardId
 */
export const getRecommendedApps = createAction(
	Actions.getRecommendedApps + ActionState.REQUEST
);

const initialState = {
	//
	isInstallModalVisible: false,
	isUnpublishModalVisible: false,
	isPublishModalVisible: false,
	isAppIconChooserVisible: false,
	//
	publishStep: 0,
	//
	installData: {
		appId: null,
		appName: null,
		appIcon: null,
		publishedBy: null,
		appDescription: null,
		boardScreenIds: "",
		hbConfig: "",
		settingsEndpoint: null,
		selectedScreens: [],
		appAccess: "public",
		restrictedAppAccess: {}, // will have keys - owner, admin and emergencyPoster mapped with boolean value
		showAllScreens: true,
		showOnMobile: true,
		showOnWeb: true,
	},
	//
	publishData: {
		appUUID: null,
		appTypeCode: null,
		developerApp: false,
		appIcon: "",
		appName: "",
		sortBy: "appName",
		endpoint: "",
		settingsEndpoint: "",
		hbConfig: "",
		mobileEndpoint: "",
		collectionBehaviour: "U",
		domainsAllowed: "",
		appDescription: "",
		appLocation: null,
		locationString: "",
		global: null,
		radius: null,
	},
	//
	buttonClicked: false,
	formErrors: {
		appType: null,
		appName: null,
		screens: null,
		appDescription: null,
		appIcon: null,
		endpoint: null,
		settingsEndpoint: null,
		location: null,
	},
	//
	isLoading: false,
	isDone: false,
	isError: false,
	//
	isUnpublishLoading: false,
	isUnpublishDone: false,
	isUnpublishError: false,
	//
	collectionAppProps: {
		selectedCollections: [],
		// Redux related codes for collectionSelector is moved into a separate file to avoid duplication
		collectionSelector: CollectionSelectorRedux.initialState,
	},
	//
	appDirectory: [],
	recommendedApps: [],
	recommendedAppsAPIStatus: AsyncState.IDLE,
	boardAppDirectory: [],
	//
	isListLoading: false,
	//
	screens: [],
};

const slice = createSlice({
	name: "appStore",
	initialState: initialState,
	reducers: {
		// Redux related codes for collectionSelector is moved into a separate file to avoid duplication
		...CollectionSelectorRedux.reducers,
		resetAppDirectory: () => initialState,
		prepareForEdit: (state, action) => {
			const appData = action.payload;

			const locationStringArray = [];

			if (appData.appVersion.appLocation.region) {
				locationStringArray.push(appData.appVersion.appLocation.region);
			}

			if (appData.appVersion.appLocation.city) {
				locationStringArray.push(appData.appVersion.appLocation.city);
			}

			if (appData.appVersion.appLocation.state) {
				locationStringArray.push(appData.appVersion.appLocation.state);
			}

			if (appData.appVersion.appLocation.country) {
				locationStringArray.push(
					appData.appVersion.appLocation.country
				);
			}

			state.publishData = {
				appUUID: appData.uuid,
				appTypeCode: appData.appTypeCode,
				developerApp: appData.developerApp,
				appIcon: appData.appVersion.appIcon,
				appName: appData.appVersion.appName,
				sortBy: appData.appVersion.sortBy,
				endpoint: appData.appVersion.endpoint,
				global: appData.appVersion.global,
				radius: appData.appVersion.radius,
				mobileEndpoint: appData.appVersion.mobileEndpoint,
				settingsEndpoint: appData.appVersion.settingsEndpoint,
				hbConfig: appData.appVersion.hbConfig,
				collectionBehaviour: appData.appVersion.collectionBehaviour,
				domainsAllowed: appData.appVersion.domainsAllowed,
				appDescription: appData.appVersion.appDescription,
				appLocation: appData.appVersion.appLocation,
				locationString: locationStringArray.join(", "),
			};

			if (appData.appTypeCode === "CL") {
				state.collectionAppProps.selectedCollections =
					CollectionSelectorRedux.convertFromEndpoint({
						boards: appData.appVersion.boards,
						endpoint: appData.appVersion.endpoint,
					});
			}

			state.isPublishModalVisible = true;
		},
		prepareForInstall: (state, action) => {
			const appData = action.payload;

			state.installData = {
				...initialState.installData,
				appId: appData.uuid,
				hbConfig: (appData.appVersion || {}).hbConfig,
				appName: appData.appVersion.appName,
				appIcon: appData.appVersion.appIcon,
				publishedBy: appData.publisherBoardName,
				appDescription: appData.appVersion.appDescription,
			};

			state.isInstallModalVisible = true;
		},
		resetForm: (state) => {
			return {
				...initialState,
				appDirectory: state.appDirectory,
				isListLoading: state.isListLoading,
				boardAppDirectory: state.boardAppDirectory,
			};
		},
		goBackToForm: (state) => {
			state.isDone = false;
			state.isError = false;
			state.isUnpublishDone = false;
			state.isUnpublishError = false;
		},
		setInstallData: (state, action) => {
			state.installData = {
				...state.installData,
				...action.payload,
			};

			const installData = state.installData;

			if (!installData.showAllScreens) {
				if (installData.selectedScreens.length === 0) {
					state.formErrors.screens = "Select at least one screen!";
				} else {
					state.formErrors.screens = null;
				}
			} else {
				state.formErrors.screens = null;
			}

			state.installData.boardScreenIds = installData.selectedScreens
				.map((item) => item.screenId)
				.join(",");
		},
		setFormData: (state, action) => {
			state.publishData = {
				...state.publishData,
				...action.payload,
			};

			const publishData = state.publishData;

			if (!publishData.appTypeCode) {
				state.formErrors.appType = "App type is required!";
			} else {
				state.formErrors.appType = null;
			}

			if (publishData.appName === "") {
				state.formErrors.appName = "App name is required!";
			} else {
				state.formErrors.appName = null;
			}

			if (publishData.appIcon === "") {
				state.formErrors.appIcon = "App icon is required!";
			} else {
				state.formErrors.appIcon = null;
			}

			if (publishData.appDescription === "") {
				state.formErrors.appDescription =
					"App description is required!";
			} else {
				state.formErrors.appDescription = null;
			}

			if (publishData.endpoint === "") {
				if (publishData.appTypeCode === "CL") {
					state.formErrors.endpoint =
						"Minimum one collection needs to be selected!";
				} else {
					state.formErrors.endpoint = "URL is required!";
				}
			} else {
				if (publishData.appTypeCode === "CL") {
					state.formErrors.endpoint = null;
				} else {
					const isUrlValid = /^(ftp|http|https):\/\/[^ "]+$/.test(
						publishData.endpoint
					);
					state.formErrors.endpoint = isUrlValid
						? null
						: "Invalid URL!";
				}
			}

			if (publishData.settingsEndpoint !== "") {
				const isUrlValid = /^(ftp|http|https):\/\/[^ "]+$/.test(
					publishData.settingsEndpoint
				);
				state.formErrors.settingsEndpoint = isUrlValid
					? null
					: "Invalid Settings URL!";
			} else {
				state.formErrors.settingsEndpoint = null;
			}

			if (
				!publishData.appLocation ||
				!publishData.appLocation.googlePlaceId
			) {
				if (!publishData.appLocation) {
					state.formErrors.location = "Location is required!";
				} else {
					const location = {
						areaCode: null,
						postalCode: null,
						latitude: null,
						longitude: null,
						region: null,
						city: null,
						state: null,
						country: null,
						googlePlaceId: publishData.appLocation.place_id,
					};

					const { geometry, address_components } =
						publishData.appLocation;

					if (geometry && geometry.location) {
						location.latitude = geometry.location.lat();
						location.longitude = geometry.location.lng();
					}

					[...(address_components || [])].forEach((item) => {
						if (item.types) {
							if ([...item.types].includes("country")) {
								location.country = item.long_name;
								return;
							}
							if (
								[...item.types].includes(
									"administrative_area_level_1"
								)
							) {
								location.state = item.long_name;
								return;
							}
							if (
								[...item.types].includes(
									"administrative_area_level_2"
								)
							) {
								location.city = item.long_name;
								return;
							}
							if ([...item.types].includes("locality")) {
								location.region = item.long_name;
								return;
							}
							if ([...item.types].includes("postal_code")) {
								location.postalCode = item.long_name;
								return;
							}
						}
					});

					state.publishData.appLocation = location;

					state.formErrors.location = null;
				}
			}
		},
		setButtonClicked: (state) => {
			state.buttonClicked = true;
		},
		jumpToStep: (state, action) => {
			state.publishStep = action.payload;
		},
		nextStep: (state) => {
			state.buttonClicked = false;
			state.publishStep = state.publishStep + 1;
		},
		prevStep: (state) => {
			state.buttonClicked = false;
			state.publishStep = state.publishStep - 1;
		},
		toggleInstallModal: (state, action) => {
			state.isInstallModalVisible = !!action.payload;
		},
		toggleUnpublishModal: (state, action) => {
			state.isUnpublishModalVisible = !!action.payload;
		},
		togglePublishModal: (state, action) => {
			state.isPublishModalVisible = !!action.payload;
		},
		toggleAppIconChooser: (state, action) => {
			state.isAppIconChooserVisible = !!action.payload;
		},
	},
	extraReducers: {
		...CollectionSelectorRedux.extraReducers,
		[Actions.publishApp + "pending"]: (state) => {
			state.isLoading = true;
			state.isDone = false;
			state.isError = false;
		},
		[Actions.publishApp + "rejected"]: (state) => {
			state.isLoading = false;
			state.isDone = true;
			state.isError = true;
		},
		[Actions.publishApp + "fulfilled"]: (state) => {
			state.isLoading = false;
			state.isDone = true;
			state.isError = false;
		},
		//
		[Actions.updatePublishedApp + "pending"]: (state) => {
			state.isLoading = true;
			state.isDone = false;
			state.isError = false;
		},
		[Actions.updatePublishedApp + "rejected"]: (state) => {
			state.isLoading = false;
			state.isDone = true;
			state.isError = true;
		},
		[Actions.updatePublishedApp + "fulfilled"]: (state) => {
			state.isLoading = false;
			state.isDone = true;
			state.isError = false;
		},
		//
		[Actions.getBoardAppDirectory + "pending"]: (state) => {
			state.isListLoading = true;
		},
		[Actions.getBoardAppDirectory + "rejected"]: (state) => {
			state.isListLoading = false;
		},
		[Actions.getBoardAppDirectory + "fulfilled"]: (state, action) => {
			state.isListLoading = false;
			state.boardAppDirectory = action.payload;
		},
		//
		[Actions.getAppDirectory + "pending"]: (state) => {
			state.isListLoading = true;
		},
		[Actions.getAppDirectory + "rejected"]: (state) => {
			state.isListLoading = false;
		},
		[Actions.getAppDirectory + "fulfilled"]: (state, action) => {
			state.isListLoading = false;
			state.appDirectory = [...(action.payload || [])]
				.reverse()
				.filter((item) => !!item.appVersion);
		},
		//
		[Actions.unPublishApp + "pending"]: (state) => {
			state.isUnpublishModalVisible = false;
			state.isUnpublishLoading = true;
			state.isUnpublishDone = false;
			state.isUnpublishError = false;
		},
		[Actions.unPublishApp + "rejected"]: (state) => {
			state.isUnpublishLoading = false;
			state.isUnpublishDone = true;
			state.isUnpublishError = true;
		},
		[Actions.unPublishApp + "fulfilled"]: (state) => {
			state.isUnpublishLoading = false;
			state.isDone = true;
			state.isUnpublishDone = true;
			state.isError = false;
			state.isUnpublishError = false;
		},
		//
		[Actions.installApp + "pending"]: (state) => {
			state.isLoading = true;
			state.isDone = false;
			state.isError = false;
		},
		[Actions.installApp + "rejected"]: (state) => {
			state.isLoading = false;
			state.isDone = true;
			state.isError = true;
		},
		[Actions.installApp + "fulfilled"]: (state) => {
			state.isLoading = false;
			state.isDone = true;
			state.isError = false;
		},
		//
		[getScreens + ActionState.PENDING]: (state) => {
			state.isListLoading = true;
		},
		[getScreens + ActionState.FULFILLED]: (state, action) => {
			let screens = [];
			const { response } = action.payload;

			if (response && response.message === "success") {
				screens = response.data;
			}

			state.isListLoading = false;
			state.screens = screens;
		},
		//
		[Actions.getRecommendedApps + ActionState.PENDING]: (state) => {
			state.recommendedAppsAPIStatus = AsyncState.PENDING;
		},
		[Actions.getRecommendedApps + ActionState.FULFILLED]: (
			state,
			action
		) => {
			state.recommendedAppsAPIStatus = AsyncState.FULFILLED;
			state.recommendedApps = action.payload;
		},
	},
});

export const {
	resetAppDirectory,
	goBackToForm,
	setFormData,
	nextStep,
	prevStep,
	resetForm,
	jumpToStep,
	setInstallData,
	prepareForEdit,
	setButtonClicked,
	prepareForInstall,
	toggleInstallModal,
	togglePublishModal,
	toggleUnpublishModal,
	toggleAppIconChooser,
	// Redux related codes for collectionSelector is moved into a separate file to avoid duplication
	// Below actions are coming from redux.js inside CollectionSelector
	syncData,
	resetPanel,
	selectBoard,
	setSearchType,
	setSearchText,
	removeCollection,
	selectCollection,
	filterLocalCollections,
} = slice.actions;

export default slice.reducer;
