import {
	NOTIFICATIONS_ENDPOINT,
	NOTIFICATION_VERSION,
} from "../helper/constants";

/**
 * Used to get all the notifications related to the user
 * @param {string} token
 * @param {number} page
 */
const getNotifications = async (token, page = 1) => {
	let response = await fetch(
		`${NOTIFICATIONS_ENDPOINT}/latest/?page=${page}`,
		{
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"x-access-token": token,
				"x-notification-version": NOTIFICATION_VERSION,
			},
		}
	);

	let responseJson = await response.json();
	return responseJson;
};

/**
 * API to make a notification read
 * @param {string} token
 * @param {string} partyId
 * @param {string} notificationId
 */
const readNotifications = async ({ token, notificationId, partyId }) => {
	let response = await fetch(`${NOTIFICATIONS_ENDPOINT}/read`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"x-access-token": token,
		},
		body: JSON.stringify({ notificationId, partyId }),
	});

	let responseJson = await response.json();
	return responseJson;
};

export { getNotifications, readNotifications };
