/*
 * @Author: snair@hootboard.com
 * @Date: 2020-06-23 00:55:22
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-10-12 01:42:20
 */

import { httpGetV2 } from "./http-wrapper";

/**
 *
 * @param {number} boardId
 * @param {number} month
 * @param {number} year
 * @param {boolean} showPrivateEvents
 */
const getHootsForCalendar = async ({
	boardId,
	month,
	year,
	showPrivateEvents = false,
}) => {
	const params = {
		month,
		year,
		showPrivateEvents,
	};

	const apiConfig = {
		"x-board-id": boardId,
	};

	let apiEndpoint = "public/hoots/calendar";
	if (localStorage.getItem("authToken")) {
		apiEndpoint = "private/hoots/calendar";
	}

	let response = await httpGetV2(apiEndpoint, params, apiConfig);
	return response;
};

/**
 *
 * @param {number} boardId
 * @param {number} month
 * @param {number} year
 * @param {boolean} showPrivateEvents
 */
const getHootsForCalendarDuration = async ({
	boardId,
	months,
	years,
	showPrivateEvents = false,
}) => {
	const params = {
		months,
		years,
		showPrivateEvents,
	};

	const apiConfig = {
		"x-board-id": boardId,
	};

	let apiEndpoint = "public/hoots/events/calendar";
	if (localStorage.getItem("authToken")) {
		apiEndpoint = "private/hoots/events/calendar";
	}

	let response = await httpGetV2(apiEndpoint, params, apiConfig);
	return response;
};

export { getHootsForCalendar, getHootsForCalendarDuration };
