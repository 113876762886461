/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-25 20:25:21
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-05-29 15:28:24
 */

import { createSlice } from "@reduxjs/toolkit";

export function getPersonalNotificationSettingsAsync(payload) {
	return {
		type: "user/notifications/get/request",
		payload: payload,
	};
}

export function updatePersonalNotificationSettingsAsync(payload) {
	return {
		type: "user/notifications/update/request",
		payload: payload,
	};
}

export function getBoardNotificationSettingsAsync(payload) {
	return {
		type: "board/notifications/get/request",
		payload: payload,
	};
}

export function updateBoardNotificationSettingsAsync(payload) {
	return {
		type: "board/notifications/update/request",
		payload: payload,
	};
}

const initialState = {
	serverState: null,
	localState: {
		hootAprRejEmail: true,
		privateMsgEmail: true,
		hbUpdatesNotif: true,
		myHootPostedEmail: false,
		privateMsgNotif: true,
		hbUpdatesEmail: false,
		hootRemovedEmail: true,
		hootAprRejNotif: true,
		hootDeletedNotif: true,
		rehootNotif: true,
		rehootEmail: true,
		hootDeletedEmail: true,
		hootRemovedNotif: true,
		myHootPostedNotif: false,
	},
	isEditing: false,
	fetchPersonalNotificationSettingsStatus: "idle",
};

const personalNotificationSettingsSlice = createSlice({
	name: "personalNotificationSettings",
	initialState: initialState,
	reducers: {
		toggleHootPostedEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					myHootPostedEmail: action.payload.checked,
				},
				isEditing: true,
			};
		},
		togglePrivateMsgEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					privateMsgEmail: action.payload.checked,
				},
				isEditing: true,
			};
		},
		toggleHootAprRejEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					hootAprRejEmail: action.payload.checked,
				},
				isEditing: true,
			};
		},
		toggleHbUpdatesEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					hbUpdatesEmail: action.payload.checked,
				},
				isEditing: true,
			};
		},
		toggleHootRemovedEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					hootRemovedEmail: action.payload.checked,
				},
				isEditing: true,
			};
		},
		toggleRehootEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					rehootEmail: action.payload.checked,
				},
				isEditing: true,
			};
		},
		toggleHootDeletedEmail: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					hootDeletedEmail: action.payload.checked,
				},
				isEditing: true,
			};
		},
	},
	extraReducers: {
		"user/notifications/get/fulfilled": (state, action) => {
			return {
				...state,
				serverState: action.payload.notificationSettings,
				localState: action.payload.notificationSettings,
				isEditing: false,
				fetchPersonalNotificationSettingsStatus: "fulfilled",
			};
		},
		"user/notifications/get/pending": (state) => {
			return {
				...state,
				fetchPersonalNotificationSettingsStatus: "pending",
			};
		},
		"user/notifications/get/rejected": (state) => {
			return {
				...state,
				fetchPersonalNotificationSettingsStatus: "rejected",
			};
		},
		"user/notifications/update/fulfilled": (state, action) => {
			return {
				...state,
				serverState: action.payload.notificationSettings,
				localState: action.payload.notificationSettings,
				isEditing: false,
				updatePersonalNotificationSettingsStatus: "fulfilled",
			};
		},
		"user/notifications/update/pending": (state) => {
			return {
				...state,
				updatePersonalNotificationSettingsStatus: "pending",
			};
		},
		"user/notifications/update/rejected": (state) => {
			return {
				...state,
				updatePersonalNotificationSettingsStatus: "rejected",
			};
		},
	},
});
export const {
	toggleHootAprRejEmail,
	toggleHbUpdatesEmail,
	toggleHootDeletedEmail,
	toggleHootPostedEmail,
	toggleHootRemovedEmail,
	togglePrivateMsgEmail,
	toggleRehootEmail,
} = personalNotificationSettingsSlice.actions;
export default personalNotificationSettingsSlice.reducer;
