import { createSlice } from "@reduxjs/toolkit";

import { ANALYTICS_EU_USAGE_GROUP } from "../../helper/constants";

import { Actions } from "./actions";

export const TIME_INTERVAL = {
    WEEK: "WEEK",
    MONTH: "MONTH",
    YEAR: "YEAR",
    DAY: "DAY",
    TODAY: "TODAY",
    YESTERDAY: "YESTERDAY",
    LAST_7_DAYS: "LAST_7_DAYS",
    LAST_30_DAYS: "LAST_30_DAYS",
    LAST_3_MONTHS: "LAST_3_MONTHS",
    LAST_6_MONTHS: "LAST_6_MONTHS",
    LAST_12_MONTHS: "LAST_12_MONTHS",
};

const initialState = {
    selectedTimeInterval: TIME_INTERVAL.LAST_7_DAYS,
    startDate: "",
    endDate: "",
    selectedMixpanelEvent: ANALYTICS_EU_USAGE_GROUP,
    selectedKioskMixpanelEvent: ANALYTICS_EU_USAGE_GROUP,
    error: false,
    errorMessage: "",
    kioskSessionsScreens: [],
    totalEngagementScreens: [],
    networkError: false,
    fetchMixpanelEngagementStatus: "idle",
    fetchAnalyticsForBoardStatus: "idle",
    fetchAnalyticsForSavedContentStatus: "idle",
    fetchBoardMembersStatus: "idle",
    engagementData: null,
    savedContentData: null,
    analyticsData: null,
    boardMembers: [],
    engagementUnitsUsage: {
        apiStatus: "idle",
        screensUsage: 0,
        webMobileUsage: 0, // Engagement units used in web and mobile
        webMobileAvailableUnits: 0, // Total available EUs limit from the pricing details for the board.
        webMobileUsagePercentage: 0,
    },
    showCustomIntervalMenu: false,
    selectedDeviceEngagement: "All",
    customStartDate: "",
    customEndDate: "",

    fetchHootEngagementStatus: "idle",
    fetchAppEngagementStatus: "idle",
    hootEngagementData: [],
    appEngagementData: [],
    fetchActivityChannelsStatus: "idle",
    activityChannels: [],
};

export const getEngagementUnitUsage = ({ boardId, engagementUnits, cycleResetDay }) => {
    return {
        type: Actions.getEngagementUnitsUsage + "request",
        payload: { boardId, engagementUnits, cycleResetDay },
    };
};

export const fetchMixpanelEngagement = (payload) => {
    return {
        type: "mixpanel/engagement/request",
        payload: payload,
    };
};

export const fetchHootEngagement = (payload) => {
    return {
        type: Actions.getHootEngagementUnitsUsage + "request",
        payload: payload,
    };
};

export const fetchAppEngagement = (payload) => {
    return {
        type: Actions.getAppEngagementUnitsUsage + "request",
        payload: payload,
    };
};

export const fetchActivityChannels = (payload) => {
    return {
        type: Actions.getActivityChannels + "request",
        payload: payload,
    };
};

export const fetchAnalyticsForBoard = (payload) => {
    return {
        type: "board/analytics/request",
        payload: payload,
    };
};

export const fetchAnalyticsForSavedContent = (payload) => {
    return {
        type: "board/analytics/saved/request",
        payload: payload,
    };
};

export const fetchBoardMembers = (payload) => {
    return {
        type: "board/members/request",
        payload: payload,
    };
};

const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        updateSelectedScreens: (state, action) => {
            const { totalEngagementScreens, kioskSessionsScreens } = action.payload;

            if (totalEngagementScreens) {
                return {
                    ...state,
                    totalEngagementScreens,
                };
            }

            if (kioskSessionsScreens) {
                return {
                    ...state,
                    kioskSessionsScreens,
                };
            }
        },
        intervalSelected: (state, action) => {
            return {
                ...state,
                selectedTimeInterval: action.payload.selectedTimeInterval,
                customStartDate: action.payload.customStartDate,
                customEndDate: action.payload.customEndDate,
            };
        },
        resetEngagementUnitsUsage: (state) => {
            state.engagementUnitsUsage = initialState.engagementUnitsUsage;
        },
        mixpanelEventSelected: (state, action) => {
            return {
                ...state,
                selectedMixpanelEvent: action.payload.selectedMixpanelEvent,
                fetchMixpanelEngagementStatus: "pending",
                engagementData: null,
            };
        },
        hideCustomMenu: (state) => {
            return {
                ...state,
                showCustomIntervalMenu: false,
            };
        },
        showCustomMenu: (state) => {
            return {
                ...state,
                showCustomIntervalMenu: true,
            };
        },
        updateSelectedDeviceEngagement: (state, action) => {
            return {
                ...state,
                selectedDeviceEngagement: action.payload.selectedDeviceEngagement,
            };
        },
        /**
         * We are resetting the previous selected values from board to
         * board, those values are selected time interval and device channel
         * to the original state as we want to show these values by default
         */
        resetSelectedValues: (state) => {
            return {
                ...state,
                selectedDeviceEngagement: initialState.selectedDeviceEngagement,
                selectedTimeInterval: initialState.selectedTimeInterval,
                hootEngagementData: [],
                appEngagementData: [],
                engagementData: [],
            };
        },
    },
    extraReducers: {
        [Actions.getEngagementUnitsUsage + "request"]: (state, action) => {
            // Adding this to the request because this information is only available in board details API.
            const { engagementUnits } = action.payload;
            state.engagementUnitsUsage.webMobileAvailableUnits = engagementUnits;
        },
        [Actions.getEngagementUnitsUsage + "pending"]: (state) => {
            state.engagementUnitsUsage.apiStatus = "pending";
        },
        [Actions.getEngagementUnitsUsage + "fulfilled"]: (state, action) => {
            const { webMobileUsage, kioskUsage } = action.payload;
            const webMobileAvailableUnits = state.engagementUnitsUsage.webMobileAvailableUnits;

            state.engagementUnitsUsage.apiStatus = "fulfilled";
            state.engagementUnitsUsage.screensUsage = kioskUsage;
            state.engagementUnitsUsage.webMobileUsage = webMobileUsage;

            // Calculating the usage percentage based on the available units and used units.
            const webMobileUsagePercentage =
                Math.round((webMobileUsage / webMobileAvailableUnits) * 100 * 100) / 100;
            state.engagementUnitsUsage.webMobileUsagePercentage = webMobileUsagePercentage;
        },
        [Actions.getEngagementUnitsUsage + "rejected"]: (state) => {
            state.engagementUnitsUsage.apiStatus = "rejected";
        },
        "mixpanel/engagement/pending": (state) => {
            return {
                ...state,
                fetchMixpanelEngagementStatus: "pending",
            };
        },
        "mixpanel/engagement/fulfilled": (state, action) => {
            return {
                ...state,
                fetchMixpanelEngagementStatus: "fulfilled",
                engagementData: action.payload.data.filter((item) => item.label != null),
            };
        },
        "mixpanel/engagement/rejected": (state) => {
            return {
                ...state,
                fetchMixpanelEngagementStatus: "rejected",
                engagementData: null,
            };
        },
        "board/analytics/pending": (state) => {
            return {
                ...state,
                fetchAnalyticsForBoardStatus: "pending",
            };
        },
        "board/analytics/fulfilled": (state, action) => {
            return {
                ...state,
                fetchAnalyticsForBoardStatus: "fulfilled",
                analyticsData: action.payload.data,
            };
        },
        "board/analytics/rejected": (state) => {
            return {
                ...state,
                fetchAnalyticsForBoardStatus: "rejected",
                analyticsData: null,
            };
        },
        "board/analytics/saved/pending": (state) => {
            return {
                ...state,
                fetchAnalyticsForSavedContentStatus: "pending",
            };
        },
        "board/analytics/saved/fulfilled": (state, action) => {
            return {
                ...state,
                fetchAnalyticsForSavedContentStatus: "fulfilled",
                savedContentData: action.payload.data,
            };
        },
        "board/analytics/saved/rejected": (state) => {
            return {
                ...state,
                fetchAnalyticsForSavedContentStatus: "rejected",
                savedContentData: null,
            };
        },
        "board/members/pending": (state) => {
            return {
                ...state,
                fetchBoardMembersStatus: "pending",
            };
        },
        "board/members/fulfilled": (state, action) => {
            return {
                ...state,
                fetchBoardMembersStatus: "fulfilled",
                boardMembers: action.payload.data,
            };
        },
        "board/members/rejected": (state) => {
            return {
                ...state,
                fetchBoardMembersStatus: "rejected",
            };
        },
        [Actions.getHootEngagementUnitsUsage + "pending"]: (state) => {
            return {
                ...state,
                fetchHootEngagementStatus: "pending",
            };
        },
        [Actions.getHootEngagementUnitsUsage + "fulfilled"]: (state, action) => {
            return {
                ...state,
                fetchHootEngagementStatus: "fulfilled",
                hootEngagementData: action.payload.data
                    .filter((item) => item.label != null)
                    .sort((a, b) => b.count - a.count),
            };
        },
        [Actions.getHootEngagementUnitsUsage + "rejected"]: (state) => {
            return {
                ...state,
                fetchHootEngagementStatus: "rejected",
            };
        },
        [Actions.getAppEngagementUnitsUsage + "pending"]: (state) => {
            return {
                ...state,
                fetchAppEngagementStatus: "pending",
            };
        },
        [Actions.getAppEngagementUnitsUsage + "fulfilled"]: (state, action) => {
            return {
                ...state,
                fetchAppEngagementStatus: "fulfilled",
                appEngagementData: action.payload.data
                    .filter((item) => item.label != null)
                    .sort((a, b) => b.count - a.count),
            };
        },
        [Actions.getAppEngagementUnitsUsage + "rejected"]: (state) => {
            return {
                ...state,
                fetchAppEngagementStatus: "rejected",
            };
        },
        [Actions.getActivityChannels + "pending"]: (state) => {
            return {
                ...state,
                fetchActivityChannelsStatus: "pending",
            };
        },
        [Actions.getActivityChannels + "fulfilled"]: (state, action) => {
            return {
                ...state,
                fetchActivityChannelsStatus: "fulfilled",
                activityChannels: action.payload.data.events,
            };
        },
        [Actions.getActivityChannels + "rejected"]: (state) => {
            return {
                ...state,
                fetchActivityChannelsStatus: "rejected",
            };
        },
    },
});
export const {
    intervalSelected,
    updateSelectedScreens,
    resetEngagementUnitsUsage,
    mixpanelEventSelected,
    showCustomMenu,
    hideCustomMenu,
    updateSelectedDeviceEngagement,
    resetSelectedValues,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
