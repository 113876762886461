import { call, put, takeLatest } from "redux-saga/effects";
import * as NotificationApi from "../../api/notifications.service";

export const Actions = {
	getNotifications: "notifications/get/",
	readNotifications: "notifications/read/",
};

function* getNotifications(action) {
	const base = Actions.getNotifications;
	try {
		yield put({ type: base + "pending", payload: {} });
		const notificationsApiResponse = yield call(
			async () =>
				await NotificationApi.getNotifications(
					action.payload.token,
					action.payload.currentPage
				)
		);
		if (notificationsApiResponse && notificationsApiResponse.success) {
			yield put({
				type: base + "fulfilled",
				payload: {
					pageNumber: action.payload.currentPage,
					notifications: notificationsApiResponse.notifications,
				},
			});
		} else {
			yield put({
				type: base + "rejected",
				payload: { notifications: [] },
			});
		}
	} catch (e) {
		yield put({ type: base + "rejected", payload: {} });
	}
}

function* readNotifications(action) {
	const base = Actions.readNotifications;
	try {
		yield put({ type: base + "pending", payload: {} });
		const notificationsApiResponse = yield call(
			async () => await NotificationApi.readNotifications(action.payload)
		);
		if (notificationsApiResponse && notificationsApiResponse.success) {
			yield put({
				type: base + "fulfilled",
				payload: {
					notifications: notificationsApiResponse.notifications,
				},
			});
		} else {
			yield put({
				type: base + "rejected",
				payload: { notifications: [] },
			});
		}
	} catch (error) {
		yield put({ type: base + "rejected", payload: {} });
	}
}

export function* getNotificationsSaga() {
	yield takeLatest(Actions.getNotifications + "request", getNotifications);
}

export function* readNotificationsSaga() {
	yield takeLatest(Actions.readNotifications + "request", readNotifications);
}
