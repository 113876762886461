/*
 * @Author: Chandu J S
 * @Date:   2020-08-07 14:41:16
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-08-07 14:41:17
 */
import { httpPostV2 } from "./http-wrapper";

const resetAccountPassword = async (params) => {
	const response = await httpPostV2("password/create", params, null);
	return response;
};

export { resetAccountPassword };
