import { APIMethods } from "../types";
import {
	ListData,
	AddItemToListActionPayload,
	RemoveItemFromListActionPayload,
	GetListPayload,
	UpdateUserAssignedDataPayload,
	UpdateListPayload,
} from "../types/lists";
import { apiRequest } from "./http-wrapper";

/**
 * Api call to create a new list.
 */
export const createNewList = async (payload: ListData) => {
	return apiRequest("lists", {
		method: APIMethods.POST,
		payload,
	});
};

/**
 * Api call to add an item to the list
 */
export const addToList = async (payload: AddItemToListActionPayload) => {
	return apiRequest(`lists/${payload.listId}/items`, {
		method: APIMethods.POST,
		payload: [payload.data],
	});
};

/**
 * Api call to remove an item from the list
 */
export const removeFromList = async (
	payload: RemoveItemFromListActionPayload
) => {
	return apiRequest(`lists/${payload.listId}/items/${payload.itemId}`, {
		method: APIMethods.DELETE,
	});
};

/**
 * Api to fetch all the lists on page reload
 * @returns all lists
 */
export const getAllLists = async () => {
	return apiRequest("lists");
};

/**
 * Api to fetch single List
 * @returns particular list respect to the listUid and token
 */
export const getList = async (payload: GetListPayload) => {
	return apiRequest(
		`lists/${payload.listUid}?listToken=${payload.listToken}`
	);
};

/**
 * Api to fetch single List
 * @returns particular list respect to the listId
 */
export const getListById = async (payload: number | string) => {
	return apiRequest(`lists/${payload}`);
};

/**
 * Api to save current list
 */
export const saveList = async (payload: string) => {
	return apiRequest(`lists/save?listToken=${payload}`, {
		method: APIMethods.POST,
	});
};
/**
 * Update list item details
 */
export const updateListItem = (payload: UpdateUserAssignedDataPayload) => {
	return apiRequest(`lists/${payload.listId}/items/${payload.itemId}`, {
		method: APIMethods.PATCH,
		payload: payload.data,
	});
};
/**
 * Update list  details
 */
export const updateList = (payload: UpdateListPayload) => {
	return apiRequest(`lists/${payload.listId}`, {
		method: APIMethods.PUT,
		payload: payload.data,
	});
};
/**
 * Delete Active list
 */
export const deleteList = (listId: number) => {
	return apiRequest(`lists/${listId}`, {
		method: APIMethods.DELETE,
	});
};

/**
 * Get Share List Token
 */
export const getShareToken = (listId: number) => {
	return apiRequest(`lists/${listId}/share`, {
		method: APIMethods.POST,
	})
}
/**
 * Api to fetch single List
 * @returns particular list respect to the share token
 */
export const getListByToken = async (payload: string) => {
	return apiRequest(`lists/${payload}`);
};
