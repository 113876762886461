/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-26 08:56:05
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-05-26 10:39:03
 */

import { call, put, takeLatest } from "redux-saga/effects";
import * as BoardApi from "../../api/boards.service";

function* getBoardNotificationSettings(action) {
	try {
		yield put({ type: "board/notifications/get/pending", payload: {} });
		const notificationSettings = yield call(
			async () =>
				await BoardApi.getBoardNotifications(action.payload.boardId)
		);
		if (notificationSettings.status !== "error") {
			yield put({
				type: "board/notifications/get/fulfilled",
				payload: { notificationSettings: notificationSettings.data },
			});
		} else {
			yield put({
				type: "board/notifications/get/rejected",
				payload: { notificationSettings: null },
			});
		}
	} catch (e) {
		yield put({
			type: "board/notifications/get/rejected",
			payload: { notificationSettings: null },
		});
	}
}

export function* getBoardNotificationSettingsSaga() {
	yield takeLatest(
		"board/notifications/get/request",
		getBoardNotificationSettings
	);
}

function* updateBoardNotificationSettings(action) {
	try {
		yield put({ type: "board/notifications/update/pending", payload: {} });
		const updateNotificationSettings = yield call(
			async () =>
				await BoardApi.saveBoardNotifications(
					action.payload.boardId,
					action.payload.params
				)
		);
		if (updateNotificationSettings.status !== "error") {
			const notificationSettings = yield call(
				async () =>
					await BoardApi.getBoardNotifications(action.payload.boardId)
			);
			if (notificationSettings.status !== "error") {
				yield put({
					type: "board/notifications/update/fulfilled",
					payload: { notificationSettings: notificationSettings.data },
				});
			} else {
				yield put({
					type: "board/notifications/update/rejected",
					payload: { notificationSettings: null },
				});
			}
		} else {
			yield put({
				type: "board/notifications/update/rejected",
				payload: { notificationSettings: null },
			});
		}
	} catch (e) {
		yield put({
			type: "board/notifications/update/rejected",
			payload: { notificationSettings: null },
		});
	}
}

export function* updateBoardNotificationSettingsSaga() {
	yield takeLatest(
		"board/notifications/update/request",
		updateBoardNotificationSettings
	);
}
