/*
 * @Author: Chandu J S
 * @Date:   2020-08-07 14:44:31
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-08-07 14:55:13
 */
import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "../../../api/password-reset.service";

function* publicResetPassword(action) {
	try {
		yield put({ type: "password-reset/pending" });

		const { encPartyId, password } = action.payload;

		const apiResponse = yield call(
			async () => await API.resetAccountPassword({ encPartyId, password })
		);
		if (!apiResponse) throw new Error(apiResponse);
		if (!apiResponse.newPasswordStatus) throw new Error(apiResponse);

		yield put({ type: "password-reset/fulfilled" });
	} catch (e) {
		yield put({ type: "password-reset/rejected", payload: e });
	}
}

export function* publicResetPasswordSaga() {
	yield takeLatest("password-reset/request", publicResetPassword);
}
