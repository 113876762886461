import { call, put, select, takeLatest } from "redux-saga/effects";
//
import { URL_SHORTENER_URL } from "../../helper/constants";
//
import * as API from "../../api/sharing.service";
//
import { analyticsClient } from "../../contexts/mixpanel.context";
import { ActionState, ErrorCodes } from "../../types";

export const Actions = {
	getUrlShortCode: "sharing/get-url-short-code/",
	sendMessage: "sharing/send-message/",
	shareViaEmails: "sharing/share-via-emails",
} as const;

const getUserId = (state: any) =>
	state.activeAccount.activeAccountDetails?.partyId;

const getUserEmail = (state: any) =>
	state.activeAccount.activeAccountDetails?.email;

function* getUrlShortCodeSaga() {
	yield takeLatest(
		Actions.getUrlShortCode + ActionState.REQUEST,
		function* (action: any): any {
			try {
				yield put({
					type: Actions.getUrlShortCode + ActionState.PENDING,
					payload: action.payload,
				});
				const data = yield call(() =>
					API.getUrlShortCode(action.payload)
				);
				if (!data) throw new Error();
				if (!data.success) throw new Error(data.errorMessage);

				yield put({
					type: Actions.getUrlShortCode + ActionState.FULFILLED,
					payload: `${URL_SHORTENER_URL}/${data.data.shortUrl}`,
				});
			} catch (e: any) {
				yield put({
					type: Actions.getUrlShortCode + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* sendMessageSaga() {
	yield takeLatest(
		Actions.sendMessage + ActionState.REQUEST,
		function* (action: any): any {
			try {
				yield put({
					type: Actions.sendMessage + ActionState.PENDING,
				});
				const data = yield call(() => API.sendMessage(action.payload));

				if (!data) throw new Error();
				if (!data.success) throw new Error(data.errorMessage);

				yield put({
					type: Actions.sendMessage + ActionState.FULFILLED,
				});
			} catch (e: any) {
				yield put({
					type: Actions.sendMessage + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

function* shareViaEmailsSaga() {
	yield takeLatest(
		Actions.shareViaEmails + ActionState.REQUEST,
		function* (action: any): any {
			try {
				yield put({
					type: Actions.shareViaEmails + ActionState.PENDING,
				});
				const userId = yield select(getUserId);
				const userEmail = yield select(getUserEmail);
				const data = yield call(async () => {
					const distinctId = await analyticsClient.getDistinctID();
					return API.shareViaEmails({
						...action.payload,
						distinctId,
						userId,
						userEmail,
					});
				});

				if (!data) throw new Error();
				if (!data.success) throw new Error(data.errorMessage);

				yield put({
					type: Actions.shareViaEmails + ActionState.FULFILLED,
				});
			} catch (e: any) {
				yield put({
					type: Actions.shareViaEmails + ActionState.REJECTED,
					payload: e?.message || ErrorCodes.GENERIC,
				});
			}
		}
	);
}

export const sharingSagas = [
	getUrlShortCodeSaga(),
	sendMessageSaga(),
	shareViaEmailsSaga(),
];
