/*
 * @Author: Chandu J S
 * @Date:   2020-08-12 10:30:02
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-08-12 10:30:02
 */
import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "../../api/hoots.service.alt";

function* hootsFromAllBoards(action) {
	try {
		yield put({
			type: "boards/all/hoots/pending",
			payload: {},
		});
		const allBoardHootsApiResponse = yield call(
			async () => await API.getAllBoardHoots(action.payload.params)
		);
		if (
			allBoardHootsApiResponse &&
			allBoardHootsApiResponse.status !== "error"
		) {
			yield put({
				type: "boards/all/hoots/fulfilled",
				payload: {
					hoots: allBoardHootsApiResponse,
				},
			});
		} else {
			yield put({
				type: "boards/all/hoots/rejected",
				payload: {
					hoots: [],
				},
			});
		}
	} catch (e) {
		yield put({
			type: "boards/all/hoots/rejected",
			payload: {},
		});
	}
}
export function* hootsFromAllBoardsSaga() {
	yield takeLatest("boards/all/hoots/request", hootsFromAllBoards);
}

function* moreHootsFromAllBoards(action) {
	try {
		yield put({
			type: "boards/all/hoots/more/pending",
			payload: {},
		});
		const allBoardHootsApiResponse = yield call(
			async () => await API.getAllBoardHoots(action.payload.params)
		);
		if (
			allBoardHootsApiResponse &&
			allBoardHootsApiResponse.status !== "error"
		) {
			yield put({
				type: "boards/all/hoots/more/fulfilled",
				payload: {
					hoots: allBoardHootsApiResponse,
				},
			});
		} else {
			yield put({
				type: "boards/all/hoots/more/rejected",
				payload: {
					hoots: [],
				},
			});
		}
	} catch (e) {
		yield put({
			type: "boards/all/hoots/more/rejected",
			payload: {},
		});
	}
}
export function* moreHootsFromAllBoardsSaga() {
	yield takeLatest("boards/all/hoots/more/request", moreHootsFromAllBoards);
}
