import { Constants, apiRequest, httpGetV2 } from "./http-wrapper";

export const getDefaultSequences = async (boardId) => {
    return apiRequest("idlestate/content/sequences/default", {
        extraHeaders: {
            [Constants.BOARD_ID_HEADER]: boardId,
        },
    });
};

export const getAllSequences = async (boardId) => {
    return apiRequest("idlestate/content/sequences", {
        extraHeaders: {
            [Constants.BOARD_ID_HEADER]: boardId,
        },
    });
};

export const getSequence = async (params) => {
    return apiRequest("idlestate/content/sequences/" + params.id, {
        payload: params.payload,
        extraHeaders: {
            [Constants.BOARD_ID_HEADER]: params.boardId,
        },
    });
};

export const createSequence = async (params) => {
    return apiRequest("idlestate/content/sequences", {
        method: "POST",
        payload: params.payload,
        extraHeaders: {
            [Constants.BOARD_ID_HEADER]: params.boardId,
        },
    });
};

export const deleteSequence = async (params) => {
    return apiRequest("idlestate/content/sequences/" + params.id, {
        method: "DELETE",
        extraHeaders: {
            [Constants.BOARD_ID_HEADER]: params.boardId,
        },
    });
};

export const saveSequence = async (params) => {
    return apiRequest("idlestate/content/sequences/" + params.payload.id, {
        method: "PUT",
        payload: params.payload,
        extraHeaders: {
            [Constants.BOARD_ID_HEADER]: params.boardId,
        },
    });
};

export const assignSequence = async (params) => {
    return apiRequest("idlestate/content/schedules", {
        method: "POST",
        payload: params.payload,
        extraHeaders: {
            [Constants.BOARD_ID_HEADER]: params.boardId,
        },
    });
};

export const updateSchedule = async (params) => {
    return apiRequest("idlestate/content/schedules/" + params.data.id, {
        method: "PATCH",
        payload: params.data,
        extraHeaders: {
            [Constants.BOARD_ID_HEADER]: params.boardId,
        },
    });
};

export const unAssignSequence = async (params) => {
    return apiRequest("idlestate/content/schedules/" + params.id, {
        method: "DELETE",
        extraHeaders: {
            [Constants.BOARD_ID_HEADER]: params.boardId,
        },
    });
};

export const getAvailability = async (params) => {
    return apiRequest("idlestate/content/schedules/availability", {
        payload: params.payload,
        extraHeaders: {
            [Constants.BOARD_ID_HEADER]: params.boardId,
        },
    });
};

export const markAsDefault = async (params) => {
    return apiRequest(`idlestate/content/sequences/${params.id}/default`, {
        method: "PATCH",
        extraHeaders: {
            [Constants.BOARD_ID_HEADER]: params.boardId,
        },
    });
};

export const loadPreviewContent = async ({ id, type, zoneId, isSaved }) => {
    /**
     * isSaved - if true means the slot we are editing it was already assignedContent few moments before
     * and now we are re-editing its content so we dont need to make a network request we are
     * already getting the content data, now we just need to return it to the reducer
     * so that it updates the slotLayout.selectedLayout.assignedContent property and the user
     * can view the content
     */
    const customType = () => {
        let customType = "";
        switch (type) {
            case "appId":
                customType = "appId";
                break;
            case "hootId":
                customType = "hootId";
                break;
            case "image":
            case "imageUrl":
                customType = "image";
                break;
            case "url":
                customType = "url";
                break;
            default:
                break;
        }
        return customType;
    };
    if (isSaved) {
        const response = {
            data: id,
        };
        return { response, type: customType(), zoneId };
    }
    if (type === "appId") {
        const response = await apiRequest(`apps/${id}`);
        return { response, type: customType(), zoneId };
    }
    if (type === "image" || type == "imageUrl") {
        const response = { data: id, message: customType() };
        return { response, type: customType(), zoneId };
    }
    if (type === "url") {
        const response = { data: id, message: customType() };
        return { response, type: customType(), zoneId };
    }
    if (type === "hootId") {
        const response = await httpGetV2("private/hoot", { postId: id }, { "x-board-id": 0 });
        return { response, type: customType(), zoneId };
    }

    return null;
};

export const fetchAvailableLayouts = async () => {
    return apiRequest("idlestate/content/sequences/layouts");
};
