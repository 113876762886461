/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-23 23:07:19
 * @Last Modified by: sridhar katta
 * @Last Modified time: 2021-12-13 11:44:44
 */

import { createSlice } from "@reduxjs/toolkit";
import emailValidator from "email-validator";
import * as AuthViewConstants from "./authViews.constants";
import { isEmpty } from "../../utils/formValidationUtils";

const userAccountStatuses = {
	UNKOWN: "UNKOWN",
	ACTIVE: "ACTIVE",
	ACTIVATION_PENDING: "ACTIVATION_PENDING",
	INVALID: "INVALID",
};

const initialState = {
	isProspect: false,
	isUserLoggedIn: false,
	sessionExpiredErrorMessage:
		"Your session is either expired or invalid. Please login again.",
	loginEmail: "",
	password: "",
	passwordRepeat: "",
	shouldKeepUserLoggedIn: false,
	resetPasswordEmail: "",
	resetPasswordEmailError: false,
	resetPasswordEmailErrorMessage: "Please provide a valid email",
	loginEmailError: false,
	loginEmailErrorMessage: "Please provide a valid email",
	passwordError: false,
	passwordErrorMessage: "Need a valid password",
	passwordRepeatError: false,
	passwordRepeatErrorMessage: "Passwords do not match",
	firstName: "",
	firstNameError: false,
	firstNameErrorMessage: "Need valid first & last names",
	lastName: "",
	lastNameError: false,
	lastNameErrorMessage: "Need a valid lastname",
	userAccountStatus: userAccountStatuses["UNKOWN"],
	showAllFormErrors: false,
	activationEmail: "",
	activationEmailError: false,
	activationEmailErrorMessage: "",
	loginStatus: "idle",
	setNewPasswordStatus: "idle",
	currentlySelectedView: AuthViewConstants.LOGIN,
	loginError: false,
	loginErrorMessage: "Incorrect email or password",
	activeError: false,
	activeErrorMessage: "You have not validated this email address.",
	activationRequestError: false,
	activationRequestLoading: false,
	activationRequestFinished: false,
	networkError: false,
	networkErrorMessage: "Could not connect to your servers. Try again",
	emailValidationStatus: "idle",
	emailValidationError: false,
	emailValidationErrorMessage: "Sorry the user already exists in our system",
	facebookLoginFailedErrorMessage: null,
	facebookAuthStatus: "idle",
	socialLoginFailedErrorMessage: null,
	socialAuthStatus: "idle",
	loginSource: "HB",
};

export const resendActivationEmailAsync = (payload) => {
	return {
		type: "auth/activation/email/resend/request",
		payload: payload,
	};
};

export const signupAndFollowAsync = (payload) => {
	return {
		type: "auth/signup/board/follow/request",
		payload: payload,
	};
};

export const signupAsync = (payload) => {
	return {
		type: "auth/signup/request",
		payload: payload,
	};
};

export const resetPasswordRequestAsync = (payload) => {
	return {
		type: "auth/resetPassword/request",
		payload: payload,
	};
};

export const setNewPasswordRequestAsync = (payload) => {
	return {
		type: "auth/password/new/request",
		payload: payload,
	};
};

export const loginRequestAsync = (payload) => {
	return {
		type: "auth/login/request",
		payload: payload,
	};
};

export const validateEmailAsync = (payload) => {
	return {
		type: "auth/email/validate/request",
		payload,
	};
};

export const facebookLoginRequest = (payload) => {
	return {
		type: "auth/facebookLogin/request",
		payload,
	};
};

export const socialLoginRequest = (payload) => {
	return {
		type: "auth/socialLogin/request",
		payload,
	};
};

const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		toggleFbProgress: (state, action) => {
			state.facebookLoginFailedErrorMessage = null;
			state.facebookAuthStatus = action.payload;
		},
		facebookLoginFailed: (state, action) => {
			state.facebookLoginFailedErrorMessage = action.payload
				? "There's been a problem with authenticating your Facebook account. Please try again later."
				: null;
		},
		resetPasswordEmailEntered: (state, action) => {
			return {
				...state,
				resetPasswordEmail: action.payload.resetPasswordEmail,
				resetPasswordEmailError: !emailValidator.validate(
					action.payload.resetPasswordEmail
				),
				loginError: false,
				activeError: false,
				showAllFormErrors: false,
			};
		},
		resetAuthView: (state) => {
			return {
				...initialState,
				isUserLoggedIn: state.isUserLoggedIn,
			};
		},
		showSignupView: () => {
			return {
				...initialState,
				currentlySelectedView: AuthViewConstants.SIGNUP,
			};
		},
		showLoginView: () => {
			return {
				...initialState,
				currentlySelectedView: AuthViewConstants.LOGIN,
			};
		},
		showResetPasswordView: () => {
			return {
				...initialState,
				currentlySelectedView: AuthViewConstants.RESET_PASSWORD,
			};
		},
		showRequestResetPasswordView: () => {
			return {
				...initialState,
				currentlySelectedView: AuthViewConstants.REQUEST_PASSWORD_RESET,
			};
		},
		showResendActivationEmailView: () => {
			return {
				...initialState,
				currentlySelectedView: AuthViewConstants.RESEND_ACTIVATION,
			};
		},
		activationEmailEntered: (state, action) => {
			return {
				...state,
				activationEmail: action.payload.activationEmail,
				activationEmailError: !emailValidator.validate(
					action.payload.activationEmail
				),
				activationEmailErrorMessage: "Please enter a valid email",
			};
		},
		loginEmailEntered: (state, action) => {
			return {
				...state,
				loginEmail: action.payload.loginEmail,
				loginEmailError: !emailValidator.validate(
					action.payload.loginEmail
				),
				loginError: false,
				activeError: false,
				showAllFormErrors: false,
			};
		},
		passwordEntered: (state, action) => {
			return {
				...state,
				password: action.payload.password,
				passwordError: isEmpty([action.payload.password])
					? true
					: false,
				loginError: false,
				activeError: false,
				showAllFormErrors: false,
			};
		},
		showFormErrors: (state, action) => {
			return {
				...state,
				showAllFormErrors: true,
				loginEmailError: action.payload.loginEmailError,
				loginEmailErrorMessage: action.payload.loginEmailErrorMessage,
			};
		},
		resetPasswordForm: (state) => {
			return {
				...state,
				resetPasswordStatus: "idle",
				loginEmail: "",
				showAllFormErrors: false,
				loginEmailError: true,
			};
		},
		firstNameEntered: (state, action) => {
			return {
				...state,
				firstName: action.payload.firstName,
				firstNameError: isEmpty([action.payload.firstName])
					? true
					: false,
			};
		},
		lastNameEntered: (state, action) => {
			return {
				...state,
				lastName: action.payload.lastName,
				lastNameError: isEmpty([action.payload.lastName])
					? true
					: false,
			};
		},
		passwordRepeatEntered: (state, action) => {
			return {
				...state,
				passwordRepeat: action.payload.passwordRepeat,
				passwordRepeatError:
					action.payload.passwordRepeat === state.password
						? false
						: true,
			};
		},
		logUserOut: () => {
			return initialState;
		},
		logUserIn: (state) => {
			return {
				...state,
				isUserLoggedIn: true,
			};
		},
		setCurrentlySelectedView: (state, action) => {
			return {
				...state,
				currentlySelectedView: action.payload.view,
			};
		},
		showValidationErrors: (state) => {
			return {
				...state,
				showAllFormErrors: true,
			};
		},
		socialLoginFailed: (state, action) => {
			return {
				...state,
				socialAuthStatus: "idle",
				socialLoginFailedErrorMessage: `There's been a problem with authenticating your ${action.payload.loginSource} login. Please try again later.`,
			};
		},
		socialLoginProgress: (state, action) => {
			return {
				...state,
				socialAuthStatus: action.payload.socialAuthStatus,
			};
		},
	},
	extraReducers: {
		"auth/activation/email/resend/pending": (state) => {
			return {
				...state,
				activationRequestError: false,
				activationRequestLoading: true,
				activationRequestFinished: false,
			};
		},
		"auth/activation/email/resend/fulfilled": (state) => {
			return {
				...state,
				activationRequestError: false,
				activationRequestLoading: false,
				activationRequestFinished: true,
			};
		},
		"auth/activation/email/resend/rejected": (state) => {
			return {
				...state,
				activationRequestError: true,
				activationRequestLoading: false,
				activationRequestFinished: true,
			};
		},
		"auth/resetPassword/pending": (state) => {
			return {
				...state,
				resetPasswordStatus: "pending",
				userAccountStatus: userAccountStatuses["UNKNOWN"],
				showAllFormErrors: false,
			};
		},
		"auth/resetPassword/fulfilled": () => {
			return {
				...initialState,
				resetPasswordStatus: "fulfilled",
				currentlySelectedView: AuthViewConstants.REQUEST_PASSWORD_RESET,
				userAccountStatus: userAccountStatuses["ACTIVE"],
			};
		},
		"auth/resetPassword/rejected": (state) => {
			return {
				...state,
				resetPasswordStatus: "rejected",
				userAccountStatus: userAccountStatuses["INVALID"],
				showAllFormErrors: true,
			};
		},
		"auth/login/fulfilled": (state, action) => {
			//Added by TN: Required for the old site switch issue after login
			/** Remove once the legacy site is no longer running **/

			localStorage.setItem("partyId", action.payload.userDetails.partyId);
			localStorage.setItem(
				"userBoards",
				JSON.stringify(action.payload.userDetails.userBoards)
			);
			/** || **/
			return {
				...initialState,
				loginStatus: "fulfilled",
				isUserLoggedIn: true,
			};
		},
		"auth/login/pending": (state) => {
			return {
				...state,
				loginStatus: "pending",
				activationRequestFinished: false,
			};
		},
		"auth/login/rejected": (state, action) => {
			return {
				...state,
				loginStatus: "rejected",
				activeError: action.payload.activeError,
				loginError: action.payload.loginError,
				networkError: action.payload.networkError,
				loginErrorMessage: "Incorrect email or password",
				activeErrorMessage: "You have not validated this email address",
				networkErrorMessage:
					"Could not connect to your servers. Try again",
			};
		},
		"auth/signup/pending": (state) => {
			return {
				...state,
				signupStatus: "pending",
				showAllFormErrors: false,
			};
		},
		"auth/signup/fulfilled": () => {
			return {
				...initialState,
				isUserLoggedIn: true,
				signupStatus: "fulfilled",
			};
		},
		"auth/signup/rejected": (state) => {
			return {
				...state,
				signupStatus: "rejected",
				showAllFormErrors: true,
			};
		},
		"auth/signup/board/follow/pending": (state) => {
			return {
				...state,
				signupStatus: "pending",
				showAllFormErrors: false,
			};
		},
		"auth/signup/board/follow/fulfilled": () => {
			return {
				...initialState,
				isUserLoggedIn: true,
				signupStatus: "fulfilled",
			};
		},
		"auth/signup/board/follow/rejected": (state) => {
			return {
				...state,
				signupStatus: "rejected",
				showAllFormErrors: true,
			};
		},
		"auth/password/new/fulfilled": () => {
			return {
				...initialState,
				setNewPasswordStatus: "fulfilled",
			};
		},
		"auth/password/new/rejected": (state) => {
			return {
				...state,
				setNewPasswordStatus: "rejected",
			};
		},
		"auth/password/new/pending": (state) => {
			return {
				...state,
				setNewPasswordStatus: "pending",
			};
		},
		"auth/email/validate/pending": (state) => {
			return {
				...state,
				emailValidationStatus: "pending",
			};
		},
		"auth/email/validate/fulfilled": (state, action) => {
			let { isMailIDUnique, partyId } = action.payload.response;

			return {
				...state,
				emailValidationStatus: "fulfilled",
				emailValidationError: !isMailIDUnique,
				isProspect: isMailIDUnique && partyId > 0 ? true : false,
				partyId: partyId,
			};
		},
		"auth/email/validate/rejected": (state) => {
			return {
				...state,
				emailValidationStatus: "rejected",
			};
		},
		"auth/facebookLogin/request": (state) => {
			return {
				...state,
				loginSource: "FB",
			};
		},
		"auth/facebookLogin/pending": (state) => {
			return {
				...state,
				loginStatus: "pending",
				socialAuthStatus: "pending",
				facebookAuthStatus: "pending",
			};
		},
		"auth/facebookLogin/fulfilled": (state) => {
			return {
				...state,
				loginStatus: "fulfilled",
				socialAuthStatus: "fulfilled",
				facebookAuthStatus: "fulfilled",
				// facebookUserDetails: action.payload.response,
				isUserLoggedIn: true,
			};
		},
		"auth/facebookLogin/rejected": (state) => {
			return {
				...state,
				loginStatus: "rejected",
				socialAuthStatus: "rejected",
				facebookAuthStatus: "rejected",
			};
		},
		"auth/socialLogin/request": (state, action) => {
			return {
				...state,
				loginSource: action.payload.loginSource,
				socialLoginFailedErrorMessage: null,
				loginStatus: "idle",
				socialAuthStatus: "idle",
			};
		},
		"auth/socialLogin/pending": (state) => {
			return {
				...state,
				loginStatus: "pending",
				socialAuthStatus: "pending",
			};
		},
		"auth/socialLogin/fulfilled": (state) => {
			return {
				...state,
				loginStatus: "fulfilled",
				socialAuthStatus: "fulfilled",
				isUserLoggedIn: true,
			};
		},
		"auth/socialLogin/rejected": (state) => {
			return {
				...state,
				loginStatus: "rejected",
				socialAuthStatus: "rejected",
			};
		},
	},
});
export const {
	socialLoginProgress,
	socialLoginFailed,
	toggleFbProgress,
	facebookLoginFailed,
	loginEmailEntered,
	showFormErrors,
	passwordEntered,
	resetPasswordForm,
	passwordRepeatEntered,
	firstNameEntered,
	lastNameEntered,
	showLoginView,
	showSignupView,
	showResendActivationEmailView,
	showResetPasswordView,
	showRequestResetPasswordView,
	logUserOut,
	logUserIn,
	activationRequestError,
	activationRequestLoading,
	activationRequestFinished,
	resetAuthView,
	setCurrentlySelectedView,
	showValidationErrors,
	resetPasswordEmailEntered,
} = authSlice.actions;
export default authSlice.reducer;
