import { call, put, takeLatest } from "redux-saga/effects";
import * as BoardsService from "../../api/boards.service";
import { FilestackClient } from "../FileStack.component";

/**
 * If you look at the API calls inside these sagas, you will see that there are PUT APIs and POST APIs.
 * POST APIs are used to create a new item. And PUT is used to update an existing item.
 * In case of business hours, we need to save individual day timings for future reference.
 * So, once the business hours are saved for a board using POST API, the subsequent API call to update
 * the hours should be PUT with businessHoursId as the id.
 * Refer to `generateInactiveHours()` method's documentation for more details.
 * Like business hours, we need to follow the same logic for locations and business details as well.
 */

export const Actions = {
	saveProfile: "board/save-profile/",
	saveCustomize: "board/save-customize/",
	saveShortURL: "board/save-short-url/",
	getCategories: "board/get-categories/",
	saveLocation: "board/save-location/",
	saveContactDetails: "board/save-contact-details/",
	saveBusinessHours: "board/save-hours/",
	savePhotos: "board/save-photos/",
	deletePhoto: "board/delete-photo/",
};

function* saveProfileSaga() {
	yield takeLatest(Actions.saveProfile + "request", function* (action) {
		try {
			yield put({ type: Actions.saveProfile + "pending", payload: {} });

			const { boardId, data } = action.payload;

			// Removing below fields from the board object. API will throw error otherwise.
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { phoneNumber, website, isAdvertisingEnabled, ...board } =
				data;
			const businessDetailsId = board.businessDetails.id;

			const apiResponses = yield call(async () => {
				return await Promise.all([
					BoardsService.manageBoard(boardId, {
						...board,
						boardThemeCustomColors: JSON.stringify(
							board.boardThemeCustomColors || "{}"
						),
						customHootThemeColors: JSON.stringify(
							board.customHootThemeColors || "[]"
						),
					}),
					businessDetailsId
						? // if `id` is available in businessDetails, then call the update API.
						  BoardsService.updateBusinessDetails({
								boardId,
								data: {
									id: businessDetailsId,
									phoneNumber: data.phoneNumber,
									website: data.website,
								},
						  })
						: BoardsService.createBusinessDetails({
								boardId,
								data: {
									phoneNumber: data.phoneNumber,
									website: data.website,
								},
						  }),
				]);
			});

			if (
				apiResponses[0] &&
				apiResponses[1] &&
				//
				apiResponses[0].message &&
				apiResponses[0].message === "success" &&
				//
				apiResponses[1].message &&
				apiResponses[1].message === "success"
			) {
				yield put({
					type: Actions.saveProfile + "fulfilled",
					payload: {},
				});
			} else {
				throw new Error({
					...apiResponses[0],
					...apiResponses[1],
				});
			}
		} catch (error) {
			yield put({
				type: Actions.saveProfile + "rejected",
				payload: error,
			});
		}
	});
}

function* saveCustomizeSaga() {
	yield takeLatest(Actions.saveCustomize + "request", function* (action) {
		try {
			yield put({ type: Actions.saveCustomize + "pending", payload: {} });

			const boardId = action.payload.boardId;

			// Removing below fields from the board object. API will throw error otherwise.
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { isAdvertisingEnabled, ...data } = action.payload.data;

			const apiResponse = yield call(async () => {
				return await BoardsService.manageBoard(boardId, {
					...data,
					customHootThemeColors: JSON.stringify(
						data.customHootThemeColors
					),
					boardThemeCustomColors: JSON.stringify(
						data.boardThemeCustomColors
					),
				});
			});

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: Actions.saveCustomize + "fulfilled",
					payload: {},
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (error) {
			yield put({
				type: Actions.saveCustomize + "rejected",
				payload: error,
			});
		}
	});
}

function* saveShortURLSaga() {
	yield takeLatest(Actions.saveShortURL + "request", function* (action) {
		try {
			yield put({ type: Actions.saveShortURL + "pending", payload: {} });

			const { boardId, data: customURL } = action.payload;

			// check for the availability of custom URL first.
			const urlAvailabilityResponse = yield call(async () => {
				return await BoardsService.checkUrlAvailability(
					boardId,
					customURL
				);
			});

			if ((urlAvailabilityResponse || {}).data) {
				throw new Error({
					reason: "not-available",
				});
			} else {
				// only if the custom URL is available, then call the update API.
				const apiResponse = yield call(async () => {
					return await BoardsService.saveCustomUrl(
						boardId,
						customURL
					);
				});

				if ((apiResponse || {}).customUrlStatus) {
					yield put({
						type: Actions.saveShortURL + "fulfilled",
						payload: {},
					});
				} else {
					throw new Error(apiResponse);
				}
			}
		} catch (error) {
			yield put({
				type: Actions.saveShortURL + "rejected",
				payload: error,
			});
		}
	});
}

function* saveLocationSaga() {
	yield takeLatest(Actions.saveLocation + "request", function* (action) {
		try {
			yield put({ type: Actions.saveLocation + "pending", payload: {} });

			// checking if one of the location items contains `id` or not.
			// in that case, we need to call the update API.
			const hasId = action.payload.data.some((item) => item.id);

			const apiResponse = yield call(async () => {
				if (hasId) {
					return await BoardsService.updateLocations(action.payload);
				} else {
					return await BoardsService.createLocations(action.payload);
				}
			});

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: Actions.saveLocation + "fulfilled",
					payload: {},
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (error) {
			yield put({
				type: Actions.saveLocation + "rejected",
				payload: error,
			});
		}
	});
}

function* saveContactDetailsSaga() {
	yield takeLatest(
		Actions.saveContactDetails + "request",
		function* (action) {
			try {
				yield put({
					type: Actions.saveContactDetails + "pending",
					payload: {},
				});

				const {
					data: { id },
				} = action.payload;

				const apiResponse = yield call(async () => {
					if (id) {
						return await BoardsService.updateBusinessDetails(
							action.payload
						);
					} else {
						return await BoardsService.createBusinessDetails(
							action.payload
						);
					}
				});

				if (
					apiResponse &&
					apiResponse.message &&
					apiResponse.message === "success"
				) {
					yield put({
						type: Actions.saveContactDetails + "fulfilled",
						payload: {},
					});
				} else {
					throw new Error(apiResponse);
				}
			} catch (error) {
				yield put({
					type: Actions.saveContactDetails + "rejected",
					payload: error,
				});
			}
		}
	);
}

function* saveBusinessHoursSaga() {
	yield takeLatest(Actions.saveBusinessHours + "request", function* (action) {
		try {
			yield put({
				type: Actions.saveBusinessHours + "pending",
				payload: {},
			});

			// checking if one of the items contains `businessHoursId` or not.
			// in that case, we need to call the update API.
			const hasId = action.payload.data.some(
				(item) => item.businessHoursId
			);

			const apiResponse = yield call(async () => {
				if (hasId) {
					return await BoardsService.updateBusinessHours(
						action.payload
					);
				} else {
					return await BoardsService.createBusinessHours(
						action.payload
					);
				}
			});

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: Actions.saveBusinessHours + "fulfilled",
					payload: {},
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (error) {
			yield put({
				type: Actions.saveBusinessHours + "rejected",
				payload: error,
			});
		}
	});
}

function* savePhotosSaga() {
	yield takeLatest(Actions.savePhotos + "request", function* (action) {
		try {
			yield put({ type: Actions.savePhotos + "pending", payload: {} });

			// Checking for imported business photos available in the array.
			const importedPhotos = action.payload.data.filter(
				(item) => item.isImported
			);
			// If yes, we need to extract them out of the array
			action.payload.data = action.payload.data.filter(
				(item) => !item.isImported
			);
			// and upload to filestack first.
			const uploadResponses = yield call(async () => {
				return await Promise.all(
					importedPhotos.map((item) =>
						FilestackClient.storeURL(item.url)
					)
				);
			});
			if (!uploadResponses.every((item) => item.url)) {
				throw new Error(uploadResponses);
			}
			// then get the CDN URL and add to the array along with the normal ones.
			action.payload.data = [
				...action.payload.data,
				...uploadResponses.map(({ url }) => ({ url })),
			];

			const apiResponse = yield call(
				async () => await BoardsService.savePhotos(action.payload)
			);

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: Actions.savePhotos + "fulfilled",
					payload: action.payload.data,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (error) {
			yield put({
				type: Actions.savePhotos + "rejected",
				payload: error,
			});
		}
	});
}

function* deletePhotoSaga() {
	yield takeLatest(Actions.deletePhoto + "request", function* (action) {
		try {
			yield put({
				type: Actions.deletePhoto + "pending",
				payload: action.payload,
			});

			const apiResponse = yield call(
				async () => await BoardsService.deletePhoto(action.payload)
			);

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: Actions.deletePhoto + "fulfilled",
					payload: action.payload,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (error) {
			yield put({
				type: Actions.deletePhoto + "rejected",
				payload: error,
			});
		}
	});
}

function* getCategoriesSaga() {
	yield takeLatest(Actions.getCategories + "request", function* (action) {
		try {
			yield put({ type: Actions.getCategories + "pending", payload: {} });

			const apiResponse = yield call(
				async () => await BoardsService.getCategories(action.payload)
			);

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success" &&
				apiResponse.data
			) {
				yield put({
					type: Actions.getCategories + "fulfilled",
					payload: apiResponse.data,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (error) {
			yield put({
				type: Actions.getCategories + "rejected",
				payload: error,
			});
		}
	});
}

export const manageBoardSagas = [
	saveProfileSaga(),
	saveCustomizeSaga(),
	saveShortURLSaga(),
	saveLocationSaga(),
	saveContactDetailsSaga(),
	saveBusinessHoursSaga(),
	savePhotosSaga(),
	deletePhotoSaga(),
	getCategoriesSaga(),
];
