/* eslint-disable react/prop-types */

/* FIXME: Fix lint issues. */
import React, { useEffect } from "react";
import * as Redux from "react-redux";

import HbAnalytics from "hb-analytics-sdk";
import mixpanel from "mixpanel-browser";
import qs from "query-string";

//
import { markEventAsSent, resetMarkedEvents } from "../components/CreateBoard/createBoard.slice";

import { mixpanelBoardDetailsObject } from "../utils/mixpanel.utils";

import { ANALYTICS_SDK_KEY, MIXPANEL_KEY, SERVER_ENV, WEB_VERSION } from "../helper/constants";

const MixpanelContext = React.createContext();
const queryParams = qs.parse(window.location.search);
const { distinctId } = queryParams || {};
const isStageEnv = SERVER_ENV === "stage";

export const analyticsClient = new HbAnalytics();

function MixpanelProvider(props) {
    const dispatch = Redux.useDispatch();

    const partyId = Redux.useSelector((state) => state.activeAccount.activeAccountId);
    const sentMixpanelEvents = Redux.useSelector((state) => state.createBoard.sentMixpanelEvents);
    const loginStatus = Redux.useSelector((state) => state.auth.loginStatus);
    const loginSource = Redux.useSelector((state) => state.auth.loginSource);
    const socialAuthStatus = Redux.useSelector((state) => state.auth.socialAuthStatus);
    const signupStatus = Redux.useSelector((state) => state.auth.signupStatus);
    const userDetails = Redux.useSelector((state) => state.activeAccount.activeAccountDetails);
    const boardDetails = Redux.useSelector((state) => state.activeBoard.boardDetails);
    const createdBoard = Redux.useSelector((state) => state.createBoard.createdBoard);
    //	initialize the mixpanel key
    mixpanel.init(MIXPANEL_KEY, {
        loaded: (mixpanel) => {
            if (distinctId) {
                mixpanel.identify(distinctId);
                if (partyId) {
                    mixpanel.identify(partyId);
                }
            }
        },
        debug: isStageEnv,
        ignore_dnt: true,
    });

    const initAnalyticsSdk = async () => {
        await analyticsClient.init({
            key: ANALYTICS_SDK_KEY,
            env: SERVER_ENV,
        });
        // Due to race conditions we need call the identify after the initializing the sdk
        identifyUser();
    };

    /**
     * If the URL contains distinctId we need to identify user.
     */
    const identifyUser = async () => {
        if (distinctId) {
            const localDistinctId = await analyticsClient.getDistinctID();
            if (localDistinctId) {
                // Here identity function accepts two parameters once is distinctId from current session
                // & second is sessionId which is distinctId from URL
                analyticsClient.identify(localDistinctId, distinctId);
            }
        }
    };

    React.useEffect(() => {
        initAnalyticsSdk();
    }, []);

    useEffect(() => {
        if (partyId) {
            const id = `${userDetails.partyId}`;
            window.oneSignalPush((OneSignal) => {
                OneSignal.login(id).then(() => {
                    mixpanel.people.set("$onesignal_user_id", id);
                    OneSignal.User.addEmail(userDetails.email);
                    OneSignal.User.addAlias("external_id", id);
                });
            });
            mixpanel.people.set("$id", partyId);
        }
    }, [partyId]);

    const boardData = mixpanelBoardDetailsObject(boardDetails || createdBoard);

    React.useEffect(() => {
        /**
         * For login
         */
        if (loginStatus === "fulfilled") {
            // new analytics identify method implementation
            analyticsClient.identify(partyId);

            analyticsClient.saveUser(userDetails.partyId, {
                email: userDetails.email,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
            });

            const currentId = mixpanel.get_distinct_id();
            mixpanel.identify(partyId);
            if (distinctId) {
                mixpanel.alias(currentId);
            }

            mixpanel.register_once({
                $email: userDetails.email,
                $first_name: userDetails.firstName,
                $last_name: userDetails.lastName,
                $id: userDetails.partyId,
                $login_type: userDetails.loginType,
            });

            if (socialAuthStatus === "fulfilled") {
                mixpanel.people.set({
                    $first_name: userDetails.firstName,
                    $last_name: userDetails.lastName,
                    $email: userDetails.email,
                    $login_source: loginSource,
                });
            }

            analyticsTrackWrapper(
                "Logged In",
                includeCommon({
                    locationId: userDetails.locationId,
                    flow: "home",
                    path: window.location.path,
                    $email: userDetails.email,
                }),
            );
        }
        /**
         * For Signup
         */
        if (signupStatus === "fulfilled") {
            analyticsClient.identify(partyId);

            // new analytics identify method implementation

            analyticsClient.saveUser(userDetails.partyId, {
                email: userDetails.email,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
            });

            mixpanel.alias(partyId);

            mixpanel.register_once({
                $email: userDetails.email,
                $first_name: userDetails.firstName,
                $last_name: userDetails.lastName,
                $id: userDetails.partyId,
                $login_type: userDetails.loginType,
            });

            mixpanel.people.set({
                $first_name: userDetails.firstName,
                $last_name: userDetails.lastName,
                $created: new Date(),
                $email: userDetails.email,
                $login_source: "HB",
            });

            analyticsTrackWrapper(
                "Signed Up",
                includeCommon({
                    locationId: userDetails.locationId,
                    $email: userDetails.email,
                    path: window.location.path,
                }),
            );
        }
    }, [loginStatus, signupStatus]);

    const resetSession = () => {
        analyticsClient.reset();
        mixpanel.reset();
    };

    /**
     * This method ensure that the given event is only sent once.
     */
    const sendUnique = (eventName = "", data) => {
        if (!sentMixpanelEvents.includes(eventName)) {
            dispatch(markEventAsSent(eventName));
            analyticsTrackWrapper(eventName, includeCommon(data || {}));
        }
    };

    const includeCommon = (data = {}) => {
        /**
         * To find if the site is loaded on mobile or desktop
         * TODO: Need to check if the site is embeded on both the devices
         */
        let channel = "";

        if (window.screen.width <= 500) {
            channel = "Web Mobile";
        } else {
            channel = "Web Desktop";
        }

        return {
            ...boardData,
            ...(data || {}),
            ...queryParams,
            webVersion: WEB_VERSION,
            channel,
        };
    };

    const analyticsTrackWrapper = (event, params) => {
        mixpanel.track(event, { ...params, trackedByHbSDK: true });
        analyticsClient.track(event, params);
    };

    const trackBoardDeleted = (params) => {
        analyticsTrackWrapper("Board Deleted", includeCommon(params));
    };

    const trackHootDeleted = (params) => {
        analyticsTrackWrapper("Hoot Deleted", includeCommon(params));
    };

    const trackMemberRemoved = (params) => {
        analyticsTrackWrapper("Member Removed", includeCommon(params));
    };

    const trackSubscriptionCancelled = (params) => {
        analyticsTrackWrapper("Subscription Cancelled", includeCommon(params));
    };

    const trackSubscribedToPushNotifications = (params) => {
        analyticsTrackWrapper("Subscribed to Push Notifications", includeCommon(params));
    };

    const trackUnSubscribedFromPushNotifications = (params) => {
        analyticsTrackWrapper("Un-subscribed from Push Notifications", includeCommon(params));
    };

    const trackWebMobileEngagementUnitLimitReached = (params) => {
        analyticsTrackWrapper("Web & Mobile Engagement Unit Limit Reached", includeCommon(params));
    };

    const trackViewedNotificationsSettingsPage = (params) => {
        analyticsTrackWrapper("Viewed Notifications Settings Page", includeCommon(params));
    };

    const trackViewedMyHootsPage = (params) => {
        analyticsTrackWrapper("Viewed My Hoots Page", includeCommon(params));
    };

    const trackViewedMyAccountPage = (params) => {
        analyticsTrackWrapper("Viewed My Account Page", includeCommon(params));
    };

    const trackViewedMyBoardsPage = (params) => {
        analyticsTrackWrapper("Viewed My Boards Page", includeCommon(params));
    };

    const trackViewedAllBoardsPage = (params) => {
        analyticsTrackWrapper("Viewed All Boards Page", includeCommon(params));
    };

    const trackQuickStartGuideItemCompleted = (params) => {
        analyticsTrackWrapper("Quick Start Guide Item Completed", includeCommon(params));
    };

    const trackProductTourStarted = (params) => {
        analyticsTrackWrapper("Product Tour Started", includeCommon(params));
    };

    const trackQuickStartGuideSkipped = (params) => {
        analyticsTrackWrapper("Quick Start Guide Skipped", includeCommon(params));
    };

    const trackProductTourCompleted = (params) => {
        analyticsTrackWrapper("Product Tour Completed", includeCommon(params));
    };

    const trackProductTourSkipped = (params) => {
        analyticsTrackWrapper("Product Tour Skipped", includeCommon(params));
    };

    const trackActivateAccount = (params) => {
        analyticsTrackWrapper("Account Activated", includeCommon(params));
    };

    const trackViewedHootBoardPage = (params) => {
        analyticsTrackWrapper("Viewed HootBoard Page", includeCommon(params));
    };

    const trackViewedHootDisplayPage = (params) => {
        analyticsTrackWrapper("Viewed Hoot Display Page", includeCommon(params));
    };

    const trackHootPosted = (postedHootObject, isEditHoot) => {
        let trackName = "Hoot Posted";
        if (isEditHoot) {
            trackName = "Hoot Edited";
        }
        analyticsTrackWrapper(trackName, includeCommon(postedHootObject));
    };

    const trackInvitationSent = (params) => {
        analyticsTrackWrapper("Invitation Sent", includeCommon(params));
    };

    const trackInvitationAccepted = (params) => {
        analyticsTrackWrapper("Invitation Accepted", includeCommon(params));
    };

    const trackBoardFollowed = (params) => {
        analyticsTrackWrapper("Board Followed", includeCommon(params));
    };

    const trackCollectionCreated = (params) => {
        analyticsTrackWrapper("Collection Created", includeCommon(params));
    };

    const trackBoardEmbedCopied = (params) => {
        analyticsTrackWrapper("Board Embed Copied", includeCommon(params));
    };

    const trackItemShared = (params) => {
        analyticsTrackWrapper("Item Shared", includeCommon(params));
    };

    const trackViewedCalendarPage = (params) => {
        analyticsTrackWrapper("Viewed Calendar Page", includeCommon(params));
    };

    const trackConnectorRuleCreated = (params) => {
        analyticsTrackWrapper("Connector Rule Created", includeCommon(params));
    };

    const trackConnectorAccountCreated = (params) => {
        analyticsTrackWrapper("Connector Account Created", includeCommon(params));
    };

    const trackViewedConnectorPage = (params) => {
        analyticsTrackWrapper("Viewed Connector Page", includeCommon(params));
    };

    const trackViewedCreateBoardPage = () => {
        sendUnique("Viewed Create Board Page", {
            path: window.location.href,
        });
    };

    const trackViewedCreateSubBoardPage = (params) => {
        analyticsTrackWrapper(
            "Viewed Create Sub-Board Page",
            includeCommon({
                ...params,
                path: window.location.href,
            }),
        );
    };

    const trackCreatedBoard = (params) => {
        analyticsTrackWrapper("Created a Board", includeCommon(params));
        analyticsClient.identify(userDetails.partyId);

        mixpanel.people.set({ boardCreator: "true" });
        mixpanel.identify(userDetails.partyId);
    };

    const trackViewedBoardSettingsPage = (params) => {
        analyticsTrackWrapper("Viewed Board Settings Page", includeCommon(params));
    };

    const trackBoardSettingsConfigured = (params) => {
        analyticsTrackWrapper("Board Settings Configured", includeCommon(params));
    };

    const trackSubBoardRemoved = (params) => {
        analyticsTrackWrapper("Sub-Board Removed", includeCommon(params));
    };

    const trackSubBoardParentRemoved = (params) => {
        analyticsTrackWrapper("Sub-Board Parent Removed", includeCommon(params));
    };

    const trackSubBoardAdded = (params) => {
        analyticsTrackWrapper("Sub-Board Added", includeCommon(params));
    };

    const trackInvitationRevoked = (params) => {
        analyticsTrackWrapper("Invitation Revoked", includeCommon(params));
    };

    const trackInvitationResent = (params) => {
        analyticsTrackWrapper("Invitation Resent", includeCommon(params));
    };

    const trackViewedManageScreensPage = (params) => {
        analyticsTrackWrapper("Viewed Manage Screens Page", includeCommon(params));
    };

    const trackViewedKiosksLearnMorePage = (params) => {
        analyticsTrackWrapper("Viewed Kiosks Learn More Page", includeCommon(params));
    };

    const trackDownloadedKioskHardwareDetails = (params) => {
        analyticsTrackWrapper("Downloaded Kiosk Hardware Details", includeCommon(params));
    };

    const trackClickedKioskHardwareBuyButton = (params) => {
        analyticsTrackWrapper("Clicked Kiosk Hardware Buy Button", includeCommon(params));
    };

    const trackViewedScreenSetupPage = (params) => {
        analyticsTrackWrapper("Viewed Screen Setup Page", includeCommon(params));
    };

    const trackScreenLicenseApplied = (params) => {
        analyticsTrackWrapper("Screen License Applied", includeCommon(params));
    };

    const trackScreenLicenseRevoked = (params) => {
        analyticsTrackWrapper("Screen License Revoked", includeCommon(params));
    };

    const trackScreenCreated = (params) => {
        analyticsTrackWrapper("Screen Created", includeCommon(params));
    };

    const trackScreenDeleted = (params) => {
        analyticsTrackWrapper("Screen Deleted", includeCommon(params));
    };

    const trackAppInstalled = (params) => {
        analyticsTrackWrapper("App Installed", includeCommon(params));
    };

    const trackAppCreated = (params) => {
        analyticsTrackWrapper("App Created", includeCommon(params));
    };

    const trackAppRemoved = (params) => {
        analyticsTrackWrapper("App Removed", includeCommon(params));
    };

    const trackSearchedBoards = (params) => {
        analyticsTrackWrapper("Searched Boards", includeCommon(params));
    };

    //Viewed Board Upgrade Page
    const trackViewedBoardUpgradePage = (params) => {
        analyticsTrackWrapper("Viewed Board Upgrade Page", includeCommon(params));
    };

    // Board Upgraded
    const trackBoardUpgraded = (params) => {
        analyticsTrackWrapper("Board Upgraded", includeCommon(params));
    };

    //Additional Licenses added
    const trackLicensesAdded = (params) => {
        analyticsTrackWrapper("Board Licenses Added", includeCommon(params));
    };

    const trackItemAddedToCart = (params) => {
        analyticsTrackWrapper("Item Added To Cart", includeCommon(params));
    };

    const trackItemRemovedFromCart = (params) => {
        analyticsTrackWrapper("Item Removed From Cart", includeCommon(params));
    };

    const trackHootCommented = (params) => {
        analyticsTrackWrapper("Hoot Commented", includeCommon(params));
    };

    const trackHootReHooted = (params) => {
        analyticsTrackWrapper("Hoot reHooted", includeCommon(params));
    };

    const trackViewedHomePage = (params) => {
        analyticsTrackWrapper("Viewed HomePage", includeCommon(params));
    };

    const trackHomepageFormFilled = (params) => {
        analyticsTrackWrapper("Homepage Form Filled", includeCommon(params));
    };

    const trackSavedHootBoardContent = (params) => {
        analyticsTrackWrapper("Saved HootBoard Content", includeCommon(params));
    };

    const trackViewedAnalyticsPage = (params) => {
        analyticsTrackWrapper("Viewed Analytics Page", includeCommon(params));
    };

    // Hoot display
    const trackViewedSavedHootBoardContent = (params) => {
        analyticsTrackWrapper("Viewed Saved HootBoard Content", includeCommon(params));
    };

    // Listing page
    const trackViewedSavedHootBoardContentPage = (params) => {
        analyticsTrackWrapper("Viewed Saved HootBoard Content Page", includeCommon(params));
    };

    const trackViewedMessageCenter = (params) => {
        analyticsTrackWrapper("Viewed Message Center", includeCommon(params));
    };

    const trackConversationSelected = (params) => {
        analyticsTrackWrapper("Conversation Selected", includeCommon(params));
    };

    const trackPrivateMessageSent = (params) => {
        analyticsTrackWrapper("Private Message Sent", includeCommon(params));
    };

    //landing page viewed
    const trackPromoteYourBusinessPageViewed = (params) => {
        analyticsTrackWrapper("Promote Your Business Page Viewed", includeCommon(params));
    };

    //Create Board Flow Started
    const trackCreateBoardFlowStarted = (params) => {
        sendUnique("Create Board Flow Started", params);
    };

    //Board Type Selection Form Viewed
    const trackBoardTypeSelectionFormViewed = (params) => {
        sendUnique("Board Type Selection Form Viewed", params);
    };

    //Board Type Selection Form Saved ///
    const trackBoardTypeSelectionFormSaved = (params) => {
        analyticsTrackWrapper("Board Type Selection Form Saved", includeCommon(params));
    };

    //Basic Board Details Form Viewed
    const trackBasicBoardDetailsFormViewed = (params) => {
        sendUnique("Basic Board Details Form Viewed", params);
    };

    //Basic Board Details Form Entered
    const trackBasicBoardDetailsFormEntered = (params) => {
        analyticsTrackWrapper("Basic Board Details Form Entered", includeCommon(params));
    };

    //Basic Board Details Form Saved
    const trackBasicBoardDetailsFormSaved = (params) => {
        analyticsTrackWrapper("Basic Board Details Form Saved", includeCommon(params));
    };

    //Board Location Form Viewed
    const trackBoardLocationFormViewed = (params) => {
        sendUnique("Board Location Form Viewed", params);
    };

    //Board Location Form Entered
    const trackBoardLocationFormEntered = (params) => {
        analyticsTrackWrapper("Board Location Form Entered", includeCommon(params));
    };

    //Board Location Form Saved
    const trackBoardLocationFormSaved = (params) => {
        analyticsTrackWrapper("Board Location Form Saved", includeCommon(params));
    };

    //Board Business Contact Form Viewed
    const trackBoardBusinessContactFormViewed = (params) => {
        sendUnique("Board Business Contact Form Viewed", params);
    };

    //Board Business Contact Form Entered
    const trackBoardBusinessContactFormEntered = (params) => {
        analyticsTrackWrapper("Board Business Contact Form Entered", includeCommon(params));
    };

    //Board Business Contact Form Saved
    const trackBoardBusinessContactFormSaved = (params) => {
        analyticsTrackWrapper("Board Business Contact Form Saved", includeCommon(params));
    };

    //Board Business Contact Form Skipped
    const trackBoardBusinessContactFormSkipped = (params) => {
        analyticsTrackWrapper("Board Business Contact Form Skipped", includeCommon(params));
    };

    // Board Business Hours Form Viewed
    const trackBoardBusinessHoursFormViewed = (params) => {
        sendUnique("Board Business Hours Form Viewed", params);
    };

    // Board Business Hours Form Entered
    const trackBoardBusinessHoursFormEntered = (params) => {
        analyticsTrackWrapper("Board Business Hours Form Entered", includeCommon(params));
    };

    // Board Business Hours Form Saved
    const trackBoardBusinessHoursFormSaved = (params) => {
        analyticsTrackWrapper("Board Business Hours Form Saved ", includeCommon(params));
    };

    // Board Business Hours Form Skipped
    const trackBoardBusinessHoursFormSkipped = (params) => {
        analyticsTrackWrapper("Board Business Hours Form Skipped", includeCommon(params));
    };

    // Board Business Photos Form Viewed
    const trackBoardBusinessPhotosFormViewed = (params) => {
        sendUnique("Board Business Photos Form Viewed", params);
    };

    // Board Business Photos Form Entered
    const trackBoardBusinessPhotosFormEntered = (params) => {
        analyticsTrackWrapper("Board Business Photos Form Entered", includeCommon(params));
    };

    // Board Business Photos Form Saved
    const trackBoardBusinessPhotosFormSaved = (params) => {
        analyticsTrackWrapper("Board Business Photos Form Saved ", includeCommon(params));
    };

    // Board Business Photos Form Skipped
    const trackBoardBusinessPhotosFormSkipped = (params) => {
        analyticsTrackWrapper("Board Business Photos Form Skipped", includeCommon(params));
    };

    // Create Board Flow Ended
    const trackCreateBoardFlowEnded = (params) => {
        dispatch(resetMarkedEvents());
        analyticsTrackWrapper("Create Board Flow Ended", includeCommon(params));
    };

    const trackBusinessSearchPageViewed = (params) => {
        analyticsTrackWrapper("Business Search Page Viewed", includeCommon(params));
    };

    const trackBusinessSearchStarted = (params) => {
        analyticsTrackWrapper("Business Search Started", includeCommon(params));
    };

    const trackCreateNewBusinessFlowStarted = (params) => {
        analyticsTrackWrapper("Create New Business Flow Started", includeCommon(params));
    };

    const trackImportBusinessFlowStarted = (params) => {
        analyticsTrackWrapper("Import Business Flow Started", includeCommon(params));
    };

    const trackExistingBusinessSelected = (params) => {
        analyticsTrackWrapper("Existing Business Selected", includeCommon(params));
    };

    const trackBusinessClaimStarted = (params) => {
        analyticsTrackWrapper("Business Claim Started", includeCommon(params));
    };

    const trackExistingBusinessViewed = (params) => {
        analyticsTrackWrapper("Existing Business Viewed", includeCommon(params));
    };

    const trackBusinessClaimFormViewed = (params) => {
        analyticsTrackWrapper("Business Claim Form Viewed", includeCommon(params));
    };

    const trackBusinessClaimFormEntered = (params) => {
        analyticsTrackWrapper("Business Claim Form Entered", includeCommon(params));
    };

    const trackBusinessClaimProcessFinished = (params) => {
        analyticsTrackWrapper("Business Claim Process Finished", includeCommon(params));
    };

    const businessDashboardCTAClicked = (params) => {
        analyticsTrackWrapper("Business Dashboard CTA Clicked", includeCommon(params));
    };

    const scheduleDemoPageViewed = (params) => {
        analyticsTrackWrapper("Schedule Demo Page Viewed", includeCommon(params));
    };

    const promoteOnHootBoardButtonClicked = (params) => {
        analyticsTrackWrapper("Promote On HootBoard Button Clicked", includeCommon(params));
    };

    const trackViewedSavedListPage = (params) => {
        analyticsTrackWrapper("Viewed Saved List Page", includeCommon(params));
    };

    const trackViewedActivityFeedPage = (params) => {
        analyticsTrackWrapper("Viewed Activity Feed Page", includeCommon(params));
    };

    const trackViewedBoardApp = (params) => {
        analyticsTrackWrapper("Viewed Board App", includeCommon(params));
    };

    const trackAnalyticsActivitySelected = (params) => {
        analyticsTrackWrapper("Analytics Activity Selected", includeCommon(params));
    };

    const trackAnalyticsChannelSelected = (params) => {
        analyticsTrackWrapper("Analytics Channel Selected", includeCommon(params));
    };

    const trackAnalyticsTimePeriodSelected = (params) => {
        analyticsTrackWrapper("Analytics Time Period Selected", includeCommon(params));
    };

    const trackAnalyticsReportDownloaded = (params) => {
        analyticsTrackWrapper("Analytics Report Downloaded", includeCommon(params));
    };

    const trackRecommendedAppInstalled = (params) => {
        analyticsTrackWrapper("Recommended App Installed", includeCommon(params));
    };

    const trackViewedListDetailsPage = (params) => {
        analyticsTrackWrapper("Viewed List Details Page", includeCommon(params));
    };

    const trackViewedMyListsPage = (params) => {
        analyticsTrackWrapper("Viewed My Lists Page", includeCommon(params));
    };

    const trackItemRemovedFromList = (params) => {
        analyticsTrackWrapper("Item Removed From List", includeCommon(params));
    };

    const trackItemAddedToList = (params) => {
        analyticsTrackWrapper("Item Added To List", includeCommon(params));
    };

    const trackListCreated = (params) => {
        analyticsTrackWrapper("List Created", includeCommon(params));
    };

    const trackListSaved = (params) => {
        analyticsTrackWrapper("List Saved", includeCommon(params));
    };

    const trackListUpdated = (params) => {
        analyticsTrackWrapper("List Updated", includeCommon(params));
    };

    const trackListDeleted = (params) => {
        analyticsTrackWrapper("List Deleted", includeCommon(params));
    };

    const trackListItemUpdated = (params) => {
        analyticsTrackWrapper("List Item Updated", includeCommon(params));
    };

    const trackViewedListMapView = (params) => {
        analyticsTrackWrapper("Viewed List Map View", includeCommon(params));
    };

    const trackListEducationButtonClicked = (params) => {
        analyticsTrackWrapper("List Education Button Clicked", includeCommon(params));
    };

    const trackBuyKioskButtonClicked = (params) => {
        analyticsTrackWrapper("Clicked Buy Kiosk Button", includeCommon(params));
    };

    const trackViewedBuyDigitalKioskPage = (params) => {
        analyticsTrackWrapper("Viewed Buy Digital Kiosks Page", includeCommon(params));
    };

    const trackViewedSubscribersList = (params) => {
        analyticsTrackWrapper("Viewed Board Subscribers Page", includeCommon(params));
    };
    const trackDownloadSubscribersList = (params) => {
        analyticsTrackWrapper("Clicked Download Subscribers List", includeCommon(params));
    };

    const trackViewedManageSequences = (params) => {
        analyticsTrackWrapper("Viewed Manage Sequences Page", includeCommon(params));
    };
    const trackCreateSequenceFlowStarted = (params) => {
        analyticsTrackWrapper("Create Sequence Flow Started", includeCommon(params));
    };
    const trackBasicSequenceDetailsEntered = (params) => {
        analyticsTrackWrapper("Basic Sequence Details Entered", includeCommon(params));
    };
    const trackContentLayoutSelected = (params) => {
        analyticsTrackWrapper("Slot Content Layout Selected", includeCommon(params));
    };
    const trackContentAssignedToZone = (params) => {
        analyticsTrackWrapper("Content Assigned To Zone", includeCommon(params));
    };
    const trackNewSequenceCreated = (params) => {
        analyticsTrackWrapper("New Sequence Created", includeCommon(params));
    };
    const trackCreateSequenceFlowEnded = (params) => {
        analyticsTrackWrapper("Create Sequence Flow Ended", includeCommon(params));
    };
    const trackEditSequenceFlowStarted = (params) => {
        analyticsTrackWrapper("Edit Sequence Flow Started", includeCommon(params));
    };
    const trackEditSequenceFlowEnded = (params) => {
        analyticsTrackWrapper("Edit Sequence Flow Ended", includeCommon(params));
    };
    const trackSequenceDeleted = (params) => {
        analyticsTrackWrapper("Sequence Deleted", includeCommon(params));
    };
    const trackSequenceAssignedToSchedule = (params) => {
        analyticsTrackWrapper("Sequence Assigned To A Schedule", includeCommon(params));
    };
    const trackSequenceRemovedFromSchedule = (params) => {
        analyticsTrackWrapper("Sequence Removed From A Schedule", includeCommon(params));
    };
    const trackContentAssignedToSlot = (params) => {
        analyticsTrackWrapper("Content Assigned To Slot", includeCommon(params));
    };

    const trackCollectionsAssignedToScreenId = (params) => {
        analyticsTrackWrapper("Collections Assigned To Screen Id", includeCommon(params));
    };
    const trackSequenceAssignedToScreenId = (params) => {
        analyticsTrackWrapper("Sequence Assigned To Screen Id", includeCommon(params));
    };
    const trackNumberOfSlotsAssigned = (params) => {
        analyticsTrackWrapper("Total Slots Assigned In A Sequence", includeCommon(params));
    };
    return (
        <MixpanelContext.Provider
            value={{
                // state,
                resetSession,
                trackBoardDeleted,
                trackHootDeleted,
                trackMemberRemoved,
                trackSubscriptionCancelled,
                trackSubscribedToPushNotifications,
                trackUnSubscribedFromPushNotifications,
                trackWebMobileEngagementUnitLimitReached,
                trackViewedNotificationsSettingsPage,
                trackViewedMyHootsPage,
                trackViewedMyAccountPage,
                trackViewedMyBoardsPage,
                trackViewedAllBoardsPage,
                scheduleDemoPageViewed,
                businessDashboardCTAClicked,
                promoteOnHootBoardButtonClicked,
                trackQuickStartGuideItemCompleted,
                trackProductTourStarted,
                trackProductTourCompleted,
                trackProductTourSkipped,
                trackQuickStartGuideSkipped,
                trackViewedMessageCenter,
                trackConversationSelected,
                trackPrivateMessageSent,
                trackActivateAccount,
                trackViewedHootDisplayPage,
                trackViewedHootBoardPage,
                trackHootPosted,
                trackInvitationSent,
                trackInvitationAccepted,
                trackBoardFollowed,
                trackCollectionCreated,
                trackBoardEmbedCopied,
                trackItemShared,
                trackViewedCalendarPage,
                trackConnectorRuleCreated,
                trackConnectorAccountCreated,
                trackViewedConnectorPage,
                trackViewedCreateBoardPage,
                trackViewedCreateSubBoardPage,
                trackCreatedBoard,
                trackViewedBoardSettingsPage,
                trackBoardSettingsConfigured,
                trackSubBoardRemoved,
                trackSubBoardParentRemoved,
                trackSubBoardAdded,
                trackInvitationRevoked,
                trackInvitationResent,
                trackViewedManageScreensPage,
                trackViewedKiosksLearnMorePage,
                trackDownloadedKioskHardwareDetails,
                trackClickedKioskHardwareBuyButton,
                trackViewedScreenSetupPage,
                trackScreenLicenseApplied,
                trackScreenLicenseRevoked,
                trackScreenCreated,
                trackScreenDeleted,
                trackAppInstalled,
                trackAppCreated,
                trackAppRemoved,
                trackSearchedBoards,
                trackViewedBoardUpgradePage,
                trackBoardUpgraded,
                trackLicensesAdded,
                trackItemAddedToCart,
                trackItemRemovedFromCart,
                trackViewedHomePage,
                trackHomepageFormFilled,
                trackHootCommented,
                trackHootReHooted,
                trackViewedAnalyticsPage,
                trackSavedHootBoardContent,
                trackViewedSavedHootBoardContentPage,
                trackViewedSavedHootBoardContent,
                trackPromoteYourBusinessPageViewed,
                trackCreateBoardFlowStarted,
                trackBoardTypeSelectionFormViewed,
                trackBoardTypeSelectionFormSaved,
                trackBasicBoardDetailsFormViewed,
                trackBasicBoardDetailsFormEntered,
                trackBasicBoardDetailsFormSaved,
                trackBoardLocationFormViewed,
                trackBoardLocationFormEntered,
                trackBoardLocationFormSaved,
                trackBoardBusinessContactFormViewed,
                trackBoardBusinessContactFormEntered,
                trackBoardBusinessContactFormSaved,
                trackBoardBusinessContactFormSkipped,
                trackBoardBusinessHoursFormViewed,
                trackBoardBusinessHoursFormEntered,
                trackBoardBusinessHoursFormSaved,
                trackBoardBusinessHoursFormSkipped,
                trackBoardBusinessPhotosFormViewed,
                trackBoardBusinessPhotosFormEntered,
                trackBoardBusinessPhotosFormSaved,
                trackBoardBusinessPhotosFormSkipped,
                trackCreateBoardFlowEnded,
                trackBusinessSearchPageViewed,
                trackBusinessSearchStarted,
                trackCreateNewBusinessFlowStarted,
                trackImportBusinessFlowStarted,
                trackExistingBusinessSelected,
                trackExistingBusinessViewed,
                trackBusinessClaimStarted,
                trackBusinessClaimFormViewed,
                trackBusinessClaimFormEntered,
                trackBusinessClaimProcessFinished,
                trackViewedSavedListPage,
                trackViewedActivityFeedPage,
                trackViewedBoardApp,
                trackAnalyticsActivitySelected,
                trackAnalyticsReportDownloaded,
                trackAnalyticsChannelSelected,
                trackAnalyticsTimePeriodSelected,
                trackRecommendedAppInstalled,
                trackViewedListDetailsPage,
                trackViewedMyListsPage,
                trackItemRemovedFromList,
                trackItemAddedToList,
                trackListCreated,
                trackListSaved,
                trackListUpdated,
                trackListDeleted,
                trackListItemUpdated,
                trackViewedListMapView,
                trackListEducationButtonClicked,
                trackBuyKioskButtonClicked,
                trackViewedBuyDigitalKioskPage,
                trackViewedSubscribersList,
                trackDownloadSubscribersList,
                trackViewedManageSequences,
                trackCreateSequenceFlowStarted,
                trackBasicSequenceDetailsEntered,
                trackContentLayoutSelected,
                trackContentAssignedToZone,
                trackNewSequenceCreated,
                trackCreateSequenceFlowEnded,
                trackEditSequenceFlowStarted,
                trackEditSequenceFlowEnded,
                trackSequenceDeleted,
                trackSequenceAssignedToSchedule,
                trackContentAssignedToSlot,
                trackCollectionsAssignedToScreenId,
                trackSequenceAssignedToScreenId,
                trackNumberOfSlotsAssigned,
                trackSequenceRemovedFromSchedule,
            }}>
            {props.children}
        </MixpanelContext.Provider>
    );
}

export {
    MixpanelContext,
    MixpanelContext as AnalyticsContext,
    MixpanelProvider,
    MixpanelProvider as AnalyticsProvider,
};

// usage at the highest level, add the context provider so that it is available to all child components

// {
/* <MixpanelProvider>
	<App />
</MixpanelProvider> */
// }

// and later inside components call the relevent methods

// let mixpanelContext = useContext(MixpanelContext)
// mixpanelContext.trackViewedHootDisplayPage(hootData);
