/*
 * @Author: Chandu J S
 * @Date:   2020-08-07 14:44:31
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-08-07 17:13:17
 */
import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "../../../api/authentication.service";

function* activateAccount(action) {
	try {
		yield put({ type: "activate-account/pending" });

		const { encUserId } = action.payload;

		const apiResponse = yield call(
			async () => await API.activateAccount(encUserId)
		);
		if (!apiResponse) throw new Error(apiResponse);
		if (!apiResponse.activationStatus) throw new Error(apiResponse);

		yield put({ type: "activate-account/fulfilled" });
	} catch (e) {
		yield put({ type: "activate-account/rejected", payload: e });
	}
}

export function* activateAccountSaga() {
	yield takeLatest("activate-account/request", activateAccount);
}
