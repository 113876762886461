import { createSlice } from "@reduxjs/toolkit";
import { emojiFilter } from "../../../../../helper/filters.helper";
import * as CollectionSelectorRedux from "../../CollectionSelector/redux";

const editAppSlice = createSlice({
	name: "editApp",
	initialState: {
		isEditing: false,
		app: null,
		appProps: {
			type: "",
			name: "",
			iconUrl: "",
			showOnAllScreens: true,
			selectedScreens: [],
			isDeveloperApp: false,

			showOnWeb: true,
			showOnMobile: true,
			appAccess: "public",
			restrictedAppAccess: {},

			showTypeErrorRequired: false,
			showNameErrorRequired: false,
			showIconUrlErrorRequired: false,
			showOnSelectedScreenErrorRequired: false,
		},
		webAppProps: {
			url: "",
			mobileFriendlyUrl: "",
			allowedDomains: "",

			showUrlErrorRequired: false,
		},
		fileAppProps: {
			url: "",
			showUrlErrorRequired: false,
		},
		rssFeedReaderAppProps: {
			url: "",

			showUrlErrorRequired: false,
		},
		collectionAppProps: {
			selectedCollections: [],
			collectionBehavior: "",
			// Redux related codes for collectionSelector is moved into a separate file to avoid duplication
			collectionSelector: CollectionSelectorRedux.initialState,
		},
	},
	reducers: {
		// Redux related codes for collectionSelector is moved into a separate file to avoid duplication
		...CollectionSelectorRedux.reducers,
		setCollectionBehavior: (state, action) => {
			state.collectionAppProps.collectionBehavior = action.payload;
		},
		editAppPressed: (state, action) => {
			const app = action.payload;

			state.isEditing = true;
			state.app = app;

			state.appProps.type = app.appTypeCode;
			state.appProps.name = app.appName;
			state.appProps.showOnAllScreens = app.showAllScreens;
			state.appProps.iconUrl = app.appIconUrl;
			state.appProps.isDeveloperApp = app.developerApp;
			state.appProps.showOnWeb = app.showOnWeb;
			state.appProps.showOnMobile = app.showOnMobile;
			state.appProps.appAccess = app.private ? "private" : "public";

			const boardAppsEndpoint =
				app.boardAppsEndpoints && app.boardAppsEndpoints.length > 0
					? app.boardAppsEndpoints[0]
					: null;

			state.appProps.selectedScreens = boardAppsEndpoint.screens
				? boardAppsEndpoint.screens
				: [];

			switch (app.appTypeCode) {
				case "WL":
					state.webAppProps.url = boardAppsEndpoint.endpoint;
					state.webAppProps.mobileFriendlyUrl =
						boardAppsEndpoint.mobileEndpoint
							? boardAppsEndpoint.mobileEndpoint
							: "";
					state.webAppProps.allowedDomains =
						boardAppsEndpoint.domainsAllowed
							? boardAppsEndpoint.domainsAllowed
							: "";
					break;

				case "FA":
					state.fileAppProps.url = boardAppsEndpoint.endpoint;
					break;

				case "RR":
					state.rssFeedReaderAppProps.url =
						boardAppsEndpoint.endpoint;
					break;

				case "CL": {
					const { endpoint, collectionBehavior } =
						app.boardAppsEndpoints[0];

					state.collectionAppProps.selectedCollections = endpoint;
					state.collectionAppProps.collectionBehavior =
						collectionBehavior ? collectionBehavior : "U";
					break;
				}

				default:
					return;
			}
		},
		close: (state) => {
			state.isEditing = false;
			state.app = null;

			state.appProps.type = "";
			state.appProps.name = "";
			state.appProps.iconUrl = "";
			state.appProps.showOnAllScreens = true;
			state.appProps.selectedScreens = [];
			state.appProps.showTypeErrorRequired = false;
			state.appProps.showNameErrorRequired = false;
			state.appProps.showIconUrlErrorRequired = false;
			state.appProps.showOnSelectedScreenErrorRequired = false;

			state.webAppProps.url = "";
			state.webAppProps.mobileFriendlyUrl = "";
			state.webAppProps.allowedDomains = "";
			state.webAppProps.showUrlErrorRequired = false;

			state.fileAppProps.url = "";
			state.fileAppProps.showUrlErrorRequired = false;

			state.rssFeedReaderAppProps.url = "";
			state.rssFeedReaderAppProps.showUrlErrorRequired = false;

			state.collectionAppProps.selectedCollections = [];
			state.collectionAppProps.collectionBehavior = "";
		},
		appShowOnWebChanged: (state, action) => {
			state.appProps.showOnWeb = action.payload;
		},
		appShowOnMobileChanged: (state, action) => {
			state.appProps.showOnMobile = action.payload;
		},
		appAccessChanged: (state, action) => {
			state.appProps.appAccess = action.payload;
		},
		restrictedAppAccessChanged: (state, action) => {
			state.appProps.restrictedAppAccess = action.payload;
		},
		appNameEntered: (state, action) => {
			state.appProps.name = emojiFilter(action.payload);
			state.appProps.showNameErrorRequired = action.payload === "";
		},
		appIconUploaded: (state, action) => {
			state.appProps.iconUrl = action.payload;
		},
		appShownOnAllScreensChanged: (state, action) => {
			state.appProps.showOnAllScreens = action.payload;
			state.appProps.showOnSelectedScreenErrorRequired = false;
		},
		appScreenToShowOnAdded: (state, action) => {
			state.appProps.selectedScreens.push(action.payload);
			state.appProps.showOnSelectedScreenErrorRequired = false;
		},
		appScreenToShowOnRemoved: (state, action) => {
			state.appProps.selectedScreens.splice(action.payload, 1);
			state.appProps.showOnSelectedScreenErrorRequired =
				state.appProps.selectedScreens.length === 0;
		},
		webAppUrlEntered: (state, action) => {
			state.webAppProps.url = action.payload;
			state.webAppProps.showUrlErrorRequired = action.payload === "";
		},
		webAppMobileFriendlyUrlEntered: (state, action) => {
			state.webAppProps.mobileFriendlyUrl = action.payload;
		},
		webAppAllowedDomainAdded: (state, action) => {
			state.webAppProps.allowedDomains = action.payload.trim();
		},
		fileAppUrlEntered: (state, action) => {
			state.fileAppProps.url = action.payload;
			state.fileAppProps.showUrlErrorRequired = action.payload === "";
		},
		rssFeedReaderAppUrlEntered: (state, action) => {
			state.rssFeedReaderAppProps.url = action.payload;
			state.rssFeedReaderAppProps.showUrlErrorRequired =
				action.payload === "";
		},
		setCollectionAppCollections: (state, action) => {
			state.collectionAppProps.selectedCollections = action.payload.map(
				(filterText) => ({
					filterText,
				})
			);
		},
	},
	extraReducers: {
		...CollectionSelectorRedux.extraReducers,
	},
});

export const {
	setCollectionBehavior,
	editAppPressed,
	close,
	appAccessChanged,
	restrictedAppAccessChanged,
	appShowOnMobileChanged,
	appShowOnWebChanged,
	appNameEntered,
	appIconUploaded,
	appShownOnAllScreensChanged,
	appScreenToShowOnAdded,
	appScreenToShowOnRemoved,
	webAppUrlEntered,
	webAppMobileFriendlyUrlEntered,
	webAppAllowedDomainAdded,
	fileAppUrlEntered,
	rssFeedReaderAppUrlEntered,
	setCollectionAppCollections,
	// Redux related codes for collectionSelector is moved into a separate file to avoid duplication
	// Below actions are coming from redux.js inside CollectionSelector
	syncData,
	resetPanel,
	selectBoard,
	setSearchType,
	setSearchText,
	removeCollection,
	selectCollection,
	filterLocalCollections,
} = editAppSlice.actions;

export default editAppSlice.reducer;
