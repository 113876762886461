/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-16 19:58:07
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-06-11 20:55:08
 */

import { createSlice } from "@reduxjs/toolkit";
import { transformHoots } from "../../utils/transformHoots.util";

export const fetchInitialHoots = (payload) => {
	return {
		type: "boards/pinned/hoots/request",
		payload,
	};
};

export const fetchMoreHoots = (payload) => {
	return {
		type: "boards/pinned/hoots/more/request",
		payload,
	};
};

const initialState = {
	pinnedBoardHoots: [],
	initialHootsFetchStatus: "idle",
	fetchMoreHootsStatus: "idle",
	shouldFetchMore: false,
	error: false,
	errorMessage: "",
	page: 0,
	max: 40,
	upcomingEvents: false,
	freeEvents: false,
};

const pinnedBoardHootsSlice = createSlice({
	name: "pinnedBoardHoots",
	initialState: initialState,
	reducers: {
		reset: () => {
			return initialState;
		},
		setPage: (state, action) => {
			return {
				...state,
				page: action.payload.page,
			};
		},
		updateHootPinned: (state, action) => {
			const { pin, selectedHootIndex } = action.payload;

			state.pinnedBoardHoots[selectedHootIndex].isPinned = pin;
		},
		updateHootRehoot: (state, action) => {
			const { selectedHootIndex } = action.payload;

			state.pinnedBoardHoots[selectedHootIndex].hootStats.rehoots += 1;
		},
		updateHootFavorite: (state, action) => {
			const { selectedHootIndex, favorite } = action.payload;

			state.pinnedBoardHoots[selectedHootIndex].favPost = favorite;
			state.pinnedBoardHoots[selectedHootIndex].hootStats.favorites +=
				favorite ? 1 : -1;
		},
		updateHootApproved: (state, action) => {
			const { selectedHootIndex, selectedHoot } = action.payload;

			if (selectedHootIndex) {
				state.pinnedBoardHoots[
					selectedHootIndex
				].requiresApproval = false;
			} else {
				const index = state.pinnedBoardHoots.findIndex(
					({ id }) => id === selectedHoot.id
				);
				state.pinnedBoardHoots[index].requiresApproval = false;
			}
		},
		updateHootRejected: (state, action) => {
			const { selectedHootIndex, selectedHoot } = action.payload;

			if (selectedHootIndex) {
				state.pinnedBoardHoots.splice(selectedHootIndex, 1);
			} else {
				const index = state.pinnedBoardHoots.findIndex(
					({ id }) => id === selectedHoot.id
				);
				state.pinnedBoardHoots.splice(index, 1);
			}
		},
		updateHootCommentCount: (state, action) => {
			const { selectedHootIndex, selectedHoot } = action.payload;

			if (selectedHootIndex) {
				state.pinnedBoardHoots[
					selectedHootIndex
				].hootStats.comments += 1;
			} else {
				const index = state.pinnedBoardHoots.findIndex(
					({ id }) => id === selectedHoot.id
				);
				state.pinnedBoardHoots[index].hootStats.comments += 1;
			}
		},
		rehooted: (state, action) => {
			const index = state.pinnedBoardHoots.findIndex(
				({ id }) => id === action.payload.id
			);

			if (index > -1) {
				state.pinnedBoardHoots[index].hootStats.rehoots += 1;
			}
		},
	},
	extraReducers: {
		"boards/pinned/hoots/pending": (state) => {
			return {
				...state,
				initialHootsFetchStatus: "pending",
				pinnedBoardHoots: [],
			};
		},
		"boards/pinned/hoots/fulfilled": (state, action) => {
			if (action.payload.hoots && action.payload.hoots.length) {
				return {
					...state,
					page: state.page + 1,
					initialHootsFetchStatus: "fulfilled",
					pinnedBoardHoots: action.payload.hoots.map(transformHoots),
					shouldFetchMore:
						action.payload.hoots.length > state.max - 2,
				};
			} else {
				return {
					...state,
					initialHootsFetchStatus: "fulfilled",
					pinnedBoardHoots: [],
					shouldFetchMore: false,
				};
			}
		},
		"boards/pinned/hoots/rejected": (state) => {
			return {
				...state,
				initialHootsFetchStatus: "rejected",
				pinnedBoardHoots: [],
				error: true,
				errorMessage: "Failed to fetch hoots",
			};
		},
		"boards/pinned/hoots/more/pending": (state) => {
			return {
				...state,
				fetchMoreHootsStatus: "pending",
			};
		},
		"boards/pinned/hoots/more/fulfilled": (state, action) => {
			if (action.payload.hoots && action.payload.hoots.length) {
				return {
					...state,
					fetchMoreHootsStatus: "fulfilled",
					pinnedBoardHoots: [
						...state.pinnedBoardHoots,
						...action.payload.hoots.map(transformHoots),
					],
					page: state.page + 1,
					shouldFetchMore:
						action.payload.hoots.length > state.max - 2,
				};
			} else {
				return {
					...state,
					page: state.page + 1,
					fetchMoreHootsStatus: "fulfilled",
					shouldFetchMore: false,
				};
			}
		},
		"boards/pinned/hoots/more/rejected": (state) => {
			return {
				...state,
				fetchMoreHootsStatus: "rejected",
				pinnedBoardHoots: [],
				error: true,
				errorMessage: "Failed to fetch hoots",
			};
		},
	},
});
export const {
	rehooted,
	updateHootApproved,
	updateHootCommentCount,
	updateHootFavorite,
	updateHootPinned,
	updateHootRehoot,
	updateHootRejected,
} = pinnedBoardHootsSlice.actions;
export default pinnedBoardHootsSlice.reducer;
