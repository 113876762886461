/*
 * @Author: snair@hootboard.com
 * @Date: 2020-06-03 01:28:27
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-07-29 08:43:00
 */

import { call, put, takeLatest } from "redux-saga/effects";
import * as ScreenApi from "../../api/screens.service";

function* getCustomMessages(action) {
	try {
		yield put({
			type: "board/screens/messages/get/pending",
			payload: {},
		});
		const apiResponse = yield call(
			async () =>
				await ScreenApi.getBoardScreenMessages(action.payload.boardId)
		);
		if (apiResponse && apiResponse.boardScreenMessages) {
			yield put({
				type: "board/screens/messages/get/fulfilled",
				payload: {
					customMessages: apiResponse.boardScreenMessages,
				},
			});
		} else {
			yield put({
				type: "board/screens/messages/get/rejected",
				payload: { customMessages: [] },
			});
		}
	} catch (e) {
		yield put({
			type: "board/screens/messages/get/rejected",
			payload: {},
		});
	}
}

export function* getCustomMessagesSaga() {
	yield takeLatest("board/screens/messages/get/request", getCustomMessages);
}

function* saveBoardScreenMessages(action) {
	try {
		yield put({
			type: "board/screens/messages/save/pending",
			payload: {},
		});
		const { boardId, screenIds, screenMessage, screenMessageId } =
			action.payload;
		const apiResponse = yield call(
			async () =>
				await ScreenApi.saveBoardScreenMessages(
					boardId,
					screenIds,
					screenMessage,
					screenMessageId
				)
		);
		if (apiResponse && apiResponse.screenMessageId) {
			yield put({
				type: "board/screens/messages/save/fulfilled",
				payload: {},
			});
		} else {
			yield put({
				type: "board/screens/messages/save/rejected",
				payload: {},
			});
		}
	} catch (e) {
		yield put({
			type: "board/screens/messages/save/rejected",
			payload: {},
		});
	}
}

export function* saveBoardScreenMessagesSaga() {
	yield takeLatest(
		"board/screens/messages/save/request",
		saveBoardScreenMessages
	);
}

function* deleteBoardScreenMessages(action) {
	try {
		yield put({
			type: "board/screens/messages/delete/pending",
			payload: {},
		});
		const apiResponse = yield call(
			async () =>
				await ScreenApi.deleteBoardScreenMessage(
					action.payload.screenMessageId
				)
		);
		if (apiResponse && apiResponse.screenMessageId) {
			yield put({
				type: "board/screens/messages/delete/fulfilled",
				payload: {},
			});
		} else {
			yield put({
				type: "board/screens/messages/delete/rejected",
				payload: {},
			});
		}
	} catch (e) {
		yield put({
			type: "board/screens/messages/delete/rejected",
			payload: {},
		});
	}
}

export function* deleteBoardScreenMessagesSaga() {
	yield takeLatest(
		"board/screens/messages/delete/request",
		deleteBoardScreenMessages
	);
}
