/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-28 16:10:00
 * @Last Modified by: Sridhar Katta
 * @Last Modified time: 2022-08-22 12:38:40
 */

import { createSlice } from "@reduxjs/toolkit";
import { Filters } from "../../helper/filters.helper";
import { ActionState, AsyncState } from "../../types";
import { isColorDark } from "../../utils/color.utils";
import { InputValidators } from "../../utils/inputValidators.util";
import { Actions as BusinessActions } from "../ManageMyBusiness/manageMyBusiness.saga";
import { Actions } from "./createBoard.saga";

const initialState = {
	//
	boardType: "",
	parentBoardId: 0,
	//
	// Wether if its a normal board create flow or "import" or "claim" process.
	// "normal", if normal board create flow.
	// components for "import" and "claim" processes are placed inside "/otherFlows" folder.
	activeBusinessFlow: "normal",
	//
	searchResults: [],
	searchAPIStatus: "idle",
	//
	boardTypes: [],
	boardTypesAPIStatus: "idle",
	//
	// if the user went through the import business flow, we need to mark this as true.
	// we will save the data into individual objects in the state.
	isDataImported: false,
	importBusinessAPIStatus: "idle",
	//
	//
	boardIdToClaim: 0,
	boardDetailsToClaim: null,
	//
	checkBusinessExistsAPIStatus: "idle",
	checkBusinessExistsData: {},
	//
	claimBoardGetAPIStatus: "idle",
	claimBoardRequestAPIStatus: "idle",
	claimBoardForm: {
		boardId: 0,
		phoneNumber: "",
		notes: "",
	},
	//
	currentStep: 0,
	stepProcessStatus: "idle",
	//
	boardLocationFormSaveStatus: "idle",
	basicBoardDetailsFormSaveStatus: "idle",
	boardBusinessContactFormSaveStatus: "idle",
	boardBusinessPhotosFormSaveStatus: "idle",
	boardBusinessHoursFormSavedStatus: "idle",
	//
	isBoardLocationFormEntered: false,
	isBasicBoardDetailsFormEntered: false,
	isBoardBusinessHoursFormEntered: false,
	isBoardBusinessPhotosFormEntered: false,
	isBoardBusinessContactFormEntered: false,
	isBusinessSearchFormEntered: false,
	isBusinessClaimFormEntered: false,
	//
	// we need to save the sent events in state so that we can avoid duplicate events.
	sentMixpanelEvents: [],
	//
	basicInfo: {
		boardName: "",
		boardDesc: "",
		boardLogo: "",
		businessCategories: [],
	},
	locationInfo: null,
	contactInfo: {
		id: null,
		phoneNumber: "", // this one is for state.
		website: "",
	},
	businessHours: [],
	//
	/**
	 * Usually, the item structure only contains a `url` field.
	 * but in case of import business flow, imported (and converted to static URL) images will also be
	 * available here, with structure of below:
	 * ```
	 * {
	 * 	"url": "STATIC URL",
	 * 	"thumbnail": "THUMBNAIL URL",
	 * 	"isImported": true
	 * }
	 * ```
	 * Later when we process the array in saga, we will look for the imported images and
	 * upload it to filestack before saving changes to our server.
	 */
	businessPhotos: [],
	//
	errors: {
		showAllFormErrors: false,
		boardNameError: true,
		boardNameErrorMessage: "Please enter a valid name for your board.",
		boardDescError: true,
		boardDescErrorMessage:
			"Please enter a brief description of your board.",
		boardLogoError: true,
		boardLogoErrorMessage: "Please set a logo for your board.",
		boardTypeError: false,
		boardTypeErrorMessage: "Please select the board type.",
		locationsError: false,
		locationsErrorMessage: "Your address & location is required.",
		//
		basicInfoAPIError: false,
		basicInfoAPIErrorMessage:
			"Yikes! Something went wrong while creating your board.",
		locationAPIError: false,
		locationAPIErrorMessage:
			"Yikes! Something went wrong while saving location.",
		contactAPIError: false,
		contactAPIErrorMessage:
			"Yikes! Something went wrong while saving details.",
		phoneNumberError: false,
		phoneNumberErrorMessage: "Please enter a valid phone number",
		websiteError: false,
		websiteErrorMessage: "Please enter a valid website url",
		hoursAPIError: false,
		hoursAPIErrorMessage: "Yikes! Something went wrong while saving hours.",
		photosAPIError: false,
		photosAPIErrorMessage:
			"Yikes! Something went wrong while saving phots.",
		claimRequestAPIError: false,
		claimRequestAPIErrorMessage:
			"Yikes! Something went wrong while sending claim request.",
		claimNoteError: false,
		claimNoteErrorMessage: "",
		claimPhoneError: false,
		claimPhoneErrorMessage: "Enter a valid phone number.",
	},
	//
	// below information saved after initial board creation API call.
	createdBoard: {},
	//
	boardThemeCustomColors: {},
	customHootThemeColors: [],
	updateThemeColorsStatus: "idle",
};

export const registerForAdSpace = (boardId) => {
	return {
		type: Actions.registerForAdSpace + "request",
		payload: { boardId },
	};
};

export const getBoardTypes = () => {
	return {
		type: Actions.getBoardTypes + "request",
	};
};

export const createBoard = (boardParams) => {
	return {
		type: Actions.createBoard + "request",
		payload: boardParams,
	};
};

export const searchBusinesses = ({ query, location }) => {
	return {
		type: Actions.searchBusinesses + "request",
		payload: { query, location },
	};
};

/**
 * We use this to get the existing board details.
 * Don't get confused with the import flow. That's `importBusiness()` method.
 * The API we are calling is normal board details API.
 * We are using the method name as business because this is used in the claim business flow.
 */
export const getBusinessDetails = (boardId) => {
	return {
		type: Actions.getBusinessDetails + "request",
		payload: boardId,
	};
};

export const claimBusiness = ({ boardId, phoneNumber, notes }) => {
	return {
		type: Actions.claimBusiness + "request",
		payload: { boardId, phoneNumber, notes },
	};
};

/**
 * Use this to pull the details of a business with UUID.
 * Details are not coming from HootBoard (could be from google or any other source).
 */
export const importBusiness = ({ uuid, provider }) => {
	return {
		type: Actions.importBusiness + "request",
		payload: { uuid, provider },
	};
};

// Use this to check if the business exists in the google and hootboard database.
export const checkBusinessExists = ({ placeId, businessName }) => {
	return {
		type: Actions.checkBusinessExists + ActionState.REQUEST,
		payload: { placeId, businessName },
	};
};

/**
 * A helper function to update the slice's state variables from the `boardDetails` object.
 * Most importantly, we need some of the `id` values after creating certain sections.
 * Like location and business details. We can only update the info if we have those ids.
 */
const getDataFromBoardDetails = (state, action) => {
	const { boardDetails } = action.payload;

	state.createdBoard = boardDetails;

	// saving for CONTACT INFO section
	state.contactInfo.id = (boardDetails.businessDetails || {}).id;

	// saving for LOCATION section
	let locationData =
		(boardDetails.locations || []).length > 0
			? boardDetails.locations[0]
			: null;
	if (locationData) {
		if (!locationData.addressLine1) {
			locationData = {
				...locationData,
				addressLine1: "",
			};
		}
		if (!locationData.addressLine2) {
			locationData = {
				...locationData,
				addressLine2: "",
			};
		}
		state.locationInfo = locationData;
	}

	// saving for BUSINESS HOURS section
	if (boardDetails.businessHours && boardDetails.businessHours.length > 0) {
		state.businessHours = boardDetails.businessHours;
	}
};

export const updateThemeColors = (payload) => {
	return {
		type: Actions.updateThemeColors + "request",
		payload: payload,
	};
};

const createBoardSlice = createSlice({
	name: "createBoard",
	initialState: initialState,
	reducers: {
		resetCreateBoard: (state, action) => {
			return {
				...initialState,
				...(action.payload || {}),
				boardTypes: state.boardTypes.length > 0 ? state.boardTypes : [],
			};
		},
		cancelClaim: (state) => {
			state.claimBoardForm = initialState.claimBoardForm;
			state.claimBoardGetAPIStatus = "idle";
			state.claimBoardRequestAPIStatus = "idle";
			state.boardIdToClaim = 0;
			state.activeBusinessFlow = "normal";
		},
		backToBusinessDetails: (state) => {
			state.claimBoardForm.boardId = 0;
		},
		proceedToClaim: (state) => {
			state.claimBoardForm.boardId = state.boardIdToClaim;
		},
		claimRequestFormUpdated: (state, action) => {
			const { context, value } = action.payload;

			switch (context) {
				case "phone":
					state.claimBoardForm.phoneNumber = value;
					state.errors.claimPhoneError =
						InputValidators.phoneNumber(value).hasError;
					break;

				case "notes":
					state.claimBoardForm.notes = value;
					break;

				default:
					break;
			}
		},
		startFreshBusiness: (state) => {
			return {
				...initialState,
				boardTypes: state.boardTypes.length > 0 ? state.boardTypes : [],
				boardType: state.boardType,
				parentBoardId: state.parentBoardId,
				currentStep: state.currentStep + 1,
				sentMixpanelEvents: state.sentMixpanelEvents,
			};
		},
		markEventAsSent: (state, action) => {
			state.sentMixpanelEvents = [
				...state.sentMixpanelEvents,
				action.payload,
			];
		},
		resetMarkedEvents: (state) => {
			state.sentMixpanelEvents = [];
		},
		setParentBoardId: (state, action) => {
			state.parentBoardId = action.payload.parentBoardId;
		},
		showValidationErrors: (state) => {
			state.errors.showAllFormErrors = true;
		},
		boardNameChanged: (state, action) => {
			const boardName = Filters.boardNameFilter(action.payload);
			const boardNameForValidation = boardName.trim();

			let boardNameError = true;
			let boardNameErrorMessage = "";

			if (boardNameForValidation && boardNameForValidation.length > 0) {
				if (boardNameForValidation.length > 80) {
					boardNameError = true;
					boardNameErrorMessage =
						"Board name should not be greater than 80 characters";
				} else {
					boardNameError = false;
					boardNameErrorMessage = "";
				}
			} else {
				boardNameError = true;
				boardNameErrorMessage =
					"Please enter a valid name for your board";
			}

			state.basicInfo.boardName = boardName;
			state.errors.boardNameError = boardNameError;
			state.errors.boardNameErrorMessage = boardNameErrorMessage;
		},
		boardCategoryChanged: (state, action) => {
			state.basicInfo.businessCategories = action.payload;
		},
		boardDescChanged: (state, action) => {
			const boardDesc = action.payload;
			const boardDescForValidation = (boardDesc || "").trim();

			let boardDescError = true;
			let boardDescErrorMessage = "";

			const isLessThan20Chars = boardDescForValidation.length < 20;
			const isMoreThan250Chars = boardDescForValidation.length > 250;

			if (isMoreThan250Chars) {
				boardDescError = true;
				boardDescErrorMessage =
					"A brief description of your board not greater than 250 characters is required";
			} else if (!state.isDataImported && isLessThan20Chars) {
				// only check for description validation if its a fresh create board process.
				// most of the time, people use import feature to avoid manual entires.
				// we need to keep it that way.
				boardDescError = true;
				boardDescErrorMessage =
					"A brief description of your board not less than 20 characters is required";
			} else {
				boardDescError = false;
				boardDescErrorMessage = "";
			}

			state.basicInfo.boardDesc = boardDesc;
			state.errors.boardDescError = boardDescError;
			state.errors.boardDescErrorMessage = boardDescErrorMessage;
		},
		boardLogoChanged: (state, action) => {
			state.basicInfo.boardLogo = action.payload;
			state.errors.boardLogoError = false;
		},
		boardTypeChanged: (state, action) => {
			state.boardType = action.payload.boardType;
		},
		updateLocation: (state, action) => {
			state.locationInfo = { ...state.locationInfo, ...action.payload };
			state.errors.locationsError = false;
			if (!state.locationInfo.addressLine1) {
				state.errors.locationsError = true;
			}
		},
		nextStep: (state) => {
			state.currentStep = state.currentStep + 1;
			state.errors.showAllFormErrors = false;
		},
		prevStep: (state) => {
			if (state.currentStep <= 0) return;

			state.currentStep = state.currentStep - 1;
		},
		jumpToStep: (state, action) => {
			let targetStep = action.payload;

			if (targetStep > state.currentStep) return;
			if (targetStep < state.currentStep) {
				state.currentStep = targetStep;
			}
		},
		contactInfoUpdated: (state, action) => {
			const { context, value } = action.payload;

			switch (context) {
				case "phone":
					state.contactInfo.phoneNumber = value;
					state.errors.phoneNumberError =
						InputValidators.phoneNumber(value).hasError;
					break;

				case "website":
					state.contactInfo.website = value;
					state.errors.websiteError =
						InputValidators.website(value).hasError;
					break;

				default:
					break;
			}
		},
		addBusinessPhotos: (state, action) => {
			state.businessPhotos = [...state.businessPhotos, ...action.payload];
		},
		removeBusinessPhoto: (state, action) => {
			state.businessPhotos = state.businessPhotos.filter(
				(_, index) => index !== action.payload
			);
		},
		updateBusinessHours: (state, action) => {
			state.businessHours = action.payload;
		},
		updateMixpanelEnteredEventWithPayload: (state, action) => {
			const newState = {
				...state,
				...(action.payload || {}),
			};
			return newState;
		},
		setThemeColors: (state, action) => {
			// we are limiting the number of colors to 3
			const [primary, background, secondary] = action.payload;
			state.boardThemeCustomColors = {
				colorPalette: "rec",
				font: secondary,
				background,
				button: primary,
			};
			const colorPalette = action.payload
				.splice(0, 3)
				.map((colorHex) => ({
					background: colorHex,
					isDark: isColorDark(colorHex),
				}));
			state.customHootThemeColors = colorPalette;
		},
	},
	extraReducers: {
		// saving all settings from board info response
		"/manageBoard/refreshBoardDetails/fulfilled": getDataFromBoardDetails,
		[Actions.getBoardTypes + "pending"]: (state) => {
			state.boardTypesAPIStatus = "pending";
			state.boardTypes = [];
		},
		[Actions.getBoardTypes + "fulfilled"]: (state, action) => {
			state.boardTypesAPIStatus = "fulfilled";
			// TODO: Remove the filter when we have the full list of board types.
			state.boardTypes = action.payload.filter(
				(item) => !["HW", "HV", "HO"].includes(item.boardType)
			);
		},
		[Actions.getBoardTypes + "rejected"]: (state) => {
			state.boardTypesAPIStatus = "rejected";
		},
		//
		[Actions.createBoard + "pending"]: (state) => {
			state.errors.basicInfoAPIError = false;
			state.stepProcessStatus = "pending";
			state.basicBoardDetailsFormSaveStatus = "pending";
		},
		[Actions.createBoard + "fulfilled"]: (state, action) => {
			state.stepProcessStatus = "idle";
			state.createdBoard = action.payload.boardInfo;
			state.currentStep = state.currentStep + 1;
			state.basicBoardDetailsFormSaveStatus = "fulfilled";
		},
		[Actions.createBoard + "rejected"]: (state) => {
			state.errors.basicInfoAPIError = true;
			state.stepProcessStatus = "rejected";
			state.basicBoardDetailsFormSaveStatus = "rejected";
		},
		//
		[Actions.searchBusinesses + "pending"]: (state) => {
			state.searchResults = [];
			state.searchAPIStatus = "pending";
		},
		[Actions.searchBusinesses + "fulfilled"]: (state, action) => {
			state.searchAPIStatus = "fulfilled";
			state.searchResults = action.payload;
		},
		[Actions.searchBusinesses + "rejected"]: (state) => {
			state.searchAPIStatus = "rejected";
		},
		//
		[Actions.getBusinessDetails + "request"]: (state, action) => {
			const boardId = action.payload;
			state.activeBusinessFlow = "claim";
			state.boardIdToClaim = boardId;
			state.claimBoardForm = initialState.claimBoardForm;
			state.errors.claimRequestAPIError = false;
		},
		[Actions.getBusinessDetails + "pending"]: (state) => {
			state.claimBoardGetAPIStatus = "pending";
		},
		[Actions.getBusinessDetails + "fulfilled"]: (state, action) => {
			state.claimBoardGetAPIStatus = "fulfilled";
			state.boardDetailsToClaim = action.payload;
		},
		[Actions.getBusinessDetails + "rejected"]: (state) => {
			state.claimBoardGetAPIStatus = "rejected";
		},
		[Actions.claimBusiness + "pending"]: (state) => {
			state.errors.claimRequestAPIError = false;
			state.claimBoardRequestAPIStatus = "pending";
		},
		[Actions.claimBusiness + "fulfilled"]: (state) => {
			state.claimBoardRequestAPIStatus = "fulfilled";
		},
		[Actions.claimBusiness + "rejected"]: (state) => {
			state.claimBoardRequestAPIStatus = "rejected";
			state.errors.claimRequestAPIError = true;
		},
		//
		[Actions.importBusiness + "request"]: (state) => {
			state.activeBusinessFlow = "import";
		},
		[Actions.importBusiness + "pending"]: (state) => {
			state.importBusinessAPIStatus = "pending";
		},
		[Actions.importBusiness + "fulfilled"]: (state, action) => {
			state.isDataImported = true;
			state.importBusinessAPIStatus = "fulfilled";

			// we get all business information in a certain format.
			// we need to convert it to the format that we use the form fields in our state.

			const data = action.payload || {};

			state.basicInfo = {
				boardName: data?.boardDetails?.boardName || "",
				boardDesc: data?.boardDetails?.boardDesc || "",
				boardLogo: data?.boardDetails?.boardLogo || "",
				businessCategories:
					data?.boardDetails?.businessCategories || [],
			};

			state.contactInfo = {
				phoneNumber:
					data?.businessDetails?.internationalNumber ||
					data?.businessDetails?.phoneNumber ||
					"",
				website: data?.businessDetails?.website || "",
			};

			state.locationInfo = data?.location;
			if (state.locationInfo) {
				state.locationInfo.addressLine1 =
					data?.location?.formattedAddress?.split(",")[0] || "";
			}

			if (data?.businessHours) {
				// business hours will only contain the values of enabled days.
				// we are filling the rest of the days with `active` as false.
				state.businessHours = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"]
					.map((item) => {
						const dayFound = (data?.businessHours || []).find(
							({ day }) => day === item
						);
						if (dayFound) return dayFound;
						return {
							day: item,
							openingHours: "09:00:00",
							closingHours: "18:00:00",
							active: false,
						};
					})
					.map((item) => {
						// sometimes, from the API, the time format will come as 00:00.
						// so, we are converting those to 00:00:00 format.
						const newResult = { ...item };

						if (item.openingHours.length === 5) {
							newResult.openingHours = item.openingHours + ":00";
						}

						if (item.closingHours.length === 5) {
							newResult.closingHours = item.closingHours + ":00";
						}

						return newResult;
					});
			}
			state.activeBusinessFlow = "normal";
			state.currentStep = state.currentStep + 1;
		},
		[Actions.importBusiness + "rejected"]: (state) => {
			state.importBusinessAPIStatus = "rejected";
			state.isDataImported = false;
		},
		//
		[Actions.importBusinessPhotos + "fulfilled"]: (state, action) => {
			state.businessPhotos = action.payload.map((item) => ({
				url: item.url.replace(
					"-w1-h1",
					`-w${item.width}-h${item.height}`
				),
				thumbnail: item.url.replace("-w1-h1", `-w200-h200`),
				isImported: true,
			}));
		},
		[Actions.importBusinessPhotos + "rejected"]: (state) => {
			state.businessPhotos = [];
		},
		//
		[BusinessActions.saveLocation + "pending"]: (state) => {
			state.errors.locationAPIError = false;
			state.stepProcessStatus = "pending";
			state.boardLocationFormSaveStatus = "pending";
		},
		[BusinessActions.saveLocation + "fulfilled"]: (state) => {
			state.stepProcessStatus = "idle";
			state.currentStep = state.currentStep + 1;
			state.boardLocationFormSaveStatus = "fulfilled";
		},
		[BusinessActions.saveLocation + "rejected"]: (state) => {
			state.errors.locationAPIError = true;
			state.stepProcessStatus = "rejected";
			state.boardLocationFormSaveStatus = "rejected";
		},
		//
		[BusinessActions.saveContactDetails + "pending"]: (state) => {
			state.errors.contactAPIError = false;
			state.stepProcessStatus = "pending";
			state.boardBusinessContactFormSaveStatus = "pending";
		},
		[BusinessActions.saveContactDetails + "fulfilled"]: (state) => {
			state.stepProcessStatus = "idle";
			state.currentStep = state.currentStep + 1;
			state.boardBusinessContactFormSaveStatus = "fulfilled";
		},
		[BusinessActions.saveContactDetails + "rejected"]: (state) => {
			state.errors.contactAPIError = true;
			state.stepProcessStatus = "rejected";
			state.boardBusinessContactFormSaveStatus = "rejected";
		},
		//
		[BusinessActions.saveBusinessHours + "pending"]: (state) => {
			state.errors.hoursAPIError = false;
			state.stepProcessStatus = "pending";
			state.boardBusinessHoursFormSavedStatus = "pending";
		},
		[BusinessActions.saveBusinessHours + "fulfilled"]: (state) => {
			state.stepProcessStatus = "idle";
			state.currentStep = state.currentStep + 1;
			state.boardBusinessHoursFormSavedStatus = "fulfilled";
		},
		[BusinessActions.saveBusinessHours + "rejected"]: (state) => {
			state.errors.hoursAPIError = true;
			state.stepProcessStatus = "rejected";
			state.boardBusinessHoursFormSavedStatus = "rejected";
		},
		//
		[BusinessActions.savePhotos + "pending"]: (state) => {
			state.errors.photosAPIError = false;
			state.stepProcessStatus = "pending";
			state.boardBusinessPhotosFormSaveStatus = "pending";
		},
		[BusinessActions.savePhotos + "fulfilled"]: (state) => {
			state.stepProcessStatus = "idle";
			state.currentStep = state.currentStep + 1;
			state.boardBusinessPhotosFormSaveStatus = "fulfilled";
		},
		[BusinessActions.savePhotos + "rejected"]: (state) => {
			state.errors.photosAPIError = true;
			state.stepProcessStatus = "rejected";
			state.boardBusinessPhotosFormSaveStatus = "rejected";
		},
		//
		[Actions.updateThemeColors + "pending"]: (state) => {
			return {
				...state,
				updateThemeColorsStatus: "pending",
			};
		},
		[Actions.updateThemeColors + "fulfilled"]: (state) => {
			return {
				...state,
				updateThemeColorsStatus: "fulfilled",
			};
		},
		[Actions.updateThemeColors + "rejected"]: (state) => {
			return {
				...state,
				updateThemeColorsStatus: "rejected",
			};
		},
		//
		[Actions.checkBusinessExists + "pending"]: (state) => {
			state.checkBusinessExistsAPIStatus = AsyncState.PENDING;
		},
		[Actions.checkBusinessExists + "fulfilled"]: (state, action) => {
			state.checkBusinessExistsAPIStatus = AsyncState.FULFILLED;
			state.checkBusinessExistsData = action.payload || {};
		},
		[Actions.checkBusinessExists + "rejected"]: (state) => {
			state.checkBusinessExistsAPIStatus = AsyncState.REJECTED;
		},
	},
});
export const {
	nextStep,
	jumpToStep,
	prevStep,
	cancelClaim,
	proceedToClaim,
	startFreshBusiness,
	backToBusinessDetails,
	claimRequestFormUpdated,
	markEventAsSent,
	resetMarkedEvents,
	addBusinessPhotos,
	removeBusinessPhoto,
	boardTypeChanged,
	boardNameChanged,
	boardCategoryChanged,
	boardDescChanged,
	boardLogoChanged,
	updateLocation,
	resetCreateBoard,
	showValidationErrors,
	updateBusinessHours,
	setParentBoardId,
	contactInfoUpdated,
	updateMixpanelEnteredEventWithPayload,
	setThemeColors,
} = createBoardSlice.actions;
export default createBoardSlice.reducer;
