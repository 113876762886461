import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as BoardsService from "../../api/boards.service";
import * as HootsService from "../../api/hoots.service";
import { transformHoots } from "../../utils/transformHoots.util";
import {
	filterExistingCollections,
	convertFromEndpoint,
	convertToEndpoint,
	tagsToCollectionSets,
} from "../ManageScreenApps/components/CollectionSelector/redux";
import { Actions } from "./bulletinBoard.saga";

const isFromHootTypeSelector = location.search.includes("fromHootTypeSelector");

export const selectCollectionApp = ({ app, state }) => {
	const {
		activeBoard,
		bulletinBoard: { fetchHootParams },
	} = state;

	const boardId = activeBoard.boardId;

	const { appName, appIconUrl } = app;
	const { collectionBehavior, endpoint: oldEndpoint } =
		app.boardAppsEndpoints[0];

	const endpoint = convertToEndpoint({
		collections: convertFromEndpoint({
			currentBoard: { boardId },
			endpoint: oldEndpoint,
			noBoardName: true,
		}),
		noHash: true,
	});

	const params = {
		...fetchHootParams,
		start: 0,
		showScheduledHoots: activeBoard.boardDetails
			? activeBoard.boardDetails.boardAdmin
			: false,
		searchString: endpoint.replace(/#/g, ""),
		collectionsUnion: true,
	};

	return {
		type: Actions.selectCollectionApp + "request",
		payload: {
			app: {
				appName,
				appIconUrl,
				endpoint,
				collectionBehavior,
			},
			boardId,
			params,
		},
	};
};

export const getRelatedTags = ({ boardId, searchString, collectionsUnion }) => {
	return {
		type: Actions.getRelatedTags + "request",
		payload: {
			boardId,
			params: {
				searchString,
				collectionsUnion,
			},
		},
	};
};

export const refreshData = createAsyncThunk(
	"/bulletinBoard/refreshData",
	async (x, thunkApi) => {
		const {
			bulletinBoard: {
				fetchHootParams,
				hoots,
				selectedCollections,
				collectionAppFilter,
			},
			activeBoard,
		} = thunkApi.getState();

		const params = {
			...fetchHootParams,
			start: 0,
			max: hoots.length,
			// collectionsUnion: false
		};

		if (collectionAppFilter.isActive) {
			params.collectionsUnion =
				collectionAppFilter.app.collectionBehavior === "U";

			const selectedEndpointsSets = convertFromEndpoint({
				endpoint: params.searchString,
				currentBoard: { boardId: activeBoard.boardId },
				noBoardName: true,
			});

			params.searchString = convertToEndpoint({
				collections: selectedEndpointsSets,
				noHash: true,
			});
		}

		const noCollectionsSelected = selectedCollections
			? selectedCollections.length === 0
			: true;

		if (noCollectionsSelected) {
			if (!collectionAppFilter.isActive) {
				const response = await BoardsService.getHoots(
					activeBoard.boardId,
					params
				);
				return {
					response,
					fetchHootParams: params,
					boardId: activeBoard.boardId,
					selectedCollections,
				};
			} else {
				params.collectionsUnion = true;
				params.searchString = convertToEndpoint({
					collections: collectionAppFilter.collections,
					noHash: true,
				});
			}
		}

		thunkApi.dispatch(
			getRelatedTags({
				boardId: activeBoard.boardId,
				searchString: params.searchString,
				collectionsUnion: params.collectionsUnion,
			})
		);

		const response = await HootsService.getHootsForCollections(
			activeBoard.boardId,
			params
		);

		return {
			response,
			fetchHootParams: params,
			boardId: activeBoard.boardId,
			selectedCollections,
		};
	}
);

export const fetchInitialData = createAsyncThunk(
	"/bulletinBoard/fetchInitialData",
	async ({ boardOptions }, thunkApi) => {
		const {
			bulletinBoard: {
				relatedTags,
				fetchHootParams,
				selectedCollections,
				collectionAppFilter,
			},
			activeBoard,
		} = thunkApi.getState();

		const params = {
			...fetchHootParams,
			collectionsUnion: false,
			showScheduledHoots: activeBoard.boardDetails
				? activeBoard.boardDetails.boardAdmin
				: false,
		};

		let collections = selectedCollections;

		if (boardOptions) {
			if (boardOptions.selectedCollections) {
				if (
					typeof boardOptions.selectedCollections === "string" &&
					boardOptions.selectedCollections !== ""
				) {
					collections = convertFromEndpoint({
						currentBoard: { boardId: activeBoard.boardId },
						endpoint: boardOptions.selectedCollections,
						noBoardName: true,
					});
					// collections = boardOptions.selectedCollections
					// 	.split(",")
					// 	.map(c => `#${c}`);
				} else {
					const containsString = [
						...boardOptions.selectedCollections,
					].some((item) => typeof item === "string");
					if (containsString) {
						collections = convertFromEndpoint({
							currentBoard: { boardId: activeBoard.boardId },
							endpoint: [
								...boardOptions.selectedCollections,
							].join(","),
							noBoardName: true,
						});
					} else {
						collections = boardOptions.selectedCollections;
					}
				}
			}

			if (boardOptions.upcomingEvents !== undefined) {
				params.upcomingEvents = boardOptions.upcomingEvents;
			}
			if (boardOptions.freeEvents !== undefined) {
				params.freeEvents = boardOptions.freeEvents;
			}
			if (boardOptions.collectionsUnion !== undefined) {
				params.collectionsUnion = boardOptions.collectionsUnion;
			}
		}

		const noCollectionsSelected = collections
			? collections.length === 0
			: true;

		const similarCollections = collectionAppFilter.isActive
			? collections.reduce((result, item) => {
					const newCollections = [
						...collectionAppFilter.collections,
						...relatedTags,
					].filter((item2) => item2.collection === item.collection);
					return [
						...result,
						...newCollections,
						...(newCollections.length === 0 ? [item] : []),
					];
			  }, [])
			: collections;

		params.searchString = convertToEndpoint({
			collections: similarCollections,
			noHash: true,
		});
		// params.searchString = noCollectionsSelected
		// 	? ""
		// 	: collections.map(c => c.slice(1, c.length)).join();

		if (collectionAppFilter.isActive) {
			params.collectionsUnion =
				collectionAppFilter.app.collectionBehavior === "U";

			const selectedEndpointsSets = convertFromEndpoint({
				endpoint: params.searchString,
				currentBoard: { boardId: activeBoard.boardId },
				noBoardName: true,
			});

			params.searchString = convertToEndpoint({
				collections: selectedEndpointsSets,
				noHash: true,
			});
		}

		if (noCollectionsSelected) {
			if (!collectionAppFilter.isActive) {
				const response = await BoardsService.getHoots(
					activeBoard.boardId,
					params
				);
				return {
					response,
					fetchHootParams: params,
					selectedCollections: collections,
					boardId: activeBoard.boardId,
				};
			} else {
				params.collectionsUnion = true;
				params.searchString = convertToEndpoint({
					collections: collectionAppFilter.collections,
					noHash: true,
				});
			}
		}

		thunkApi.dispatch(
			getRelatedTags({
				boardId: activeBoard.boardId,
				searchString: params.searchString,
				collectionsUnion: params.collectionsUnion,
			})
		);

		const response = await HootsService.getHootsForCollections(
			activeBoard.boardId,
			params
		);

		return {
			response,
			fetchHootParams: params,
			selectedCollections: collections,
			boardId: activeBoard.boardId,
		};
	}
);

export const applyFilterFetchHoots = createAsyncThunk(
	"/bulletinBoard/applyFilterFetchHoots",
	async ({ boardId }, thunkApi) => {
		const {
			bulletinBoard: { fetchHootParams, selectedCollections },
			activeBoard,
		} = thunkApi.getState();

		if (selectedCollections.length === 0) {
			const response = await BoardsService.getHoots(
				activeBoard.boardId,
				fetchHootParams
			);
			return {
				hoots: response ? response : [],
			};
		}

		thunkApi.dispatch(
			getRelatedTags({
				boardId: activeBoard.boardId,
				searchString: fetchHootParams.searchString,
				collectionsUnion: fetchHootParams.collectionsUnion,
			})
		);

		const response = await HootsService.getHootsForCollections(
			activeBoard.boardId,
			{
				...fetchHootParams,
				collectionsUnion: false,
				showScheduledHoots: activeBoard.boardDetails
					? activeBoard.boardDetails.boardAdmin
					: false,
			}
		);

		return {
			hoots: response ? response : [],
			boardId,
		};
	}
);

export const fetchMoreHoots = createAsyncThunk(
	"/bulletinBoard/fetchMoreHoots",
	async (x, thunkApi) => {
		const {
			activeBoard,
			bulletinBoard: {
				fetchHootParams,
				selectedCollections,
				collectionAppFilter,
			},
		} = thunkApi.getState();

		const params = { ...fetchHootParams };

		const noCollectionsSelected = selectedCollections
			? selectedCollections.length === 0
			: true;

		if (collectionAppFilter.isActive) {
			params.collectionsUnion =
				collectionAppFilter.app.collectionBehavior === "U";

			const selectedEndpointsSets = convertFromEndpoint({
				endpoint: params.searchString,
				currentBoard: { boardId: activeBoard.boardId },
				noBoardName: true,
			});

			params.searchString = convertToEndpoint({
				collections: selectedEndpointsSets,
				noHash: true,
			});
		}

		if (noCollectionsSelected) {
			if (!collectionAppFilter.isActive) {
				const response = await BoardsService.getHoots(
					activeBoard.boardId,
					params
				);
				return {
					response,
				};
			} else {
				params.collectionsUnion = true;
				params.searchString = convertToEndpoint({
					collections: collectionAppFilter.collections,
					noHash: true,
				});
			}
		}

		var response = await HootsService.getHootsForCollections(
			activeBoard.boardId,
			{
				...params,
				showScheduledHoots: activeBoard.boardDetails
					? activeBoard.boardDetails.boardAdmin
					: false,
			}
		);

		return { response };
	}
);

export const favoriteHootChanged = createAsyncThunk(
	"/bulletinBoard/favoriteHootChanged",
	async ({ hootId, favorite }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		let response;
		if (favorite) {
			response = await HootsService.favoriteHoot(boardId, hootId);
		} else {
			response = await HootsService.unfavoriteHoot(boardId, hootId);
		}
		return { response };
	}
);

const initialState = {
	boardId: null,

	isLoading: true,
	noMoreHoots: false,
	showNoMoreHootsBanner: false,
	isFetchingHootsForCollections: false,
	isFetchingMoreHoots: false,
	showRetweetModal: false,

	fetchHootParams: {
		start: 0,
		max: 20,
		addBoardPosts: true,
		upcomingEvents: false,
		freeEvents: false,
		showFeaturedCollection: false,
		showScheduledHoots: false,
		searchString: "",
		collectionsUnion: true,
	},

	hoots: [],

	collectionsMap: {},
	collections: [],
	relatedTags: [],
	selectedCollections: [],

	collectionAppFilter: {
		app: {
			appName: "",
			appIconUrl: "",
			endpoint: "",
			collectionBehavior: "",
		},
		isActive: false,
		collections: [],
	},

	iframeAppFilter: {
		app: {
			appName: "",
			appIconUrl: "",
			endpoint: "",
		},
		isActive: false,
	},

	showFilter: false,
	filter: {
		upcomingEvents: false,
		freeEvents: false,
		selectedCollections: {},
	},
};

const bulletinBoardSlice = createSlice({
	name: "bulletinBoard",
	initialState: initialState,
	reducers: {
		toggleIframeApp: (state, action) => {
			state.iframeAppFilter.app =
				action.payload || initialState.iframeAppFilter.app;
			state.iframeAppFilter.isActive = !!action.payload;
			state.collectionAppFilter = initialState.collectionAppFilter;
		},
		toggleFilter: (state, action) => {
			const boardId = action.payload.boardId;
			state.iframeAppFilter = initialState.iframeAppFilter;
			state.showFilter = !state.showFilter;
			state.filter.upcomingEvents = state.fetchHootParams.upcomingEvents;
			state.filter.freeEvents = state.fetchHootParams.freeEvents;
			state.filter.selectedCollections = convertFromEndpoint({
				endpoint: state.fetchHootParams.searchString,
				currentBoard: { boardId },
				noBoardName: true,
			})
				.filter((item) => +item.boardId === +boardId)
				.reduce(
					(result, item) => ({ ...result, [item.collection]: true }),
					{}
				);
			// : state.fetchHootParams.searchString
			// 		.split(",")
			// 		.reduce((prev, curr) => {
			// 			prev[`#${curr}`] = true;
			// 			return prev;
			// 		}, {});
		},
		filterUpcomingEventsChanged: (state, action) => {
			state.filter.upcomingEvents = action.payload;
		},
		filterFreeEventsChanged: (state, action) => {
			state.filter.freeEvents = action.payload;
		},
		filterSelectCollection: (state, action) => {
			state.filter.selectedCollections[action.payload.collection] = true;
		},
		filterDeselectCollection: (state, action) => {
			delete state.filter.selectedCollections[action.payload.collection];
		},
		reset: (state) => {
			state.isLoading = true;
			state.showFilter = false;
			state.collections = [];
			state.relatedTags = [];
			state.selectedCollections = [];
			state.iframeAppFilter = initialState.iframeAppFilter;
			state.collectionAppFilter = initialState.collectionAppFilter;
			state.filter.upcomingEvents = false;
			state.filter.freeEvents = false;
			state.filter.selectedCollections = {};
			state.hoots = [];
			state.noMoreHoots = false;
			state.fetchHootParams.start = 0;
			state.fetchHootParams.max = 20;
			state.fetchHootParams.addBoardPosts = true;
			state.fetchHootParams.upcomingEvents = false;
			state.fetchHootParams.freeEvents = false;
			state.fetchHootParams.showFeaturedCollection = false;
			state.fetchHootParams.showScheduledHoots = false;
			state.fetchHootParams.searchString = "";
			state.fetchHootParams.collectionsUnion = true;
			state.showNoMoreHootsBanner = false;
		},
		updateHootPinned: (state, action) => {
			const { pin, hootId } = action.payload;

			state.hoots = state.hoots.map((hoot) => {
				if (hoot.id === hootId) {
					return { ...hoot, isPinned: pin };
				}
				return hoot;
			});
		},
		updateHootRehoot: (state, action) => {
			const { selectedHootIndex } = action.payload;

			state.hoots[selectedHootIndex].hootStats.rehoots += 1;
		},
		updateHootFavorite: (state, action) => {
			const { selectedHootIndex, favorite } = action.payload;

			state.hoots[selectedHootIndex].favPost = favorite;
			state.hoots[selectedHootIndex].hootStats.favorites += favorite
				? 1
				: -1;
		},
		updateHootApproved: (state, action) => {
			const { selectedHootIndex, selectedHoot } = action.payload;

			if (selectedHootIndex && state.hoots[selectedHootIndex]) {
				state.hoots[selectedHootIndex].requiresApproval = false;
			} else {
				const foundHootIndex = state.hoots.findIndex(
					({ id }) => id === selectedHoot.id
				);
				if (foundHootIndex !== -1) {
					state.hoots[foundHootIndex].requiresApproval = false;
				}
			}
		},
		updateHootRejected: (state, action) => {
			const { selectedHootIndex, selectedHoot } = action.payload;

			if (selectedHootIndex) {
				state.hoots.splice(selectedHootIndex, 1);
			} else {
				const index = state.hoots.findIndex(
					({ id }) => id === selectedHoot.id
				);
				state.hoots.splice(index, 1);
			}
		},
		updateHootCommentCount: (state, action) => {
			const { selectedHootIndex, selectedHoot } = action.payload;

			if (selectedHootIndex) {
				state.hoots[selectedHootIndex].hootStats.comments += 1;
			} else {
				const index = state.hoots.findIndex(
					({ id }) => id === selectedHoot.id
				);
				state.hoots[index].hootStats.comments += 1;
			}
		},
		rehooted: (state, action) => {
			const index = state.hoots.findIndex(
				({ id }) => id === action.payload.id
			);

			if (index > -1) {
				state.hoots[index].hootStats.rehoots += 1;
			}
		},
		showNoMoreHootsBanner: (state) => {
			state.showNoMoreHootsBanner = true;
		},
		unSelectCollectionApp: (state) => {
			state.relatedTags = [];
			state.selectedCollections = [];
			state.collectionAppFilter = initialState.collectionAppFilter;
		},
	},
	extraReducers: {
		"saved/hoots/saveLocal/request": (state, action) => {
			const hootIds = action.payload.saveForLaterData.hoots;
			return {
				...state,
				hoots: state.hoots.map((hoot) => {
					if (hootIds.includes(hoot.id)) {
						return { ...hoot, isSaved: true };
					}
					return hoot;
				}),
			};
		},
		"saved/hoots/unsave/request": (state, action) => {
			const { hootId } = action.payload;
			return {
				...state,
				hoots: state.hoots.map((hoot) => {
					if (hoot.id === hootId) {
						return { ...hoot, isSaved: false };
					}
					return hoot;
				}),
			};
		},

		[Actions.selectCollectionApp + "request"]: (state, action) => {
			const { app, boardId } = action.payload;

			const collections = convertFromEndpoint({
				endpoint: app.endpoint,
				currentBoard: { boardId },
				noBoardName: true,
			});
			state.collectionAppFilter = {
				app,
				collections,
				isActive: true,
			};
			state.relatedTags = [];
			state.selectedCollections = [];
			state.iframeAppFilter = initialState.iframeAppFilter;
		},
		[Actions.selectCollectionApp + "pending"]: (state) => {
			state.isFetchingHootsForCollections = true;
			state.showNoMoreHootsBanner = false;
			state.noMoreHoots = false;
		},
		[Actions.selectCollectionApp + "fulfilled"]: (state, action) => {
			const { hoots, params } = action.payload;
			state.hoots = hoots.map(transformHoots).filter((hootItem) => {
				if (isFromHootTypeSelector)
					return !(hootItem.requiresApproval || hootItem.isScheduled);
				return hootItem;
			});
			state.fetchHootParams = params;
			state.isLoading = false;
			state.isFetchingHootsForCollections = false;
			state.noMoreHoots = state.hoots.length < 20;
			state.selectedCollections = [];
			state.fetchHootParams.start = state.hoots.length;
		},

		[Actions.getRelatedTags + "fulfilled"]: (state, action) => {
			state.relatedTags = filterExistingCollections({
				collections: tagsToCollectionSets(action.payload),
				selectedValues: state.collectionAppFilter.isActive
					? state.collectionAppFilter.collections
					: state.selectedCollections,
			});
		},

		[fetchInitialData.fulfilled]: (state, action) => {
			if (state.latestRequestId !== action.meta.requestId) {
				return;
			}

			const { response, fetchHootParams, selectedCollections, boardId } =
				action.payload;
			const { boardId: reqBoardId } = action.meta.arg;
			const hoots = response ? response : [];

			if (reqBoardId && reqBoardId !== boardId) {
				return;
			}

			// if (
			// 	!state.selectedCollections.every(
			// 		(c, i) => c === selectedCollections[i]
			// 	)
			// ) {
			// 	return;
			// }

			state.noMoreHoots = hoots.length < 20;
			state.hoots = hoots.map(transformHoots).filter((hootItem) => {
				if (isFromHootTypeSelector)
					return !(hootItem.requiresApproval || hootItem.isScheduled);
				return hootItem;
			});
			state.isLoading = false;
			state.isFetchingHootsForCollections = false;
			state.fetchHootParams = { ...fetchHootParams, start: hoots.length };

			const allLocalCollections = [...selectedCollections].every(
				(item) => +boardId === +item.boardId
			);

			if (allLocalCollections) {
				state.collections = filterExistingCollections({
					// collections: Object.keys(
					// 	hoots.reduce((prev, curr) => {
					// 		curr.collections.forEach(c => (prev[c] = true));
					// 		return prev;
					// 	}, {})
					// ).map(collection => ({
					// 	collection,
					// 	boardId
					// })),
					collections: [],
					selectedValues: selectedCollections,
				});
			} else {
				state.collections = [];
			}
		},
		[fetchInitialData.pending]: (state, action) => {
			state.hoots = [];
			state.relatedTags = [];
			state.isFetchingHootsForCollections = true;
			state.noMoreHoots = false;
			state.showNoMoreHootsBanner = false;
			state.latestRequestId = action.meta.requestId;

			if (action.meta.arg) {
				const {
					addCollection,
					removeCollection,
					boardOptions,
					freeEvents,
					boardId,
					upcomingEvents,
				} = action.meta.arg;

				if (boardOptions) {
					state.isLoading = true;

					if (boardOptions.selectedCollections) {
						if (
							typeof boardOptions.selectedCollections === "string"
						) {
							state.selectedCollections = convertFromEndpoint({
								currentBoard: { boardId },
								endpoint: boardOptions.selectedCollections,
								noBoardName: true,
							});
						} else {
							const containsString = [
								...boardOptions.selectedCollections,
							].some((item) => typeof item === "string");
							if (containsString) {
								state.selectedCollections = convertFromEndpoint(
									{
										currentBoard: {
											boardId,
										},
										endpoint: [
											...boardOptions.selectedCollections,
										].join(","),
										noBoardName: true,
									}
								);
							} else {
								state.selectedCollections =
									boardOptions.selectedCollections;
							}
						}
					}
				}

				if (addCollection) {
					state.isFetchingHootsForCollections = true;
					state.selectedCollections = [
						...state.selectedCollections,
						addCollection,
					];
					state.collections = [];
				} else if (removeCollection) {
					state.isFetchingHootsForCollections = true;
					state.selectedCollections =
						state.selectedCollections.filter(
							(item) =>
								item.collection !== removeCollection.collection
						);
					state.collections = [];
				}

				if (upcomingEvents !== undefined) {
					state.isFetchingHootsForCollections = true;
					state.fetchHootParams.upcomingEvents = upcomingEvents;
				}
				if (freeEvents !== undefined) {
					state.isFetchingHootsForCollections = true;
					state.fetchHootParams.freeEvents = freeEvents;
				}
			}
			state.fetchHootParams.start = 0;
		},
		[fetchInitialData.rejected]: (state) => {
			state.isLoading = false;
			state.isFetchingHootsForCollections = false;
			state.noMoreHoots = true;
		},

		[fetchMoreHoots.fulfilled]: (state, action) => {
			const { response } = action.payload;
			const newHoots =
				response.constructor.name === "Array"
					? response.map(transformHoots).filter((hootItem) => {
							if (isFromHootTypeSelector)
								return !(
									hootItem.requiresApproval ||
									hootItem.isScheduled
								);
							return hootItem;
					  })
					: [];
			const allHoots = [...state.hoots, ...newHoots];

			state.hoots = allHoots;
			state.isFetchingMoreHoots = false;
			state.noMoreHoots = newHoots.length < 20;
			state.fetchHootParams.start = allHoots.length;
		},
		[fetchMoreHoots.pending]: (state) => {
			state.isFetchingMoreHoots = true;
		},
		[fetchMoreHoots.rejected]: (state) => {
			state.isFetchingMoreHoots = false;
		},

		[favoriteHootChanged.fulfilled]: (state, action) => {
			const { response } = action.payload;
			const { hootId, favorite } = action.meta.arg;

			if (!response.success) {
				const index = state.hoots.findIndex(
					(hoot) => hoot.id === hootId
				);
				state.hoots[index].favPost = !favorite;
				state.hoots[index].hootStats.favorites += !favorite ? 1 : -1;
			}
		},
		[favoriteHootChanged.pending]: (state, action) => {
			const { hootId, favorite } = action.meta.arg;
			const index = state.hoots.findIndex((hoot) => hoot.id === hootId);

			state.hoots[index].favPost = favorite;
			state.hoots[index].hootStats.favorites += favorite ? 1 : -1;
		},
		[favoriteHootChanged.rejected]: (state, action) => {
			const { hootId, favorite } = action.meta.arg;
			const index = state.hoots.findIndex((hoot) => hoot.id === hootId);

			state.hoots[index].favPost = !favorite;
			state.hoots[index].hootStats.favorites += !favorite ? 1 : -1;
		},

		[applyFilterFetchHoots.fulfilled]: (state, action) => {
			const { hoots } = action.payload;

			state.hoots = hoots.map(transformHoots).filter((hootItem) => {
				if (isFromHootTypeSelector)
					return !(hootItem.requiresApproval || hootItem.isScheduled);
				return hootItem;
			});
			state.isLoading = false;
			state.isFetchingHootsForCollections = false;
			state.noMoreHoots = hoots.length < 20;
		},
		[applyFilterFetchHoots.pending]: (state, action) => {
			state.showFilter = false;
			state.isFetchingHootsForCollections = true;
			state.collections = [];

			const collections = Object.keys(
				state.filter.selectedCollections
			).map((collection) => ({
				collection,
				boardId: action.meta.arg.boardId,
			}));

			state.selectedCollections = collections;

			const params = {
				...state.fetchHootParams,
				start: 0,
				upcomingEvents: state.filter.upcomingEvents,
				freeEvents: state.filter.freeEvents,
				searchString: convertToEndpoint({ collections, noHash: true }),
			};
			state.fetchHootParams = params;
			state.noMoreHoots = false;
		},
		[applyFilterFetchHoots.rejected]: (state) => {
			state.isLoading = false;
			state.isFetchingHootsForCollections = false;
		},

		[refreshData.fulfilled]: (state, action) => {
			const {
				response,
				fetchHootParams,
				// selectedCollections,
				// boardId
			} = action.payload;
			state.isLoading = false;
			state.isFetchingHootsForCollections = false;
			// const { boardId: reqBoardId } = action.meta.arg;
			const hoots = response ? response : [];

			// if (reqBoardId && reqBoardId !== boardId) {
			// 	return;
			// }

			// if (
			// 	!state.selectedCollections.every(
			// 		(c, i) => c === selectedCollections[i]
			// 	)
			// ) {
			// 	return;
			// }
			state.noMoreHoots = hoots.length < 20;
			state.hoots = hoots.map(transformHoots).filter((hootItem) => {
				if (isFromHootTypeSelector)
					return !(hootItem.requiresApproval || hootItem.isScheduled);
				return hootItem;
			});
			state.fetchHootParams = {
				...fetchHootParams,
				start: hoots.length,
				max: 20,
			};
			// state.collections = Object.keys(
			// 	hoots.reduce((prev, curr) => {
			// 		curr.collections.forEach(c => (prev[c] = true));
			// 		return prev;
			// 	}, {})
			// );
		},
		[refreshData.pending]: (state) => {
			state.isFetchingHootsForCollections = true;
		},
		[refreshData.rejected]: (state) => {
			state.isLoading = false;
			state.isFetchingHootsForCollections = false;
		},

		"activeBoard/resetActiveBoard": (state) => {
			return {
				...initialState,
				latestRequestId: state.latestRequestId,
			};
		},
	},
});

export const {
	toggleFilter,
	unSelectCollectionApp,
	filterDeselectCollection,
	filterFreeEventsChanged,
	filterSelectCollection,
	filterUpcomingEventsChanged,
	reset,
	updateHootPinned,
	updateHootRehoot,
	updateHootFavorite,
	updateHootApproved,
	updateHootRejected,
	updateHootCommentCount,
	rehooted,
	toggleIframeApp,
	showNoMoreHootsBanner,
} = bulletinBoardSlice.actions;

export default bulletinBoardSlice.reducer;
