/*
 * @Author: snair@hootboard.com
 * @Date: 2020-06-01 15:56:13
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-07-23 09:17:54
 */

import { call, put, takeLatest } from "redux-saga/effects";
import * as ScreenApi from "../../api/screens.service";

function* createScreen(action) {
	try {
		yield put({
			type: "screen/create/pending",
			payload: {},
		});
		const loginRequest = yield call(
			async () =>
				await ScreenApi.createScreen(
					action.payload.boardId,
					action.payload.params
				)
		);
		if (loginRequest && loginRequest.screenId) {
			yield put({
				type: "screen/create/fulfilled",
				payload: {
					screenCreated: true,
					screenId: loginRequest.screenId,
				},
			});
		} else {
			yield put({
				type: "screen/create/rejected",
				payload: { screenCreated: false },
			});
		}
	} catch (e) {
		yield put({
			type: "screen/create/rejected",
			payload: {},
		});
	}
}

export function* createScreenSaga() {
	yield takeLatest("screen/create/request", createScreen);
}
