import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionState, AsyncState } from "../../types";
import {
	ListData,
	AddItemToListActionPayload,
	CreateNewListActionPayload,
	RemoveItemFromListActionPayload,
	UpdateUserAssignedDataPayload,
	UpdateListPayload,
	GetListPayload,
} from "../../types/lists";
import { getListItemDetails, sortListItemByDate } from "../../utils/lists.util";
import { Actions } from "./lists.saga";
import { transformListHootLike } from "../../utils/transformListHootLike.utils";
//

/**
 * Action for creating a new list.
 */
export const createNewList = createAction<CreateNewListActionPayload>(
	Actions.createNewList + ActionState.REQUEST
);

/**
 * Action for getting all the lists on page reload.
 */
export const getAllLists = createAction(
	Actions.getAllLists + ActionState.REQUEST
);

export const getList = createAction<GetListPayload>(
	Actions.getList + ActionState.REQUEST
);

export const getListById = createAction<number>(
	Actions.getListById + ActionState.REQUEST
);

export const saveList = createAction<string>(
	Actions.saveList + ActionState.REQUEST
);

export const addItemToList = createAction<AddItemToListActionPayload>(
	Actions.addItemToList + ActionState.REQUEST
);

/**
 * Action for removing an item from a list.
 */
export const removeItemFromList = createAction<RemoveItemFromListActionPayload>(
	Actions.removeItemFromList + ActionState.REQUEST
);

/**
 *Update user assigned data.
 */
export const updateUserAssignedData =
	createAction<UpdateUserAssignedDataPayload>(
		Actions.updateUserAssignedData + ActionState.REQUEST
	);

/**
 * Update list details
 */
export const updateList = createAction<UpdateListPayload>(
	Actions.updateList + ActionState.REQUEST
);
/**
 * Delete list
 */
export const deleteList = createAction<number>(
	Actions.deleteList + ActionState.REQUEST
);

/**
 * Action to get share token for a list.
 */
export const getShareToken = createAction<number>(
	Actions.getShareToken + ActionState.REQUEST
);

/**
 * Action to get list by share token.
 */
export const getListByToken = createAction<string>(
	Actions.getListByToken + ActionState.REQUEST
);

const initialState = {
	isMapOpen: false,
	selectedMapItemId: 0, // can be used to highlight certain item on the map.
	//
	listItemMappings: {} as { [itemValue: string]: string[] },
	availableLists: [] as ListData[],
	isCreateListModalVisible: false,
	listCreatedState: AsyncState.IDLE,
	listCreatedError: "",
	getListState: AsyncState.IDLE,
	getListError: "",
	getListByIdState: AsyncState.IDLE,
	getListByIdError: "",
	currentList: {} as ListData,
	isSaveListButtonClicked: false,
	saveListState: AsyncState.IDLE,
	saveListError: "",
	getListsState: AsyncState.IDLE,
	getListsError: "",
	addToListState: AsyncState.IDLE,
	addToListError: "",
	removeFromListState: AsyncState.IDLE,
	removeFromListError: "",
	activeListItemId: 0,
	userAssignedDataState: AsyncState.IDLE,
	userAssignedDataError: "",
	updateListDetailsState: AsyncState.IDLE,
	updateListDetailsError: "",
	deleteListState: AsyncState.IDLE,
	deleteListError: "",
	isListCardClicked: false,
	isEditListModalClicked: false,
	isDeleteListButtonClicked: false,
	isInfoPageActive: false,
	isMapDataAvailable: false,
	isSeeMoreButtonClicked: false,
	activeDropdownListId: 0,
	activeListCardIndex: 0,
	savedListUid: "",
	getShareTokenState: AsyncState.IDLE,
	getShareToken: "",
	getListByTokenState: AsyncState.IDLE,
	getListByTokenError: "",
};

const slice = createSlice({
	name: "lists",
	initialState,
	reducers: {
		toggleMapView: (state, action: PayloadAction<boolean>) => {
			const value = !!action.payload;
			state.isMapOpen = value;
			if (!value) {
				state.selectedMapItemId = 0;
			}
		},
		setSelectedMapItemId: (state, action: PayloadAction<number>) => {
			state.selectedMapItemId = action.payload;
		},
		toggleCreateListModal: (state) => {
			state.isCreateListModalVisible = !state.isCreateListModalVisible;
		},
		toggleListTimelinePage: (state) => {
			state.isListCardClicked = !state.isListCardClicked;
		},
		toggleEditListModal: (state) => {
			state.isEditListModalClicked = !state.isEditListModalClicked;
			state.updateListDetailsState = AsyncState.IDLE;
		},
		toggleDeleteListModal: (state) => {
			state.isDeleteListButtonClicked = !state.isDeleteListButtonClicked;
			state.deleteListState = AsyncState.IDLE;
		},
		updateActiveListItemId: (state, action: PayloadAction<number>) => {
			state.activeListItemId = action.payload;
		},
		updateSaveListButtonClick: (state, action: PayloadAction<boolean>) => {
			state.isSaveListButtonClicked = action.payload;
		},
		resetSaveListState: (state) => {
			state.saveListState = AsyncState.IDLE;
		},
		toggleInfoPageActive: (state) => {
			state.isInfoPageActive = !state.isInfoPageActive;
		},
		resetAddToListState: (state) => {
			state.addToListState = AsyncState.IDLE;
		},
		updateActiveDropdownListId: (state, action: PayloadAction<number>) => {
			state.activeDropdownListId = action.payload;
		},
		updateSavedListUid: (state, action: PayloadAction<string>) => {
			state.savedListUid = action.payload;
		},
		updateActiveListCardIndex: (state, action: PayloadAction<number>) => {
			state.activeListCardIndex = action.payload;
		},
		updateSeeMoreButtonClick: (state, action: PayloadAction<boolean>) => {
			state.isSeeMoreButtonClicked = action.payload;
		},
		// Reset reducer will be triggered upon closing the "myListPage"
		resetMyListPage: (state) => {
			state.activeListCardIndex = 0;
			state.isEditListModalClicked = false;
			state.isDeleteListButtonClicked = false;
			state.isListCardClicked = false;
			state.isSeeMoreButtonClicked = false;
		},
		// Reset Share Token 
		resetShareToken: (state) => {
			state.getShareTokenState= AsyncState.IDLE;
			state.getShareToken= "";
		},
	},
	extraReducers(builder) {
		builder.addCase(
			Actions.createNewList + ActionState.REQUEST,
			(state, action: PayloadAction<CreateNewListActionPayload>) => {
				const { listData, tempId } = action.payload;
				state.availableLists = [
					{ ...listData, listId: tempId },
					...state.availableLists,
				];
				state.listCreatedError = "";
			}
		);
		builder.addCase(
			Actions.createNewList + ActionState.PENDING,
			(state) => {
				state.listCreatedState = AsyncState.PENDING;
				state.listCreatedError = "";
			}
		);
		builder.addCase(
			Actions.createNewList + ActionState.FULFILLED,
			(state, action: any) => {
				state.listCreatedState = AsyncState.FULFILLED;
				state.listCreatedError = "";
				const request = action.payload
					.listData as CreateNewListActionPayload;
				const listId = action.payload.data as number;

				state.availableLists = state.availableLists.map((item) => {
					if (item.listId === request.tempId) {
						item.listId = listId;
					}
					return item;
				});
			}
		);
		builder.addCase(
			Actions.createNewList + ActionState.REJECTED,
			(state, action: PayloadAction<any>) => {
				const request = action.payload.data;
				state.availableLists = state.availableLists.filter(
					(list) => list.listId !== request.tempId
				);
				state.listCreatedState = AsyncState.REJECTED;
				state.listCreatedError = action.payload.message;
			}
		);
		//
		builder.addCase(Actions.getAllLists + ActionState.PENDING, (state) => {
			state.getListsState = AsyncState.PENDING;
			state.getListsError = "";
		});
		builder.addCase(
			Actions.getAllLists + ActionState.FULFILLED,
			(state, action: PayloadAction<ListData[]>) => {
				state.listItemMappings = {};
				state.availableLists = action.payload
					.map((item) => {
						for (const listItem of item.items) {
							const { itemValueData, listIds } =
								getListItemDetails({
									listItemMappings: state.listItemMappings,
									itemValue: listItem.itemValue,
								});
							state.listItemMappings[itemValueData] = [
								...listIds,
								`${item.listId}:${listItem.itemId}`,
							];
						}
						return { ...item, items: item.items };
					})
					.sort((a, b) => {
						return (b.listId || 0) - (a.listId || 0);
					});
				state.getListsState = AsyncState.FULFILLED;
				state.getListsError = "";
			}
		);
		builder.addCase(
			Actions.getAllLists + ActionState.REJECTED,
			(state, action: PayloadAction<any>) => {
				state.getListsState = AsyncState.REJECTED;
				state.getListsError = action.payload;
			}
		);
		//
		builder.addCase(
			Actions.addItemToList + ActionState.REQUEST,
			(state, action: PayloadAction<AddItemToListActionPayload>) => {
				const { listId, data } = action.payload;
				const { itemValueData, listIds } = getListItemDetails({
					itemValue: data.itemValue,
					listItemMappings: state.listItemMappings,
				});
				state.listItemMappings[itemValueData] = [
					...listIds,
					`${listId}:`,
				];
			}
		);
		builder.addCase(
			Actions.addItemToList + ActionState.PENDING,
			(state) => {
				state.addToListState = AsyncState.PENDING;
				state.addToListError = "";
			}
		);
		builder.addCase(
			Actions.addItemToList + ActionState.FULFILLED,
			(state, action: PayloadAction<any>) => {
				const request = action.payload
					.item as AddItemToListActionPayload;
				const newListItemId = action.payload.itemIds[0] as number;

				const { itemValueData, listIds } = getListItemDetails({
					itemValue: request.data.itemValue,
					listItemMappings: state.listItemMappings,
				});

				state.listItemMappings[itemValueData] = listIds.map(
					(item: any) => {
						if (item.includes(`${request.listId}:`))
							return `${request.listId}:${newListItemId}`;
						return item;
					}
				);
				state.addToListState = AsyncState.FULFILLED;
				state.addToListError = "";
			}
		);
		builder.addCase(
			Actions.addItemToList + ActionState.REJECTED,
			(state, action: PayloadAction<any>) => {
				state.addToListState = AsyncState.REJECTED;
				state.addToListError = action.payload;
			}
		);
		//
		builder.addCase(
			Actions.removeItemFromList + ActionState.REQUEST,
			(state, action: PayloadAction<RemoveItemFromListActionPayload>) => {
				const { listId, itemValue } = action.payload;
				const { itemValueData, listIds } = getListItemDetails({
					itemValue: itemValue,
					listItemMappings: state.listItemMappings,
				});
								state.listItemMappings[itemValueData] = listIds.filter(
					(item: any) => !item.includes(`${listId}:`)
				);
			}
		),
			builder.addCase(
				Actions.removeItemFromList + ActionState.PENDING,
				(state) => {
					state.removeFromListState = AsyncState.PENDING;
					state.removeFromListError = "";
				}
			);
		builder.addCase(
			Actions.removeItemFromList + ActionState.FULFILLED,
			(state, action: PayloadAction<RemoveItemFromListActionPayload>) => {
				state.listItemMappings[action.payload.itemValue] =
					state.listItemMappings[action.payload.itemValue].filter(
						(item) => item !== action.payload.listId.toString()
					);
				if (action.payload.listId === state.currentList.listId) {
					// Filtering the listBoard details, if the list item is removed from the timeline view.
					const removedItem = state.currentList.items.filter(
						(item) => item.itemId === action.payload.itemId
					);
					const boardItems = state.currentList.items.filter(
						(item) =>
							item.itemAddedOnBoardId ===
							removedItem[0].itemAddedOnBoardId
					);
					if (boardItems.length === 1) {
						const listBoards = state.currentList.listBoards?.filter(
							(listBoard) => {
								return (
									listBoard.id !==
									parseInt(boardItems[0].itemAddedOnBoardId)
								);
							}
						);
						state.currentList.listBoards = listBoards;
						state.availableLists.map((list) => {
							if (list.listId === action.payload.listId) {
								list.listBoards = listBoards;
							}
						});
					}
					//
					const currentItems = state.currentList.items.filter(
						(item) => {
							return action.payload.itemId !== item.itemId;
						}
					);
					state.currentList.items = currentItems;
				}
				state.removeFromListState = AsyncState.FULFILLED;
				state.removeFromListError = "";
			}
		);
		builder.addCase(
			Actions.removeItemFromList + ActionState.REJECTED,
			(state, action: PayloadAction<any>) => {
				state.removeFromListState = AsyncState.REJECTED;
				state.removeFromListError = action.payload;
			}
		);
		//Get single list
		builder.addCase(Actions.getList + ActionState.PENDING, (state) => {
			state.getListState = AsyncState.PENDING;
			state.getListError = "";
			state.isMapDataAvailable = false;
		});
		builder.addCase(
			Actions.getList + ActionState.FULFILLED,
			(state, action: PayloadAction<ListData>) => {
				state.getListState = AsyncState.FULFILLED;
				state.getListError = "";
				const newItems = action.payload.items.filter((item: any) => {
					if (item.itemData !== null) return item;
				});
				const hoots = newItems.map((item) => item.itemData);
				const currentListItemsData = hoots.map((item: any) =>
					transformListHootLike(item)
				);
				for (let i = 0; i < newItems.length; i++) {
					newItems[i].itemData = currentListItemsData[i];
				}
				state.currentList = {
					...action.payload,
					items: newItems,
				};
				state.isMapDataAvailable = action.payload.items.some(
					(item) => item.itemData?.locationInfo !== null
				);
			}
		);
		builder.addCase(
			Actions.getList + ActionState.REJECTED,
			(state, action: PayloadAction<any>) => {
				state.getListState = AsyncState.REJECTED;
				state.getListError = action.payload;
			}
		);
		//Get single list by listId
		builder.addCase(Actions.getListById + ActionState.PENDING, (state) => {
			state.getListByIdState = AsyncState.PENDING;
			state.getListByIdError = "";
			state.isMapDataAvailable = false;
		});
		builder.addCase(
			Actions.getListById + ActionState.FULFILLED,
			(state, action: PayloadAction<ListData>) => {
				state.getListByIdState = AsyncState.FULFILLED;
				state.getListByIdError = "";
				const newItems = action.payload.items.filter((item: any) => {
					if (item.itemData !== null) return item;
				});
				const hoots = newItems.map((item) => item.itemData);
				const currentListItemsData = hoots.map(transformListHootLike);
				for (let i = 0; i < newItems.length; i++) {
					newItems[i].itemData = currentListItemsData[i];
				}
				state.currentList = {
					...action.payload,
					items: newItems.reverse(),
				};
				state.currentList = sortListItemByDate(state.currentList);
				state.isMapDataAvailable = action.payload.items.some(
					(item) => item.itemData?.locationInfo !== null
				);
			}
		);
		builder.addCase(
			Actions.getListById + ActionState.REJECTED,
			(state, action: PayloadAction<any>) => {
				state.getListByIdState = AsyncState.REJECTED;
				state.getListByIdError = action.payload;
			}
		);
		// save list
		builder.addCase(Actions.saveList + ActionState.PENDING, (state) => {
			state.saveListState = AsyncState.PENDING;
			state.saveListError = "";
		});
		builder.addCase(Actions.saveList + ActionState.FULFILLED, (state) => {
			state.saveListState = AsyncState.FULFILLED;
			state.saveListError = "";
		});
		builder.addCase(
			Actions.saveList + ActionState.REJECTED,
			(state, action: PayloadAction<string>) => {
				state.saveListState = AsyncState.REJECTED;
				state.saveListError = action.payload;
			}
		);
		// Update user assigned data on list item
		builder.addCase(
			Actions.updateUserAssignedData + ActionState.PENDING,
			(state) => {
				state.userAssignedDataState = AsyncState.PENDING;
				state.userAssignedDataError = "";
			}
		);
		builder.addCase(
			Actions.updateUserAssignedData + ActionState.FULFILLED,
			(state, action: PayloadAction<UpdateUserAssignedDataPayload>) => {
				state.userAssignedDataState = AsyncState.FULFILLED;
				state.userAssignedDataError = "";
				const newItems = state.currentList.items.map((item) => {
					if (item.itemId === action.payload.itemId) {
						item.userAssignedDateTime =
							action.payload.data.userAssignedDateTime;
						item.userAssignedNote =
							action.payload.data.userAssignedNote;
					}
					return item;
				});
				state.currentList.items = newItems;
				state.currentList = sortListItemByDate(state.currentList);
			}
		);
		builder.addCase(
			Actions.updateUserAssignedData + ActionState.REJECTED,
			(state, action: PayloadAction<string>) => {
				state.userAssignedDataState = AsyncState.REJECTED;
				state.userAssignedDataError = action.payload;
			}
		);
		// Update list details
		builder.addCase(Actions.updateList + ActionState.PENDING, (state) => {
			state.updateListDetailsState = AsyncState.PENDING;
			state.updateListDetailsError = "";
		});
		builder.addCase(
			Actions.updateList + ActionState.FULFILLED,
			(state, action: PayloadAction<UpdateListPayload>) => {
				state.currentList = {
					...state.currentList,
					name: action.payload.data?.name,
					description: action.payload.data?.description,
					listImageUrl: action.payload.data.listImageUrl,
				};
				state.updateListDetailsState = AsyncState.FULFILLED;
				state.updateListDetailsError = "";
				state.availableLists.map((list) => {
					if (list.listId === action.payload.listId) {
						list.name = action.payload.data?.name;
						list.listImageUrl = action.payload.data.listImageUrl;
					}
				});
			}
		);
		builder.addCase(
			Actions.updateList + ActionState.REJECTED,
			(state, action: PayloadAction<string>) => {
				state.updateListDetailsState = AsyncState.REJECTED;
				state.updateListDetailsError = action.payload;
			}
		);
		// Delete list
		builder.addCase(Actions.deleteList + AsyncState.PENDING, (state) => {
			state.deleteListState = AsyncState.PENDING;
			state.deleteListError = "";
		});
		builder.addCase(
			Actions.deleteList + AsyncState.FULFILLED,
			(state, action: PayloadAction<number>) => {
				state.deleteListState = AsyncState.FULFILLED;
				state.deleteListError = "";
				const deletedListId = action.payload;
				const listIndex = state.availableLists.findIndex(
					(item) => item.listId === deletedListId
				);

				// Removing from the local mappings so that it shows correct counts.
				if (state.currentList.listId === deletedListId) {
					const itemValues = state.currentList.items.map(
						(item) => item.itemValue
					);
					itemValues.map((itemValue) => {
						state.listItemMappings[itemValue] =
							state.listItemMappings[itemValue].filter(
								(item) =>
									item.split(":")[0] !==
									deletedListId.toString()
							);
					});
				}
				//
				state.availableLists = state.availableLists.filter(
					(list: ListData) => list.listId !== action.payload
				);

				// Switching different list after deleted the list in mobile view
				if (state.availableLists.length > 0) {
					if (listIndex > 0) {
						state.activeListCardIndex = listIndex - 1;
					} else if (listIndex === 0) {
						state.activeListCardIndex = 0;
					} else {
						state.activeListCardIndex = listIndex + 1;
					}
				}

				// Setting currentList to empty.
				if (state.currentList.listId === deletedListId) {
					state.currentList = {} as ListData;
				}
			}
		);
		builder.addCase(
			Actions.deleteList + AsyncState.REJECTED,
			(state, action: PayloadAction<string>) => {
				(state.deleteListState = AsyncState.REJECTED),
					(state.deleteListError = action.payload);
			}
		);


		// Share Token
		builder.addCase(Actions.getShareToken + AsyncState.PENDING,
			(state) => {
				state.getShareTokenState = AsyncState.PENDING;
				state.getShareToken = "";
			})
		builder.addCase(Actions.getShareToken + AsyncState.FULFILLED,
			(state, action: PayloadAction<string>) => {
				state.getShareTokenState = AsyncState.FULFILLED;
				state.getShareToken = action.payload;

			})
		builder.addCase(Actions.getShareToken + AsyncState.REJECTED,
			(state) => {
				state.getShareTokenState = AsyncState.REJECTED;
				state.getShareToken = "";
			})

		//Get single list by share token
		builder.addCase(Actions.getListByToken + ActionState.PENDING, (state) => {
			state.getListByTokenState = AsyncState.PENDING;
			state.getListByTokenError = "";
		});
		builder.addCase(
			Actions.getListByToken + ActionState.FULFILLED,
			(state, action: PayloadAction<ListData>) => {
				state.getListByTokenState = AsyncState.FULFILLED;
				state.getListByTokenError = "";
				const newItems = action.payload.items.filter((item: any) => {
					if (item.itemData !== null) return item;
				});
				const hoots = newItems.map((item) => item.itemData);
				const currentListItemsData = hoots.map(transformListHootLike).reverse();
				for (let i = 0; i < newItems.length; i++) {
					newItems[i].itemData = currentListItemsData[i];
				}
				state.currentList = {
					...action.payload,
					items: newItems,
				};
				state.currentList = sortListItemByDate(state.currentList);
				state.isMapDataAvailable = action.payload.items.some(
					(item) => item.itemData?.locationInfo !== null
				);
			}
		);
		builder.addCase(
			Actions.getListByToken + ActionState.REJECTED,
			(state, action: PayloadAction<any>) => {
				state.getListByTokenState = AsyncState.REJECTED;
				state.getListByTokenError = action.payload;
			}
		);
	},
});
export const {
	toggleMapView,
	setSelectedMapItemId,
	toggleCreateListModal,
	toggleListTimelinePage,
	toggleEditListModal,
	toggleDeleteListModal,
	updateActiveListItemId,
	updateSaveListButtonClick,
	resetSaveListState,
	toggleInfoPageActive,
	resetAddToListState,
	updateActiveDropdownListId,
	updateActiveListCardIndex,
	updateSavedListUid,
	resetMyListPage,
	updateSeeMoreButtonClick,
	resetShareToken
} = slice.actions;
export default slice.reducer;
