/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-13 19:17:18
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-05-13 19:19:09
 */

import { createSlice } from "@reduxjs/toolkit";
const drawerSlice = createSlice({
	name: "drawer",
	initialState: {
		isOpen: false,
	},
	reducers: {
		openDrawer: (state) => {
			return {
				...state,
				isOpen: true,
			};
		},
		closeDrawer: (state) => {
			return {
				...state,
				isOpen: false,
			};
		},
	},
	extraReducers: {},
});
export const { openDrawer, closeDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
