/*
 * @Author: Chandu J S
 * @Date:   2020-08-07 12:21:25
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-08-07 17:02:50
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isLoading: true,
	isServerError: false,
};

const slice = createSlice({
	name: "activateAccount",
	initialState,
	reducers: {},
	extraReducers: {
		"activate-account/pending": () => {
			return initialState;
		},
		"activate-account/fulfilled": () => {
			return { ...initialState, isLoading: false };
		},
		"activate-account/rejected": () => {
			return {
				...initialState,
				isLoading: false,
				isServerError: true,
			};
		},
	},
});

export const activateAccountAsyncAction = ({ encUserId }) => {
	return {
		type: "activate-account/request",
		payload: { encUserId },
	};
};

// export const {} = slice.actions

export default slice.reducer;
