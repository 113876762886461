export const isEmpty = (values = []) => {
	let possible = values.filter((value) => {
		if (value && value.trim() && value.trim().length >= 1) {
			return false;
		} else {
			return true;
		}
	});

	return possible && possible.length ? true : false;
};

export const isEqual = (value1, value2) => {
	if (value1 === value2) {
		return true;
	} else {
		return false;
	}
};

export const isUrlValid = (inputUrl) => {
	const linkregex =
		/((([A-Za-z]{3,9}:(?:\/\/)?)[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/;
	return linkregex.test(inputUrl);
};

export const isEmailValid = (inputEmail) => {
	const emailreg =
		/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
	return emailreg.test(inputEmail);
};
