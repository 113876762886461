/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-24 16:25:23
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-05-26 00:03:13
 */

import { call, put, takeLatest } from "redux-saga/effects";
import * as AccountApi from "../../api/account.service";

function* getPersonalNotificationSettings() {
	try {
		yield put({ type: "user/notifications/get/pending", payload: {} });
		const notificationSettings = yield call(
			async () => await AccountApi.getPersonalNotificationSettings()
		);
		if (notificationSettings.status !== "error") {
			yield put({
				type: "user/notifications/get/fulfilled",
				payload: { notificationSettings: notificationSettings },
			});
		} else {
			yield put({
				type: "user/notifications/get/rejected",
				payload: { notificationSettings: null },
			});
		}
	} catch (e) {
		yield put({
			type: "user/notifications/get/rejected",
			payload: { notificationSettings: null },
		});
	}
}

export function* getPersonalNotificationSettingsSaga() {
	yield takeLatest(
		"user/notifications/get/request",
		getPersonalNotificationSettings
	);
}

function* updatePersonalNotificationSettings(action) {
	try {
		yield put({ type: "user/notifications/update/pending", payload: {} });
		const updateNotificationSettings = yield call(
			async () =>
				await AccountApi.updatePersonalNotificationSettings(
					action.payload.params
				)
		);
		if (updateNotificationSettings.status !== "error") {
			const notificationSettings = yield call(
				async () => await AccountApi.getPersonalNotificationSettings()
			);
			if (notificationSettings.status !== "error") {
				yield put({
					type: "user/notifications/update/fulfilled",
					payload: { notificationSettings: notificationSettings },
				});
			} else {
				yield put({
					type: "user/notifications/update/rejected",
					payload: { notificationSettings: null },
				});
			}
		} else {
			yield put({
				type: "user/notifications/update/rejected",
				payload: { notificationSettings: null },
			});
		}
	} catch (e) {
		yield put({
			type: "user/notifications/update/rejected",
			payload: { notificationSettings: null },
		});
	}
}

export function* updatePersonalNotificationSettingsSaga() {
	yield takeLatest(
		"user/notifications/update/request",
		updatePersonalNotificationSettings
	);
}
