import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import * as API from "../../api/boards.service";

export const Actions = {
	getAllCompletedSteps: "onBoarding/get-completed-steps/",
	markStepAsDone: "onBoarding/mark-step-as-done/",
};

function* getAllCompletedStepsSaga() {
	yield takeLatest(
		Actions.getAllCompletedSteps + "request",
		function* (action) {
			try {
				yield put({
					type: Actions.getAllCompletedSteps + "pending",
					payload: {},
				});
				const serverResponse = yield call(
					async () =>
						await API.getAllCompletedSteps({
							boardId: action.payload,
						})
				);

				if (serverResponse && serverResponse.message === "success") {
					yield put({
						type: Actions.getAllCompletedSteps + "fulfilled",
						payload: serverResponse.data || [],
					});
				} else {
					throw new Error(serverResponse);
				}
			} catch (e) {
				yield put({
					type: Actions.getAllCompletedSteps + "rejected",
					payload: {},
				});
			}
		}
	);
}

function* markOnBoardingAsDoneStepSaga() {
	yield takeEvery(Actions.markStepAsDone + "request", function* (action) {
		try {
			const { boardId, startGuideTourName } = action.payload;
			// we don't need to save on server if we are just marking step as done locally.
			if (action.payload.saveOnlyLocal) return;

			yield put({
				type: Actions.markStepAsDone + "pending",
				payload: {},
			});

			const apiResponse = yield call(async () => {
				return await API.markStepAsDone({
					data: {
						startGuideTourName: startGuideTourName,
						startGuideTourStatus: "completed",
					},
					boardId,
				});
			});
			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: Actions.markStepAsDone + "fulfilled",
					payload: { startGuideTourName },
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (error) {
			yield put({
				type: Actions.markStepAsDone + "rejected",
				payload: error,
			});
		}
	});
}
export const onBoardingSagas = [
	getAllCompletedStepsSaga(),
	markOnBoardingAsDoneStepSaga(),
];
