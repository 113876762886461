import {
	apiRequest,
	httpDeleteV2,
	httpGetV2,
	httpPostV2,
	httpPutV2
} from "./http-wrapper";

/**
 * API to get screen apps
 * @param {number} boardId
 */
export const getScreenApps = async (boardId) => {
	return apiRequest(`boards/${boardId}/apps`, {
		payload: { sharedCollections: true },
	});
};

/**
 * API to create screen app
 * @param {number} boardId
 * @param {Object} params
 */
export const createApp = async (boardId, params) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpPostV2(
		"private/screens/apps",
		params,
		apiConfig
	);
	return response;
};

/**
 * API to update screen app
 * @param {number} boardId
 * @param {number} appId
 * @param {Object} params
 */
export const updateApp = async (boardId, appId, params) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpPutV2(
		`private/screens/apps/${appId}`,
		params,
		apiConfig
	);
	return response;
};

/**
 * API to delete screen app
 * @param {number} boardId
 * @param {number} appId
 */
export const deleteApp = async (boardId, appId) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpDeleteV2(
		`private/screens/apps/${appId}`,
		null,
		apiConfig
	);
	return response;
};

/**
 * API to get app categories
 * @param {number} boardId
 */
export const getAppCategories = async (boardId) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpGetV2("private/platform/apps", null, apiConfig);
	return response;
};

/**
 * API to get board apps
 * @param {number} boardId
 */
export const getBoardApps = async (boardId) => {
	return apiRequest(`boards/${boardId}/apps`, {
		payload: { sharedCollections: true },
	});
};

/**
 * API to update screen app sequence
 * @param {number} boardId
 * @param {Object} params
 */
export const updateSequence = async (boardId, params) => {
	const apiConfig = { "x-board-id": boardId };

	const response = await httpPutV2("private/screens/apps", params, apiConfig);
	return response;
};

/**
 * API to get a signed upload request.
 * @param {number} boardId
 * @param {object} params
 * @param {string} params.fileName
 * @param {string} params.fileType
 */
export const fetchSignedUploadRequest = (boardId, params) => {
	return apiRequest("upload/sr", {
		payload: {
			...params,
			context: "files",
		},
		extraHeaders: { "x-board-id": boardId },
	});
};
