import { createSlice } from "@reduxjs/toolkit";
import { Actions } from "./onBoarding.saga";

export const getAllCompletedSteps = (boardId) => {
	return {
		type: Actions.getAllCompletedSteps + "request",
		payload: boardId,
	};
};

export const markStepAsDone = ({
	boardId,
	startGuideTourName,
	saveOnRequest = false,
}) => {
	return {
		type: Actions.markStepAsDone + "request",
		payload: { boardId, startGuideTourName, saveOnRequest },
	};
};

const initialState = {
	completedSteps: [], //it is array of string
	getAllCompletedStepsAPIStatus: "idle",
	markStepAsDoneAPIStatus: "idle",
	isQuickStartGuideOpen: false,
};

const onBoardingSlice = createSlice({
	name: "onBoarding",
	initialState: initialState,
	reducers: {
		resetGetAllCompletedStepsAPIStatus: (state) => {
			state.getAllCompletedStepsAPIStatus = "idle";
		},
		setQuickStartGuide: (state, action) => {
			state.isQuickStartGuideOpen = action.payload;
		},
		resetOnboarding: () => {
			return initialState;
		},
	},

	extraReducers: {
		[Actions.getAllCompletedSteps + "pending"]: (state) => {
			state.getAllCompletedStepsAPIStatus = "pending";
		},
		[Actions.getAllCompletedSteps + "fulfilled"]: (state, action) => {
			state.getAllCompletedStepsAPIStatus = "fulfilled";

			// We are getting payload as an array of object format.
			// But we need to convert it as an array of string format(all completed steps).

			state.completedSteps = action.payload.map(
				(item) => item.startGuideTourName
			);
		},
		[Actions.getAllCompletedSteps + "rejected"]: (state) => {
			state.getAllCompletedStepsAPIStatus = "rejected";
		},

		[Actions.markStepAsDone + "request"]: (state, action) => {
			if (action.payload.saveOnRequest) {
				const onboardingCompletedStepName =
					action.payload.startGuideTourName;
				// Once step is completed update the state.
				state.completedSteps = [
					...state.completedSteps,
					onboardingCompletedStepName,
				];
			}
		},

		[Actions.markStepAsDone + "pending"]: (state) => {
			state.markStepAsDoneAPIStatus = "pending";
		},
		[Actions.markStepAsDone + "fulfilled"]: (state, action) => {
			state.markStepAsDoneAPIStatus = "fulfilled";
			if (!action.payload.saveOnRequest) {
				const onboardingCompletedStepName =
					action.payload.startGuideTourName;
				// Once step is completed update the state.
				state.completedSteps = [
					...state.completedSteps,
					onboardingCompletedStepName,
				];
			}
		},
		[Actions.markStepAsDone + "rejected"]: (state) => {
			state.markStepAsDoneAPIStatus = "rejected";
		},
	},
});
export const {
	resetGetAllCompletedStepsAPIStatus,
	setQuickStartGuide,
	resetOnboarding,
} = onBoardingSlice.actions;

export default onBoardingSlice.reducer;
