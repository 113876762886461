/* eslint-disable react/prop-types */
/* FIXME: Fix lint issues. */
import { useRef, useState, useEffect } from "react";
//
import Spinner from "../../hb-ui/Spinners/circle";
import { HealthyImage } from "../../hb-ui/HealthyImage";
//
import { cl } from "../../utils/general.util";

export const StandardLoader = ({ type, className = "" }) => {
	const loadingRef = useRef(null);
	const [height, setHeight] = useState(1);

	useEffect(() => {
		if (!loadingRef.current) return;
		let { offsetTop } = loadingRef.current;
		setHeight(offsetTop);
	}, [loadingRef]);

	const isSpinner = type === "spinner";

	return (
		<div
			ref={loadingRef}
			style={{ height: `calc(100vh - ${height}px)` }}
			className={cl("grid grid-cols-12 w-full", className)}
		>
			<div className="col-span-12 w-full h-full flex justify-center items-center">
				<div className="w-24 h-24 overflow-hidden relative">
					{isSpinner && <Spinner className="spinner" />}
					{!isSpinner && (
						<HealthyImage
							className="w-full h-full absolute"
							src="/assets/loading.gif"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default StandardLoader;
