import { FilestackClient } from "../components/FileStack.component";
import { FILESTACK_KEY } from "../helper/constants";

/**
 * Used to upload icon to filestack
 * @param {Object} params
 * @param {imageData} params.file - image
 */
export const saveAppIcon = async (params) => {
	try {
		const response = await FilestackClient.upload(params.file);
		return response;
	} catch (error) {
		console.log(error);
	}
};

/**
 * Used to transform a transparent SVG icon to a colored SVG icon
 * based on the svg icon url & color passed to the function, return a binary image
 * @param {Object} params
 * @param {string} params.iconUrl - svg url
 * @param {string} params.selectedColor - hex decimal color value
 */

export const transformSVG = async (params) => {
	const newIcon = await new Promise((resolve) => {
		// this image will hold our source image data
		const inputImage = new Image();
		inputImage.crossOrigin = "anonymous";

		// generated image size
		const imageSize = 200;
		// padding inside the rectangle
		const padding = 20;

		// we want to wait for our image to load
		inputImage.onload = () => {
			// create a canvas that will present the output image
			const outputImage = document.createElement("canvas");

			outputImage.width = imageSize;
			outputImage.height = imageSize;

			// draw our image at position 0, 0 on the canvas
			const ctx = outputImage.getContext("2d");
			ctx.fillStyle = params.selectedColor;
			ctx.fillRect(0, 0, outputImage.width, outputImage.height);

			ctx.drawImage(
				inputImage,
				padding,
				padding,
				outputImage.width - padding * 2,
				outputImage.height - padding * 2
			);

			const imageData = outputImage.toDataURL();
			resolve(imageData);
		};

		// start loading our image
		inputImage.src = `https://process.filestackapi.com/${FILESTACK_KEY}/${params.iconUrl}`;
	});

	return newIcon;
};

/**
 * Function to sort the apps by page number and sequence number.
 */
export const sortApps = (boardApps) => {
	const sortedApps = boardApps.sort((app1, app2) => {
		if (app1.pageNum !== app2.pageNum) {
			return app1.pageNum - app2.pageNum;
		} else {
			return app1.seqNumber - app2.seqNumber;
		}
	});
	return sortedApps;
};
