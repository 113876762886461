import moment from "moment";

import ColorPalette from "./color.utils";

export const transformSequenceSlots = (item) => item;

export const transformSequence = (data) => data;

export const transformSequences = (item, index) => {
    const colors = [
        ...Object.values(ColorPalette.colors),
        ...Object.values(ColorPalette.userColors),
    ].filter((_, index) => index > 1);
    return {
        ...item,
        theme: colors[index],
    };
};

export const transformCreateSequencePayload = (data, id) => {
    // Filter out properties other than zones.assignedContent
    let seqItems = [...data.sequenceItems];
    const updatedSeqItemVal = seqItems.map((seq) => {
        if (
            seq.seqItemValue !== null &&
            seq.seqItemValue.data &&
            seq.seqItemValueType == "layoutConfig"
        ) {
            const layoutId = seq.seqItemValue.data.id;
            const layoutIconUrl = seq.seqItemValue.data.iconUrl;
            const layoutOrientation = seq.seqItemValue.data.orientation;
            const zones = seq.seqItemValue.data.zones.map((zone) => {
                let itemVal;
                switch (zone.assignedContent.type) {
                    case "appId":
                        itemVal =
                            zone.assignedContent.value[0]?.boardAppsId ||
                            zone.assignedContent.value.id;
                        break;
                    case "hootId":
                        itemVal =
                            zone.assignedContent.value.postId || zone.assignedContent.value.id;
                        break;
                    default:
                        itemVal = zone.assignedContent.value;
                        break;
                }

                return {
                    zoneId: zone.id,
                    itemValue: itemVal,
                    itemType: zone.assignedContent.type,
                    position: zone.position,
                };
            });
            return {
                ...seq,
                seqItemValue: JSON.stringify({
                    layoutId,
                    layoutIconUrl,
                    layoutOrientation,
                    zones: [...zones],
                }),
            };
        } else {
            return seq;
        }
    });
    if (id) {
        return {
            name: data.name,
            description: data.description,
            orientation: data.orientation,
            id: data.id,
            createdBy: data.createdBy,
            isEditable: data.isEditable,
            object: data.object,
            sequenceItems: updatedSeqItemVal,
        };
    } else {
        return {
            name: data.name,
            description: data.description,
            orientation: data.orientation,
            sequenceItems: updatedSeqItemVal,
        };
    }
};

export const checkIfContentIsAssignedToEveryZone = (zones) => {
    if (zones.every((zone) => Object.values(zone.assignedContent).length > 0)) {
        return true;
    } else return false;
};

const getHootImages = (data) => {
    let images = [];
    const localData = data;
    if (localData.images && localData.images.length > 0) {
        images = localData.images.filter((img) => img.size == "LG");
    } else if (localData.thumbnail && localData.thumbnail.length > 0) {
        images = [{ url: localData.thumbnail }];
    } else if (localData.internalImages && localData.internalImages.length > 0) {
        images = localData.internalImages.map((image) => ({ url: image }));
    } else if (localData.thumbPath) {
        images = [{ url: localData.thumbPath }];
    } else if (localData.hootCoverImages && Object.keys(localData.hootCoverImages).length > 0) {
        if (
            localData.hootCoverImages.large !== null &&
            localData.hootCoverImages.large[0].url.length > 0
        ) {
            images = localData.hootCoverImages.large;
        }
    }

    if (images.length <= 0) {
        images = [{ url: "" }];
    }

    return images.slice(0, 4);
};
//
const getEventData = (localData) => {
    if (
        (localData.majorCategoryCode && localData.majorCategoryCode == "EVENT") ||
        (localData.postingCategory && localData.postingCategory == "EVENT")
    ) {
        const startDateTime = moment(
            localData.eventStart || localData.eventInfo.eventStartDateTime,
        );
        const endDateTime = moment(localData.eventEnd || localData.eventInfo.eventEndDateTime);
        if (startDateTime._isValid && endDateTime._isValid) {
            let formattedOutput = startDateTime.format("ddd, YYYY MMM Do h:mm A");

            // Check if the event spans multiple days
            if (!startDateTime.isSame(endDateTime, "day")) {
                formattedOutput += ` to ${endDateTime.format("ddd, YYYY MMM Do h:mm A")} (IST)`;
            } else {
                formattedOutput += ` to ${endDateTime.format("h:mm A")} ${moment
                    .tz(localData.hootTimezone)
                    .format("z")}`;
            }

            return formattedOutput;
        } else {
            return null;
        }
    } else return null;
};
//

const getIsHootFullScreen = (localData) => {
    if (localData.signageSettings) {
        if (localData.signageSettings.isFullScreen) return true;
        else return false;
    }
    if (localData.digFullScreenHoot) return true;
    else return false;
};

const getVideoData = (localData) => {
    if (localData.hootType == "HLVID" || localData.type == "HLVID") {
        const data =
            localData.embedMediaInfo && Object.values(localData.embedMediaInfo).length > 0
                ? localData.embedMediaInfo
                : localData.embedableMedia && localData.embedableMedia.length > 0
                ? { html: localData.embedableMedia, type: "", url: localData.embededMediaUrl }
                : { html: "", type: "", url: "" };
        return data;
    }
};
//
export const transformZoneValue = (zone, data) => {
    if (zone.assignedContent.type === "hootId") {
        const localData = data;
        const images = getHootImages(data);
        const newValue = {
            colorInfo: localData.colorInfo || ColorPalette.colors[localData.hootBgColor] || {},
            images,

            title: localData.title || localData.postName,
            descriptionPlainText: localData.descriptionPlainText || localData.postDescPlainText,
            isFullScreen: getIsHootFullScreen(localData),
            timeout:
                localData.digHootTimeoutInSec * 1000 ||
                localData.signageSettings.timeout * 1000 ||
                15000,
        };
        const eventData = getEventData(localData);
        if (eventData !== "null") {
            newValue.eventDate = eventData;
        }

        if (getVideoData(localData)) {
            newValue.embedMediaInfo = getVideoData(localData);
        }
        return newValue;
    } else if (zone.assignedContent.type === "appId") {
        const localData = data;
        return { endpoints: localData.endpoints || localData };
    } else {
        return data;
    }
};
