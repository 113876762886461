import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import * as SequenceAPI from "../../api/sequences.service";

import { ACTION_STATES } from "../../helper/constants";

export const Actions = {
    getDefaultSequences: "sequence/get-defaults/",
    getAllSequences: "sequence/get-all/",
    getSequence: "sequence/get/",
    createSequence: "sequence/create/",
    deleteSequence: "sequence/delete/",
    saveSequence: "sequence/save/",
    assignSequence: "sequence/assign/",
    updateSchedule: "sequence/update-schedule/",
    unAssignSequence: "sequence/un-assign/",
    getAvailability: "sequence/get-availability/",
    markAsDefault: "sequence/mark-as-default/",
    loadPreviewContent: "sequence/load-preview-content/",
    fetchLayouts: "sequence/get-layouts/",
};

function* getDefaultSequencesSaga() {
    yield takeLatest(Actions.getDefaultSequences + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.getDefaultSequences + ACTION_STATES.PENDING,
                payload: {},
            });
            const serverResponse = yield call(
                async () => await SequenceAPI.getDefaultSequences(action.payload),
            );

            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.getDefaultSequences + ACTION_STATES.FULFILLED,
                payload: serverResponse.data,
            });
        } catch (e) {
            yield put({
                type: Actions.getDefaultSequences + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

function* getAllSequencesSaga() {
    yield takeLatest(Actions.getAllSequences + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.getAllSequences + ACTION_STATES.PENDING,
                payload: {},
            });
            const serverResponse = yield call(
                async () => await SequenceAPI.getAllSequences(action.payload),
            );

            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.getAllSequences + ACTION_STATES.FULFILLED,
                payload: serverResponse.data,
            });
        } catch (e) {
            yield put({
                type: Actions.getAllSequences + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

function* getSequenceSaga() {
    yield takeLatest(Actions.getSequence + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.getSequence + ACTION_STATES.PENDING,
                payload: {},
            });
            const serverResponse = yield call(
                async () => await SequenceAPI.getSequence(action.payload),
            );
            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.getSequence + ACTION_STATES.FULFILLED,
                payload: serverResponse.data,
            });
        } catch (e) {
            yield put({
                type: Actions.getSequence + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

function* createSequenceSaga() {
    yield takeLatest(Actions.createSequence + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.createSequence + ACTION_STATES.PENDING,
                payload: {},
            });
            const serverResponse = yield call(
                async () => await SequenceAPI.createSequence(action.payload),
            );

            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.createSequence + ACTION_STATES.FULFILLED,
                payload: serverResponse.data,
            });

            yield put({
                type: Actions.getAllSequences + ACTION_STATES.REQUEST,
                payload: action.payload.boardId,
            });
        } catch (e) {
            yield put({
                type: Actions.createSequence + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

function* deleteSequenceSaga() {
    yield takeLatest(Actions.deleteSequence + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.deleteSequence + ACTION_STATES.PENDING,
                payload: {},
            });
            const serverResponse = yield call(
                async () => await SequenceAPI.deleteSequence(action.payload),
            );

            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.deleteSequence + ACTION_STATES.FULFILLED,
                payload: serverResponse.data,
            });

            yield put({
                type: Actions.getAllSequences + ACTION_STATES.REQUEST,
                payload: action.payload.boardId,
            });
        } catch (e) {
            yield put({
                type: Actions.deleteSequence + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

function* saveSequenceSaga() {
    yield takeLatest(Actions.saveSequence + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.saveSequence + ACTION_STATES.PENDING,
                payload: {},
            });
            const serverResponse = yield call(
                async () => await SequenceAPI.saveSequence(action.payload),
            );

            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.saveSequence + ACTION_STATES.FULFILLED,
                payload: serverResponse.data,
            });

            yield put({
                type: Actions.getAllSequences + ACTION_STATES.REQUEST,
                payload: action.payload.boardId,
            });
        } catch (e) {
            yield put({
                type: Actions.saveSequence + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

function* assignSequenceSaga() {
    yield takeLatest(Actions.assignSequence + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.assignSequence + ACTION_STATES.PENDING,
                payload: {},
            });
            const serverResponse = yield call(
                async () => await SequenceAPI.assignSequence(action.payload),
            );

            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.assignSequence + ACTION_STATES.FULFILLED,
                payload: serverResponse.data,
            });
        } catch (e) {
            yield put({
                type: Actions.assignSequence + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

function* updateScheduleSaga() {
    yield takeLatest(Actions.updateSchedule + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.updateSchedule + ACTION_STATES.PENDING,
                payload: {},
            });
            const serverResponse = yield call(
                async () => await SequenceAPI.updateSchedule(action.payload),
            );

            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.updateSchedule + ACTION_STATES.FULFILLED,
                payload: serverResponse.data,
            });
        } catch (e) {
            yield put({
                type: Actions.updateSchedule + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

function* unAssignSequenceSaga() {
    yield takeLatest(Actions.unAssignSequence + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.unAssignSequence + ACTION_STATES.PENDING,
                payload: {},
            });
            const serverResponse = yield call(
                async () => await SequenceAPI.unAssignSequence(action.payload),
            );

            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.unAssignSequence + ACTION_STATES.FULFILLED,
                payload: serverResponse.data,
            });
        } catch (e) {
            yield put({
                type: Actions.unAssignSequence + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

function* getAvailabilitySaga() {
    yield takeLatest(Actions.getAvailability + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.getAvailability + ACTION_STATES.PENDING,
                payload: action.payload,
            });
            const serverResponse = yield call(
                async () => await SequenceAPI.getAvailability(action.payload),
            );
            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.getAvailability + ACTION_STATES.FULFILLED,
                payload: serverResponse.data,
            });
        } catch (e) {
            yield put({
                type: Actions.getAvailability + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

function* fetchLayoutsSaga() {
    yield takeLatest(Actions.fetchLayouts + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.fetchLayouts + ACTION_STATES.PENDING,
                payload: action.payload,
            });
            const serverResponse = yield call(
                async () => await SequenceAPI.fetchAvailableLayouts(action.payload),
            );
            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.fetchLayouts + ACTION_STATES.FULFILLED,
                payload: serverResponse.data,
            });
        } catch (e) {
            yield put({
                type: Actions.fetchLayouts + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

function* markAsDefaultSaga() {
    yield takeLatest(Actions.markAsDefault + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.markAsDefault + ACTION_STATES.PENDING,
                payload: {},
            });
            const serverResponse = yield call(
                async () => await SequenceAPI.markAsDefault(action.payload),
            );

            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.markAsDefault + ACTION_STATES.FULFILLED,
                payload: serverResponse.data,
            });

            yield put({
                type: Actions.getAllSequences + ACTION_STATES.REQUEST,
                payload: action.payload.boardId,
            });
        } catch (e) {
            yield put({
                type: Actions.markAsDefault + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

function* loadPreviewContentSaga() {
    yield takeEvery(Actions.loadPreviewContent + ACTION_STATES.REQUEST, function* (action) {
        try {
            yield put({
                type: Actions.loadPreviewContent + ACTION_STATES.PENDING,
                payload: {},
            });
            const serverResponse = yield call(async () => {
                return SequenceAPI.loadPreviewContent(action.payload);
            });
            if (!serverResponse) throw Error(JSON.stringify(serverResponse));
            if (serverResponse.error) throw Error(JSON.stringify(serverResponse));

            yield put({
                type: Actions.loadPreviewContent + ACTION_STATES.FULFILLED,
                payload: serverResponse,
            });
        } catch (e) {
            yield put({
                type: Actions.loadPreviewContent + ACTION_STATES.REJECTED,
                payload: {},
            });
        }
    });
}

export const sequencesSagas = [
    getDefaultSequencesSaga(),
    getAllSequencesSaga(),
    getSequenceSaga(),
    createSequenceSaga(),
    deleteSequenceSaga(),
    saveSequenceSaga(),
    assignSequenceSaga(),
    updateScheduleSaga(),
    unAssignSequenceSaga(),
    getAvailabilitySaga(),
    markAsDefaultSaga(),
    loadPreviewContentSaga(),
    fetchLayoutsSaga(),
];
