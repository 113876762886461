/*
 * @Author: Chandu J S
 * @Date:   2020-10-16 22:09:14
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-10-22 16:57:40
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	// This is used inside the hoot display page. For the message button.
	miniSender: {
		actionErrorMessage: null,
		actionStatus: "idle",
		message: "",
	},
	messageCenter: {
		replyActionStatus: "idle",
		replyMessage: "",
		replyErrorMessage: null,
		conversationsFetchStatus: "idle",
		conversationsErrorMessage: null,
		conversations: [],
		unreadCount: 0,
		selectedConversationIndex: null,
	},
	mixpanelData: {
		conversationId: null,
	},
};

const messagingSlice = createSlice({
	name: "messaging",
	initialState,
	reducers: {
		setMiniSenderMessage: (state, action) => {
			state.miniSender.message = action.payload;
		},
		resetMiniSender: (state) => {
			state.miniSender = initialState.miniSender;
		},
		setConversationIndex: (state, action) => {
			const index = action.payload;
			state.messageCenter.selectedConversationIndex = index;

			const defaultData = initialState.messageCenter;

			state.messageCenter.replyActionStatus =
				defaultData.replyActionStatus;
			state.messageCenter.replyErrorMessage =
				defaultData.replyErrorMessage;
			state.messageCenter.replyMessage = defaultData.replyMessage;

			if (action.payload != null) {
				const conversation = state.messageCenter.conversations[index];
				state.messageCenter.unreadCount -=
					conversation.unreadMessages || 0;
			}
		},
		setConversationReplyMessage: (state, action) => {
			state.messageCenter.replyMessage = action.payload;
		},
	},
	extraReducers: {
		"messaging/send/pending": (state) => {
			state.mixpanelData = initialState.mixpanelData;
			state.miniSender.actionErrorMessage = null;
			state.miniSender.actionStatus = "pending";
		},
		"messaging/send/fulfilled": (state, action) => {
			state.mixpanelData.conversationId = action.payload.conversationId;
			state.miniSender.actionStatus = "fulfilled";
		},
		"messaging/send/rejected": (state) => {
			state.miniSender.actionStatus = "rejected";
			state.miniSender.actionErrorMessage =
				"Failed to send your message. Please try again.";
		},
		//
		"messaging/get-conversations/pending": (state) => {
			state.messageCenter.conversationsErrorMessage = null;
			state.messageCenter.conversationsFetchStatus = "pending";
		},
		"messaging/get-conversations/fulfilled": (state, action) => {
			state.messageCenter.conversationsFetchStatus = "fulfilled";
			state.messageCenter.conversations = action.payload.map((item) => ({
				...item,
				messages: item.messages.reverse(),
			}));
			state.messageCenter.unreadCount =
				state.messageCenter.conversations.reduce((sum, item) => {
					return sum + item.unreadMessages;
				}, 0);
		},
		"messaging/get-conversations/rejected": (state) => {
			state.messageCenter.conversationsFetchStatus = "rejected";
			state.messageCenter.conversationsErrorMessage =
				"Failed to fetch conversations. Please try again.";
		},
		//
		"messaging/send-reply/pending": (state) => {
			state.mixpanelData = initialState.mixpanelData;
			state.messageCenter.replyErrorMessage = null;
			state.messageCenter.replyActionStatus = "pending";
		},
		"messaging/send-reply/fulfilled": (state, action) => {
			const {
				conversationIndex: index,
				conversationId: convId,
				...message
			} = action.payload;

			state.mixpanelData.conversationId = convId;

			state.messageCenter.replyActionStatus = "fulfilled";
			state.messageCenter.conversations[index].messages.push(message);

			const defaultData = initialState.messageCenter;

			state.messageCenter.replyErrorMessage =
				defaultData.replyErrorMessage;
			state.messageCenter.replyMessage = defaultData.replyMessage;
		},
		"messaging/send-reply/rejected": (state) => {
			state.messageCenter.replyActionStatus = "rejected";
			state.messageCenter.replyErrorMessage =
				"Failed to send your message. Please try again.";
		},
		//
		"messaging/get-unread/fulfilled": (state, action) => {
			state.messageCenter.unreadCount = action.payload;
		},
		//
		"messaging/read/request": (state, action) => {
			const { conversationIndex } = action.payload;
			state.messageCenter.conversations[
				conversationIndex
			].unreadMessages = 0;
		},
		//
	},
});

export const sendMessage = ({ sendersId, receiverId, messageText, hootId }) => {
	return {
		type: "messaging/send/request",
		payload: { sendersId, receiverId, messageText, hootId },
	};
};

export const sendReply = ({
	conversationId,
	messageText,
	conversationIndex,
}) => {
	return {
		type: "messaging/send-reply/request",
		payload: { conversationId, messageText, conversationIndex },
	};
};

export const getConversationsSilent = () => {
	return {
		type: "messaging/get-conversations/request",
		payload: { silent: true },
	};
};

export const getConversations = () => {
	return {
		type: "messaging/get-conversations/request",
		payload: { silent: false },
	};
};

export const getUnreadCount = () => {
	return {
		type: "messaging/get-unread/request",
	};
};

export const setRead = ({ conversationId, conversationIndex }) => {
	return {
		type: "messaging/read/request",
		payload: { conversationId, conversationIndex },
	};
};

export const {
	setMiniSenderMessage,
	resetMiniSender,
	setConversationIndex,
	setConversationReplyMessage,
} = messagingSlice.actions;

export default messagingSlice.reducer;
