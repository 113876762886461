import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import * as BoardsService from "../../api/boards.service";
import * as HootsService from "../../api/hoots.service";
import * as CHActions from "../../components/Calendar/calendar.slice";
import * as ABHActions from "../../components/HootsFromAllBoards/allBoardHoots.slice";
import * as PBHActions from "../../components/HootsFromPinnedBoards/pinnedBoardHoots.slice";
import * as MHActions from "../../redux/myHoots.slice";
// 
import * as API from "../../api/lists.service";
// 
import { transformComments } from "../../utils/transformComments.util";
import { transformDisplayHoot } from "../../utils/transformDisplayHoot.util";
import * as BBActions from "../ResponsiveBulletinBoard/bulletinBoard.slice";
import { extractListToken } from "../../utils/lists.util";

const slicesToUpdate = {
	bb: BBActions,
	mh: MHActions,
	pbh: PBHActions,
	abh: ABHActions,
	ch: CHActions,
};

export const deleteHoot = createAsyncThunk(
	"/hootDisplay/deleteHoot",
	async (_x, thunkApi) => {
		const {
			activeBoard: { boardId },
			activeHoot: { selectedHootId, sliceToUpdate },
		} = thunkApi.getState();

		const response = await HootsService.deleteHoot(selectedHootId);

		if (sliceToUpdate) {
			thunkApi.dispatch(
				slicesToUpdate[sliceToUpdate].refreshData({ boardId })
			);
		} else {
			Object.keys(slicesToUpdate).forEach((key) =>
				thunkApi.dispatch(slicesToUpdate[key].refreshData({ boardId }))
			);
		}

		return { response };
	}
);

export const removeRehootedHoot = createAsyncThunk(
	"/hootDisplay/removeRehootedHoot",
	async (_x, thunkApi) => {
		const {
			activeBoard: { boardId },
			activeHoot: { selectedHootId, sliceToUpdate },
		} = thunkApi.getState();

		const response = await HootsService.removeRehootedHoot(
			boardId,
			selectedHootId
		);

		if (sliceToUpdate) {
			thunkApi.dispatch(
				slicesToUpdate[sliceToUpdate].refreshData({ boardId })
			);
		} else {
			Object.keys(slicesToUpdate).forEach((key) =>
				thunkApi.dispatch(slicesToUpdate[key].refreshData({ boardId }))
			);
		}

		return { response };
	}
);

export const deleteComment = createAsyncThunk(
	"/hootDisplay/deleteComment",
	async ({ commentId }, thunkApi) => {
		const {
			activeHoot: { selectedHootId },
		} = thunkApi.getState();

		const response = await HootsService.deleteComment(
			commentId,
			selectedHootId
		);

		const commentsRes = await HootsService.getComments(selectedHootId);
		const comments = commentsRes.data.map(transformComments);

		return { response, comments };
	}
);

export const approveComment = createAsyncThunk(
	"/hootDisplay/approveComment",
	async ({ commentId, commentApproval, commentRejectMsg }, thunkApi) => {
		const {
			activeBoard: { boardId },
			activeHoot: { selectedHootId },
		} = thunkApi.getState();

		const response = await HootsService.approveComment(boardId, commentId, {
			commentApproval,
			commentRejectMsg,
		});

		const commentsRes = await HootsService.getComments(selectedHootId);
		const comments = commentsRes.data.map(transformComments);

		return { response, comments };
	}
);

export const getComments = createAsyncThunk(
	"/hootDisplay/getComments",
	async ({ hootId }) => {
		const response = await HootsService.getComments(hootId);
		return { comments: response.data.map(transformComments) };
	}
);

export const postComment = createAsyncThunk(
	"/hootDisplay/postComment",
	async ({ mentionIds, commentText, hootId }, thunkApi) => {
		const {
			activeBoard: { boardId },
			activeHoot: { sliceToUpdate, selectedHootIndex, selectedHoot },
		} = thunkApi.getState();

		const postRes = await HootsService.postComment(
			boardId,
			hootId,
			mentionIds,
			commentText
		);

		const commentsRes = await HootsService.getComments(hootId);
		const comments = commentsRes.data.map(transformComments);

		if (sliceToUpdate) {
			thunkApi.dispatch(
				slicesToUpdate[sliceToUpdate].updateHootCommentCount({
					selectedHoot,
					selectedHootIndex,
					commentCount: comments.length,
				})
			);
		} else {
			Object.keys(slicesToUpdate).forEach((key) =>
				thunkApi.dispatch(
					slicesToUpdate[key].updateHootCommentCount({
						selectedHoot,
						selectedHootIndex,
						commentCount: comments.length,
					})
				)
			);
		}

		return {
			response: postRes,
			comments,
		};
	}
);

export const processHootApprovalRequest = createAsyncThunk(
	"/hootDisplay/processHootApprovalRequest",
	async ({ postApproval }, thunkApi) => {
		const {
			activeBoard: { boardId },
			activeHoot: { selectedHootId, selectedHoot, selectedHootIndex },
		} = thunkApi.getState();

		const response = await BoardsService.processHootApproval(
			boardId,
			selectedHootId,
			postApproval
		);

		if (!response.postApprovalStatus) {
			return { response };
		}

		thunkApi.dispatch(
			BBActions.updateHootApproved({
				selectedHoot,
				selectedHootIndex,
			})
		);

		return { response };
	}
);

export const rejectHootApproval = createAsyncThunk(
	"/hootDisplay/rejectHootApproval",
	async (_props, thunkApi) => {
		const {
			activeBoard: { boardId },
			activeHoot: { selectedHootId, hootRejectionMessage },
		} = thunkApi.getState();

		const response = await BoardsService.processHootApproval(
			boardId,
			selectedHootId,
			"remove",
			hootRejectionMessage
		);

		if (!response.postApprovalStatus) {
			return { response };
		}

		thunkApi.dispatch(BBActions.fetchInitialData({ boardId }));

		return { response };
	}
);

export const fetchHoot = createAsyncThunk(
	"/hootDisplay/fetchHoot",
	async ({ hootId, index }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();
		const response = await BoardsService.getHoot(
			boardId ? boardId : 0,
			hootId
		);

		const listToken = extractListToken(response.data);

		if (listToken) {
            const data = await API.getListByToken(listToken);       
            if (!data) throw new Error();
            if (!data.success) throw new Error(data.errorMessage);
            if (data.error) throw new Error(data.errorMessage);
            return {
				hoot: transformDisplayHoot({...response.data, listId: data.data.listId,
                items: data.data.items}), index
            };
        }

		return { hoot: transformDisplayHoot(response.data), index };
	}
);

export const pinHootChanged = createAsyncThunk(
	"/hootDisplay/pinHootChanged",
	async ({ pin, hootId }, thunkApi) => {
		const {
			activeBoard: { boardId },
			activeHoot: { selectedHootIndex, sliceToUpdate },
		} = thunkApi.getState();

		const response = await HootsService.pinHoot(boardId, hootId, pin);

		if (sliceToUpdate && slicesToUpdate[sliceToUpdate]) {
			thunkApi.dispatch(
				slicesToUpdate[sliceToUpdate].updateHootPinned({
					pin,
					hootId,
					selectedHootIndex,
				})
			);
			thunkApi.dispatch(slicesToUpdate[sliceToUpdate].refreshData());
		}
		return { response };
	}
);

export const favoriteHootChanged = createAsyncThunk(
	"/hootDisplay/favoriteHootChanged",
	async ({ hootId, favorite }, thunkApi) => {
		const {
			activeBoard: { boardId },
			activeHoot: { sliceToUpdate, selectedHootIndex },
		} = thunkApi.getState();

		if (sliceToUpdate && slicesToUpdate[sliceToUpdate]) {
			thunkApi.dispatch(
				slicesToUpdate[sliceToUpdate].updateHootFavorite({
					selectedHootIndex,
					favorite,
				})
			);
		}

		let response;
		if (favorite) {
			response = await HootsService.favoriteHoot(boardId, hootId);
		} else {
			response = await HootsService.unfavoriteHoot(boardId, hootId);
		}
		return { response };
	}
);

export const hootVisibilityChanged = createAsyncThunk(
	"/hootDisplay/hootVisibilityChanged",
	async ({ hootId, visibility }, thunkApi) => {
		const {
			activeBoard: { boardId },
			activeHoot: { sliceToUpdate },
		} = thunkApi.getState();

		const response = await HootsService.updateVisibility(
			boardId,
			hootId,
			visibility
		);

		if (sliceToUpdate) {
			thunkApi.dispatch(
				slicesToUpdate[sliceToUpdate].refreshData({ boardId })
			);
		} else {
			Object.keys(slicesToUpdate).forEach((key) =>
				thunkApi.dispatch(slicesToUpdate[key].refreshData({ boardId }))
			);
		}

		const hootResponse = await BoardsService.getHoot(
			boardId ? boardId : 0,
			hootId
		);

		const listToken = extractListToken(hootResponse.data);
		
		if (listToken) {
			const data = await API.getListByToken(listToken);
			if (!data) throw new Error();
			if (!data.success) throw new Error(data.errorMessage);
			if (data.error) throw new Error(data.errorMessage);
			return {
				response,
				hoot: transformDisplayHoot({ ...hootResponse.data, items: data.data.items, listId: data.data.listId }),
			};
		}
	
		return { response, hoot: transformDisplayHoot(hootResponse.data)};
	}
);

export const hootCollectionAdded = createAsyncThunk(
	"/hootDisplay/hootCollectionAdded",
	async ({ hootId }, thunkApi) => {
		const {
			activeBoard: { boardId },
			activeHoot: { selectedHoot },
		} = thunkApi.getState();

		const response = await HootsService.addCollectionToHoot(
			boardId,
			hootId,
			selectedHoot.boardTagsList.join()
		);
		return { response };
	}
);

export const hootCollectionRemoved = createAsyncThunk(
	"/hootDisplay/hootCollectionRemoved",
	async ({ hootId, collection }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await HootsService.removeCollectionFromHoot(
			boardId,
			hootId,
			collection
		);
		return { response };
	}
);

export const hootExpiryDateUpdated = createAsyncThunk(
	"/hootDisplay/hootExpiryDateUpdated",
	async ({ hootId, expiryDate }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await HootsService.putExpiryDate(
			boardId,
			hootId,
			expiryDate ? expiryDate : ""
		);
		return { response };
	}
);

export const hootSetExpiryDateUpdated = createAsyncThunk(
	"/hootDisplay/hootSetExpiryDateUpdated",
	async ({ hootId, setExpiryDate }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		if (!setExpiryDate) {
			const response = await HootsService.putExpiryDate(boardId, hootId);
			return { response };
		}
	}
);

export const rescheduleHoot = createAsyncThunk(
	"/hootDisplay/rescheduleHoot",
	async ({ hootId, isScheduled, scheduledOnDate }, thunkApi) => {
		const {
			activeBoard: { boardId },
			activeHoot: { selectedHoot, sliceToUpdate },
		} = thunkApi.getState();

		const { hootTimezone } = selectedHoot || {};

		const updateDate = async (value) => {
			const response = await HootsService.reScheduleHoot(
				boardId,
				hootId,
				value,
				hootTimezone
			);
			thunkApi.dispatch(
				slicesToUpdate[sliceToUpdate].refreshData({ boardId })
			);
			return { response };
		};

		if (isScheduled) {
			// only need to call the API if there is a value for scheduledOnDate
			if (scheduledOnDate) {
				return updateDate(scheduledOnDate);
			}
		} else {
			// removing scheduled date
			return updateDate("");
		}
	}
);

export const hootUpdateDigitalSignageSettings = createAsyncThunk(
	"/hootDisplay/hootUpdateDigitalSignageSettings",
	async ({ hootId, config }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await HootsService.updateHootSettings(boardId, {
			postId: hootId,
			...config,
		});
		return { response };
	}
);

export const hootDisableCommentsUpdated = createAsyncThunk(
	"/hootDisplay/hootDisableCommentsUpdated",
	async ({ hootId, disableComments }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await HootsService.updateHootSettings(boardId, {
			postId: hootId,
			disableComments,
		});
		return { response };
	}
);

export const hootExcludeFromNewsLetterUpdated = createAsyncThunk(
	"/hootDisplay/hootExcludeFromNewsLetterUpdated",
	async ({ hootId, excludeDigests }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await HootsService.updateHootSettings(boardId, {
			postId: hootId,
			excludeDigests,
		});
		return { response };
	}
);

export const showHootDisplay = createAsyncThunk(
	"/hootDisplay/showHootDisplay",
	async (
		{ hootId, index, hoots, sliceToUpdate, boardId, ignoreBoardId },
		thunkApi
	) => {
		const { activeBoard } = thunkApi.getState();

		// check if boardId is available in params if not, get activeBoard's id,
		// if that is also not available, set boardId as 0
		const xBoardId = ignoreBoardId
			? 0
			: boardId
			? boardId
			: activeBoard.boardId
			? activeBoard.boardId
			: 0;

		const response = await BoardsService.getHoot(xBoardId, hootId);

		const listToken = extractListToken(response.data);

		if (listToken) {
			const listData = await API.getListByToken(listToken);

			return {
				hoot: transformDisplayHoot({...response.data, items: listData.data.items, listId: listData.data.listId}),
				index,
				hoots,
				sliceToUpdate,
			};
		}

		return {
			hoot: transformDisplayHoot(response.data),
			index,
			hoots,
			sliceToUpdate,
		};
	}
);

const activeHootSlice = createSlice({
	name: "hootDisplay",
	initialState: {
		isHootRestricted: false,

		isLoading: true,
		showRetweetModal: false,

		hoots: [],
		boardCollections: [],

		selectedHootId: null,
		selectedHootIndex: -1,
		selectedHoot: null,

		isShowingMobileManageHoot: false,

		sliceToUpdate: null,
		isHootModalVisible: false,

		hootApprovalStatus: null,
		hootRejectionStatus: null,

		deleteHootStatus: null,

		postCommentStatus: null,
		loadCommentsStatus: null,
		refreshCommentsStatus: null,

		commentApprovalStatus: {},
		commentRejectionStatus: {},

		deleteComments: {},
		comments: [],

		hootUpdateStatus: "idle",

		hootRejectionMessage: "",
		isHootRejectMessageDialogVisible: false,
		isHootNotAvailable: false,

		nestedHootRoutes: []
	},
	reducers: {
		closeHootDisplay: (state, action) => {
			state.selectedHoot = null;
			state.selectedHootId = null;
			state.selectedHootIndex = -1;
			state.hoots = [];
			state.showRetweetModal = false;
			state.sliceToUpdate = null;
			state.hootApprovalStatus = null;
			state.hootRejectionStatus = null;
			state.postCommentStatus = null;
			state.comments = [];
			state.loadCommentsStatus = null;
			state.refreshCommentsStatus = null;
			state.deleteComments = {};
			state.deleteHootStatus = null;
			state.commentApprovalStatus = {};
			state.commentRejectionStatus = {};
			state.isShowingMobileManageHoot = false;
			// 
			if (action.payload && !action.payload.resetNestedRoute) {
				console.log(action.payload)
			} else {
				state.nestedHootRoutes = [];
			}
		},
		showMobileManageHoot: (state) => {
			state.isShowingMobileManageHoot = true;
		},
		setHootRejectMessageDialog: (state, action) => {
			state.isHootRejectMessageDialogVisible = !!action.payload;
		},
		setHootRejectMessage: (state, action) => {
			state.hootRejectionMessage = action.payload;
		},
		hideMobileManageHoot: (state) => {
			state.isShowingMobileManageHoot = false;
		},
		resetApprovalStatus: (state) => {
			state.hootApprovalStatus = null;
			state.hootRejectionStatus = null;
		},
		rehooted: (state, action) => {
			if (
				state.selectedHoot &&
				state.selectedHootId === action.payload.id
			) {
				state.selectedHoot.hootStats.rehoots += 1;
			}
		},
		resetNestedRoute: (state, action) => {
			if (action.payload.up) {
				const hootRoute = state.nestedHootRoutes;
				hootRoute.pop()
				state.nestedHootRoutes = hootRoute;
				state.selectedHoot = hootRoute[hootRoute.length - 1];
			} else if (action.payload.exit) {
				state.selectedHoot = state.nestedHootRoutes[0];
				state.nestedHootRoutes = state.nestedHootRoutes.slice(0, 1);
			} else {
				state.nestedHootRoutes = [];
			}
		}
	},
	extraReducers: {
		[deleteComment.fulfilled]: (state, action) => {
			const { commentId } = action.meta.arg;
			const { comments } = action.payload;

			state.comments = comments;

			state.deleteComments[commentId] = "fulfilled";
		},
		[deleteComment.pending]: (state, action) => {
			const { commentId } = action.meta.arg;

			state.deleteComments[commentId] = "pending";
		},
		[deleteComment.rejected]: (state, action) => {
			const { commentId } = action.meta.arg;

			state.deleteComments[commentId] = "rejected";
		},

		[getComments.fulfilled]: (state, action) => {
			const { comments } = action.payload;
			const { refresh } = action.meta.arg;

			state.comments = comments;

			if (refresh) {
				state.refreshCommentsStatus = "fulfilled";
			} else {
				state.loadCommentsStatus = "fulfilled";
			}
		},
		[getComments.pending]: (state, action) => {
			const { refresh } = action.meta.arg;

			if (refresh) {
				state.refreshCommentsStatus = "pending";
			} else {
				state.loadCommentsStatus = "pending";
			}
		},
		[getComments.rejected]: (state, action) => {
			const { refresh } = action.meta.arg;

			if (refresh) {
				state.refreshCommentsStatus = "rejected";
			} else {
				state.loadCommentsStatus = "rejected";
			}
		},

		[fetchHoot.fulfilled]: (state, action) => {
			const { hoot } = action.payload;

			if (state.selectedHootId !== hoot.id) return;

			state.selectedHoot = hoot;
		},
		[fetchHoot.pending]: (state, action) => {
			const { hootId, index } = action.meta.arg;
			state.selectedHoot = null;
			state.selectedHootId = hootId;
			state.selectedHootIndex = index;
		},
		[fetchHoot.rejected]: (state) => {
			state.selectedHoot = null;
			state.selectedHootId = null;
			state.selectedHootIndex = -1;
			state.hoots = [];
			state.showRetweetModal = false;
			state.sliceToUpdate = null;
		},

		[pinHootChanged.fulfilled]: (state, action) => {
			const { response } = action.payload;
			const { pin } = action.meta.arg;
			if (response.pinHootStatus !== pin) {
				state.selectedHoot.isPinned = response.pinHootStatus;
			}
		},
		[pinHootChanged.pending]: (state, action) => {
			const { pin } = action.meta.arg;
			state.selectedHoot.isPinned = pin;
		},
		[pinHootChanged.rejected]: (state, action) => {
			const { pin } = action.meta.arg;

			state.selectedHoot.isPinned = !pin;
		},

		[favoriteHootChanged.fulfilled]: (state, action) => {
			const { response } = action.payload;
			const { favorite } = action.meta.arg;

			if (!response.success) {
				if (state.selectedHoot) {
					state.selectedHoot.favPost = !favorite;
					state.selectedHoot.hootStats.favorites += !favorite
						? 1
						: -1;
				}
			}
		},
		[favoriteHootChanged.pending]: (state, action) => {
			const { favorite } = action.meta.arg;

			if (state.selectedHoot) {
				state.selectedHoot.favPost = favorite;
				state.selectedHoot.hootStats.favorites += favorite ? 1 : -1;
			}
		},
		[favoriteHootChanged.rejected]: (state, action) => {
			const { favorite } = action.meta.arg;

			if (state.selectedHoot) {
				state.selectedHoot.favPost = !favorite;
				state.selectedHoot.hootStats.favorites += !favorite ? 1 : -1;
			}
		},

		[hootVisibilityChanged.fulfilled]: (state, action) => {
			const { response, hoot } = action.payload;
			const { visibility } = action.meta.arg;

			state.hootUpdateStatus = "fulfilled";
			state.selectedHoot = hoot;

			if (!response.postApprovalStatus) {
				const privateHoot = visibility === "public";

				state.selectedHoot.manage.privateHoot = privateHoot;
			}
		},
		[hootVisibilityChanged.pending]: (state, action) => {
			const { visibility } = action.meta.arg;
			const privateHoot = !(visibility === "public");

			state.hootUpdateStatus = "pending";
			state.selectedHoot.manage.privateHoot = privateHoot;
		},
		[hootVisibilityChanged.rejected]: (state, action) => {
			const { visibility } = action.meta.arg;
			const privateHoot = visibility === "public";

			state.hootUpdateStatus = "rejected";
			state.selectedHoot.manage.privateHoot = privateHoot;
		},

		[hootCollectionAdded.fulfilled]: (state, action) => {
			const { response } = action.payload;
			const { collection } = action.meta.arg;

			if (response.message !== "success") {
				const index = state.selectedHoot.boardTagsList.findIndex(
					(c) => c === collection
				);

				state.selectedHoot.boardTagsList.splice(index, 1);
			}
		},
		[hootCollectionAdded.pending]: (state, action) => {
			const { collection } = action.meta.arg;

			state.selectedHoot.boardTagsList.push(collection);
		},
		[hootCollectionAdded.rejected]: (state, action) => {
			const { collection } = action.meta.arg;
			const index = state.selectedHoot.boardTagsList.findIndex(
				(c) => c === collection
			);

			state.selectedHoot.boardTagsList.splice(index, 1);
		},

		[hootCollectionRemoved.fulfilled]: (state, action) => {
			const { response } = action.payload;
			const { collection, currIndex } = action.meta.arg;

			if (response.result !== "1") {
				state.selectedHoot.boardTagsList.splice(
					currIndex,
					0,
					collection
				);
			}
		},
		[hootCollectionRemoved.pending]: (state, action) => {
			const { currIndex } = action.meta.arg;

			state.selectedHoot.boardTagsList.splice(currIndex, 1);
		},
		[hootCollectionRemoved.rejected]: (state, action) => {
			const { collection, currIndex } = action.meta.arg;

			state.selectedHoot.boardTagsList.splice(currIndex, 0, collection);
		},

		[hootSetExpiryDateUpdated.fulfilled]: (state) => {
			// const { setExpiryDate } = action.meta.arg;
			state.selectedHoot.manage.expiryDate = null;
		},
		[hootSetExpiryDateUpdated.pending]: (state, action) => {
			const { setExpiryDate } = action.meta.arg;

			state.selectedHoot.manage.setExpiryDate = setExpiryDate;
		},
		[hootSetExpiryDateUpdated.rejected]: (state, action) => {
			const { setExpiryDate } = action.meta.arg;

			state.selectedHoot.manage.setExpiryDate = !setExpiryDate;
		},
		//
		[rescheduleHoot.fulfilled]: () => {},
		[rescheduleHoot.pending]: (state, action) => {
			const { isScheduled, scheduledOnDate } = action.meta.arg;
			if (isScheduled) {
				state.selectedHoot.scheduledOnDate =
					moment(scheduledOnDate).format("Do MMM, YYYY");
				state.selectedHoot.manage.scheduledOnDate = scheduledOnDate;
			}
			state.selectedHoot.isScheduled = isScheduled;
			state.selectedHoot.manage.isScheduled = isScheduled;
		},
		[rescheduleHoot.rejected]: (state, action) => {
			const { isScheduledPrev, scheduledOnDatePrev } = action.meta.arg;
			state.selectedHoot.scheduledOnDate = scheduledOnDatePrev;
			state.selectedHoot.manage.scheduledOnDate = scheduledOnDatePrev;
			state.selectedHoot.isScheduled = isScheduledPrev;
			state.selectedHoot.manage.isScheduled = isScheduledPrev;
		},
		//
		[hootExpiryDateUpdated.fulfilled]: () => {},
		[hootExpiryDateUpdated.pending]: (state, action) => {
			const { expiryDate } = action.meta.arg;

			state.selectedHoot.manage.expiryDate = expiryDate
				? expiryDate
				: null;
		},
		[hootExpiryDateUpdated.rejected]: (state, action) => {
			const { prevDate } = action.meta.arg;

			state.selectedHoot.manage.expiryDate = prevDate;
		},

		[hootDisableCommentsUpdated.fulfilled]: () => {},
		[hootDisableCommentsUpdated.pending]: (state, action) => {
			const { disableComments } = action.meta.arg;

			state.selectedHoot.manage.disableComments = disableComments;
		},
		[hootDisableCommentsUpdated.rejected]: (state, action) => {
			const { disableComments } = action.meta.arg;

			state.selectedHoot.manage.disableComments = !disableComments;
		},

		[hootUpdateDigitalSignageSettings.fulfilled]: () => {},
		[hootUpdateDigitalSignageSettings.pending]: (state, action) => {
			const { config } = action.meta.arg;

			state.selectedHoot.manageCopy = {
				...state.selectedHoot.manage,
			};

			state.selectedHoot.manage = {
				...state.selectedHoot.manage,
				...config,
			};
		},
		[hootUpdateDigitalSignageSettings.rejected]: (state) => {
			state.selectedHoot.manage = state.selectedHoot.manageCopy;
		},

		[hootExcludeFromNewsLetterUpdated.fulfilled]: () => {},
		[hootExcludeFromNewsLetterUpdated.pending]: (state, action) => {
			const { excludeDigests } = action.meta.arg;

			state.selectedHoot.manage.excludeDigests = excludeDigests;
		},
		[hootExcludeFromNewsLetterUpdated.rejected]: (state, action) => {
			const { disableComments } = action.meta.arg;

			state.selectedHoot.manage.disableComments = !disableComments;
		},

		[showHootDisplay.fulfilled]: (state, action) => {
			const { hoots, hoot, index, sliceToUpdate } = action.payload;

			state.isHootRestricted = hoot.privateHoot;

			if (state.selectedHootId !== hoot.id) return;

			state.hoots = hoots;
			state.selectedHoot = hoot;
			state.selectedHootIndex = index;
			state.selectedHootId = hoot.id;
			state.sliceToUpdate = sliceToUpdate;
			state.isLoading = false;
			state.isHootModalVisible = true;
			state.nestedHootRoutes = [...state.nestedHootRoutes, hoot];
		},
		[showHootDisplay.pending]: (state, action) => {
			const { hootId, index, hoots } = action.meta.arg;

			state.isHootRestricted = false;
			state.selectedHootId = hootId;
			state.selectedHootIndex = index;
			state.hoots = hoots;
			state.hootApprovalStatus = null;
			state.hootRejectionStatus = null;
			state.postCommentStatus = null;
			state.comments = [];
			state.loadCommentsStatus = null;
			state.refreshCommentsStatus = null;
			state.deleteComments = {};
			state.deleteHootStatus = null;
			state.commentApprovalStatus = {};
			state.commentRejectionStatus = {};
			state.isHootNotAvailable = false;
		},
		[showHootDisplay.rejected]: (state) => {
			state.isHootNotAvailable = true;
		},

		[processHootApprovalRequest.fulfilled]: (state, action) => {
			const { postApprovalStatus } = action.payload.response;

			if (!postApprovalStatus) {
				state.hootApprovalStatus = "rejected";
				return;
			}

			// updating the active hoot's privacy state temporarily
			const { postApproval } = action.meta.arg || {};
			const privateHoot = postApproval !== "public";
			state.selectedHoot.manage.privateHoot = privateHoot;
			state.selectedHoot.privateHoot = privateHoot;

			state.selectedHoot.requiresApproval = false;
			state.hootApprovalStatus = "fulfilled";
		},
		[processHootApprovalRequest.pending]: (state) => {
			state.hootApprovalStatus = "pending";
		},
		[processHootApprovalRequest.rejected]: (state) => {
			state.hootApprovalStatus = "rejected";
		},

		[rejectHootApproval.fulfilled]: (state, action) => {
			const { postApprovalStatus } = action.payload.response;

			if (!postApprovalStatus) {
				state.hootRejectionStatus = "rejected";
				return;
			}

			state.selectedHoot = null;
			state.selectedHootId = null;
			state.selectedHootIndex = -1;
			state.hoots = [];
			state.showRetweetModal = false;
			state.sliceToUpdate = null;
			state.hootRejectionMessage = "";
			state.hootRejectionStatus = "fulfilled";
		},
		[rejectHootApproval.pending]: (state) => {
			state.hootRejectionStatus = "pending";
		},
		[rejectHootApproval.rejected]: (state) => {
			state.hootRejectionStatus = "rejected";
		},

		[postComment.fulfilled]: (state, action) => {
			const { comments } = action.payload;

			state.postCommentStatus = "fulfilled";
			state.comments = comments;
		},
		[postComment.pending]: (state) => {
			state.postCommentStatus = "pending";
		},
		[postComment.rejected]: (state) => {
			state.postCommentStatus = "rejected";
		},

		[deleteHoot.fulfilled]: (state) => {
			state.deleteHootStatus = "fulfilled";
		},
		[deleteHoot.pending]: (state) => {
			state.deleteHootStatus = "pending";
		},
		[deleteHoot.rejected]: (state) => {
			state.deleteHootStatus = "rejected";
		},

		[removeRehootedHoot.fulfilled]: (state) => {
			state.deleteHootStatus = "fulfilled";
		},
		[removeRehootedHoot.pending]: (state) => {
			state.deleteHootStatus = "pending";
		},
		[removeRehootedHoot.rejected]: (state) => {
			state.deleteHootStatus = "rejected";
		},

		[approveComment.fulfilled]: (state, action) => {
			const { commentId, commentApproval } = action.meta.arg;
			const { comments } = action.payload;

			state.comments = comments;

			if (commentApproval === "remove") {
				state.commentRejectionStatus[commentId] = "fulfilled";
			} else {
				state.commentApprovalStatus[commentId] = "fulfilled";
			}
		},
		[approveComment.pending]: (state, action) => {
			const { commentId, commentApproval } = action.meta.arg;

			if (commentApproval === "remove") {
				state.commentRejectionStatus[commentId] = "pending";
			} else {
				state.commentApprovalStatus[commentId] = "pending";
			}
		},
		[approveComment.rejected]: (state, action) => {
			const { commentId, commentApproval } = action.meta.arg;

			if (commentApproval === "remove") {
				state.commentRejectionStatus[commentId] = "rejected";
			} else {
				state.commentApprovalStatus[commentId] = "rejected";
			}
		},
		"saved/hoots/unsave/request": (state) => {
			if (state.selectedHoot) {
				state.selectedHoot.isSaved = false;
			}
		},
		"saved/hoots/saveLocal/request": (state) => {
			if (state.selectedHoot) {
				state.selectedHoot.isSaved = true;
			}
		},
	},
});

export const {
	closeHootDisplay,
	showMobileManageHoot,
	hideMobileManageHoot,
	resetApprovalStatus,
	setHootRejectMessageDialog,
	setHootRejectMessage,
	rehooted,
	resetNestedRoute,
} = activeHootSlice.actions;

export default activeHootSlice.reducer;