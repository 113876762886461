import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isOpen: false,
	feedUrl: null,
	originalFeedUrl: null,
	badgeCount: 0,
};

const changeLogSlice = createSlice({
	name: "changeLog",
	initialState: initialState,
	reducers: {
		closeChangeLog: (state) => {
			state.isOpen = false;
		},
		openChangeLog: (state) => {
			if (state.originalFeedUrl) {
				state.isOpen = true;
				state.feedUrl = state.originalFeedUrl;
			}
		},
	},
	extraReducers: {
		"changelog/get/fulfilled": (state, action) => {
			const { badgeCount, feedUrl } = action.payload;

			state.originalFeedUrl = feedUrl;
			state.badgeCount = badgeCount;
		},
	},
});

export const getBadgeCount = () => {
	return {
		type: "changelog/get/request",
	};
};

export const { closeChangeLog, openChangeLog } = changeLogSlice.actions;

export default changeLogSlice.reducer;
