import { call, put, takeLatest } from "redux-saga/effects";

import * as ScreenApi from "../../api/screens.service";

export const Actions = {
    getScreen: "board/screens/single/",
    getScreens: "board/screens/list/",
    premiumActivationToggle: "board/screens/activate-toggle/",
    getKiosksData: "board/screens/get-kiosks-data/",
};

function* getScreenSaga() {
    yield takeLatest(Actions.getScreen + "request", function* (action) {
        try {
            yield put({
                type: Actions.getScreen + "pending",
                payload: {},
            });
            const response = yield call(async () => await ScreenApi.getScreen(action.payload));

            if (!response) throw Error();
            if (response.error) throw Error();
            if (!response.data) throw Error();
            yield put({
                type: Actions.getScreen + "fulfilled",
                payload: response.data,
            });
        } catch (e) {
            yield put({
                type: Actions.getScreen + "rejected",
            });
        }
    });
}

function* getScreensSaga() {
    yield takeLatest(Actions.getScreens + "request", function* (action) {
        try {
            yield put({
                type: Actions.getScreens + "pending",
                payload: {},
            });
            const response = yield call(
                async () => await ScreenApi.getScreens(action.payload.boardId),
            );

            if (!response) throw Error();
            if (response.error) throw Error();
            if (!response.data) throw Error();
            yield put({
                type: Actions.getScreens + "fulfilled",
                payload: {
                    screens: response.data,
                },
            });
        } catch (e) {
            yield put({
                type: Actions.getScreens + "rejected",
                payload: {
                    screens: [],
                },
            });
        }
    });
}

function* premiumActivationToggle() {
    yield takeLatest(Actions.premiumActivationToggle + "request", function* (action) {
        const { payload } = action || {};
        try {
            yield put({
                type: Actions.premiumActivationToggle + "pending",
                payload,
            });
            const getScreensApiResponse = yield call(
                async () =>
                    await ScreenApi.toggleLicense({
                        boardId: payload.boardId,
                        screenId: payload.screenId,
                        screenLicenseActive: payload.value,
                    }),
            );
            if (!getScreensApiResponse) throw Error();
            if (getScreensApiResponse.error) throw Error();
            if (!getScreensApiResponse.data) throw Error();

            yield put({
                type: Actions.premiumActivationToggle + "fulfilled",
                payload,
            });
        } catch (error) {
            yield put({
                type: Actions.premiumActivationToggle + "rejected",
                payload: {
                    ...payload,
                    error,
                },
            });
        }
    });
}

function* getKiosksDataSaga() {
    yield takeLatest(Actions.getKiosksData + "request", function* () {
        try {
            yield put({
                type: Actions.getKiosksData + "pending",
                payload: [],
            });
            const response = yield call(async () => await ScreenApi.getKiosksData());

            if (!response) throw Error();
            if (response.error) throw Error();
            if (!response.data) throw Error();
            if (!response.data.products) throw Error();
            yield put({
                type: Actions.getKiosksData + "fulfilled",
                payload: response.data.products,
            });
        } catch (e) {
            yield put({
                type: Actions.getKiosksData + "rejected",
                payload: e.message,
            });
        }
    });
}

export const manageScreensSaga = [
    getScreenSaga(),
    getScreensSaga(),
    premiumActivationToggle(),
    getKiosksDataSaga(),
];
