import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as BoardAPI from "../api/boards.service";
import * as AppsAPI from "../api/app.service";
import { mergeWithAdvertisingEnabled } from "../utils/general.util";
import { sortApps } from "../utils/app.utils";

export const fetchBoardApps = createAsyncThunk(
	"/board/fetchBoardApps",
	async ({ boardId }) => {
		let response = await AppsAPI.getBoardApps(boardId);
		return {
			response: response,
		};
	}
);

export const fetchBoardDetails = (payload) => {
	return {
		type: "board/details/request",
		payload,
	};
};

export const leaveBoard = (payload) => {
	return {
		type: "board/leave/request",
		payload,
	};
};

export const loadEmbedConfig = createAsyncThunk(
	"/board/embed/loadConfig",
	async ({ accessToken }) => {
		const response = await BoardAPI.loadEmbedConfig(accessToken);

		return { response };
	}
);

const activeBoardSlice = createSlice({
	name: "activeBoard",
	initialState: {
		boardId: null,
		boardDetails: null,
		boardDetailsFetchStatus: "idle",
		leaveBoardStatus: "idle",
		boardApps: [],
		boardScreens: [],
		embedParams: null,
		loadEmbedConfigStatus: "idle",
		// Engagement unit usage restriction.
		hasMemberRestriction: false,
	},
	reducers: {
		resetLeaveBoardStatus: (state) => {
			state.leaveBoardStatus = "idle";
		},
		setBoardRestrictions: (state, action) => {
			state.hasMemberRestriction = action.payload;
		},
		removeSubboard: (state, action) => {
			if (!state.boardDetails) return;

			const index = state.boardDetails.subBoards.find(
				({ boardId }) => boardId === action.payload.boardId
			);
			state.boardDetails.subBoards.splice(index, 1);
		},
		selectActiveBoard: (state, action) => {
			return {
				...state,
				boardId: action.payload,
				boardDetails: null,
				boardDetailsFetchStatus: "pending",
				boardApps: [],
				boardScreens: [],
				boardAppsFetchStatus: "pending",
				boardScreensFetchStatus: "pending",
			};
		},
		resetActiveBoard: (state) => {
			let embedParams = null;
			if (localStorage.getItem("embed")) {
				embedParams = state.embedParams;
			}
			return {
				...state,
				boardId: null,
				boardDetails: null,
				boardDetailsFetchStatus: "idle",
				boardApps: [],
				boardScreens: [],
				embedParams: embedParams,
			};
		},
		refreshBoardDetails: (state, action) => {
			state.boardDetails = mergeWithAdvertisingEnabled(
				action.payload.response
			);
		},
		setApps: (state, action) => {
			state.boardApps = sortApps(action.payload);
		},
		embedParamsAdded: (state, action) => {
			state.embedParams = action.payload;
		},
	},
	extraReducers: {
		"board/details/setApps": (state, action) => {
			state.boardApps = sortApps(action.payload);
		},
		"board/details/fulfilled": (state, action) => {
			state.boardDetailsFetchStatus = "fulfilled";
			state.boardAppsFetchStatus = "fulfilled";
			state.boardApps = sortApps(action.payload.boardApps);
			state.boardDetails = mergeWithAdvertisingEnabled(
				action.payload.boardDetails
			);
			state.boardId = action.payload.boardDetails.boardId;
		},
		"board/details/pending": (state) => {
			return {
				...state,
				boardDetailsFetchStatus: "pending",
				boardAppsFetchStatus: "pending",
				boardApps: null,
				boardDetails: null,
			};
		},
		"board/details/rejected": (state) => {
			return {
				...state,
				boardDetailsFetchStatus: "rejected",
				boardAppsFetchStatus: "rejected",
				boardApps: null,
				boardDetails: null,
			};
		},
		"board/leave/pending": (state) => {
			return {
				...state,
				leaveBoardStatus: "pending",
			};
		},
		"board/leave/fulfilled": (state) => {
			return {
				...state,
				leaveBoardStatus: "fulfilled",
			};
		},
		"board/leave/rejected": (state) => {
			return {
				...state,
				leaveBoardStatus: "rejected",
			};
		},
		[loadEmbedConfig.fulfilled]: (state, action) => {
			// const pattern = /[^0-9a-zA-Z:,]/g
			if (action.payload.response) {
				let response = action.payload.response;
				if (response.data) {
					return {
						...state,
						embedParams: {
							embed: true,
							collections: response.data.collections.replace(
								/[#]/g,
								""
							),
							view: response.data.boardView,
							showPosterName: response.data.showPosterName,
							showComments: response.data.showComments,
							allowedDomains: response.data.allowedDomains,
						},
						loadEmbedConfigStatus: "success",
					};
				}
			}
		},
		[loadEmbedConfig.pending]: (state) => {
			return {
				...state,
				loadEmbedConfigStatus: "pending",
			};
		},
		[loadEmbedConfig.rejected]: (state) => {
			return {
				...state,
				loadEmbedConfigStatus: "rejected",
			};
		},
	},
});
export const {
	selectActiveBoard,
	resetActiveBoard,
	refreshBoardDetails,
	removeSubboard,
	resetLeaveBoardStatus,
	setApps,
	embedParamsAdded,
	setBoardRestrictions,
} = activeBoardSlice.actions;

export default activeBoardSlice.reducer;
