/*
 * @Author: snair@hootboard.com
 * @Date: 2019-02-16 01:18:43
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-11-04 13:41:43
 */

import moment from "moment-timezone";
import { ColorPalette } from "./color.utils";

//  Scheme of hoot

// let hoot = {
// 	title: "",
// 	description: "",
// 	userQuote: "",
// 	buttonLabel: "",
// 	buttonLink: "",
// 	background: "",
// 	font: "",
// 	type: "",
// 	authorName: "",
// 	authorPictureUrl: "",
// 	authorInitials: "",
// 	twitterLink: "",
// 	twitterHandle: "",
// 	images: "",
// 	showTitle: "",
// 	eventDateTime: "",
// 	eventTimezone: "",
// 	eventLocation: "",
// 	postedTime: "",
// 	pinned: "",
// 	collections: "",
// 	mediaUrl: "",
// 	mediaSnippet: "",
// 	sourceType: "",
// 	rehootAllowed: false,
// 	hootStats: {},
// 	fav: false
// };

//  based on the type of hoot, you have to look in several places
//  to gather data in the required schema.
//  My argument with this transform operation is that let's do a transform
//  and get the data in order first so that we don't have to do this condition
//  checking at component level.

//  this method is used to transform the API data of all kinds of hoots listing pages
//  like, bulletin board, my hoots, etc. you can pass the method info a map function.
export const transformHoots = (hoot) => {
	//
	const isHootList = hoot.type === "HLLIST";
	// 
	const result = {
		id: hoot.id || 0,
		listId: hoot.listId || 0,
		listUid: hoot.listUid || null,
		postBoards: hoot.boards || hoot.listBoards || [],
		boardTagsList: hoot.collections,
		authorId: hoot.authorId ? hoot.authorId : null,
		owningBoardId: hoot.owningBoardId ? hoot.owningBoardId : null,
		requiresApproval: hoot.requiresApproval,
		isScheduled: hoot.scheduled ? hoot.scheduled : false,
		scheduledOnDate: hoot.scheduledOnDate ? hoot.scheduledOnDate : null,
		type: hoot.type,
		isPinned: hoot.pinned,
		isSaved: hoot.saved,
		postedTime: hoot.postedTime,
		rehootAllowed: hoot.rehootAllowed,
		sourceType: hoot.sourceType,
		hootStats: hoot.stats
			? hoot.stats
			: { favorites: 0, comments: 0, rehoots: 0 },
		eventTextLocation:
			hoot.eventLoc && hoot.eventLoc.eventTextLocation
				? hoot.eventLoc.eventTextLocation
				: null,
		pinnedComment: hoot.pinnedComment
			? hoot.pinnedComment
			: {
					commentText: null,
					commentPlainText: null,
					commenterName: null,
			  },
		...ColorPalette.colors[hoot.styles.background],
		...parseTitle(hoot),
		...parseDescription(hoot),
		...parseUserQuote(hoot),
		...parseDates(hoot.eventDetails),
		...parseLocation(hoot.eventLoc),
		...parseTwitterInfo(hoot.twitterInfo),
		...parseCollections(hoot),
		...parseAuthorInfo(hoot.authorInfo),
		...parseImages(hoot),
		...parseButtons(hoot.button, hoot.embedableMedia.mediaUrl,isHootList),
		...parseEmbedableMedia(hoot),
		...parsePdfInfo(hoot),
		...parseManageHoot(hoot),
		//TN: Following are added here because these are needed for Edit Hoot
		postingCategory: hoot.postingCategory,
		hootBgColorClass: hoot.hootBgColor,
		customHootThemeColors: hoot.customHootThemeColors,
		recurringRule: hoot.recurringRule,
		hootExpiryDate: hoot.hootLevelExpDate,
	};
	return result;
};

function parseManageHoot(hoot) {
	const defaultValues = {
		disableComments: false,
		expiryDate: null,
		excludeDigests: false,
		favPost: false,
		privateHoot: true,
	};
	const {
		disableComments,
		expiryDate,
		excludeDigests,
		favPost,
		privateHoot,
	} = hoot;

	return {
		manage: {
			...defaultValues,
			disableComments,
			setExpiryDate: expiryDate !== "",
			expiryDate: expiryDate !== "" ? expiryDate : null,
			excludeDigests,
			privateHoot,
		},
		favPost,
	};
}

function parseTitle(hoot) {
	const { title, showTitle, name } = hoot;

	return {
		title: title || name,
		showTitle: showTitle ? showTitle : true,
	};
}

function parseCollections(hoot) {
	if (hoot.collections && hoot.collections.length) {
		return {
			collections: hoot.collections,
		};
	} else {
		return null;
	}
}

function parseDescription(hoot) {
	switch (hoot.hootType) {
		case "HLVID":
		case "HLBLOG":
			return {
				description: hoot.userQuote ? hoot.userQuote : null,
				plainTextDescription: hoot.userQuote ? hoot.userQuote : null,
			};

		default: {
			const { description, plainTextDescription } = hoot;
			return {
				description: description ? description : null,
				plainTextDescription: plainTextDescription
					? plainTextDescription
					: null,
			};
		}
	}
}

function parseDates(eventLoc) {
	let eventDateTime = {
		eventStartDateTime: null,
		eventEndDateTime: null,
		eventTimezone: null,
		freeEvent: true,
	};

	if (eventLoc && eventLoc.eventStartDateTime && eventLoc.eventTimezone) {
		eventDateTime = {
			...eventDateTime,
			eventTimezone: eventLoc.eventTimezone,
		};
	}

	if (eventLoc && eventLoc.eventStartDateTime && eventLoc.eventTimezone) {
		eventDateTime = {
			...eventDateTime,
			eventStartDateTime: `${moment(eventLoc.eventStartDateTime)
				.tz(eventLoc.eventTimezone)
				.format("ddd, YYYY MMM Do h:mm A")}`,
		};
	}

	if (eventLoc && eventLoc.eventStartDateTime && eventLoc.eventTimezone) {
		eventDateTime = {
			...eventDateTime,
			eventStartDateTime: `${moment(eventLoc.eventStartDateTime)
				.tz(eventLoc.eventTimezone)
				.format("ddd, YYYY MMM Do h:mm A")}`,
		};
	}

	if (eventLoc && eventLoc.eventStartDateTime && !eventLoc.eventTimezone) {
		eventDateTime = {
			...eventDateTime,
			eventStartDateTime: `${moment(eventLoc.eventStartDateTime)
				.tz("america/new_york")
				.format("ddd, YYYY MMM Do h:mm A")}`,
		};
	}

	if (eventLoc && eventLoc.eventEndDateTime && eventLoc.eventTimezone) {
		eventDateTime = {
			...eventDateTime,
			eventEndDateTime: `${moment(eventLoc.eventEndDateTime)
				.tz(eventLoc.eventTimezone)
				.format("ddd, YYYY MMM Do h:mm A")}`,
		};
	}

	if (eventLoc && eventLoc.eventEndDateTime && eventLoc.eventTimezone) {
		eventDateTime = {
			...eventDateTime,
			eventEndDateTime: `${moment(eventLoc.eventEndDateTime)
				.tz(eventLoc.eventTimezone)
				.format("ddd, YYYY MMM Do h:mm A")}`,
		};
	}

	if (eventLoc && eventLoc.eventEndDateTime && !eventLoc.eventTimezone) {
		eventDateTime = {
			...eventDateTime,
			eventEndDateTime: `${moment(eventLoc.eventEndDateTime)
				.tz("america/new_york")
				.format("ddd, YYYY MMM Do h:mm A")}`,
		};
	}

	return {
		...eventDateTime,
		freeEvent: (eventLoc && eventLoc.freeEvent) || false,
	};
}

function parseAuthorInfo(authorInfo) {
	if (!authorInfo) return {};
	return {
		authorPictureUrl: authorInfo.authorPicExists
			? authorInfo.authorPictureUrl
			: null,
		authorName: authorInfo.authorName,
		authorInitials: authorInfo.authorInitials,
	};
}

function parsePdfInfo(hoot) {
	if (hoot.pdfUrl && hoot.hootType === "UFPDF") {
		return {
			pdfUrl: hoot.pdfUrl,
		};
	} else {
		return {
			pdfUrl: null,
		};
	}
}

function parseImages(hoot) {
	if (hoot.images && hoot.images.small && hoot.images.small.length) {
		return {
			largeImages: hoot.images.large,
			images: hoot.images.small ,
		};
	}
	else if (hoot.listImageUrl) {
		return {
			images: [{
				url: hoot.listImageUrl,
				id: null,
				width: null,
				height: 425,
			}]
		}
	} else {
		return null;
	}
	// switch (hoot.hootType) {
	// 	case "HLBLOG":
	// 		return {
	// 			images: parseInternalImages(hoot.internalImagesNew, "S"),
	// 			largeImages: parseInternalImages(hoot.internalImagesNew, "L"),
	// 		};

	// 	case "DISC":
	// 		return {
	// 			images: parseHootCoverImages(
	// 				hoot.hootCoverImagesNew.small
	// 					? hoot.hootCoverImagesNew
	// 					: hoot.hootCoverImages,
	// 				"S"
	// 			),
	// 			largeImages: parseHootCoverImages(
	// 				hoot.hootCoverImagesNew.large
	// 					? hoot.hootCoverImagesNew
	// 					: hoot.hootCoverImages,
	// 				"L"
	// 			),
	// 		};

	// 	case "HLVID":
	// 		return {
	// 			images: parseInternalImages(hoot.internalImagesNew, "S"),
	// 			largeImages: parseInternalImages(hoot.internalImagesNew, "L"),
	// 		};

	// 	case "UFIMG":
	// 		return hoot.photoPath
	// 			? {
	// 					images: [
	// 						{
	// 							width: hoot.imgWidth,
	// 							height: hoot.imgHeight,
	// 							url: hoot.photoPath,
	// 						},
	// 					],
	// 					largeImages: [
	// 						{
	// 							width: hoot.imgWidth,
	// 							height: hoot.imgHeight,
	// 							url: hoot.photoPath,
	// 						},
	// 					],
	// 			  }
	// 			: hoot.postImgUrl
	// 			? {
	// 					images: [
	// 						{
	// 							width: hoot.imgWidth,
	// 							height: hoot.imgHeight,
	// 							url: hoot.postImgUrl,
	// 						},
	// 					],
	// 					largeImages: [
	// 						{
	// 							width: hoot.imgWidth,
	// 							height: hoot.imgHeight,
	// 							url: hoot.postImgUrl,
	// 						},
	// 					],
	// 			  }
	// 			: { images: null, largeImages: null };

	// 	case "UFPDF":
	// 		return hoot.photoPath
	// 			? {
	// 					images: [
	// 						{
	// 							width: hoot.imgWidth,
	// 							height: hoot.imgHeight,
	// 							url: hoot.photoPath,
	// 						},
	// 					],
	// 					largeImages: [
	// 						{
	// 							width: hoot.imgWidth,
	// 							height: hoot.imgHeight,
	// 							url: hoot.photoPath,
	// 						},
	// 					],
	// 			  }
	// 			: hoot.postImgUrl
	// 			? {
	// 					images: [
	// 						{
	// 							width: hoot.imgWidth,
	// 							height: hoot.imgHeight,
	// 							url: hoot.postImgUrl,
	// 						},
	// 					],
	// 					largeImages: [
	// 						{
	// 							width: hoot.imgWidth,
	// 							height: hoot.imgHeight,
	// 							url: hoot.postImgUrl,
	// 						},
	// 					],
	// 			  }
	// 			: { images: null, largeImages: null };

	// 	default:
	// 		return { images: null };
	// }
}

function parseUserQuote(hoot) {
	switch (hoot.hootType) {
		case "HLVID":
		case "HLBLOG":
			return {
				userQuote: hoot.desc
					? hoot.desc
					: hoot.postDesc
					? hoot.postDesc
					: null,
			};

		default:
			return null;
	}
}

function parseEmbedableMedia(hoot) {
	if (hoot.embedableMedia && hoot.embedableMedia.length) {
		if (hoot.embededMediaUrl && hoot.embededMediaUrl.length) {
			return {
				mediaUrl: hoot.embededMediaUrl,
				mediaSnippet: hoot.embedableMedia,
			};
		} else {
			return {
				mediaUrl: null,
				mediaSnippet: hoot.embedableMedia,
			};
		}
	} else {
		if (hoot.embededMediaUrl && hoot.embededMediaUrl.length) {
			return {
				mediaUrl: hoot.embededMediaUrl,
				mediaSnippet: null,
			};
		} else {
			return {
				mediaUrl: null,
				mediaSnippet: null,
			};
		}
	}
}

function parseLocation(eventLoc) {
	if (eventLoc && eventLoc.eventLoc) {
		if (eventLoc.eventLocationText) {
			return {
				eventLocationPlaceId: eventLoc.eventLocationPlaceId,
				eventLocationText: eventLoc.eventLocationText,
			};
		} else {
			return {
				eventLocationPlaceId: eventLoc.eventLocationPlaceId,
				eventLocationText: null,
			};
		}
	} else {
		return {
			eventLocationPlaceId: null,
			eventLocationText: null,
		};
	}
}

function parseTwitterInfo(twitterInfo) {
	if (twitterInfo && twitterInfo.twitterHandle && twitterInfo.twitterHandle.length) {
		if (twitterInfo.twitterLink && twitterInfo.twitterLink.length) {
			return {
				twitterLink: twitterInfo.twitterLink,
				twitterHandle: twitterInfo.twitterHandle,
			};
		}
	}

	return {
		twitterHandle: "",
		twitterLink: "",
	};
}

function parseButtons(button, mediaUrl, isHootList) {
	if (button) {
		const { buttonLink, buttonLabel } = button;
		if (buttonLink && buttonLink !== "" && buttonLabel && buttonLabel !== "") {
			return {
				buttonLink: buttonLink,
				buttonLabel: buttonLabel,
			};
		}
	}

	return {
		buttonLink: isHootList ? mediaUrl : null,
		buttonLabel: isHootList ? "View List" : null,
	};
}
