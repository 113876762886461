import { createSlice } from "@reduxjs/toolkit";
import { Actions } from "./unSubscribe.saga";

const initialState = {
	isLoading: false,
	actionPerformed: false,
	isServerError: false,
	getBoardDetailsStatus: "idle",
	boardDetails: null,
};

export const getBoardDetailsAsync = (payload) => {
	return {
		type: Actions.getBoardDetails + "request",
		payload,
	};
};

const slice = createSlice({
	name: "unSubscribe",
	initialState,
	reducers: {},
	extraReducers: {
		"unsubscribe/pending": (state) => {
			return { ...state, isLoading: true, actionPerformed: true };
		},
		"unsubscribe/fulfilled": (state) => {
			return { ...state, actionPerformed: true, isLoading: false };
		},
		"unsubscribe/rejected": (state) => {
			return {
				...state,
				actionPerformed: true,
				isServerError: true,
				isLoading: false,
			};
		},
		[Actions.getBoardDetails + "rejected"]: (state) => {
			return {
				...state,
				getBoardDetailsStatus: "rejected",
			};
		},
		[Actions.getBoardDetails + "pending"]: (state) => {
			return {
				...state,
				getBoardDetailsStatus: "pending",
			};
		},
		[Actions.getBoardDetails + "fulfilled"]: (state, action) => {
			const { boardDetails } = action.payload;

			return {
				...state,
				getBoardDetailsStatus: "fulfilled",
				boardDetails,
			};
		},
	},
});

export const unSubscribeBoardAsyncAction = ({ encParams, boardId }) => {
	return {
		type: "unsubscribe/board/request",
		payload: { encParams, boardId },
	};
};

export const unSubscribePersonalAsyncAction = ({ encParams }) => {
	return {
		type: "unsubscribe/personal/request",
		payload: { encParams },
	};
};

export const unSubscribeInvitationAsyncAction = ({ encParams }) => {
	return {
		type: "unsubscribe/invitation/request",
		payload: { encParams },
	};
};

// export const {} = slice.actions

export default slice.reducer;
