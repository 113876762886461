import moment from "moment-timezone";
import { TIME_INTERVAL } from "../components/Analytics/analytics.slice";
import {
	ENGAGEMENT_CHANNELS,
	ANALYTICS_EU_USAGE_GROUP,
} from "../helper/constants";

export function generateTextBasedOnSelectedInterval(selectedTimeInterval) {
	if (selectedTimeInterval === "WEEK") {
		return "week";
	}

	if (selectedTimeInterval === "DAY") {
		return "day";
	}

	if (selectedTimeInterval === "MONTH") {
		return "month";
	}
}

/**
 * Returns number of days based on start date and end date.
 */
const timeDurationInDays = (startDate, endDate) => {
	return moment(endDate).diff(startDate, "days");
};

/**
 * Used to create all the params we need to send for the segmentation API.
 * @param {object} params
 * @param {string} params.selectedTimeInterval
 * @param {number} params.boardId
 * @param {string} params.event
 * @param {array} params.screenIds
 * @param {array} params.groupBy
 * @param {string} params.customStartDate
 * @param {string} params.customEndDate
 * @param {string} params.timeZone
 * @returns
 */
export const computeSegmentationParams = (params) => {
	const {
		selectedTimeInterval,
		boardId,
		event,
		screenIds,
		customStartDate,
		customEndDate,
		timeZone,
	} = params;

	const filter = transformSegmentationProperties({
		boardId,
		screenIds,
	});

	const { endDateTime, startDateTime, timeGranularity } = getDateParams({
		selectedTimeInterval,
		customEndDate,
		customStartDate,
	});

	const groupBy = (params.groupBy || []).map((item) => {
		if (item == "time") return `time:${timeGranularity}`;
		return item;
	});

	return {
		event,
		filter,
		timeZone,
		endDateTime,
		startDateTime,
		groupBy,
	};
};

/**
 * Used to calculate the time periods & time granularity based on selected interval.
 * @param {object} params
 * @param {string} params.selectedTimeInterval
 * @param {number} params.customEndDate
 * @param {string} params.customStartDate
 */
export const getDateParams = (params) => {
	const { selectedTimeInterval, customEndDate, customStartDate } = params;
	/**
	 * If the selectedTimeInterval is in the format of CUSTOM-startDate-endDate
	 * then we will split and check the first element and compute params
	 *
	 * If the selectedTimeInterval is in the format of WEEK, TODAY, YESTERDAY,
	 * LAST_7_DAYS,LAST_30_DAYS, LAST_3_MONTHS,LAST_6_MONTHS AND LAST_12_MONTHS
	 * we will calculate start date and end date from the present day along with other params
	 */

	const customInterval = selectedTimeInterval.split("-");
	if (customInterval[0] === "CUSTOM") {
		let unit;
		const endDate = moment(customEndDate).endOf("day").unix() * 1000;
		const startDate = moment(customStartDate).startOf("day").unix() * 1000;

		const days = timeDurationInDays(startDate, endDate);
		/**
		 * Determines mixpanel time period unit based on selected time interval
		 */
		if (days === 0) {
			unit = "hour";
		} else if (days <= 30) {
			unit = "day";
		} else if (days > 30 && days <= 90) {
			unit = "week";
		} else {
			unit = "month";
		}

		return {
			endDateTime: endDate,
			startDateTime: startDate,
			timeGranularity: unit,
		};
	} else if (selectedTimeInterval === "TODAY") {
		const endDate = moment().endOf("day").unix() * 1000;
		const startDate = moment().startOf("day").unix() * 1000;

		return {
			endDateTime: endDate,
			startDateTime: startDate,
			timeGranularity: "hour",
		};
	} else if (selectedTimeInterval === "YESTERDAY") {
		const endDate = moment().endOf("day").subtract(1, "day").unix() * 1000;
		const startDate =
			moment().startOf("day").subtract(1, "day").unix() * 1000;

		return {
			endDateTime: endDate,
			startDateTime: startDate,
			timeGranularity: "hour",
		};
	} else if (selectedTimeInterval === "LAST_7_DAYS") {
		const endDate = moment().unix() * 1000;
		const startDate = moment(moment().subtract(7, "days")).unix() * 1000;

		return {
			endDateTime: endDate,
			startDateTime: startDate,
			timeGranularity: "day",
		};
	} else if (selectedTimeInterval === "LAST_30_DAYS") {
		const endDate = moment().unix() * 1000;
		const startDate = moment(moment().subtract(30, "days")).unix() * 1000;

		return {
			endDateTime: endDate,
			startDateTime: startDate,
			timeGranularity: "day",
		};
	} else if (selectedTimeInterval === "LAST_3_MONTHS") {
		const endDate = moment().unix() * 1000;
		const startDate = moment().subtract(3, "months").unix() * 1000;

		return {
			endDateTime: endDate,
			startDateTime: startDate,
			timeGranularity: "month",
		};
	} else if (selectedTimeInterval === "LAST_6_MONTHS") {
		const endDate = moment().unix() * 1000;
		const startDate = moment().subtract(6, "months").unix() * 1000;

		return {
			endDateTime: endDate,
			startDateTime: startDate,
			timeGranularity: "month",
		};
	} else if (selectedTimeInterval === "LAST_12_MONTHS") {
		const endDate = moment().unix() * 1000;
		const startDate = moment().subtract(12, "months").unix() * 1000;

		return {
			endDateTime: endDate,
			startDateTime: startDate,
			timeGranularity: "month",
		};
	} else {
		console.error("Not supported case of selectedTimeInterval");
	}
};

/**
 * Used to transform data as per the chart requirements.
 * @param {object} weeklySegmentationData
 * @param {select} selectedTimeInterval
 * @returns
 */
export const transformData = (weeklySegmentationData, selectedTimeInterval) => {
	const values =
		weeklySegmentationData.values[
			`${ANALYTICS_EU_USAGE_GROUP}`
		];
	const weeks = weeklySegmentationData.series;

	return weeks.map((item, index) => {
		return {
			[selectedTimeInterval === "WEEK"
				? "week"
				: selectedTimeInterval === "MONTH"
				? "month"
				: "day"]: index,
			engagementUnits: values[item],
			x: moment(item).format("MM/DD"),
			label: values[item],
		};
	});
};

export function computePercentageDifference(A, B) {
	return (100 * (A - B)) / ((A + B) / 2);
}

/**
 * Get next engagement units reset date from the given day.
 * @param {number} resetDay Day number.
 */
export const getNextResetDate = (resetDay) => {
	const activeResetDate = moment().set("date", resetDay).startOf("day");

	// Checking if date is already passed.
	if (activeResetDate.isBefore(moment())) {
		return activeResetDate.add(1, "month");
	}

	return activeResetDate;
};

/**
 * When we pass the complete title to the chart, it will truncate title and show ellipsis
 * in order to show complete title we need to break the title into array of words
 * and each word can not exceed 38 characters
 * @param {string} title
 */
const titleBreakDown = (title) => {
	if (!title) return "";
	if (title.length < 30) return title;
	const words = title.split(" ");
	const length = Math.ceil(words.length / 2);
	const partOne = words.slice(0, length).join(" ");
	const partTwo = words.slice(length).join(" ");
	return [partOne, partTwo];
};

/**
 * Transform the segmentation data for App Views graph.
 */
export const transformAppsViewedData = (data) => {
	return data.map((item, index) => ({
		apps: index,
		y: item.count,
		x: item.label,
		label: 0,
	}));
};

/**
 * Since hoot data does not contain number of views, we get it from hoot engagement
 * @param {number} hootId
 * @param {array} hootEngagement
 * @returns
 */
// const getHootViews = (hootId, hootEngagement) => {
// 	const hoot = hootEngagement.find((item) => item.hootId === hootId);
// 	return hoot.numberOfViews;
// };

/**
 * Transform the segmentation data for Hoot Viewed graph.
 */
export const transformHootsViewedData = (data) => {
	return data
		.reduce((result, item) => {
			return [
				...result,
				{ ...item, label: item.children[0]?.label || "" },
			];
		}, [])
		.map((item, index) => ({
			apps: index,
			y: item.count,
			x: titleBreakDown(item.label),
			label: 0,
		}));
};

// To get chart series data when we get props
export const getChartSeries = (props) => {
	const { selectedDeviceEngagement, data } = props;

	if (selectedDeviceEngagement === "All") {
		return [
			{
				name: "Kiosk Engagement Units",
				data: data.map((item) => item.kioskEngagementUnits || 0),
			},
			{
				name: "Web, Mobile & Embed Engagement Units",
				data: data.map((item) => item.engagementUnits),
			},
		];
	} else if (selectedDeviceEngagement === "Kiosk") {
		return [
			{
				name: "Kiosk Engagement Units",
				data: data.map((item) => item.kioskEngagementUnits || 0),
			},
		];
	} else if (selectedDeviceEngagement === "Web, Mobile & Embed") {
		return [
			{
				name: "Web, Mobile & Embed Engagement Units",
				data: data.map((item) => item.engagementUnits),
			},
		];
	} else {
		console.error("No case existed");
	}
};

const TIME_FORMAT = "DD MMM, hha";
const DAY_FORMAT = "MMM DD";

/**
 * Used to transform the data into the chart accepted format
 * @param {object} params
 * @param {Array} params.channel
 * @param {Array} params.screens
 * @param {object} params.engagementData
 * @param {string} params.timeInterval
 */
export const overallEngagementTransformFunction = (params) => {
	const engagementData = params.engagementData || [];
	const shouldFilterByScreens =
		params.channel == "Kiosk" ? params.screens : [];

	const labels = engagementData.map((data) => data.label);

	// To modify labels we are calculating the dates differences &  using it when formatting the labels.
	const days = timeDurationInDays(
		params.dates.startDate,
		params.dates.endDate
	);

	const getUnitsOfSegment = ({ label, segment }) => {
		const labelItem = engagementData.find((item) => item.label === label);
		const children = labelItem.children || [];
		let matchedChildren = children.filter((item) =>
			segment.includes(item.label)
		);
		if (shouldFilterByScreens.length > 0) {
			matchedChildren = matchedChildren.reduce((result, item1) => {
				// finding the screen id based count
				const filteredByScreens = (item1.children || []).filter(
					(item2) => shouldFilterByScreens.includes(item2.label)
				);
				return [...result, ...filteredByScreens];
			}, []);
		}
		return matchedChildren.reduce((result, item) => {
			return result + item.count;
		}, 0);
	};

	let labelFormat = DAY_FORMAT;
	if (
		params.timeInterval === "TODAY" ||
		params.timeInterval === "YESTERDAY" ||
		days === 0
	) {
		labelFormat = TIME_FORMAT;
	}

	const data = labels.map((label, index) => {
		return {
			[params.timeInterval === TIME_INTERVAL.WEEK
				? "week"
				: params.timeInterval === TIME_INTERVAL.MONTH
				? "month"
				: "day"]: index,
			engagementUnits:
				params.channel == "Kiosk"
					? 0
					: getUnitsOfSegment({
							label,
							segment: ENGAGEMENT_CHANNELS.webMobile,
					  }),
			x: moment(label).format(labelFormat),
			label: label,
			kioskEngagementUnits:
				params.channel == "Web, Mobile & Embed"
					? 0
					: getUnitsOfSegment({
							label,
							segment: ENGAGEMENT_CHANNELS.touchKiosk,
					  }),
		};
	});

	return data;
};

/**
 * Used to kiosk data as that is expected by the chart
 */
export const transformKioskData = (analyticsData) => {
	if (
		analyticsData &&
		analyticsData.analytics &&
		analyticsData.analytics.engagementOnKiosks &&
		analyticsData.analytics.engagementOnKiosks.engagementPerKiosk
	) {
		const engagementOnKiosk =
			analyticsData.analytics.engagementOnKiosks.engagementPerKiosk;
		return engagementOnKiosk.map((kiosk) => {
			return {
				name: kiosk.screenId,
				data: [kiosk.engagementUnits],
			};
		});
	} else {
		return [{ name: "kiosk", data: [0, 0] }];
	}
};

const transformSegmentationProperties = (params) => {
	let result = "";

	if (params.boardId) {
		result += `properties.boardId IN ${params.boardId},"${params.boardId}"`;
	}

	if (params.screenIds.length !== 0) {
		result += ` AND properties.screenId IN ${params.screenIds
			.map((val) => `"${val}"`)
			.join(",")}`;
	}

	return result;
};

/**
 * Used to create the chart titles for overall engagement chart.
 * @param {string} device
 * @param {string} event
 * @param {object} dates
 * @returns
 */
export const getChartTitles = (device, event, dates) => {
	let chartDownloadTitle;
	if (device === "Kiosk") {
		chartDownloadTitle = "Kiosk";
	} else if (device === "Web, Mobile & Embed") {
		chartDownloadTitle = "Web, Mobile, Embed";
	} else {
		chartDownloadTitle = "Web, Mobile, Embed & Kiosk";
	}

	const eventName =
		event === ANALYTICS_EU_USAGE_GROUP
			? "Overall Engagement"
			: event;

	return {
		chartTitle:
			dates &&
			`Total Engagement Report (${dates.startDate} - ${dates.endDate})`,
		chartSubTitle: eventName + " - " + chartDownloadTitle,
	};
};
