import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "../../../api/unsubscribe.service";
import * as BoardApi from "../../../api/boards.service";

export const Actions = {
	getBoardDetails: "unsubscribe/board/details/",
};

function* getBoardDetails(action) {
	const base = Actions.getBoardDetails;

	try {
		yield put({ type: base + "pending", payload: {} });
		let { boardId } = action.payload;
		const boardDetailsApiResponse = yield call(
			async () => await BoardApi.getBoardDetails(boardId)
		);
		if (
			boardDetailsApiResponse &&
			boardDetailsApiResponse.status !== "error"
		) {
			yield put({
				type: base + "fulfilled",
				payload: {
					boardDetails: boardDetailsApiResponse,
				},
			});
		} else {
			yield put({
				type: base + "rejected",
				payload: { boardDetails: null },
			});
		}
	} catch (e) {
		yield put({ type: base + "rejected", payload: {} });
	}
}

function* unSubscribeBoard(action) {
	try {
		yield put({ type: "unsubscribe/pending" });

		const { encParams, boardId } = action.payload;

		const apiResponse = yield call(
			async () => await API.unSubscribeBoard({ encParams, boardId })
		);
		if (!apiResponse) throw new Error(apiResponse);

		yield put({ type: "unsubscribe/fulfilled" });
	} catch (e) {
		yield put({ type: "unsubscribe/rejected", payload: e });
	}
}

function* unSubscribePersonal(action) {
	try {
		yield put({ type: "unsubscribe/pending" });

		const { encParams } = action.payload;

		const apiResponse = yield call(
			async () => await API.unSubscribePersonal({ encParams })
		);
		if (!apiResponse) throw new Error(apiResponse);

		yield put({ type: "unsubscribe/fulfilled" });
	} catch (e) {
		yield put({ type: "unsubscribe/rejected", payload: e });
	}
}

function* unSubscribeInvitation(action) {
	try {
		yield put({ type: "unsubscribe/pending" });

		const { encParams } = action.payload;

		const apiResponse = yield call(
			async () => await API.unSubscribeInvitation({ encParams })
		);
		if (!apiResponse) throw new Error(apiResponse);

		yield put({ type: "unsubscribe/fulfilled" });
	} catch (e) {
		yield put({ type: "unsubscribe/rejected", payload: e });
	}
}

export function* unSubscribeBoardSaga() {
	yield takeLatest("unsubscribe/board/request", unSubscribeBoard);
}

export function* unSubscribePersonalSaga() {
	yield takeLatest("unsubscribe/personal/request", unSubscribePersonal);
}

export function* unSubscribeInvitationSaga() {
	yield takeLatest("unsubscribe/invitation/request", unSubscribeInvitation);
}

export function* getBoardDetailsSaga() {
	yield takeLatest(Actions.getBoardDetails + "request", getBoardDetails);
}
