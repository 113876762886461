/*
 * @Author: tnityanand@hootboard.com
 * @Date: 2020-04-03 16:35:12
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-05-27 20:37:09
 */

import {
	transformUser,
	transformUserBoards,
} from "../utils/transformUser.util";
import { apiRequest, httpGetV2, httpPostV2 } from "./http-wrapper";

/**
 * API for fetching user's created or follwed boards
 */
const getUserBoards = async () => {
	const userBoardsApi = await apiRequest("user/boards", {
		extraHeaders: { "x-cache-action": "by-pass" },
	});
	if (!userBoardsApi?.success) throw Error(userBoardsApi);
	return transformUserBoards(userBoardsApi.data);
};

/**
 * API for fetching user data.
 */
const getUserDetails = async () => {
	const userApi = await apiRequest("user");
	if (!userApi || !userApi?.success) throw Error(userApi);

	const userBoards = await getUserBoards();

	return {
		...transformUser(userApi.data),
		userBoards,
	};
};

// /**
//  * API for fetching user's created or follwed boards
//  */
// const getUserDetails = async () => {
// 	let response = httpGetV2("private/user", null, null);
// 	return response;
// };

/**
 * API for fetching user's product onboarding statuses
 */
export const getOnBoardingStatuses = async () => {
	let response = httpGetV2("private/user/onboard", null, null);
	return response;
};

/**
 * API for marking user's product onboarding status as complete
 * @param {Object} params
 * @param {string} params.onboardingTourName
 * @param {string} params.status
 */
export const markOnBoardingStatus = async (params) => {
	let response = httpPostV2(
		"private/user/onboard",
		{
			onboardingTourName: params.onboardingTourName,
			onboardingTourStatus: params.status,
		},
		null
	);
	return response;
};

export { getUserDetails, getUserBoards };
