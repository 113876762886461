export const AccessLevels = {
	/**
	 * Voluntary Admin
	 */
	VA: "VA",
	/**
	 * Poster
	 */
	ME: "ME",
	/**
	 * Private Viewer
	 */
	PV: "PV",
	/**
	 * Admin
	 */
	AA: "AA",
	/**
	 * Super Admin
	 */
	SA: "SA",
	/**
	 * DMO Admin
	 */
	DA: "DA",
	/**
	 * Follower
	 */
	FL: "FL",
};

export const getAccessLevelName = (membershipType) => {
	switch (membershipType) {
		case "VA":
			return "Voluntary Admin";
		case "ME":
			return "Poster";
		case "PV":
			return "Private Viewer";
		case "AA":
			return "Admin";
		case "SA":
			return "Super Admin";
		case "DA":
			return "DMO Admin";
		default:
			return "Follower";
	}
};

export const checkIfAdmin = (membershipType) => {
	switch (membershipType) {
		case "VA":
		case "AA":
		case "SA":
		case "DA":
			return true;
		default:
			return false;
	}
};

export const checkIfJoined = (membershipType) => {
	switch (membershipType) {
		case "VA":
		case "ME":
		case "PV":
		case "AA":
		case "SA":
		case "DA":
			return true;
		default:
			return false;
	}
};

export const getAccessLevelColors = (membershipType) => {
	switch (membershipType) {
		case "VA":
			return "hb-brand-black";
		case "ME":
			return "hb-brand-purple";
		case "PV":
			return "hb-brand-red";
		case "AA":
			return "hb-brand-blue";
		case "SA":
			return "hb-brand-navy";
		case "DA":
			return "hb-brand-green";
		default:
			return "hb-brand-yellow";
	}
};

export const hasAccessToPost = (membershipType) => {
	switch (membershipType) {
		case "VA":
			return true;
		case "ME":
			return true;
		case "PV":
			return false;
		case "AA":
			return true;
		case "SA":
			return true;
		case "DA":
			return true;
		default:
			return false;
	}
};

export const requiresAccessChangeToPost = (
	membershipType,
	boardType = "pri"
) => {
	let isBoardPublic = boardType !== "pri";

	switch (membershipType) {
		case "VA":
			return false;
		case "ME":
			return false;
		case "PV":
			return isBoardPublic ? false : true;
		case "AA":
			return false;
		case "SA":
			return false;
		case "DA":
			return false;
		default:
			return isBoardPublic ? false : true;
	}
};

export const getAccessLevelDescription = (
	membershipType,
	communityCodeRequired = false
) => {
	switch (membershipType) {
		case "VA":
			return `You own this board and can manage all board settings`;
		case "ME":
			return `You can view all posts on this board as well as add content to the board, however you cannot manage any board settings`;
		case "PV":
			return `You can view both public & private posts on this board however you cannot post
			anything. If you want to create posts, ${
				communityCodeRequired
					? "enter the community code shared by the board's admin or"
					: "lease "
			}
			request access.`;
		case "AA":
			return `You have complete access to all of the board settings.`;
		case "SA":
			return `You're a super admin and have complete access to the board`;
		case "DA":
			return `You own this board and have posting access`;
		default:
			return `You can view only public posts on this board and cannot post
			anything. If you want to create posts, ${
				communityCodeRequired
					? "enter the community code shared by the board's admin or"
					: "please "
			}
			request access.`;
	}
};

/**
 * Get all possible permissions for a user based on the hoot & board conditions
 *
 * @param {Object} params
 * @param {Object} params.hoot `selectedHoot` object from state.
 * @param {string} params.context this field is for to decide if the hoot is opened in specific context (eg. shared-collection).
 * @param {number} params.accountId `activeAccountId` from state.
 * @param {Object} params.activeBoard active board object.
 */
export const getPermissionsForHoot = (params) => {
	// by default, there should be only view permission for the hoot.
	const defaultPermissions = ["view-collections"];

	// if any of the params are not present, return defaultPermissions
	if (
		!params.hoot ||
		!params.accountId ||
		!params.activeBoard.membershipType
	) {
		return defaultPermissions;
	}

	// here we have hoots in different types
	const HootOriginTypes = {
		DEFAULT: "Default hoot on the board",
		AUTHOR: "Own hoot (Hoot created by the active user)",
		NON_AUTHOR: "Hoot created by someone else",
		REHOOT: "Rehooted hoot from other boards",
		SHARED_APP_HOOT: "Hoots from other boards (eg. shared collection)",
	};

	// basic lookup table of rules based on the access level and hoot origin type
	const AccessRules = {
		[AccessLevels.AA]: {
			[HootOriginTypes.DEFAULT]: [
				"pin-unpin",
				"remove",
				"admin-message",
				"screen-settings",
			],
			[HootOriginTypes.AUTHOR]: [
				"screen-settings",
				"direct-promote",
				"pin-unpin",
				"delete",
				"reschedule",
				"edit",
				"hide-hoot",
				"admin-message",
				"update-collections",
				"privacy-settings",
				"disable-comments",
				"exclude-newsletters",
			],
			[HootOriginTypes.NON_AUTHOR]: [
				"screen-settings",
				"direct-promote",
				"pin-unpin",
				"delete",
				"reschedule",
				"hide-hoot",
				"update-collections",
				"admin-message",
				"privacy-settings",
				"disable-comments",
				"exclude-newsletters",
				"hoot-approval",
			],
			[HootOriginTypes.REHOOT]: [
				"screen-settings",
				"direct-promote",
				"pin-unpin",
				"remove",
				"reschedule",
				"hide-hoot",
				"hoot-approval",
				"admin-message",
				"update-collections",
				"privacy-settings",
				"disable-comments",
				"exclude-newsletters",
			],
			[HootOriginTypes.SHARED_APP_HOOT]: [
				"view-collections",
				"shared-collection-app-info",
			],
		},
		[AccessLevels.ME]: {
			[HootOriginTypes.DEFAULT]: defaultPermissions,
			[HootOriginTypes.AUTHOR]: [
				"delete",
				"edit",
				"update-collections",
				"reschedule",
			],
			[HootOriginTypes.NON_AUTHOR]: defaultPermissions,
			[HootOriginTypes.REHOOT]: defaultPermissions,
			[HootOriginTypes.SHARED_APP_HOOT]: defaultPermissions,
		},
	};

	// All admins have same access rules,
	// so we don't need to repeat these inside the rule set above
	AccessRules[AccessLevels.DA] = AccessRules[AccessLevels.AA];
	AccessRules[AccessLevels.SA] = AccessRules[AccessLevels.AA];
	AccessRules[AccessLevels.VA] = AccessRules[AccessLevels.AA];

	// for any other membership types, we can return defaultPermissions

	// SHARED_APP_HOOT origin type has defaultPermissions in all cases of access levels
	// so, setting this as initial value means, if the below if else conditions fails somehow, defaultPermissions will return.
	let hootOriginType = HootOriginTypes.SHARED_APP_HOOT;

	if (["shared-collection"].includes(params.context)) {
		// straight forward. checking if its a shared collection hoot.
		hootOriginType = HootOriginTypes.SHARED_APP_HOOT;
	} else if (params.hoot.defaultHoot) {
		// straight forward. checking if its a default hoot.
		hootOriginType = HootOriginTypes.DEFAULT;
	} else if (params.hoot.authorId === params.accountId) {
		// if hoot owner is same as logged in user
		hootOriginType = HootOriginTypes.AUTHOR;

		// if (activeBoard.boardId !== hoot.owningBoardId) { // if the hoot belongs to another board, its a rehooted one.
		// 	hootOriginType = HootOriginTypes.REHOOT;
		// } else { // same board, own hoot
		// 	hootOriginType = HootOriginTypes.AUTHOR;
		// }
	} else {
		if (params.activeBoard.boardId === params.hoot.owningBoardId) {
			// created in this board, but hoot is someone elses hoot
			hootOriginType = HootOriginTypes.NON_AUTHOR;
		} else {
			// checking if the hoot is rehooted into this board
			// the challenge is a shared app hoot could also satisfy this condition.
			const postBoardIds = params.hoot.postBoards.map(
				(item) => item.boardId
			);
			if (postBoardIds.includes(params.activeBoard.boardId)) {
				hootOriginType = HootOriginTypes.REHOOT;
			} // else condition is not needed to be handled since the initial value is initialized as SHARED_APP_HOOT
		}
	}

	// if active board's membershipType is not available in the rule set, simply return defaultPermissions.
	// in our case, all public, private viewers & followers types will be handled here.
	// plus, in case the  membershipType is empty or unknown, that will be also taken care.
	if (!AccessRules[params.activeBoard.membershipType]) {
		return defaultPermissions;
	}

	// if everything looks good, and permissions are available, get the data and return from the object map.
	return AccessRules[params.activeBoard.membershipType][hootOriginType];
};
