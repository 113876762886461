/*
 * @Author: Chandu J S
 * @Date:   2020-07-27 22:12:02
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-07-29 11:45:36
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isNew: true,
	isSaving: false,
	isSaved: false,
	saveError: false,
	showSpinner: true,
	messageError: false,
	customMessages: [],
	messageErrorReason: "Message field cannot be empty!",
	localState: {
		message: "",
		messageId: null,
		screenIds: [],
	},
};

export function getCustomMessagesAsync(payload) {
	return {
		type: "board/screens/messages/get/request",
		payload: payload,
	};
}

export function saveBoardScreenMessageAsync(payload) {
	return {
		type: "board/screens/messages/save/request",
		payload: { ...payload, screenIds: payload.screenIds.join(",") },
	};
}

export function deleteBoardScreenMessageAsync(payload) {
	return {
		type: "board/screens/messages/delete/request",
		payload: payload,
	};
}

const manageCustomMessageSlice = createSlice({
	initialState,
	name: "manageCustomMessage",
	reducers: {
		hideError: (state) => {
			state.saveError = false;
		},
		resetNewMessage: () => {
			return {
				...initialState,
				messageError: true,
			};
		},
		setMessageContent: (state, action) => {
			const localState = action.payload;
			state.messageError = localState.message === "";
			state.localState = action.payload;
		},
		setEditLocalState: (state, action) => {
			const { screenMessageText, screenMessageId, screens } =
				action.payload;

			state.isNew = false;
			state.localState = {
				message: screenMessageText,
				messageId: screenMessageId,
				screenIds: screens.map((item) => item.screenId),
			};
		},
		deleteBoardScreenMessage: (state, action) => {
			state.customMessages = state.customMessages.filter(
				(item) => item.screenMessageId !== action.payload
			);
		},
	},
	extraReducers: {
		"board/screens/messages/get/pending": (state) => {
			state.showSpinner = true;
		},
		"board/screens/messages/get/fulfilled": (state, action) => {
			state.showSpinner = false;
			state.customMessages = action.payload.customMessages;
		},
		"board/screens/messages/get/rejected": (state) => {
			state.showSpinner = false;
		},
		"board/screens/messages/save/pending": (state) => {
			state.saveError = false;
			state.isSaved = false;
			state.isSaving = true;
		},
		"board/screens/messages/save/fulfilled": (state) => {
			state.isSaved = true;
			state.isSaving = false;
		},
		"board/screens/messages/save/rejected": (state) => {
			state.saveError = true;
			state.isSaved = false;
			state.isSaving = false;
		},
	},
});
export const {
	hideError,
	resetNewMessage,
	setMessageContent,
	setEditLocalState,
	deleteBoardScreenMessage,
} = manageCustomMessageSlice.actions;
export default manageCustomMessageSlice.reducer;
