import { PropsWithChildren, Suspense } from "react";
import StandardLoader from "./StandardLoader";
//

export const LazyLoader = (props: PropsWithChildren) => {
	return (
		<Suspense fallback={<StandardLoader type="" />}>
			{props.children}
		</Suspense>
	);
};
