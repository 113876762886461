import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserDetails } from "../api/users.service";

export const fetchUserDetails = createAsyncThunk("user/details", async () => {
	let response = await getUserDetails();
	return {
		userDetails: response,
	};
});

const initialState = {
	isOffline: null,
};

const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setAppOffline: (state, action) => {
			state.isOffline = !!action.payload;
		},
	},
	extraReducers: {},
});

export const { setAppOffline } = appSlice.actions;

export default appSlice.reducer;
