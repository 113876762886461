import { useEffect, useState } from "react";

type HealthyImageProps = {
	/**
	 * Image source.
	 */
	src: string;
	/**
	 * Optional alt attribute.
	 * If not provided, alt attribute will be an empty string.
	 */
	alt?: string;
	/**
	 * Inline custom styles.
	 */
	style?: object;
	onClick?: () => void;
	/**
	 * Callback for handling image loading error.
	 */
	onError?: () => void;
	className?: string;
	/**
	 * Fallback image source in case of error.
	 * If not provided, fallback image will be an empty 1 px image.
	 */
	fallbackSrc?: string;
};

/**
 * This component is a clone of default img tag with onError property.
 * Use this component as a replacement of normal image.
 * This will handle the error and display a default image.
 */
export const HealthyImage = (props: HealthyImageProps) => {
	const handleOnError = () => {
		if (props.onError) props.onError();
		if (props.fallbackSrc) {
			setImageSrc(props.fallbackSrc);
		} else {
			setImageSrc(
				"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
			);
		}
	};

	const [imageSrc, setImageSrc] = useState(props.src || props.fallbackSrc);

	useEffect(() => {
		setImageSrc(props.src || props.fallbackSrc);
	}, [props.src, props.fallbackSrc]);

	return (
		<img
			alt={props.alt}
			src={imageSrc}
			onClick={props.onClick}
			onError={handleOnError}
			className={props.className}
			style={props.style}
		/>
	);
};

HealthyImage.defaultProps = {
	alt: "",
	style: {},
	className: "",
};
