/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-16 19:58:07
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-08-27 15:54:51
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { transformHoots } from "../utils/transformHoots.util";
import * as Hoots from "../api/hoots.service.alt";
import * as HootsService from "../api/hoots.service";

export const refreshData = () => {
	return fetchInitialHoots({
		params: {
			start: 0,
			max: 40,
			upcomingEvents: false,
			freeEvents: false,
		},
	});
};

export const fetchInitialHoots = createAsyncThunk(
	"/myHoots/fetchInitialHoots",
	async ({ params }) => {
		let response = await Hoots.getUserHoots(params);
		return {
			response: response,
		};
	}
);

export const fetchMoreHoots = createAsyncThunk(
	"/myHoots/fetchMoreHoots",
	async ({ params }) => {
		let response = await Hoots.getUserHoots(params);
		return {
			response: response,
		};
	}
);

export const favoriteHootChanged = createAsyncThunk(
	"/myHoots/favoriteHootChanged",
	async ({ hootId, favorite }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		let response;
		if (favorite) {
			response = await HootsService.favoriteHoot(boardId, hootId);
		} else {
			response = await HootsService.unfavoriteHoot(boardId, hootId);
		}
		return { response };
	}
);

const initialState = {
	myHoots: [],
	initialHootsFetchStatus: "idle",
	fetchMoreHootsStatus: "idle",
	shouldFetchMore: false,
	error: false,
	errorMessage: "",
	page: 0,
	max: 40,
	upcomingEvents: false,
	freeEvents: false,
};

const myHootsSlice = createSlice({
	name: "myHoots",
	initialState: initialState,
	reducers: {
		reset: () => {
			return initialState;
		},
		setPage: (state, action) => {
			return {
				...state,
				page: action.payload.page,
			};
		},
		updateHootPinned: (state, action) => {
			const { pin, selectedHootIndex } = action.payload;

			state.myHoots[selectedHootIndex].isPinned = pin;
		},
		updateHootRehoot: (state, action) => {
			const { selectedHootIndex } = action.payload;

			state.myHoots[selectedHootIndex].hootStats.rehoots += 1;
		},
		updateHootFavorite: (state, action) => {
			const { selectedHootIndex, favorite } = action.payload;

			state.myHoots[selectedHootIndex].favPost = favorite;
			state.myHoots[selectedHootIndex].hootStats.favorites += favorite
				? 1
				: -1;
		},
		updateHootApproved: (state, action) => {
			const { selectedHootIndex, selectedHoot } = action.payload;

			if (selectedHootIndex) {
				state.myHoots[selectedHootIndex].requiresApproval = false;
			} else {
				const index = state.myHoots.findIndex(
					({ id }) => id === selectedHoot.id
				);
				state.myHoots[index].requiresApproval = false;
			}
		},
		updateHootRejected: (state, action) => {
			const { selectedHootIndex, selectedHoot } = action.payload;

			if (selectedHootIndex) {
				state.myHoots.splice(selectedHootIndex, 1);
			} else {
				const index = state.myHoots.findIndex(
					({ id }) => id === selectedHoot.id
				);
				state.myHoots.splice(index, 1);
			}
		},
		updateHootCommentCount: (state, action) => {
			const { selectedHootIndex, selectedHoot } = action.payload;

			if (selectedHootIndex) {
				state.myHoots[selectedHootIndex].hootStats.comments += 1;
			} else {
				const index = state.myHoots.findIndex(
					({ id }) => id === selectedHoot.id
				);
				state.myHoots[index].hootStats.comments += 1;
			}
		},
		rehooted: (state, action) => {
			const index = state.myHoots.findIndex(
				({ id }) => id === action.payload.id
			);

			if (index > -1) {
				state.myHoots[index].hootStats.rehoots += 1;
			}
		},
	},
	extraReducers: {
		[fetchInitialHoots.pending]: (state) => {
			return {
				...state,
				initialHootsFetchStatus: "pending",
				myHoots: [],
			};
		},
		[fetchInitialHoots.fulfilled]: (state, action) => {
			if (action.payload.response && action.payload.response.length) {
				const myHoots = (action.payload.response || []).map(
					transformHoots
				);
				return {
					...state,
					myHoots,
					initialHootsFetchStatus: "fulfilled",
					page: state.page + 1,
					shouldFetchMore:
						action.payload.response.length > state.max - 2,
				};
			} else {
				return {
					...state,
					initialHootsFetchStatus: "fulfilled",
					myHoots: [],
					shouldFetchMore: false,
				};
			}
		},
		[fetchInitialHoots.rejected]: (state) => {
			return {
				...state,
				initialHootsFetchStatus: "rejected",
				myHoots: [],
				error: true,
				errorMessage: "Failed to fetch hoots",
			};
		},
		[fetchMoreHoots.pending]: (state) => {
			return {
				...state,
				fetchMoreHootsStatus: "pending",
			};
		},
		[fetchMoreHoots.fulfilled]: (state, action) => {
			if (action.payload.response && action.payload.response.length) {
				return {
					...state,
					fetchMoreHootsStatus: "fulfilled",
					myHoots: [
						...state.myHoots,
						...action.payload.response.map(transformHoots),
					],
					page: state.page + 1,
					shouldFetchMore:
						action.payload.response.length > state.max - 2,
				};
			} else {
				return {
					...state,
					page: state.page + 1,
					fetchMoreHootsStatus: "fulfilled",
					shouldFetchMore: false,
				};
			}
		},
		[fetchMoreHoots.rejected]: (state) => {
			return {
				...state,
				fetchMoreHootsStatus: "rejected",
				myHoots: [],
				error: true,
				errorMessage: "Failed to fetch hoots",
			};
		},

		[favoriteHootChanged.fulfilled]: (state, action) => {
			const { response } = action.payload;
			const { favorite, index } = action.meta.arg;

			if (!response.success) {
				state.myHoots[index].favPost = !favorite;
				state.myHoots[index].hootStats.favorites += !favorite ? 1 : -1;
			}
		},
		[favoriteHootChanged.pending]: (state, action) => {
			const { index, favorite } = action.meta.arg;

			state.myHoots[index].favPost = favorite;
			state.myHoots[index].hootStats.favorites += favorite ? 1 : -1;
		},
		[favoriteHootChanged.rejected]: (state, action) => {
			const { index, favorite } = action.meta.arg;

			state.myHoots[index].favPost = !favorite;
			state.myHoots[index].hootStats.favorites += !favorite ? 1 : -1;
		},
	},
});
export const {
	updateHootPinned,
	updateHootRehoot,
	updateHootFavorite,
	updateHootApproved,
	updateHootRejected,
	updateHootCommentCount,
	rehooted,
} = myHootsSlice.actions;
export default myHootsSlice.reducer;
