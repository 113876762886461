/* eslint-disable react/prop-types */
/* FIXME: Fix lint issues. */
import React, {useEffect} from "react";
import * as filestack from "filestack-js";
import { FILESTACK_KEY } from "../helper/constants";

const client = filestack.init(FILESTACK_KEY);

export const FilestackClient = client;

const FileStack = ({
	fileType,
	maxSize,
	multipleFiles,
	children,
	onUploadDone,
	onOpen,
	onClose,
	className,
	customOptions = {},
	autoOpen = false,
}) => {
	const options = {
		...customOptions,
		accept: fileType,
		viewType: "list",
		maxSize,
		maxFiles: multipleFiles ? 20 : 1,
		uploadInBackground: false,
		onOpen,
		onClose,
		onUploadDone: onUploadDone ? onUploadDone : () => {},
	};

	useEffect(() => {
		if (autoOpen) openIt();
	}, []);

	const openIt = () => client.picker(options).open();

	return (
		<div
			className={`focus:outline-none cursor-pointer ${className}`}
			onClick={openIt}
		>
			{children}
		</div>
	);
};

export default FileStack;
