/*
 * @Author: tnityanand@hootboard.com
 * @Date: 2020-05-14 17:09:51
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-12-14 22:38:49
 */

import moment from "moment-timezone";

let defaultPostHootParams = {
	hootTitle: "",
	hootDescription: "",
	tagsText: "",
	selectedCommIds: "", //Need to send in this format => 14418:pri
	hootCoverImages: "[]",
	removedCoveImgs: "",
	hootBgColor: "hoot-bgcolor-white",
	uploadedFileLink: "",
	callToActionLabel: "",
	callToActionLink: "", //Its not there in existing
	linkHootFetchedData: "",
	linkHootEmbedUrl: "",
	aboutLink: "",
	event: {
		eventStartDate: "",
		eventStartTime: "",
		eventEndDate: "",
		eventEndTime: "",
		freeEvent: false,
	},
	hootLocation: {
		city: "",
		region: "",
		country: "",
		zipCode: "",
		formattedAddress: "",
		latitude: 0,
		longitude: 0,
		placeId: "",
	},
	eventTimezone: moment.tz.guess(), //Get it from moment
	hootTimezone: moment.tz.guess(),
	recurringRule: {
		occurences: "",
		frequency: "",
		interval: "",
		dayOfMonth: "",
		daysOfWeek: "",
		monthOfYear: "",
		recurrenceEndDate: "",
		sendEmailNotification: true,
	},
	postingCategory: "ANNT", //Need to set this
	hootType: "DISC", //This will be set on type of the uploaded file - like pdf - UFPDF, image - UFIMG
	fileType: "html", //File type is set on type of the uploaded file - like image or pdf
	hootExpiryDate: "", //Its not there in existing
	hootExpiryTime: "",
	calendarOnlyHoot: false,
	showTitle: true,
	hootScheduledDate: "",
	hootScheduledTime: "",
	eventTextLoc: "",
	postApproved: false,
	customHoot: false,
};

/**
 * This function will have to be removed if the new v2 API is implemented where the params will be same as the states
 */
function mapEventObjects(eventDetails, postHootParams) {
	const newPostHootParams = {
		...postHootParams,
		event: { ...defaultPostHootParams.event },
		calendarOnlyHoot: false,
	};
	if (eventDetails) {
		const dateFormat = "MM/DD/YYYY";
		const timeFormat = "hh:mm A";
		const dateTimeFormat = dateFormat + " " + timeFormat;

		const eventStart =
			moment(eventDetails.startDate).format(dateFormat) +
			" " +
			moment(eventDetails.startTime).format(timeFormat);
		const eventEnd =
			moment(eventDetails.endDate).format(dateFormat) +
			" " +
			moment(eventDetails.endTime).format(timeFormat);

		newPostHootParams.event.eventStartDate = moment(
			eventStart,
			dateTimeFormat
		)
			.utc()
			.format("MM/DD/YYYY");
		newPostHootParams.event.eventStartTime = moment(
			eventStart,
			dateTimeFormat
		)
			.utc()
			.format("hh:mm A");
		newPostHootParams.event.eventEndDate = moment(eventEnd, dateTimeFormat)
			.utc()
			.format("MM/DD/YYYY");
		newPostHootParams.event.eventEndTime = moment(eventEnd, dateTimeFormat)
			.utc()
			.format("hh:mm A");

		newPostHootParams.event.freeEvent = eventDetails.freeEvent;
		newPostHootParams.calendarOnlyHoot = eventDetails.calendarOnlyHoot;
	}
	return newPostHootParams;
}

function getHootTypeForAcquiredLinkData(data) {
	// 
	const queryString = data.original.original_url.split('?')[1];
	const params = new URLSearchParams(queryString);
	// 
	const listToken = params.get('listToken');

	if (data && data.type === "embedly") {
		//	chances that the hootType could either be HLVID or HLBLOG or HLLIST
		if (data.original && data.original.media && data.original.media.type) {
			if (
				data.original.media.type === "video" ||
				data.original.media.type === "rich"
			) {
				return "HLVID";
			} else {
				return "HLBLOG";
			}
		} else if (listToken) {
			return "HLLIST";
		} else {
			return "HLBLOG";
		}
	} else {
		return "HLBLOG";
	}
}

export function mapPostParams(postParamObj) {
	let postHootParams = { ...defaultPostHootParams };
	let {
		hootTitle,
		hootDesc,
		hootCoverImages,
		removedHootCoverImageIds,
		hootLocation,
		actionButtonLabel,
		actionButtonLink,
		uploadedFileData,
		fileHootShowTitle,
		hootExpiryDate,
		eventDetails,
		recurrenceRule,
		selectedHootBgColor,
		customHootThemeColors,
		selectedBoardId,
		privateHoot,
		selectedBoardPostApproval,
		scheduledHootDate,
		hootVisibility,
		selectedCollections,
		postingCategory,
		acquiredLinkData,
	} = {
		...postParamObj,
	};

	postHootParams = mapEventObjects(eventDetails, postHootParams);

	postHootParams.privateHoot = privateHoot;
	postHootParams.hootTitle = hootTitle;
	postHootParams.hootDescription = hootDesc;
	if (hootCoverImages.length > 0) {
		postHootParams.hootCoverImages = JSON.stringify(hootCoverImages);
	}
	if (removedHootCoverImageIds.length > 0) {
		postHootParams.removedCoveImgs = removedHootCoverImageIds.join();
	}

	// postHootParams.locIds = hootLocation; //Not needed to send
	postHootParams.callToActionLabel = actionButtonLabel;
	postHootParams.callToActionLink = actionButtonLink;
	postHootParams.hootExpiryDate =
		hootExpiryDate !== ""
			? moment(hootExpiryDate).format("MM/DD/YYYY")
			: "";
	postHootParams.hootExpiryTime =
		hootExpiryDate !== "" ? moment(hootExpiryDate).format("h:mm A") : "";
	// if(hootExpiryDate !== ''){
	// 	postHootParams.expireHoot = true;
	// }
	if (recurrenceRule) {
		postHootParams.recurringRule = {
			criteria: recurrenceRule.criteria,
			dateMonth: recurrenceRule.dateMonth,
			day: recurrenceRule.day,
			dayOfMonth: recurrenceRule.dayOfMonth,
			daysOfWeek: recurrenceRule.daysOfWeek,
			frequency: recurrenceRule.frequency,
			interval: recurrenceRule.interval,
			monthOfYear: recurrenceRule.monthOfYear,
			occurences: recurrenceRule.occurences,
			recurrenceEndDate:
				recurrenceRule.criteria === "UNTIL"
					? recurrenceRule.recurrenceEndDate
					: "",
		};
	}
	postHootParams.hootBgColor = selectedHootBgColor;
	postHootParams.customHootThemeColors = customHootThemeColors
		? JSON.stringify(customHootThemeColors)
		: null;
	postHootParams.selectedCommIds = `${selectedBoardId}:${hootVisibility}`; //Need to convert
	postHootParams.postApproved = selectedBoardPostApproval;
	// postHootParams.collections = collections;
	postHootParams.hootScheduledDate = scheduledHootDate;
	// hootVisibility;
	// boardCollections;
	postHootParams.postingCategory = postingCategory;
	if (hootLocation) {
		postHootParams.hootLocation.city = hootLocation.cityName;
		postHootParams.hootLocation.region = hootLocation.regionName;
		postHootParams.hootLocation.country = hootLocation.countryCode;
		postHootParams.hootLocation.latitude = hootLocation.latitude;
		postHootParams.hootLocation.longitude = hootLocation.longitude;
		postHootParams.hootLocation.formattedAddress =
			hootLocation.formattedAddress;
		postHootParams.hootLocation.zipCode = hootLocation.zipCode;
		postHootParams.hootLocation.placeId = hootLocation.googlePlaceId;
	}
	if (uploadedFileData) {
		postHootParams.uploadedFileLink = uploadedFileData.url;
		if (uploadedFileData.mimetype === "application/pdf") {
			postHootParams.hootType = "UFPDF";
			postHootParams.fileType = "pdf";
		} else {
			postHootParams.hootType = "UFIMG";
			postHootParams.fileType = "image";
		}
	}
	postHootParams.showTitle = fileHootShowTitle;
	if (acquiredLinkData) {
		postHootParams.linkHootFetchedData = JSON.stringify(
			["eventbrite", "meetup"].includes(acquiredLinkData.type)
				? acquiredLinkData.photos
				: acquiredLinkData.original
		);
		const { original_url, url, link } = acquiredLinkData.original;
		postHootParams.linkHootEmbedUrl = original_url || url || link || "";
		if (acquiredLinkData.timings && acquiredLinkData.timings.timezone) {
			postHootParams.eventTimezone = acquiredLinkData.timings.timezone;
		}
		postHootParams.hootTitle = acquiredLinkData.title;
		postHootParams.aboutLink = acquiredLinkData.description;
		postHootParams.hootType =
			getHootTypeForAcquiredLinkData(acquiredLinkData);
	}

	if (selectedCollections && selectedCollections.length > 0) {
		let tagsText = "";
		selectedCollections.map((collection, i) => {
			if (i === 0) {
				tagsText = `${collection.filterText}`;
			} else {
				tagsText = `${tagsText},${collection.filterText}`;
			}
		});
		postHootParams.tagsText = tagsText;
	}

	return postHootParams;
}
