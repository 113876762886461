import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isLoading: true,
	isServerError: false,
};

const slice = createSlice({
	name: "verifyEmail",
	initialState,
	reducers: {
		stopLoading: (state) => {
			state.isLoading = false;
		},
	},
	extraReducers: {
		"verify-email/pending": () => {
			return initialState;
		},
		"verify-email/fulfilled": (state) => {
			state.isLoading = false;
			state.isServerError = false;
		},
		"verify-email/rejected": (state) => {
			state.isLoading = false;
			state.isServerError = true;
		},
	},
});

export const verifyEmailAsyncAction = (newEmail) => {
	return {
		type: "verify-email/request",
		payload: newEmail,
	};
};

export default slice.reducer;
export const { stopLoading } = slice.actions;
