/*
 * @Author: snair@hootboard.com
 * @Date: 2020-06-02 17:52:43
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-06-09 17:00:00
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { getExtendedRoles } from "../../api/account.service";
import * as UserApi from "../../api/users.service";

function* userDetails() {
	try {
		yield put({ type: "user/details/pending", payload: {} });
		const userDetailsApiResponse = yield call(
			async () => await UserApi.getUserDetails()
		);
		if (
			userDetailsApiResponse &&
			userDetailsApiResponse.status !== "error"
		) {
			try {
				const rolesApiResponse = yield call(
					async () =>
						await getExtendedRoles(userDetailsApiResponse.email)
				);
				yield put({
					type: "user/details/fulfilled",
					payload: {
						userDetails: userDetailsApiResponse,
						extendedRoles: rolesApiResponse.data,
					},
				});
			} catch (error) {
				yield put({
					type: "user/details/fulfilled",
					payload: {
						userDetails: userDetailsApiResponse,
						extendedRoles: [],
					},
				});
			}
		} else {
			yield put({
				type: "user/details/rejected",
				payload: { userDetails: null },
			});
		}
	} catch (e) {
		yield put({ type: "user/details/rejected", payload: {} });
	}
}

export function* userDetailsSaga() {
	yield takeLatest("user/details/request", userDetails);
}
