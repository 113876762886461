/* eslint-disable react/prop-types */
/* FIXME: Fix lint issues. */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ActionContentItem = ({ title, isCompleted, handleOnClick }) => {
	return (
		<div
			className="grid items-center"
			style={{ gridTemplateColumns: "auto 1fr" }}
		>
			<div className="w-8">
				{isCompleted != null && (
					<div
						className={`flex items-center justify-center h-5 w-5 rounded-full border ml-auto text-center leading-5 ${
							isCompleted
								? "bg-green-600 border-transparent"
								: "border-gray-400"
						}`}
					>
						{isCompleted && (
							<FontAwesomeIcon
								icon={faCheck}
								className="text-white text-xs"
							/>
						)}
					</div>
				)}
			</div>
			<p
				className={`font-medium block w-full ml-2 cursor-pointer text-hb-brand-blue`}
				onClick={(event) => {
					handleOnClick(event);
				}}
			>
				{title}
			</p>
		</div>
	);
};

export default ActionContentItem;
