import { createSlice } from "@reduxjs/toolkit";
import { Actions } from "./productTour.saga";

export const markTourAsFinished = (onboardingTourName) => {
	return {
		type: Actions.markTourAsFinished + "request",
		payload: onboardingTourName,
	};
};

export const markTourAsSkipped = (onboardingTourName) => {
	return {
		type: Actions.markTourAsSkipped + "request",
		payload: onboardingTourName,
	};
};

export const getAllTourStatuses = () => {
	return {
		type: Actions.getAllTourStatuses + "request",
	};
};

const initialState = {
	dataLoaded: false,
	isDisabled: false,
	completedTours: [],
	activeTour: "",
	open: {},
	disableBeacon: true, // value false means show beacon, and true means do not show beacon
};

const productTourSlice = createSlice({
	name: "productTour",
	initialState: initialState,
	reducers: {
		runProductTour: (state, action) => {
			const { tourName, open, disableBeacon } = action.payload;
			state.isDisabled = false;
			if (tourName) {
				state.open[tourName] = open;
			}
			state.activeTour = tourName;

			if (disableBeacon !== undefined) {
				state.disableBeacon = disableBeacon;
			}
		},

		setActiveTour: (state, action) => {
			state.activeTour = action.payload;
		},
		setBeaconState: (state, action) => {
			state.disableBeacon = action.payload;
		},

		disableTour: (state) => {
			state.isDisabled = true;
		},
	},
	extraReducers: {
		[Actions.markTourAsFinished + "request"]: (state, action) => {
			const onboardingTourName = action.payload;
			if (state.completedTours.includes(onboardingTourName)) return;
			state.completedTours = [
				...state.completedTours,
				onboardingTourName,
			];
		},
		[Actions.markTourAsSkipped + "request"]: (state, action) => {
			const onboardingTourName = action.payload;
			if (state.completedTours.includes(onboardingTourName)) return;
			state.completedTours = [
				...state.completedTours,
				onboardingTourName,
			];
		},
		[Actions.getAllTourStatuses + "fulfilled"]: (state, action) => {
			const statuses = action.payload;
			state.dataLoaded = true;
			state.completedTours = statuses
				.filter((item) => {
					return ["completed", "skipped"].includes(
						item.onboardingTourStatus
					);
				})
				.map((item) => item.onboardingTourName);
		},
	},
});

export const { runProductTour, setActiveTour, setBeaconState, disableTour } =
	productTourSlice.actions;

export default productTourSlice.reducer;
