import { call, put, takeLatest } from "redux-saga/effects";
import * as Services from "../../api/changelog.service";

function* changeLog() {
	try {
		yield put({ type: "changelog/get/pending" });
		const apiResponse = yield call(async () => {
			const filterResponse = await Services.getFilters();
			if (!filterResponse) return false;

			const beamerApiResponse = await Services.getData(
				filterResponse.role
			);
			return {
				feedUrl: beamerApiResponse.url,
				badgeCount: beamerApiResponse.count,
			};
		});
		if (!apiResponse) throw Error("Unknown error!");
		yield put({
			type: "changelog/get/fulfilled",
			payload: apiResponse,
		});
	} catch (e) {
		yield put({
			type: "changelog/get/rejected",
			payload: e,
		});
	}
}

export function* changeLogSaga() {
	yield takeLatest("changelog/get/request", changeLog);
}
