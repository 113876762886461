/** This method removes emojis from given string */
export const emojiFilter = (originalText = "") => {
	return originalText.replace(/[^\p{L}\p{N}\p{P}\p{Z}]/gu, "");
};

/** This method removes special characters from given string */
export const specialCharFilter = (originalText = "", replaceWith = "") => {
	return originalText.replace(/[^a-z0-9_-\w\s]/g, replaceWith);
};

/** This method removes special characters from given string */
export const boardNameFilter = (originalText = "") => {
	return originalText.replace(/[^a-z0-9!@&()+_-\w\s]/g, "");
};

/** This method removes special characters from given string */
export const specialCharsExceptFilter = (
	originalText = "",
	replaceWith = ""
) => {
	return emojiFilter(originalText).replace(/[&\\#+$~%^*<>{}]/g, replaceWith);
};

/** This method convert normal string to tag formated string */
export const tagFilter = (originalText = "") => {
	originalText = originalText.toLowerCase();

	// remove accents, swap ñ for n, etc
	var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
	var to = "aaaaaeeeeiiiioooouuuunc------";

	for (var i = 0, l = from.length; i < l; i++) {
		originalText = originalText.replace(
			new RegExp(from.charAt(i), "g"),
			to.charAt(i)
		);
	}

	originalText = originalText
		.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
		.replace(/\s+/g, "-") // collapse whitespace and replace by -
		.replace(/-+/g, "-"); // collapse dashes

	return originalText;
};

export const Filters = {
	/** This method removes alphabets from given string */
	alphabetsFilter: (originalText) => {
		return (originalText || "").replace(/[a-zA-Z](.*)/g, "");
	},
	/**
	 * Sanitize special characters from given board name for URL.
	 */
	boardNameOnUrlFilter: (originalText) => {
		return specialCharFilter(originalText, "_").replace(/ /g, "_");
	},
	emojiFilter,
	boardNameFilter,
	specialCharFilter,
	specialCharsExceptFilter,
	tagFilter,
};
