/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react/prop-types */

/* FIXME: Fix lint issues. */
import React from "react";

import Spinner from "../Spinners/circle";

// below components needs to be cleaned up.

export function Button(props) {
    const {
        children,
        color,
        backgroundColor,
        style,
        buttonColor,
        className = "",
        loading = false,
        large = false,
        disabled = false,
    } = props;

    const { loading: _, buttonColor: ___, backgroundColor: __, ...rawProps } = props;

    return (
        <button
            {...rawProps}
            style={{
                color: color,
                backgroundColor: backgroundColor,
                ...style,
            }}
            className={
                `rounded ${className} ${generateButtonStyle("Button", buttonColor)} ${
                    disabled ? "cursor-not-allowed" : "cursor-pointer"
                } block truncate text-sm outline-none md:text-md ${
                    className.indexOf("text-") === -1 ? "text-white" : ""
                } ${
                    className.indexOf("rounded") === -1 ? "rounded" : ""
                } px-4 active:opacity-50 sm:px-6 ` + (large ? "py-4" : "py-2")
            }>
            {loading ? <Spinner className="spinner" /> : children}
        </button>
    );
}

export function OutlineButton(props) {
    const {
        children,
        color,
        style,
        buttonColor,
        backgroundColor,
        className = "",
        loading = false,
        large = false,
        onClick = () => {},
    } = props;
    return (
        <button
            onClick={onClick}
            {...props}
            style={{
                color: color,
                backgroundColor: backgroundColor,
                ...style,
            }}
            className={
                `${className} ${generateButtonStyle(
                    "OutlineButton",
                    buttonColor,
                )} block truncate rounded border-solid bg-white px-6 text-sm outline-none active:opacity-50 md:text-md ` +
                (large ? "py-4" : "py-2")
            }>
            {loading ? <Spinner className="spinner" /> : children}
        </button>
    );
}

export function TextButton(props) {
    const { children, color, style, align = "center", large = false, className } = props;
    return (
        <a
            {...props}
            style={{
                color: color,
                backgroundColor: "transparent",
                ...style,
            }}
            className={
                `text-${align} block cursor-pointer truncate rounded border-none fill-current px-6 text-sm font-semibold outline-none active:opacity-50 md:text-md ${className} ` +
                (large ? "py-4" : "py-2")
            }>
            {children}
        </a>
    );
}

export function IconButton(props) {
    const { children, color, style, large = false, className, backgroundColor } = props;
    // FIXME: Fix lint issues.
    // eslint-disable-next-line no-unused-vars
    const { buttonColor: _, ...filteredProps } = props;
    return (
        <a
            {...filteredProps}
            style={{
                color: color,
                backgroundColor: backgroundColor ? backgroundColor : "transparent",
                ...style,
            }}
            className={
                `flex flex-row items-center justify-center truncate outline-none ${className} block rounded border-none fill-current py-2 px-2 text-sm  font-semibold  active:opacity-50 md:text-md ${className} ` +
                (large ? "py-4" : "py-2")
            }>
            {children}
        </a>
    );
}

function generateButtonStyle(buttonType, buttonColor) {
    if (!buttonColor || buttonColor === "") {
        return "";
    } else {
        switch (buttonColor) {
            case "blue":
                if (buttonType === "OutlineButton") {
                    return "border border-hb-brand-blue text-hb-brand-blue";
                } else {
                    return "bg-hb-brand-blue text-white border-hb-brand-blue";
                }
            case "red":
                if (buttonType === "OutlineButton") {
                    return "border border-hb-brand-red text-hb-brand-red";
                } else {
                    return "bg-hb-brand-red text-white border-hb-brand-red";
                }
            case "yellow":
                if (buttonType === "OutlineButton") {
                    return "border border-hb-brand-yellow text-hb-brand-yellow";
                } else {
                    return "bg-hb-brand-yellow text-white border-hb-brand-yellow";
                }
            case "green":
                if (buttonType === "OutlineButton") {
                    return "border border-hb-brand-green text-hb-brand-green";
                } else {
                    return "bg-hb-brand-green text-white border-hb-brand-green";
                }
            case "navy":
                if (buttonType === "OutlineButton") {
                    return "border border-hb-brand-navy text-hb-brand-navy";
                } else {
                    return "bg-hb-brand-navy text-white border-hb-brand-navy";
                }
            case "black":
                if (buttonType === "OutlineButton") {
                    return "border border-hb-brand-black text-hb-brand-black";
                } else {
                    return "bg-hb-brand-black text-white border-hb-brand-black";
                }
            case "gray-dark":
                if (buttonType === "OutlineButton") {
                    return "border border-hb-brand-gray-dark text-hb-brand-gray-dark";
                } else {
                    return "bg-hb-brand-gray-dark text-white border-hb-brand-gray-dark";
                }
            case "purple":
                if (buttonType === "OutlineButton") {
                    return "border border-hb-brand-purple text-hb-brand-purple";
                } else {
                    return "bg-hb-brand-purple text-white border-hb-brand-purple";
                }
            case "dove":
                if (buttonType === "OutlineButton") {
                    return "border border-dove text-dove";
                } else {
                    return "bg-dove text-white border-dove";
                }
            case "slate":
                if (buttonType === "OutlineButton") {
                    return "border border-slate text-slate";
                } else {
                    return "bg-slate text-white border-slate";
                }
            case "frost":
                if (buttonType === "OutlineButton") {
                    return "border border-frost text-frost";
                } else {
                    return "bg-frost text-white border-frost";
                }
            case "midnight":
                if (buttonType === "OutlineButton") {
                    return "border border-midnight text-midnight";
                } else {
                    return "bg-midnight text-white border-midnight";
                }

            default:
        }
    }
}

export function BlurButton(props) {
	const {
		children,
		color,
		style,
		className,
		backgroundColor,
		activeState,
		activeStyle,
		onClick,
		label,
		loading,
	} = props;
	// FIXME: Fix lint issues.
	// eslint-disable-next-line no-unused-vars
	return (
		loading ? <Spinner className="spinner" /> :
			<button
				style={style}
				onClick={onClick ? onClick : null}
				className={`relative flex cursor-pointer items-center justify-center overflow-hidden border-2 transition-[border] duration-300 ease-in-out hover:decoration-none ${className ? className : ""
					} ${activeState ? "border-white" : "border-[rgba(255,255,255,0.3)] hover:border-white"
					} ${label ? "rounded-lg text-white font-semibold px-5 py-2 whitespace-nowrap" : "rounded-full h-10 w-10"}`}>
				<div
					className={`absolute top-0 left-0 -z-10 h-full w-full ${activeState
							? activeStyle
								? activeStyle
								: ""
							: "bg-[rgba(255,255,255,0.26)] blur"
						}`}></div>
				<>
					{children && children}
					{label && label}
				</>
			</button>
	);
}

export default { Button, OutlineButton, TextButton, IconButton, BlurButton };
