import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "../../../api/account.service";

function* verifyEmail(action) {
	try {
		yield put({ type: "verify-email/pending" });

		const newEmail = action.payload;

		const apiResponse = yield call(
			async () => await API.changeAccountEmail(newEmail)
		);

		if (!apiResponse) throw new Error(apiResponse);

		yield put({ type: "verify-email/fulfilled" });
	} catch (e) {
		yield put({ type: "verify-email/rejected", payload: e });
	}
}

export function* verifyEmailSaga() {
	yield takeLatest("verify-email/request", verifyEmail);
}
