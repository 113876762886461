export const transformUser = (data: any) => {
	return {
		memberCreated: data.memberCreated,
		displayName: data.displayName,
		firstName: data.firstName,
		lastName: data.lastName,
		userBoards: [],
		isPasswordResetRequired: data.isPasswordResetRequired,
		userInitials: data.userInitials,
		userPictureExists: data.userPictureExists,
		userPictureUrl: data.userPictureUrl,
		onboardingDone: data.onboardingDone,
		redirectTo: data.redirectTo,
		partyId: data.partyId,
		email: data.email,
		siteAdminRole: data.siteAdminRole,
		token: data.token,
		userColorCss: data.userColorCss,
		emailValidated: data.emailValidated,
		defaultLocation: data.defaultLocation,
		locations: data.locations,
		removeLocations: data.removeLocations,
		userExternalAccount: data.userExternalAccount,
	};
};

/**
 * Transform new user board to old format.
 */
export const transformUserBoards = (data: any[]) => {
	if (!data) return [];
	return data.map((item) => {
		return {
			boardName: item?.name,
			boardDesc: item?.description,
			locationId: item?.location?.googlePlaceId,
			boardType: item?.type,
			boardLogo: item?.logoUrl,
			boardBackgroundImageLink: item?.backgroundImageUrl,
			boardThemeCustomColors: item?.customColors?.board,
			boardId: item?.id,
			premiumBoard: !!item?.isPremium,
			boardUrl: item?.url,
			followersCount: item?.stats?.members,
			hootsCount: item?.stats?.hoots,
			membershipType: item?.membershipTypeCode,
			pinned: !!item?.isPinned,
			preApproveHoots: !!item?.approvalSettings?.hoots,
			preApproveComments: !!item?.approvalSettings?.comments,
			hootVisibility: item?.hootVisibility,
			//
			// boardOwnerId: null,
			// privateBoard: false,
			// domainName: null,
			// approvalStatus: "APR",
			// refSource: null,
			// globalBoard: false,
			// paidTags: false,
			// boardCollectionRequired: false,
			// customUrl: null,
			// removeLocations: false,
			// partnerBoardId: 0,
			// parentBoardId: 0,
			// collections: null,
			// requestAccessToPost: false,
			// embedCustTheme: false,
			// customBgImage: false,
			// allowMembersToInviteOthers: false,
			// freeEngagementUnits: 0,
			// screenJoiningAccess: null,
			// memRequestCount: 0,
			// boardCommunityCode: null,
			// showCommCode: false,
			// showInfoPage: false,
			// showPosterName: false,
			// mobileJoin: false,
			// screenLayout: null,
			// boardLocation: null,
			// screenAccessToken: null,
			// boardVisibPublic: false,
			// boardHideSearch: false,
			// boardVisibChange: false,
			// locations: null,
			// boardAdmin: null,
			// weeklyDigestDay: "MON",
			// currentPlan: null,
			// purchasedLicenses: null,
			// hootViewLimit: null,
			// subBoards: null,
			// communityCodeRequired: null,
			// superParentSubBoards: null,
			// parentBoard: null,
			// subscriptionId: null,
			// b2bCustomer: null,
			// trialPeriod: null,
			// customSettings: null,
			// trialAvailed: null,
			// trialEndDate: null,
			// boardCollections: null,
			// upgadeCompletePercent: 0,
			// boardExists: null,
			// hootLimitCount: null,
			// viewLimitCount: 0,
			// boardBlocked: null,
			// manuallyUpgraded: null,
			// partnershipStatus: null,
			// hoots: null,
			// admins: null,
			// privacy: null,
			// boardAttr: null,
			// pinnedSequence: null,
			// partnerRelation: null,
			// hidePosterName: false,
			// hideHootDescription: false,
			// planInterval: null,
			// dmoBoard: false,
			// securedBoard: false,
			// customHootThemeColors: null,
			// businessHours: null,
			// businessDetails: null,
			// businessCategories: null,
			// businessPhotos: null,
			// parentDmoBoardId: null,
			// claimedRecord: false,
			// claimRequested: false,
			// euCycleResetDay: 0,
			// userBasedPricing: false,
			// hbLocalEnabled: false,
			// emailBoard: false,
			// subDomain: false,
		};
	});
};
