/*
 * @Author: Chandu J S
 * @Date:   2020-08-10 10:03:59
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-11-12 14:59:36
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as BoardsService from "../../api/boards.service";
import { DEFAULT_COLOR_PALETTE } from "../../helper/constants";
import { boardNameFilter, tagFilter } from "../../helper/filters.helper";
import { fetchUserBoardsAsync } from "../../redux/activeAccount.slice";
import * as ABActions from "../../redux/activeBoard.slice";

const defaultBoardParams = {
	start: 0,
	max: 20,
	addBoardPosts: false,
	upcomingEvents: false,
	freeEvents: false,
	showFeaturedCollection: true,
	showScheduledHoots: true,
	customURL: "",
	addUpdateCollectionStatus: "idle",
};

export const checkUrl = createAsyncThunk(
	"/manageBoard/checkUrl",
	async (x, thunkApi) => {
		const {
			activeBoard: { boardId },
			manageBoard: {
				changes: { customUrl },
			},
		} = thunkApi.getState();

		const checkResponse = await BoardsService.checkUrlAvailability(
			boardId,
			customUrl
		);

		if (checkResponse.data) {
			return { urlAvailable: false };
		}

		const saveResponse = await BoardsService.saveCustomUrl(
			boardId,
			customUrl
		);

		return { urlAvailable: true, response: saveResponse };
	}
);

export const deleteBoard = createAsyncThunk(
	"/manageBoard/deleteBoard",
	async (x, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await BoardsService.deleteBoard(boardId);

		function timeout(ms) {
			return new Promise((resolve) => setTimeout(resolve, ms));
		}
		async function wait() {
			await timeout(1000);
		}

		await wait();

		return { response };
	}
);

export const fetchBoardData = createAsyncThunk(
	"/manageBoard/fetchBoardData",
	async (boardId) => {
		const params = {
			start: 0,
			max: 1,
			addBoardPosts: false,
			upcomingEvents: false,
			freeEvents: false,
			showFeaturedCollection: false,
			showScheduledHoots: false,
		};

		const response = await BoardsService.getManageBoardDetails(
			boardId,
			params
		);

		return response;
	}
);

export const refreshBoardDetails = createAsyncThunk(
	"/manageBoard/refreshBoardDetails",
	async (boardId, thunkApi) => {
		const boardDetails = await BoardsService.getBoardDetails(
			boardId,
			defaultBoardParams
		);
		thunkApi.dispatch(
			ABActions.refreshBoardDetails({ response: boardDetails })
		);
		return { boardDetails };
	}
);

export const saveManageBoardDetails = createAsyncThunk(
	"/manageBoard/saveManageBoardDetails",
	async ({ boardId, settings }, thunkApi) => {
		const response = await BoardsService.manageBoard(boardId, {
			...settings,
			boardThemeCustomColors: JSON.stringify(
				settings.boardThemeCustomColors
			),
			customHootThemeColors: settings.customHootThemeColors
				? JSON.stringify(settings.customHootThemeColors)
				: null,
		});

		await thunkApi.dispatch(refreshBoardDetails(boardId));
		thunkApi.dispatch(fetchUserBoardsAsync());

		return { response };
	}
);

export const updateCollection = createAsyncThunk(
	"/manageBoard/updateCollection",
	async (x, thunkApi) => {
		const {
			manageBoard: { editingCollection },
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await BoardsService.updateBoardCollection(
			boardId,
			editingCollection.collectionId,
			{
				featured: editingCollection.featured,
				parentTagId: editingCollection.collectionParentId,
				price: editingCollection.price,
				tagText: `#${editingCollection.collectionName.replace(
					/#/g,
					""
				)}`,
			}
		);

		const boardDetails = await BoardsService.getBoardDetails(
			boardId,
			defaultBoardParams
		);
		thunkApi.dispatch(
			ABActions.refreshBoardDetails({ response: boardDetails })
		);

		return { response };
	}
);

export const newBoardCollection = createAsyncThunk(
	"/manageBoard/newBoardCollection",
	async (x, thunkApi) => {
		const {
			manageBoard: { editingCollection },
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await BoardsService.addBoardCollection(boardId, {
			featured: editingCollection.featured,
			parentTagId: 0,
			price: 0,
			tagText: `#${editingCollection.collectionName.replace(/#/g, "")}`,
		});

		const boardDetails = await BoardsService.getBoardDetails(
			boardId,
			defaultBoardParams
		);
		thunkApi.dispatch(
			ABActions.refreshBoardDetails({ response: boardDetails })
		);
		return { response };
	}
);

export const deleteCollection = createAsyncThunk(
	"/manageBoard/deleteCollection",
	async ({ collectionId }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await BoardsService.deleteBoardCollection(
			boardId,
			collectionId
		);

		const boardDetails = await BoardsService.getBoardDetails(
			boardId,
			defaultBoardParams
		);
		thunkApi.dispatch(
			ABActions.refreshBoardDetails({ response: boardDetails })
		);

		return { response };
	}
);

export const collectionFeaturedUpdated = createAsyncThunk(
	"/manageBoard/collectionFeaturedUpdated",
	async ({ filterId, featured }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await BoardsService.updateCollectionFeatured(
			boardId,
			filterId,
			featured
		);

		const boardDetails = await BoardsService.getBoardDetails(
			boardId,
			defaultBoardParams
		);
		thunkApi.dispatch(
			ABActions.refreshBoardDetails({ response: boardDetails })
		);

		return { response };
	}
);

export const updateWeeklyDigestDay = createAsyncThunk(
	"/manageBoard/updateWeeklyDigestDay",
	async ({ weeklyDigestDay }, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await BoardsService.saveWeeklyDigestDay(
			boardId,
			weeklyDigestDay
		);

		return { response };
	}
);

export const addParentBoard = createAsyncThunk(
	"/manageBoard/addParentBoard",
	async (x, thunkApi) => {
		const {
			manageBoard: { tempParentBoard },
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await BoardsService.addParentBoard(
			boardId,
			tempParentBoard.boardId
		);

		const boardDetails = await BoardsService.getBoardDetails(
			boardId,
			defaultBoardParams
		);
		thunkApi.dispatch(
			ABActions.refreshBoardDetails({ response: boardDetails })
		);

		return { response };
	}
);

export const removeParentBoard = createAsyncThunk(
	"/manageBoard/removeParentBoard",
	async (x, thunkApi) => {
		const {
			manageBoard: {
				changes: { parentBoard },
			},
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await BoardsService.removeParentBoard(
			boardId,
			parentBoard.boardId
		);

		const boardDetails = await BoardsService.getBoardDetails(
			boardId,
			defaultBoardParams
		);
		thunkApi.dispatch(
			ABActions.refreshBoardDetails({ response: boardDetails })
		);

		return { response };
	}
);

export const removeSubboard = createAsyncThunk(
	"/manageBoard/removeSubboard",
	async (subboardId, thunkApi) => {
		const {
			activeBoard: { boardId },
		} = thunkApi.getState();

		const response = await BoardsService.removeSubboard(
			boardId,
			subboardId
		);

		if (response.status) {
			thunkApi.dispatch(
				ABActions.removeSubboard({ boardId: subboardId })
			);
		}

		const boardDetails = await BoardsService.getBoardDetails(
			boardId,
			defaultBoardParams
		);
		thunkApi.dispatch(
			ABActions.refreshBoardDetails({ response: boardDetails })
		);

		return { response };
	}
);

const initialState = {
	fetchingBoardSettingsStatus: "pending",

	boardNameError: false,
	boardDescriptionError: false,
	boardCommunityCodeError: false,

	settings: {
		// securedBoard: false,
		// hideHootDescription: false,
		// hidePosterName: false,
	},
	changes: {},
	madeChanges: false,
	editingCollection: null,
	editingCollectionIndex: -1,
	isAdding: false,

	urlAvailable: false,
	urlCheckStatus: null,
	recommendedColorScheme: { background: "", font: "", button: "" },

	savingStatus: "",
	attemptingDelete: false,
	deleteStatus: "",

	tempParentBoard: null,
	addingParentPending: false,

	removingParentPending: false,

	subBoardsToRemove: {},
	addUpdateCollectionStatus: "idle",
	addCollectionStatus: "idle",

	deleteCollectionStatus: "idle", // added for mark as done
	collectionFeaturedUpdatedStatus: "idle", // added for mark as done

	addCollectionMixpanelData: {},

	isSaving: false,
	boardInfoSelectedTab: 0,

	pageType: "",
};

const manageBoardSlice = createSlice({
	name: "manageBoard",
	initialState,
	reducers: {
		updateHideHootDescription: (state, action) => {
			state.changes.hideHootDescription = action.payload;
		},
		setCustomHootThemeColors: (state, action) => {
			state.changes.customHootThemeColors = action.payload;
		},
		updateHidePosterName: (state, action) => {
			state.changes.hidePosterName = action.payload;
		},
		undoChanges: (state) => {
			state.changes = state.settings;
			state.boardNameError = false;
			state.boardDescriptionError = false;
			state.boardCommunityCodeError = false;
		},
		setParentBoard: (state, action) => {
			state.tempParentBoard = action.payload;
		},
		deleteComplete: (state) => {
			state.deleteStatus = "";
			state.attemptingDelete = false;
		},
		setAttemptingDelete: (state, action) => {
			state.attemptingDelete = action.payload;

			if (!action.payload) {
				state.deleteStatus = "";
			}
		},
		setDefaultColorScheme: (state, action) => {
			state.changes.boardThemeCustomColors = {
				...state.changes.boardThemeCustomColors,
				...action.payload,
			};
		},
		setRecommendedColorScheme: (state, action) => {
			state.changes.boardThemeCustomColors = {
				...state.changes.boardThemeCustomColors,
				colorPalette: "rec",
				...action.payload,
			};
		},
		customUrlChanged: (state, action) => {
			state.changes.customUrl =
				action.payload === "" ? null : action.payload;
			state.urlAvailable = false;
			state.urlCheckStatus = null;
		},
		setIsAddingCollection: (state) => {
			state.isAdding = true;
			state.editingCollection = { collectionName: "", featured: false };
		},
		editCollectionNameChanged: (state, action) => {
			state.editingCollection.collectionName = tagFilter(action.payload);
		},
		editCollectionFeaturedChanged: (state, action) => {
			state.editingCollection.featured = action.payload;
		},
		setEditingCollection: (state, action) => {
			const { collection, index } = action.payload;
			state.editingCollection = collection;
			state.editingCollectionIndex = index;
		},
		closeEditModal: (state) => {
			state.editingCollection = null;
			state.editingCollectionIndex = -1;
			state.isAdding = false;
		},
		boardNameEntered: (state, action) => {
			state.changes.boardName = boardNameFilter(action.payload);
			state.boardNameError = action.payload === "";
		},
		boardDescEntered: (state, action) => {
			state.changes.boardDesc = action.payload;
			state.boardDescriptionError = action.payload.length < 80;
		},
		boardLogoChanged: (state, action) => {
			state.changes.boardLogo = action.payload;
		},
		boardThemeColorPaletteChanged: (state, action) => {
			state.changes.boardThemeCustomColors.colorPalette = action.payload;
		},
		boardThemeButtonColorChanged: (state, action) => {
			state.changes.boardThemeCustomColors.button = action.payload;
		},
		boardThemeFontColorChanged: (state, action) => {
			state.changes.boardThemeCustomColors.font = action.payload;
		},
		boardThemeBackgroundColorChanged: (state, action) => {
			state.changes.boardThemeCustomColors.background = action.payload;
		},
		backgroundImageLinkChanged: (state, action) => {
			state.changes.boardBackgroundImageLink = action.payload;
		},
		boardCommunityCodeChanged: (state, action) => {
			state.changes.boardCommunityCode = action.payload;
			state.boardCommunityCodeError = action.payload.length < 6;
		},
		allowMembersToInviteOthersChanged: (state, action) => {
			state.changes.allowMembersToInviteOthers = action.payload;
		},
		screenJoiningAccessChanged: (state, action) => {
			state.changes.screenJoiningAccess = action.payload;
		},
		communityCodeRequiredChanged: (state, action) => {
			state.changes.communityCodeRequired = action.payload;
		},
		hootVisibilityChanged: (state, action) => {
			state.changes.hootVisibility = action.payload;
		},
		preApprovehootsChanged: (state, action) => {
			state.changes.preApproveHoots = action.payload;
		},
		preApproveCommentsChanged: (state, action) => {
			state.changes.preApproveComments = action.payload;
		},
		boardHideSearchChanged: (state, action) => {
			state.changes.boardHideSearch = action.payload;
		},
		secureBoardChanged: (state, action) => {
			state.changes.securedBoard = action.payload;
		},
		locationAdded: (state, action) => {
			state.changes.locations.push(action.payload);
		},
		locationRemoved: (state, action) => {
			state.changes.locations.splice(action.payload, 1);
			state.changes.removeLocations = true;
		},
		privateBoardChanged: (state, action) => {
			state.changes.privateBoard = action.payload;
			state.changes.requestAccessToPost = action.payload;
		},
		updateLocation: (state, action) => {
			state.changes.locations = [action.payload];
		},
		boardInfoTabView: (state, action) => {
			state.boardInfoSelectedTab = action.payload;
		},
	},
	extraReducers: {
		[fetchBoardData.fulfilled]: (state, action) => {
			const temp = {
				...action.payload,
				locations: action.payload.locations
					? action.payload.locations
					: [],
			};
			const defaultTheme = DEFAULT_COLOR_PALETTE;
			const theme = {
				boardThemeCustomColors: temp.boardThemeCustomColors
					? Object.keys(temp.boardThemeCustomColors).length === 0
						? defaultTheme
						: temp.boardThemeCustomColors
					: defaultTheme,
			};

			state.settings = { ...temp, ...theme };
			state.changes = { ...temp, ...theme };
			state.urlAvailable = false;
			state.urlCheckStatus = null;
			state.fetchingBoardSettingsStatus = "fulfilled";
		},
		[fetchBoardData.pending]: () => {
			return initialState;
		},
		[fetchBoardData.rejected]: (state) => {
			state.fetchingBoardSettingsStatus = "rejected";
		},

		[saveManageBoardDetails.fulfilled]: (state, action) => {
			// We will check if pageType is equal to action.meta.arg.pageType to avoid updating the
			// wrong `savingStatus` value
			if (state.pageType === action.meta.arg.pageType) {
				state.settings = state.changes;
				state.savingStatus = "fulfilled";
			}
		},
		[saveManageBoardDetails.pending]: (state, action) => {
			// Set pageType in pending state to prevent reading the stale value of `savingStatus`
			const pageType = action.meta.arg.pageType;
			state.savingStatus = "pending";
			state.pageType = pageType;
		},
		[saveManageBoardDetails.rejected]: (state) => {
			state.savingStatus = "rejected";
		},

		[updateCollection.fulfilled]: (state) => {
			state.changes.boardCollections[
				state.editingCollectionIndex
			].collectionName = `#${state.editingCollection.collectionName.replace(
				/#/g,
				""
			)}`;
			state.changes.boardCollections[
				state.editingCollectionIndex
			].featured = state.editingCollection.featured;
			state.editingCollection = null;
			state.editingCollectionIndex = -1;
			state.addUpdateCollectionStatus = "success";
		},
		[updateCollection.pending]: (state) => {
			state.addUpdateCollectionStatus = "pending";
		},
		[updateCollection.rejected]: (state) => {
			state.addUpdateCollectionStatus = "rejected";
		},

		[deleteCollection.fulfilled]: (state) => {
			state.deleteCollectionStatus = "success"; /// added
		},
		[deleteCollection.pending]: (state, action) => {
			const { index } = action.meta.arg;
			state.changes.boardCollections.splice(index, 1);
		},
		[deleteCollection.rejected]: () => {},

		[newBoardCollection.pending]: (state) => {
			state.addCollectionMixpanelData = {};
		},
		[newBoardCollection.fulfilled]: (state, action) => {
			const {
				response: { data, message },
			} = action.payload;

			if (message !== "success") {
				return;
			}

			state.changes.boardCollections.push({
				featured: data.featured,
				collectionId: data.filterId,
				parentTagId: data.parentTagId,
				price: data.price,
				collectionName: data.tagText,
			});
			state.addCollectionMixpanelData = {
				collectionId: data.filterId,
				parentCollectionId: data.parentTagId,
				collectionPrice: data.price,
				collectionName: data.tagText,
			};
			state.isAdding = false;
			state.editingCollection = null;
			state.editingCollectionIndex = -1;
			state.addUpdateCollectionStatus = "success";
			state.addCollectionStatus = "success";
		},
		[newBoardCollection.pending]: (state) => {
			state.addCollectionStatus = "rejected";
			state.addUpdateCollectionStatus = "pending";
		},
		[newBoardCollection.rejected]: (state) => {
			state.addCollectionStatus = "rejected";
			state.addUpdateCollectionStatus = "rejected";
		},

		[updateWeeklyDigestDay.fulfilled]: (state, action) => {
			state.isSaving = false;
			state.changes.weeklyDigestDay = action.meta.arg.weeklyDigestDay;
		},
		[updateWeeklyDigestDay.pending]: (state) => {
			state.isSaving = true;
		},
		[updateWeeklyDigestDay.rejected]: (state) => {
			state.isSaving = false;
		},

		[collectionFeaturedUpdated.fulfilled]: (state, action) => {
			const { response } = action.payload;
			state.collectionFeaturedUpdatedStatus = "success";

			if (response.message !== "success") {
				state.changes.boardCollections =
					state.settings.boardCollections;
			}
		},
		[collectionFeaturedUpdated.pending]: (state, action) => {
			const { filterId, featured } = action.meta.arg;

			state.changes.boardCollections.forEach((x, i) => {
				if (
					state.changes.boardCollections[i].collectionId === filterId
				) {
					state.changes.boardCollections[i].featured = featured;
				} else {
					state.changes.boardCollections[i].featured = false;
				}
			});
		},
		[collectionFeaturedUpdated.rejected]: (state) => {
			state.changes.boardCollections = state.settings.boardCollections;
		},

		[checkUrl.fulfilled]: (state, action) => {
			const { urlAvailable } = action.payload;

			state.urlAvailable = urlAvailable;

			if (!urlAvailable) {
				state.urlCheckStatus = "rejected";
				return;
			}

			state.urlCheckStatus = "fulfilled";
			state.settings.customUrl = state.changes.customUrl;
		},
		[checkUrl.pending]: (state) => {
			state.urlCheckStatus = "pending";
			state.urlAvailable = false;
		},
		[checkUrl.rejected]: (state) => {
			state.urlCheckStatus = "rejected";
			state.urlAvailable = false;
		},

		[deleteBoard.fulfilled]: (state, action) => {
			const { boardDeleted } = action.payload.response;

			state.deleteStatus = boardDeleted ? "fulfilled" : "rejected";
		},
		[deleteBoard.pending]: (state) => {
			state.deleteStatus = "pending";
		},
		[deleteBoard.rejected]: (state) => {
			state.deleteStatus = "rejected";
		},

		[addParentBoard.fulfilled]: (state, action) => {
			state.addingParentPending = false;

			if (action.payload.response.status) {
				state.changes.parentBoard = state.tempParentBoard;
				state.changes.parentBoardId =
					state.tempParentBoard && state.tempParentBoard.boardId;
				state.tempParentBoard = null;
			}
		},
		[addParentBoard.pending]: (state) => {
			state.addingParentPending = true;
		},
		[addParentBoard.rejected]: (state) => {
			state.addingParentPending = false;
		},

		[removeParentBoard.fulfilled]: (state, action) => {
			state.removingParentPending = false;

			if (action.payload.response.status) {
				state.changes.parentBoard = null;
				state.changes.parentBoardId = 0;
			}
		},
		[removeParentBoard.pending]: (state) => {
			state.removingParentPending = true;
		},
		[removeParentBoard.rejected]: (state) => {
			state.removingParentPending = false;
		},

		[removeSubboard.fulfilled]: (state, action) => {
			const subboardId = action.meta.arg;

			delete state.subBoardsToRemove[subboardId];
		},
		[removeSubboard.pending]: (state, action) => {
			const subboardId = action.meta.arg;

			state.subBoardsToRemove[subboardId] = true;
		},
		[removeSubboard.rejected]: (state, action) => {
			const subboardId = action.meta.arg;

			delete state.subBoardsToRemove[subboardId];
		},
	},
});

export const {
	updateHideHootDescription,
	updateHidePosterName,
	deleteComplete,
	backgroundImageLinkChanged,
	setIsAddingCollection,
	editCollectionNameChanged,
	editCollectionFeaturedChanged,
	closeEditModal,
	setEditingCollection,
	boardNameEntered,
	boardDescEntered,
	boardLogoChanged,
	boardThemeColorPaletteChanged,
	boardThemeButtonColorChanged,
	boardThemeFontColorChanged,
	boardThemeBackgroundColorChanged,
	boardCommunityCodeChanged,
	allowMembersToInviteOthersChanged,
	screenJoiningAccessChanged,
	communityCodeRequiredChanged,
	hootVisibilityChanged,
	setCustomHootThemeColors,
	preApproveCommentsChanged,
	preApprovehootsChanged,
	boardHideSearchChanged,
	secureBoardChanged,
	locationAdded,
	locationRemoved,
	privateBoardChanged,
	customUrlChanged,
	setRecommendedColorScheme,
	setDefaultColorScheme,
	setAttemptingDelete,
	setParentBoard,
	undoChanges,
	updateLocation,
	boardInfoTabView,
} = manageBoardSlice.actions;

export default manageBoardSlice.reducer;
