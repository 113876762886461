import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "../../api/hoots.service";

export const Actions = {
	selectCollectionApp: "bulletin-board/select-collection-app/",
	getRelatedTags: "bulletin-board/related-tags/",
};

function* selectCollectionAppSaga() {
	const base = Actions.selectCollectionApp;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId, params } = action.payload;

			yield put({
				type: Actions.getRelatedTags + "request",
				payload: {
					boardId,
					params: {
						searchString: params.searchString,
						collectionsUnion: params.collectionsUnion,
					},
				},
			});
			yield put({ type: base + "pending" });

			const apiResponse = yield call(async () => {
				return await API.getHootsForCollections(boardId, params);
			});

			if (!apiResponse) {
				throw new Error(apiResponse);
			}

			yield put({
				type: base + "fulfilled",
				payload: {
					hoots: apiResponse,
					params,
				},
			});
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

function* getRelatedTagsSaga() {
	const base = Actions.getRelatedTags;

	yield takeLatest(base + "request", function* (action) {
		try {
			const { boardId, params } = action.payload;

			yield put({ type: base + "pending" });

			const apiResponse = yield call(async () => {
				return await API.getRelatedTags({ boardId, params });
			});

			if (
				apiResponse &&
				apiResponse.message &&
				apiResponse.message === "success"
			) {
				yield put({
					type: base + "fulfilled",
					payload: apiResponse.data,
				});
			} else {
				throw new Error(apiResponse);
			}
		} catch (payload) {
			yield put({
				type: base + "rejected",
				payload,
			});
		}
	});
}

export const bulletinBoardSagas = [
	selectCollectionAppSaga(),
	getRelatedTagsSaga(),
];
