/*
 * @Author: snair@hootboard.com
 * @Date: 2020-06-11 17:27:17
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-06-11 20:54:03
 */

import { call, put, takeLatest } from "redux-saga/effects";
import * as HootApi from "../../api/hoots.service.alt";

function* hootsFromPinnedBoards(action) {
	try {
		yield put({
			type: "boards/pinned/hoots/pending",
			payload: {},
		});
		const pinnedBoardHootsApiResponse = yield call(
			async () =>
				await HootApi.getHootsFromPinnedBoards(action.payload.params)
		);
		if (
			pinnedBoardHootsApiResponse &&
			pinnedBoardHootsApiResponse.status !== "error"
		) {
			yield put({
				type: "boards/pinned/hoots/fulfilled",
				payload: {
					hoots: pinnedBoardHootsApiResponse,
				},
			});
		} else {
			yield put({
				type: "boards/pinned/hoots/rejected",
				payload: {
					hoots: [],
				},
			});
		}
	} catch (e) {
		yield put({
			type: "boards/pinned/hoots/rejected",
			payload: {},
		});
	}
}
export function* hootsFromPinnedBoardsSaga() {
	yield takeLatest("boards/pinned/hoots/request", hootsFromPinnedBoards);
}

function* moreHootsFromPinnedBoards(action) {
	try {
		yield put({
			type: "boards/pinned/hoots/more/pending",
			payload: {},
		});
		const pinnedBoardHootsApiResponse = yield call(
			async () =>
				await HootApi.getHootsFromPinnedBoards(action.payload.params)
		);
		if (
			pinnedBoardHootsApiResponse &&
			pinnedBoardHootsApiResponse.status !== "error"
		) {
			yield put({
				type: "boards/pinned/hoots/more/fulfilled",
				payload: {
					hoots: pinnedBoardHootsApiResponse,
				},
			});
		} else {
			yield put({
				type: "boards/pinned/hoots/more/rejected",
				payload: {
					hoots: [],
				},
			});
		}
	} catch (e) {
		yield put({
			type: "boards/pinned/hoots/more/rejected",
			payload: {},
		});
	}
}
export function* moreHootsFromPinnedBoardsSaga() {
	yield takeLatest(
		"boards/pinned/hoots/more/request",
		moreHootsFromPinnedBoards
	);
}
