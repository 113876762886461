/**
 * Here we have all the validators for the input fields.
 * To make the field required, you can just pass the second parameter as `true`.
 * We will use these validators all over the app to make the validations consistent.
 * Most of the validators listed here are self explanatory.
 * Read through the error messages defined inside the functions to understand what they do.
 * TODO: Add more validators.
 */
export const InputValidators = {
    name: (value, isRequired = true) => {
        const valueForValidation = (value || "").trim();

        let hasError = false;
        let errorMessage = "";

        if (valueForValidation && valueForValidation.length > 0) {
            if (valueForValidation.length > 80) {
                hasError = true;
                errorMessage = "Name should not be greater than 80 characters";
            }
        } else {
            if (isRequired) {
                hasError = true;
                errorMessage = "Please enter a valid name";
            }
        }

        return {
            value,
            hasError,
            errorMessage,
        };
    },
    orientation: (value, isRequired = true) => {
        const valueForValidation = (value || "").trim();

        let hasError = false;
        let errorMessage = "";

        const availableValues = ["POR", "LAN"];

        if (valueForValidation && valueForValidation.length > 0) {
            if (!availableValues.some((el) => el == valueForValidation)) {
                hasError = true;
                errorMessage =
                    "Please enter a valid orientation. It can be either portrait or landscape";
            }
        } else {
            if (isRequired) {
                hasError = true;
                errorMessage = "Please enter a valid orientation";
            }
        }

        return {
            value,
            hasError,
            errorMessage,
        };
    },
    boardName: (value, isRequired = true) => {
        const valueForValidation = (value || "").trim();

        let hasError = false;
        let errorMessage = "";

        if (valueForValidation && valueForValidation.length > 0) {
            if (valueForValidation.length > 80) {
                hasError = true;
                errorMessage = "Board name should not be greater than 80 characters";
            }
        } else {
            if (isRequired) {
                hasError = true;
                errorMessage = "Please enter a valid name for your board";
            }
        }

        return {
            value,
            hasError,
            errorMessage,
        };
    },
    description: (value, isRequired = true) => {
        const valueForValidation = (value || "").trim();

        let hasError = false;
        let errorMessage = "";

        if (valueForValidation && valueForValidation.length >= 10) {
            if (valueForValidation.length > 250) {
                hasError = true;
                errorMessage = "A brief description not greater than 250 characters is required";
            }
        } else {
            if (isRequired) {
                hasError = true;
                errorMessage = "A brief description not less than 10 characters is required";
            }
        }

        return {
            value,
            hasError,
            errorMessage,
        };
    },
    boardDescription: (value, isRequired = true) => {
        const valueForValidation = (value || "").trim();

        let hasError = false;
        let errorMessage = "";

        if (valueForValidation && valueForValidation.length >= 20) {
            if (valueForValidation.length > 250) {
                hasError = true;
                errorMessage =
                    "A brief description of your board not greater than 250 characters is required";
            }
        } else {
            if (isRequired) {
                hasError = true;
                errorMessage =
                    "A brief description of your board not less than 20 characters is required";
            }
        }

        return {
            value,
            hasError,
            errorMessage,
        };
    },
    phoneNumber: (value, isRequired = false) => {
        const valueForValidation = (value || "").trim().replace(/[\s()+\-.]|ext/gi, "");

        let hasError = false;
        let errorMessage = "";

        if (valueForValidation) {
            const pattern = /^\d{7,}$/;
            if (!pattern.test(valueForValidation)) {
                hasError = true;
                errorMessage = "Invalid phone number!";
            }
        } else {
            if (isRequired) {
                hasError = true;
                errorMessage = "Phone number is a required field!";
            }
        }

        return {
            value,
            hasError,
            errorMessage,
        };
    },
    email: (value, isRequired = false) => {
        const valueForValidation = (value || "").trim();

        let hasError = false;
        let errorMessage = "";

        if (valueForValidation) {
            const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            if (!valueForValidation.match(pattern)) {
                hasError = true;
                errorMessage = "Invalid email address!";
            }
        } else {
            if (isRequired) {
                hasError = true;
                errorMessage = "Email is a required field!";
            }
        }

        return {
            value,
            hasError,
            errorMessage,
        };
    },
    website: (value, isRequired = false) => {
        // TODO: Add more validations for website.
        const valueForValidation = (value || "").trim();

        let hasError = false;
        let errorMessage = "";

        if (valueForValidation) {
            const pattern =
                /^(https?:\/\/)?([\w\d-_]+)\.([\w\d-_.]+)\/?\??([^#\n\r]*)?#?([^\n\r]*)/gm;
            if (!valueForValidation.match(pattern)) {
                hasError = true;
                errorMessage = "Invalid website address!";
            }
        } else {
            if (isRequired) {
                hasError = true;
                errorMessage = "This is a required field!";
            }
        }

        return {
            value,
            hasError,
            errorMessage,
        };
    },
    boardLogo: (value, isRequired = true) => {
        const valueForValidation = (value || "").trim();

        let hasError = false;
        let errorMessage = "";

        if (!valueForValidation && isRequired) {
            hasError = true;
            errorMessage = "Board logo is a required field!";
        }

        return {
            value,
            hasError,
            errorMessage,
        };
    },
    businessCategories: (value, isRequired = true) => {
        let hasError = false;
        let errorMessage = "";

        if (value.length === 0 && isRequired) {
            hasError = true;
            errorMessage = "At least one category is required!";
        }

        return {
            value,
            hasError,
            errorMessage,
        };
    },
    location: (value, isRequired = true) => {
        let hasError = false;
        let errorMessage = "";

        if (!value && isRequired) {
            hasError = true;
            errorMessage = "Location is required!";
        }

        return {
            value,
            hasError,
            errorMessage,
        };
    },
    addressLine: (value, isRequired = true) => {
        let hasError = false;
        let errorMessage = "";

        if (!value && isRequired) {
            hasError = true;
            errorMessage = "Address line should not be empty!";
        }

        return {
            value,
            hasError,
            errorMessage,
        };
    },
    //
    /**
     * This is very simple utility method to find if there are any active errors in the give object.
     * This function only checks if there are any true values in the object.
     * Once we have a better form management library, we can replace the implementation.
     */
    hasErrors: (errors) => {
        const errorKeys = Object.keys(errors);
        return !errorKeys.some((item) => !!errors[item] && typeof errors[item] === "boolean");
    },
};
