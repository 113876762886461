/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-27 11:48:22
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-08-21 19:34:48
 */

import { call, put, takeLatest } from "redux-saga/effects";
import * as BoardApi from "../../api/boards.service";
import * as UserApi from "../../api/users.service";

function* changeBoardAccess(action) {
	try {
		yield put({
			type: "board/access/change/pending",
			payload: {},
		});
		const changeAccessRequest = yield call(
			async () => await BoardApi.requestBoardAccess(action.payload.params)
		);
		if (
			changeAccessRequest &&
			changeAccessRequest.boardCommunityCodeValid
		) {
			const userDetails = yield call(
				async () => await UserApi.getUserDetails()
			);
			if (userDetails) {
				yield put({
					type: "board/access/change/fulfilled",
					payload: { loginSuccess: true, userDetails: userDetails },
				});
			} else {
				yield put({
					type: "board/access/change/rejected",
					payload: { loginSuccess: true, userDetails: null },
				});
			}
		} else {
			yield put({
				type: "board/access/change/rejected",
				payload: { userExists: false },
			});
		}
	} catch (e) {
		yield put({
			type: "board/access/change/rejected",
			payload: {},
		});
	}
}

export function* changeBoardAccessSaga() {
	yield takeLatest("board/access/change/request", changeBoardAccess);
}

function* requestBoardAccess(action) {
	try {
		yield put({
			type: "board/access/request/pending",
			payload: {},
		});
		const changeAccessRequest = yield call(
			async () => await BoardApi.requestBoardAccess(action.payload.params)
		);
		if (changeAccessRequest.status !== "error") {
			const userDetails = yield call(
				async () => await UserApi.getUserDetails()
			);
			if (userDetails) {
				yield put({
					type: "board/access/request/fulfilled",
					payload: { loginSuccess: true, userDetails: userDetails },
				});
			} else {
				yield put({
					type: "board/access/request/fulfilled",
					payload: { loginSuccess: true, userDetails: null },
				});
			}
		} else {
			yield put({
				type: "board/access/request/rejected",
				payload: { userExists: false },
			});
		}
	} catch (e) {
		yield put({
			type: "board/access/request/rejected",
			payload: {},
		});
	}
}

export function* requestBoardAccessSaga() {
	yield takeLatest("board/access/request/request", requestBoardAccess);
}

function* followBoard(action) {
	try {
		yield put({
			type: "board/follow/pending",
			payload: {},
		});
		const followBoard = yield call(
			async () => await BoardApi.joinBoard(action.payload.params)
		);
		if (followBoard && !followBoard.linkExpired) {
			const userDetails = yield call(
				async () => await UserApi.getUserDetails()
			);
			if (userDetails) {
				yield put({
					type: "board/follow/fulfilled",
					payload: {
						loginSuccess: true,
						userDetails: userDetails,
					},
				});

				// refId is the invitation code.
				// if the user is invited to join the board, after joining the board, pin the board.
				if (action.payload.params.refId) {
					// user/board/pin/request to the pin the board.
					yield put({
						type: "user/board/pin/request",
						payload: { boardId: action.payload.params.boardId },
					});
				}
			} else {
				yield put({
					type: "board/follow/rejected",
					payload: { loginSuccess: true, userDetails: null },
				});
			}
		} else {
			yield put({
				type: "board/follow/rejected",
				payload: {
					loginSuccess: true,
					userDetails: null,
					linkExpired: followBoard.linkExpired
						? followBoard.linkExpired
						: false,
				},
			});
		}
	} catch (e) {
		yield put({
			type: "board/follow/rejected",
			payload: {},
		});
	}
}

export function* followBoardSaga() {
	yield takeLatest("board/follow/request", followBoard);
}
