export const mixpanelBoardDetailsObject = (boardDetails) => {
	if (!boardDetails) return {};
	const boardLocations = [];
	if (boardDetails.locations) {
		boardDetails.locations.map((loc) => {
			boardLocations.push(loc.googlePlaceId);
		});
	}
	return {
		boardApproveComments: boardDetails.preApproveComments,
		boardApprovePosts: boardDetails.preApproveHoots,
		boardLocations: boardLocations,
		boardLocationSetting: !boardDetails.globalBoard,
		boardLogo: boardDetails.boardLogo,
		boardSecurityType: boardDetails.privateBoard
			? "only members with access can post"
			: "anyone can post",
		boardSecret: boardDetails.boardHideSearch,
		boardPlan: boardDetails.premiumBoard ? "paid" : "free",
		boardCollectionCount: (boardDetails.boardCollections
			? boardDetails.boardCollections
			: []
		).length,
		boardSubBoardCount: (boardDetails.subBoards
			? boardDetails.subBoards
			: []
		).length,
		boardSubCommunityType: boardDetails.boardUsedForSubType,
		boardCommunityType: boardDetails.boardType,
		boardDescription: boardDetails.boardDesc,
		boardHootCount: boardDetails.hootsCount,
		boardFollowCount: boardDetails.followersCount,
		boardName: boardDetails.boardName,
		boardId: boardDetails.boardId,
		boardUrl: boardDetails.boardUrl,
	};
};

export const mixpanelHootDetailsObject = (hootData, selectedBoardId) => {
	let hootCategory = "Link";
	if (hootData?.type === "UFIMG" || hootData?.type === "UFPDF") {
		hootCategory = "File";
	} else if (hootData?.type === "DISC") {
		hootCategory = "Text";
	}

	let rehootBoardIds = [],
		rehootBoardNames = [];
	if (hootData?.hootStats.rehoots > 0 && hootData?.postBoards) {
		const rehootedBoards = hootData?.postBoards.filter(
			(board) => board.boardId !== hootData?.owningBoardId
		);
		for (const rehootBoard of rehootedBoards) {
			rehootBoardIds.push(rehootBoard.boardId);
			rehootBoardNames.push(rehootBoard.boardName);
		}
	}
	return {
		sourceType: hootData?.sourceType,
		hootAboutLink: hootData?.userQuote ? hootData?.userQuote : "",
		aboutLink: hootData?.userQuote ? hootData?.userQuote : "",
		hootPrivate: hootData?.privateHoot,
		hootMediaSource: hootData?.mediaUrl,
		hootTagsPostedTo: hootData?.boardTagsList,
		rehootedBoardIds: rehootBoardIds,
		tagsPostedTo: rehootBoardIds,
		rehootBoardIDs: rehootBoardIds,
		rehootBoardNames: rehootBoardNames,
		path: window.location.href,
		hootCreatorId: hootData?.authorId !== null ? hootData?.authorId : "",
		hootType: hootData?.type,
		hootOwningBoardId:
			hootData?.owningBoardId !== null ? hootData?.owningBoardId : "",
		boardId: selectedBoardId || 0,
		hootColorId: hootData?.hootBgColorClass,
		hootColor: hootData?.hootBgColorClass ? true : false,
		hootButton: hootData?.buttonLabel !== null ? true : false,
		//hootLocation: 'string',
		hootEventLocation:
			hootData?.eventLocationPlaceId !== null
				? hootData?.eventLocationPlaceId
				: "",
		hootEvent: hootData?.eventDateTime ? true : false,
		//hootFlow: 'string',
		hootCategory: hootCategory,
		createdAt:hootData.createdAt,
		hootDescription: hootData?.plainTextDescription,
		hootId: hootData?.id,
		hootTitle: hootData?.title,
		hootUrl: window.location.href,
		boardsPostedToCount: (hootData?.postBoards ? hootData.postBoards : [])
			.length,
	};
};

export const mixpanelPostHootObject = (
	postedHootObj,
	selectedBoardId,
	selectedBoardName,
	createdHootUrl
	// isEditHoot
) => {
	let hootCategory = "Text",
		hootType = "DISC";
	if (postedHootObj.uploadedFileData) {
		hootCategory = "File";
		if (postedHootObj.uploadedFileData.mimetype === "application/pdf") {
			hootType = "UFPDF";
		} else {
			hootType = "UFIMG";
		}
	}
	if (postedHootObj.acquiredLinkData) {
		hootCategory = "Link";
		hootType = "HLBLOG";
	}

	let tagsText = [];
	if (
		postedHootObj.selectedCollections &&
		postedHootObj.selectedCollections.length > 0
	) {
		postedHootObj.selectedCollections.map((collection) => {
			tagsText.push(collection.filterText);
		});
	}
	return {
		// INFO: If you want to post to multiple boards, update this.
		boardsPostedToCount: 1,
		hootPrivate: postedHootObj.hootVisibility === "pri",
		isRichTextUsed: postedHootObj.isRichTextUsed,
		hootEventLocation: postedHootObj.hootLocation
			? postedHootObj.hootLocation.googlePlaceId
			: "",
		// hootPrivate: postedHootObj.userPermPub,
		hootColorId: postedHootObj.hootBgColorClass,
		hootTagsPostedTo: tagsText.length > 0 ? tagsText.join() : "",
		boardId: selectedBoardId,
		boardIdsPostedTo: selectedBoardId,
		boardName: selectedBoardName,
		boardNamesPostedTo: selectedBoardName,
		hootColorID: postedHootObj.selectedHootBgColor,
		hootColor: postedHootObj.selectedHootBgColor ? true : false,
		hootButton: postedHootObj.actionButtonLabel !== "" ? true : false,
		hootLocation: postedHootObj.hootLocation
			? postedHootObj.hootLocation.googlePlaceId
			: "",
		hootEvent: postedHootObj.eventDetails ? true : false,
		hootCategory: hootCategory,
		hootDescription: postedHootObj.hootDesc,
		hootCoverImage: (postedHootObj.hootCoverImages
			? postedHootObj.hootCoverImages
			: []
		).length
			? true
			: false,
		hootImageCount: (postedHootObj.hootCoverImages
			? postedHootObj.hootCoverImages
			: []
		).length,
		hootType: hootType,
		//hootID: 'string',
		hootTitle: postedHootObj.acquiredLinkData
			? postedHootObj.acquiredLinkData.titile
			: postedHootObj.hootTitle,
		hootUrl: createdHootUrl,
		path: window.location.href,

		postingCategory: postedHootObj.postingCategory,
		// TODO: Remove? Already included in all events
		// channel: getDevice()
	};
};

export const mixpanelCreateBoardObject = (
	boardObject,
	createdBoardUrl,
	boardDetails
) => {
	let boardId = 0;
	if (createdBoardUrl) {
		let str1 = createdBoardUrl.substring(createdBoardUrl.indexOf("b/") + 2);
		boardId = str1.substring(0, str1.indexOf("/"));
	}
	let boardLocations = [],
		locationSetting = false;
	if (
		boardObject &&
		boardObject.locations &&
		boardObject.locations.length > 0
	) {
		boardObject.locations.map((loc) => {
			boardLocations.push(loc.googlePlaceId);
		});
		locationSetting = true;
	}
	let mixpanelObj = {
		boardLogo: boardObject.boardLogo,
		boardLocations: boardLocations,
		boardSubCommunityType: "",
		boardCommunityType: boardObject.boardType,
		boardDescription: boardObject.boardDesc,
		boardCreatedAt: new Date(),
		boardName: boardObject.boardName,
		path: window.location.href,
		boardCreatedDate: new Date(),
		boardInviteType: "Member",
		boardId: parseInt(boardId),
		// boardSecurityType: securityType,
		// boardHootVisibility: $scope.hootVisib,
		// boardApprovePosts: $scope.preApproveHoots,
		// boardApproveComments: $scope.preApproveComnts,
		boardLocationSetting: locationSetting,
		boardInviteMessage: "",
		// boardPartnerInviteCount: partnerCount,
		collectionName: "",
		boardCollectionCount: 0,
		// boardInviteEmailCount: emailCount,
	};
	if (boardDetails != null) {
		mixpanelObj = {
			...mixpanelObj,
			parentBoardSubCommunityType: "",
			parentBoardCommunityType: boardDetails.boardType,
			parentBoardName: boardDetails.boardName,
			parentBoardID: boardDetails.boardId,
		};
	}
	return mixpanelObj;
};

export const mixpanelBoardAppObject = (data) => {
	return {
		boardAppsId: data.boardAppsId,
		appName: data.appName,
		seqNumber: data.seqNumber,
		showAllScreens: data.showAllScreens,
		boardId: data.boardId,
		platformAppId: data.platformAppId,
		appDeleted: data.appDeleted,
		appTypeCode: data.appTypeCode,
		appIconUrl: data.appIconUrl,
		appCategory: data.appCategory,
		pageNum: data.pageNum,
		appIconSize: data.appIconSize,
		showOnWeb: data.showOnWeb,
		showOnMobile: data.showOnMobile,
		boardAppsEndpoints: data.boardAppsEndpoints,
		appStoreId: data.appStoreId,
		publishedBy: data.publishedBy,
		appDescription: data.appDescription,
		settingsEndpoint: data.settingsEndpoint,
		hbConfig: data.hbConfig,
		developerApp: data.developerApp,
		private: data.private,
	};
};

export const getBoardIdAndNameFromList = (list) => {
	return (list.listBoards || []).map((board) => {
		return {
			boardId: board.id,
			boardName: board.name,
		};
	});
};

export const getListIdAndNameFromLists = (lists) => {
	return (lists || []).map((list) => {
		return {
			listName: list.name,
			listId: list.listId,
		};
	});
};
