import { httpGetV2, httpPostV2, httpPutV2, httpDeleteV2 } from "./http-wrapper";

/**
 * This API will trigger a notification email to the admins of the board which reminds them to
 * upgrade the board pricing plan. This API will be triggered by a non-admin user of the board.
 * @param {number} boardId
 */
export const notifyAdmin = async (boardId) => {
	const apiConfig = { "x-board-id": boardId };

	let response = await httpPostV2(
		"private/boards/notify/admin",
		null,
		apiConfig
	);
	return response;
};

/**
 * Method to call the API to create a Stripe subscription for the licenses purchased
 * NOTE: This method is the replacement of the new method created for the product pricing implementation
 * @param {Object} params
 * @param {number} params.boardId - ID of the board
 * @param {string} params.stripeToken - Token received from Stripe after authenticating the card. This will be blank if invoice based payment is selected
 * @param {boolean} params.isInvoice - Flag to determine invoice based payments (sending invoice to the customer instead of charging automatically)
 * @param {boolean} params.isTrial - Flag to determine if the subscription start as a trial or not.
 * @param {string} params.cartItems - Stringified JSON object of the purchased invoices
 * @param {string} params.planInterval - Yearly (YR) or monthly (MO)
 */
const createSubscription = async (params) => {
	const apiConfig = {
		"x-board-id": params.boardId,
	};

	let response = await httpPostV2(
		"private/subscription",
		{
			stripeToken: params.stripeToken,
			b2bCust: params.isInvoice,
			includeTrial: params.isTrial,
			licsPurchased: params.cartItems,
			billingInterval: params.planInterval,
		},
		apiConfig
	);
	return response;
};

/**
 * Fetch already added credit card by the board admin
 * @param {number} boardId = ID of the board
 */
const getCustomerCard = async (boardId) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let response = await httpGetV2("private/cards", null, apiConfig);
	return response;
};

/**
 * API to save the card
 * @param {number} boardId - ID of the board
 * @param {*} stripeToken - Token recieved from Stripe after validating the credit card
 */
const saveCustomerCard = async (boardId, stripeToken) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let response = await httpPutV2(
		`private/cards?stripeToken=${stripeToken}`,
		null,
		apiConfig
	);
	return response;
};

/**
 * Fetching the pricing details from Stripe API
 * @param {number} boardId - ID of the board
 */
const getProductPricing = async (boardId) => {
	const apiConfig = {
		"x-board-id": boardId,
	};
	let response = await httpGetV2("private/pricing", null, apiConfig);
	return response;
};

/**
 * API to fetch list of subscriptions
 * @param {number} boardId
 */
const getSubscriptions = async (boardId) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let response = await httpGetV2("private/subscriptions", null, apiConfig);
	return response;
};

/**
 * API to cancel a subscription
 * @param {number} boardId
 * @param {string} subscriptionId
 */
const cancelSubscription = async (boardId, subscriptionId) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let response = await httpDeleteV2(
		`private/subscription/${subscriptionId}`,
		null,
		apiConfig
	);
	return response;
};

/**
 * API to update the Stripe customer details
 * NOTE: Currently only the email is changed
 * @param {number} boardId
 * @param {string} adminEmail
 */
const updateCustomer = async (boardId, adminEmail) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let response = await httpPutV2(
		`private/customer?adminEmail=${adminEmail}`,
		null,
		apiConfig
	);
	return response;
};

/**
 * API for updating the subscription with change to the billing method
 * @param {*} boardId
 * @param {*} subscriptionId
 * @param {*} billingMethod
 */
const updateSubscription = async (boardId, subscriptionId, billingMethod) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let params = {
		subscription: subscriptionId,
		billingMethod: billingMethod,
	};

	let response = await httpPutV2("private/subscription", params, apiConfig);
	return response;
};

/**
 * API to fetch the invoices for specific subscription
 * @param {*} boardId
 * @param {*} subscriptionId
 */
const getInvoices = async (boardId, subscriptionId) => {
	const apiConfig = {
		"x-board-id": boardId,
	};

	let params = {
		subscription: subscriptionId,
	};

	let response = await httpGetV2("private/invoices", params, apiConfig);
	return response;
};

export {
	createSubscription,
	getCustomerCard,
	saveCustomerCard,
	getProductPricing,
	getSubscriptions,
	cancelSubscription,
	updateCustomer,
	updateSubscription,
	getInvoices,
};
