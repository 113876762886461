/*
 * @Author: snair@hootboard.com
 * @Date: 2020-05-25 13:12:17
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-06-09 01:36:21
 */

import { createSlice } from "@reduxjs/toolkit";
import validator from "email-validator";

const initialState = {
	isFetching: false,
	serverState: null,
	localState: {
		email: "",
		firstName: "",
		lastName: "",
		userPictureUrl: "",
	},
	newEmail: "",
	newEmailError: false,
	newEmailErrorMessage: "",
	currentPasswordTestStatus: "idle",
	currentPassword: "",
	currentPasswordError: false,
	currentPasswordErrorMessage: "",
	newPasswordTestStatus: "idle",
	updatePasswordStatus: "idle",
	newPassword: "",
	newPasswordRepeat: "",
	emailError: false,
	emailErrorMessage: "",
	passwordError: false,
	passwordErrorMessage: "",
	passwordRepeatError: false,
	passwordRepeatErrorMessage: "",
	firstNameError: false,
	firstNameErrorMessage: "",
	lastNameError: false,
	lastNameErrorMessage: "",
	userPictureUrlError: false,
	userPictureUrlErrorMessage: "",
	isEditing: false,
	currentViewIndex: 0,
	showAllFormErrors: false,
	updateEmailStatus: "idle",
	beaconChecked: true,
};

export function updateEmailAsync(payload) {
	return {
		type: "user/account/email/update/request",
		payload: payload,
	};
}

export function updatePasswordAsync(payload) {
	return {
		type: "user/account/password/update/request",
		payload: payload,
	};
}

export function testCurrentPasswordAsync(payload) {
	return {
		type: "user/account/password/test/request",
		payload: payload,
	};
}

export function updateAccountDetailsAsync(payload) {
	return {
		type: "user/account/update/request",
		payload: payload,
	};
}

export function getAccountDetailsAsync(payload) {
	return {
		type: "user/account/get/request",
		payload: payload,
	};
}

const myAccountSlice = createSlice({
	name: "myAccount",
	initialState: initialState,
	reducers: {
		setInitialState: (state, action) => {
			return {
				...state,
				serverState: action.payload.initialState,
				localState: action.payload.initialState,
			};
		},
		bypassPasswordConfirm: (state) => {
			state.currentViewIndex = 1;
		},
		newEmailEdited: (state, action) => {
			return {
				...state,
				newEmail: action.payload.newEmail,
				isEditing: true,
				newEmailError: !validator.validate(action.payload.newEmail),
				newEmailErrorMessage: "Please enter a valid email",
			};
		},
		emailEdited: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					email: action.payload.email,
				},
				isEditing: true,
				emailError: !validator.validate(action.payload.email),
				emailErrorMessage: "Please enter a valid email",
			};
		},
		currentPasswordEntered: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
				},
				isEditing: true,
				showAllFormErrors: false,
				currentPassword: action.payload.currentPassword,
				currentPasswordError: action.payload.currentPassword.trim()
					.length
					? false
					: true,
				passwordErrorMessage: "Need a valid password",
			};
		},
		newPasswordEntered: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
				},
				newPassword: action.payload.newPassword,
				isEditing: true,
				newPasswordError: action.payload.newPassword.trim().length
					? false
					: true,
				passwordRepeatErrorMessage: "Passwords do not match",
			};
		},
		newPasswordRepeatEntered: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
				},
				newPasswordRepeat: action.payload.newPasswordRepeat,
				isEditing: true,
				passwordRepeatError:
					state.localState.password === action.payload.passwordRepeat
						? false
						: true,
				passwordRepeatErrorMessage: "Passwords do not match",
			};
		},
		firstNameEdited: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					firstName: action.payload.firstName,
					displayName: `${action.payload.firstName} ${state.localState.lastName}`,
				},
				isEditing: true,
				firstNameError: action.payload.firstName.trim().length
					? false
					: true,
				firstNameErrorMessage: "Need a valid first name",
			};
		},
		lastNameEdited: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					lastName: action.payload.lastName,
					displayName: `${state.localState.firstName} ${action.payload.lastName}`,
				},
				isEditing: true,
				lastNameError: action.payload.lastName.trim().length
					? false
					: true,
				lastNameErrorMessage: "Need a valid first name",
			};
		},
		userPictureUrlEdited: (state, action) => {
			return {
				...state,
				localState: {
					...state.localState,
					userPictureUrl: action.payload.userPictureUrl,
				},
				isEditing: true,
				userPictureUrlError: action.payload.userPictureUrl
					? false
					: true,
			};
		},
		toggleFormErrors: (state) => {
			return {
				...state,
				showAllFormErrors: true,
			};
		},

		resetChangeEmailForm: (state) => {
			return {
				...state,
				newEmail: "",
				newEmailError: "",
				newEmailErrorMessage: "",
				updateEmailStatus: "idle",
				showAllFormErrors: false,
			};
		},
		resetPasswordForm: (state) => {
			return {
				...state,
				newPassword: "",
				currentPassword: "",
				newPasswordRepeat: "",
				passwordRepeatError: false,
				passwordRepeatErrorMessage: "",
				currentPasswordTestStatus: "idle",
				updatePasswordStatus: "idle",
				currentViewIndex: 0,
			};
		},
		toggleBeaconMode: (state, action) => {
			localStorage.setItem(
				"productTourBeaconMode",
				Boolean(action.payload.checked)
			);

			state.beaconChecked = Boolean(action.payload.checked);
		},

		setBeaconMode: (state, action) => {
			state.beaconChecked = action.payload;
		},
	},
	extraReducers: {
		"user/account/get/pending": (state) => {
			state.isFetching = true;
		},
		"user/account/get/fulfilled": (state, action) => {
			state.isFetching = false;
			state.localState = action.payload.accountDetails;
			state.serverState = action.payload.accountDetails;
		},
		"user/account/update/pending": (state) => {
			return {
				...state,
				updateAccountStatus: "pending",
			};
		},
		"user/account/update/fulfilled": (state, action) => {
			return {
				...state,
				updateAccountStatus: "fulfilled",
				localState: action.payload.accountDetails,
				serverState: action.payload.accountDetails,
			};
		},
		"user/account/update/rejected": (state) => {
			return {
				...state,
				updateAccountStatus: "rejected",
			};
		},
		"user/account/password/test/pending": (state) => {
			return {
				...state,
				currentPasswordTestStatus: "pending",
			};
		},
		"user/account/password/test/rejected": (state) => {
			return {
				...state,
				currentPasswordTestStatus: "rejected",
				currentPasswordError: true,
				showAllFormErrors: true,
				currentPasswordErrorMessage: "Password your entered is wrong",
			};
		},
		"user/account/password/test/fulfilled": (state) => {
			return {
				...state,
				currentPasswordTestStatus: "fulfilled",
				currentViewIndex: 1,
			};
		},
		"user/account/password/update/pending": (state) => {
			return {
				...state,
				updatePasswordStatus: "pending",
			};
		},
		"user/account/password/update/rejected": (state) => {
			return {
				...state,
				updatePasswordStatus: "rejected",
			};
		},
		"user/account/password/update/fulfilled": (state) => {
			return {
				...state,
				updatePasswordStatus: "fulfilled",
				currentViewIndex: 2,
			};
		},
		"user/account/email/update/pending": (state) => {
			return {
				...state,
				updateEmailStatus: "pending",
			};
		},
		"user/account/email/update/rejected": (state, action) => {
			return {
				...state,
				updateEmailStatus: "rejected",
				newEmailError: true,
				newEmailErrorMessage: !action.payload.isUniqueEmail
					? "That email is already taken"
					: "Please try again",
				showAllFormErrors: true,
			};
		},
		"user/account/email/update/fulfilled": (state, action) => {
			return {
				...state,
				updateEmailStatus: "fulfilled",
				localState: action.payload.accountDetails,
				serverState: action.payload.accountDetails,
			};
		},
	},
});
export const {
	showAllFormErrors,
	emailEdited,
	currentPasswordEntered,
	newPasswordEntered,
	newPasswordRepeatEntered,
	userPictureUrlEdited,
	firstNameEdited,
	lastNameEdited,
	setInitialState,
	resetPasswordForm,
	toggleFormErrors,
	newEmailEdited,
	resetChangeEmailForm,
	toggleBeaconMode,
	setBeaconMode,
	bypassPasswordConfirm,
} = myAccountSlice.actions;
export default myAccountSlice.reducer;
