/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
// Common constants. Don't comment out this part.
export const IMAGE_SIZES = { maxWidth: 4000, maxHeight: 3000 };
export const MAX_UPLOAD_HOOT_IMAGE_SIZE_LIMIT = 8 * 1024 * 1024;

export const DEFAULT_COLOR_PALETTE = {
    background: "#e6e6e6",
    button: "#80b948",
    font: "#5073a9",
    colorPalette: "def",
};
export const ACTION_STATES = {
    IDLE: "idle",
    REQUEST: "request",
    PENDING: "pending",
    REJECTED: "rejected",
    FULFILLED: "fulfilled",
};
export const ENGAGEMENT_CHANNELS = {
    webMobile: ["Web Desktop", "Android Mobile App", "IOS Mobile App", "Web Mobile"],
    touchKiosk: ["HootBoard Touch Kiosk"],
    others: ["API"],
};

export const RANDOM_TIMELINE_QUESTIONS_AFTER_EVENT = [
    "Is this done?",
    "Is it over?",
    "Done with this?",
    "Did this end?",
    "Did you join?",
];

export const ALL_ANALYTICS_CHANNELS = [
    "Web Mobile",
    "Web Desktop",
    "Android Mobile App",
    "IOS Mobile App",
    "Kiosk",
    "API",
].join();

export const ACCEPTED_IMAGE_TYPES = [".jpg", ".jpeg", ".png", ".gif"];
export const SCRAPPER_URL = "https://scraper.hootboard.com";
export const FILESTACK_KEY = "AEDuIZLBRR4mpta8P7ZHjz";
export const MIXPANEL_API_ENDPOINT = "https://mixpanel.com/api/2.0";
export const MIXPANEL_SEGMENTATION_ENDPOINT = "https://mixpanel.com/api/2.0/segmentation";
export const GOOGLE_CLIENT_ID =
    "1034605854789-d8v8rvhb44be85haqoj19n7cu5r82iff.apps.googleusercontent.com";
// When the notification endpoint changes, update this version.
export const NOTIFICATION_VERSION = "1.0.0";
// INFO: This needs to be updated before going to release.
export const WEB_VERSION = "10.10.8";
// shorten Url & key
export const URL_SHORTENER_URL = "https://tr.hootboard.com";
export const URL_SHORTENER_API_KEY = "key_790a6210-9a6c-43aa-a456-040da7af18cf";
export const SHOPIFY_API_KEY = "gck_8c1560bd-9390-46d8-b687-e0d9d2d0f163";
export const DOODLE_IMG_URL = "https://d2oqhl3js3c5ij.cloudfront.net/255063/hoots/htmlhoots/hootfiles/images/52188I-akqdil.png"

// env
export const SERVER_ENV: any = process.env.REACT_APP_SERVER_ENV;
export const ONESIGNAL_APP_ID: any = process.env.REACT_APP_ONESIGNAL_APP_ID;
export const GTAG: any = process.env.REACT_APP_GTAG;
export const GOOGLE_ANALYTICS_ID_LEGACY: any = process.env.REACT_APP_GOOGLE_ANALYTICS_ID_LEGACY;
export const ADV_DSP_URL: any = process.env.REACT_APP_ADV_DSP_URL;
export const ADV_API: any = process.env.REACT_APP_ADV_API;
export const API_URL: any = process.env.REACT_APP_API_URL;
export const API_URL_LATEST: any = process.env.REACT_APP_API_URL_LATEST;
export const API_KEY: any = process.env.REACT_APP_API_KEY;
export const API_ENDPOINT: any = process.env.REACT_APP_API_ENDPOINT;
export const DOMAIN: any = process.env.REACT_APP_DOMAIN;
export const CITYGUIDE_DOMAIN:any = process.env.REACT_APP_CITYGUIDE_DOMAIN;
export const S3_IMAGE_DOMAIN: any = process.env.REACT_APP_S3_IMAGE_DOMAIN;
export const SEND_MESSAGE_ENDPOINT: any = process.env.REACT_APP_SEND_MESSAGE_ENDPOINT;
export const NOTIFICATIONS_ENDPOINT: any = process.env.REACT_APP_NOTIFICATIONS_ENDPOINT;
export const NOTIFICATIONS_WEBSOCKET_ENDPOINT = process.env.REACT_APP_NOTIFICATIONS_WEBSOCKET_ENDPOINT;
export const MIXPANEL_BASIC_AUTHORIZATION: any = process.env.REACT_APP_MIXPANEL_BASIC_AUTHORIZATION;
export const ANALYTICS_EU_USAGE_GROUP = `$${process.env.REACT_APP_ANALYTICS_EU_USAGE_GROUP}`;
export const HOOTBOARD_ANALYTICS_ENDPOINT: any = process.env.REACT_APP_HOOTBOARD_ANALYTICS_ENDPOINT;
export const MIXPANEL_PROJECT_ID: any = process.env.REACT_APP_MIXPANEL_PROJECT_ID;
export const ROOT_URL: any = process.env.REACT_APP_ROOT_URL;
export const RSS_FEED_READER_URL: any = process.env.REACT_APP_RSS_FEED_READER_URL;
export const MIXPANEL_KEY: any = process.env.REACT_APP_MIXPANEL_KEY;
export const FACEBOOK_APPID: any = process.env.REACT_APP_FACEBOOK_APPID;
export const EMBED_URL: any = process.env.REACT_APP_EMBED_URL;
export const SHARE_ITEM_URL: any = process.env.REACT_APP_SHARE_ITEM_URL;
export const SHARE_VIA_EMAIL_ENDPOINT: any = process.env.REACT_APP_SHARE_VIA_EMAIL_ENDPOINT;
export const SUBSCRIBE_API_ENDPOINT: any = process.env.REACT_APP_SUBSCRIBE_API_ENDPOINT;
export const API_ACCESS_KEY: any = process.env.REACT_APP_API_ACCESS_KEY;
export const BEAMER_API_KEY: any = process.env.REACT_APP_BEAMER_API_KEY;
export const STRIPE_KEY: any = process.env.REACT_APP_STRIPE_KEY;
export const GOOGLE_API: any = process.env.REACT_APP_GOOGLE_API;
export const ANALYTICS_SDK_KEY: any = process.env.REACT_APP_ANALYTICS_SDK_KEY;
export const ANALYTICS_API_URL: any = process.env.REACT_APP_ANALYTICS_API_URL;
export const CLARITY_ID: any = process.env.REACT_APP_CLARITY_ID;
export const ZENDESK_ID: any = process.env.REACT_APP_ZENDESK_ID;

interface MapBoxProps {
    longitude?: number;
    latitude?: number;
    width?: number;
    height?: number;
    zoomLevel?: number;
}

/**
 * This is static Map Box view.
 * Use this url for documentation https://docs.mapbox.com/api/maps/static-images/
 * @returns Image of the map based on the latitude & longitude
 */

export const MAP_BOX = ({
    longitude,
    latitude,
    width = 400,
    height = 400,
    zoomLevel = 16, // value between 0 - 22
}: MapBoxProps) => {
    return `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-l-embassy+f74e4e(${longitude},${latitude})/${longitude},${latitude},${zoomLevel}/${width}x${height}/?access_token=pk.eyJ1Ijoic25haXJoYiIsImEiOiJja2RraGFlMmIwb2prMnBvaHJvY2xsMmkzIn0.zpA777bWdLuQ-ojtL4_3ww`;
};