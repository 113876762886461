/*
 * @Author: Chandu J S
 * @Date:   2020-08-07 12:21:25
 * @Last Modified by:   Chandu J S
 * @Last Modified time: 2020-08-07 14:51:40
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isDone: false,
	isLoading: false,
	isServerError: false,
	isPasswordMismatchError: false,
	isNotStrongPasswordError: false,
	localState: {
		password: null,
		passwordRepeat: null,
	},
};

const slice = createSlice({
	name: "resetPassword",
	initialState,
	reducers: {
		setPassword: (state, action) => {
			const password = action.payload;
			state.localState.password = password;

			const passwordRepeat = state.localState.passwordRepeat;
			state.isPasswordMismatchError =
				!passwordRepeat && password !== passwordRepeat;

			// // The string must be eight characters or longer
			// // The string must contain at least 1 numeric character
			// // The string must contain at least 1 lowercase alphabetical character
			// // The string must contain at least 1 uppercase alphabetical character
			// // The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
			// const strongRegex = new RegExp(
			// 	'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
			// )

			// state.isNotStrongPasswordError = !strongRegex.test(password)
			state.isNotStrongPasswordError = false;
		},
		setPasswordRepeat: (state, action) => {
			const passwordRepeat = action.payload;
			const password = state.localState.password;

			state.isPasswordMismatchError =
				password != null && passwordRepeat !== password;

			state.localState.passwordRepeat = passwordRepeat;
		},
		backToView: (state) => {
			state.isServerError = false;
		},
	},
	extraReducers: {
		"password-reset/pending": (state) => {
			return {
				...initialState,
				isLoading: true,
				localState: state.localState,
			};
		},
		"password-reset/fulfilled": (state) => {
			return {
				...initialState,
				isDone: true,
				localState: state.localState,
			};
		},
		"password-reset/rejected": (state) => {
			return {
				...initialState,
				isServerError: true,
				localState: state.localState,
			};
		},
	},
});

export const resetPasswordAsyncAction = ({ encPartyId, password }) => {
	return {
		type: "password-reset/request",
		payload: { encPartyId, password },
	};
};

export const { setPassword, setPasswordRepeat, backToView } = slice.actions;

export default slice.reducer;
