import { createSlice } from "@reduxjs/toolkit";
import validator from "valid-url";

import { Actions } from "../ManageScreensNew/manageScreens.saga";

import { emojiFilter } from "../../helper/filters.helper";

export const updateScreenAsync = (payload) => {
    const idleStates = {};
    for (const idleState of payload.params.idleStates) {
        idleStates[idleState.keyName] = idleState.isActive;
        idleStates[idleState.configKeyName] = JSON.stringify(idleState.config);
    }
    return {
        type: "screen/update/request",
        payload: { ...payload, params: { ...payload.params, idleStates } },
    };
};

export const deleteScreenAsync = (payload) => {
    return {
        type: "screen/delete/request",
        payload,
    };
};

/**
 * @param {Object} payload
 * @param {string} payload.boardId
 * @param {string} payload.screenId
 */
export const getScreenForEdit = (payload) => {
    return {
        type: Actions.getScreen + "request",
        payload,
    };
};

const initialState = {
    steps: ["Info", "Content", "Location"], //TODO: Add 'Attractor Loops' once enabled again
    currentStep: 0,
    localState: {
        screenName: "",
        screenType: "INT",
        screenLayout: "POR",
        showPrivatePosts: false,
        showBoardCommunityCode: false,
        collections: "",
        showPosterName: false,
        showInfoPage: false,
        mobileJoin: false,
        showComments: false,
        screensDefaultView: "AV",
        screenLocation: {
            formattedAddress: "",
        },
        locationText: "",
        locationAppUrl: "",
        screenJoiningAccess: "FL",
        idleStates: [
            {
                name: "Concierge Home Page",
                keyName: "conciergeHomePage",
                configKeyName: "conciergeConfig",
                config: {
                    title: "",
                },
            },
            {
                name: "Digital Signage",
                keyName: "digitalSignage",
                configKeyName: "digitalSignageConfig",
            },
            {
                name: "App Switcher",
                keyName: "appSwitcher",
                configKeyName: "appSwitcherConfig",
            },
            {
                name: "Hoot Switcher",
                keyName: "hootSwitcher",
                configKeyName: "hootSwitcherConfig",
            },
            {
                name: "Collection Switcher",
                keyName: "collectionSwitcher",
                configKeyName: "collectionSwitcherConfig",
                config: {
                    collections: "",
                    behaviour: "union",
                },
            },
            {
                name: "Tap Anywhere Animation",
                keyName: "tapAnywhereAnimation",
                configKeyName: "tapAnywhereAnimationConfig",
            },
            {
                name: "Upcoming Events Switcher",
                keyName: "upcomingEventSwitcher",
                configKeyName: "upcomingEventSwitcherConfig",
            },
            {
                name: "Fullscreen Image Switcher",
                keyName: "fullScreenImageSwitcher",
                configKeyName: "fullScreenImageSwitcherConfig",
                config: {
                    collections: "",
                    behaviour: "union",
                },
            },
        ],
    },
    serverState: null,
    isEditing: false,
    loadScreenStatus: "idle",
    deleteScreenStatus: "idle",
    updateScreenStatus: "idle",
    showAllFormErrors: false,
    screenNameError: false,
    screenNameErrorMessage: "Enter a name or nick-name for your screen",
    screenLocationError: false,
    screenLocationErrorMessage: "Pleae enter the location of the screen",
    locationTextError: false,
    locationTextErrorMessage: "Pleae enter valid text",
    locationAppUrlError: false,
    locationAppUrlErrorMessage: "Please enter a valid URL",
};

const updateScreenSlice = createSlice({
    name: "updateScreen",
    initialState: initialState,
    reducers: {
        updateIdleState: (state, action) => {
            const { index, data } = action.payload;
            state.localState.idleStates[index] = data;
        },
        assignedContentChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    ...action.payload,
                },
            };
        },
        screenNameChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    screenName: emojiFilter(action.payload.screenName),
                },
                screenNameError:
                    action.payload.screenName &&
                    action.payload.screenName.trim() &&
                    action.payload.screenName.trim().length
                        ? false
                        : true,
            };
        },
        screenLayoutChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    screenLayout: action.payload.screenLayout,
                },
            };
        },
        toggleShowPrivatePosts: (state) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    showPrivatePosts: !state.localState.showPrivatePosts,
                },
            };
        },
        toggleShowBoardCommunityCode: (state) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    showBoardCommunityCode: !state.localState.showBoardCommunityCode,
                },
            };
        },
        toggleShowComments: (state) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    showComments: !state.localState.showComments,
                },
            };
        },
        toggleShowPosterName: (state) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    showPosterName: !state.localState.showPosterName,
                },
            };
        },
        toggleShowInfoPage: (state) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    showInfoPage: !state.localState.showInfoPage,
                },
            };
        },
        toggleMobileJoin: (state) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    mobileJoin: !state.localState.mobileJoin,
                },
            };
        },
        screenLocationChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    screenLocation: action.payload.screenLocation,
                },
                screenLocationError:
                    action.payload.screenLocation && action.payload.screenLocation.formattedAddress
                        ? false
                        : true,
            };
        },
        locationAppUrlChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    locationAppUrl: action.payload.locationAppUrl,
                },
                locationAppUrlError:
                    action.payload.locationAppUrl &&
                    action.payload.locationAppUrl.trim() &&
                    action.payload.locationAppUrl.trim().length
                        ? !validator.isWebUri(action.payload.locationAppUrl)
                        : true,
            };
        },
        locationTextChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    locationText: action.payload.locationText,
                },
                locationTextError:
                    action.payload.locationText &&
                    action.payload.locationText.trim() &&
                    action.payload.locationText.trim().length
                        ? false
                        : true,
            };
        },
        screenJoiningAccessChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    screenJoiningAccess: action.payload.screenJoiningAccess,
                },
            };
        },
        screensDefaultViewChanged: (state, action) => {
            return {
                ...state,
                localState: {
                    ...state.localState,
                    screensDefaultView: action.payload.screensDefaultView,
                },
            };
        },
        nextStep: (state) => {
            if (state.currentStep >= state.steps.length - 1) return;
            return {
                ...state,
                currentStep: state.currentStep + 1,
            };
        },
        prevStep: (state) => {
            if (state.currentStep <= 0) return;
            return {
                ...state,
                currentStep: state.currentStep - 1,
            };
        },
        jumpToStep: (state, action) => {
            return {
                ...state,
                currentStep: action.payload.targetStep,
            };
        },
        resetUpdateScreen: () => {
            return initialState;
        },
        showFormErrors: (state) => {
            return {
                ...state,
                showAllFormErrors: true,
            };
        },
    },
    extraReducers: {
        "screen/update/pending": (state) => {
            state.updateScreenStatus = "pending";
        },
        "screen/update/fulfilled": (state) => {
            state.updateScreenStatus = "fulfilled";
            state.serverState = state.localState;
        },
        "screen/update/rejected": (state) => {
            state.updateScreenStatus = "rejected";
        },
        "screen/delete/pending": (state) => {
            state.deletedScreenData = {
                screenId: null,
            };
        },
        "screen/delete/fulfilled": (state, action) => {
            return {
                ...state,
                deleteScreenStatus: "fulfilled",
                deletedScreenData: {
                    screenId: action.payload.screenId,
                    screenName: action.payload.screenName,
                },
            };
        },
        [Actions.getScreen + "pending"]: (state) => {
            state.loadScreenStatus = "pending";
        },
        [Actions.getScreen + "rejected"]: (state) => {
            state.loadScreenStatus = "rejected";
        },
        [Actions.getScreen + "fulfilled"]: (state, action) => {
            const payloadState = action.payload;

            let localState = { ...payloadState };

            const payloadIdleStates = payloadState.idleStates;
            let idleStates = initialState.localState.idleStates;

            if (payloadIdleStates) {
                idleStates = idleStates.map((item) => {
                    const config = {
                        ...(item.config || {}),
                        ...JSON.parse(payloadIdleStates[item.configKeyName]),
                    };
                    return {
                        ...item,
                        config,
                        isActive: payloadIdleStates[item.keyName],
                        isConfigurable: Object.entries(config).length > 1 ? true : false,
                        isMoreConfigOpen: false,
                    };
                });
            } else {
                idleStates = idleStates.map((item) => {
                    return {
                        ...item,
                        config: { ...(item.config || {}), duration: 20 },
                        isActive: false,
                        isConfigurable: !!item.config,
                        isMoreConfigOpen: false,
                    };
                });
            }

            localState = {
                ...localState,
                idleStates,
            };

            return {
                ...state,
                localState,
                loadScreenStatus: "fulfilled",
                serverState: localState,
            };
        },
    },
});
export const {
    screenNameChanged,
    screenJoiningAccessChanged,
    screenLayoutChanged,
    toggleShowPrivatePosts,
    toggleShowBoardCommunityCode,
    toggleShowComments,
    toggleShowPosterName,
    toggleShowInfoPage,
    toggleMobileJoin,
    locationAppUrlChanged,
    locationTextChanged,
    screenLocationChanged,
    screensDefaultViewChanged,
    setInitialState,
    showFormErrors,
    nextStep,
    prevStep,
    jumpToStep,
    resetUpdateScreen,
    updateIdleState,
    assignedContentChanged,
} = updateScreenSlice.actions;
export default updateScreenSlice.reducer;
