import { createSlice } from "@reduxjs/toolkit";
import {
	boardNameFilter,
	specialCharFilter,
} from "../../helper/filters.helper";
import { InputValidators } from "../../utils/inputValidators.util";
import { Actions } from "./manageMyBusiness.saga";
import { Actions as CreateBoardActions } from "../CreateBoard/createBoard.saga";

const initialState = {
	categoriesFetchStatus: "idle",
	categories: [],
	profile: {
		apiStatus: "idle",
		data: {
			boardName: "",
			businessCategories: [],
			boardDesc: "",
			boardLogo: "",
			phoneNumber: "",
			website: "",
		},
		serverData: {}, // using this for undo changes feature.
		errors: {
			boardNameError: false,
			boardNameErrorMessage: "",
			//
			boardDescError: false,
			boardDescErrorMessage: "",
			//
			phoneNumberError: false,
			phoneNumberErrorMessage: "",
			//
			websiteError: false,
			websiteErrorMessage: "",
			//
			boardLogoError: false,
			boardLogoErrorMessage: "",
			//
			businessCategoriesError: false,
			businessCategoriesErrorMessage: "",
		},
		areErrorsVisible: false,
	},
	customize: {
		apiStatus: "idle",
		data: {
			boardBackgroundImageLink: "",
			boardThemeCustomColors: {},
			customHootThemeColors: [],
			boardLogo: "",
		},
		serverData: {},
		areErrorsVisible: false,
		errors: {},
	},
	location: {
		apiStatus: "idle",
		data: null,
		serverData: null,
		areErrorsVisible: false,
		errors: {},
	},
	hours: {
		apiStatus: "idle",
		data: [],
		serverData: [],
		errors: {},
	},
	url: {
		apiStatus: "idle", // url save status
		data: "", // customUrl
		errors: {
			urlNotAvailableError: false,
			urlNotAvailableErrorMessage: "URL already in use!",
			//
			saveError: false,
			saveErrorMessage: "Yikes! Something wrong happened.",
		},
	},
	photos: {
		apiStatus: "idle",
		data: [],
		serverData: [],
		errors: {},
	},
};

/**
 * A helper function to update the slice's state variables from the `boardDetails` object.
 */
const getDataFromBoardDetails = (state, action) => {
	const { boardDetails } = action.payload;

	// saving for PROFILE section
	const profileData = {
		boardName: boardDetails.boardName || "",
		boardDesc: boardDetails.boardDesc || "",
		businessCategories: boardDetails.businessCategories || [],
		boardLogo: boardDetails.boardLogo || "",
		phoneNumber: boardDetails.businessDetails.phoneNumber || "",
		website: boardDetails.businessDetails.website || "",
	};
	state.profile.data = profileData;
	state.profile.serverData = profileData;

	// saving for CUSTOMIZE section
	const customizeData = {
		boardLogo: boardDetails.boardLogo || "",
		boardBackgroundImageLink: boardDetails.boardBackgroundImageLink || "",
		boardThemeCustomColors: boardDetails.boardThemeCustomColors || {},
		customHootThemeColors: boardDetails.customHootThemeColors,
	};
	state.customize.data = customizeData;
	state.customize.serverData = customizeData;

	// saving for SHORT URL section
	state.url.data = boardDetails.customUrl;

	// saving for PHOTOS section
	state.photos.serverData = boardDetails.businessPhotos.small;

	// saving for LOCATION section
	// FIXME: Some weird issue!
	// Somehow, i can't use const for `locationData` variable and update
	// only the `addressLine1` & `addressLine2` fields.
	let locationData =
		(boardDetails.locations || []).length > 0
			? boardDetails.locations[0]
			: null;
	if (locationData) {
		if (!locationData.addressLine1) {
			locationData = {
				...locationData,
				addressLine1: "",
			};
		}
		if (!locationData.addressLine2) {
			locationData = {
				...locationData,
				addressLine2: "",
			};
		}
	}
	state.location.data = locationData;
	state.location.serverData = locationData;

	// saving for BUSINESS HOURS section
	state.hours.data = boardDetails.businessHours;
	state.hours.serverData = boardDetails.businessHours;
};

const slice = createSlice({
	name: "manageMyBusiness",
	initialState: initialState,
	reducers: {
		resetSettings: () => initialState,
		undoSettings: (state, action) => {
			const contextName = action.payload;
			state[contextName].data = state[contextName].serverData;
		},
		/**
		 * Show validation errors for the given context.
		 */
		showValidationErrors: (state, action) => {
			const contextName = action.payload;
			state[contextName].areErrorsVisible = true;
		},
		//
		profileFormUpdated: (state, action) => {
			const {
				businessCategories,
				boardDesc,
				boardLogo,
				phoneNumber,
				website,
			} = action.payload;

			// strip out special chars from the name. or it will make a conflict in database.
			const boardName = boardNameFilter(action.payload.boardName);

			const boardNameValidation = InputValidators.boardName(boardName);
			state.profile.data.boardName = boardNameValidation.value;
			state.profile.errors.boardNameError = boardNameValidation.hasError;
			state.profile.errors.boardNameErrorMessage =
				boardNameValidation.errorMessage;

			const boardDescValidation =
				InputValidators.boardDescription(boardDesc);
			state.profile.data.boardDesc = boardDescValidation.value;
			state.profile.errors.boardDescError = boardDescValidation.hasError;
			state.profile.errors.boardDescErrorMessage =
				boardDescValidation.errorMessage;

			const phoneNumberValidation =
				InputValidators.phoneNumber(phoneNumber);
			state.profile.data.phoneNumber = phoneNumberValidation.value;
			state.profile.errors.phoneNumberError =
				phoneNumberValidation.hasError;
			state.profile.errors.phoneNumberErrorMessage =
				phoneNumberValidation.errorMessage;

			const websiteValidation = InputValidators.website(website);
			state.profile.data.website = websiteValidation.value;
			state.profile.errors.websiteError = websiteValidation.hasError;
			state.profile.errors.websiteErrorMessage =
				websiteValidation.errorMessage;

			const boardLogoValidation = InputValidators.boardLogo(boardLogo);
			state.profile.data.boardLogo = boardLogoValidation.value;
			state.profile.errors.boardLogoError = boardLogoValidation.hasError;
			state.profile.errors.boardLogoErrorMessage =
				boardLogoValidation.errorMessage;

			const businessCategoriesValidation =
				InputValidators.businessCategories(businessCategories);
			state.profile.data.businessCategories =
				businessCategoriesValidation.value;
			state.profile.errors.businessCategoriesError =
				businessCategoriesValidation.hasError;
			state.profile.errors.businessCategoriesErrorMessage =
				businessCategoriesValidation.errorMessage;
		},
		//
		locationFormUpdated: (state, action) => {
			const address = action.payload;
			state.location.data = address;

			const locationValidation = InputValidators.location(address);

			if (locationValidation.hasError) {
				state.location.errors.locationsError = true;
				state.location.errors.locationsErrorMessage =
					locationValidation.errorMessage;
			} else {
				// strip out special chars from the name. or it will make a conflict in database.
				const addressLine1 = specialCharFilter(address.addressLine1);

				const addressLine1Validation =
					InputValidators.addressLine(addressLine1);

				state.location.data.addressLine1 = addressLine1Validation.value;
				state.location.errors.locationsError =
					addressLine1Validation.hasError;
				state.location.errors.locationsErrorMessage =
					addressLine1Validation.errorMessage;
			}
		},
		//
		customizeFormUpdated: (state, action) => {
			const {
				boardBackgroundImageLink,
				boardThemeCustomColors,
				customHootThemeColors,
			} = action.payload;

			state.customize.data.boardBackgroundImageLink =
				boardBackgroundImageLink;
			state.customize.data.boardThemeCustomColors =
				boardThemeCustomColors;
			state.customize.data.customHootThemeColors = customHootThemeColors;
		},
		//
		hoursFormUpdated: (state, action) => {
			state.hours.data = action.payload;
		},
		//
		addPhotos: (state, action) => {
			state.photos.data = [...action.payload, ...state.photos.data];
		},
		removePhoto: (state, action) => {
			const photoIndex = action.payload;
			state.photos.data = state.photos.data.filter(
				(_item, index) => photoIndex !== index
			);
		},
	},
	extraReducers: {
		// saving all settings from board info response
		"/manageBoard/refreshBoardDetails/fulfilled": getDataFromBoardDetails,
		"board/details/fulfilled": getDataFromBoardDetails,
		//
		[CreateBoardActions.importBusiness + "fulfilled"]: (state, action) => {
			const categories =
				action.payload?.boardDetails?.businessCategories || [];
			const existingCategories = state.categories.map(
				({ categoryId }) => categoryId
			);
			for (const category of categories) {
				if (!existingCategories.includes(category.categoryId)) {
					state.categories = [...state.categories, category];
				}
			}
		},
		//
		[Actions.getCategories + "pending"]: (state) => {
			state.categoriesFetchStatus = "pending";
		},
		[Actions.getCategories + "fulfilled"]: (state, action) => {
			state.categoriesFetchStatus = "fulfilled";
			state.categories = action.payload;
		},
		[Actions.getCategories + "rejected"]: (state) => {
			state.categoriesFetchStatus = "rejected";
		},
		//
		[Actions.saveProfile + "pending"]: (state) => {
			state.profile.apiStatus = "pending";
		},
		[Actions.saveProfile + "fulfilled"]: (state) => {
			state.profile.apiStatus = "fulfilled";
			state.profile.serverData = state.profile.data;
		},
		[Actions.saveProfile + "rejected"]: (state) => {
			state.profile.apiStatus = "rejected";
		},
		//
		[Actions.saveCustomize + "pending"]: (state) => {
			state.customize.apiStatus = "pending";
		},
		[Actions.saveCustomize + "fulfilled"]: (state) => {
			state.customize.apiStatus = "fulfilled";
			state.customize.serverData = state.customize.data;
		},
		[Actions.saveCustomize + "rejected"]: (state) => {
			state.customize.apiStatus = "rejected";
		},
		//
		[Actions.saveShortURL + "pending"]: (state) => {
			state.url.apiStatus = "pending";
			state.url.errors.urlNotAvailableError = false;
			state.url.errors.saveError = false;
		},
		[Actions.saveShortURL + "fulfilled"]: (state) => {
			state.url.apiStatus = "fulfilled";
		},
		[Actions.saveShortURL + "rejected"]: (state, action) => {
			const { reason } = action.payload;
			state.url.apiStatus = "rejected";
			if (reason && reason === "not-available") {
				state.url.errors.urlNotAvailableError = true;
			} else {
				state.url.errors.saveError = true;
			}
		},
		//
		[Actions.saveLocation + "pending"]: (state) => {
			state.location.apiStatus = "pending";
		},
		[Actions.saveLocation + "fulfilled"]: (state) => {
			state.location.apiStatus = "fulfilled";
			state.location.serverData = state.location.data;
		},
		[Actions.saveLocation + "rejected"]: (state) => {
			state.location.apiStatus = "rejected";
		},
		//
		[Actions.saveBusinessHours + "pending"]: (state) => {
			state.hours.apiStatus = "pending";
		},
		[Actions.saveBusinessHours + "fulfilled"]: (state) => {
			state.hours.apiStatus = "fulfilled";
			state.hours.serverData = state.hours.data;
		},
		[Actions.saveBusinessHours + "rejected"]: (state) => {
			state.hours.apiStatus = "rejected";
		},
		//
		[Actions.deletePhoto + "pending"]: (state, action) => {
			const { data } = action.payload;
			// once the photo delete action is dispatched, we are expecting the API will do its job.
			// instead of updating the state after the API call, we are updating the state first.
			// otherwise the user will see a slight delay in the UI.
			state.photos.serverData = state.photos.serverData.filter(
				(item) => item.id !== data
			);
			state.photos.apiStatus = "pending";
		},
		[Actions.deletePhoto + "fulfilled"]: (state) => {
			state.photos.apiStatus = "fulfilled";
		},
		[Actions.deletePhoto + "rejected"]: (state) => {
			state.photos.apiStatus = "rejected";
		},
		//
		[Actions.savePhotos + "pending"]: (state) => {
			state.photos.apiStatus = "pending";
		},
		[Actions.savePhotos + "fulfilled"]: (state, action) => {
			state.photos.apiStatus = "fulfilled";
			state.photos.data = [];
			state.photos.serverData = [
				...action.payload,
				...state.photos.serverData,
			];
		},
		[Actions.savePhotos + "rejected"]: (state) => {
			state.photos.apiStatus = "rejected";
		},
	},
});

export const saveProfile = ({ boardId, data }) => {
	return {
		type: Actions.saveProfile + "request",
		payload: { boardId, data },
	};
};

export const saveCustomize = ({ boardId, data }) => {
	return {
		type: Actions.saveCustomize + "request",
		payload: { boardId, data },
	};
};

export const saveShortURL = ({ boardId, data }) => {
	return {
		type: Actions.saveShortURL + "request",
		payload: { boardId, data },
	};
};

export const saveLocation = ({ boardId, data }) => {
	return {
		type: Actions.saveLocation + "request",
		payload: { boardId, data },
	};
};

export const getCategories = () => {
	return {
		type: Actions.getCategories + "request",
		payload: {},
	};
};

export const saveContactDetails = ({ boardId, data }) => {
	return {
		type: Actions.saveContactDetails + "request",
		payload: { boardId, data },
	};
};

export const saveBusinessHours = ({ boardId, data }) => {
	return {
		type: Actions.saveBusinessHours + "request",
		payload: { boardId, data },
	};
};

export const savePhotos = ({ boardId, data }) => {
	return {
		type: Actions.savePhotos + "request",
		payload: { boardId, data },
	};
};

export const deletePhoto = ({ boardId, data }) => {
	return {
		type: Actions.deletePhoto + "request",
		payload: { boardId, data },
	};
};

export const {
	undoSettings,
	resetSettings,
	showValidationErrors,
	//
	profileFormUpdated,
	locationFormUpdated,
	customizeFormUpdated,
	hoursFormUpdated,
	removePhoto,
	addPhotos,
	//
} = slice.actions;
export default slice.reducer;
