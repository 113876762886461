import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
//
import { ActionState, AsyncState } from "../types";
import { ShareableItem, ShareViaEmailPayload } from "../types/sharing";
//
import { Actions } from "./sagas/sharing.saga";

/**
 * Get the short code url for the provide lists url
 */
export const getUrlShortCode = createAction<string>(
	Actions.getUrlShortCode + ActionState.REQUEST
);

export const sendMessage = createAction<{
	text: string;
	phoneNumber: string;
	type: "sms" | "whatsapp";
}>(Actions.sendMessage + ActionState.REQUEST);

/**
 * Share via emails action
 */
export const shareViaEmails = createAction<ShareViaEmailPayload>(
	Actions.shareViaEmails + ActionState.REQUEST
);

export const shareError = createAction(
	Actions.shareViaEmails + ActionState.REJECTED
);

const initialState = {
	isShareModalVisible: false,
	shareModalTitle: "",
	messageText: "",
	sharingItemUrl: null as string | null,
	sharedItemType: null as ShareableItem | null,
	//
	getUrlShortCodeState: AsyncState.IDLE,
	getUrlShortCodeData: null as string | null,
	getUrlShortCodeError: "",
	//
	sendMessageState: AsyncState.IDLE,
	sendMessageError: "",
	//
	shareViaEmailsState: AsyncState.IDLE,
	shareViaEmailsError: "",
};

const slice = createSlice({
	name: "sharing",
	initialState,
	reducers: {
		setShareModalDetails: (
			state,
			action: PayloadAction<{
				itemType: ShareableItem;
				title: string;
				itemUrl: string;
				messageText: string;
			}>
		) => {
			state.shareModalTitle = action.payload.title;
			state.sharingItemUrl = action.payload.itemUrl;
			state.isShareModalVisible = !!action.payload.itemUrl;
			state.messageText = action.payload.messageText;
			state.sharedItemType = action.payload.itemType;
		},

		toggleShareModal: (state) => {
			if (state.isShareModalVisible) {
				state.sharingItemUrl = null;
			}
			state.isShareModalVisible = !state.isShareModalVisible;
		},
		resetSharing: () => initialState,
		resetMessageState: (state) => {
			state.sendMessageState = initialState.sendMessageState;
			state.shareViaEmailsState = initialState.shareViaEmailsState;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(Actions.getUrlShortCode + ActionState.PENDING, (state) => {
				state.getUrlShortCodeState = AsyncState.PENDING;
				state.getUrlShortCodeError = "";
			})
			.addCase(
				Actions.getUrlShortCode + ActionState.FULFILLED,
				(state, action: PayloadAction<any>) => {
					state.getUrlShortCodeState = AsyncState.FULFILLED;
					state.getUrlShortCodeData = action.payload;
				}
			)
			.addCase(
				Actions.getUrlShortCode + ActionState.REJECTED,
				(state, action: PayloadAction<any>) => {
					state.getUrlShortCodeState = AsyncState.REJECTED;
					state.getUrlShortCodeError = action.payload;
				}
			);
		//
		builder
			.addCase(Actions.sendMessage + ActionState.PENDING, (state) => {
				state.sendMessageState = AsyncState.PENDING;
				state.sendMessageError = "";
			})
			.addCase(Actions.sendMessage + ActionState.FULFILLED, (state) => {
				state.sendMessageState = AsyncState.FULFILLED;
			})
			.addCase(
				Actions.sendMessage + ActionState.REJECTED,
				(state, action: PayloadAction<any>) => {
					state.sendMessageState = AsyncState.REJECTED;
					state.sendMessageError = action.payload;
				}
			);
		//
		builder
			.addCase(Actions.shareViaEmails + ActionState.PENDING, (state) => {
				state.shareViaEmailsState = AsyncState.PENDING;
				state.shareViaEmailsError = "";
			})
			.addCase(
				Actions.shareViaEmails + ActionState.FULFILLED,
				(state) => {
					state.shareViaEmailsState = AsyncState.FULFILLED;
				}
			)
			.addCase(
				Actions.shareViaEmails + ActionState.REJECTED,
				(state, action: PayloadAction<any>) => {
					state.shareViaEmailsState = AsyncState.REJECTED;
					state.shareViaEmailsError = action.payload;
				}
			);
	},
});

export const {
	setShareModalDetails,
	toggleShareModal,
	resetSharing,
	resetMessageState,
} = slice.actions;
export default slice.reducer;
