/*
 * @Author: tnityanand@hootboard.com
 * @Date: 2020-03-24 16:05:31
 * @Last Modified by: snair@hootboard.com
 * @Last Modified time: 2020-06-06 12:02:46
 */

import { EMBED_URL, ROOT_URL } from "./constants";

export const boardLinkGenerator = (
	boardId,
	boardName,
	embed = false,
	domain = false
) => {
	let domainLink = ROOT_URL;
	if (embed) {
		domainLink = EMBED_URL;
	}
	if (domain) {
		return `${domainLink}b/${boardId}/${(boardName || "").replace(
			/ /g,
			"_"
		)}`;
	} else {
		return `/b/${boardId}/${(boardName || "").replace(/ /g, "_")}`;
	}
};

export const hootLinkGenerator = (
	boardId,
	boardName,
	hootId,
	hootName,
	embed = false,
	domain = false
) => {
	if (embed) return;

	let hootUrl = `b/${boardId}/${boardName.replace(
		/ /g,
		"_"
	)}/${hootId}/${hootName.replace(/ /g, "_")}`;
	if (domain) {
		return `${ROOT_URL}${hootUrl}`;
	} else {
		return "/" + hootUrl;
	}
};
