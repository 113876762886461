import { createSlice } from "@reduxjs/toolkit";
import { Actions } from "./activityFeed.saga";

export const fetchNotificationsAsync = (payload) => {
	return {
		type: Actions.getNotifications + "request",
		payload,
	};
};

export const readNotificationsAsync = (payload) => {
	return {
		type: Actions.readNotifications + "request",
		payload,
	};
};

const initialState = {
	notifications: [],
	notificationsFetchStatus: "idle",
	notificationsReadStatus: "idle",
	currentPage: 0,
	refreshNotificationsCounter: 0,
	isPageEnd: false,
};

const activityFeedSlice = createSlice({
	name: "activityFeed",
	initialState: initialState,
	reducers: {
		refreshNotifications: (state) => {
			state.refreshNotificationsCounter += 1;
		},
		resetToInitialState: (state) => {
			return {
				...initialState,
				// Avoid resetting, so we can show cached notifications while loading for more or refreshing
				notifications: state.notifications,
				refreshNotificationsCounter: state.refreshNotificationsCounter,
			};
		},
		// Used on unmount of activity page, so it can fetch fresh notifications each time on navigating back.
		clearNotifications: (state) => {
			state.refreshNotificationsCounter = 0;
			state.notifications = [];
		},
	},
	extraReducers: {
		[Actions.getNotifications + "pending"]: (state) => {
			return {
				...state,
				notificationsFetchStatus: "pending",
			};
		},
		[Actions.getNotifications + "fulfilled"]: (state, action) => {
			const { notifications } = action.payload;
			if (notifications.length === 0) {
				return {
					...state,
					isPageEnd: true,
					notificationsFetchStatus: "fulfilled",
				};
			} else {
				return {
					...state,
					notifications:
						// Overriding the global cache if page is 0
						action.payload.pageNumber === 0
							? action.payload.notifications
							: [
									...state.notifications,
									...action.payload.notifications,
							  ],
					currentPage: action.payload.pageNumber,
					notificationsFetchStatus: "fulfilled",
				};
			}
		},
		[Actions.getNotifications + "rejected"]: (state) => {
			return {
				...state,
				notificationsFetchStatus: "rejected",
			};
		},
		[Actions.readNotifications + "pending"]: (state) => {
			return {
				...state,
				notificationsReadStatus: "pending",
			};
		},
		[Actions.readNotifications + "fulfilled"]: (state) => {
			return {
				...state,
				notificationsReadStatus: "fulfilled",
			};
		},
		[Actions.readNotifications + "rejected"]: (state) => {
			return {
				...state,
				notificationsReadStatus: "rejected",
			};
		},
	},
});

export const { resetToInitialState, refreshNotifications, clearNotifications } =
	activityFeedSlice.actions;

export default activityFeedSlice.reducer;
