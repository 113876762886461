import { createSlice } from "@reduxjs/toolkit";
import { Actions } from "./pricing.saga";
import {
	calculateItemPrice,
	calculateTotalPrice,
	transformCartPayload,
} from "../../utils/pricing.util";

export const fetchProductPricing = ({ boardId }) => {
	return {
		type: Actions.fetchProductPricing + "request",
		payload: { boardId },
	};
};

export const fetchCustomerCard = ({ boardId }) => {
	return {
		type: Actions.fetchCustomerCard + "request",
		payload: { boardId },
	};
};

export const saveCard = ({ boardId, stripeToken }) => {
	return {
		type: Actions.saveCard + "request",
		payload: { boardId, stripeToken },
	};
};

export const fetchBoardSubscriptions = ({ boardId }) => {
	return {
		type: Actions.fetchBoardSubscriptions + "request",
		payload: { boardId },
	};
};

export const subscriptionCancellation = ({
	boardId,
	subscriptionId,
	cancelsOn,
}) => {
	return {
		type: Actions.subscriptionCancellation + "request",
		payload: { boardId, subscriptionId, cancelsOn },
	};
};

export const updateCustomerDetails = ({ boardId, adminEmail }) => {
	return {
		type: Actions.updateCustomerDetails + "request",
		payload: { boardId, adminEmail },
	};
};

export const updateBillingMethod = ({
	boardId,
	subscriptionId,
	billingMethod,
}) => {
	return {
		type: Actions.updateBillingMethod + "request",
		payload: { boardId, subscriptionId, billingMethod },
	};
};

export const fetchInvoices = ({ boardId, subscriptionId }) => {
	return {
		type: Actions.fetchInvoices + "request",
		payload: { boardId, subscriptionId },
	};
};

/**
 * @param {Object} payload
 * @param {number} payload.boardId
 * @param {string} payload.cartItems
 * @param {string} payload.stripeToken
 * @param {string} payload.planInterval
 * @param {boolean} payload.isTrial
 * @param {boolean} payload.isInvoice
 */
export const startCheckout = (payload) => {
	return {
		type: Actions.processCheckout + "request",
		payload: {
			...payload,
			cartItems: transformCartPayload(payload.cartItems),
		},
	};
};

/**
 * @param {number} boardId
 */
export const notifyAdmin = (boardId) => {
	return {
		type: Actions.notifyAdmin + "request",
		payload: boardId,
	};
};

const getProcessForSuccess = (state) => {
	let processForSuccess = state.processForSuccess;

	if (state.includeTrial) {
		processForSuccess = "trial";
	}

	if (state.boardSubscriptions.length > 0) {
		processForSuccess = "addLicenses";
	} else {
		processForSuccess = "upgrade";
	}

	return processForSuccess;
};

const initialState = {
	pageSelected: 0,
	includeTrial: false,
	cancelsOn: null,
	boardPricingPlan: {},
	customerCard: {},
	customerId: "",
	customerEmail: "",
	customerCardFetchStatus: "pending",
	saveCardStatus: "idle",
	upgradeBoardProcessStatus: "idle",
	planInterval: "YR",
	cart: {
		payableAmount: 0,
		monthlyAmount: 0,
		items: [],
	},
	purchasedLicensesAmount: 0,
	showAddCardControl: true,
	saveOnlyCard: false,
	existingPurchasedLicenses: null,
	purchaseAdditionalLicenses: false,
	startTrialProcessStatus: "idle",
	productPricing: [],
	fetchProductPricingStatus: "idle",
	notifyAdminAPIStatus: "idle",
	processForSuccess: "upgrade",
	fetchBoardSubscriptionsStatus: "idle",
	boardSubscriptions: [],
	showCancelSubscriptionModal: false,
	cancelSubscriptionStatus: "idle",
	showChangePaymentMethodModal: false,
	subscriptionIdForChange: "",
	billingMethodForChange: "",
	showChangeAdminModal: false,
	updateCustomerStatus: "idle",
	showChangeCardModal: false,
	updateSubscriptionStatus: "idle",
	showPreviousInvoicesModal: false,
	previousInvoices: [],
	fetchInvoicesStatus: "idle",
	requestInvoice: false,
	isTrialCancel: false,
};

const pricingSlice = createSlice({
	name: "pricing", // TODO: Rename to `pricing`.
	initialState,
	reducers: {
		clearUpgradeData: () => initialState,
		setTrial: (state) => {
			state.includeTrial = true;
		},
		setCustomerStatusIdle: (state) => {
			state.updateCustomerStatus = "idle";
		},
		setTrialCancel: (state, action) => {
			state.isTrialCancel = action.payload;
		},
		setSubscriptionCancelOn: (state, action) => {
			state.cancelsOn = action.payload;
		},
		setUpgradeBoardProcessStatus: (state, action) => {
			state.upgradeBoardProcessStatus = action.payload
				? "pending"
				: "idle";
			if (action.payload) {
				state.saveCardStatus = "idle";
			}
		},
		openPricingPage: (state) => {
			state.pageSelected = 0;
		},
		openManageSubscriptionsPage: (state) => {
			state.pageSelected = 2;
		},
		openUpgradeSuccessPage: (state, action) => {
			return {
				...state,
				pageSelected: 3,
				processForSuccess: action.payload,
			};
		},
		planIntervalChanged: (state, action) => {
			const planInterval = action.payload || "YR";
			if (planInterval !== state.planInterval) {
				state.planInterval = planInterval;

				/**
				 * When plan interval changes, we need to fetch the similar plans with new interval from the pricing list.
				 * And match with the items in the cart and update the cart.
				 */

				const cartItems = [];
				for (const cartItem of state.cart.items) {
					const product = state.productPricing.find((item) => {
						return (
							item.licenseType === cartItem.licenseType &&
							item.interval === planInterval
						);
					});
					if (product) {
						const priceDetails = calculateItemPrice({
							quantity: cartItem.quantity,
							price: product.amount,
							tiers: product.tiers,
						});

						const payload = {
							name: product.planName,
							quantity: cartItem.quantity,
							currency: product.currency.toUpperCase(),
							pricingId: product.pricingId,
							licenseType: product.licenseType,
							unitPrice: priceDetails.unitPrice,
							totalPrice: priceDetails.totalPrice,
						};

						if (planInterval === "YR") {
							payload.unitPrice = payload.unitPrice / 12;
							payload.totalPrice = payload.totalPrice / 12;
						}

						cartItems.push(payload);
					}
				}

				const monthlyAmount = calculateTotalPrice(cartItems);
				let payableAmount = monthlyAmount;

				if (state.planInterval === "YR") {
					payableAmount = payableAmount * 12;
				}

				state.cart = {
					items: cartItems,
					payableAmount,
					monthlyAmount,
				};
			}
		},
		setShowAddCardControl: (state, action) => {
			return {
				...state,
				showAddCardControl: action.payload,
			};
		},
		saveOnlyCardSelected: (state, action) => {
			state.saveOnlyCard = action.payload;
		},
		changePaymentMethodModalOpened: (state, action) => {
			state.showChangePaymentMethodModal = action.payload;
		},
		subscriptionIdForChangeAdded: (state, action) => {
			return {
				...state,
				subscriptionIdForChange: action.payload,
				updateSubscriptionStatus: "idle",
			};
		},
		billingMethodForChangeAdded: (state, action) => {
			return {
				...state,
				billingMethodForChange: action.payload,
			};
		},
		changeAdminModalOpened: (state, action) => {
			state.showChangeAdminModal = action.payload;
			state.updateCustomerStatus = "idle";
		},
		changeCardModalOpened: (state, action) => {
			state.showChangeCardModal = action.payload;
		},
		viewPreviousInvoicesModalOpened: (state, action) => {
			state.showPreviousInvoicesModal = action.payload;
		},
		cancelSubscriptionModalOpened: (state, action) => {
			state.showCancelSubscriptionModal = action.payload;
		},
		resetCart: (state) => {
			state.cart = initialState.cart;
		},
		addToCart: (state, action) => {
			// Send these items: name, licenseType, pricingId, quantity, unitPrice, totalPrice, currency
			const payload = action.payload;
			let items = [...state.cart.items];
			const existingCartItem = items.find((item) => {
				return item.pricingId === payload.pricingId;
			});

			if (existingCartItem) {
				// When adding to cart, if the item already exists, just increase the quantity
				existingCartItem.quantity += payload.quantity;
				// Also increase the total price
				existingCartItem.totalPrice =
					existingCartItem.quantity * existingCartItem.unitPrice;
				items = items.filter((item) => {
					return item.pricingId !== payload.pricingId;
				});
				items.push(existingCartItem);
			} else {
				items.push(payload);
			}

			const monthlyAmount = calculateTotalPrice(items);
			let payableAmount = monthlyAmount;

			if (state.planInterval === "YR") {
				payableAmount = payableAmount * 12;
			}

			state.cart = {
				items,
				monthlyAmount,
				payableAmount,
			};
		},
		removeFromCart: (state, action) => {
			// Send item index to remove.
			let items = state.cart.items.filter(
				(item, index) => index !== action.payload
			);

			// We can't let the user remove just one item.
			const matchUBP = (item) =>
				["UBF", "UBM"].includes(item.licenseType);

			const ubpCount = items.filter(matchUBP).length;
			if (ubpCount === 1) {
				// If user removes one UBP, we need to remove the other as well.
				items = items.filter((item) => !matchUBP(item));
			}

			const monthlyAmount = calculateTotalPrice(items);
			let payableAmount = monthlyAmount;

			if (state.planInterval === "YR") {
				payableAmount = payableAmount * 12;
			}

			state.cart = {
				items,
				monthlyAmount,
				payableAmount,
			};
		},
		proceedToPayment: (state) => {
			state.pageSelected = 1;
		},
	},
	extraReducers: {
		[Actions.fetchCustomerCard + "fulfilled"]: (state, action) => {
			if (action.payload !== undefined) {
				let data = action.payload.data;
				if (data && data.custCard) {
					if (data.custCard.cardId) {
						state.showAddCardControl = false;
					}
					state.customerCard = data.custCard;
					state.customerId = data.customerId;
					state.customerEmail = data.customerEmail;
					state.customerCardFetchStatus = "success";
				} else {
					return {
						...state,
						customerCardFetchStatus: "success",
					};
				}
			} else {
				return {
					...state,
					customerCardFetchStatus: "rejected",
				};
			}
		},
		[Actions.fetchCustomerCard + "pending"]: (state) => {
			return {
				...state,
				customerCardFetchStatus: "pending",
			};
		},
		[Actions.fetchCustomerCard + "rejected"]: (state) => {
			return {
				...state,
				customerCardFetchStatus: "rejected",
			};
		},
		[Actions.saveCard + "fulfilled"]: (state, action) => {
			if (action.payload && action.payload.data) {
				let data = action.payload.data;
				return {
					...state,
					showAddCardControl: false,
					customerCard: data.custCard,
					saveCardStatus: "success",
				};
			} else {
				return {
					...state,
					saveCardStatus: "rejected",
				};
			}
		},
		[Actions.saveCard + "pending"]: (state) => {
			return {
				...state,
				saveCardStatus: "pending",
			};
		},
		[Actions.saveCard + "rejected"]: (state) => {
			return {
				...state,
				saveCardStatus: "rejected",
				upgradeBoardProcessStatus: "idle",
			};
		},
		[Actions.processCheckout + "pending"]: (state) => {
			state.upgradeBoardProcessStatus = "pending";
		},
		[Actions.processCheckout + "pending"]: (state) => {
			return {
				...state,
				showSpinner: true,
				isError: false,
				errorMessage: "",
				upgradeBoardProcessStatus: "pending",
			};
		},
		[Actions.processCheckout + "fulfilled"]: (state) => {
			return {
				...state,
				showSpinner: false,
				isError: false,
				errorMessage: "",
				pageSelected: 3,
				processForSuccess: getProcessForSuccess(state),
				upgradeBoardProcessStatus: "fulfilled",
			};
		},
		[Actions.processCheckout + "rejected"]: (state) => {
			return {
				...state,
				showSpinner: false,
				isError: true,
				upgradeBoardProcessStatus: "idle",
				processForSuccess: getProcessForSuccess(state),
				errorMessage: "Error occurred while adding licenses!",
			};
		},
		[Actions.startBoardTrial + "fulfilled"]: (state, action) => {
			if (action.payload) {
				return {
					...state,
					startTrialProcessStatus: "success",
				};
			} else {
				return {
					...state,
					startTrialProcessStatus: "rejected",
				};
			}
		},
		[Actions.startBoardTrial + "rejected"]: (state) => {
			return {
				...state,
				startTrialProcessStatus: "rejected",
			};
		},
		[Actions.startBoardTrial + "pending"]: (state) => {
			return {
				...state,
				startTrialProcessStatus: "pending",
			};
		},
		[Actions.fetchProductPricing + "fulfilled"]: (state, action) => {
			if (action.payload !== undefined) {
				const resp = action.payload;
				let productPricing =
					resp && Array.isArray(resp.data) ? resp.data : [];

				if (productPricing) {
					productPricing = productPricing
						.filter((item) => item.productId) // some items contains empty objects
						.map((item) => {
							const features = JSON.parse(item.features);
							const addOns = (item.addOns || []).map(
								(addOnItem) => ({
									...addOnItem,
									features: JSON.parse(
										addOnItem.features || "[]"
									),
								})
							);

							return {
								...item,
								features,
								addOns,
								currency: "$",
							};
						});
				}

				return {
					...state,
					productPricing,
					fetchProductPricingStatus: "success",
				};
			} else {
				return {
					...state,
					fetchProductPricingStatus: "rejected",
				};
			}
		},
		[Actions.fetchProductPricing + "pending"]: (state) => {
			return {
				...state,
				fetchProductPricingStatus: "pending",
			};
		},
		[Actions.fetchProductPricing + "rejected"]: (state) => {
			return {
				...state,
				fetchProductPricingStatus: "rejected",
			};
		},
		[Actions.fetchBoardSubscriptions + "fulfilled"]: (state, action) => {
			if (action.payload !== undefined) {
				return {
					...state,
					boardSubscriptions: (action.payload &&
					Array.isArray(action.payload.data)
						? action.payload.data
						: []
					).map((item) => ({
						...item,
						currency: "$",
						productItems: (item.productItems || []).map(
							(productItem) => ({
								...productItem,
								currency: "$",
							})
						),
					})),
					fetchBoardSubscriptionsStatus: "success",
				};
			} else {
				return {
					...state,
					fetchBoardSubscriptionsStatus: "rejected",
				};
			}
		},
		[Actions.fetchBoardSubscriptions + "pending"]: (state) => {
			return {
				...state,
				fetchBoardSubscriptionsStatus: "pending",
			};
		},
		[Actions.fetchBoardSubscriptions + "rejected"]: (state) => {
			return {
				...state,
				fetchBoardSubscriptionsStatus: "rejected",
			};
		},
		[Actions.subscriptionCancellation + "fulfilled"]: (state, action) => {
			if (action.payload !== undefined) {
				return {
					...state,
					cancelSubscriptionStatus: "success",
				};
			} else {
				return {
					...state,
					cancelsOn: null,
					cancelSubscriptionStatus: "rejected",
				};
			}
		},
		[Actions.subscriptionCancellation + "pending"]: (state) => {
			return {
				...state,
				cancelSubscriptionStatus: "pending",
			};
		},
		[Actions.subscriptionCancellation + "rejected"]: (state) => {
			return {
				...state,
				cancelsOn: null,
				cancelSubscriptionStatus: "rejected",
			};
		},
		[Actions.updateCustomerDetails + "fulfilled"]: (state, action) => {
			if (
				action.payload &&
				action.payload.data &&
				action.payload.data.updatedEmail
			) {
				return {
					...state,
					customerEmail: action.payload.data.updatedEmail,
					updateCustomerStatus: "success",
				};
			} else {
				return {
					...state,
					updateCustomerStatus: "rejected",
				};
			}
		},
		[Actions.updateCustomerDetails + "pending"]: (state) => {
			return {
				...state,
				updateCustomerStatus: "pending",
			};
		},
		[Actions.updateCustomerDetails + "rejected"]: (state) => {
			return {
				...state,
				updateCustomerStatus: "rejected",
			};
		},
		[Actions.updateBillingMethod + "fulfilled"]: (state, action) => {
			if (action.payload) {
				let updatedSubscriptions = [...state.boardSubscriptions];
				if (action.payload.data) {
					const fetchedSubscriptionData = action.payload.data;
					updatedSubscriptions = updatedSubscriptions.map(
						(subscription) =>
							subscription.subscriptionId ===
							fetchedSubscriptionData.subscriptionId
								? fetchedSubscriptionData
								: subscription
					);
				}
				return {
					...state,
					updateSubscriptionStatus: "success",
					boardSubscriptions: updatedSubscriptions,
				};
			} else {
				return {
					...state,
					updateSubscriptionStatus: "rejected",
				};
			}
		},
		[Actions.updateBillingMethod + "pending"]: (state) => {
			return {
				...state,
				updateSubscriptionStatus: "pending",
			};
		},
		[Actions.updateBillingMethod + "rejected"]: (state) => {
			return {
				...state,
				updateSubscriptionStatus: "rejected",
			};
		},
		[Actions.fetchInvoices + "fulfilled"]: (state, action) => {
			if (action.payload !== undefined) {
				return {
					...state,
					previousInvoices:
						action.payload && Array.isArray(action.payload.data)
							? action.payload.data
							: [],
					fetchInvoicesStatus: "success",
				};
			} else {
				return {
					...state,
					fetchInvoicesStatus: "rejected",
				};
			}
		},
		[Actions.fetchInvoices + "pending"]: (state) => {
			return {
				...state,
				fetchInvoicesStatus: "pending",
			};
		},
		[Actions.fetchInvoices + "rejected"]: (state) => {
			return {
				...state,
				fetchInvoicesStatus: "rejected",
			};
		},
		[Actions.notifyAdmin + "pending"]: (state) => {
			state.notifyAdminAPIStatus = "pending";
		},
		[Actions.notifyAdmin + "rejected"]: (state) => {
			state.notifyAdminAPIStatus = "rejected";
		},
		[Actions.notifyAdmin + "fulfilled"]: (state) => {
			state.notifyAdminAPIStatus = "fulfilled";
		},
	},
});

export const {
	setTrial,
	clearUpgradeData,
	openPricingPage,
	setSubscriptionCancelOn,
	setTrialCancel,
	setCustomerStatusIdle,
	openManageSubscriptionsPage,
	openUpgradeSuccessPage,
	planIntervalChanged,
	setShowAddCardControl,
	setUpgradeBoardProcessStatus,
	saveOnlyCardSelected,
	changePaymentMethodModalOpened,
	subscriptionIdForChangeAdded,
	billingMethodForChangeAdded,
	changeAdminModalOpened,
	changeCardModalOpened,
	viewPreviousInvoicesModalOpened,
	cancelSubscriptionModalOpened,
	//
	resetCart,
	addToCart,
	removeFromCart,
	proceedToPayment,
} = pricingSlice.actions;

export default pricingSlice.reducer;
