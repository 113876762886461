import { useSelector } from "react-redux";
import { ListData } from "../types/lists";

/**
 * Utility function to get list item details.
 */
export const getListItemDetails = (params: {
	listItemMappings: any;
	itemValue: any;
}) => {
	return {
		itemValueData: params.itemValue,
		listIds: (params.listItemMappings[params.itemValue] || []) as any[],
	};
};

export const getChannelName = () => {
	/**
	 * To find if the site is loaded on mobile or desktop
	 */
	let channel = "";

	if (window.screen.width <= 500) {
		channel = "Web Mobile";
	} else {
		channel = "Web Desktop";
	}

	return channel;
};

/**
 * Get count of items inside a list based on a `listId`.
 */
export const useItemsCountInList = (listId?: number) => {
	const listItemMappings = useSelector(
		(state: any) => state.lists.listItemMappings
	);

	// Looping through all mapping items to find the sum.
	const itemCount = Object.keys(listItemMappings).reduce(
		(result, itemValue) => {
			const listIds = listItemMappings[itemValue].map((item: string) =>
				Number(item.split(":")[0])
			);
			if ([...new Set(listIds)].includes(listId)) return result + 1;
			return result;
		},
		0
	);
	return itemCount;
};

/**
 * Function to sort the list items by the userAssignedDateTime.
 */
export const sortListItemByDate = (list: ListData) => {
	const newItems = list.items.sort((item1, item2) => {
		// Handling empty or null userAssignedDateTime
		if (!item1.userAssignedDateTime && !item2.userAssignedDateTime) {
			return 0; // No preference for order
		}
		if (!item1.userAssignedDateTime) {
			return 1; // a is considered greater and placed after b
		}
		if (!item2.userAssignedDateTime) {
			return -1; // b is considered greater and placed after a
		}
		// Sort by date when both userAssignedDateTime values are present
		const dateA: Date = new Date(item1.userAssignedDateTime);
		const dateB: Date = new Date(item2.userAssignedDateTime);

		return dateA.getTime() - dateB.getTime();
	});
	list.items = newItems;
	return list;
};

/**
 * Function to generate the endpoint of list.
 */
export const generateListUrl = (params: {
	listId: number,
	name: string,
}) => {
	return `l/${params.listId}/${params.name.replace(
		/\s+/g,
		"_"
	)}`;
};

/**
 * Function to extract the list token from the embed url.
 */
export const extractListToken = (response: any) => {
	if (response.hootType === "HLLIST") {
		const queryString = response.embededMediaUrl.split("?")[1];
		const params = new URLSearchParams(queryString);
		const listToken = params.get("listToken") || "";

		return listToken;
	}
	return null;
};